import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { ChangeRequestStatus } from "../../../foritfied/types/evaluation/ChangeRequest";
import {
  WildfireFieldSchemaType,
  FormOverrides,
  WildfireDesignationLevel,
} from "../../customHooks/useFieldSchemaFactory";
import { find, get, isNil } from "lodash";
import { ComplianceTypes } from "../../types/ComplianceTypes";

export const transformFieldsForIterationEngineBeforeBuild = (
  fields: Readonly<BaseFieldProps<unknown>[]>,
  field: BaseFieldProps<unknown>,
  formOverrides?: FormOverrides,
  wildfireFieldSchema?: WildfireFieldSchemaType
) => {
  // Early return if no fieldKey is present
  if (!field.fieldKey) {
    return field;
  }

  // Compliance Check
  const compliantLevel = formOverrides?.fieldDecisions
    ? formOverrides?.fieldDecisions[field.fieldKey]?.complianceType
    : undefined;

  let complianceClassName;

  if (compliantLevel === undefined) {
    complianceClassName = "";
  } else {
    const isCompliant =
      compliantLevel === ComplianceTypes.Compliant ||
      compliantLevel === ComplianceTypes.NA;
    complianceClassName = !isCompliant ? "non-compliant-label" : "";
  }

  // Change Request Handling
  const shouldBeDisabled =
    formOverrides?.iterationEngineProps?.shouldEnableForm === false &&
    !formOverrides?.iterationEngineProps?.fieldKeysWithChangeRequests?.includes(
      field.fieldKey as string
    );
  const fieldChangeRequest =
    formOverrides?.iterationEngineProps?.changeRequests?.find(
      (cr) => cr.evaluationFieldKey === field.fieldKey
    );
  let changeRequestClassName = "";
  if (fieldChangeRequest) {
    switch (fieldChangeRequest.status) {
      case ChangeRequestStatus.New:
        changeRequestClassName = "change-request-new";
        break;
      case ChangeRequestStatus.Addressed:
        changeRequestClassName = "change-request-addressed";
        break;
      case ChangeRequestStatus.Resolved:
        changeRequestClassName = "change-request-resolved";
        break;
      default:
        changeRequestClassName = "";
    }
  }

  // Is Plus
  const fieldIsPlus =
    get(wildfireFieldSchema, field.fieldKey)?.designationLevel ===
      WildfireDesignationLevel.Plus || false;

  // Associated Field
  // If wildfireFieldSchema has schemas with an associatedFieldKey equals to field.fieldKey, it should always show
  const singleFieldSchemaWithThisAssociatedField = find(
    wildfireFieldSchema,
    (schema) => {
      return schema.associatedFieldKey === field.fieldKey;
    }
  );

  const singleFieldWithThisAssociatedField = fields.find((f) => {
    return f.fieldKey === singleFieldSchemaWithThisAssociatedField?.fieldKey;
  });

  // Default Hidden
  let shouldBeHidden = false;

  // Validate against the associated field
  if (!singleFieldWithThisAssociatedField) {
    // Validate against the isPlus field
    if (!isNil(formOverrides?.isPlus) && !isNil(fieldIsPlus)) {
      shouldBeHidden = !((formOverrides?.isPlus as boolean) === fieldIsPlus);
    }
  }

  // Combine classNames from both compliance and change request logic
  const classNames = [
    field.fieldKey,
    complianceClassName,
    changeRequestClassName,
  ].filter(Boolean);

  return {
    ...field,
    labelProps: {
      classNames,
      disabled: shouldBeDisabled,
      onClick: () =>
        formOverrides?.onLabelClick && formOverrides.onLabelClick(field),
    },
    disabled: shouldBeDisabled,
    hidden: field.hidden || shouldBeHidden,
  };
};
