import { Observer } from "mobx-react";
import React from "react";
import { EvaluationForm as EvaluationFormType } from "../../../types/evaluation/Evaluation";
import { fieldValidators } from "../../../utils/fieldValidators";
import { buildField } from "../../Fields";
import { AllFields } from "../../Fields/types";

export interface ExternalEvaluationFormProps {
  evaluationForm: EvaluationFormType;
  externalFolderId: string;
}

export const ExternalEvaluationForm: React.FC<ExternalEvaluationFormProps> = ({
  evaluationForm,
  externalFolderId,
}) => {
  return (
    <Observer>
      {() => (
        <div className={`evaluation-container__content__evaluation-form`}>
          <div>
            {evaluationForm.evaluationFields
              .filter((field) => field.isActive)
              // we should hide all "hidden" fields, unless they are file fields (this is a Formstack limitation)
              .filter((field) => field.fieldType === "Embed" || !field.isHidden)
              .sort((prev, next) => prev.sortOrder - (next.sortOrder ?? 1000))
              .map((field, idx) => {
                return (
                  <div key={field.id} id={field.id}>
                    {buildField(
                      {
                        fieldId: field.id,
                        label: field.label,
                        isExternalView: true,
                        isHidden: field.isHidden,
                        value: field.value,
                        folderId: field.extData,
                        sortOrder: field.sortOrder,
                        fieldType: field.fieldType,
                        optionValues: field.optionValues,
                        isSelectedField: false,
                        hasActiveChangeRequest: false,
                        isSelectedChangeRequest: false,
                        isResovledChangeRequest: false,
                        hasActiveInternalComment: false,
                        isEmpty: fieldValidators[field.fieldType],
                        disabled: true,
                        parentFolderId: externalFolderId,
                        onSubmit: async () => {
                          return 0;
                        },
                      } as AllFields,
                      idx
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </Observer>
  );
};
