import { ExistingResource } from "./ExistingResource";

export enum ChangeRequestStatus {
  New = 1,
  Addressed = 2,
  Resolved = 3,
}

export interface NewChangeRequest {
  id: string;
  evaluationItemDataId: string;
  from: string;
  to?: string;
  status: ChangeRequestStatus;
  status_AsString: string;
  valueChangedAt?: string;
  valueChangedBy?: string;
  valueChangedBy_AsName?: string;
  daysTillEligibleForAutoRejection: number;
  daysInChangesRequired?: number;
  changesRequiredDate?: string;
}

export type ChangeRequest = ExistingResource<NewChangeRequest>;
