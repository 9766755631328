import { dialogStore } from "@ucl/library";
import { UpdateUserForm } from "./UpdateUserForm";
import "../styles.scss";

const dialogIdBase = "wildfire-update-user-dialog";

const getDialogId = (wildfireApplicationId: string): string =>
  `${dialogIdBase}_${wildfireApplicationId}`;

export const openUpdateUserDialog = (
  userId: string,
  role: string,
  companyName: string,
  firstName: string,
  lastName: string,
  isActive: boolean,
  afterUpdate?: () => void
) =>
  dialogStore.openDialog({
    id: getDialogId(userId),
    className: dialogIdBase,
    header: "Update User",
    allowDragging: false,
    content: () => (
      <UpdateUserForm
        userId={userId}
        role={role}
        companyName={companyName}
        firstName={firstName}
        lastName={lastName}
        isActive={isActive}
        afterUpdate={afterUpdate}
      />
    ),
  });

export const closeUpdateUserDialog = (userId: string) =>
  dialogStore.closeDialog(getDialogId(userId));
