import { Classes, Dialog, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DialogFooter } from "./DialogFooter";
import { BaseDialogProps } from "./types";
import { ReactNode } from "react";

export interface SimpleConfirmationDialogProps extends BaseDialogProps {
  title: string;
  content: ReactNode;
}

export const SimpleConfirmationDialog: React.FC<
  SimpleConfirmationDialogProps
> = ({ isOpen, title, content, closeDialog, submitDialog }) => {
  return (
    <Dialog isOpen={isOpen} hasBackdrop={false}>
      <div className={Classes.DIALOG_HEADER}>
        <Icon icon={IconNames.TICK_CIRCLE} /> {title}
      </div>
      <div className={Classes.DIALOG_BODY}>{content}</div>
      <DialogFooter
        closeButtonText="Back to Edit"
        closeDialog={closeDialog}
        submitButtonText="Continue"
        submitDialog={submitDialog}
      />
    </Dialog>
  );
};
