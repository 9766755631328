import { IconNames } from "@blueprintjs/icons";
import { Tooltip2 } from "@blueprintjs/popover2";
import { permissionStore } from "../../stores/PermissionStore";
import { PageSidebarItem } from "./PageSidebarItem";
import "./styles.scss";
import { useProductTypeHandler } from "../../providers/ProductTypeHandlerProvider";

export interface PageSidebarProps {
  activePage: "home" | "reports" | "admin" | "help";
}

export const PageSidebar: React.FC<PageSidebarProps> = ({ activePage }) => {
  const { routePrefix } = useProductTypeHandler();

  return (
    <div className="page-sidebar">
      <PageSidebarItem
        item={{
          icon: IconNames.PROPERTIES,
          route: `${routePrefix}`,
          label: "Board",
        }}
        isSelected={activePage === "home"}
      />

      {permissionStore.canViewReportsPage && (
        <Tooltip2 className="page-sidebar-tool-tip">
          <PageSidebarItem
            item={{
              icon: IconNames.CHART,
              route: `${routePrefix}/reports`,
              label: "Reports",
            }}
            isSelected={activePage === "reports"}
          />
        </Tooltip2>
      )}

      {permissionStore.canViewAdminPage && (
        <Tooltip2 className="page-sidebar-tool-tip">
          <PageSidebarItem
            item={{
              icon: IconNames.SETTINGS,
              route: `${routePrefix}/admin`,
              label: "Admin",
            }}
            isSelected={activePage === "admin"}
          />
        </Tooltip2>
      )}

      <Tooltip2 className="page-sidebar-tool-tip">
        <PageSidebarItem
          item={{
            icon: IconNames.Help,
            route: `${routePrefix}/help`,
            label: "Help",
          }}
          isSelected={activePage === "help"}
        />
      </Tooltip2>
    </div>
  );
};
