import { GridColumn } from "@ucl/library";
import { UserRoleType } from "../../../../../common/Components/User/userTypes";

const wildfireUserGridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "role_AsString",
    headerText: "Role",
    type: "string",
  }),
  new GridColumn({
    field: "firstName",
    headerText: "First Name",
    type: "string",
  }),
  new GridColumn({
    field: "lastName",
    headerText: "Last Name",
    type: "string",
  }),
  new GridColumn({
    field: "email",
    headerText: "Email",
    type: "string",
  }),
  new GridColumn({
    field: "companyName",
    headerText: "Company Name",
    type: "string",
  }),
  new GridColumn({
    field: "modifiedAt_AsDateOnly",
    headerText: "Updated Date",
    type: "datetime",
    format: { format: "MM/dd/yyyy", type: "date" },
  }),
  new GridColumn({
    field: "modifiedBy_AsName",
    headerText: "Updated By",
    type: "string",
  }),
  new GridColumn({
    field: "createdAt_AsDateOnly",
    headerText: "Created Date",
    type: "datetime",
    format: { format: "MM/dd/yyyy", type: "date" },
  }),
  new GridColumn({
    field: "createdBy_AsName",
    headerText: "Created By",
    type: "string",
  }),
  new GridColumn({
    field: "isActive",
    headerText: "Active",
    type: "boolean",
    displayAsCheckBox: true,
    textAlign: "Center",
  }),
];

const wildfireUserGridRoleColumnsConfigurationDefaultFields = [
  "role_AsString",
  "firstName",
  "lastName",
  "email",
  "companyName",
  "modifiedAt_AsDateOnly",
  "modifiedBy_AsName",
  "createdAt_AsDateOnly",
  "createdBy_AsName",
  "isActive",
];

export const wildfireUserGridRoleColumnsConfiguration: Record<
  "Default",
  GridColumn[] | undefined
> = {
  Default: [
    ...wildfireUserGridColumnsConfiguration
      .filter((x) =>
        wildfireUserGridRoleColumnsConfigurationDefaultFields.includes(x.field)
      )
      .sort(function (a, b) {
        return (
          wildfireUserGridRoleColumnsConfigurationDefaultFields.indexOf(
            a.field
          ) -
          wildfireUserGridRoleColumnsConfigurationDefaultFields.indexOf(b.field)
        );
      }),
  ],
};

export const getWildfireUserGridColumnsConfigurationByRole = (
  _role?: UserRoleType
) => {
  return wildfireUserGridRoleColumnsConfiguration.Default as GridColumn[];
};
