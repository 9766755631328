import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { fortifiedUserStore } from "../../stores/FortifiedUserStore";
import { schemaStore } from "../../stores/AppSchemaStore";
import { AccountInfo } from "@azure/msal-browser";

//TODO: Additional Clean Up needed on MSAL Login, Logout and Redirect.
// See Here for Additional Documentation:
//https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md#usemsal-hook
//https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#browserautherrors

export const usePagesAuthentication = () => {
  const account = useAccount();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated({
    homeAccountId: account?.homeAccountId as string | undefined,
  });

  const isUserAuthenticated = !!isAuthenticated && !!account;

  const [isInitialized, setIsInitialized] = useState(false);
  const [hasError, setHasError] = useState(false);

  const isExternalRoute = () => location.pathname.includes("/external");

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    if (account && !isExternalRoute()) {
      await handleActiveAccount(account);
    } else if (!isExternalRoute()) {
      await handleLogin();
    }
    setIsInitialized(true);
  };

  const handleLogin = async () => {
    await instance.initialize();

    await instance
      .handleRedirectPromise()
      .then(async (response) => {
        if (response?.account) {
          instance.setActiveAccount(response.account);
          await handleActiveAccount(response.account);
        } else {
          await instance.loginRedirect();
        }
      })
      .catch(async (error) => {
        console.error("Handling redirect promise failed:", error);
        setHasError(true);
      });
  };

  const handleActiveAccount = async (account: AccountInfo) => {
    await Promise.all([
      fortifiedUserStore.initAccount(account),
      schemaStore.init(),
    ]).catch((error) => {
      console.error("Failed to initialize stores and schemas:", error);
      setHasError(true);
    });
  };

  return { isInitialized, isUserAuthenticated, hasError };
};
