import { FormGroup } from "@blueprintjs/core";
import { Observer, observer } from "mobx-react";
import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { boxTokenStore } from "../../../stores/BoxTokenStore";
import { FileField as IterationFileField } from "../../../types/viewModels/IterationEngineViewModel";
import { BaseFieldProps } from "../types";
import "./styles.scss";
import variables from "../../../../common/config/variables";
import { externalBoxTokenStore } from "../../../stores/ExternalBoxTokenStore";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { BoxContentExplorer } from "../../../../common/Components/Fields/BoxContentExplorer";
import { permissionStore } from "../../../stores/PermissionStore";
import { RelativityTooltip } from "@ucl/library";

export interface FileFieldProps
  extends BaseFieldProps<IterationFileField>,
    Omit<IterationFileField, "id" | "sortOrder" | "isHidden"> {
  parentFolderId?: string;
}

export const FileField: React.FC<FileFieldProps> = observer(
  ({
    folderId,
    fieldId,
    label,
    value,
    isSelectedField,
    hasActiveChangeRequest,
    isSelectedChangeRequest,
    isResovledChangeRequest,
    hasActiveInternalComment,
    disabled,
    onSubmit,
    isExternalView,
    parentFolderId,
  }) => {
    const [timestamp, updateTimestamps] = React.useState<number>(Date.now());
    const [ready, setReady] = React.useState<boolean>(false);
    const [token, setToken] = React.useState<string>();
    const shouldShowExplorer = !!folderId;
    const shouldDisableFileRead =
      label === "Compliance Letter" &&
      !permissionStore.canViewFCFMFApprovalDocuments;

    let height = 20;
    if (shouldShowExplorer) {
      height += 380;
    }

    const setBoxTokenStore = async () => {
      if (!boxTokenStore.isTokenValid) {
        await boxTokenStore.refreshBoxToken();
      } else {
        await boxTokenStore.init();
      }

      setToken(boxTokenStore.boxToken);
    };

    const setExternalBoxTokenStore = async (parentFolderId: string) => {
      if (!externalBoxTokenStore.isTokenValid) {
        await externalBoxTokenStore.refreshBoxToken(parentFolderId);
      } else {
        await externalBoxTokenStore.init(parentFolderId);
      }

      setToken(externalBoxTokenStore.boxToken);
    };

    useEffect(() => {
      const refreshInterval = parseInt(
        variables.boxFileUploaderRefreshInterval
      ); //30 minutes

      if (isExternalView && parentFolderId) {
        setExternalBoxTokenStore(parentFolderId);
      } else {
        setBoxTokenStore();
      }

      // Set up an interval to periodically check and refresh the token
      const tokenRefreshInterval = setInterval(
        setBoxTokenStore,
        refreshInterval
      );
      setReady(true);

      return () => {
        clearInterval(tokenRefreshInterval);
      };
    }, []);

    return (
      <Observer>
        {() => (
          <FormGroup>
            <SelectableLabel
              name={label}
              fieldId={fieldId}
              value={value}
              isSelectedField={isSelectedField}
              hasActiveChangeRequest={hasActiveChangeRequest}
              isSelectedChangeRequest={isSelectedChangeRequest}
              isResovledChangeRequest={isResovledChangeRequest}
              hasActiveInternalComment={hasActiveInternalComment}
              shouldDisableFieldLabelClick={isExternalView}
            />
            <div key={timestamp} style={{ height }}>
              <IntlProvider locale="en">
                {ready && token && shouldShowExplorer && (
                  <RelativityTooltip
                    shouldShowTooltip={shouldDisableFileRead}
                    compact={true}
                    className="file-field-tooltip-container"
                    content={
                      <div className="file-field-tooltip">
                        Available for preview and download after Certificate
                        Payment has been successfully processed
                      </div>
                    }
                  >
                    <BoxContentExplorer
                      key={folderId}
                      token={token}
                      rootFolderId={folderId}
                      language="en-US"
                      canCreateNewFolder={false}
                      canPreview={!shouldDisableFileRead}
                      canDownload={!shouldDisableFileRead}
                      canShare={false}
                      canDelete={
                        !disabled &&
                        permissionStore.canDeleteFilesFromUploader(fieldId)
                      }
                      canUpload={!disabled}
                      canRename={!disabled}
                      onUpload={async (files) => {
                        const fileNames = JSON.stringify(
                          files.map((file) => file.name)
                        );
                        onSubmit(fileNames);
                        updateTimestamps(Date.now());
                      }}
                    />
                  </RelativityTooltip>
                )}
              </IntlProvider>
            </div>
          </FormGroup>
        )}
      </Observer>
    );
  }
);
