import React, { useEffect } from "react";
import { Navigate, useOutlet } from "react-router";
import { autorun } from "mobx";
import { errorStore } from "../../../common/Components/Error/ErrorStore";
import ErrorWrapper from "../../../common/Components/Error/ErrorWrapper";
import { ErrorBoundary } from "react-error-boundary";
export interface PagesLayoutComponentProps {
  redirectPath: string;
}

const ExternalPagesLayoutComponent: React.FC<PagesLayoutComponentProps> = (
  props
) => {
  const outlet = useOutlet();
  const [hasError, setHasError] = React.useState<boolean>(false);

  useEffect(() => {
    autorun(() => {
      setHasError(!!errorStore.errorMessage);
    });
  }, []);

  return (
    <>
      <div className="fortified-page">
        {hasError && <ErrorWrapper />}
        {!hasError && (
          <ErrorBoundary FallbackComponent={() => <ErrorWrapper />}>
            <>{outlet || <Navigate to={props.redirectPath} replace />}</>
          </ErrorBoundary>
        )}
      </div>
    </>
  );
};

export const ExternalPagesLayout = ExternalPagesLayoutComponent;
