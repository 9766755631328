import { dialogStore, Footer } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import { Intent } from "@blueprintjs/core";
import "../styles.scss";
import { AppToaster } from "../../../../foritfied/components/Toast/Toast";
import WildfireSecondaryButton from "../../Button/DialogFooterButtons/SecondaryButton/SecondaryButton";
import WildfireSubmitButton from "../../Button/DialogFooterButtons/SubmitButton/SubmitButton";

const dialogIdBase = "wildfire-approval-dialog";

const getDialogId = (wildfireApplicationId: string): string =>
  `${dialogIdBase}_${wildfireApplicationId}`;

const buildWildfireApprovalDialogProps = (
  wildfireApplicationId: string,
  approve: () => Promise<void>
): DialogProps => {
  return {
    id: getDialogId(wildfireApplicationId),
    className: dialogIdBase,
    header: "Designation Confirmation",
    allowDragging: false,
    content: () => (
      <>
        <div className={`wildfire-dialog-body-text`}>
          Have you verified that the building address is correct? Are you sure
          you want to designate?
        </div>
        <Footer>
          <WildfireSecondaryButton
            buttonText="Cancel"
            onClick={() => closeWildfireApprovalDialog(wildfireApplicationId)}
          />
          <WildfireSubmitButton
            buttonText="Designate"
            onClick={async () => {
              closeWildfireApprovalDialog(wildfireApplicationId);
              await approve()
                .then(async () => {
                  AppToaster.show({
                    message: "Evaluation designated.",
                    intent: Intent.SUCCESS,
                  });
                })
                .catch((error) => {
                  AppToaster.show({
                    message:
                      "Unexpected error occured while designating evaluation.",
                    intent: Intent.DANGER,
                  });
                  console.error(error);
                });
            }}
          />
        </Footer>
      </>
    ),
  };
};

export const openWildfireApprovalDialog = (
  wildfireApplicationId: string,
  approve: () => Promise<void>
) =>
  dialogStore.openDialog(
    buildWildfireApprovalDialogProps(wildfireApplicationId, approve)
  );

export const closeWildfireApprovalDialog = (wildfireApplicationId: string) =>
  dialogStore.closeDialog(getDialogId(wildfireApplicationId));
