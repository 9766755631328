import { Classes, Dialog } from "@blueprintjs/core";
import { useRef } from "react";
import { evaluationStore } from "../../stores/EvaluationStore";
import Certificate from "../Certificate";
import { DialogFooter } from "./DialogFooter";
import "./styles.scss";
import { BaseDialogProps } from "./types";

export interface ViewCertificateDialogProps extends BaseDialogProps {}

export const ViewCertificateDialog: React.FC<ViewCertificateDialogProps> = ({
  isOpen,
  closeDialog,
}) => {
  const printableRef = useRef<HTMLIFrameElement>(null);

  return (
    <Dialog className="certificate-dialog" isOpen={isOpen} hasBackdrop={false}>
      <div className={Classes.DIALOG_HEADER}>View Certificate</div>
      <div className={Classes.DIALOG_BODY}>
        {evaluationStore.evaluation && (
          <Certificate
            evaluation={evaluationStore.evaluation}
            printableRef={printableRef}
          />
        )}
      </div>
      <DialogFooter
        closeDialog={closeDialog}
        submitDialog={async () => {
          printableRef?.current?.contentWindow?.print();
        }}
        closeButtonText="Cancel"
        submitButtonText="Print to PDF"
      />
    </Dialog>
  );
};
