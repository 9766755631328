import { WildfireBoxContentExplorerValue } from "../../components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { FieldSchemaModel } from "../fieldSchemaConfig";
import { EnumSingleSelectFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";

export enum OtherFieldEvaluationFormParts {
  OtherStructuresAndOutbuildings = "other-structures-outbuildings",
}

export interface OtherFieldEvaluationFormModel extends FieldSchemaModel {
  // Relationships
  wildfireApplicationEvaluationId: string;

  // Metadata
  otherStructuresAndOutbuildingsRequiredFieldCount: number;

  isOtherStructuresAndOutbuildingsComplete: boolean;

  otherStructuresAndOutbuildingsSubmitted: boolean;
  submitted: boolean;

  //fields
  structuresAndOutbuildingsPhotosFolder: WildfireBoxContentExplorerValue;
  accessoryStructuresDistanceOrAbsent: EnumSingleSelectFieldValue;
  maxThreeLargeAccessoriesNearHome: EnumSingleSelectFieldValue;
  accessoryStructure1ComplianceWithHome: EnumSingleSelectFieldValue;
  accessoryStructure2ComplianceWithHome: EnumSingleSelectFieldValue;
  accessoryStructure3ComplianceWithHome: EnumSingleSelectFieldValue;
  hotTubPlacementCompliance: EnumSingleSelectFieldValue;
  propaneTankPlacementAndSafety: EnumSingleSelectFieldValue;
  miscPhotosFolder: WildfireBoxContentExplorerValue;
}
