import { Schema } from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import { FCFMFSchemaFactoryV2 } from "../../../../../common/customHooks/useFCFMFFieldSchemaFactory";
import wildfireBaseApiClient from "../../../../../../../wildfire/lib/wildfireBaseApiClient";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { CommercialRedesignationFormContainerProps } from "./CommercialRedesignationFormContainer";
import { Evaluation_CommercialRedesignationFormModel } from "../../../../types/CommercialRedesignationFormModel";
import { FCFMFRedesignationFormParts } from "../../../../../common/types/Redesignation/BaseFCFMFRedesignationFormModel";

function useCommercialRedesignationFormContainer(
  props: CommercialRedesignationFormContainerProps
) {
  const [commercialSchemaFactory, setCommercialSchemaFactory] =
    useState<FCFMFSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireBaseApiClient.get<Schema>(`/wildfire/schema`);
    const newSchemaFactory = new FCFMFSchemaFactoryV2(schema);
    setCommercialSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!commercialSchemaFactory) {
      getSchema();
    }
    //return () => {
    //     setDefaultHeader();
    //   };
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.formPart]);

  // const { setWildfireEvaluationHeader, setDefaultHeader } =
  //   useContext(AppHeaderContext);
  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    FCFMFRedesignationFormParts | undefined
  >(props.formPart);

  const [formModel, setFormModel] =
    useState<Evaluation_CommercialRedesignationFormModel>();

  const form = formModel?.commercialRedesignation.redesignationForm;

  const getNextUnsubmittedFormPart = () => {
    if (!form) {
      return null;
    }

    if (!form.isApplicationInformationComplete) {
      return FCFMFRedesignationFormParts.ApplicationInformation;
    } else if (!form.isGeneralDetailsComplete) {
      return FCFMFRedesignationFormParts.GeneralDetails;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart = (): FCFMFRedesignationFormParts => {
    // Default to the first part of the form if we can't find a better option
    const defaultFormPart = Object.values(FCFMFRedesignationFormParts)[0];

    // If the application form part is provided (by URL), use it
    if (!!props.formPart) {
      return props.formPart;
    }

    // Otherwise, try to find the first unsubmitted part of the form
    // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
    return getNextUnsubmittedFormPart() ?? defaultFormPart;
  };

  const getFormPartChecklistStatus = (
    formPart: FCFMFRedesignationFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case FCFMFRedesignationFormParts.ApplicationInformation:
        return form?.isApplicationInformationComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case FCFMFRedesignationFormParts.GeneralDetails:
        return form?.isGeneralDetailsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const applicationChecklistParts: FormChecklistPart<FCFMFRedesignationFormParts>[] =
    [
      {
        formPart: FCFMFRedesignationFormParts.ApplicationInformation,
        displayName: "Application Information",
        requiredFieldCount: form?.applicationInformationRequiredFieldCount,
      },
      {
        formPart: FCFMFRedesignationFormParts.GeneralDetails,
        displayName: "General Details",
        requiredFieldCount: form?.generalDetailsRequiredFieldCount,
        disabled: form?.isApplicationInformationComplete !== true,
      },
    ];

  return {
    applicationChecklistParts,
    getFormPartChecklistStatus,
    setFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    commercialSchemaFactory,
  };
}

export default useCommercialRedesignationFormContainer;
