import { computed, makeObservable } from "mobx";
import { iterationEngineStore } from "./IterationEngineStore";
import { fortifiedUserStore } from "./FortifiedUserStore";

class IterationEngineCommentViewerStore {
  constructor() {
    makeObservable(this, {
      canRequestChange: computed,
      canSubmitForReview: computed,
      canApproveChangeRequest: computed,
      canRejectChangeRequest: computed,
    });
  }

  get addressedChangeRequestsCount() {
    return Object.keys(iterationEngineStore.changeRequests).filter(
      (key) =>
        iterationEngineStore.changeRequests[key].status ===
          "dirty-addressed-by-value" ||
        iterationEngineStore.changeRequests[key].status ===
          "dirty-addressed-by-comment" ||
        iterationEngineStore.changeRequests[key].status ===
          "dirty-addressed-by-file" ||
        iterationEngineStore.changeRequests[key].status === "addressed"
    ).length;
  }

  get needsAddressedChangeRequestsCount() {
    return Object.keys(iterationEngineStore.changeRequests).filter(
      (key) =>
        iterationEngineStore.changeRequests[key].status === "new" ||
        iterationEngineStore.changeRequests[key].status ===
          "dirty-addressed-by-value" ||
        iterationEngineStore.changeRequests[key].status ===
          "dirty-addressed-by-comment" ||
        iterationEngineStore.changeRequests[key].status ===
          "dirty-addressed-by-file" ||
        iterationEngineStore.changeRequests[key].status === "addressed"
    ).length;
  }

  get resolvedChangeRequestsCount() {
    return Object.keys(iterationEngineStore.changeRequests).filter(
      (key) => iterationEngineStore.changeRequests[key].status === "resolved"
    ).length;
  }

  get changeRequestsCount() {
    return Object.keys(iterationEngineStore.changeRequests).length;
  }

  // 1. when user is auditor
  // 2. when there is one change request just created but not yet saved
  get canRequestChange() {
    return (
      fortifiedUserStore.isAuditor &&
      Object.keys(iterationEngineStore.changeRequests).some(
        (key) => iterationEngineStore.changeRequests[key].status !== "resolved"
      )
    );
  }

  // 1. when user is evaluator
  // 2. when there is one change request just addressed but not yet saved
  get canSubmitForReview() {
    return (
      fortifiedUserStore.isEvaluator &&
      iterationEngineStore.applicationStatus === "Changes Required" &&
      Object.keys(iterationEngineStore.changeRequests).some(
        (key) =>
          iterationEngineStore.changeRequests[key].status ===
            "dirty-addressed-by-value" ||
          iterationEngineStore.changeRequests[key].status ===
            "dirty-addressed-by-comment" ||
          iterationEngineStore.changeRequests[key].status ===
            "dirty-addressed-by-file" ||
          iterationEngineStore.changeRequests[key].status === "addressed"
      ) &&
      !Object.keys(iterationEngineStore.changeRequests).some(
        (key) => iterationEngineStore.changeRequests[key].status === "new"
      )
    );
  }

  // 1. when user is auditor
  // 2. when there is one change request addressed
  get canApproveChangeRequest() {
    return fortifiedUserStore.isAuditor;
  }

  // 1. when user is auditor
  get canRejectChangeRequest() {
    return fortifiedUserStore.isAuditor;
  }
}

export const iterationEngineCommentViewerStore =
  new IterationEngineCommentViewerStore();
