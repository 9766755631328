import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { HomeFieldSchemaModel } from "./homeFieldSchemaConfig";
import { homeStandardIterationEngineBasicInformationFormBuilder } from "../../../standard/components/Forms/BasicInformationForm/HomeStandardBasicInformationFormBuilder";
import { homeSCSHIterationEngineBasicInformationFormBuilder } from "../../../scsh/components/Forms/BasicInformationForm/HomeSCSHBasicInformationFormBuilder";
import { homeSCSHIterationEngineEvaluationFormBuilder } from "../../../scsh/components/Forms/SCSHEvaluationForm/HomeSCSHEvaluationFormBuilder";

export type HomeIterationEngineFieldSchemaFormType =
  typeof HomeIterationEngineFieldSchemaFormTypeList[number];

export const HomeIterationEngineFieldSchemaFormTypeList = [
  "HomeStandardIterationEngineBasicInformationForm",
  "HomeSCSHIterationEngineBasicInformationForm",
  "HomeSCSHIterationEngineEvaluationForm",
] as const;

export type HomeIterationEngineFieldSchemaFormBuildersConfig = Record<
  HomeIterationEngineFieldSchemaFormType,
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ) => Partial<FieldSchemaFormBuilderV2<HomeFieldSchemaModel>>
>;
export const homeIterationEngineFieldSchemaFormBuilders: HomeIterationEngineFieldSchemaFormBuildersConfig =
  {
    HomeStandardIterationEngineBasicInformationForm:
      homeStandardIterationEngineBasicInformationFormBuilder,
    HomeSCSHIterationEngineBasicInformationForm:
      homeSCSHIterationEngineBasicInformationFormBuilder,
    HomeSCSHIterationEngineEvaluationForm:
      homeSCSHIterationEngineEvaluationFormBuilder,
  };
