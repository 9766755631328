import React from "react";
import { Callout, Intent, NonIdealState } from "@blueprintjs/core";
import { Observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { PageSidebar } from "../components/PageSidebar/PageSidebar";
import { evaluationStore } from "../stores/EvaluationStore";
import { ChecklistContainer } from "../components/Checklist/ChecklistContainer";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import "./styles.scss";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../AppInsights";
import { Header } from "../components/Header/Header";
import { useProductTypeHandler } from "../providers/ProductTypeHandlerProvider";

export interface ChecklistProps {}

const useChecklistQueryParams = () => {
  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const redirectedFromFormType_AsString = query.get("redirectedFromFormType");
  const redirectedFromFormType = redirectedFromFormType_AsString
    ? parseInt(redirectedFromFormType_AsString)
    : null;

  return {
    fortifiedId: query.get("fortifiedId"),
    redirectedFromFormType:
      !redirectedFromFormType || isNaN(redirectedFromFormType)
        ? null
        : redirectedFromFormType,
  };
};

const ChecklistComponent: React.FC<ChecklistProps> = () => {
  const { fortifiedId, redirectedFromFormType } = useChecklistQueryParams();

  const { routePrefix } = useProductTypeHandler();

  if (!fortifiedId) {
    return <NonIdealState />;
  }

  // Synchronize
  React.useEffect(() => {
    const synchronize = async () => {
      const detail = await evaluationStore.getEvaluationByFortifiedId(
        fortifiedId
      );

      const isSyncing = detail.evaluation.evaluationForms.some(
        (form) =>
          form.evaluationItemType === redirectedFromFormType &&
          !form.isCompleted
      );

      evaluationStore.setEvaluation({
        ...detail,
        evaluation: {
          ...detail.evaluation,
          evaluationForms: detail.evaluation.evaluationForms.map((form) => {
            if (form.evaluationItemType === redirectedFromFormType) {
              form.isSyncing = isSyncing;
            }

            return form;
          }),
        },
      });

      if (isSyncing) {
        setTimeout(synchronize, 5000);
      }
    };

    synchronize();

    return () => evaluationStore.reset();
  }, []);

  return (
    <div className="fortified-page">
      <Observer>
        {() => (
          <Header
            breadCrumbs={[
              { href: `${routePrefix}`, text: "Board" },
              { text: evaluationStore.evaluationBreadcrumbText },
            ]}
            renderWarning={() =>
              evaluationStore.isFortifiedHomeEvaluation &&
              evaluationStore.evaluation?.otherEvaluationsHaveSameAddress && (
                <Callout intent={Intent.WARNING}>
                  An Evaluation with this address already exists. Please verify
                  this is accurate.
                </Callout>
              )
            }
          />
        )}
      </Observer>
      <Observer>
        {() =>
          evaluationStore.evaluation && evaluationStore.schema ? (
            <div className="content">
              <PageSidebar activePage="home" />
              <ChecklistContainer
                evaluation={evaluationStore.evaluation}
                schema={evaluationStore.schema}
              />
            </div>
          ) : (
            <Loading />
          )
        }
      </Observer>
    </div>
  );
};

export const Checklist = withAITracking(
  reactPlugin,
  ChecklistComponent,
  "Checklist",
  "tracking-container"
);
