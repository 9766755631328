import { Observer } from "mobx-react";
import React from "react";
import { evaluationChangeRequestStore } from "../../stores/EvaluationChangeRequestStore";
import { evaluationStore } from "../../stores/EvaluationStore";
import {
  EvaluationField,
  EvaluationForm as EvaluationFormType,
} from "../../types/evaluation/Evaluation";
import { fieldValidators } from "../../utils/fieldValidators";
import { buildField } from "../Fields";
import { AllFields } from "../Fields/types";

export interface EvaluationFormProps {
  evaluationForm: EvaluationFormType;
  updateEvaluationItemData: (
    evaluationItemId: string,
    evaluationItemDataId: string,
    value: string
  ) => Promise<void>;
}

export const EvaluationForm: React.FC<EvaluationFormProps> = ({
  evaluationForm,
  updateEvaluationItemData,
}) => {
  const isFieldExternalView = (field: EvaluationField) =>
    field.label === "Fortified ID";

  return (
    <Observer>
      {() => (
        <div className={`evaluation-container__content__evaluation-form`}>
          <div>
            {evaluationForm.evaluationFields
              .filter((field) => field.isActive)
              // we should hide all "hidden" fields, unless they are file fields (this is a Formstack limitation)
              .filter((field) => field.fieldType === "Embed" || !field.isHidden)
              .sort((prev, next) => prev.sortOrder - (next.sortOrder ?? 1000))
              .map((field, idx) => {
                const fieldChangeRequestState =
                  evaluationChangeRequestStore.getFieldChangeRequestState(
                    field.id
                  );

                return (
                  <div key={field.id} id={field.id}>
                    {buildField(
                      {
                        fieldId: field.id,
                        label: field.label,
                        isHidden: field.isHidden,
                        value: field.value,
                        folderId: field.extData,
                        sortOrder: field.sortOrder,
                        fieldType: field.fieldType,
                        optionValues: field.optionValues,
                        isSelectedField:
                          evaluationStore.selectedField?.id === field.id,
                        hasActiveChangeRequest:
                          fieldChangeRequestState.isActive,
                        isSelectedChangeRequest:
                          fieldChangeRequestState.isSelected,
                        isResovledChangeRequest:
                          fieldChangeRequestState.isActiveAndResolved,
                        hasActiveInternalComment:
                          evaluationStore.hasActiveInternalComment(field.id),
                        isEmpty: fieldValidators[field.fieldType],
                        disabled: !field.canBeUpdated,
                        onSubmit: async (value: unknown) => {
                          await updateEvaluationItemData(
                            evaluationForm.id,
                            field.id,
                            value as string
                          );
                        },
                        isExternalView: isFieldExternalView(field),
                      } as AllFields,
                      idx
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </Observer>
  );
};
