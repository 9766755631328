import {
  EnumSingleSelectFieldProps,
  FieldSchemaFormBuilderV2,
  NumericFieldProps,
  SchemaFactoryV2,
} from "@ucl/library";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { EvaluationFormModel } from "../EvaluationFormModel";
import { get } from "lodash";

const modelName = "Evaluation";
const subModelName = "WildfireApplication";
const formName = "RoofFieldEvaluationForm";
const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Cover Material
const coverMaterialFields = (
  form: EvaluationFormModel,
  isIEBuilder?: boolean,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("RoofCoverMaterialPhotoFolder"),
    hasLimitedFileTypes: true,
    defaultToUpload: !isIEBuilder,
    errorMessages: get(errors, "roofCoverMaterialPhotoFolder.FileCount"),
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire" &&
      !form.areLegacyFileUploadersRequired,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("DominantRoofCoverPercentage"),
    min: 0,
    max: 100,
    format: "0'%'",
    validateDecimalOnType: true,
    decimals: 0,
  } as NumericFieldProps,
  buildFieldKey("RoofCoverMaterial"),
  {
    fieldKey: buildFieldKey("SecondaryRoofCoverPercentage"),
    min: 0,
    max: 100,
    format: "0'%'",
    hidden:
      form.wildfireApplication.roofFieldEvaluationForm
        .dominantRoofCoverPercentage === 100 ||
      form.wildfireApplication.roofFieldEvaluationForm
        .dominantRoofCoverPercentage === null,
    validateDecimalOnType: true,
    decimals: 0,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("SecondaryRoofCoverMaterial"),
    hidden:
      form.wildfireApplication.roofFieldEvaluationForm
        .dominantRoofCoverPercentage === 100 ||
      form.wildfireApplication.roofFieldEvaluationForm
        .dominantRoofCoverPercentage === null,
  } as EnumSingleSelectFieldProps,
];
const coverMaterialRequiredFields = [
  buildFieldKey("RoofCoverMaterialPhotoFolder"),
  buildFieldKey("RoofCoverMaterial"),
  buildFieldKey("DominantRoofCoverPercentage"),
  buildFieldKey("SecondaryRoofCoverPercentage"),
  buildFieldKey("SecondaryRoofCoverMaterial"),
];

export const roofFieldEvaluationCoverMaterialBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        coverMaterialFields(form, false, errors),
        onValueChange,
        form,
        errors,
        coverMaterialRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }

    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Part 2: Other Roof Conditions
const otherRoofCoverConditionsFields = [
  buildFieldKey("IsRoofClearOfDebris"),
  buildFieldKey("AreSkylightsFlatGlass"),
];
const otherRoofCoverConditionsRequiredFields = [
  buildFieldKey("IsRoofClearOfDebris"),
  buildFieldKey("AreSkylightsFlatGlass"),
];

export const roofFieldEvaluationOtherRoofCoverConditionsBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        otherRoofCoverConditionsFields,
        onValueChange,
        form,
        errors,
        otherRoofCoverConditionsRequiredFields
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Part 3: Gutters And Downspouts
const guttersAndDownspoutsFields = (
  form: EvaluationFormModel,
  isIEBuilder?: boolean,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("GuttersAndDownspoutsPhotoFolder"),
    defaultToUpload: !isIEBuilder,
    hasLimitedFileTypes: true,
    errorMessages: get(errors, "guttersAndDownspoutsPhotoFolder.FileCount"),
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire" &&
      !form.areLegacyFileUploadersRequired,
  } as WildfireBoxContentExplorerProps,
  buildFieldKey("AreGuttersAndDownspoutsMetal"),
  buildFieldKey("AreGuttersAndDownspoutsClearOfDebris"),
  buildFieldKey("AreGuttersCoveredWithMetalGuard"),
];
const guttersAndDownspoutsRequiredFields = [
  buildFieldKey("GuttersAndDownspoutsPhotoFolder"),
  buildFieldKey("AreGuttersAndDownspoutsMetal"),
  buildFieldKey("AreGuttersAndDownspoutsClearOfDebris"),
  buildFieldKey("AreGuttersCoveredWithMetalGuard"),
];

export const roofFieldEvaluationGuttersAndDownspoutsBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        guttersAndDownspoutsFields(form, false, errors),
        onValueChange,
        form,
        errors,
        guttersAndDownspoutsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Part 4: Eaves And Soffits
const eavesAndSoffitsFields = (
  form: EvaluationFormModel,
  isIEBuilder?: boolean,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("EavesAndSoffitsPhotoFolder"),
    defaultToUpload: !isIEBuilder,
    hasLimitedFileTypes: true,
    errorMessages: get(errors, "eavesAndSoffitsPhotoFolder.FileCount"),
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire" &&
      !form.areLegacyFileUploadersRequired,
  } as WildfireBoxContentExplorerProps,
  buildFieldKey("AreEavesEnclosedWithNoncombustibleMaterial"),
];
const eavesAndSoffitsRequiredFields = [
  buildFieldKey("EavesAndSoffitsPhotoFolder"),
  buildFieldKey("AreEavesEnclosedWithNoncombustibleMaterial"),
];

export const roofFieldEvaluationEavesAndSoffitsBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        eavesAndSoffitsFields(form, false, errors),
        onValueChange,
        form,
        errors,
        eavesAndSoffitsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Part 5: Vents
const ventsFields = (
  form: EvaluationFormModel,
  isIEBuilder?: boolean,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("VentsPhotoFolder"),
    defaultToUpload: !isIEBuilder,
    hasLimitedFileTypes: true,
    errorMessages: get(errors, "ventsPhotoFolder.FileCount"),
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire" &&
      !form.areLegacyFileUploadersRequired,
  } as WildfireBoxContentExplorerProps,
  buildFieldKey("AreRoofVentsFireResistant"),
  buildFieldKey("OtherNotes"),
];
const ventsRequiredFields = [
  buildFieldKey("VentsPhotoFolder"),
  buildFieldKey("AreRoofVentsFireResistant"),
];

export const roofFieldEvaluationVentsBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        ventsFields(form, false, errors),
        onValueChange,
        form,
        errors,
        ventsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Full form builder with all parts included
export const roofFieldEvaluationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        [
          ...coverMaterialFields(form),
          ...otherRoofCoverConditionsFields,
          ...guttersAndDownspoutsFields(form, false, errors),
          ...eavesAndSoffitsFields(form, false, errors),
          ...ventsFields(form, false, errors),
        ],
        onValueChange,
        form,
        errors,
        [
          ...coverMaterialRequiredFields,
          ...otherRoofCoverConditionsRequiredFields,
          ...guttersAndDownspoutsRequiredFields,
          ...eavesAndSoffitsRequiredFields,
          ...ventsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const wieRoofFieldEvaluationFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        [
          ...coverMaterialFields(form, true, errors),
          ...otherRoofCoverConditionsFields,
          ...guttersAndDownspoutsFields(form, true, errors),
          ...eavesAndSoffitsFields(form, true, errors),
          ...ventsFields(form, true, errors),
        ],
        onValueChange,
        form,
        errors,
        [
          ...coverMaterialRequiredFields,
          ...otherRoofCoverConditionsRequiredFields,
          ...guttersAndDownspoutsRequiredFields,
          ...eavesAndSoffitsRequiredFields,
          ...ventsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];

    if (beforeBuild) {
      fields = beforeBuild(fields);
    }

    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};
