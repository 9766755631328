import { Button, IconName } from "@blueprintjs/core";
import "./sidebarMenu.scss";
import { HelpPageMenuItems } from "../../pages/HelpCenterPage";

export type MenuItem = {
  id: HelpPageMenuItems;
  label: string;
  iconName: IconName;
  onClick: () => void;
};

export interface SidebarMenuSelectionProps {
  menuItems: MenuItem[];
  activeMenuItem: string; // Add this line
}

export const SidebarMenu: React.FC<SidebarMenuSelectionProps> = ({
  menuItems,
  activeMenuItem, // And this line
}) => {
  return (
    <div className="sidebar-menu">
      {menuItems.map((item) => (
        <div className="sidebar-menu__button-wrapper" key={item.id}>
          <Button
            intent="primary"
            active={item.id === activeMenuItem} // Compare the ids here
            minimal
            onClick={item.onClick}
            rightIcon={item.iconName}
          >
            {item.label}
          </Button>
        </div>
      ))}
    </div>
  );
};
