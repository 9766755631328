import { EvaluationCommentV2 } from "../../../components/IterationEngine/types/EvaluationCommentV2";
import wildfireBaseAPIClient from "../../wildfireBaseApiClient";

class WildfireEvaluationCommentApiClient {
  getEvaluationComments = async (id: string, evaluationFieldKey: string) => {
    return await wildfireBaseAPIClient.get<EvaluationCommentV2[]>(
      `v2/evaluation/${id}/field/${evaluationFieldKey}/comment`
    );
  };

  createEvaluationComment = async (
    evaluationId: string,
    evaluationFieldKey: string,
    evaluationFieldLabel: string,
    comment: string,
    isPublic: boolean
  ) => {
    return await wildfireBaseAPIClient.post<EvaluationCommentV2[]>(
      `v2/evaluation/${evaluationId}/field/${evaluationFieldKey}/comment`,
      {
        comment,
        evaluationFieldLabel,
        isPublic,
      }
    );
  };

  createEvaluationComments = async (
    evaluationId: string,
    evaluationFieldKey: string,
    evaluationFieldLabel: string,
    comments: string[]
  ) => {
    return await wildfireBaseAPIClient.post<EvaluationCommentV2[]>(
      `v2/evaluation/${evaluationId}/field/${evaluationFieldKey}/comments`,
      {
        comments,
        evaluationFieldLabel,
      }
    );
  };

  deleteEvaluationComment = async (
    evaluationId: string,
    evaluationFieldKey: string,
    evaluationCommentId: string
  ) => {
    return await wildfireBaseAPIClient.delete<EvaluationCommentV2>(
      `v2/evaluation/${evaluationId}/field/${evaluationFieldKey}/comment/${evaluationCommentId}`
    );
  };
}

export const wildfireEvaluationCommentApiClient =
  new WildfireEvaluationCommentApiClient();
