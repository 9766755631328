import React from "react";
import { Navigate, useNavigate, useOutlet } from "react-router";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import fortified_logo_multifamily from "../../../../../foritfied/assets/fortified_logo_multifamily.png";
import fortified_logo_commercial from "../../../../../foritfied/assets/fortified_logo_commercial.png";
import {
  AppHeader,
  AppSideBar,
  PrimaryCard,
  NavigationExpandModes,
  SidebarTypes,
  Dialogs as UCLDialogs,
} from "@ucl/library";
import "../styles.scss";
import {
  fortifiedCommercialMultifamilyRoutePrefix,
  uclRouteObject,
} from "../../../../../foritfied/pages/index/fortifiedCommercialMultifamilyRouteConfig";
import variables from "../../../../../common/config/variables";
import { fortifiedCommercialMultifamilyLogout } from "../../../../../common/lib/msal/fortifiedCommercialMultifamilyMsal";
import { fortifiedUserStore } from "../../../../../foritfied/stores/FortifiedUserStore";
import { UserAvatar } from "../../../../../common/Components/Avatar/UserAvatar";
import { Dialogs } from "../../../../../foritfied/components/Dialogs";

export interface PagesLayoutComponentProps {
  redirectPath: string;
}

const FCFMFPagesLayoutComponent: React.FC<PagesLayoutComponentProps> = (
  props
) => {
  const outlet = useOutlet();
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);

  //const { appHeader } = useContext(AppHeaderContext);

  const navigate = useNavigate();

  const ErrorFallback = (args: FallbackProps) => {
    return (
      <PrimaryCard headerContent="Something went wrong">
        <div role="alert">
          <pre>{args.error.message}</pre>
          <button onClick={args.resetErrorBoundary}>Try again</button>
        </div>
      </PrimaryCard>
    );
  };

  return (
    <>
      <div className="fc-fmf-page">
        <UCLDialogs />
        <Dialogs />
        <AppHeader
          theme={variables.theme}
          appLogo={
            <a
              onClick={() =>
                navigate(`${fortifiedCommercialMultifamilyRoutePrefix}`)
              }
            >
              <img src={fortified_logo_commercial} width={100} />
              <img src={fortified_logo_multifamily} width={100} />
            </a>
          }
          //appTitle={appHeader}
          breadcrumbSettings={{
            uclRouteObject: uclRouteObject(),
          }}
        >
          <UserAvatar
            logout={fortifiedCommercialMultifamilyLogout}
            userFullName={fortifiedUserStore.fullUserName}
            userDisplayRole={fortifiedUserStore.displayRole}
          />
        </AppHeader>
        <div className="content">
          {
            <AppSideBar
              uclRouteObject={uclRouteObject()}
              isOpen={false}
              onNavigationExpandChange={(sideBarOpen: boolean) => {
                if (sideBarOpen !== isSidebarOpen)
                  setIsSidebarOpen(sideBarOpen);
              }}
              showSearchBar={false}
              navigationExpandMode={NavigationExpandModes.AllClosed}
              dockSidebarType={SidebarTypes.Column}
            />
          }
          <div className="fc-fmf-page-main-content">
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <div>
                {outlet || <Navigate to={props.redirectPath} replace />}
              </div>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </>
  );
};

export const FCFMFPagesLayout = FCFMFPagesLayoutComponent;
