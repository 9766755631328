import { Footer, TextAreaField } from "@ucl/library";
import { FC, useState } from "react";
import "./styles.scss";
import WildfireSecondaryButton from "../../Button/DialogFooterButtons/SecondaryButton/SecondaryButton";
import WildfireSubmitButton from "../../Button/DialogFooterButtons/SubmitButton/SubmitButton";

export interface AuditorNotesUpdateFormProps {
  onCancel: () => void;
  onSubmit: (auditorNotes: string) => Promise<void>;
  auditorNotes?: string;
  canViewAuditorNotes: boolean;
  submitButtonText?: string;
  cancelButtonText?: string;
}

export const AuditorNotesUpdateForm: FC<AuditorNotesUpdateFormProps> = ({
  onCancel,
  onSubmit,
  auditorNotes,
  submitButtonText,
  cancelButtonText,
  canViewAuditorNotes,
}) => {
  const [localAuditorNotes, setAuditorNotes] = useState<string | undefined>(
    auditorNotes
  );

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const localsubmitButtonText = submitButtonText || "Submit";
  const localCancelButtonText = cancelButtonText || "Cancel";

  return (
    <div className="wildfire-auditor-notes-form">
      {canViewAuditorNotes && (
        <TextAreaField
          label="Auditor Notes"
          value={localAuditorNotes}
          onSubmit={(updatedAuditorNotes) => {
            setAuditorNotes(updatedAuditorNotes);
          }}
          disabled={isSaving}
        />
      )}
      <Footer>
        <WildfireSecondaryButton
          buttonText={localCancelButtonText}
          onClick={onCancel}
          disabled={isSaving}
        />
        <WildfireSubmitButton
          buttonText={localsubmitButtonText}
          isLoading={isSaving}
          onClick={async () => {
            setIsSaving(true);
            await onSubmit(localAuditorNotes || "");
            setIsSaving(false);
          }}
        />
      </Footer>
    </div>
  );
};
