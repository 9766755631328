import { Button, Intent } from "@blueprintjs/core";
import { FC, useState } from "react";
import { evaluationStore } from "../../../stores/EvaluationStore";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { RelativityTooltip } from "@ucl/library";

interface TraineeClaimEvaluationActionButtonProps {
  disabled: boolean;
}

const TraineeClaimEvaluationActionButton: FC<
  TraineeClaimEvaluationActionButtonProps
> = ({ disabled }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const claimEvaluation = async () => {
    setIsLoading(true);
    try {
      await evaluationStore.claimEvaluation();
      AppToaster.show({
        intent: Intent.SUCCESS,
        message: "Sucessfully claimed Evaluation for Trainee Audit",
        timeout: 5000,
      });
    } catch (err) {
      console.error(err);
      AppToaster.show({
        intent: Intent.DANGER,
        message:
          "Unexpected error occured while claiming Evaluation for Trainee Audit",
        timeout: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RelativityTooltip
      shouldShowTooltip={disabled}
      compact={true}
      className="trainee-claim-evaluation-tooltip-container"
      content={
        <div className="trainee-claim-evaluation-tooltip">
          This Evaluation has already been claimed.
        </div>
      }
    >
      <Button
        minimal
        intent={Intent.PRIMARY}
        text="Claim Evaluation"
        onClick={claimEvaluation}
        loading={isLoading}
        disabled={disabled}
      />
    </RelativityTooltip>
  );
};

export default TraineeClaimEvaluationActionButton;
