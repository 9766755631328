import { Classes, Dialog, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DialogFooter } from "./DialogFooter";
import { BaseDialogProps } from "./types";

export interface EvaluationCreationDialogProps extends BaseDialogProps {
  productType: string;
}

export const EvaluationCreationDialog: React.FC<
  EvaluationCreationDialogProps
> = ({ isOpen, closeDialog, submitDialog, productType }) => {
  return (
    <Dialog isOpen={isOpen} hasBackdrop={false}>
      <div className={Classes.DIALOG_HEADER}>
        <Icon icon={IconNames.TICK_CIRCLE} /> Confirmation
      </div>
      <div className={Classes.DIALOG_BODY}>
        {productType === "commercial" && (
          <div>
            <div>
              You will be starting a new FORTIFIED Application, continue?
            </div>
          </div>
        )}
        {productType === "home" && (
          <div>
            You will be starting a new FORTIFIED Home Evaluation, continue?
          </div>
        )}
      </div>
      <DialogFooter closeDialog={closeDialog} submitDialog={submitDialog} />
    </Dialog>
  );
};
