import { Button, Classes, Dialog } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import { Observer } from "mobx-react";
import React from "react";
import { fortifiedGridViewModuleStore } from "../../stores/FortifiedGridViewModuleStore";
import { FortifiedGridViewConfiguration } from "./FortifiedGridViewConfiguration";
import GridViewsAutocompleteComponent from "../../../ucl/components/GridViews/GridViewsAutocomplete";
import variables from "../../../common/config/variables";
import { get } from "lodash";
import "./styles.scss";
import { useProductTypeHandler } from "../../providers/ProductTypeHandlerProvider";

export interface FortifiedGridViewPickerProps {}
export const FortifiedGridViewPicker: React.FC<
  FortifiedGridViewPickerProps
> = () => {
  const { getAccessToken } = useProductTypeHandler();

  const [isPickerDialogOpen, setIsPickerDialogOpen] =
    React.useState<boolean>(false);

  const closeDialog = () => {
    setIsPickerDialogOpen(false);
  };

  return (
    <Observer>
      {() => (
        <section
          className={classNames("fortified-grid-views-picker", {
            ready: fortifiedGridViewModuleStore.currentGridView,
          })}
        >
          <>
            <div className="fortified-grid-views-picker-config">
              {fortifiedGridViewModuleStore.currentGridView?.gridId ===
                "board-grid" && (
                <GridViewsAutocompleteComponent
                  type="AsyncAutocomplete"
                  apiBaseUrl={variables.apiBaseUrl}
                  odataUrl={`${variables.apiBaseUrl}odata/usergridviewodata`}
                  getAuthRequestHeader={getAccessToken}
                  gridId={
                    fortifiedGridViewModuleStore.currentGridView?.gridId ?? ""
                  }
                  value={fortifiedGridViewModuleStore.currentGridView?.id}
                  onSubmit={(value) =>
                    fortifiedGridViewModuleStore.setCurrentGridView(
                      String(get(value, "value"))
                    )
                  }
                />
              )}
              <Button
                title="Board View Configuration"
                minimal={true}
                icon={IconNames.COG}
                disabled={!fortifiedGridViewModuleStore.currentGridView}
                onClick={() => {
                  !!fortifiedGridViewModuleStore.currentGridView &&
                    setIsPickerDialogOpen(true);
                }}
              >
                <span
                  className={classNames({
                    "bp4-skeleton":
                      !fortifiedGridViewModuleStore.currentGridView,
                  })}
                >{`${
                  fortifiedGridViewModuleStore.gridBaseSettings?.title ?? ""
                } View Configuration`}</span>
              </Button>
            </div>
            {fortifiedGridViewModuleStore.currentGridView && (
              <div className="fortified-grid-views-picker-dialog">
                <Dialog
                  isOpen={isPickerDialogOpen}
                  canEscapeKeyClose={false}
                  canOutsideClickClose={false}
                  hasBackdrop={false}
                  portalClassName="fortified-grid-views-picker-dialog"
                  title={`${fortifiedGridViewModuleStore.gridBaseSettings?.title} View Configuration`}
                  isCloseButtonShown={true}
                  onClose={() => setIsPickerDialogOpen(false)}
                >
                  <div className={Classes.DIALOG_BODY}>
                    <FortifiedGridViewConfiguration
                      viewId={fortifiedGridViewModuleStore.currentGridView.id}
                      onCancel={closeDialog}
                      onSubmit={closeDialog}
                    />
                  </div>
                </Dialog>
              </div>
            )}
          </>
        </section>
      )}
    </Observer>
  );
};
