import { FormGroup, NumericInput } from "@blueprintjs/core";
import { useState } from "react";
import { NumberField as InterationEngineNumberField } from "../../../types/viewModels/IterationEngineViewModel";
import { BaseFieldProps } from "../types";
import "./styles.scss";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";

export interface NumberFieldProps
  extends BaseFieldProps<number>,
    Omit<InterationEngineNumberField, "id" | "sortOrder" | "isHidden"> {}

export const NumberField: React.FC<NumberFieldProps> = ({
  fieldId,
  label,
  value,
  disabled,
  onSubmit,
  isEmpty,
  isSelectedField,
  hasActiveChangeRequest,
  isSelectedChangeRequest,
  isResovledChangeRequest,
  hasActiveInternalComment,
  isExternalView,
}) => {
  if (isEmpty(value)) {
    return null;
  }
  const [localValue, setLocalValue] = useState<number>(value as number);

  return (
    <FormGroup className="base-field numeric-input-field">
      <SelectableLabel
        fieldId={fieldId}
        name={label}
        value={localValue}
        isSelectedField={isSelectedField}
        hasActiveChangeRequest={hasActiveChangeRequest}
        isSelectedChangeRequest={isSelectedChangeRequest}
        isResovledChangeRequest={isResovledChangeRequest}
        hasActiveInternalComment={hasActiveInternalComment}
        shouldDisableFieldLabelClick={isExternalView}
      />
      <NumericInput
        disabled={disabled}
        name={label}
        defaultValue={localValue ?? ""}
        onValueChange={(value) => {
          setLocalValue(value);
        }}
        onBlur={() => onSubmit(localValue)}
      />
    </FormGroup>
  );
};
