import { ServiceOptionsInfoCard } from "./ServiceOptionsInfoCard";
import "./styles.scss";

export interface ServiceOptionsDisplayProps {
  canOnlyHaveSelfService?: boolean;
}
export const ServiceOptionsDisplay: React.FC<ServiceOptionsDisplayProps> = (
  props
) => {
  return (
    <div className="service-options-display-container">
      <ServiceOptionsInfoCard
        title="Standard DIY Service"
        details={[
          <>
            Complete the DIY work myself using the{" "}
            <a
              href="https://wildfireprepared.org/wp-content/uploads/WPH-How-To-Prepare-My-Home-Checklist.pdf"
              target="_blank"
              rel="noreferrer"
            >
              How-To Prepare My Home Checklist
            </a>
            .
          </>,
          "Submit photos for review to ensure I'm eligible for an evaluation.",
          "Final in-person evaluation to document the completed work.",
          "Wildfire Prepared Home designation certificate issued.",
        ]}
        price="$125 nonrefundable, application fee."
        bestFor="Homeowners preferring a hands-on approach with flexible timelines."
      />
      <ServiceOptionsInfoCard
        title="Full Service"
        details={[
          "Meet with a wildfire risk expert to guide you through Wildfire Prepared Home designation.",
          "Includes a treatment plan so you know exactly what is required to get designated.",
          "Save time and money with 6x designation success rate vs. DIY.",
          "Access a vetted contractor network to get help from quality pros.",
        ]}
        price={
          <ul>
            <li>$149 for initial consultations with no obligation</li>
            <li>$399 for final inspection (includes all fees)</li>
            <li>Discounts available</li>
          </ul>
        }
        bestFor="Homeowners seeking professional, quick, and comprehensive service."
        canOnlyHaveSelfService={props.canOnlyHaveSelfService}
      />
    </div>
  );
};
