import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { dialogsViewerStore } from "../../../stores/DialogsViewerStore";

interface ViewRejectionReasonsActionButtonProps {}

const ViewRejectionReasonsActionButton: React.FC<
  ViewRejectionReasonsActionButtonProps
> = () => {
  return (
    <Button
      text="Rejection Reasons"
      intent={Intent.DANGER}
      icon={IconNames.Disable}
      disabled={false}
      minimal
      onClick={() => {
        dialogsViewerStore.setIsRejectionReasonDialogOpen(true);
      }}
    />
  );
};

export default ViewRejectionReasonsActionButton;
