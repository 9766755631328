import { Icon } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";
import { ColumnModel } from "@syncfusion/ej2-react-grids";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import FortifiedGridColumn from "../../types/fortifiedGrid/FortifiedGridColumn";

interface GridClickableIconColumnOptions extends ColumnModel {
  columnName: string;
  icon: IconName;
  dialogType: DialogTypes;
}

type DialogTypes = "auditorNotes" | "evaluatorNote";

const openDialog = (
  dialogType: DialogTypes,
  fieldValue: string,
  modelId: string
) => {
  switch (dialogType) {
    case "auditorNotes":
      dialogsViewerStore.setIsAuditorNotesDialogOpen(true, {
        auditorNotes: fieldValue,
        evaluationId: modelId,
      });
      break;
    case "evaluatorNote":
      dialogsViewerStore.setIsEvaluatorNotesDialogOpen(true, {
        evaluatorNotes: fieldValue,
        evaluationId: modelId,
      });
      break;
    default:
      throw new Error(
        `Cannot create column with unexpected dialogType: ${dialogType}`
      );
  }
};

export default class GridClickableIconColumn extends FortifiedGridColumn {
  constructor(opts: GridClickableIconColumnOptions) {
    const { columnName, icon, dialogType, ...superOpts } = opts;
    super({
      ...superOpts,
      template: ((model: Record<string, string>) => {
        const fieldValue = model[columnName];
        const modelId = model["id"];
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              openDialog(dialogType, fieldValue, modelId);
            }}
          >
            <Icon className="base-grid-icon" icon={icon}></Icon>
            {fieldValue}
          </div>
        );
      }) as any,
    });
  }
}
