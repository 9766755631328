import {
  EmailFieldProps,
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
} from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { Evaluation_CommercialRedesignationFormModel } from "../../../types/CommercialRedesignationFormModel";

const modelName = "Evaluation";
const subModelName = "CommercialRedesignation";
const formName = "RedesignationEvaluatorEmailForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

//Part 1: Evaluator Email
const evaluatorEmailFields = () => [
  {
    fieldKey: buildFieldKey("EvaluatorEmail"),
  } as EmailFieldProps,
];

const requiredEvaluatorEmailFields = [buildFieldKey("EvaluatorEmail")];

export const commercialRedesignationEvaluatorEmailBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialRedesignationFormModel>(
        modelName,
        evaluatorEmailFields(),
        onValueChange,
        form,
        errors,
        requiredEvaluatorEmailFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel>;
};

// Full form builder with all parts included
export const commercialRedesignationEvaluatorEmailFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialRedesignationFormModel>(
        modelName,
        [...evaluatorEmailFields()],
        onValueChange,
        form,
        errors,
        [...requiredEvaluatorEmailFields],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const commercialIterationEngineRedesignationEvaluatorEmailFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialRedesignationFormModel>(
        modelName,
        [...evaluatorEmailFields()],
        onValueChange,
        form,
        errors,
        [...requiredEvaluatorEmailFields],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialRedesignationFormModel>;
};
