import { Button, Intent } from "@blueprintjs/core";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { evaluationStore } from "../../stores/EvaluationStore";
import { evaluationApiClient } from "../../lib/apiClients/evaluation/evaluationApiClient";
import { useState } from "react";

interface ProceedToFCFMFPaymentActionButtonProps {}

export const openBuildingRequiredFieldsIncompleteDialog = (
  setIsSaving: (isSaving: boolean) => void,
  buildingErrors: string[]
) => {
  setIsSaving(true);
  dialogsViewerStore.confirm({
    shouldHideCancelButton: true,
    confirmButtonText: "Close",
    confirmButtonIntent: "danger",
    confirmHeaderText: "Warning - All Required Building Fields Not Complete",
    content: (
      <>
        <span>
          The following field(s) must be complete prior to moving to Application
          Fee Payment:
          <ul>
            {buildingErrors.map((error, idx) => (
              <li key={idx}>{error}</li>
            ))}
          </ul>
        </span>
      </>
    ),
    onClose: () => {
      setIsSaving(false);
    },
    onConfirm: async () => {
      const result = {
        isSuccess: true,
        error: undefined,
      };
      setIsSaving(false);
      return result;
    },
  });
};

const ProceedToFCFMFPaymentActionButton: React.FC<
  ProceedToFCFMFPaymentActionButtonProps
> = () => {
  const [isSaving, setIsSaving] = useState(false);

  const handlePaymentButtonClick = async (action: () => void) => {
    setIsSaving(true);

    if (!!evaluationStore.evaluation?.isComplexCommercialEvaluation) {
      const buildingValidationDTO =
        await evaluationApiClient.getAreAllRequiredBuildingsFieldsComplete(
          evaluationStore.evaluation?.id || ""
        );

      if (!buildingValidationDTO.areAllRequiredBuildingFieldsFieldsFilledOut) {
        openBuildingRequiredFieldsIncompleteDialog(
          setIsSaving,
          buildingValidationDTO.buildingValidationErrors
        );
      } else {
        action();
      }
    } else {
      action();
    }

    setIsSaving(false);
  };

  if (evaluationStore.evaluation?.hasSufficientPaymentCredits) {
    return (
      <Button
        intent={Intent.PRIMARY}
        text="Proceed to Payment"
        loading={isSaving}
        onClick={async () => {
          setIsSaving(true);
          await handlePaymentButtonClick(() =>
            dialogsViewerStore.setIsApplyPaymentCreditDialogOpen(true)
          );
          setIsSaving(false);
        }}
      />
    );
  }

  return !!evaluationStore.paymentLink ? (
    <Button
      intent={Intent.PRIMARY}
      loading={isSaving}
      text="Proceed to Payment"
      onClick={async () => {
        setIsSaving(true);
        await handlePaymentButtonClick(() =>
          window.open(evaluationStore.paymentLink)
        );
        setIsSaving(false);
      }}
    />
  ) : null;
};

export default ProceedToFCFMFPaymentActionButton;
