import {
  Alignment,
  BreadcrumbProps,
  Breadcrumbs,
  Navbar,
} from "@blueprintjs/core";
import logo from "../../assets/Fortified_transparent.png";
import "./styles.scss";
import { UserAvatar } from "../../../common/Components/Avatar/UserAvatar";
import { fortifiedUserStore } from "../../stores/FortifiedUserStore";
import { useProductTypeHandler } from "../../providers/ProductTypeHandlerProvider";

export interface HeaderProps {
  fortifiedId?: string;
  address?: string;
  name?: string;
  breadCrumbs?: BreadcrumbProps[];
  renderActions?: () => React.ReactNode;
  renderWarning?: () => React.ReactNode;
}

export const buildHeading = (fortifiedId?: string, address?: string) => {
  return fortifiedId && address ? (
    <span>
      {address} (FORTIFIED ID {fortifiedId})
    </span>
  ) : (
    <span></span>
  );
};

export const Header: React.FC<HeaderProps> = ({
  fortifiedId,
  address,
  name,
  breadCrumbs,
  renderActions,
  renderWarning,
}) => {
  const { logoutUser } = useProductTypeHandler();
  return (
    <Navbar className="navbar">
      <Navbar.Group className="navbar_left" align={Alignment.LEFT}>
        <img src={logo} width={100} />
        <span className="navbar_left_title">{name}</span>
        {breadCrumbs && <Breadcrumbs items={breadCrumbs} />}
      </Navbar.Group>
      <Navbar.Group>{renderWarning && renderWarning()}</Navbar.Group>
      <Navbar.Group>
        <span className="navbar__checklist-title">
          {buildHeading(fortifiedId, address)}{" "}
        </span>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        {renderActions && <div>{renderActions()}</div>}
        <UserAvatar
          logout={logoutUser}
          userFullName={fortifiedUserStore.fullUserName}
          userDisplayRole={fortifiedUserStore.displayRole}
        />
      </Navbar.Group>
    </Navbar>
  );
};
