import { Observer } from "mobx-react";
import { evaluationStore } from "../../../../stores/EvaluationStore";
import ComplexCommercialEvaluationBuildingCard from "./ComplexCommercialEvaluationBuildingCard";
import "./styles.scss";
import AddBuildingsButton from "./AddBuildingsButton";
import { useComplexCommercialEvaluationBuildingsCard } from "./useComplexCommercialEvaluationBuildingsCard";
import ProceedToFCFMFPaymentActionButton from "../../../Checklist/ProceedToFCFMFPaymentActionButton";
import { PrimaryCard, RelativityTooltip } from "@ucl/library";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";

const ComplexCommercialEvaluationBuildingsCard: React.FC = () => {
  const { createBuilding, isSaving, isFetching } =
    useComplexCommercialEvaluationBuildingsCard();

  return (
    <Observer>
      {() => {
        return (
          <div>
            {evaluationStore.evaluation?.isComplexCommercialEvaluation && (
              <PrimaryCard
                id="complex-evaluation-buildings-card"
                className="complex-evaluation-buildings-card"
                headerContent={
                  <>
                    <h2>Buildings</h2>
                    <AddBuildingsButton
                      isLoading={isSaving}
                      onClick={async () => {
                        await createBuilding(
                          evaluationStore.evaluation?.id || ""
                        );
                      }}
                    />
                    <div className="flex-break"></div>
                    <p className="primary-card-description">
                      This section is where you can add, delete, and edit
                      building information.
                    </p>
                  </>
                }
              >
                {evaluationStore.evaluation
                  .areBuildingsFinishedBeingProcessed && !isFetching ? (
                  evaluationStore.complexCommercialEvaluationBuildings?.map(
                    (building, idx) => (
                      <ComplexCommercialEvaluationBuildingCard
                        key={building.id}
                        building={building}
                        buildingCount={idx + 1}
                        isSaving={isSaving}
                      />
                    )
                  )
                ) : (
                  <div className="buildings-loading-spinner">
                    <RelativityTooltip
                      placement="auto"
                      compact={true}
                      shouldShowTooltip={true}
                      interactionKind={"hover"}
                      content={"Buildings are being processed."}
                    >
                      <>
                        <Loading />
                        Buildings Are Being Processed...
                      </>
                    </RelativityTooltip>
                  </div>
                )}
                {evaluationStore.isFCFMFPaymentStep && (
                  <div className="fc-fmf-payment-button-container">
                    <ProceedToFCFMFPaymentActionButton />
                  </div>
                )}
              </PrimaryCard>
            )}
          </div>
        );
      }}
    </Observer>
  );
};

export default ComplexCommercialEvaluationBuildingsCard;
