import { useEffect, useState } from "react";
import { CreateCommercialEvaluationDialogProps } from "./CreateCommercialEvaluationDialog";
import { CommercialEvaluationOptions } from "../../../../types/schema";
import {
  EvaluationCreationOptions,
  EvaluationTypes,
} from "../../../../types/evaluation/EvaluationTypes";
import { EvaluationChild } from "../../../../types/evaluation/Evaluation";
import { evaluationApiClient } from "../../../../lib/apiClients/evaluation/evaluationApiClient";
import {
  navigateToEvaluationForm,
  openRedesignationFullyExpiredConfirmation,
  openRedesignationNotYetApprovedConfirmation,
  submitNewEvaluation,
} from "../CreateEvaluationHelpers";

export const useCreateCommercialEvaluationDialog = (
  props: CreateCommercialEvaluationDialogProps
) => {
  const [selectedRedesignation, setSelectedRedesignation] =
    useState<EvaluationChild>();
  const [isSaving, setIsSaving] = useState(false);
  const [isfetching, setIsfetching] = useState(false);
  const [selectedParentEvaluationOption, setParentEvaluationOption] =
    useState<CommercialEvaluationOptions>();
  const [creationType, setCreationType] = useState<EvaluationCreationOptions>(
    EvaluationCreationOptions.New
  );
  const [isRedegButtonDisabled, setIsRedegButtonDisabled] =
    useState<boolean>(true);

  const closeDialog = () => {
    setCreationType(EvaluationCreationOptions.New);
    setParentEvaluationOption(undefined);
    setSelectedRedesignation(undefined);
    setIsRedegButtonDisabled(true);
    props.closeDialog();
  };

  const isEvaluationFullyExpired =
    selectedRedesignation?.isEvaluationFullyExpired;

  const isEvaluationApproved = selectedRedesignation?.isApproved;

  // DEV Note - We need to come up with a clean strategy for implementing confirmation dialogs into our dialogs.
  const submitNewCommercialRedesignation = async () => {
    if (isEvaluationFullyExpired) {
      openRedesignationFullyExpiredConfirmation(setIsSaving);
    } else if (selectedParentEvaluationOption && !isEvaluationApproved) {
      openRedesignationNotYetApprovedConfirmation(
        () =>
          submitNewEvaluation(
            EvaluationTypes.CommercialReDesignation,
            selectedParentEvaluationOption.value,
            props.submitDialog,
            setIsSaving,
            closeDialog
          ),
        setIsSaving
      );
    } else {
      return submitNewEvaluation(
        EvaluationTypes.CommercialReDesignation,
        selectedParentEvaluationOption?.value || "",
        props.submitDialog,
        setIsSaving,
        closeDialog
      );
    }
  };

  const submitNewCommercialEvaluation = async () => {
    setIsSaving(true);

    await navigateToEvaluationForm(EvaluationTypes.Commercial)
      .then(() => {
        props.submitDialog();
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  useEffect(() => {
    const fetchMostRecentEvaluationChild = async () => {
      if (!!selectedParentEvaluationOption) {
        setIsRedegButtonDisabled(true);
        setIsfetching(true);
        const isEligibleForRedesignation =
          await evaluationApiClient.getMostRecentEvaluationChild(
            selectedParentEvaluationOption?.id
          );
        setSelectedRedesignation(isEligibleForRedesignation);
        setIsRedegButtonDisabled(false);
        setIsfetching(false);
      } else {
        setIsRedegButtonDisabled(true);
      }
    };

    fetchMostRecentEvaluationChild();
  }, [selectedParentEvaluationOption]);

  useEffect(() => {
    setParentEvaluationOption(undefined);
    setSelectedRedesignation(undefined);
    setIsRedegButtonDisabled(true);
  }, [creationType]);

  const selectableOptions = [
    { label: "New Evaluation", value: EvaluationCreationOptions.New },
    {
      label: "Redesignation",
      value: EvaluationCreationOptions.Child,
    },
  ];

  return {
    selectedRedesignation,
    setSelectedRedesignation,
    isSaving,
    selectedParentEvaluationOption,
    setParentEvaluationOption,
    creationType,
    setCreationType,
    isRedegButtonDisabled,
    setIsRedegButtonDisabled,
    closeDialog,
    selectableOptions,
    isfetching,
    setIsfetching,
    submitNewCommercialRedesignation,
    submitNewCommercialEvaluation,
  };
};
