import { useEffect, useState } from "react";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../common/Components/Error/ErrorStore";
import { Evaluation_MultifamilyRedesignation } from "../types/MultifamilyRedesignation";
import { multifamilyRedesignationAPIClient } from "../lib/apiClients/multifamilyRedesignationAPIClient";

export const useMultifamilyRedesignation = (evaluationId: string) => {
  const [multifamilyRedesignation, setMultifamilyRedesignation] =
    useState<Evaluation_MultifamilyRedesignation | null>();
  const [isApplicationLoading, setIsApplicationLoading] = useState(false);

  useEffect(() => {
    getCommercialEvaluation(evaluationId);
  }, [evaluationId]);

  const getCommercialEvaluation = async (evaluationId: string) => {
    await multifamilyRedesignationAPIClient
      .getMultifamilyRedesignation(evaluationId)
      .then((response) => {
        setMultifamilyRedesignation(response);
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      });
  };

  const refreshMultifamilyRedesignation = async () => {
    setIsApplicationLoading(true);
    if (multifamilyRedesignation?.id) {
      await multifamilyRedesignationAPIClient
        .getMultifamilyRedesignation(multifamilyRedesignation?.id)
        .then((response) => {
          setMultifamilyRedesignation(response);
          //setWildfireEvaluationHeader(response);
        })
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        })
        .finally(() => {
          setIsApplicationLoading(false);
        });
    }
  };

  return {
    multifamilyRedesignation,
    refreshCommercialRedesignation: refreshMultifamilyRedesignation,
    isApplicationLoading,
  };
};
