import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import MultifamilyEvaluationFieldInspectionFormContainer from "../components/forms/EvaluationFieldInspectionForm/Container/MultifamilyEvaluationFieldInspectionFormContainer";
import { FCFMFEvaluationFieldInspectionFormParts } from "../../common/types/Evaluation/BaseFCFMFEvaluationFieldInspectionForm";

export interface MultifamilyEvaluationFieldInspectionProps {
  evaluationId?: string;
  evaluationFieldInspectionFormPart?: FCFMFEvaluationFieldInspectionFormParts;
}

const MultifamilyEvaluationFieldInspectionFormPageComponent: FC<
  MultifamilyEvaluationFieldInspectionProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <MultifamilyEvaluationFieldInspectionFormContainer
            evaluationId={props.evaluationId}
            evaluationFieldInspectionFormPart={
              props.evaluationFieldInspectionFormPart
            }
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const MultifamilyEvaluationFieldInspectionFormPage = withAITracking(
  reactPlugin,
  MultifamilyEvaluationFieldInspectionFormPageComponent,
  "MultifamilyEvaluationFieldInspectionFormPageComponent",
  "tracking-container"
);
