import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import React from "react";
import { reactPlugin } from "../../AppInsights";
import { PageSidebar } from "../components/PageSidebar/PageSidebar";
import { AdminTabs } from "./AdminTabs";
import "./styles.scss";
import { Header } from "../components/Header/Header";
import { permissionStore } from "../stores/PermissionStore";

export const AdminPageComponent: React.FC = () => {
  return (
    <div className="fortified-page board-page">
      <Observer>{() => <Header />}</Observer>
      <div className="content">
        <PageSidebar activePage="admin" />
        <>
          {permissionStore.canViewAdminPage && (
            <div className="page-main-content">
              <AdminTabs />
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export const AdminPage = withAITracking(
  reactPlugin,
  AdminPageComponent,
  "AdminPage",
  "tracking-container"
);
