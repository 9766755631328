import moment from "moment";

export const parseDate = (date: string | Date) =>
  moment(date).format("MM/DD/YYYY");

export const parseDateTime = (date: string | Date) =>
  moment(date).format("MM/DD/YYYY hh:mm A");

export const parseLongDate = (date: string | Date) =>
  moment(date).format("dddd, MMMM Do YYYY, h:mm a");

export const areDatesEqual = (
  date: string | Date,
  dateToCompare: string | Date
) => moment(date).isSame(dateToCompare);
