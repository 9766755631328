import { Intent } from "@blueprintjs/core";
import { FieldFormV2Module } from "@ucl/library";
import { useFieldSchemaFactory } from "../../../customHooks/useFieldSchemaFactory";
import { useCallback, useEffect, useRef, useState } from "react";
import { wildfireEvaluationApiClient } from "../../../lib/apiClients/application/wildfireApplicationApiClient";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../common/Components/Error/ErrorStore";
import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { useNavigate } from "react-router";
import { EvaluationFormModel } from "../../../forms/EvaluationFormModel";
import { LandscapeFieldEvaluationFormParts } from "../../../forms/LandscapeFieldEvaluationForm/LandscapeFieldEvaluationFormModel";
import { LandscapeFieldEvaluationFormProps } from "./LandscapeFieldEvaluationForm";
import { OtherFieldEvaluationFormParts } from "../../../forms/OtherFieldEvaluationForm/OtherFieldEvaluationFormModel";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { getScrollContainer } from "../../../utils/getScrollContainer";

export function useLandscapeFieldEvaluationForm(
  props: LandscapeFieldEvaluationFormProps
) {
  const navigate = useNavigate();

  const { builders, wieBuilders } = useFieldSchemaFactory();
  const formRef = useRef<FieldFormV2Module<EvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!props.isWiePage && !!containerRef.current) {
      const scrollableParent = getScrollContainer(containerRef.current);

      scrollableParent?.scrollTo(0, 0);
    }
  }, [props.formPart]);

  const [
    landscapeFieldEvaluationFormModel,
    setLandscapeFieldEvaluationFormModel,
  ] = useState<EvaluationFormModel>();

  // Differentiates between save and submit
  let shouldSubmit = false;
  const setShouldSubmit = (value: boolean) => {
    shouldSubmit = value;
  };

  useEffect(() => {
    if (landscapeFieldEvaluationFormModel) {
      setLandscapeFieldEvaluationFormModel({
        ...landscapeFieldEvaluationFormModel,
      });
    }
  }, [wieBuilders]);

  const isFirstPartOfForm =
    props.formPart === Object.values(LandscapeFieldEvaluationFormParts)[0];

  const isLastPartOfForm =
    props.formPart === Object.values(LandscapeFieldEvaluationFormParts).pop();

  const fetchLandscapeFieldEvaluationForm = async () => {
    setIsLoading(true);
    await wildfireEvaluationApiClient
      .getLandscapeFieldEvaluationForm(props.wildfireEvaluationId)
      .then((response) => {
        setLandscapeFieldEvaluationFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!!props.formModel) {
      setLandscapeFieldEvaluationFormModel(props.formModel);
      setIsLoading(false);
    } else {
      fetchLandscapeFieldEvaluationForm();
    }
  }, [props.formModel]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (!!landscapeFieldEvaluationFormModel) {
      setLandscapeFieldEvaluationFormModel(landscapeFieldEvaluationFormModel);

      if (props.setFormModel) {
        props.setFormModel(landscapeFieldEvaluationFormModel);
      }
    }
  }, [landscapeFieldEvaluationFormModel]);

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: EvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormSubmit(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: EvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getLandscapeFieldEvaluationFormPartBuilder = () => {
    if (props.isWiePage) {
      return wieBuilders?.LandscapeFieldEvaluationForm;
    }
    switch (props.formPart) {
      case LandscapeFieldEvaluationFormParts.ZeroToFiveFeet:
        return builders?.LandscapeFieldEvaluationFormZeroToFiveFeetPart;
      case LandscapeFieldEvaluationFormParts.FiveToThirtyFeet:
        return builders?.LandscapeFieldEvaluationFormFiveToThirtyFeetPart;
      default:
        return builders?.LandscapeFieldEvaluationForm;
    }
  };

  const handleFormSubmit = async (
    form: EvaluationFormModel,
    fieldKey?: string
  ) => {
    setLandscapeFieldEvaluationFormModel(form);

    await (shouldSubmit
      ? wildfireEvaluationApiClient.submitLandscapeFieldEvaluationForm(
          form.wildfireApplication.id,
          form.wildfireApplication.landscapeFieldEvaluationForm,
          props.formPart
        )
      : wildfireEvaluationApiClient.updateLandscapeFieldEvaluationForm(
          form.wildfireApplication.id,
          form.wildfireApplication.landscapeFieldEvaluationForm,
          fieldKey
        )
    )
      .then(async (response) => {
        handleFormResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = (response: EvaluationFormModel) => {
    props.setHasUnsavedChanges(false);
    if (shouldSubmit) {
      setLandscapeFieldEvaluationFormModel(response);

      if (isLastPartOfForm || !props.formPart) {
        // Navigate to first part of next form
        navigate(
          `${wildfireRoutePrefix}/${
            props.wildfireEvaluationId
          }/other-field-evaluation-form/${Object.values(
            OtherFieldEvaluationFormParts
          )[0].valueOf()}`
        );
      } else {
        // Navigate to next part of form
        navigate(
          `${wildfireRoutePrefix}/${
            props.wildfireEvaluationId
          }/landscape-field-evaluation-form/${Object.values(
            LandscapeFieldEvaluationFormParts
          )[
            Object.values(LandscapeFieldEvaluationFormParts).indexOf(
              props.formPart
            ) + 1
          ].valueOf()}`
        );
      }
    } else {
      handlePartialResponseFormUpdate(response);
    }
  };

  const handlePartialResponseFormUpdate = (response: EvaluationFormModel) => {
    //This is used to combat save on blur issues. This only updates fields that are essetnial to the form.
    //These are set in the API response. Field Counts and sub section completion status
    setLandscapeFieldEvaluationFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        zeroToFiveFeetRequiredFieldCount,
        fiveToThirtyFeetRequiredFieldCount,
        submitted,
        zeroToFiveFeetSubmitted,
        fiveToThirtyFeetSubmitted,
        zeroToFiveFeetPhotosFolder,
        fiveToThirtyFeetPhotosFolder,
        isFiveToThirtyFeetComplete,
        isZeroToFiveFeetComplete,
      } = response.wildfireApplication.landscapeFieldEvaluationForm;

      return {
        ...prevState,
        wildfireApplication: {
          ...prevState.wildfireApplication,
          landscapeFieldEvaluationForm: {
            ...prevState.wildfireApplication.landscapeFieldEvaluationForm,
            zeroToFiveFeetRequiredFieldCount,
            fiveToThirtyFeetRequiredFieldCount,
            submitted,
            zeroToFiveFeetSubmitted,
            fiveToThirtyFeetSubmitted,
            zeroToFiveFeetPhotosFolder,
            fiveToThirtyFeetPhotosFolder,
            isFiveToThirtyFeetComplete,
            isZeroToFiveFeetComplete,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    landscapeFieldEvaluationFormModel,
    setShouldSubmit,
    isFirstPartOfForm,
    isLastPartOfForm,
    getLandscapeFieldEvaluationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
  };
}

export default useLandscapeFieldEvaluationForm;
