import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { FCFMFComplianceLetterParts } from "../../../common/types/Evaluation/BaseFCFMFComplianceLetter";
import { CommercialComplianceLetterFormModel } from "../../types/CommercialComplianceLetterFormModel";
import { Evaluation_CommercialEvaluationFormModel } from "../../types/CommercialEvaluationFormModel";

class CommercialEvaluationComplianceLetterAPIClient {
  updateCommercialComplianceLetter = async (
    evaluationId: string,
    commercialComplianceLetter: CommercialComplianceLetterFormModel,
    fieldKey?: string
  ): Promise<Evaluation_CommercialEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_CommercialEvaluationFormModel>(
      `/commercial/compliance-letter/${evaluationId}`,
      {
        commercialComplianceLetter,
        fieldKey,
      }
    );
  };

  submitCommercialComplianceLetter = async (
    wildfireApplicationId: string,
    complianceLetter: CommercialComplianceLetterFormModel,
    complianceLetterPart?: FCFMFComplianceLetterParts
  ): Promise<Evaluation_CommercialEvaluationFormModel> => {
    const complianceLetterPartValue = (
      complianceLetterPart ??
      (Object.values(
        FCFMFComplianceLetterParts
      ).pop() as FCFMFComplianceLetterParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_CommercialEvaluationFormModel>(
      `/commercial/compliance-letter/${wildfireApplicationId}/${complianceLetterPartValue}`,
      complianceLetter
    );
  };
}

export const commercialEvaluationComplianceLetterAPIClient =
  new CommercialEvaluationComplianceLetterAPIClient();
