import { useEffect, useState } from "react";

export const useFavicon = (defaultFavicon: string) => {
  const [faviconLink, setFaviconLink] = useState<string>(defaultFavicon);

  useEffect(() => {
    const favicon = document.getElementById(
      "ibhs-favicon"
    ) as HTMLLinkElement | null;

    if (favicon) {
      favicon.href = faviconLink;
    }
  }, []);

  return { setFaviconLink };
};
