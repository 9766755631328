import { Callout, Intent, NonIdealState } from "@blueprintjs/core";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { reactPlugin } from "../../AppInsights";
import { EvaluationContainer } from "../components/Evaluation/EvaluationContainer";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { PageSidebar } from "../components/PageSidebar/PageSidebar";
import { evaluationStore } from "../stores/EvaluationStore";
import "./styles.scss";
import { Header } from "../components/Header/Header";
import { useProductTypeHandler } from "../providers/ProductTypeHandlerProvider";

export interface EvaluationProps {}

const EvaluationComponent: React.FC<EvaluationProps> = () => {
  const { id } = useParams<{ id: string }>();

  const [isInitialized, setIsInitialized] = useState(false);

  const { routePrefix } = useProductTypeHandler();

  if (!id) {
    return <NonIdealState />;
  }

  const initialize = async () => {
    await evaluationStore.init(id);
    setIsInitialized(true);
  };

  useEffect(() => {
    initialize();

    return () => {
      evaluationStore.reset();
      setIsInitialized(false);
    };
  }, []);

  return (
    <div className="fortified-page">
      <Observer>
        {() => (
          <>
            <>
              <Header
                breadCrumbs={[
                  { href: `${routePrefix}`, text: "Board" },
                  {
                    text: evaluationStore.evaluationBreadcrumbText,
                  },
                ]}
                renderWarning={() =>
                  evaluationStore.isFortifiedHomeEvaluation &&
                  evaluationStore.evaluation
                    ?.otherEvaluationsHaveSameAddress && (
                    <Callout intent={Intent.WARNING}>
                      An Evaluation with this address already exists. Please
                      verify this is accurate.
                    </Callout>
                  )
                }
              />
              {!!evaluationStore.evaluation && isInitialized ? (
                <div className="content">
                  <PageSidebar activePage="home" />
                  <EvaluationContainer />
                </div>
              ) : (
                <Loading />
              )}
            </>
          </>
        )}
      </Observer>
    </div>
  );
};

export const Evaluation = withAITracking(
  reactPlugin,
  EvaluationComponent,
  "Board",
  "tracking-container"
);
