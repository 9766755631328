import { ReactNode } from "react";
import { PrimaryCard, PrimaryCardHeader } from "@ucl/library";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import "./styles.scss";

export interface InformationalBannerProps {
  title: string;
  content: ReactNode;
}

export const InformationalBanner: React.FC<InformationalBannerProps> = ({
  title,
  content,
}: InformationalBannerProps) => {
  return (
    <div className="informational-card-container">
      <PrimaryCard>
        <PrimaryCardHeader label="">
          <Icon
            className="informational-card-icon"
            icon={IconNames.INFO_SIGN}
          />
          {title}
        </PrimaryCardHeader>
        <div className="informational-card-body">{content}</div>
      </PrimaryCard>
    </div>
  );
};
