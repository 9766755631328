import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Classes, Popover2 } from "@blueprintjs/popover2";

interface SendForReviewActionButtonProps {
  shouldDisplaySendForReview: boolean;
  isSendForReviewDisabled: boolean;
  sendForReview: () => Promise<void>;
  errors?: string[];
}

const SendForReviewActionButton: React.FC<SendForReviewActionButtonProps> = ({
  shouldDisplaySendForReview,
  isSendForReviewDisabled,
  sendForReview,
  errors,
}) => {
  const shouldDisplaySendForReviewWithErrors =
    shouldDisplaySendForReview &&
    errors &&
    errors.length > 0 &&
    isSendForReviewDisabled === false;
  const shouldDisplaySendForReviewWithChangeRequest =
    shouldDisplaySendForReview &&
    shouldDisplaySendForReviewWithErrors === false;

  if (shouldDisplaySendForReviewWithErrors) {
    return (
      <Popover2
        interactionKind="hover"
        placement="bottom-end"
        portalClassName="tooltip-popover"
        popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
        content={
          <span>
            The following field(s) must be complete prior to moving forward:
            <ul>
              {errors?.map((error, idx) => (
                <li key={idx}>{error}</li>
              ))}
            </ul>
          </span>
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        renderTarget={({ isOpen, ...targetProps }) => (
          <div {...targetProps}>
            <Button
              icon={IconNames.KEY_ENTER}
              text="Send for Review"
              minimal
              intent={Intent.PRIMARY}
              disabled={true}
            />
          </div>
        )}
      />
    );
  }

  if (shouldDisplaySendForReviewWithChangeRequest) {
    return isSendForReviewDisabled ? (
      <Popover2
        interactionKind="hover"
        placement="bottom-end"
        portalClassName="tooltip-popover"
        popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
        content={
          <span>
            All change requests must be addressed before sending this for
            review. If you have addressed all change requests and sent this for
            review, then no further action is required at this time.
          </span>
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        renderTarget={({ isOpen, ...targetProps }) => (
          <div {...targetProps}>
            <Button
              icon={IconNames.KEY_ENTER}
              text="Send for Review"
              minimal
              intent={Intent.PRIMARY}
              onClick={async () => {
                await sendForReview();
              }}
              disabled={isSendForReviewDisabled}
            />
          </div>
        )}
      />
    ) : (
      <Button
        icon={IconNames.KEY_ENTER}
        text="Send for Review"
        minimal
        intent={Intent.PRIMARY}
        onClick={async () => {
          await sendForReview();
        }}
      />
    );
  }

  return null;
};

export default SendForReviewActionButton;
