import {
  FieldSchemaFormBuilderV2,
  Schema,
  SchemaFactoryV2,
} from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { FieldSchemas } from "@ucl/library/lib/components/FieldSchema/types";
import { ChangeRequestV2 } from "../../../../wildfire/components/IterationEngine/types/ChangeRequestV2";
import { transformFieldsForIterationEngineBeforeBuild } from "../util/transformFieldBeforeBuild";
import {
  FCFMFFieldSchemaModel,
  FCFMFFieldSchemaFormType,
  FCFMFFieldSchemaFormTypeList,
  fcfmfCustomFieldSchemaFormBuilders,
  commercialFieldSchemaConfig,
} from "../components/FieldSchema/fcfmfFieldSchemaConfig";
import {
  FCFMFIterationEngineFieldSchemaFormType,
  FCFMFIterationEngineFieldSchemaFormTypeList,
  fcfmfIterationEngineFieldSchemaFormBuilders,
} from "../components/FieldSchema/fcfmfIterationEngineFieldSchemaConfig";

export type FCFMFFieldSchemaType = {
  [key: string]: {
    associatedFieldKey?: string;
    fieldKey?: string;
  };
};

interface FieldSchemaFactoryContextProps {
  schemaFactory?: FCFMFSchemaFactoryV2;
  builders?: FieldSchemaBuilderConfig;
  wieBuilders?: FCFMFIterationEngineFieldSchemaBuilderConfig;
}

interface FieldSchemaFactoryProviderProps {
  schemaFactory: FCFMFSchemaFactoryV2;
  children: ReactNode;
  formOverrides?: FormOverrides;
}

export interface FormOverrides {
  onFieldChange?: (field: FCFMFFieldSchemaModel) => void;
  onLabelClick?: (fieldProps: BaseFieldProps<any>) => void;
  iterationEngineProps?: IterationEngineProps;
}

export interface FCFMFSchema extends Schema {
  schema: {
    Evaluation: FieldSchemas;
    [key: string]: FieldSchemas;
  };
  commercialFieldSchema: FCFMFFieldSchemaType;
}

export class FCFMFSchemaFactoryV2 extends SchemaFactoryV2 {
  schema: FCFMFSchema;

  constructor(loadedSchema: Schema | null) {
    super(loadedSchema);
    this.schema = loadedSchema as FCFMFSchema;
  }
}

interface IterationEngineProps {
  fieldKeysWithChangeRequests?: string[];
  shouldEnableForm: boolean;
  changeRequests?: ChangeRequestV2[];
  fieldsToEnable?: string[];
}

type FieldSchemaBuilderConfig = Record<
  FCFMFFieldSchemaFormType,
  Partial<FieldSchemaFormBuilderV2<FCFMFFieldSchemaModel>>
>;

type FCFMFIterationEngineFieldSchemaBuilderConfig = Record<
  FCFMFIterationEngineFieldSchemaFormType,
  Partial<FieldSchemaFormBuilderV2<FCFMFFieldSchemaModel>>
>;

const FieldSchemaFactoryContext = createContext<FieldSchemaFactoryContextProps>(
  {
    schemaFactory: undefined,
    builders: undefined,
  }
);

export const FieldSchemaFactoryProvider: React.FC<
  FieldSchemaFactoryProviderProps
> = (props) => {
  const [builders, setBuilders] = useState<FieldSchemaBuilderConfig>();
  const [wieBuilders, setWieBuilders] =
    useState<FCFMFIterationEngineFieldSchemaBuilderConfig>();

  const getBuilders = (factory: FCFMFSchemaFactoryV2) => {
    let builderList = {};
    for (const formIdx in FCFMFFieldSchemaFormTypeList) {
      if (
        fcfmfCustomFieldSchemaFormBuilders.hasOwnProperty(
          FCFMFFieldSchemaFormTypeList[formIdx] as FCFMFFieldSchemaFormType
        )
      ) {
        const customFieldSchemaBuilderConfig =
          fcfmfCustomFieldSchemaFormBuilders[
            FCFMFFieldSchemaFormTypeList[formIdx]
          ](factory, (fields: BaseFieldProps<any>[]) => {
            return fields.map((field) => {
              return field;
            });
          });
        builderList = {
          ...builderList,
          [FCFMFFieldSchemaFormTypeList[formIdx]]:
            customFieldSchemaBuilderConfig,
        };
      } else {
        const config =
          commercialFieldSchemaConfig[
            FCFMFFieldSchemaFormTypeList[formIdx] as FCFMFFieldSchemaFormType
          ];
        if (config) {
          const fieldSchemaBuilderConfig =
            factory.buildFieldSchemaFormBuilder<FCFMFFieldSchemaModel>(config);

          builderList = {
            ...builderList,
            [FCFMFFieldSchemaFormTypeList[formIdx]]: fieldSchemaBuilderConfig,
          };
        }
      }
    }
    setBuilders(builderList as FieldSchemaBuilderConfig);
  };

  const getWieBuilders = (factory: FCFMFSchemaFactoryV2) => {
    let builderList = {};
    for (const formIdx in FCFMFIterationEngineFieldSchemaFormTypeList) {
      if (
        fcfmfIterationEngineFieldSchemaFormBuilders.hasOwnProperty(
          FCFMFIterationEngineFieldSchemaFormTypeList[
            formIdx
          ] as FCFMFIterationEngineFieldSchemaFormType
        )
      ) {
        const wieFieldSchemaBuilderConfig =
          fcfmfIterationEngineFieldSchemaFormBuilders[
            FCFMFIterationEngineFieldSchemaFormTypeList[formIdx]
          ](
            factory,
            (fields: BaseFieldProps<any>[], shouldEnableForm?: boolean) => {
              // Transform fields for iteration engine
              return fields.map((field) => {
                const transformedField =
                  transformFieldsForIterationEngineBeforeBuild(
                    field,
                    props.formOverrides,
                    shouldEnableForm
                  );

                return transformedField;
              });
            }
          );

        builderList = {
          ...builderList,
          [FCFMFIterationEngineFieldSchemaFormTypeList[formIdx]]:
            wieFieldSchemaBuilderConfig,
        };
      }
    }
    setWieBuilders(builderList as FCFMFIterationEngineFieldSchemaBuilderConfig);
  };

  useEffect(() => {
    if (props.schemaFactory) {
      getBuilders(props.schemaFactory);
      getWieBuilders(props.schemaFactory);
    }
  }, [props.schemaFactory]);

  useEffect(() => {
    if (props.schemaFactory) {
      getWieBuilders(props.schemaFactory);
    }
  }, [
    props.formOverrides?.iterationEngineProps?.changeRequests,
    props.formOverrides?.iterationEngineProps?.fieldsToEnable,
  ]);

  return (
    <FieldSchemaFactoryContext.Provider
      value={{
        schemaFactory: props.schemaFactory,
        builders,
        wieBuilders,
      }}
    >
      {props.children}
    </FieldSchemaFactoryContext.Provider>
  );
};

export const useFCFMFFieldSchemaFactory = () =>
  useContext(FieldSchemaFactoryContext);
