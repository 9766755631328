import React from "react";
import { dialogsViewerStore } from "../../../stores/DialogsViewerStore";
import { Button } from "@blueprintjs/core";

interface LegacyEvaluationDataActionLinkProps {}

const LegacyEvaluationDataActionLink: React.FC<
  LegacyEvaluationDataActionLinkProps
> = ({}) => {
  return (
    <Button
      text="FOCUS 1.0 Data"
      minimal
      onClick={() =>
        dialogsViewerStore.setIsLegacyEvaluationDataDialogOpen(true)
      }
    />
  );
};

export default LegacyEvaluationDataActionLink;
