import React from "react";
import {
  AutoCompleteComponent,
  AutoCompleteModel,
  ChangeEventArgs,
  FieldSettingsModel,
  FilteringEventArgs,
  SelectEventArgs,
} from "@syncfusion/ej2-react-dropdowns";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base/src";
import { AsyncAutoCompleteFieldProps } from "./AsyncAutoCompleteField";
import { DataManager, Predicate, Query } from "@syncfusion/ej2-data";
import AsyncAutocompleteODataV4Adaptor, {
  inactiveCategory,
} from "./AsyncAutoCompleteODataV4Adaptor";
import { get } from "lodash";
import { AsyncAutocompleteFieldValue } from "../../types/fieldTypes";

export const useAsyncAutoCompleteField = (
  props: AsyncAutoCompleteFieldProps
) => {
  const autoCompleteRef = React.useRef<AutoCompleteComponent | null>(null);
  const [ready, setReady] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = React.useState<boolean>(false);
  const [instancePredicate, setInstancePredicate] = React.useState<
    Predicate | undefined
  >();
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [localValue, setLocalValue] =
    React.useState<AsyncAutocompleteFieldValue>(props.value);
  const [localData, setLocalData] = React.useState<any>();
  const [autoCompleteSettings, setAutoCompleteSettings] = React.useState<
    AutoCompleteModel & DefaultHtmlAttributes
  >();

  const [queryKey, setQueryKey] = React.useState<string>();

  const handleValueChange = (newValue: string) => {
    if (initialized && autoCompleteRef.current) {
      /**
       * Setting local value to detect change faster than rerendering,
       * allowing the UI to adjust styles like require as it occurs.
       */
      setLocalValue(newValue);
      autoCompleteRef.current.value = newValue;
    }
  };

  React.useEffect(() => {
    setQueryKey("initial-key");
  }, []);

  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
  }, [autoCompleteRef]);

  React.useEffect(() => {
    if (initialized && autoCompleteRef.current) {
      handleValueChange(props.value as string);
    }
  }, [props.value]);

  /** Observe and Save Filter Settings */
  React.useEffect(() => {
    if (initialized) {
      setup();
    }
  }, [queryKey]);

  React.useEffect(() => {
    const requestKey = JSON.stringify(props.autoCompleteSettings);
    if (requestKey !== queryKey) {
      setQueryKey(requestKey);
    }
  }, [props.autoCompleteSettings]);

  React.useEffect(() => {
    if (initialized) {
      props.onSubmit && props.onSubmit(localData);
    }
  }, [localData]);

  React.useEffect(() => {
    const predicate = props.autoCompleteSettings?.query?.queries.find(
      (x) => x.fn === "onWhere"
    )?.e as Predicate;
    setInstancePredicate(predicate);
  }, [props.autoCompleteSettings?.query?.queries]);

  const shouldShowSearchPopup =
    !props.autoCompleteSettings?.minLength ||
    (localValue && props.autoCompleteSettings?.minLength >= localValue.length);

  // Events
  const showSearchPopup = () => {
    if (
      !!autoCompleteRef.current &&
      initialized &&
      !props.disabled &&
      shouldShowSearchPopup
    ) {
      autoCompleteRef.current.showPopup();
    }
  };

  const handleSearchSelect = async (args: SelectEventArgs) => {
    if (get(args.itemData, "category") === inactiveCategory) {
      args.cancel = true;
      autoCompleteRef.current?.hidePopup();

      setTimeout(() => {
        if (!!autoCompleteRef.current && initialized) {
          autoCompleteRef.current.showPopup();
        }
      }, 100);
    } else {
      if (!!autoCompleteRef.current && initialized) {
        setLocalData(args.itemData);
      }
    }
  };

  const handleChange = async (args: ChangeEventArgs) => {
    if (!!autoCompleteRef.current && initialized && args.isInteracted) {
      if (!args?.value) {
        /** If localData is undefined it never triggers the update, in which case we trigger in this clause */
        if (!!localData) {
          setLocalData(undefined);
        } else {
          props.onSubmit && props.onSubmit(undefined);
        }
      } else {
        setLocalData(args.itemData);
      }
    }
  };

  const buildDefaultQuery = () => {
    const queryResultCount = props.autoCompleteSettings?.suggestionCount || 50;

    return new Query()
      .select(props.fieldNames || ["label", "value"])
      .sortBy(props.sortBy || "label")
      .take(queryResultCount);
  };

  const handleFiltering = (args: FilteringEventArgs) => {
    if (autoCompleteRef.current) {
      args.preventDefaultAction = true;

      let query = buildDefaultQuery();

      const containsPredicate = new Predicate(
        autoCompleteRef.current.fields.text || "label",
        "contains",
        args.text,
        true
      );

      if (!autoCompleteRef.current.query) {
        query = query.where(containsPredicate);
      } else {
        let predicate = containsPredicate;
        if (props.searchBy) {
          predicate = props.searchBy(args.text);
        }

        if (predicate) {
          if (instancePredicate) {
            query.where(predicate.and(instancePredicate));
          } else {
            query.where(predicate);
          }
        }
      }
      args.updateData(autoCompleteRef.current.dataSource, query);
    }
  };

  const defaultAutoCompleteSettings: AutoCompleteModel & DefaultHtmlAttributes =
    {
      autofill: true,
      showClearButton: true,
      highlight: true,
      allowCustom: false,
      fields: { text: "label", value: "value" },
      itemTemplate: "<table><tr><td>${label}</td></tr><table>",
      /** Inmutable */
      select: handleSearchSelect,
      focus: showSearchPopup,
      onClick: showSearchPopup,
      change: handleChange,
      filtering: handleFiltering,
    };

  // Setup
  const setupODataSource = (
    odataUrl: string,
    authRequestHeader?: Record<string, string> | undefined | string,
    fieldSettings?: FieldSettingsModel
  ): DataManager => {
    const dataManager = new DataManager({
      url: odataUrl,
      adaptor: new AsyncAutocompleteODataV4Adaptor(
        !!props.hasNotInListOption,
        !!props.groupByActiveStatus,
        fieldSettings
      ),
      crossDomain: true,
      headers: !!authRequestHeader
        ? [{ Authorization: `Bearer ${authRequestHeader}` }]
        : undefined,
    });
    return dataManager;
  };

  const setup = async () => {
    if (!queryKey) {
      return;
    }
    const authRequestHeader = await props.getAuthRequestHeader();
    if (!!authRequestHeader) {
      // Setup Properties
      const settings = {
        ...defaultAutoCompleteSettings,
        ...(props.autoCompleteSettings || {}),
      } as AutoCompleteModel & DefaultHtmlAttributes;

      /** Set Item Template Wrapper */
      settings.itemTemplate =
        "<span class='async-autocomplete-item async-autocomplete-item-disabled-${isDeleted}'>" +
        String(settings.itemTemplate) +
        "</span>";

      /** System not allows groupby if not in list option is set up */
      if (
        settings.fields &&
        (!!props.hasNotInListOption || !!props.groupByActiveStatus)
      ) {
        settings.fields.groupBy = "category";
      }

      const dm = setupODataSource(
        props.odataUrl,
        authRequestHeader,
        settings.fields
      );
      settings.dataSource = dm;
      settings.value = props.value;
      setAutoCompleteSettings(settings);
      setReady(true);
    }
  };

  return {
    autoCompleteRef,
    ready,
    isLoading,
    setIsLoading,
    isDataLoaded,
    setIsDataLoaded,
    localValue,
    setLocalValue,
    autoCompleteSettings,
    handleValueChange,
    buildDefaultQuery,
  };
};
