import {
  Button,
  Checkbox,
  Classes,
  Dialog,
  FormGroup,
  Intent,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { schemaStore } from "../../stores/AppSchemaStore";
import { BaseDialogProps, ConfirmDialogOptions } from "./types";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import {
  adminApiClient,
  AdminEvaluationAddressReadDTO,
  AdminEvaluationUpdateDTO,
} from "../../lib/apiClients/admin/adminApiClient";
import add from "date-fns/add";
import { AppToaster } from "../Toast/Toast";
import { permissionStore } from "../../stores/PermissionStore";
import { evaluationApiClient } from "../../lib/apiClients/evaluation/evaluationApiClient";
import { evaluationStore } from "../../stores/EvaluationStore";
import { genericErrorMessage } from "../../../common/Components/Error/ErrorStore";
import { AdminEvaluationSteps } from "../../types/evaluation/Evaluation";
import { MinimalSelectableLabel } from "../Fields/SelectableLabel/MinimalSelectableLabel";
import { useNavigate } from "react-router";
import {
  yesOrNoSelectionOptions,
  fromYesOrNoSelectionValue,
  toYesOrNoSelectionValue,
  YesOrNoSelectionOption,
  YesOrNoSelectionValue,
} from "../../types/evaluation/YesOrNoSelection";
import { useProductTypeHandler } from "../../providers/ProductTypeHandlerProvider";
import { DateField, SimpleAddressField } from "@ucl/library";
import { useAccount } from "@azure/msal-react";
import { SingleSelectField } from "../../../ucl/components/Fields/Dropdowns/SingleSelectField";
import { Option, OptionStringValue } from "../../types/schema";

interface AdminEvaluationUpdateFormModel {
  step: number;
  designationLevel: number;
  hazard: number;
  evaluatorId: string;
  auditorId: string;
  buildingAddress: AdminEvaluationAddressReadDTO;
  isNewRoof: number;
  expiresAt?: string;
  approvedAt?: string;
}

const defaultForm: AdminEvaluationUpdateFormModel = {
  evaluatorId: "",
  auditorId: "",
  designationLevel: 0,
  hazard: 0,
  step: 0,
  buildingAddress: {
    street: "",
    street2: undefined,
    city: "",
    state: 0,
    zip: "",
  },
  isNewRoof: 0,
  expiresAt: undefined,
};

export interface AdminDialogProps extends BaseDialogProps {}

export const AdminDialog: React.FC<AdminDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [toggleEvaluator, setToggleEvaluator] = useState(false);
  const [toggleAuditor, setToggleAuditor] = useState(false);
  const [toggleDesignationLevel, setToggleDesignationLevel] = useState(false);
  const [toggleHazard, setToggleHazard] = useState(false);
  const [toggleStatus, setToggleStatus] = useState(false);
  const [toggleAddress, setToggleAddress] = useState(false);
  const [toggleNewRoof, setToggleNewRoof] = useState(false);
  const [toggleApprovedDate, setToggleApprovedDate] = useState(false);
  const [toggleExpirationDate, setToggleExpirationDate] = useState(false);
  const [form, setForm] = useState(defaultForm);
  const [evaluators, setEvaluators] = useState<OptionStringValue[]>([]);
  const [auditors, setAuditors] = useState<OptionStringValue[]>([]);

  const navigate = useNavigate();
  const { routePrefix } = useProductTypeHandler();
  const account = useAccount();

  useEffect(() => {
    if (!!account) {
      adminApiClient.getEvaluators().then((evaluators) =>
        setEvaluators(
          evaluators.map((e) => ({
            label: `${e.firstName} ${e.lastName}`,
            value: e.id,
          }))
        )
      );
      adminApiClient.getAuditors().then((auditors) =>
        setAuditors([
          { label: "None", value: "0" },
          ...auditors.map((a) => ({
            label: `${a.firstName} ${a.lastName}`,
            value: a.id,
          })),
        ])
      );
    }
  }, []);

  useEffect(() => {
    if (dialogsViewerStore.adminDialogOpenOptions) {
      const { evaluationId } = dialogsViewerStore.adminDialogOpenOptions;

      adminApiClient
        .getAdminEvaluation(evaluationId)
        .then((adminEvaluation) => {
          setForm({
            ...defaultForm,
            ...adminEvaluation,
            isNewRoof: toYesOrNoSelectionValue(adminEvaluation.isNewRoof),
            auditorId: adminEvaluation.auditorId || "0",
          });
        });
    }
  }, [dialogsViewerStore.adminDialogOpenOptions?.evaluationId]);

  useEffect(() => {
    return () => {
      setToggleEvaluator(false);
      setToggleAuditor(false);
      setToggleDesignationLevel(false);
      setToggleHazard(false);
      setToggleStatus(false);
      setToggleAddress(false);
      setToggleNewRoof(false);
      setToggleApprovedDate(false);
      setToggleExpirationDate(false);
      setForm(defaultForm);
      setIsSaving(false);
    };
  }, [isOpen]);

  const deleteEvaluation = async (): Promise<void> => {
    if (!!dialogsViewerStore.adminDialogOpenOptions) {
      const { evaluationId } = dialogsViewerStore.adminDialogOpenOptions;
      const confirmOptions: ConfirmDialogOptions = {
        content: (
          <>
            Are you sure you want to delete this evaluation?
            {evaluationStore.isComplexCommercialEvaluation && (
              <>
                <br />
                <br />
                <strong>
                  This is a Complex Commercial evaluation, so deleting it will
                  also delete ALL of its Building evaluations!
                </strong>
              </>
            )}
          </>
        ),
        onConfirm: async () => {
          try {
            await evaluationApiClient.deleteEvaluation(evaluationId);
            closeDialog();
            navigate(`${routePrefix}`);
            return { isSuccess: true, error: null };
          } catch (error) {
            return { isSuccess: false, error };
          }
        },
        onClose: () => {
          closeDialog();
        },
        confirmHeaderText: "Confirm Delete",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
      };

      dialogsViewerStore.confirm(confirmOptions);
    } else {
      AppToaster.show({
        message: "Error occurred deleting evaluation.",
        intent: Intent.DANGER,
      });
    }
  };

  const openPaymentHasNotBeenProcessedDialog = (
    evaluationId: string,
    form: AdminEvaluationUpdateDTO,
    setIsSaving: (isSaving: boolean) => void,
    updateEvaluationAndSubmitDialog: (
      evaluationId: string,
      form: AdminEvaluationUpdateDTO,
      setIsSaving: (isSaving: boolean) => void
    ) => Promise<{ isSuccess: boolean; error: any }>
  ) => {
    setIsSaving(true);
    dialogsViewerStore.confirm({
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      confirmHeaderText: "Warning",
      content: (
        <>
          Payment has yet to be processed for this evaluation. Are you sure you
          would like to skip past this step?
        </>
      ),
      onClose: () => {
        setIsSaving(false);
      },
      onConfirm: async () => {
        return updateEvaluationAndSubmitDialog(evaluationId, form, setIsSaving);
      },
    });
  };

  const updateEvaluationAndSubmitDialog = async (
    evaluationId: string,
    form: AdminEvaluationUpdateDTO,
    setIsSaving: (isSaving: boolean) => void
  ) => {
    const result = { isSuccess: true, error: undefined };

    await adminApiClient
      .updateAdminEvaluation(evaluationId, form)
      .then(() => {
        submitDialog();
      })
      .catch((error) => {
        result.isSuccess = false;
        result.error = error;

        AppToaster.show({
          message: <div>{genericErrorMessage}</div>,
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsSaving(false);
      });

    return result;
  };

  return (
    <Dialog isOpen={isOpen} hasBackdrop={false}>
      <div className={Classes.DIALOG_HEADER}>Admin Dialog</div>
      <div className={Classes.DIALOG_BODY}>
        <form
          id="admin-form"
          onSubmit={async (e) => {
            e.preventDefault();

            if (!!dialogsViewerStore.adminDialogOpenOptions) {
              setIsSaving(true);

              const { evaluationId } =
                dialogsViewerStore.adminDialogOpenOptions;

              const formDTO: AdminEvaluationUpdateDTO = {
                ...form,
                isNewRoof: fromYesOrNoSelectionValue(
                  form.isNewRoof as YesOrNoSelectionValue
                ),
              };

              if (
                form.step === AdminEvaluationSteps.IBHSReview &&
                evaluationStore.doesEvaluationHaveRequiredPayment
              ) {
                openPaymentHasNotBeenProcessedDialog(
                  evaluationId,
                  formDTO,
                  setIsSaving,
                  updateEvaluationAndSubmitDialog
                );
              } else {
                updateEvaluationAndSubmitDialog(
                  evaluationId,
                  formDTO,
                  setIsSaving
                );
              }
            }
          }}
        >
          {permissionStore.canViewUpdateEvaluatorAdminButton && (
            <>
              <FormGroup className="base-field">
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <Checkbox
                    onChange={() => setToggleEvaluator(!toggleEvaluator)}
                  />
                  <MinimalSelectableLabel label={"Update Evaluator"} />
                </div>
              </FormGroup>
              {toggleEvaluator && (
                <SingleSelectField
                  type="SingleSelect"
                  label="Evaluator"
                  value={form.evaluatorId}
                  optionValues={evaluators}
                  onSubmit={(value) => {
                    const option = value as OptionStringValue;

                    setForm((prev) => ({
                      ...prev,
                      evaluatorId: option.value,
                    }));
                  }}
                />
              )}
            </>
          )}
          {evaluationStore.isFortifiedHomeEvaluation &&
            permissionStore.canViewUpdateAuditorAdminButton && (
              <>
                <FormGroup className="base-field">
                  <div style={{ display: "flex", alignItems: "baseline" }}>
                    <Checkbox
                      onChange={() => setToggleAuditor(!toggleAuditor)}
                    />
                    <MinimalSelectableLabel label={"Update Auditor"} />
                  </div>
                </FormGroup>
                {toggleAuditor && (
                  <SingleSelectField
                    type="SingleSelect"
                    label="Auditor"
                    value={form.auditorId}
                    optionValues={auditors}
                    onSubmit={(value) => {
                      const option = value as OptionStringValue;

                      setForm((prev) => ({
                        ...prev,
                        auditorId: option.value as string,
                      }));
                    }}
                  />
                )}
              </>
            )}
          {permissionStore.canViewUpdateDesignationLevelAdminButton && (
            <>
              <FormGroup className="base-field">
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <Checkbox
                    onChange={() =>
                      setToggleDesignationLevel(!toggleDesignationLevel)
                    }
                  />
                  <MinimalSelectableLabel label={"Update Designation Level"} />
                </div>
              </FormGroup>
              {toggleDesignationLevel && (
                <>
                  <SingleSelectField
                    type="SingleSelect"
                    label="Designation Level"
                    value={form.designationLevel}
                    optionValues={schemaStore.designationLevelOptions}
                    onSubmit={(value) => {
                      const option = value as Option;

                      setForm((prev) => ({
                        ...prev,
                        designationLevel: option.value as number,
                      }));
                    }}
                  />
                </>
              )}
            </>
          )}
          {permissionStore.canViewUpdateHazardAdminButton && (
            <>
              <FormGroup className="base-field">
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <Checkbox onChange={() => setToggleHazard(!toggleHazard)} />
                  <MinimalSelectableLabel label={"Update Hazard Type"} />
                </div>
              </FormGroup>
              {toggleHazard && (
                <SingleSelectField
                  type="SingleSelect"
                  label="Hazard Type"
                  value={form.hazard}
                  optionValues={schemaStore.hazardOptions}
                  onSubmit={(value) => {
                    const option = value as Option;

                    setForm((prev) => ({
                      ...prev,
                      hazard: option.value as number,
                    }));
                  }}
                />
              )}
            </>
          )}
          {permissionStore.canViewUpdateStatusAdminButton && (
            <>
              <FormGroup className="base-field">
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <Checkbox onChange={() => setToggleStatus(!toggleStatus)} />
                  <MinimalSelectableLabel label={"Update Status"} />
                </div>
              </FormGroup>
              {toggleStatus && (
                <SingleSelectField
                  type="SingleSelect"
                  label={"Status"}
                  description='Note: Moving to a status before "Payment" will require the evaluator to pay again.'
                  value={form.step}
                  optionValues={
                    evaluationStore.isFortifiedHomeEvaluation
                      ? schemaStore.homeAdminStatusOptions
                      : schemaStore.commercialAdminStatusOptions
                  }
                  onSubmit={(value) => {
                    const option = value as Option;

                    setForm((prev) => ({
                      ...prev,
                      step: option.value as number,
                    }));
                  }}
                />
              )}
            </>
          )}
          {permissionStore.canViewUpdateBuildingAddressAdminButton && (
            <>
              <FormGroup className="base-field">
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <Checkbox onChange={() => setToggleAddress(!toggleAddress)} />
                  <MinimalSelectableLabel label={"Update Building Address"} />
                </div>
              </FormGroup>
              {toggleAddress && (
                <SimpleAddressField
                  type="SimpleAddress"
                  label="Building Address"
                  value={{
                    ...form.buildingAddress,
                    state: form.buildingAddress.state?.toString(),
                  }}
                  description="The building address for the current evaluation."
                  onSubmit={(value) => {
                    setForm((prev) => ({
                      ...prev,
                      buildingAddress: {
                        ...value,
                        state: parseInt(value.state || ""),
                      },
                    }));
                  }}
                />
              )}
            </>
          )}
          {evaluationStore.isFortifiedHomeEvaluation &&
            permissionStore.canViewUpdateNewRoofAdminButton && (
              <>
                <FormGroup className="base-field">
                  <div style={{ display: "flex", alignItems: "baseline" }}>
                    <Checkbox
                      onChange={() => setToggleNewRoof(!toggleNewRoof)}
                    />
                    <MinimalSelectableLabel label={"Update New Roof"} />
                  </div>
                </FormGroup>
                {toggleNewRoof && (
                  <SingleSelectField
                    type="SingleSelect"
                    label="Is New Roof"
                    value={form.isNewRoof}
                    optionValues={yesOrNoSelectionOptions}
                    onSubmit={(option) => {
                      const value = (option as YesOrNoSelectionOption)?.value;
                      setForm((prev) => ({
                        ...prev,
                        isNewRoof: value,
                      }));
                    }}
                  />
                )}
              </>
            )}
          {permissionStore.canViewUpdateApprovedDateAdminButton && (
            <>
              <FormGroup className="base-field">
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <Checkbox
                    onChange={() => setToggleApprovedDate(!toggleApprovedDate)}
                  />
                  <MinimalSelectableLabel label={"Update Approved Date"} />
                </div>
              </FormGroup>
              {toggleApprovedDate && (
                <DateField
                  type="Date"
                  label="Approved Date"
                  value={form.approvedAt || ""}
                  maxDate={add(new Date(), {
                    years: 10,
                  })}
                  minDate={add(new Date(), {
                    years: -10,
                  })}
                  onSubmit={(value) => {
                    setForm((prev) => ({
                      ...prev,
                      approvedAt: value as string,
                    }));
                  }}
                />
              )}
            </>
          )}
          {permissionStore.canViewUpdateExpirationDateAdminButton && (
            <>
              <FormGroup className="base-field">
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <Checkbox
                    onChange={() =>
                      setToggleExpirationDate(!toggleExpirationDate)
                    }
                  />
                  <MinimalSelectableLabel label={"Update Expiration Date"} />
                </div>
              </FormGroup>
              {toggleExpirationDate && (
                <DateField
                  type="Date"
                  label="Expiration Date"
                  value={form.expiresAt || ""}
                  maxDate={add(new Date(), {
                    years: 10,
                  })}
                  minDate={add(new Date(), {
                    years: -10,
                  })}
                  onSubmit={(value) => {
                    setForm((prev) => ({
                      ...prev,
                      expiresAt: value as string,
                    }));
                  }}
                />
              )}
            </>
          )}
        </form>
      </div>
      {permissionStore.canDeleteEvaluations && (
        <Button
          minimal
          intent={Intent.DANGER}
          text={"Delete Evaluation"}
          onClick={deleteEvaluation}
        />
      )}
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text={"Cancel"}
          onClick={closeDialog}
        />
        <Button
          intent={Intent.SUCCESS}
          text={"Submit"}
          type="submit"
          form="admin-form"
          loading={isSaving}
        />
      </div>
    </Dialog>
  );
};
