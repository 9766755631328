import { dialogStore } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import { WildfireBoxContentExplorer } from "../../Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import "../styles.scss";
import "./styles.scss";

const dialogIdBase = "legacy-wildfire-evaluation-files-dialog";

const getDialogId = (wildfireApplicationId: string): string =>
  `${dialogIdBase}_${wildfireApplicationId}`;

const buildLegacyWildfireEvaluationFilesDialogProps = (
  wildfireApplicationId: string,
  externalFolderId?: string
): DialogProps => {
  return {
    id: getDialogId(wildfireApplicationId),
    className: dialogIdBase,
    header: "Legacy Wildfire Evaluation Files",
    allowDragging: false,
    content: () => (
      <WildfireBoxContentExplorer
        className="legacy-wildfire-evaluation-files-dialog__content-explorer"
        value={{
          rootFolderId: externalFolderId,
        }}
        disabled={false}
      />
    ),
  };
};

export const openLegacyWildfireEvaluationFilesDialog = (
  wildfireApplicationId: string,
  externalFolderId?: string
) =>
  dialogStore.openDialog(
    buildLegacyWildfireEvaluationFilesDialogProps(
      wildfireApplicationId,
      externalFolderId
    )
  );

export const closeAdminUpdateDialog = (wildfireApplicationId: string) =>
  dialogStore.closeDialog(getDialogId(wildfireApplicationId));
