import { FieldEvaluationFormPageProps } from "./FieldEvaluationFormPage";
import { RoofFieldEvaluationFormParts } from "../../forms/RoofFieldEvaluationForm/RoofFieldEvaluationFormModel";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../common/types/Forms/FormChecklist";
import { EvaluationFormModel } from "../../forms/EvaluationFormModel";

export interface RoofFieldEvaluationFormPartsProps {
  fieldEvalautionFormProps: FieldEvaluationFormPageProps;
  fieldEvaluationFormModel: EvaluationFormModel | undefined;
}

const useRoofFieldEvaluationFormParts = (
  props: RoofFieldEvaluationFormPartsProps
) => {
  const roofFormModel =
    props.fieldEvaluationFormModel?.wildfireApplication.roofFieldEvaluationForm;

  const getNextRoofUnsubmittedFormPart = () => {
    if (!roofFormModel) {
      return undefined;
    }

    if (!roofFormModel.isCoverMaterialComplete) {
      return RoofFieldEvaluationFormParts.CoverMaterial;
    } else if (!roofFormModel.isOtherRoofCoverConditionsComplete) {
      return RoofFieldEvaluationFormParts.OtherRoofCoverConditions;
    } else if (!roofFormModel.isGuttersAndDownspoutsComplete) {
      return RoofFieldEvaluationFormParts.GuttersAndDownspouts;
    } else if (!roofFormModel.isEavesAndSoffitsComplete) {
      return RoofFieldEvaluationFormParts.EavesAndSoffits;
    } else if (!roofFormModel.isVentsComplete) {
      return RoofFieldEvaluationFormParts.Vents;
    }
    // Form is complete
    return undefined;
  };

  const getRoofFieldEvaluationPartChecklistStatus = (
    formPart: RoofFieldEvaluationFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextRoofUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case RoofFieldEvaluationFormParts.CoverMaterial:
        return roofFormModel?.isCoverMaterialComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case RoofFieldEvaluationFormParts.OtherRoofCoverConditions:
        return roofFormModel?.isOtherRoofCoverConditionsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case RoofFieldEvaluationFormParts.GuttersAndDownspouts:
        return roofFormModel?.isGuttersAndDownspoutsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case RoofFieldEvaluationFormParts.EavesAndSoffits:
        return roofFormModel?.isEavesAndSoffitsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case RoofFieldEvaluationFormParts.Vents:
        return roofFormModel?.isVentsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const roofFieldEvaluationChecklistParts: FormChecklistPart<RoofFieldEvaluationFormParts>[] =
    [
      {
        formPart: RoofFieldEvaluationFormParts.CoverMaterial,
        displayName: "Cover Material",
        requiredFieldCount: roofFormModel?.coverMaterialRequiredFieldCount,
      },
      {
        formPart: RoofFieldEvaluationFormParts.OtherRoofCoverConditions,
        displayName: "Other Roof Cover Conditions",
        requiredFieldCount:
          roofFormModel?.otherRoofCoverConditionsRequiredFieldCount,
      },
      {
        formPart: RoofFieldEvaluationFormParts.GuttersAndDownspouts,
        displayName: "Gutters and Downspouts",
        requiredFieldCount:
          roofFormModel?.guttersAndDownspoutsRequiredFieldCount,
      },
      {
        formPart: RoofFieldEvaluationFormParts.EavesAndSoffits,
        displayName: "Eaves and Soffits",
        requiredFieldCount: roofFormModel?.eavesAndSoffitsRequiredFieldCount,
      },
      {
        formPart: RoofFieldEvaluationFormParts.Vents,
        displayName: "Vents",
        requiredFieldCount: roofFormModel?.ventsRequiredFieldCount,
      },
    ];

  return {
    roofFieldEvaluationChecklistParts,
    getRoofFieldEvaluationPartChecklistStatus,
  };
};

export default useRoofFieldEvaluationFormParts;
