import { ChangeRequest } from "../../../types/evaluation/ChangeRequest";
import {
  EvaluationChild,
  Evaluation,
  EvaluationRedesignationHistory,
  ComplexCommercialEvaluationBuilding,
  ComplexCommercialEvaluationBuildingRelationship,
  ComplexCommercialEvaluationBuildingValidationDTO,
} from "../../../types/evaluation/Evaluation";
import { EvaluationSchema } from "../../../types/evaluation/EvaluationSchema";
import { ExistingComment } from "../../../../common/apiClients/comments/types";
import { Option } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import baseApiClient from "../baseApiClient";
import { AuditorNotesDTO } from "../../../types/dtos/AuditorNotesDTO";
import { EvaluatorNotesDTO } from "../../../types/dtos/EvaluatorNotesDTO";

class EvaluationApiClient {
  createEvaluation = async () => {
    return baseApiClient.post<{ evaluation: Evaluation }>(`evaluation`);
  };

  deleteEvaluation = async (evaluationId: string) => {
    return await baseApiClient.delete<void>(
      `evaluation/${evaluationId}/delete`
    );
  };

  createHomeEvaluation = async () => {
    return baseApiClient.post<{ evaluation: Evaluation }>(
      `fortified-home/evaluation`
    );
  };

  createHomeRedesignationEvaluation = async (
    parentEvaluationId: string,
    evaluationExpirationDateType: number
  ) => {
    return baseApiClient.post<{ evaluation: Evaluation }>(
      `fortified-home/evaluation/${parentEvaluationId}/re-designation`,
      { evaluationExpirationDateType }
    );
  };

  createCommercialRedesignationEvaluation = async (
    parentEvaluationId: string
  ) => {
    return baseApiClient.post<{ evaluation: Evaluation }>(
      `evaluation/${parentEvaluationId}/re-designation`
    );
  };

  getEvaluationDetail = async (id: string) => {
    return await baseApiClient.get<{
      evaluation: Evaluation;
      schema: EvaluationSchema;
      error: { errors: string[] };
    }>(`evaluation/${id}/detail`);
  };

  getEvaluationDetailByFortifiedId = async (fortifiedId: string) => {
    return await baseApiClient.get<{
      evaluation: Evaluation;
      schema: EvaluationSchema;
      error: { errors: string[] };
    }>(`evaluation/${fortifiedId}/detail-by-fid`);
  };

  updateEvaluationItemData = async (
    evaluationId: string,
    evaluationItemId: string,
    evaluationItemDataId: string,
    value: string
  ) => {
    return await baseApiClient.put<{
      evaluation: Evaluation;
      changeRequest: ChangeRequest;
      error: { errors: string[] };
    }>(
      `evaluation/${evaluationId}/item/${evaluationItemId}/data/${evaluationItemDataId}`,
      { value }
    );
  };

  moveWorkflowToNextStep = async (evaluationId: string) => {
    return await baseApiClient.put<void>(
      `evaluation/${evaluationId}/workflow/next-step`
    );
  };

  sendTraineeAuditForReview = async (evaluationId: string) => {
    return await baseApiClient.post<void>(
      `evaluation/${evaluationId}/trainee-audit/send-for-review`
    );
  };

  returnTraineeAuditWithChangesRequired = async (evaluationId: string) => {
    return await baseApiClient.post<void>(
      `evaluation/${evaluationId}/trainee-audit/require-changes`
    );
  };

  approveEvaluation = async (evaluationId: string) => {
    return await baseApiClient.put<void>(`evaluation/${evaluationId}/approval`);
  };

  rejectEvaluation = async (
    evaluationId: string,
    denyReasons: Option<number>[],
    additionalNotes: string
  ) => {
    return await baseApiClient.put<Evaluation>(
      `evaluation/${evaluationId}/rejection`,
      { rejectionReasons: denyReasons, additionalNotes }
    );
  };

  sendRequestedChanges = async (evaluationId: string) => {
    return await baseApiClient.post<void>(
      `evaluation/${evaluationId}/requested-changes-email`
    );
  };

  sendChangesForReview = async (evaluationId: string) => {
    return await baseApiClient.post<void>(
      `evaluation/${evaluationId}/changes-for-review-email`
    );
  };

  withdrawEvaluation = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation>(
      `evaluation/${evaluationId}/withdraw`
    );
  };

  reactivateEvaluation = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation>(
      `evaluation/${evaluationId}/reactivate`
    );
  };

  claimEvaluation = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation>(
      `evaluation/${evaluationId}/claim`
    );
  };

  getEvaluationComments = async (evaluationId: string) => {
    return await baseApiClient.get<ExistingComment[]>(
      `evaluation/${evaluationId}/comments`
    );
  };

  applyPaymentCredit = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation>(
      `evaluation/${evaluationId}/apply-payment-credit`
    );
  };

  updateAuditorNotes = async (
    evaluationId: string,
    auditorNotesDTO: AuditorNotesDTO
  ) => {
    return await baseApiClient.put<Evaluation>(
      `evaluation/${evaluationId}/auditor-notes`,
      auditorNotesDTO
    );
  };

  updateEvaluatorNotes = async (
    evaluationId: string,
    evaluatorNotesDTO: EvaluatorNotesDTO
  ) => {
    return await baseApiClient.put<Evaluation>(
      `evaluation/${evaluationId}/evaluator-notes`,
      evaluatorNotesDTO
    );
  };

  getEvaluationRedesignations = async (evaluationId: string) => {
    return await baseApiClient.get<EvaluationRedesignationHistory[]>(
      `evaluation/${evaluationId}/relations`
    );
  };

  getComplexCommercialEvaluationBuildingRelationship = async (
    complexCommercialEvaluationId: string
  ) => {
    return baseApiClient.get<ComplexCommercialEvaluationBuildingRelationship>(
      `evaluation/${complexCommercialEvaluationId}/building-relationship`
    );
  };

  getEvaluationCertificate = async (evaluationId: string) => {
    return baseApiClient.downloadPost(
      `evaluation/${evaluationId}/get-certificate`
    );
  };

  regenerateEvaluationCertificate = async (evaluationId: string) => {
    return baseApiClient.downloadPost(
      `evaluation/${evaluationId}/regenerate-evaluation-certificate`
    );
  };

  getRooferConfirmationForm = async (fortifiedId: string) => {
    return baseApiClient.downloadGet(
      `evaluation/by-fid/${fortifiedId}/generate-roofing-confirmation-form`
    );
  };

  createPostDesignationBoxFolder = async (evaluationId: string) => {
    return baseApiClient.post<Evaluation>(
      `evaluation/${evaluationId}/create-post-designation-box-folder`
    );
  };

  getMostRecentEvaluationChild = async (evaluationId: string) => {
    return baseApiClient.get<EvaluationChild>(
      `evaluation/${evaluationId}/most-recent-evaluation-redesignation`
    );
  };
  resubmitEvaluation = async (evaluationId: string) => {
    return baseApiClient.post<Evaluation>(
      `evaluation/${evaluationId}/resubmit`
    );
  };

  createComplexCommercialEvaluationBuilding = async (
    complexCommercialEvaluationId: string
  ) => {
    return baseApiClient.post<ComplexCommercialEvaluationBuilding[]>(
      `evaluation/${complexCommercialEvaluationId}/complex-commercial-evaluation-building`
    );
  };

  getComplexCommercialEvaluationBuildings = async (
    complexCommercialEvaluationId: string
  ) => {
    return baseApiClient.get<ComplexCommercialEvaluationBuilding[]>(
      `evaluation/${complexCommercialEvaluationId}/complex-commercial-evaluation-buildings`
    );
  };

  getAreAllRequiredBuildingsFieldsComplete = async (
    complexCommercialEvaluationId: string
  ) => {
    return baseApiClient.get<ComplexCommercialEvaluationBuildingValidationDTO>(
      `evaluation/${complexCommercialEvaluationId}/are-all-required-buildings-fields-complete`
    );
  };

  getAreBuildingsFinishedBeingProcessed = async (
    complexCommercialEvaluationId: string
  ) => {
    return baseApiClient.get<boolean>(
      `evaluation/${complexCommercialEvaluationId}/are-buildings-finished-being-processed`
    );
  };
}

export const evaluationApiClient = new EvaluationApiClient();
