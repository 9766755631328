import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import "./styles.scss";
import { openEvaluatorAssignmentDialog } from "../../Dialogs/EvaluatorAssignment/EvaluatorAssignmentDialog";
import { useState } from "react";
import { wildfireEvaluationApiClient } from "../../../lib/apiClients/application/wildfireApplicationApiClient";
import { AppToaster } from "../../../../foritfied/components/Toast/Toast";
import { RelativityTooltip } from "@ucl/library";

export interface EvaluatorAssignmentButtonProps {
  evaluationId: string;
  onSubmit?: () => void;
  isEvaluationClaimedByCurrentUser: boolean;
}

export const EvaluatorAssignmentButton: React.FC<
  EvaluatorAssignmentButtonProps
> = ({
  evaluationId,
  onSubmit,
  isEvaluationClaimedByCurrentUser,
}: EvaluatorAssignmentButtonProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getEvaluationCompanies = async () => {
    try {
      return await wildfireEvaluationApiClient.getEvaluationCompanies(
        evaluationId
      );
    } catch (error) {
      AppToaster.show({
        message: "Failed to get evaluation companies",
        intent: Intent.DANGER,
      });
      console.error(error);
      return null;
    }
  };

  const handleClick = async () => {
    setIsLoading(true);
    const evaluationCompanies = await getEvaluationCompanies();
    if (evaluationCompanies) {
      openEvaluatorAssignmentDialog(
        evaluationId,
        evaluationCompanies,
        onSubmit
      );
    }
    setIsLoading(false);
  };

  return (
    <RelativityTooltip
      shouldShowTooltip={!isEvaluationClaimedByCurrentUser}
      content={"Claim Evaluation to assign an Evaluator"}
    >
      <Button
        minimal
        className="wildfire-evaluator-assignment-button"
        text="Evaluator Assignment"
        intent={Intent.PRIMARY}
        icon={IconNames.INHERITED_GROUP}
        disabled={!isEvaluationClaimedByCurrentUser}
        loading={isLoading}
        onClick={async () => {
          await handleClick();
        }}
      />
    </RelativityTooltip>
  );
};
