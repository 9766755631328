import { makeObservable, observable } from "mobx";
import React from "react";

export const genericErrorMessage = (
  <p>
    Sorry, something went wrong. If you continue to see this message, please
    reach out to support at:
    <br />
    <a
      href="https://fortifiedhome.org/focus-support-request/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Submit Fortified Home Support Request
    </a>
    <br />
    Thank you!
  </p>
);

export class ErrorStore {
  public errorMessage?: React.ReactNode;
  constructor() {
    makeObservable(this, {
      errorMessage: observable,
    });
  }

  setErrorMessage(errorMessage: React.ReactNode) {
    this.errorMessage = errorMessage;
  }
}

export const errorStore = new ErrorStore();
