import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Observer, observer } from "mobx-react";
import { useEffect } from "react";
import { evaluationChangeRequestStore } from "../../stores/EvaluationChangeRequestStore";
import { evaluationCommentStore } from "../../stores/EvaluationCommentStore";
import { evaluationStore } from "../../stores/EvaluationStore";
import { permissionStore } from "../../stores/PermissionStore";
import { fortifiedUserStore } from "../../stores/FortifiedUserStore";
import { EvaluationField } from "../../types/evaluation/Evaluation";
import { CommentInput } from "./CommentInput";
import { CommentListView } from "./CommentListView";
import "./styles.scss";
import {
  MessageComponent,
  Severity,
} from "@syncfusion/ej2-react-notifications";

export interface CommentContainerProps {}

export const CommentContainer: React.FC<CommentContainerProps> = observer(
  () => {
    useEffect(() => {
      return () => {
        evaluationStore.resetCommentContainer();
      };
    }, []);

    const shouldShowBanner =
      !evaluationChangeRequestStore.hasNoUnaddressedChangeRequests &&
      evaluationStore.shouldShowAutoRejectBanner;

    return evaluationStore.selectedField ? (
      <Observer>
        {() => (
          <div className="comment-pannel">
            {shouldShowBanner && (
              <MessageComponent
                className="message"
                content={`Evaluation will auto-reject in ${
                  evaluationChangeRequestStore.longestOutstandingChangeRequest
                    .daysTillEligibleForAutoRejection + 1
                } days, if all changes are not addressed`}
                severity={Severity.Warning}
                showCloseIcon={true}
              />
            )}

            <div className="comment-container">
              <CommentListView
                field={evaluationStore.selectedField as EvaluationField}
                currentPosition={
                  evaluationChangeRequestStore.currentChangeRequestPosition
                }
                changeRequestCount={
                  evaluationChangeRequestStore.unresolvedChangeRequest.length
                }
                comments={evaluationCommentStore.comments}
                changeRequest={
                  evaluationChangeRequestStore.selectedChangeRequest
                }
                navigateToChangeRequest={
                  evaluationChangeRequestStore.navigateToChangeRequest
                }
                isAuditor={fortifiedUserStore.isAuditor}
              />
              <CommentInput
                changeRequestId={
                  evaluationChangeRequestStore.selectedChangeRequest?.id
                }
                changeRequestStatus={
                  evaluationChangeRequestStore.selectedChangeRequest?.status
                }
                canProvideChangeRequestResolution={
                  permissionStore.canViewAuditorActionButtons
                }
                onSubmitComment={evaluationCommentStore.createComment}
                onResolveChangeRequest={
                  evaluationChangeRequestStore.resolveChangeRequest
                }
                onUnresolveChangeRequest={
                  evaluationChangeRequestStore.unresolveChangeRequest
                }
              />
            </div>
          </div>
        )}
      </Observer>
    ) : (
      <div className="comment-pannel">
        <div className="comment-container">
          <div className="comment-container__no-selection">
            <Icon icon={IconNames.SYMBOL_TRIANGLE_DOWN} /> Click on a field to
            request changes and make comments.
          </div>
        </div>
      </div>
    );
  }
);
