import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import CommercialEvaluationCompanyAssignmentFormContainer from "../components/forms/EvaluationCompanyAssignmentForm/Container/CommercialEvaluationCompanyAssignmentFormContainer";
import { FCFMFEvaluationCompanyAssignmentFormParts } from "../../common/types/Evaluation/BaseFCFMFEvaluationCompanyAssignmentForm";

export interface CommercialEvaluationCompanyAssignmentProps {
  evaluationId?: string;
  evaluationCompanyAssignmentFormPart?: FCFMFEvaluationCompanyAssignmentFormParts;
}

const CommercialEvaluationCompanyAssignmentFormPageComponent: FC<
  CommercialEvaluationCompanyAssignmentProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <CommercialEvaluationCompanyAssignmentFormContainer
            evaluationId={props.evaluationId}
            evaluationCompanyAssignmentFormPart={
              props.evaluationCompanyAssignmentFormPart
            }
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const CommercialEvaluationCompanyAssignmentFormPage = withAITracking(
  reactPlugin,
  CommercialEvaluationCompanyAssignmentFormPageComponent,
  "CommercialEvaluationCompanyAssignmentFormPageComponent",
  "tracking-container"
);
