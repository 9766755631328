import { difference } from "lodash";
import { ChangeRequest } from "../../foritfied/types/evaluation/ChangeRequest";
import { EvaluationField } from "../../foritfied/types/evaluation/Evaluation";
import { FormstackAddress } from "../../foritfied/types/formstack/fieldValueTypes/FormstackAddress";
import { FormstackName } from "../../foritfied/types/formstack/fieldValueTypes/FormstackName";
import { FormstackSmartListOption } from "../../foritfied/types/formstack/fieldValueTypes/FormstackSmartListOption";
import {
  CheckboxField,
  RadioField,
  SelectField,
} from "../../foritfied/types/viewModels/IterationEngineViewModel";

export const getOptionLabel = (
  value: string,
  options: FormstackSmartListOption[]
) => {
  return options.find((option) => option.value === value)?.label;
};

export const changeRequestHelpers: {
  [key: string]: {
    getValue?: (value: string, field?: EvaluationField) => string;
    getComment?: (
      changeRequest: ChangeRequest,
      field?: EvaluationField
    ) => React.ReactNode;
  };
} = {
  name: {
    getValue: (value: string) => {
      const nameObj: FormstackName = JSON.parse(value);
      return `First Name: ${nameObj.first}, Last Name: ${nameObj.last}`;
    },
  },
  checkbox: {
    getValue: (value: string, field?: EvaluationField) => {
      const checkboxObj: string[] = JSON.parse(value);
      return `${checkboxObj
        .map((checkbox) =>
          getOptionLabel(checkbox, (field as CheckboxField).optionValues)
        )
        .join(",")}`;
    },
  },
  select: {
    getValue: (value: string, field?: EvaluationField) => {
      const selectObj: string[] = JSON.parse(value);
      return `${getOptionLabel(
        selectObj[0],
        (field as SelectField).optionValues
      )}`;
    },
  },
  radio: {
    getValue: (value: string, field?: EvaluationField) => {
      return `${getOptionLabel(value, (field as RadioField).optionValues)}`;
    },
  },
  address: {
    getValue: (value: string) => {
      const form: FormstackAddress = JSON.parse(value);
      return `${form.address}${!!form.address2 ? `, ${form.address2}` : ""}, ${
        form.city
      }, ${form.state}, ${form.zip}`;
    },
  },
  embed: {
    getComment: (changeRequest) => {
      try {
        const newFiles: string[] = difference(
          JSON.parse(changeRequest.from),
          JSON.parse(changeRequest.to || "[]")
        );
        return (
          <span className="change-request-comment">
            Has uploaded{" "}
            <span className="change-request-comment_new">
              {newFiles.join(", ")}
            </span>
          </span>
        );
      } catch (e) {
        return null;
      }
    },
  },
};
