export interface NotifySubmitterFormLink {
  name: string;
  link: string;
}

export interface NotifySubmitterLinkProps {
  mailTo: string;
  address: string;
  fortifiedId: string;
  formLinks: NotifySubmitterFormLink[];
}

const nextParagraph = "\n\n";

export const buildLink = ({
  mailTo,
  address,
  fortifiedId,
  formLinks,
}: NotifySubmitterLinkProps) => {
  return `mailto:${mailTo}?${buildLinkSubject(
    address,
    fortifiedId
  )}&${buildLinkBody(formLinks, address, fortifiedId)}`;
};

const buildLinkSubject = (address: string, fortifiedId: string) =>
  `subject=FORTIFIED Compliance Form(s) for ${address} - ${fortifiedId}`;

const buildLinkBody = (
  formLinks: NotifySubmitterFormLink[],
  address: string,
  fortifiedId: string
) => {
  let body = "Hello,";
  body += nextParagraph;
  body += `Please complete the following form(s) for ${address} - ${fortifiedId} as soon as possible.`;
  body += nextParagraph;
  body += formLinks
    .map((formLink) => `${formLink.name} - ${formLink.link}`)
    .join(nextParagraph);
  body += nextParagraph;
  body += "Thank you!";

  return `body=${encodeURIComponent(body)}`;
};
