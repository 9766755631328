import "./styles.scss";

export interface RelativityCardHeaderProps {
  label: string;
  children?: React.ReactNode;
}
export const RelativityCardHeader: React.FC<RelativityCardHeaderProps> = (
  props
) => {
  return (
    <div className="relativity-card-header">
      <h4 className="bp4-heading">{props.label}</h4>
      {props.children}
    </div>
  );
};
