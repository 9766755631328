import {
  AddressFieldValue,
  EmailFieldValue,
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
  NumericFieldValue,
  PhoneNumberFieldValue,
  SingleCheckboxFieldValue,
  TextAreaFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum ApplicationFormParts {
  PropertyInformation = "property-information",
  ServiceOptions = "service-options",
  Eligibility = "eligibility",
  Payment = "payment",
  PhotoRequirements = "photo-requirements",
}

export interface ApplicationFormModel {
  // Relationships
  wildfireApplicationEvaluationId: string;

  // Metadata
  propertyInformationRequiredFieldCount: number;
  serviceOptionsRequiredFieldCount: number;
  eligibilityRequiredFieldCount: number;
  photoRequirementsRequiredFieldCount: number;

  propertyInformationSubmitted: boolean;
  serviceOptionsSubmitted: boolean;
  eligibilitySubmitted: boolean;
  photoRequirementsSubmitted: boolean;
  submitted: boolean;

  // Part 1: Property Information
  howDidYouHearAboutUs: EnumSingleSelectFieldValue;
  howDidYouHearAboutUsOther: TextAreaFieldValue;

  ownershipAttestation: SingleCheckboxFieldValue;

  hasCoOwner: EnumRadioFieldValue;
  coOwnerFirstName: TextFieldValue;
  coOwnerLastName: TextFieldValue;
  coOwnerEmail: EmailFieldValue;
  coOwnerPhoneNumber: PhoneNumberFieldValue;

  hasSecondaryContact: EnumRadioFieldValue;
  secondaryContactFirstName: TextFieldValue;
  secondaryContactLastName: TextFieldValue;
  secondaryContactEmail: EmailFieldValue;
  secondaryContactPhoneNumber: PhoneNumberFieldValue;

  roofInstallationYearKnown: EnumRadioFieldValue;
  roofInstallationYear: NumericFieldValue;
  homeConstructionYearKnown: EnumRadioFieldValue;
  homeConstructionYear: NumericFieldValue;
  streetAddress: AddressFieldValue;

  hasHotTub: EnumRadioFieldValue;
  hasStationaryPropaneTank: EnumRadioFieldValue;

  hasGate: EnumRadioFieldValue;
  gateCode?: TextAreaFieldValue;
  hasPets: EnumRadioFieldValue;
  petRestraintAttestation?: SingleCheckboxFieldValue;
  needsAppointment: EnumRadioFieldValue;
  appointmentPhoneNumber: PhoneNumberFieldValue;
  otherPropertyNotes: TextAreaFieldValue;

  // Part 2: Service Options
  serviceType: EnumRadioFieldValue;

  // Part 3: Eligibility
  selfServiceWorkAttestation: SingleCheckboxFieldValue;
  selfServicePhotoAttestation: SingleCheckboxFieldValue;
  selfServiceNonRefundabilityAttestation: SingleCheckboxFieldValue;

  // Part 4: Photo Requirements
  frontPhotoFolder: WildfireBoxContentExplorerValue;
  rightSidePhotoFolder: WildfireBoxContentExplorerValue;
  backPhotoFolder: WildfireBoxContentExplorerValue;
  leftSidePhotoFolder: WildfireBoxContentExplorerValue;

  lastMadronusSubmissionAt: Date;
  madronusRedirectUrl: string;

  //Internal Fields
  canOnlyHaveSelfService: boolean;
  allRequiredFieldsCompleted: boolean;
  hasPaymentBeenSubmitted: boolean;
}
