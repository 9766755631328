import { fortifiedHomeRoutePrefix } from "../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { fortifiedCommercialMultifamilyRoutePrefix } from "../../foritfied/pages/index/fortifiedCommercialMultifamilyRouteConfig";
import fortified_logo_home from "../../foritfied/assets/fortified_logo_home.png";
import fortified_logo_commercial from "../../foritfied/assets/fortified_logo_commercial.png";
import fortified_logo_multifamily from "../../foritfied/assets/fortified_logo_multifamily.png";
import "./styles.scss";
import { PrimaryCard, SecondaryCard } from "@ucl/library";
import { wildfireRoutePrefix } from "../../wildfire/pages/index/wildfireRouteConfig";
import wildfire_logo from "../../wildfire/assets/wildfire_logo.png";

const ProductTypeNavigator = () => {
  return (
    <div className="ibhs-products-container">
      <h1>Please select an IBHS product to continue</h1>
      <PrimaryCard>
        <SecondaryCard>
          <a
            className="ibhs-products-container__product"
            href={`${fortifiedHomeRoutePrefix}`}
          >
            <h2>Fortified Home</h2>
            <img
              src={fortified_logo_home}
              alt="Fortified Home"
              width={380}
              height={180}
            />
          </a>
        </SecondaryCard>
        <SecondaryCard>
          <a
            className="ibhs-products-container__product"
            href={`${fortifiedCommercialMultifamilyRoutePrefix}`}
          >
            <h2>Fortified Commercial & Multifamily</h2>
            <div>
              <img
                src={fortified_logo_commercial}
                alt="Fortified Commercial"
                width={380}
                height={180}
              />
              <img
                src={fortified_logo_multifamily}
                alt="Fortified Home"
                width={380}
                height={180}
              />
            </div>
          </a>
        </SecondaryCard>
        <SecondaryCard>
          <a
            className="ibhs-products-container__product"
            href={`${wildfireRoutePrefix}`}
          >
            <h2>Wildfire Prepared</h2>
            <img
              src={wildfire_logo}
              alt="Wildfire Prepared"
              width={380}
              height={180}
            />
          </a>
        </SecondaryCard>
      </PrimaryCard>
    </div>
  );
};

export default ProductTypeNavigator;
