import {
  EmailFieldProps,
  EnumRadioFieldProps,
  EnumSingleSelectFieldProps,
  FieldSchemaFormBuilderV2,
  NumericFieldProps,
  PhoneNumberFieldProps,
  SchemaFactoryV2,
  SingleCheckboxFieldProps,
  SmartAddressFieldProps,
  TextFieldProps,
} from "@ucl/library";
import { Evaluation_CommercialEvaluationFormModel } from "../../../types/CommercialEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { YesOrNoSelection } from "../../../../../../wildfire/types/FormFields";
import { ASCECodeTypes } from "../../../../common/types/Fields/FCFMFFieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";
import { addressApiClient } from "../../../../../../foritfied/lib/apiClients/address/addressApiClient";

const modelName = "Evaluation";
const subModelName = "CommercialEvaluation";
const formName = "CommercialEvaluationApplicationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

//Part 1: Application Information
const applicationInformationFields = () => [
  buildFieldKey("DesignationLevel"),
  buildFieldKey("ApplicantFirstName"),
  buildFieldKey("ApplicantLastName"),
  buildFieldKey("BusinessName"),
  {
    fieldKey: buildFieldKey("BusinessAddress"),
    getAddressesByQuery: addressApiClient.getAddressesByQuery,
    getAddressByQuery: addressApiClient.getAddressByQuery,
    isSmartOnly: false,
  } as SmartAddressFieldProps,
  buildFieldKey("BusinessPhoneNumber"),
];

const applicationInformationRequiredFields = [
  buildFieldKey("DesignationLevel"),
  buildFieldKey("ApplicantFirstName"),
  buildFieldKey("ApplicantLastName"),
  buildFieldKey("BusinessName"),
  buildFieldKey("BusinessAddress"),
  buildFieldKey("BusinessPhoneNumber"),
];

export const commercialApplicationInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        applicationInformationFields(),
        onValueChange,
        form,
        errors,
        applicationInformationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};

//Part 2: Email Notifications

const emailNotificationsFields = (
  form: Evaluation_CommercialEvaluationFormModel
) => [
  buildFieldKey("AreYouTheBuildingOwner"),
  {
    fieldKey: buildFieldKey("BusinessEmail"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .areYouTheBuildingOwner === YesOrNoSelection.No,
  } as EmailFieldProps,
  {
    fieldKey: buildFieldKey("Title"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .areYouTheBuildingOwner === YesOrNoSelection.Yes,
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("CompanyName"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .areYouTheBuildingOwner === YesOrNoSelection.Yes,
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("Phone"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .areYouTheBuildingOwner === YesOrNoSelection.Yes,
  } as PhoneNumberFieldProps,
  {
    fieldKey: buildFieldKey("Email"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .areYouTheBuildingOwner === YesOrNoSelection.Yes,
  } as EmailFieldProps,
];

const emailNotificationsRequiredFields = [
  buildFieldKey("AreYouTheBuildingOwner"),
  buildFieldKey("BusinessEmail"),
  buildFieldKey("Title"),
  buildFieldKey("CompanyName"),
  buildFieldKey("Phone"),
  buildFieldKey("Email"),
];

export const commercialEmailNotificationsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        emailNotificationsFields(form),
        onValueChange,
        form,
        errors,
        emailNotificationsRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};

//Part 3 General Project Details
const generalProjectDetailsFields = (
  _form: Evaluation_CommercialEvaluationFormModel
) => [
  buildFieldKey("IsThisANewConstruction"),
  buildFieldKey("PropertyHasMoreThanOneBuilding"),
];

const generalProjectDetailsRequiredFields = [
  buildFieldKey("IsThisANewConstruction"),
  buildFieldKey("PropertyHasMoreThanOneBuilding"),
];

export const commercialGeneralProjectDetailsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        generalProjectDetailsFields(form),
        onValueChange,
        form,
        errors,
        generalProjectDetailsRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};

//Part 4 New Constructions And New Additions
const newConstructionsAndNewAdditionsFields = (
  form: Evaluation_CommercialEvaluationFormModel
) => [
  {
    fieldKey: buildFieldKey("ProjectPhaseType"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .isThisANewConstruction === YesOrNoSelection.No,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("WoodFrameRoofEligibilityAcknowledgment"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .isThisANewConstruction === YesOrNoSelection.No,
  } as SingleCheckboxFieldProps,
];

const newConstructionsAndNewAdditionsRequiredFields = [
  buildFieldKey("ProjectPhaseType"),
  buildFieldKey("ApproxSizeSqFt"),
  buildFieldKey("ApproxHeightFt"),
  //Commercial Specific
  buildFieldKey("WoodFrameRoofEligibilityAcknowledgment"),
];

export const commercialNewConstructionsAndNewAdditionsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        newConstructionsAndNewAdditionsFields(form),
        onValueChange,
        form,
        errors,
        newConstructionsAndNewAdditionsRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};

//Part 5 Existing Construction - Reroofing
const existingConstructionReroofingFields = (
  form: Evaluation_CommercialEvaluationFormModel
) => [
  {
    fieldKey: buildFieldKey("YearCompleted"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .isThisANewConstruction === YesOrNoSelection.Yes,
    format: "0000",
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("YearsOccupied"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .isThisANewConstruction === YesOrNoSelection.Yes,
    format: "0",
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("HeightFts"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .isThisANewConstruction === YesOrNoSelection.Yes &&
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .propertyHasMoreThanOneBuilding === YesOrNoSelection.Yes,
    format: "0",
  } as NumericFieldProps,
];

const existingConstructionReroofingRequiredFields = [
  buildFieldKey("YearCompleted"),
  buildFieldKey("YearsOccupied"),
  buildFieldKey("HeightFts"),
];

export const commercialExistingConstructionReroofingBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        existingConstructionReroofingFields(form),
        onValueChange,
        form,
        errors,
        existingConstructionReroofingRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};

//Part 6 Project Construction Details
const projectConstructionDetailsFields = (
  form: Evaluation_CommercialEvaluationFormModel
) => [
  buildFieldKey("ProjectOrBuildingName"),
  {
    fieldKey: buildFieldKey("Address"),
    getAddressesByQuery: addressApiClient.getAddressesByQuery,
    getAddressByQuery: addressApiClient.getAddressByQuery,
    isSmartOnly: false,
  } as SmartAddressFieldProps,
  buildFieldKey("HazardType"),
  buildFieldKey("HasHailSupplement"),
  buildFieldKey("IbcVersionType"),
  buildFieldKey("AsceCodeType"),
  {
    fieldKey: buildFieldKey("ImportanceFactorType"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .asceCodeType !== ASCECodeTypes.PreASCE702 &&
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .asceCodeType !== ASCECodeTypes.ASCE702 &&
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .asceCodeType !== ASCECodeTypes.ASCE705,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("RiskCategoryType"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .asceCodeType !== ASCECodeTypes.ASCE710 &&
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .asceCodeType !== ASCECodeTypes.ASCE716,
  } as EnumRadioFieldProps,
  buildFieldKey("ExposureCategoryType"),
  //Commercial Specific
  {
    fieldKey: buildFieldKey("OccupancyType"),
    hidden: form.isComplexCommercialEvaluation,
  } as EnumSingleSelectFieldProps,
  {
    fieldKey: buildFieldKey("NumberOfRoofCoverSystems"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .isThisANewConstruction === YesOrNoSelection.Yes,
    max: 6,
    format: "0",
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("GrossRoofAreaSqft"),
    format: "0",
    hidden: form.isComplexCommercialEvaluation,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("TotalFloorAreaSqft"),
    format: "0",
    hidden: form.isComplexCommercialEvaluation,
  } as NumericFieldProps,
];

const projectConstructionDetailsRequiredFields = [
  buildFieldKey("ProjectOrBuildingName"),
  buildFieldKey("Address"),
  buildFieldKey("HazardType"),
  buildFieldKey("HasHailSupplement"),
  buildFieldKey("IbcVersionType"),
  buildFieldKey("AsceCodeType"),
  buildFieldKey("ImportanceFactorType"),
  buildFieldKey("RiskCategoryType"),
  buildFieldKey("ExposureCategoryType"),
  buildFieldKey("OccupancyType"),
  buildFieldKey("NumberOfRoofCoverSystems"),
  buildFieldKey("GrossRoofAreaSqft"),
  buildFieldKey("TotalFloorAreaSqft"),
];

export const commercialProjectConstructionDetailsFieldsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        projectConstructionDetailsFields(form),
        onValueChange,
        form,
        errors,
        projectConstructionDetailsRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};

//Part 7 Existing Roof Cover Systems
const existingRoofCoverSystemFields = (
  form: Evaluation_CommercialEvaluationFormModel
) => [
  // Roof Cover System 1
  {
    fieldKey: buildFieldKey("IsRoofCoverSystem1GoingToBeReplaced"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 1,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem1ExistingRoofAgeYears"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 1,
    format: "0",
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem1ExistingRoofSlope"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 1,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("IsRoofCoverSystem1ContinuousParapet"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .roofCoverSystem1ExistingRoofSlope !== 1,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem1ParapetHeightFt"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .isRoofCoverSystem1ContinuousParapet !== YesOrNoSelection.Yes,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem1CoverCapSheetType"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .roofCoverSystem1ExistingRoofSlope !== 1,
  } as EnumSingleSelectFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem1ExistingRoofCoverDesignation"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 1,
  } as EnumRadioFieldProps,

  // Roof Cover System 2
  {
    fieldKey: buildFieldKey("IsRoofCoverSystem2GoingToBeReplaced"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 2,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem2ExistingRoofAgeYears"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 2,
    format: "0",
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem2ExistingRoofSlope"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 2,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("IsRoofCoverSystem2ContinuousParapet"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .roofCoverSystem2ExistingRoofSlope !== 1,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem2ParapetHeightFt"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .isRoofCoverSystem2ContinuousParapet !== YesOrNoSelection.Yes,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem2CoverCapSheetType"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .roofCoverSystem2ExistingRoofSlope !== 1,
  } as EnumSingleSelectFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem2ExistingRoofCoverDesignation"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 2,
  } as EnumRadioFieldProps,

  // Roof Cover System 3
  {
    fieldKey: buildFieldKey("IsRoofCoverSystem3GoingToBeReplaced"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 3,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem3ExistingRoofAgeYears"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 3,
    format: "0",
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem3ExistingRoofSlope"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 3,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("IsRoofCoverSystem3ContinuousParapet"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .roofCoverSystem3ExistingRoofSlope !== 1,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem3ParapetHeightFt"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .isRoofCoverSystem3ContinuousParapet !== YesOrNoSelection.Yes,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem3CoverCapSheetType"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .roofCoverSystem3ExistingRoofSlope !== 1,
  } as EnumSingleSelectFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem3ExistingRoofCoverDesignation"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 3,
  } as EnumRadioFieldProps,

  // Roof Cover System 4
  {
    fieldKey: buildFieldKey("IsRoofCoverSystem4GoingToBeReplaced"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 4,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem4ExistingRoofAgeYears"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 4,
    format: "0",
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem4ExistingRoofSlope"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 4,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("IsRoofCoverSystem4ContinuousParapet"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .roofCoverSystem4ExistingRoofSlope !== 1,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem4ParapetHeightFt"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .isRoofCoverSystem4ContinuousParapet !== YesOrNoSelection.Yes,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem4CoverCapSheetType"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .roofCoverSystem4ExistingRoofSlope !== 1,
  } as EnumSingleSelectFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem4ExistingRoofCoverDesignation"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 4,
  } as EnumRadioFieldProps,

  // Roof Cover System 5
  {
    fieldKey: buildFieldKey("IsRoofCoverSystem5GoingToBeReplaced"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 5,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem5ExistingRoofAgeYears"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 5,
    format: "0",
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem5ExistingRoofSlope"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 5,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("IsRoofCoverSystem5ContinuousParapet"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .roofCoverSystem5ExistingRoofSlope !== 1,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem5ParapetHeightFt"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .isRoofCoverSystem5ContinuousParapet !== YesOrNoSelection.Yes,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem5CoverCapSheetType"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .roofCoverSystem5ExistingRoofSlope !== 1,
  } as EnumSingleSelectFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem5ExistingRoofCoverDesignation"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 5,
  } as EnumRadioFieldProps,

  // Roof Cover System 6
  {
    fieldKey: buildFieldKey("IsRoofCoverSystem6GoingToBeReplaced"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 6,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem6ExistingRoofAgeYears"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 6,
    format: "0",
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem6ExistingRoofSlope"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 6,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("IsRoofCoverSystem6ContinuousParapet"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .roofCoverSystem6ExistingRoofSlope !== 1,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem6ParapetHeightFt"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .isRoofCoverSystem6ContinuousParapet !== YesOrNoSelection.Yes,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem6CoverCapSheetType"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .roofCoverSystem6ExistingRoofSlope !== 1,
  } as EnumSingleSelectFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverSystem6ExistingRoofCoverDesignation"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .numberOfRoofCoverSystems! < 6,
  } as EnumRadioFieldProps,
];

const existingRoofCoverSystemRequiredFields = [
  buildFieldKey("IsRoofCoverSystem1GoingToBeReplaced"),
  buildFieldKey("RoofCoverSystem1ExistingRoofAgeYears"),
  buildFieldKey("RoofCoverSystem1ExistingRoofSlope"),
  buildFieldKey("IsRoofCoverSystem1ContinuousParapet"),
  buildFieldKey("RoofCoverSystem1ParapetHeightFt"),
  buildFieldKey("RoofCoverSystem1CoverCapSheetType"),
  buildFieldKey("RoofCoverSystem1ExistingRoofCoverDesignation"),

  buildFieldKey("IsRoofCoverSystem2GoingToBeReplaced"),
  buildFieldKey("RoofCoverSystem2ExistingRoofAgeYears"),
  buildFieldKey("RoofCoverSystem2ExistingRoofSlope"),
  buildFieldKey("IsRoofCoverSystem2ContinuousParapet"),
  buildFieldKey("RoofCoverSystem2ParapetHeightFt"),
  buildFieldKey("RoofCoverSystem2CoverCapSheetType"),
  buildFieldKey("RoofCoverSystem2ExistingRoofCoverDesignation"),

  buildFieldKey("IsRoofCoverSystem3GoingToBeReplaced"),
  buildFieldKey("RoofCoverSystem3ExistingRoofAgeYears"),
  buildFieldKey("RoofCoverSystem3ExistingRoofSlope"),
  buildFieldKey("IsRoofCoverSystem3ContinuousParapet"),
  buildFieldKey("RoofCoverSystem3ParapetHeightFt"),
  buildFieldKey("RoofCoverSystem3CoverCapSheetType"),
  buildFieldKey("RoofCoverSystem3ExistingRoofCoverDesignation"),

  buildFieldKey("IsRoofCoverSystem4GoingToBeReplaced"),
  buildFieldKey("RoofCoverSystem4ExistingRoofAgeYears"),
  buildFieldKey("RoofCoverSystem4ExistingRoofSlope"),
  buildFieldKey("IsRoofCoverSystem4ContinuousParapet"),
  buildFieldKey("RoofCoverSystem4ParapetHeightFt"),
  buildFieldKey("RoofCoverSystem4CoverCapSheetType"),
  buildFieldKey("RoofCoverSystem4ExistingRoofCoverDesignation"),

  buildFieldKey("IsRoofCoverSystem5GoingToBeReplaced"),
  buildFieldKey("RoofCoverSystem5ExistingRoofAgeYears"),
  buildFieldKey("RoofCoverSystem5ExistingRoofSlope"),
  buildFieldKey("IsRoofCoverSystem5ContinuousParapet"),
  buildFieldKey("RoofCoverSystem5ParapetHeightFt"),
  buildFieldKey("RoofCoverSystem5CoverCapSheetType"),
  buildFieldKey("RoofCoverSystem5ExistingRoofCoverDesignation"),

  buildFieldKey("IsRoofCoverSystem6GoingToBeReplaced"),
  buildFieldKey("RoofCoverSystem6ExistingRoofAgeYears"),
  buildFieldKey("RoofCoverSystem6ExistingRoofSlope"),
  buildFieldKey("IsRoofCoverSystem6ContinuousParapet"),
  buildFieldKey("RoofCoverSystem6ParapetHeightFt"),
  buildFieldKey("RoofCoverSystem6CoverCapSheetType"),
  buildFieldKey("RoofCoverSystem6ExistingRoofCoverDesignation"),
];

export const commercialExistingRoofCoverSystemFieldsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        existingRoofCoverSystemFields(form),
        onValueChange,
        form,
        errors,
        existingRoofCoverSystemRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};

//Part 8 File Uploaders
const fileUploaderFields = (
  _form: Evaluation_CommercialEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("ArchitecturalDrawings"),
    defaultToUpload: false,
    errorMessages: get(errors, "architecturalDrawings.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ArchitecturalStructuralDrawings"),
    defaultToUpload: false,
    errorMessages: get(errors, "architecturalStructuralDrawings.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

const fileUploaderRequiredFields = [
  buildFieldKey("ArchitecturalDrawings"),
  buildFieldKey("ArchitecturalStructuralDrawings"),
];

export const commercialFileUploaderFieldsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        fileUploaderFields(form, errors),
        onValueChange,
        form,
        errors,
        fileUploaderRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};

//Part 9 Confirmation Fields
const confirmationFields = (
  _form: Evaluation_CommercialEvaluationFormModel,
  _errors?: { [key: string]: string[] }
) => [
  buildFieldKey("PaymentVerificationAndAcknowledgement"),
  //comercial specific
  buildFieldKey("FortifiedCommercialStandardsFamiliarityConfirmation"),
];

const confirmationRequiredFields = [
  buildFieldKey("PaymentVerificationAndAcknowledgement"),
  buildFieldKey("FortifiedCommercialStandardsFamiliarityConfirmation"),
];

export const commercialConfirmationFieldsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        confirmationFields(form, errors),
        onValueChange,
        form,
        errors,
        confirmationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};

// Full form builder with all parts included
export const commercialApplicationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        [
          ...applicationInformationFields(),
          ...emailNotificationsFields(form),
          ...generalProjectDetailsFields(form),
          ...newConstructionsAndNewAdditionsFields(form),
          ...existingConstructionReroofingFields(form),
          ...projectConstructionDetailsFields(form),
          ...existingRoofCoverSystemFields(form),
          ...fileUploaderFields(form),
          ...confirmationFields(form),
        ],
        onValueChange,
        form,
        errors,
        [
          ...applicationInformationRequiredFields,
          ...emailNotificationsRequiredFields,
          ...generalProjectDetailsRequiredFields,
          ...newConstructionsAndNewAdditionsRequiredFields,
          ...existingConstructionReroofingRequiredFields,
          ...projectConstructionDetailsRequiredFields,
          ...existingRoofCoverSystemRequiredFields,
          ...fileUploaderRequiredFields,
          ...confirmationRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const commercialIterationEngineApplicationFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        [
          ...applicationInformationFields(),
          ...emailNotificationsFields(form),
          ...generalProjectDetailsFields(form),
          ...newConstructionsAndNewAdditionsFields(form),
          ...existingConstructionReroofingFields(form),
          ...projectConstructionDetailsFields(form),
          ...existingRoofCoverSystemFields(form),
          ...fileUploaderFields(form),
          ...confirmationFields(form),
        ],
        onValueChange,
        form,
        errors,
        [
          ...applicationInformationRequiredFields,
          ...emailNotificationsRequiredFields,
          ...generalProjectDetailsRequiredFields,
          ...newConstructionsAndNewAdditionsRequiredFields,
          ...existingConstructionReroofingRequiredFields,
          ...projectConstructionDetailsRequiredFields,
          ...existingRoofCoverSystemRequiredFields,
          ...fileUploaderRequiredFields,
          ...confirmationRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};
