import { Icon } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";
import { Column, ColumnModel } from "@syncfusion/ej2-react-grids";
import { evaluationApiClient } from "../../lib/apiClients/evaluation/evaluationApiClient";
import { browserDownloadFileResponse } from "../../../common/utils/download";

interface FortifiedCertificateGridClickableIconColumnOptions
  extends ColumnModel {
  icon: IconName;
}

export default class FortifiedCertificateGridClickableIconColumn extends Column {
  constructor(opts: FortifiedCertificateGridClickableIconColumnOptions) {
    const { icon, ...superOpts } = opts;
    super({
      ...superOpts,
      template: ((model: Record<string, any>) => {
        const evaluationId = model["id"];
        const fortifiedId = model["fortifiedId"];
        const approvedAt = model["approvedAt"];

        const certificateName = "Certificate.pdf";
        const fileName = fortifiedId
          ? `${fortifiedId}-${certificateName}`
          : `${certificateName}`;

        const downloadCertificate = async () => {
          await evaluationApiClient
            .getEvaluationCertificate(evaluationId)
            .then((response) =>
              browserDownloadFileResponse(response.data, fileName)
            );
        };

        return (
          approvedAt && (
            <div
              className="certificate-grid-column"
              onClick={(e) => {
                e.stopPropagation();
                downloadCertificate();
              }}
            >
              <Icon className="base-grid-icon" icon={icon} />
              <span> Download</span>
            </div>
          )
        );
      }) as any,
    });
  }
}
