import ErrorWrapper from "../../../common/Components/Error/ErrorWrapper";
import { PagesLayout } from "./PagesLayout";
import { createBrowserRouter } from "react-router-dom";
import { utils, WithParams } from "@ucl/library";
import { Board } from "../Board";
import { Evaluation } from "../Evaluation";
import { Checklist } from "../Checklist";
import { ReportsPage } from "../ReportsPage";
import { AdminPage } from "../AdminPage";
import { HelpPage } from "../HelpPage";
import { EvaluationBidPage } from "../EvaluationBidPage";
import { ExternalEvaluationSummaryPage } from "../ExternalEvaluationSummaryPage";
import { ReportViewerPage } from "../reports/ReportViewerPage";
import { fortifiedHomeLogout } from "../../../common/lib/msal/fortifiedHomeMsal";
import { HomeBasicInformationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationBasicInformationForm";
import { HomePagesLayout } from "../../../fortifiedV2/Home/common/pages/index/HomePagesLayout";
import { HomeStandardBasicInformationPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardBasicInformationPage";
import { HomeStandardIterationEnginePage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardIterationEnginePage";
import {
  faChartSimple,
  faList,
  faSliders,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { HomeSCSHBasicInformationFormPage } from "../../../fortifiedV2/Home/scsh/pages/HomeSCSHBasicInformationFormPage";
import { HomeSCSHIterationEnginePage } from "../../../fortifiedV2/Home/scsh/pages/HomeSCSHIterationEnginePage";
import { HomeSCSHEvaluationFormPage } from "../../../fortifiedV2/Home/scsh/pages/HomeSCSHEvaluationFormPage";
import { SCSHEvaluationForm_FormParts } from "../../../fortifiedV2/Home/scsh/types/HomeSCSHEvaluation_EvaluationFormModel";

const errorWrapper = (
  <div className="fortified-page">
    <ErrorWrapper logOutUser={fortifiedHomeLogout} />
  </div>
);

export const fortifiedHomeRoutePrefix = "/fh";

export const fortifiedHomeStandardRoutePrefix = `${fortifiedHomeRoutePrefix}/standard`;

export const fortifiedHomeSCSHRoutePrefix = `${fortifiedHomeRoutePrefix}/scsh`;

export const uclRouteObject = [
  {
    path: `${fortifiedHomeRoutePrefix}`,
    label: "Board",
    icon: faList,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        errorElement: errorWrapper,
        element: <Board />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/evaluation/:id`,
    label: "Evaluation Page",
    icon: undefined,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Evaluation Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: <Evaluation />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/checklist`,
    label: "Checklist Page",
    icon: undefined,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Checklist Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: <Checklist />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/reports`,
    label: "Reports",
    icon: faChartSimple,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Reports",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: <ReportsPage />,
      },
      {
        path: ":reportType",
        label: "Reports",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: <ReportViewerPage />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/admin`,
    label: "Admin",
    icon: faSliders,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Admin Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: <AdminPage />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/help`,
    label: "Help",
    icon: faQuestionCircle,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Help",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        errorElement: errorWrapper,
        element: <HelpPage />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/bid/:id`,
    label: "Bids Page",
    icon: undefined,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Bids Page",
        isAvailable: true,
        showInNavigation: false,
        errorElement: errorWrapper,
        showInSubNavigation: false,
        element: <EvaluationBidPage />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/external/evaluation-summary/:id`,
    label: "Bids Page",
    icon: undefined,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Bids Page",
        isAvailable: true,
        showInNavigation: false,
        errorElement: errorWrapper,
        showInSubNavigation: false,
        element: <ExternalEvaluationSummaryPage />,
      },
    ],
  },
  //Home V2 Routes
  {
    path: `${fortifiedHomeStandardRoutePrefix}/:evaluationId`,
    label: "Forms",
    icon: undefined,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
    children: [
      {
        path: "basic-information",
        label: "Basic Information Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardBasicInformationPage evaluationId={evaluationId} />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "basic-information/:homeStandardBasicInformationFormPart",
        label: "Application Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            homeStandardBasicInformationFormPart: HomeBasicInformationFormParts;
          }>>
            {({ evaluationId, homeStandardBasicInformationFormPart }) => {
              return (
                <HomeStandardBasicInformationPage
                  evaluationId={evaluationId}
                  homeStandardBasicInformationFormPart={
                    homeStandardBasicInformationFormPart
                  }
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
    ],
  },
  {
    path: `${fortifiedHomeStandardRoutePrefix}/iteration-engine/:evaluationId`,
    label: "Iteration Engine",
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
    children: [
      {
        path: "",
        label: "",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardIterationEnginePage evaluationId={evaluationId} />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
    ],
  },
  //scsh routes
  {
    path: `${fortifiedHomeSCSHRoutePrefix}/:evaluationId`,
    label: "Forms",
    icon: undefined,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
    children: [
      {
        path: "basic-information",
        label: "Basic Information Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeSCSHBasicInformationFormPage evaluationId={evaluationId} />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "basic-information/:homeSCSHBasicInformationFormPart",
        label: "Basic Information Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            homeSCSHBasicInformationFormPart: HomeBasicInformationFormParts;
          }>>
            {({ evaluationId, homeSCSHBasicInformationFormPart }) => {
              return (
                <HomeSCSHBasicInformationFormPage
                  evaluationId={evaluationId}
                  homeSCSHBasicInformationFormPart={
                    homeSCSHBasicInformationFormPart
                  }
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "evaluation-form",
        label: "SCSH Evaluation Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return <HomeSCSHEvaluationFormPage evaluationId={evaluationId} />;
            }}
          </WithParams>
        ),
      },
      {
        path: "evaluation-form/:homeSCSHEvaluationFormPart",
        label: "SCSH Evaluation Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            homeSCSHEvaluationFormPart: SCSHEvaluationForm_FormParts;
          }>>
            {({ evaluationId, homeSCSHEvaluationFormPart }) => {
              return (
                <HomeSCSHEvaluationFormPage
                  evaluationId={evaluationId}
                  homeSCSHEvaluationFormPart={homeSCSHEvaluationFormPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
    ],
  },
  {
    path: `${fortifiedHomeSCSHRoutePrefix}/iteration-engine/:evaluationId`,
    label: "Iteration Engine",
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
    children: [
      {
        path: "",
        label: "",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeSCSHIterationEnginePage evaluationId={evaluationId} />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
    ],
  },
] as utils.routes.UclRouteObject[];

export const fortified_Home_browserRouter = createBrowserRouter(
  uclRouteObject,
  {
    basename: process.env.REACT_APP_PATH_PREFIX,
  }
);
