import { Button, Icon, Tag } from "@blueprintjs/core";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../common/types/Forms/FormChecklist";
import { IconNames } from "@blueprintjs/icons";
import { useNavigate } from "react-router";
import "./styles.scss";

interface ChecklistProps<T> {
  checklistParts: FormChecklistPart<T>[];
  evaluationId: string | number;
  formURL: string;
  title: string;
  activeFormPart: T | undefined;
  getChecklistStatus: (formPart: T) => FormChecklistStatus;
}

const FormChecklistProgress = <T,>(props: ChecklistProps<T>) => {
  const navigate = useNavigate();

  const getChecklistIcon = (checklistPart: FormChecklistPart<T>) => {
    switch (props.getChecklistStatus(checklistPart.formPart)) {
      case FormChecklistStatus.Unsubmitted:
        return IconNames.CIRCLE;
      case FormChecklistStatus.NextUp:
        return IconNames.RING;
      case FormChecklistStatus.Submitted:
        return IconNames.TICK_CIRCLE;
      default:
        return IconNames.ERROR;
    }
  };

  return (
    <div className="form-progress-container">
      <div className="form-progress-container__steps">
        <div className="form-progress-container__steps__header">
          {props.title}
        </div>
        {props.checklistParts.map((checklistPart, index) => {
          if (checklistPart.hidden) {
            return null;
          }

          const formPartValue = (checklistPart.formPart as any).valueOf();
          return (
            <div key={formPartValue}>
              <div className="form-progress-container__steps__item">
                <div className="form-progress-container__steps__item__page">
                  <Icon icon={getChecklistIcon(checklistPart)} />
                  <Button
                    minimal
                    intent="primary"
                    active={props.activeFormPart === checklistPart.formPart}
                    text={checklistPart.displayName}
                    onClick={() =>
                      navigate(`${props.formURL}/${formPartValue}`)
                    }
                    disabled={checklistPart.disabled}
                  />
                </div>
                {!!checklistPart.requiredFieldCount &&
                  checklistPart.requiredFieldCount > 0 && (
                    <div className="form-progress-container__steps__item__badge">
                      <Tag large={true} round={true}>
                        {checklistPart.requiredFieldCount}
                      </Tag>
                    </div>
                  )}
              </div>
              {index !== props.checklistParts.length - 1 && (
                <div className="vertical-line" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FormChecklistProgress;
