import variables from "../../../common/config/variables";

export type WildfireReportTypes =
  | "ibhs-evaluation-volume-report"
  | "ibhs-backlog-report"
  | "rejection-reason-report";

export interface ReportViewDetails {
  slug: WildfireReportTypes;
  title: string;
  reportLink: string;
  hasViewPermission: boolean;
}

export const reports = (
  hasPermission: (permission: PermissionsKey) => boolean
): Record<WildfireReportTypes, ReportViewDetails> => {
  return {
    "ibhs-evaluation-volume-report": {
      slug: "ibhs-evaluation-volume-report",
      title: "IBHS Evaluation Volume Report",
      reportLink: variables.wildfireEvaluationVolumeReportLink,
      hasViewPermission: hasPermission("CanViewWFPVolumeReport"),
    },
    "ibhs-backlog-report": {
      slug: "ibhs-backlog-report",
      title: "IBHS Backlog Report",
      reportLink: variables.wildfireIbhsBacklogReportLink,
      hasViewPermission: hasPermission("CanViewWFPBacklogReport"),
    },
    "rejection-reason-report": {
      slug: "rejection-reason-report",
      title: "IBHS Rejection Reason Report",
      reportLink: variables.wildfireIbhsRejectionReasonReport,
      hasViewPermission: hasPermission("CanViewWFPRejectionReport"),
    },
  };
};
