import { Button, Intent } from "@blueprintjs/core";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { evaluationStore } from "../../stores/EvaluationStore";

interface ProceedToPaymentActionButtonProps {}

const ProceedToPaymentActionButton: React.FC<
  ProceedToPaymentActionButtonProps
> = ({}) => {
  if (evaluationStore.evaluation?.hasSufficientPaymentCredits) {
    return (
      <Button
        intent={Intent.PRIMARY}
        text="Proceed to Payment"
        onClick={() => {
          dialogsViewerStore.setIsApplyPaymentCreditDialogOpen(true);
        }}
      />
    );
  }

  return !!evaluationStore.paymentLink ? (
    <Button
      intent={Intent.PRIMARY}
      text="Proceed to Payment"
      onClick={() => {
        window.open(evaluationStore.paymentLink);
      }}
    />
  ) : null;
};

export default ProceedToPaymentActionButton;
