import { dialogStore, Footer } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import { ReactNode } from "react";
import "./styles.scss";
import WildfireSecondaryButton from "../../Button/DialogFooterButtons/SecondaryButton/SecondaryButton";
import WildfireSubmitButton from "../../Button/DialogFooterButtons/SubmitButton/SubmitButton";

export enum ChangeRequestsCompleteDialogType {
  ApplicantDialog,
  AdminDialog,
}

const dialogIdBase = "wildfire-change-requests-complete-dialog";

const getDialogId = (wildfireApplicationId: string): string =>
  `${dialogIdBase}_${wildfireApplicationId}`;

const getHeaderText = (
  dialogType: ChangeRequestsCompleteDialogType
): string => {
  switch (dialogType) {
    case ChangeRequestsCompleteDialogType.ApplicantDialog:
      return "Send For Review Confirmation";
    case ChangeRequestsCompleteDialogType.AdminDialog:
      return "Send Forward Confirmation";
  }
};

const getBodyText = (
  dialogType: ChangeRequestsCompleteDialogType
): ReactNode => {
  switch (dialogType) {
    case ChangeRequestsCompleteDialogType.ApplicantDialog:
      return (
        <>
          You have addressed all outstanding change requests on your
          application.
          <br />
          Do you want send it for review?
        </>
      );
    case ChangeRequestsCompleteDialogType.AdminDialog:
      return (
        <>
          You have resolved all outstanding change requests on this application.
          <br />
          Do you want to send it forward?
        </>
      );
  }
};

const getAcceptButtonText = (
  dialogType: ChangeRequestsCompleteDialogType
): string => {
  switch (dialogType) {
    case ChangeRequestsCompleteDialogType.ApplicantDialog:
      return "Send For Review";
    case ChangeRequestsCompleteDialogType.AdminDialog:
      return "Send Forward";
  }
};

const buildChangeRequestsCompleteDialogProps = (
  wildfireApplicationId: string,
  dialogType: ChangeRequestsCompleteDialogType,
  onAccept: () => Promise<void>
): DialogProps => {
  return {
    id: getDialogId(wildfireApplicationId),
    className: dialogIdBase,
    header: getHeaderText(dialogType),
    allowDragging: false,
    content: () => (
      <div className="wildfire-change-requests-complete-dialog">
        <div className={`wildfire-dialog-body-text`}>
          {getBodyText(dialogType)}
        </div>
        <Footer>
          <WildfireSecondaryButton
            buttonText="Cancel"
            onClick={() =>
              closeChangeRequestsCompleteDialog(wildfireApplicationId)
            }
          />
          <WildfireSubmitButton
            buttonText={getAcceptButtonText(dialogType)}
            onClick={async () => {
              closeChangeRequestsCompleteDialog(wildfireApplicationId);
              await onAccept();
            }}
          />
        </Footer>
      </div>
    ),
  };
};

export const openChangeRequestsCompleteDialog = (
  wildfireApplicationId: string,
  dialogType: ChangeRequestsCompleteDialogType,
  onAccept: () => Promise<void>
) =>
  dialogStore.openDialog(
    buildChangeRequestsCompleteDialogProps(
      wildfireApplicationId,
      dialogType,
      onAccept
    )
  );

export const closeChangeRequestsCompleteDialog = (
  wildfireApplicationId: string
) => dialogStore.closeDialog(getDialogId(wildfireApplicationId));
