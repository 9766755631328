import React, { createContext } from "react";
import { ChangeRequestV2 } from "../IterationEngine/types/ChangeRequestV2";
import { WildfireSchema } from "../../customHooks/useFieldSchemaFactory";
import {
  useEvaluationChangeRequest,
  UseEvaluationChangeRequestProps,
} from "../../../common/customHooks/IterationEngine/ChangeRequests/useEvaluationChangeRequest";
import { wildfireChangeRequestApiClient } from "../../lib/apiClients/ChangeRequest/wildfireChangeRequestApiClient";

interface EvaluationChangeRequestContextProps {
  allChangeRequests: ChangeRequestV2[];
  setAllChangeRequests: (changeRequests: ChangeRequestV2[]) => void;
  updateChangeRequest: (changeRequest?: ChangeRequestV2) => void;
  resolveChangeRequest: (changeRequestId: string) => Promise<void>;
  unresolveChangeRequest: (changeRequestId: string) => Promise<void>;
  unresolvedChangeRequests: ChangeRequestV2[];
  hasNoUnaddressedChangeRequests: boolean;
  addressedAtLeastOneChangeRequest: boolean;
  resolvedAllChangeRequests: boolean;
  getChangeRequests?: () => Promise<void>;
  getEvaluationFieldKeysForAllUnresolvedChangeRequests: string[];
  changeRequestCount: number;
  resolvedChangeRequestCount: number;
  resolvedChangeRequestsPercentage: () => number;
  hasOneNewChangeRequest: boolean;
  longestOutstandingChangeRequest: ChangeRequestV2 | undefined;
}

const defaultValue: EvaluationChangeRequestContextProps = {
  allChangeRequests: [],
  unresolvedChangeRequests: [],
  hasNoUnaddressedChangeRequests: false,
  addressedAtLeastOneChangeRequest: false,
  resolvedAllChangeRequests: false,
  setAllChangeRequests: (changeRequests: ChangeRequestV2[]) =>
    console.warn("setChangeRequests not implemented.", changeRequests),
  updateChangeRequest: (changeRequest?: ChangeRequestV2) =>
    console.warn("updateChangeRequest not implemented.", changeRequest),
  resolveChangeRequest: async () =>
    console.warn("resolveChangeRequest not implemented."),
  unresolveChangeRequest: async () =>
    console.warn("unresolveChangeRequest not implemented."),
  getEvaluationFieldKeysForAllUnresolvedChangeRequests: [],
  changeRequestCount: 0,
  resolvedChangeRequestCount: 0,
  resolvedChangeRequestsPercentage: () => 0,
  hasOneNewChangeRequest: false,
  longestOutstandingChangeRequest: {} as ChangeRequestV2,
};

export const EvaluationChangeRequestContext =
  createContext<EvaluationChangeRequestContextProps>(defaultValue);

export const EvaluationChangeRequestProvider: React.FC<
  React.PropsWithChildren<{
    evaluationId: string;
    wildfireSchema: WildfireSchema;
  }>
> = ({ children, evaluationId, wildfireSchema }) => {
  const useEvaluationChangeRequestProps: UseEvaluationChangeRequestProps = {
    evaluationId: evaluationId,
    schema: wildfireSchema,
    getChangeRequest: wildfireChangeRequestApiClient.getChangeRequest,
    getChangeRequestsByEvaluation:
      wildfireChangeRequestApiClient.getChangeRequestsByEvaluation,
    resolveChangeRequest: wildfireChangeRequestApiClient.resolveChangeRequest,
    unresolveChangeRequest:
      wildfireChangeRequestApiClient.unresolveChangeRequest,
  };

  const {
    allChangeRequests,
    setAllChangeRequests,
    updateChangeRequest,
    resolveChangeRequest,
    unresolveChangeRequest,
    unresolvedChangeRequests,
    hasNoUnaddressedChangeRequests,
    addressedAtLeastOneChangeRequest,
    resolvedAllChangeRequests,
    getChangeRequests,
    changeRequestCount,
    resolvedChangeRequestCount,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    resolvedChangeRequestsPercentage,
    hasOneNewChangeRequest,
    longestOutstandingChangeRequest,
  } = useEvaluationChangeRequest(useEvaluationChangeRequestProps);

  return (
    <EvaluationChangeRequestContext.Provider
      value={{
        allChangeRequests,
        setAllChangeRequests,
        updateChangeRequest,
        resolveChangeRequest,
        unresolveChangeRequest,
        unresolvedChangeRequests,
        hasNoUnaddressedChangeRequests,
        addressedAtLeastOneChangeRequest,
        resolvedAllChangeRequests,
        getChangeRequests,
        changeRequestCount,
        resolvedChangeRequestCount,
        getEvaluationFieldKeysForAllUnresolvedChangeRequests,
        resolvedChangeRequestsPercentage,
        hasOneNewChangeRequest,
        longestOutstandingChangeRequest,
      }}
    >
      {children}
    </EvaluationChangeRequestContext.Provider>
  );
};
