import {
  EnumSingleSelectFieldProps,
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
} from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { Evaluation_CommercialEvaluationFormModel } from "../../../types/CommercialEvaluationFormModel";

const modelName = "Evaluation";
const subModelName = "CommercialEvaluation";
const formName = "CommercialEvaluationCompanyAssignmentForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

//Part 1: Evaluation Evaluator Assignment
const evaluationCompanyAssignmentFields = () => [
  {
    fieldKey: buildFieldKey("EvaluatorCompany"),
    placeholder: "Please select an option",
    hidden: false,
  } as EnumSingleSelectFieldProps,
];

const evaluationCompanyAssignmentRequiredFields = [
  buildFieldKey("EvaluatorCompany"),
];

// Full form builder with all parts included
export const commercialEvaluationCompanyAssignmentFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        [...evaluationCompanyAssignmentFields()],
        onValueChange,
        form,
        errors,
        [...evaluationCompanyAssignmentRequiredFields]
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const commercialIterationEngineEvaluationCompanyAssignmentFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        [...evaluationCompanyAssignmentFields()],
        onValueChange,
        form,
        errors,
        [...evaluationCompanyAssignmentRequiredFields]
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};
