import {
  Button,
  Classes,
  Dialog,
  Icon,
  Intent,
  TextArea,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useEffect, useState } from "react";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { evaluationStore } from "../../stores/EvaluationStore";
import { DialogFooter } from "./DialogFooter";
import { BaseDialogProps } from "./types";
import { SelectableLabelWithToolTip } from "../Fields/SelectableLabel/SelectableLabelWithToolTip";

export interface RejectionReasonsDialogProps extends BaseDialogProps {}

export const RejectionReasonsDialog: React.FC<RejectionReasonsDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [localEvaluationRejectionReasons, setLocalEvaluationRejectionReasons] =
    useState<string[]>([]);
  const [ready, setReady] = useState<boolean>(false);
  const [note, setNote] = useState<string>();

  useEffect(() => {
    if (evaluationStore.evaluation) {
      setLocalEvaluationRejectionReasons(
        evaluationStore.evaluation.fortifiedRejectionReasons
      );
      setNote(evaluationStore.evaluation.rejectionAdditionalNotes);
      setReady(true);
    }
  }, [dialogsViewerStore.isRejectionReasonDialogOpen]);

  return (
    <>
      {ready && (
        <Dialog isOpen={isOpen} hasBackdrop={false} isCloseButtonShown={true}>
          <div className={Classes.DIALOG_HEADER}>
            <Icon
              icon={IconNames.Disable}
              intent={Intent.DANGER}
              title={"Rejection Reasons"}
            />
            Rejection Reasons
          </div>
          <div className={`${Classes.DIALOG_BODY} rejection-reasons-readonly`}>
            {localEvaluationRejectionReasons &&
              localEvaluationRejectionReasons.map((reason, index) => {
                return (
                  <div className="rejection-reason" key={index}>
                    <Button
                      minimal
                      intent={Intent.DANGER}
                      text={reason}
                      icon={IconNames.Cross}
                    />
                  </div>
                );
              })}
            <div className="rejection-reasons-textarea">
              <SelectableLabelWithToolTip name={"Rejection Notes"} />
              <TextArea
                className="rejection-reasons-notes"
                rows={5}
                value={note}
                readOnly={true}
              ></TextArea>
            </div>
          </div>
          <DialogFooter
            closeButtonText="Close"
            isSubmitButtonHidden={true}
            closeDialog={closeDialog}
            submitDialog={submitDialog}
          />
        </Dialog>
      )}
    </>
  );
};
