import {
  FieldFormV2,
  FieldSchemaFormBuilderV2,
  RelativityTooltip,
} from "@ucl/library";
import { Button, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useNavigate } from "react-router";
import { ApplicationFormParts } from "../../../forms/ApplicationForm/ApplicationFormModel";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import "../styles.scss";
import useApplicationForm from "./useApplicationForm";
import { EvaluationFormModel } from "../../../forms/EvaluationFormModel";
import classnames from "classnames";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";

export interface ApplicationFormProps {
  wildfireEvaluationId: string;
  isWiePage?: boolean;
  formPart?: ApplicationFormParts;
  showFormControls?: boolean;
  onFormSave?: (fieldKey: string, value: any) => Promise<void>;
  setFormModel?: (formModel: EvaluationFormModel) => void;
  disableForm?: boolean;
  hasDuplicateAddress?: boolean;
  onInitialized?: () => void;
  setHasUnsavedChanges: (value: boolean) => void;
}

export const ApplicationForm = (props: ApplicationFormProps) => {
  const navigate = useNavigate();

  const {
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    applicationFormModel,
    setShouldSubmit,
    isFirstPartOfForm,
    isLastPartOfForm,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    isSubmitDisabled,
    toolTipText,
    containerRef,
    buttonText,
  } = useApplicationForm(props);

  return (
    <div ref={containerRef} className="wildfire-form-container">
      {noBuilders || isLoading ? (
        <Loading />
      ) : !applicationFormModel ? (
        <NonIdealState />
      ) : (
        <>
          <FieldFormV2<EvaluationFormModel>
            isDisabled={isSubmitting || props.disableForm}
            value={applicationFormModel}
            fieldSchemaFormBuilder={
              getApplicationFormPartBuilder() as FieldSchemaFormBuilderV2<EvaluationFormModel>
            }
            onFormSubmit={async (form) => {
              await handleFormSubmit(form);
            }}
            ref={formRef}
            onFieldChanged={async (form, value, fieldProps) => {
              await onFormFieldChange(form, value, fieldProps);
            }}
          />

          {(props.showFormControls ?? true) && (
            <div className="wildfire-form-footer">
              {!isFirstPartOfForm && (
                <Button
                  className={
                    "wildfire-form-back-button " +
                    "wildfire-form-navigation-button"
                  }
                  text="Back"
                  icon={IconNames.ARROW_LEFT}
                  onClick={() => {
                    // Navigate to the previous part of the form
                    const previousFormPart =
                      props.formPart === "photo-requirements"
                        ? ApplicationFormParts.Eligibility
                        : Object.values(ApplicationFormParts)[
                            Object.values(ApplicationFormParts).indexOf(
                              props.formPart!
                            ) - 1
                          ].valueOf();

                    navigate(
                      `${wildfireRoutePrefix}/${props.wildfireEvaluationId}/application-form/${previousFormPart}`
                    );
                  }}
                />
              )}
              <RelativityTooltip
                shouldShowTooltip={isSubmitDisabled}
                content={toolTipText}
              >
                <Button
                  className={classnames({
                    "wildfire-form-submit-button":
                      isLastPartOfForm && !isSubmitDisabled,
                    "wildfire-form-wide-button": isLastPartOfForm,
                    "wildfire-form-continue-button": !isLastPartOfForm,
                    "wildfire-form-navigation-button":
                      !isLastPartOfForm && !isSubmitDisabled,
                  })}
                  text={buttonText}
                  rightIcon={
                    isLastPartOfForm ? IconNames.TICK : IconNames.ARROW_RIGHT
                  }
                  disabled={isSubmitDisabled}
                  loading={isSubmitting}
                  onClick={() => {
                    if (formRef.current) {
                      props.setHasUnsavedChanges(false);
                      setShouldSubmit(true);
                      setIsSubmitting(true);
                      formRef.current.submit();
                    }
                  }}
                />
              </RelativityTooltip>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ApplicationForm;
