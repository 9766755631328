import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

export interface ChangeRequestNavigatorProps {
  currentPosition: number;
  changeRequestCount: number;
  navigateToChangeRequest: (idx: number) => void;
}

export const ChangeRequestNavigator: React.FC<ChangeRequestNavigatorProps> = ({
  currentPosition,
  changeRequestCount,
  navigateToChangeRequest,
}) => {
  return currentPosition >= 0 && changeRequestCount > 0 ? (
    <div className="change-request-navigator">
      <div className="change-request-navigator_go-left">
        {currentPosition > 0 ? (
          <Button
            style={{
              backgroundColor: "transparent",
            }}
            icon={IconNames.ARROW_LEFT}
            text="Previous"
            minimal={true}
            onClick={() => navigateToChangeRequest(currentPosition - 1)}
          />
        ) : (
          <div className="change-request-navigator_place-holder" />
        )}
      </div>
      <div className="change-request-navigator_state">
        {currentPosition + 1}/{changeRequestCount} Unresolved Change Requests
      </div>
      <div className="change-request-navigator_go-right">
        {currentPosition < changeRequestCount - 1 ? (
          <Button
            style={{
              backgroundColor: "transparent",
            }}
            rightIcon={IconNames.ARROW_RIGHT}
            text="Next"
            minimal={true}
            onClick={() => navigateToChangeRequest(currentPosition + 1)}
          />
        ) : (
          <div className="change-request-navigator_place-holder" />
        )}
      </div>
    </div>
  ) : null;
};
