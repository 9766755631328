import { ApiClient } from "@ucl/library";
import { FeatureToggleSet } from "../FeatureToggles";

const useFeatureToggleAPI = (baseApiClient: ApiClient) => {
  const getFeatureToggleSet = async () => {
    return baseApiClient.get<FeatureToggleSet>("feature-toggle/toggle");
  };

  return { getFeatureToggleSet };
};

export default useFeatureToggleAPI;
