import { InformationalBanner } from "../../../../../wildfire/components/Forms/FormSections/InformationalBanner/InformationalBanner";

export interface FCFMFPricingInformationBannerProps {}

export const FCFMFPricingInformationBanner: React.FC<
  FCFMFPricingInformationBannerProps
> = ({}: FCFMFPricingInformationBannerProps) => {
  return (
    <InformationalBanner
      title="Pricing Information"
      content={
        <>
          <a href="https://fortifiedhome.org/fortified-commercial-and-multifamily-pricing/">
            FORTIFIED Commercial and Multifamily Pricing Information
          </a>
        </>
      }
    />
  );
};
