import ErrorWrapper from "../../../common/Components/Error/ErrorWrapper";
import { createBrowserRouter } from "react-router-dom";
import { utils } from "@ucl/library";
import { ExternalEvaluationSummaryPage } from "../ExternalEvaluationSummaryPage";
import { ExternalPagesLayout } from "./ExternalPagesLayout";
import { fortifiedCommercialMultifamilyRoutePrefix } from "./fortifiedCommercialMultifamilyRouteConfig";

const errorWrapper = (
  <div className="fortified-page">
    <ErrorWrapper />
  </div>
);

const uclRouteObject = [
  {
    path: `${fortifiedCommercialMultifamilyRoutePrefix}/external/evaluation-summary/:id`,
    label: "External Evaluation Summary Page",
    icon: undefined,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: (
      <ExternalPagesLayout
        redirectPath={`${fortifiedCommercialMultifamilyRoutePrefix}`}
      />
    ),
    children: [
      {
        path: "",
        label: "External Evaluation Summary Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: <ExternalEvaluationSummaryPage />,
      },
    ],
  },
] as utils.routes.UclRouteObject[];

export const fortified_Commercial_Multifamily_ExternalBrowserRouter =
  createBrowserRouter(uclRouteObject, {
    basename: process.env.REACT_APP_PATH_PREFIX,
  });
