import { FieldFormV2, FieldSchemaFormBuilderV2 } from "@ucl/library";
import { Button, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useNavigate } from "react-router";
import { HomeExteriorFieldEvaluationFormParts } from "../../../forms/HomeExteriorFieldEvaluationForm/HomeExteriorFieldEvaluationFormModel";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import "../styles.scss";
import useHomeExteriorFieldEvaluationForm from "./useHomeExteriorFieldEvaluationForm";
import { EvaluationFormModel } from "../../../forms/EvaluationFormModel";
import classnames from "classnames";
import { RoofFieldEvaluationFormParts } from "../../../forms/RoofFieldEvaluationForm/RoofFieldEvaluationFormModel";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";

export interface HomeExteriorFieldEvaluationFormProps {
  wildfireEvaluationId: string;
  isWiePage?: boolean;
  formPart?: HomeExteriorFieldEvaluationFormParts;
  formModel?: EvaluationFormModel;
  setFormModel?: (model: EvaluationFormModel) => void;
  showFormControls?: boolean;
  onFormSave?: (fieldKey: string, value: any) => Promise<void>;
  disableForm?: boolean;
  onInitialized?: () => void;
  setHasUnsavedChanges: (value: boolean) => void;
}

export const HomeExteriorFieldEvaluationForm = (
  props: HomeExteriorFieldEvaluationFormProps
) => {
  const navigate = useNavigate();

  const {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    homeExteriorFieldEvaluationFormModel,
    setShouldSubmit,
    isFirstPartOfForm,
    isLastPartOfForm,
    getHomeExteriorFieldEvaluationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    containerRef,
  } = useHomeExteriorFieldEvaluationForm(props);

  const noBuilders = !builders && !wieBuilders;
  return (
    <div ref={containerRef} className="wildfire-form-container">
      {noBuilders || isLoading ? (
        <Loading />
      ) : !homeExteriorFieldEvaluationFormModel ? (
        <NonIdealState />
      ) : (
        <>
          <FieldFormV2<EvaluationFormModel>
            isDisabled={isSubmitting || props.disableForm}
            value={homeExteriorFieldEvaluationFormModel}
            fieldSchemaFormBuilder={
              getHomeExteriorFieldEvaluationFormPartBuilder() as FieldSchemaFormBuilderV2<EvaluationFormModel>
            }
            onFormSubmit={async (form) => handleFormSubmit(form)}
            ref={formRef}
            onFieldChanged={async (form, value, fieldProps) => {
              await onFormFieldChange(form, value, fieldProps);
            }}
          />

          {(props.showFormControls ?? true) && (
            <div className="wildfire-form-footer">
              <Button
                className={
                  "wildfire-form-back-button " +
                  "wildfire-form-navigation-button"
                }
                text="Back"
                icon={IconNames.ARROW_LEFT}
                onClick={() => {
                  if (isFirstPartOfForm) {
                    // Navigate to the last part of the previous form
                    navigate(
                      `${wildfireRoutePrefix}/${
                        props.wildfireEvaluationId
                      }/roof-field-evaluation-form/${Object.values(
                        RoofFieldEvaluationFormParts
                      )
                        ?.pop()
                        ?.valueOf()}`
                    );
                  } else {
                    // Navigate to the previous part of the form
                    navigate(
                      `${wildfireRoutePrefix}/${
                        props.wildfireEvaluationId
                      }/home-exterior-field-evaluation-form/${Object.values(
                        HomeExteriorFieldEvaluationFormParts
                      )[
                        Object.values(
                          HomeExteriorFieldEvaluationFormParts
                        ).indexOf(props.formPart!) - 1
                      ].valueOf()}`
                    );
                  }
                }}
              />
              <Button
                className={classnames({
                  "wildfire-form-submit-button": isLastPartOfForm,
                  "wildfire-form-continue-button": !isLastPartOfForm,
                  "wildfire-form-navigation-button": !isLastPartOfForm,
                })}
                text={isLastPartOfForm ? "Submit" : "Continue"}
                rightIcon={
                  isLastPartOfForm ? IconNames.TICK : IconNames.ARROW_RIGHT
                }
                disabled={isSubmitting || props.disableForm}
                loading={isSubmitting}
                onClick={() => {
                  if (formRef.current) {
                    props.setHasUnsavedChanges(false);
                    setShouldSubmit(true);
                    setIsSubmitting(true);
                    formRef.current.submit();
                  }
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HomeExteriorFieldEvaluationForm;
