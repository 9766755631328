import React, { ReactNode, createContext, useState } from "react";
import { WildfireEvaluation } from "../../types/WildfireApplication/WildfireApplication";
import { EvaluationFormModel } from "../../forms/EvaluationFormModel";
import WildfireAppHeader from "../Header/WildfireAppHeader";

// Create the context
interface AppHeaderContextProps {
  appHeader: ReactNode;
  setWildfireEvaluationHeader: (
    wildfireEvaluation: WildfireEvaluation | EvaluationFormModel
  ) => void;
  setDefaultHeader: () => void;
}

const primaryHeader = "Wildfire Prepared";

const defaultValue: AppHeaderContextProps = {
  appHeader: primaryHeader,
  setWildfireEvaluationHeader: (
    wildfireEvaluation: WildfireEvaluation | EvaluationFormModel
  ): void => {
    throw new Error(`Function not implemented.${wildfireEvaluation}`);
  },
  setDefaultHeader: (): void => {
    throw new Error(`Function not implemented.`);
  },
};

export const AppHeaderContext =
  createContext<AppHeaderContextProps>(defaultValue);

// Create a provider
export const AppHeaderProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [appHeader, setAppHeader] = useState<ReactNode>(primaryHeader);

  const setDefaultHeader = () => {
    setAppHeader(primaryHeader);
  };

  const setWildfireEvaluationHeader = (
    wildfireEvaluation: WildfireEvaluation | EvaluationFormModel
  ) => {
    setAppHeader(<WildfireAppHeader wildfireEvaluation={wildfireEvaluation} />);
  };

  return (
    <AppHeaderContext.Provider
      value={{ appHeader, setWildfireEvaluationHeader, setDefaultHeader }}
    >
      {children}
    </AppHeaderContext.Provider>
  );
};
