import * as msal from "@azure/msal-browser";
import _envVar from "../../config/variables";
import { baseMsalConfig, ibhsB2CPolicies, loginRequest } from ".";

// Specific configuration for Wildfire, extending the base configuration
export const wildfireMsalConfig = {
  ...baseMsalConfig,
  auth: {
    ...baseMsalConfig.auth,
    authority: ibhsB2CPolicies.authorities.signUpSignIn.wildfireAuthority,
    redirectUri: _envVar.wildfire_RedirectUri,
    postLogoutRedirectUri: _envVar.wildfire_PostLogoutRedirectUri,
  },
};

export const wildfireMsalInstance = new msal.PublicClientApplication(
  wildfireMsalConfig
);

export const logOutWildfireUser = async () => {
  const loggedInAccount = wildfireMsalInstance.getActiveAccount();

  await wildfireMsalInstance.logoutRedirect({
    account: loggedInAccount,
    postLogoutRedirectUri: wildfireMsalConfig.auth.postLogoutRedirectUri,
  });
};

export const getWildfireAccessToken = async () => {
  try {
    const token = await wildfireMsalInstance.acquireTokenSilent(loginRequest);
    return token.accessToken;
  } catch (e) {
    wildfireMsalInstance.loginRedirect(loginRequest);
  }
};
