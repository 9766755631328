import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { Evaluation_CommercialRedesignation } from "../../types/CommercialRedesignation";
import { Evaluation_CommercialRedesignationFormModel } from "../../types/CommercialRedesignationFormModel";

class CommercialRedesignationAPIClient {
  createCommercialRedesignation = async (parentEvaluationId: string) => {
    return baseApiClient.post<Evaluation_CommercialRedesignation>(
      `commercial/redesignation/${parentEvaluationId}`
    );
  };

  getCommercialEvaluation = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_CommercialRedesignation>(
      `commercial/redesignation/${evaluationId}`
    );
  };

  getCommercialRedesignationFormModel = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_CommercialRedesignationFormModel>(
      `commercial/redesignation/${evaluationId}`
    );
  };
}

export const commercialRedesignationAPIClient =
  new CommercialRedesignationAPIClient();
