import { fortifiedHomeRoutePrefix } from "../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { getFortifiedCommercialMultifamilyAccessToken } from "./fortifiedCommercialMultifamilyMsal";
import { getFortifiedHomeAccessToken } from "./fortifiedHomeMsal";

export const getAccessTokenFromRoute = (): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const currentPath = window.location.pathname;
    const fh = currentPath.startsWith(fortifiedHomeRoutePrefix);

    let accessTokenMethod;

    if (fh) {
      accessTokenMethod = getFortifiedHomeAccessToken;
    } else {
      accessTokenMethod = getFortifiedCommercialMultifamilyAccessToken;
    }

    accessTokenMethod().then(resolve).catch(reject);
  });
};
