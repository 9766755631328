import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
} from "react";
import { WildfireEvaluation } from "../../types/WildfireApplication/WildfireApplication";
import { WildfireRejectionFormModel } from "../Dialogs/Rejection/WildfireRejectionForm";
import { AppHeaderContext } from "./AppHeaderContext";
import { useWildfireEvaluation } from "../../customHooks/useWildfireEvaluation";
import { isNil } from "lodash";

interface WildfireEvaluationContexProps {
  wildfireEvaluation: WildfireEvaluation | null | undefined;
  setWildfireEvaluationById?: () => void;
  evaluationFieldkeys?: string[] | undefined;
  refreshWildfireEvaluation: () => Promise<void>;
  isApplicationLoading: boolean;
  areFieldKeysLoading: boolean;
  sendForward: () => Promise<void>;
  isSendForwardLoading: boolean;
  approve: () => Promise<void>;
  isApproveLoading: boolean;
  reject: (form: WildfireRejectionFormModel) => Promise<void>;
  isRejectLoading: boolean;
  requestChanges: () => Promise<void>;
  isRequestChangesLoading: boolean;
  downloadCertificate: () => Promise<unknown>;
  regenerateCertificate: () => Promise<unknown>;
}

export const WildfireEvaluationContext =
  createContext<WildfireEvaluationContexProps>({
    wildfireEvaluation: null,
    isApplicationLoading: false,
    areFieldKeysLoading: false,
    isSendForwardLoading: false,
    isApproveLoading: false,
    isRejectLoading: false,
    isRequestChangesLoading: false,
    refreshWildfireEvaluation: async () => {
      console.warn("refreshWildfireApplication not implemented");
    },
    sendForward: async () => {
      console.warn("sendForward not implemented");
    },
    approve: async () => {
      console.warn("approve not implemented");
    },
    reject: async () => {
      console.warn("reject not implemented");
    },
    requestChanges: async () => {
      console.warn("requestChanges not implemented");
    },
    downloadCertificate: async () => {
      console.warn("downloadCertificate not implemented");
    },
    regenerateCertificate: async () => {
      console.warn("regenerateCertificate not implemented");
    },
  });

export function WildfireEvaluationProvider(
  props: React.PropsWithChildren<{
    evaluationId?: string;
    setAppHeader?: Dispatch<SetStateAction<string>>;
  }>
) {
  const {
    wildfireEvaluation,
    evaluationFieldkeys,
    setWildfireEvaluationById,
    refreshWildfireEvaluation,
    isApplicationLoading,
    areFieldKeysLoading,
    sendForward,
    isSendForwardLoading,
    approve,
    isApproveLoading,
    reject,
    isRejectLoading,
    requestChanges,
    isRequestChangesLoading,
    downloadCertificate,
    regenerateCertificate,
    getEvaluationFieldkeys,
  } = useWildfireEvaluation();

  const { setWildfireEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (!wildfireEvaluation) {
      setWildfireEvaluationById(props.evaluationId);
      getEvaluationFieldkeys(props.evaluationId);
    } else {
      setWildfireEvaluationHeader(wildfireEvaluation);
    }
    return () => {
      setDefaultHeader();
    };
  }, []);

  useEffect(() => {
    if (!isNil(wildfireEvaluation)) {
      setWildfireEvaluationHeader(wildfireEvaluation);
    }
  }, [wildfireEvaluation]);

  return (
    <WildfireEvaluationContext.Provider
      value={{
        wildfireEvaluation,
        evaluationFieldkeys,
        setWildfireEvaluationById,
        refreshWildfireEvaluation,
        isApplicationLoading,
        areFieldKeysLoading,
        sendForward,
        isSendForwardLoading,
        approve,
        isApproveLoading,
        reject,
        isRejectLoading,
        requestChanges,
        isRequestChangesLoading,
        downloadCertificate,
        regenerateCertificate,
      }}
    >
      {props.children}
    </WildfireEvaluationContext.Provider>
  );
}
