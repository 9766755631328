import { Alert, Intent } from "@blueprintjs/core";
import React from "react";
import { useState } from "react";
import { RelativityCardHeader } from "../Card/RelativityCardHeader";

export interface ConfirmDialogProps {
  isOpen: boolean;
  content: React.ReactNode;
  onConfirm?: () => Promise<{ isSuccess: boolean; error: any }>;
  closeDialog?: () => void;

  confirmHeaderText?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;

  shouldHideCancelButton?: boolean;
  confirmButtonIntent?: Intent;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Alert
      className="confirm-dialog"
      isOpen={props.isOpen}
      onConfirm={async () => {
        setIsLoading(true);
        const result = props.onConfirm && (await props.onConfirm());
        setIsLoading(false);

        if (result?.isSuccess) {
          props.closeDialog && props.closeDialog();
        } else if (result?.error) {
          props.closeDialog && props.closeDialog();
          throw result?.error;
        }
      }}
      onCancel={() => props.closeDialog && props.closeDialog()}
      loading={isLoading}
      cancelButtonText={
        props.shouldHideCancelButton
          ? undefined
          : !!props.cancelButtonText
          ? props.cancelButtonText
          : "No"
      }
      confirmButtonText={
        !!props.confirmButtonText ? props.confirmButtonText : "Yes"
      }
      intent={
        !!props.confirmButtonIntent ? props.confirmButtonIntent : "success"
      }
    >
      <>
        <RelativityCardHeader
          label={
            !!props.confirmHeaderText ? props.confirmHeaderText : "Confirm"
          }
        />
        <div className="confirm-dialog-content">{props.content}</div>
      </>
    </Alert>
  );
};

export default ConfirmDialog;
