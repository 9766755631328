import { Button, Classes, Dialog, Intent, RadioGroup } from "@blueprintjs/core";
import React from "react";
import { BaseDialogProps } from "../../types";
import { CommercialEvaluationOptions } from "../../../../types/schema";
import { EvaluationCreationOptions } from "../../../../types/evaluation/EvaluationTypes";
import { useCreateCommercialEvaluationDialog } from "./useCreateCommercialEvaluationDialog";
import CommercialEvaluationFieldInfoContainer from "./CommercialEvaluationFieldInfoContainer";
import { AsyncAutoCompleteField } from "../../../../../ucl/components/Fields/Dropdowns/AsyncAutoComplete/AsyncAutoCompleteField";
import variables from "../../../../../common/config/variables";
import { redesignationFooterHelperText } from "../CreateEvaluationHelpers";
import { MinimalSelectableLabel } from "../../../Fields/SelectableLabel/MinimalSelectableLabel";
import EvaluationHistory from "../../../Evaluation/History/EvaluationHistory";
import { useProductTypeHandler } from "../../../../providers/ProductTypeHandlerProvider";

export interface CreateCommercialEvaluationDialogProps
  extends BaseDialogProps {}

const CreateCommercialEvaluationDialog: React.FC<
  CreateCommercialEvaluationDialogProps
> = (props) => {
  const { getAccessToken } = useProductTypeHandler();

  const {
    selectedRedesignation,
    isSaving,
    selectedParentEvaluationOption,
    setParentEvaluationOption,
    creationType,
    setCreationType,
    isRedegButtonDisabled,
    closeDialog,
    selectableOptions,
    isfetching,
    submitNewCommercialRedesignation,
    submitNewCommercialEvaluation,
  } = useCreateCommercialEvaluationDialog(props);

  const newEvalautionHelperText = (
    <>
      <br />
      <div>You will be starting a new FORTIFIED Application, continue?</div>
    </>
  );

  return (
    <Dialog
      isOpen={props.isOpen}
      className="create-evaluation-dialog"
      hasBackdrop={false}
      isCloseButtonShown={true}
      onClose={closeDialog}
      title={<div>Create FC/FMF Evaluation</div>}
    >
      <div className={Classes.DIALOG_BODY}>
        <div>
          <MinimalSelectableLabel label={"Please Select an Option"} />
          <RadioGroup
            inline={true}
            onChange={(e) => {
              const value = e.currentTarget.value as EvaluationCreationOptions;
              setCreationType(value);
            }}
            selectedValue={creationType}
            options={selectableOptions.map((option, idx) => ({
              ...option,
              key: idx,
            }))}
          />
          {creationType === EvaluationCreationOptions.New &&
            newEvalautionHelperText}
          {creationType === EvaluationCreationOptions.Child && (
            <>
              <AsyncAutoCompleteField
                label="Search by FID"
                type="AsyncAutocomplete"
                disabled={isfetching || isSaving}
                apiBaseUrl={variables.apiBaseUrl}
                odataUrl={`${variables.apiBaseUrl}odata/commercialevaluationodata/options`}
                getAuthRequestHeader={getAccessToken}
                value={selectedParentEvaluationOption?.value}
                onSubmit={async (e) => {
                  const selectedOption = e as CommercialEvaluationOptions;
                  if (selectedOption === undefined) {
                    setParentEvaluationOption(undefined);
                  } else {
                    setParentEvaluationOption(selectedOption);
                  }
                }}
              />
              {selectedParentEvaluationOption && (
                <>
                  <CommercialEvaluationFieldInfoContainer
                    selectedOption={selectedParentEvaluationOption}
                    selectedChildOption={selectedRedesignation}
                  />
                  <EvaluationHistory
                    evaluationId={selectedParentEvaluationOption.id}
                    readonly={true}
                  />
                </>
              )}
              {redesignationFooterHelperText}
            </>
          )}
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text={"Cancel"}
          onClick={() => {
            closeDialog();
          }}
        />
        {creationType === EvaluationCreationOptions.New && (
          <Button
            intent={Intent.SUCCESS}
            text={"New Evaluation"}
            type="submit"
            onClick={async () => {
              await submitNewCommercialEvaluation();
            }}
            loading={isSaving}
          />
        )}
        {creationType === EvaluationCreationOptions.Child && (
          <Button
            intent={Intent.SUCCESS}
            minimal={isRedegButtonDisabled}
            disabled={isRedegButtonDisabled}
            loading={isSaving || isfetching}
            text={"New Redesignation"}
            type="submit"
            onClick={async () => {
              await submitNewCommercialRedesignation();
            }}
          />
        )}
      </div>
    </Dialog>
  );
};

export default CreateCommercialEvaluationDialog;
