import { Button, Intent } from "@blueprintjs/core";
import "./styles.scss";
import { IconNames } from "@blueprintjs/icons";
import { useNavigate } from "react-router";
import { createNewWildfireApplication } from "../../../lib/application/applicationUtil";
import { useState } from "react";

export interface CreateWildfireApplicationCardProps {}
export const CreateWildfireApplicationCard: React.FC<
  CreateWildfireApplicationCardProps
> = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigateToNewWildfireApplication = async () => {
    navigate(await createNewWildfireApplication());
  };

  return (
    <div className="create-wildfire-application-container">
      <Button
        minimal
        className="create-wildfire-application-button"
        intent={Intent.PRIMARY}
        icon={IconNames.Plus}
        text="Click here to start your Home Application"
        loading={isLoading}
        onClick={async () => {
          setIsLoading(true);
          await navigateToNewWildfireApplication();
          setIsLoading(false);
        }}
      ></Button>
    </div>
  );
};
