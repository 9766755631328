import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { FCFMFApplicationFormParts } from "../../../common/types/Evaluation/BaseFCFMFApplicationForm";
import { MultifamilyApplicationFormModel } from "../../types/MultifamilyApplicationFormModel";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../types/MultifamilyEvaluationFormModel";

class MultifamilyEvaluationApplicationFormAPIClient {
  updateMultifamilyApplicationForm = async (
    evaluationId: string,
    multifamilyApplicationForm: MultifamilyApplicationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_MultifamilyEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_MultifamilyEvaluationFormModel>(
      `/multifamily/application-form/${evaluationId}`,
      {
        multifamilyApplicationForm,
        fieldKey,
      }
    );
  };

  submitMultifamilyApplicationForm = async (
    evaluationId: string,
    applicationForm: MultifamilyApplicationFormModel,
    applicationFormPart?: FCFMFApplicationFormParts
  ): Promise<Evaluation_MultifamilyEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const applicationFormPartValue = (
      applicationFormPart ??
      (Object.values(
        FCFMFApplicationFormParts
      ).pop() as FCFMFApplicationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_MultifamilyEvaluationFormModel>(
      `/multifamily/application-form/${evaluationId}/${applicationFormPartValue}`,
      applicationForm
    );
  };
}

export const multifamilyEvaluationApplicationFormAPIClient =
  new MultifamilyEvaluationApplicationFormAPIClient();
