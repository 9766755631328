import "./styles.scss";
import { EvaluationCommentV2 } from "../types/EvaluationCommentV2";
import { convertDateTo_EST_timeZone_AsString } from "../../../../common/utils/momentTimeZone";
import { Button, TextArea } from "@blueprintjs/core";
import { useState } from "react";
import { wildfireUserStore } from "../../../stores/WildfireUserStore";

export interface ApplicantIterationEngineCommentPanelProps {
  comments: EvaluationCommentV2[];
  unsavedComments: EvaluationCommentV2[];
  setUnsavedComments: (comments: EvaluationCommentV2[]) => void;
  isDisabled?: boolean;
}

const ApplicantIterationEngineCommentPanel: React.FC<
  ApplicantIterationEngineCommentPanelProps
> = (props) => {
  const [inputComment, setInputComment] = useState<string>("");

  const sortedComments = [
    ...props.unsavedComments.map((comment) => ({
      ...comment,
      createdAt: convertDateTo_EST_timeZone_AsString(comment.createdAt),
    })),
    ...props.comments.map((comment) => ({
      ...comment,
      createdAt: convertDateTo_EST_timeZone_AsString(comment.createdAt),
    })),
  ].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  const addComment = async () => {
    props.setUnsavedComments([
      ...props.unsavedComments,
      {
        comment: inputComment,
        createdBy_AsName: wildfireUserStore.fullUserName,
        role_AsString: wildfireUserStore.displayRole,
        createdAt: new Date().toISOString(),
      } as EvaluationCommentV2,
    ]);
    setInputComment("");
  };

  return (
    <>
      <div className="comment-list-scroll-container">
        <div className="comment-list-view">
          <div className="comment-list-view-title">Comments</div>
          {sortedComments.map((comment) => (
            <div key={comment.id} className="comment-list-view_item">
              <div className="comment-list-view_item_line-1">
                <div className="comment-list-view_item_line-1_name">
                  {comment.createdBy_AsName}
                  {comment.role_AsString && ` (${comment.role_AsString})`}
                </div>
              </div>
              <div className="comment-list-view_item_line-1_time">
                {comment.createdAt}
              </div>
              <div className="comment-list-view_item_line-2">
                {comment.comment}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="comment-input">
        <TextArea
          className="comment-textarea"
          rows={5}
          value={inputComment}
          placeholder="Add a new comment..."
          onChange={(e) => setInputComment(e.target.value)}
          disabled={props.isDisabled}
        />
        <div className="comment-input_actions">
          <Button
            text="Submit Comment"
            intent="primary"
            className="comment-input__submit-button"
            disabled={props.isDisabled}
            onClick={addComment}
          />
        </div>
      </div>
    </>
  );
};

export default ApplicantIterationEngineCommentPanel;
