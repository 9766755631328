import {
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
  SmartAddressFieldProps,
} from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { Evaluation_HomeSCSHEvaluationFormModel } from "../../../types/HomeSCSHEvaluationFormModel";
import { addressApiClient } from "../../../../../../foritfied/lib/apiClients/address/addressApiClient";

const modelName = "Evaluation";
const subModelName = "HomeSCSHEvaluation";
const formName = "HomeSCSHEvaluationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

//Part 1: Homeowner Information
const informationFields = () => [
  buildFieldKey("ApplicationNumber"),
  buildFieldKey("AdminName"),
  buildFieldKey("Email"),

  buildFieldKey("RoofContractorName"),
  buildFieldKey("RoofContractorCompany"),
  buildFieldKey("RoofContractorEmail"),
  buildFieldKey("RoofContractorPhone"),
  {
    fieldKey: buildFieldKey("RoofContractorAddress"),
    getAddressesByQuery: addressApiClient.getAddressesByQuery,
    getAddressByQuery: addressApiClient.getAddressByQuery,
    isSmartOnly: false,
  } as SmartAddressFieldProps,

  buildFieldKey("InspectorName"),
  buildFieldKey("InspectorCompany"),
  buildFieldKey("InspectorEmail"),
  buildFieldKey("InspectorPhone"),
  {
    fieldKey: buildFieldKey("InspectorAddress"),
    getAddressesByQuery: addressApiClient.getAddressesByQuery,
    getAddressByQuery: addressApiClient.getAddressByQuery,
    isSmartOnly: false,
  } as SmartAddressFieldProps,

  buildFieldKey("DwellingType"),
  buildFieldKey("HUDZone"),
  buildFieldKey("NumberOfStories"),
  buildFieldKey("WindSpeed"),
  buildFieldKey("BuildingCode"),
  buildFieldKey("FoundationType"),
  buildFieldKey("SealedRoofDeckType"),
  buildFieldKey("RoofCoveringSystem"),
];

const informationRequiredFields = [
  buildFieldKey("ApplicationNumber"),
  buildFieldKey("AdminName"),
  buildFieldKey("Email"),

  buildFieldKey("RoofContractorName"),
  buildFieldKey("RoofContractorCompany"),
  buildFieldKey("RoofContractorEmail"),
  buildFieldKey("RoofContractorPhone"),
  buildFieldKey("RoofContractorAddress"),

  buildFieldKey("InspectorName"),
  buildFieldKey("InspectorCompany"),
  buildFieldKey("InspectorEmail"),
  buildFieldKey("InspectorPhone"),
  buildFieldKey("InspectorAddress"),

  buildFieldKey("DwellingType"),
  buildFieldKey("HUDZone"),
  buildFieldKey("NumberOfStories"),
  buildFieldKey("WindSpeed"),
  buildFieldKey("BuildingCode"),
  buildFieldKey("FoundationType"),
  buildFieldKey("SealedRoofDeckType"),
  buildFieldKey("RoofCoveringSystem"),
];

export const homeSCSHEvaluationInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeSCSHEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeSCSHEvaluationFormModel>(
        modelName,
        informationFields(),
        onValueChange,
        form,
        errors,
        informationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeSCSHEvaluationFormModel>;
};

export const homeSCSHEvaluationFormInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeSCSHEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeSCSHEvaluationFormModel>(
        modelName,
        [...informationFields()],
        onValueChange,
        form,
        errors,
        [...informationRequiredFields],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeSCSHEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeSCSHIterationEngineEvaluationFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeSCSHEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeSCSHEvaluationFormModel>(
        modelName,
        [...informationFields()],
        onValueChange,
        form,
        errors,
        [...informationRequiredFields],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeSCSHEvaluationFormModel>;
};
