import FortifiedGridViewCreateDTO from "../../../types/dtos/FortifiedGridViewCreateDTO";
import FortifiedGridViewReadDTO from "../../../types/dtos/FortifiedGridViewReadDTO";
import FortifiedGridViewUpdateFilterSettingsDTO from "../../../types/dtos/FortifiedGridViewUpdateFilterSettingsDTO";
import baseApiClient from "../baseApiClient";

class FortifiedGridViewApiClient {
  fetchViews = async (gridId: string): Promise<FortifiedGridViewReadDTO[]> => {
    return await baseApiClient.get<FortifiedGridViewReadDTO[]>(
      `usergridview/${gridId}`
    );
  };

  createView = async (
    gridId: string,
    fortifiedGridViewCreateDTO: FortifiedGridViewCreateDTO
  ): Promise<FortifiedGridViewReadDTO> => {
    return await baseApiClient.post<FortifiedGridViewReadDTO>(
      `usergridview/${gridId}`,
      fortifiedGridViewCreateDTO
    );
  };

  updateViewFilterSettings = async (
    gridId: string,
    id: string,
    updateFilterSettingsDTO: FortifiedGridViewUpdateFilterSettingsDTO
  ): Promise<void> => {
    return await baseApiClient.put(
      `usergridview/${gridId}/${id}/updatefiltersettings`,
      updateFilterSettingsDTO
    );
  };

  updateViewColumnSettings = async (
    gridId: string,
    id: string,
    columnSettings: string
  ): Promise<void> => {
    return await baseApiClient.put(
      `usergridview/${gridId}/${id}/updatecolumnsettings`,
      { columnSettings: columnSettings }
    );
  };
}

export const fortifiedGridViewApiClient = new FortifiedGridViewApiClient();
