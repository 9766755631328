import { FC } from "react";
import { ExistingGridCommentForm } from "./ExistingGridCommentForm";
import { Card } from "@blueprintjs/core";
import { ExistingComment } from "../../../../common/apiClients/comments/types";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";

export interface ExistingGridCommentsViewProps {
  comments: ExistingComment[];
  markCommentAsRead: (commentId: string) => Promise<void>;
  markCommentAsUnread: (commentId: string) => Promise<void>;
  updateComment: (
    commentId: string,
    comment: ExistingComment,
    updatedMessage: string
  ) => Promise<void>;
  deleteComment: (commentId: string) => Promise<void>;
  isInitialized: boolean;
}

export const ExistingGridCommentsView: FC<ExistingGridCommentsViewProps> = ({
  comments,
  markCommentAsRead,
  markCommentAsUnread,
  updateComment,
  deleteComment,
  isInitialized,
}: ExistingGridCommentsViewProps) => {
  return (
    <div className="wildfire-existing-grid-comments-view">
      {isInitialized &&
        comments.map((comment) => (
          <Card
            className="wildfire-existing-grid-comments-view__comment-card"
            key={comment.id}
          >
            <ExistingGridCommentForm
              key={comment.id}
              comment={comment}
              markCommentAsRead={markCommentAsRead}
              markCommentAsUnread={markCommentAsUnread}
              updateComment={updateComment}
              deleteComment={deleteComment}
            />
          </Card>
        ))}
      {!isInitialized && <Loading />}
    </div>
  );
};
