import { useEffect, useState } from "react";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../common/Components/Error/ErrorStore";
import { Evaluation_HomeStandardEvaluation } from "../types/HomeEvaluation";
import { homeStandardEvaluationAPIClient } from "../lib/apiClients/homeStandardEvaluationAPIClient";

export const useHomeStandardEvaluation = (evaluationId: string) => {
  const [homeStandardEvaluation, setHomeStandardEvaluation] =
    useState<Evaluation_HomeStandardEvaluation | null>();
  const [isApplicationLoading, setIsApplicationLoading] = useState(false);

  useEffect(() => {
    getCommercialEvaluation(evaluationId);
  }, [evaluationId]);

  const getCommercialEvaluation = async (evaluationId: string) => {
    await homeStandardEvaluationAPIClient
      .getHomeStandardEvaluation(evaluationId)
      .then((response) => {
        setHomeStandardEvaluation(response);
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      });
  };

  const refreshHomeStandardEvaluation = async () => {
    setIsApplicationLoading(true);
    if (homeStandardEvaluation?.id) {
      await homeStandardEvaluationAPIClient
        .getHomeStandardEvaluation(homeStandardEvaluation?.id)
        .then((response) => {
          setHomeStandardEvaluation(response);
          //setWildfireEvaluationHeader(response);
        })
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        })
        .finally(() => {
          setIsApplicationLoading(false);
        });
    }
  };

  return {
    homeStandardEvaluation,
    refreshHomeStandardEvaluation,
    isApplicationLoading,
  };
};
