import {
  FieldSchemaFormBuilderV2,
  Schema,
  SchemaFactoryV2,
} from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  homeCustomFieldSchemaFormBuilders,
  homeFieldSchemaConfig,
  HomeFieldSchemaFormType,
  HomeFieldSchemaFormTypeList,
  HomeFieldSchemaModel,
} from "../common/components/FieldSchema/homeFieldSchemaConfig";
import { FieldSchemas } from "@ucl/library/lib/components/FieldSchema/types";
import { ChangeRequestV2 } from "../../../wildfire/components/IterationEngine/types/ChangeRequestV2";
import {
  homeIterationEngineFieldSchemaFormBuilders,
  HomeIterationEngineFieldSchemaFormType,
  HomeIterationEngineFieldSchemaFormTypeList,
} from "../common/components/FieldSchema/homeIterationEngineFieldSchemaConfig";
import { transformFieldsForIterationEngineBeforeBuild } from "../../FCFMF/common/util/transformFieldBeforeBuild";

export type HomeFieldSchemaType = {
  [key: string]: {
    associatedFieldKey?: string;
    fieldKey?: string;
  };
};

interface FieldSchemaFactoryContextProps {
  schemaFactory?: HomeSchemaFactoryV2;
  builders?: FieldSchemaBuilderConfig;
  wieBuilders?: HomeIterationEngineFieldSchemaBuilderConfig;
}

interface FieldSchemaFactoryProviderProps {
  schemaFactory: HomeSchemaFactoryV2;
  children: ReactNode;
  formOverrides?: FormOverrides;
}

export interface FormOverrides {
  onFieldChange?: (field: HomeFieldSchemaModel) => void;
  onLabelClick?: (fieldProps: BaseFieldProps<any>) => void;
  iterationEngineProps?: IterationEngineProps;
}

export interface HomeSchema extends Schema {
  schema: {
    Evaluation: FieldSchemas;
    [key: string]: FieldSchemas;
  };
  homeFieldSchema: HomeFieldSchemaType;
}

export class HomeSchemaFactoryV2 extends SchemaFactoryV2 {
  schema: HomeSchema;

  constructor(loadedSchema: Schema | null) {
    super(loadedSchema);
    this.schema = loadedSchema as HomeSchema;
  }
}

interface IterationEngineProps {
  fieldKeysWithChangeRequests?: string[];
  shouldEnableForm: boolean;
  changeRequests?: ChangeRequestV2[];
}

type FieldSchemaBuilderConfig = Record<
  HomeFieldSchemaFormType,
  Partial<FieldSchemaFormBuilderV2<HomeFieldSchemaModel>>
>;

type HomeIterationEngineFieldSchemaBuilderConfig = Record<
  HomeIterationEngineFieldSchemaFormType,
  Partial<FieldSchemaFormBuilderV2<HomeFieldSchemaModel>>
>;

const FieldSchemaFactoryContext = createContext<FieldSchemaFactoryContextProps>(
  {
    schemaFactory: undefined,
    builders: undefined,
  }
);

export const FieldSchemaFactoryProvider: React.FC<
  FieldSchemaFactoryProviderProps
> = (props) => {
  const [builders, setBuilders] = useState<FieldSchemaBuilderConfig>();
  const [wieBuilders, setWieBuilders] =
    useState<HomeIterationEngineFieldSchemaBuilderConfig>();

  const getBuilders = (factory: HomeSchemaFactoryV2) => {
    let builderList = {};
    for (const formIdx in HomeFieldSchemaFormTypeList) {
      if (
        homeCustomFieldSchemaFormBuilders.hasOwnProperty(
          HomeFieldSchemaFormTypeList[formIdx] as HomeFieldSchemaFormType
        )
      ) {
        const customFieldSchemaBuilderConfig =
          homeCustomFieldSchemaFormBuilders[
            HomeFieldSchemaFormTypeList[formIdx]
          ](factory, (fields: BaseFieldProps<any>[]) => {
            return fields.map((field) => {
              return field;
            });
          });
        builderList = {
          ...builderList,
          [HomeFieldSchemaFormTypeList[formIdx]]:
            customFieldSchemaBuilderConfig,
        };
      } else {
        const config =
          homeFieldSchemaConfig[
            HomeFieldSchemaFormTypeList[formIdx] as HomeFieldSchemaFormType
          ];
        if (config) {
          const fieldSchemaBuilderConfig =
            factory.buildFieldSchemaFormBuilder<HomeFieldSchemaModel>(config);

          builderList = {
            ...builderList,
            [HomeFieldSchemaFormTypeList[formIdx]]: fieldSchemaBuilderConfig,
          };
        }
      }
    }
    setBuilders(builderList as FieldSchemaBuilderConfig);
  };

  const getWieBuilders = (factory: HomeSchemaFactoryV2) => {
    let builderList = {};
    for (const formIdx in HomeIterationEngineFieldSchemaFormTypeList) {
      if (
        homeIterationEngineFieldSchemaFormBuilders.hasOwnProperty(
          HomeIterationEngineFieldSchemaFormTypeList[
            formIdx
          ] as HomeIterationEngineFieldSchemaFormType
        )
      ) {
        const wieFieldSchemaBuilderConfig =
          homeIterationEngineFieldSchemaFormBuilders[
            HomeIterationEngineFieldSchemaFormTypeList[formIdx]
          ](factory, (fields: BaseFieldProps<any>[]) => {
            // Transform fields for iteration engine
            return fields.map((field) => {
              const transformedField =
                transformFieldsForIterationEngineBeforeBuild(
                  field,
                  props.formOverrides
                );

              return transformedField;
            });
          });

        builderList = {
          ...builderList,
          [HomeIterationEngineFieldSchemaFormTypeList[formIdx]]:
            wieFieldSchemaBuilderConfig,
        };
      }
    }
    setWieBuilders(builderList as HomeIterationEngineFieldSchemaBuilderConfig);
  };

  useEffect(() => {
    if (props.schemaFactory) {
      getBuilders(props.schemaFactory);
      getWieBuilders(props.schemaFactory);
    }
  }, [props.schemaFactory]);

  useEffect(() => {
    if (props.schemaFactory) {
      getWieBuilders(props.schemaFactory);
    }
  }, [props.formOverrides?.iterationEngineProps?.changeRequests]);

  return (
    <FieldSchemaFactoryContext.Provider
      value={{
        schemaFactory: props.schemaFactory,
        builders,
        wieBuilders,
      }}
    >
      {props.children}
    </FieldSchemaFactoryContext.Provider>
  );
};

export const useHomeFieldSchemaFactory = () =>
  useContext(FieldSchemaFactoryContext);
