import { EmailFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum FCFMFComplianceFormParts {
  EvaluatorEmail = "evaluator-email",
  ComplianceFormPhotoUploader = "compliance-form-photo-uploader",
}

export interface BaseFCFMFComplianceFormModel
  extends BaseFCFMFComplianceFormFileUploaders {
  evaluatorEmail: EmailFieldValue;
}

interface BaseFCFMFComplianceFormFileUploaders {
  complianceFormPhotoUploader: WildfireBoxContentExplorerValue;
}
