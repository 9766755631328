import { PrimaryCard } from "@ucl/library";
import { EvaluationStep } from "../../../../../../foritfied/types/evaluation/Evaluation";
import { useContext, useEffect, useState } from "react";
import { UnsavedChangesBlockerContext } from "../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import classNames from "classnames";
import MultifamilyRedesignationForm from "../ApplicationForm/Form/MultifamilyRedesignationForm";
import MultifamilyRedesignationEvaluatorEmailForm from "../RedesignationEvaluatorEmailForm/Form/MultifamilyRedesignationEvaluatorEmailForm";

interface IterationEngineFormsProps {
  evaluationId: string;
  evaluationStep: EvaluationStep;
  onFormSave: (fieldKey: string) => Promise<void>;
  onAllFormsInitialized: () => void;
  fieldsContainerClassName: string;
  selectedTabId: string;
}

const MultifamilyRedesignationIterationEngineForms: React.FC<
  IterationEngineFormsProps
> = (props) => {
  //const loadEvaluationForms = props.evaluationStep === EvaluationStep.FinalReview;
  const [redesignationFormInitiaized, setRedesignationFormInitiaized] =
    useState<boolean>();

  const [
    redesignationEvaluatorEmailFormInitiaized,
    setRedesignationEvaluatorEmailFormInitiaized,
  ] = useState<boolean>();

  const [allFormsInitialized, setAllFormsInitialized] = useState(false);

  useEffect(() => {
    const formsInitialized =
      redesignationFormInitiaized && redesignationEvaluatorEmailFormInitiaized;

    if (formsInitialized) {
      setTimeout(() => {
        setAllFormsInitialized(true);
        props.onAllFormsInitialized();
      }, 600);
    } else {
      setAllFormsInitialized(false);
    }
  }, [redesignationFormInitiaized, redesignationEvaluatorEmailFormInitiaized]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const getFormAndHeaderContent = () => {
    switch (props.selectedTabId) {
      case "MultifamilyRedesignationEvaluatorEmailForm":
        return {
          headerContent: "Multifamily Redesignation Evaluator Email Form",
          form: (
            <MultifamilyRedesignationEvaluatorEmailForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRedesignationEvaluatorEmailFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      default:
        return {
          headerContent: "Multifamily Redesignation Form",
          form: (
            <MultifamilyRedesignationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRedesignationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
    }
  };

  const { headerContent, form } = getFormAndHeaderContent();

  return (
    <>
      {!allFormsInitialized && <Loading />}
      <div
        className={classNames(props.fieldsContainerClassName, {
          "evaluation-forms-container-initialized": allFormsInitialized,
        })}
      >
        <PrimaryCard headerContent={headerContent}>{form}</PrimaryCard>
      </div>
    </>
  );
};

export default MultifamilyRedesignationIterationEngineForms;
