import {
  Column,
  PredicateModel,
  SortDescriptorModel,
} from "@syncfusion/ej2-react-grids";
import moment from "moment";

export class FortifiedGridFiltersModuleStore {
  private filterPredicateText = {
    and: "AND",
    or: "OR",
  };

  private filterOperatorText = {
    startswith: "Starts with",
    endswith: "Ends with",
    contains: "Contains",
    equal: "Equal to",
    notequal: "Not Equal to",
    greaterthan: "Greater than",
    greaterthanorequal: "Greater than or Equal To",
    lessthan: "Less than",
    lessthanorequal: "Less than or Equal To",
  };

  private valueOrEmpty = (value: string): string => {
    return ["", "null"].includes(value) ? "empty" : value;
  };

  // Sort
  public getSortDetails = (
    columns?: Column[],
    sorts?: SortDescriptorModel[]
  ) => {
    const sortDetails = sorts?.map((x) => ({
      field: String(x.field),
      details: this.getSortText(columns, x),
      direction: x.direction,
    }));

    return sortDetails;
  };

  public getSortText = (
    columns?: Column[],
    sort?: SortDescriptorModel
  ): string => {
    const col = columns?.find((x) => x.field === sort?.field);

    return `Order: ${col?.headerText}`;
  };

  // Filter
  public getFilterDetails = (filters?: PredicateModel[]) => {
    const distinctFields = filters
      ?.map((item) => item.field)
      .filter((value, index, self) => self.indexOf(value) === index);

    const filterDetails = distinctFields?.map((field) => ({
      field: String(field),
      details: this.getFilterText(filters, field),
    }));
    return filterDetails;
  };

  public formatValue = (
    value: string | number | Date | boolean | undefined,
    type: string | undefined
  ): string => {
    if (type === "date") {
      return moment(String(value)).format("L");
    }
    return String(value);
  };

  public getFilterText = (
    filters?: PredicateModel[],
    field?: string
  ): string => {
    // Process Filter (Ex. [Equal to] [100] [OR] [Equal to] [1000])
    let details = "";
    const filterTextList: string[] = [];
    filters
      ?.filter((x) => x.field === field)
      .forEach((col, idx) => {
        const predicateText =
          idx > 0
            ? (this.filterPredicateText[
                col.predicate as keyof typeof this.filterPredicateText
              ] as string)
            : "";
        const operatorText =
          this.filterOperatorText[
            col.operator as keyof typeof this.filterOperatorText
          ];
        const filterText = `${operatorText} ${this.valueOrEmpty(
          this.formatValue(col.value, col.type)
        )}`;

        // Remove any duplicate
        if (filterTextList.includes(filterText)) {
          return;
        }

        filterTextList.push(filterText);

        details += ` ${predicateText} ${filterText} `.trimStart();
      });

    return details;
  };
}

export const fortifiedGridFiltersModuleStore =
  new FortifiedGridFiltersModuleStore();
