import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { FCFMFEvaluationCompanyAssignmentFormParts } from "../../common/types/Evaluation/BaseFCFMFEvaluationCompanyAssignmentForm";
import MultifamilyEvaluationCompanyAssignmentFormContainer from "../components/forms/EvaluationCompanyAssignmentForm/Container/MultifamilyEvaluationCompanyAssignmentFormContainer";

export interface MultifamilyEvaluationCompanyAssignmentProps {
  evaluationId?: string;
  evaluationCompanyAssignmentFormPart?: FCFMFEvaluationCompanyAssignmentFormParts;
}

const MultifamilyEvaluationCompanyAssignmentFormPageComponent: FC<
  MultifamilyEvaluationCompanyAssignmentProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <MultifamilyEvaluationCompanyAssignmentFormContainer
            evaluationId={props.evaluationId}
            evaluationCompanyAssignmentFormPart={
              props.evaluationCompanyAssignmentFormPart
            }
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const MultifamilyEvaluationCompanyAssignmentFormPage = withAITracking(
  reactPlugin,
  MultifamilyEvaluationCompanyAssignmentFormPageComponent,
  "MultifamilyEvaluationCompanyAssignmentFormPageComponent",
  "tracking-container"
);
