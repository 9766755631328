import { useEffect, useState } from "react";
import {
  FCFMFSchemaFactoryV2,
  FieldSchemaFactoryProvider,
} from "../../../common/customHooks/useFCFMFFieldSchemaFactory";
import { useCommercialRedesignationIterationEngine } from "./useCommercialRedesignationIterationEngine";
import FortifiedIterationEngineChecklistAndTabs from "../../../../Common/components/IterationEngine/ChecklistAndTabs/FortifiedIterationEngineChecklistAndTabs";
import { EvaluationStep } from "../../../../../foritfied/types/evaluation/Evaluation";
import CommercialRedesignationIterationEngineHeader from "./CommercialRedesignationIterationEngineHeader";
import CommercialRedesignationIterationEngineFormsContainer from "../forms/EvaluationForm/CommercialRedesignationIterationEngineFormsContainer";
import FortifiedIterationEngineCommentPanel from "../../../../Common/components/IterationEngine/FortifiedIterationEngineCommentPanel";

export interface CommercialRedesignationIterationEngineProps {
  evaluationId: string;
  schemaFactory: FCFMFSchemaFactoryV2;
}

const CommercialRedesignationIterationEngine: React.FC<
  CommercialRedesignationIterationEngineProps
> = (props) => {
  const {
    //Evaluation
    commercialRedesignation,
    //Comments
    comments,
    createComment,
    deleteComment,
    selectedKey,
    selectedField,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
    //ChangeRequests
    allChangeRequests,
    resolveChangeRequest,
    unresolveChangeRequest,
    unresolvedChangeRequests,
    getChangeRequests,
    changeRequestCount,
    resolvedChangeRequestCount,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    resolvedChangeRequestsPercentage,
    //Iteration Engine
    setSelectedIterationEngineFormTabId,
    selectedIterationEngineFormTabId,
    canEditForm,
    formTabs,
  } = useCommercialRedesignationIterationEngine(
    props.evaluationId,
    props.schemaFactory.schema
  );

  const [evaluationFormInitialized, setEvaluationFormInitialized] =
    useState(false);

  useEffect(() => {
    setEvaluationFormInitialized(false);
  }, [props.evaluationId, selectedIterationEngineFormTabId]);

  return (
    <section className="wildfire-admin-iteration-engine">
      {commercialRedesignation && (
        <FieldSchemaFactoryProvider
          schemaFactory={props.schemaFactory}
          formOverrides={{
            onLabelClick: (fieldProps) =>
              setSelectedFieldAndGetComments?.(
                commercialRedesignation.id,
                fieldProps
              ),
            iterationEngineProps: {
              fieldKeysWithChangeRequests:
                getEvaluationFieldKeysForAllUnresolvedChangeRequests,
              shouldEnableForm: canEditForm,
              changeRequests: allChangeRequests,
            },
          }}
        >
          <div className="wildfire-iteration-engine">
            <div className="wildfire-iteration-engine__header">
              <CommercialRedesignationIterationEngineHeader
                evaluationId={props.evaluationId}
              />
            </div>
            <div className="wildfire-iteration-engine__content">
              <div className="wildfire-iteration-engine__left-panel">
                <FortifiedIterationEngineChecklistAndTabs
                  evaluationStep={commercialRedesignation.step}
                  evaluationStatus={commercialRedesignation.status_AsString}
                  selectedTabId={selectedIterationEngineFormTabId}
                  setSelectedTabId={setSelectedIterationEngineFormTabId}
                  formTabs={formTabs}
                  evaluationStepDetails={[
                    {
                      id: EvaluationStep.Application,
                      label: "Application",
                    },
                    {
                      id: EvaluationStep.EvaluatorAssignment,
                      label: "Evaluator Assignment",
                    },
                    {
                      id: EvaluationStep.FieldInspectionsSubmitted,
                      label: "Field Inspections",
                    },
                    {
                      id: EvaluationStep.EvaluatorComplianceLetterSubmitted,
                      label: "Compliance Letter",
                    },
                    {
                      id: EvaluationStep.FinalReview,
                      label: "IBHS Review",
                    },
                  ]}
                />
              </div>
              <div className="wildfire-iteration-engine__center-panel">
                <CommercialRedesignationIterationEngineFormsContainer
                  fieldsContainerClassName="evaluation-forms-container"
                  selectedTabId={selectedIterationEngineFormTabId}
                  changeRequests={allChangeRequests}
                  evaluationId={props.evaluationId}
                  evaluationStep={commercialRedesignation.step}
                  getChangeRequests={getChangeRequests}
                  onEvaluationFormInitialized={() => {
                    setEvaluationFormInitialized(true);
                  }}
                />
              </div>
              <div className="wildfire-iteration-engine__right-panel">
                <FortifiedIterationEngineCommentPanel
                  fieldsContainerClassName="evaluation-forms-container"
                  isOpen={evaluationFormInitialized}
                  changeRequests={allChangeRequests}
                  unresolvedChangeRequests={unresolvedChangeRequests}
                  setSelectedIterationEngineFormTabId={
                    setSelectedIterationEngineFormTabId
                  }
                  selectedIterationEngineFormTabId={
                    selectedIterationEngineFormTabId
                  }
                  selectedKey={selectedKey}
                  comments={comments}
                  createComment={(
                    comment,
                    evaluationFieldKey,
                    evaluationFieldLabel,
                    isPublic
                  ) =>
                    createComment(
                      commercialRedesignation.id,
                      comment,
                      evaluationFieldKey,
                      evaluationFieldLabel,
                      isPublic
                    )
                  }
                  selectedField={selectedField}
                  deleteComment={(commentId, evaluationFieldKey) =>
                    deleteComment(
                      commercialRedesignation.id,
                      commentId,
                      evaluationFieldKey
                    )
                  }
                  setSelectedKeyAndGetComments={(fieldKey) =>
                    setSelectedKeyAndGetComments!(
                      commercialRedesignation.id,
                      fieldKey
                    )
                  }
                  allChangeRequests={allChangeRequests}
                  changeRequestCount={changeRequestCount}
                  resolvedChangeRequestCount={resolvedChangeRequestCount}
                  resolvedChangeRequestsPercentage={
                    resolvedChangeRequestsPercentage
                  }
                  unresolveChangeRequest={unresolveChangeRequest}
                  resolveChangeRequest={resolveChangeRequest}
                  getChangeRequests={getChangeRequests}
                />
              </div>
            </div>
          </div>
        </FieldSchemaFactoryProvider>
      )}
    </section>
  );
};

export default CommercialRedesignationIterationEngine;
