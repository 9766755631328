import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { BaseDialogProps } from "./types";
import { evaluationStore } from "../../stores/EvaluationStore";
import { useEffect, useState } from "react";
import { Evaluation } from "../../types/evaluation/Evaluation";
import "./styles.scss";

export interface LegacyEvaluationDataDialogDialogProps
  extends BaseDialogProps {}

export const LegacyEvaluationDataDialog: React.FC<
  LegacyEvaluationDataDialogDialogProps
> = ({ isOpen, closeDialog }) => {
  const [legacyEvaluation, setLegacyEvaluation] = useState<Evaluation>();

  useEffect(() => {
    setLegacyEvaluation(evaluationStore.evaluation);
  }, [evaluationStore.evaluation]);

  const fieldsToHide = [
    "evaluationForms",
    "otherEvaluationsHaveSameAddress",
    "isNonProfitProject",
    "paymentLink",
    "hasSufficientPaymentCredits",
    "fortifiedHomeThirdPartyFolderId",
    "hazard",
    "designationLevel",
    "buildingState",
    "businessState",
    "isLegacyEvaluation",
    "isEvaluationClaimed",
    "canWithdraw",
    "approvalType",
    "step",
    "productType",
    "isRedesignation",
    "originEvaluationId",
    "parentEvaluationId",
    "complexCommercialAndMultifamilyEvaluationId",
    "isComplexCommercialEvaluation",
    "isComplexCommercialEvaluationBuilding",
    "hasFinalCertificateFeePaymentBeenProcessed",
    "hasFCFMFPaymentWorkflow",
    "areBuildingsFinishedBeingProcessed",
    "isComplexCommercialEvaluationBuildingBlockingParentFromApproval",
    "evaluationBids",
    "hasAnEvaluationBidBeenAccepted",
  ];
  //2022-11-08T17:49:36.32
  return (
    <Dialog
      className="legacy-evaluation-data-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
    >
      <div className={Classes.DIALOG_HEADER}>FOCUS 1.0 Data</div>
      <div className={Classes.DIALOG_BODY}>
        <table className="legacy-evaluation-table">
          <thead>
            <tr>
              <th className="legacy-evaluation-header">Field Label</th>
              <th className="legacy-evaluation-header">Data</th>
            </tr>
          </thead>
          <tbody>
            {legacyEvaluation &&
              Object.keys(legacyEvaluation)
                .filter((key) => !fieldsToHide.includes(key))
                .map((key) => (
                  <tr key={key}>
                    <td className="legacy-evaluation-cell legacy-evaluation-label">
                      {key}
                    </td>
                    <td className="legacy-evaluation-cell legacy-evaluation-data">
                      {legacyEvaluation[key as keyof Evaluation]?.toString()}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text={"Cancel"}
          onClick={closeDialog}
        />
      </div>
    </Dialog>
  );
};
