import {
  FieldFormV2,
  FieldSchemaFormBuilderV2,
  RelativityTooltip,
} from "@ucl/library";
import { Button, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useNavigate } from "react-router";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import "../styles.scss";
import { useOtherFieldEvaluationForm } from "./useOtherFieldEvaluationForm";
import { EvaluationFormModel } from "../../../forms/EvaluationFormModel";
import { OtherFieldEvaluationFormParts } from "../../../forms/OtherFieldEvaluationForm/OtherFieldEvaluationFormModel";
import classnames from "classnames";
import { LandscapeFieldEvaluationFormParts } from "../../../forms/LandscapeFieldEvaluationForm/LandscapeFieldEvaluationFormModel";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";

export interface OtherFieldEvaluationFormProps {
  wildfireEvaluationId: string;
  isWiePage?: boolean;
  formPart?: OtherFieldEvaluationFormParts;
  formModel?: EvaluationFormModel;
  setFormModel?: (model: EvaluationFormModel) => void;
  showFormControls?: boolean;
  onFormSave?: (fieldKey: string, value: any) => Promise<void>;
  disableForm?: boolean;
  onInitialized?: () => void;
  setHasUnsavedChanges: (value: boolean) => void;
}

export const OtherFieldEvaluationForm = (
  props: OtherFieldEvaluationFormProps
) => {
  const navigate = useNavigate();

  const {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    otherFieldEvaluationFormModel,
    setShouldSubmit,
    isFirstPartOfForm,
    isLastPartOfForm,
    getOtherFieldEvaluationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
  } = useOtherFieldEvaluationForm(props);

  const isReadyForIBHSReview =
    props.formModel?.wildfireApplication
      ?.areFieldEvaluationFormsReadyForIBHSReview;
  const isBlockedFromSubmitting = isLastPartOfForm && !isReadyForIBHSReview;

  const noBuilders = !builders && !wieBuilders;
  return (
    <div className="wildfire-form-container">
      {noBuilders || isLoading ? (
        <Loading />
      ) : !otherFieldEvaluationFormModel ? (
        <NonIdealState />
      ) : (
        <>
          <FieldFormV2<EvaluationFormModel>
            isDisabled={isSubmitting || props.disableForm}
            value={otherFieldEvaluationFormModel}
            fieldSchemaFormBuilder={
              getOtherFieldEvaluationFormPartBuilder() as FieldSchemaFormBuilderV2<EvaluationFormModel>
            }
            onFormSubmit={async (form) => handleFormSubmit(form)}
            ref={formRef}
            onFieldChanged={async (form, value, fieldProps) => {
              await onFormFieldChange(form, value, fieldProps);
            }}
          />

          {(props.showFormControls ?? true) && (
            <div className="wildfire-form-footer">
              <Button
                className={
                  "wildfire-form-back-button " +
                  "wildfire-form-navigation-button"
                }
                text="Back"
                icon={IconNames.ARROW_LEFT}
                onClick={() => {
                  if (isFirstPartOfForm) {
                    // Navigate to the last part of the previous form
                    navigate(
                      `${wildfireRoutePrefix}/${
                        props.wildfireEvaluationId
                      }/landscape-field-evaluation-form/${Object.values(
                        LandscapeFieldEvaluationFormParts
                      )
                        ?.pop()
                        ?.valueOf()}`
                    );
                  } else {
                    // Navigate to the previous part of the form
                    navigate(
                      `${wildfireRoutePrefix}/${
                        props.wildfireEvaluationId
                      }/other-field-evaluation-form/${Object.values(
                        OtherFieldEvaluationFormParts
                      )[
                        Object.values(OtherFieldEvaluationFormParts).indexOf(
                          props.formPart!
                        ) - 1
                      ].valueOf()}`
                    );
                  }
                }}
              />
              <RelativityTooltip
                shouldShowTooltip={isBlockedFromSubmitting}
                content={
                  "Please complete all required fields before submitting to IBHS for review."
                }
              >
                <Button
                  className={classnames({
                    "wildfire-form-submit-button":
                      isLastPartOfForm && isReadyForIBHSReview,
                    "wildfire-form-wide-button": isLastPartOfForm,
                    "wildfire-form-continue-button": !isLastPartOfForm,
                    "wildfire-form-navigation-button": !isLastPartOfForm,
                  })}
                  text={
                    isLastPartOfForm ? "Submit for IBHS Review" : "Continue"
                  }
                  rightIcon={
                    isLastPartOfForm ? IconNames.TICK : IconNames.ARROW_RIGHT
                  }
                  disabled={
                    isSubmitting || isBlockedFromSubmitting || props.disableForm
                  }
                  loading={isSubmitting}
                  onClick={() => {
                    if (formRef.current) {
                      props.setHasUnsavedChanges(false);
                      setShouldSubmit(true);
                      setIsSubmitting(true);
                      formRef.current.submit();
                    }
                  }}
                />
              </RelativityTooltip>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default OtherFieldEvaluationForm;
