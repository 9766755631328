import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { CommercialEvaluationFieldInspectionFormModel } from "../../types/CommercialFieldInspectionFormModel";
import { Evaluation_CommercialEvaluationFormModel } from "../../types/CommercialEvaluationFormModel";

class CommercialEvaluationFieldInspectionFormAPIClient {
  updateCommercialEvaluationFieldInspectionForm = async (
    evaluationId: string,
    evaluationFieldInspectionForm: CommercialEvaluationFieldInspectionFormModel,
    fieldKey?: string
  ): Promise<Evaluation_CommercialEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_CommercialEvaluationFormModel>(
      `/commercial/evaluation-field-inspection-form/${evaluationId}`,
      {
        evaluationFieldInspectionForm,
        fieldKey,
      }
    );
  };

  submitCommercialEvaluationFieldInspectionForm = async (
    evaluationId: string,
    evaluationFieldInspectionForm: CommercialEvaluationFieldInspectionFormModel
  ): Promise<Evaluation_CommercialEvaluationFormModel> => {
    return baseApiClient.post<Evaluation_CommercialEvaluationFormModel>(
      `/commercial/evaluation-field-inspection-form/${evaluationId}`,
      evaluationFieldInspectionForm
    );
  };
}

export const commercialEvaluationFieldInspectionFormAPIClient =
  new CommercialEvaluationFieldInspectionFormAPIClient();
