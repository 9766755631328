import { ChangeRequestV2 } from "../../../components/IterationEngine/types/ChangeRequestV2";
import wildfireBaseAPIClient from "../../wildfireBaseApiClient";

class WildfireChangeRequestApiClient {
  getChangeRequest = async (evaluationId: string, changeRequestId: string) => {
    return await wildfireBaseAPIClient.get<ChangeRequestV2>(
      `evaluation/${evaluationId}/change-request/${changeRequestId}`
    );
  };

  getChangeRequestsByEvaluation = async (evaluationId: string) => {
    return await wildfireBaseAPIClient.get<ChangeRequestV2[]>(
      `evaluation/${evaluationId}/change-request/v2`
    );
  };

  resolveChangeRequest = async (
    evaluationId: string,
    changeRequestId: string
  ) => {
    return await wildfireBaseAPIClient.put<void>(
      `evaluation/${evaluationId}/change-request/${changeRequestId}/status/resolution`
    );
  };

  unresolveChangeRequest = async (
    evaluationId: string,
    changeRequestId: string
  ) => {
    return await wildfireBaseAPIClient.put<void>(
      `evaluation/${evaluationId}/change-request/${changeRequestId}/status/unresolution`
    );
  };
}

export const wildfireChangeRequestApiClient =
  new WildfireChangeRequestApiClient();
