import { FieldEvaluationFormPageProps } from "./FieldEvaluationFormPage";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../common/types/Forms/FormChecklist";
import { EvaluationFormModel } from "../../forms/EvaluationFormModel";
import { LandscapeFieldEvaluationFormParts } from "../../forms/LandscapeFieldEvaluationForm/LandscapeFieldEvaluationFormModel";

export interface LandscapeFieldEvaluationFormPartsProps {
  fieldEvalautionFormProps: FieldEvaluationFormPageProps;
  fieldEvaluationFormModel: EvaluationFormModel | undefined;
}

const useLandscapeFieldEvaluationFormParts = (
  props: LandscapeFieldEvaluationFormPartsProps
) => {
  const landscapeFormModel =
    props.fieldEvaluationFormModel?.wildfireApplication
      .landscapeFieldEvaluationForm;

  const getNextLandscapeUnsubmittedFormPart = () => {
    if (!landscapeFormModel) {
      return undefined;
    }

    if (!landscapeFormModel.isZeroToFiveFeetComplete) {
      return LandscapeFieldEvaluationFormParts.ZeroToFiveFeet;
    } else if (!landscapeFormModel.isFiveToThirtyFeetComplete) {
      return LandscapeFieldEvaluationFormParts.FiveToThirtyFeet;
    }
    // Form is complete
    return undefined;
  };

  const getLandscapeFieldEvaluationPartChecklistStatus = (
    formPart: LandscapeFieldEvaluationFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextLandscapeUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case LandscapeFieldEvaluationFormParts.ZeroToFiveFeet:
        return landscapeFormModel?.isZeroToFiveFeetComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case LandscapeFieldEvaluationFormParts.FiveToThirtyFeet:
        return landscapeFormModel?.isFiveToThirtyFeetComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const landscapeFieldEvaluationChecklistParts: FormChecklistPart<LandscapeFieldEvaluationFormParts>[] =
    [
      {
        formPart: LandscapeFieldEvaluationFormParts.ZeroToFiveFeet,
        displayName: "0-5 Feet",
        requiredFieldCount:
          landscapeFormModel?.zeroToFiveFeetRequiredFieldCount,
      },
      {
        formPart: LandscapeFieldEvaluationFormParts.FiveToThirtyFeet,
        displayName: "5-30 Feet",
        requiredFieldCount:
          landscapeFormModel?.fiveToThirtyFeetRequiredFieldCount,
      },
    ];

  return {
    landscapeFieldEvaluationChecklistParts,
    getLandscapeFieldEvaluationPartChecklistStatus,
  };
};

export default useLandscapeFieldEvaluationFormParts;
