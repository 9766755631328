import { Classes, Dialog } from "@blueprintjs/core";
import { DialogFooter } from "./DialogFooter";
import { BaseDialogProps } from "./types";

export interface WithdrawEvaluationDialogProps extends BaseDialogProps {}

export const WithdrawEvaluationDialog: React.FC<
  WithdrawEvaluationDialogProps
> = ({ isOpen, closeDialog, submitDialog }) => {
  return (
    <Dialog
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Withdraw Evaluation"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        Are you sure you want to withdraw?
      </div>
      <DialogFooter closeDialog={closeDialog} submitDialog={submitDialog} />
    </Dialog>
  );
};
