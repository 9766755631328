import { Option } from "@ucl/library/lib/components/Fields/types/fieldTypes";

export type YesOrNoSelectionValue = 2 | 1;

export type YesOrNoSelectionOption = Option<YesOrNoSelectionValue>;

export const yesOrNoSelectionOptions: YesOrNoSelectionOption[] = [
  { label: "Yes", value: 1 },
  { label: "No", value: 2 },
];

export const toYesOrNoSelectionValue = (
  value: boolean
): YesOrNoSelectionValue => {
  return value ? 1 : 2;
};

export const fromYesOrNoSelectionValue = (
  value: YesOrNoSelectionValue
): boolean => {
  return value === 1;
};
