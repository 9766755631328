import { FormGroup, Icon, InputGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { MenuItem2 } from "@blueprintjs/popover2";
import { Select2 } from "@blueprintjs/select";
import React, { useRef } from "react";
//import { useIsToolTipVisibleSetter } from "../../../utils/field";
import { RelativityTooltip } from "../../Tooltip/RelativityTooltip";
import { FormFieldErrorContainer } from "../FieldError/FormFieldErrorContainer";
import { FieldLabel } from "../FieldLabel/FieldLabel";
import { Option, SingleSelectFieldValue } from "../types/fieldTypes";
import { BaseFieldProps } from "../types/fieldTypes";
import "./styles.scss";
import { useIsToolTipVisibleSetter } from "@ucl/library/lib/utils/field";

/**Interface for SingleSelectField*/
export interface SingleSelectFieldProps extends BaseFieldProps {
  type: "SingleSelect";
  value: SingleSelectFieldValue;
  optionValues: Option[];
  reorderOptions?: ReorderOption[];
  noResultsLabel?: string;
}

/** Used for reordering OptionValues of the dropdown*/
export interface ReorderOption {
  newIndex: number;
  value: string | number;
}

/**Single Select is used primarily with enum values from the API, but can be passed collection of arrays*/
export const SingleSelectField: React.FC<SingleSelectFieldProps> = ({
  value,
  label,
  description,
  optionValues,
  disabled,
  onSubmit,
  hidden,
  errorMessages,
  isRequired,
  placeholder,
  reorderOptions,
  noResultsLabel,
}) => {
  if (hidden) {
    return null;
  }
  const [localValue, setLocalValue] =
    React.useState<SingleSelectFieldValue>(value);
  const selectInputRef = useRef<HTMLInputElement | null>(null);
  const isTooltipVisible = useIsToolTipVisibleSetter(selectInputRef, value);

  const getSelectValue = (
    selectValue: SingleSelectFieldValue,
    options: Option[]
  ) => {
    return options.find(
      (option) => String(option.value) === String(selectValue)
    );
  };

  const selectedOption = getSelectValue(localValue, optionValues);

  const orderedOptionValues = () => {
    if (!!reorderOptions) {
      reorderOptions.forEach((element) => {
        const foundOptionIndex = optionValues.findIndex(
          (x) => x.value === element.value
        );

        optionValues.splice(
          element.newIndex,
          0,
          optionValues.splice(foundOptionIndex, 1)[0]
        );
      });
    }
    return optionValues;
  };

  const selectedOptionToUse = selectedOption
    ? (selectedOption as Option)?.label
    : "";

  const checkIfSelectedValueIsValid = (
    onChangeEvent: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isValidOption = getSelectValue(
      onChangeEvent.target.value,
      optionValues
    );
    if (!isValidOption) {
      onChangeEvent.target.value = "";
    }
  };

  return (
    <FormGroup
      key={localValue}
      className={`${
        isRequired && !localValue ? "has-required-background" : "select-field"
      }`}
    >
      {label && (
        <FieldLabel labelName={label} tooltipDescription={description} />
      )}
      <Select2<Option>
        items={orderedOptionValues()}
        noResults={noResultsLabel ? noResultsLabel : "No Results"}
        resetOnSelect={true}
        filterable={optionValues.length >= 10}
        popoverProps={{
          position: "bottom-left",
          minimal: true,
          hasBackdrop: true,
          canEscapeKeyClose: true,
        }}
        itemListPredicate={(query, items) => {
          query = query.toLowerCase();

          return items.filter((optionValue) =>
            optionValue.label.toLowerCase().includes(query)
          );
        }}
        itemRenderer={(item, itemProps) => {
          return (
            <MenuItem2
              className="select__menu-item"
              key={itemProps.index}
              text={item.label}
              active={String(localValue) === String(item.value)}
              onClick={itemProps.handleClick}
            />
          );
        }}
        onItemSelect={(item) => {
          setLocalValue(item.value as SingleSelectFieldValue);
          onSubmit && onSubmit(item);
        }}
        disabled={disabled}
      >
        <RelativityTooltip
          shouldShowTooltip={isTooltipVisible}
          content={selectedOptionToUse}
        >
          <InputGroup
            inputRef={selectInputRef}
            className="select-input"
            rightElement={
              <Icon className="select-right-icon" icon={IconNames.CaretDown} />
            }
            readOnly={true}
            disabled={disabled}
            placeholder={placeholder}
            onChange={(e) => {
              checkIfSelectedValueIsValid(e);
            }}
            value={selectedOptionToUse}
          />
        </RelativityTooltip>
      </Select2>
      {errorMessages && (
        <FormFieldErrorContainer errorMessages={errorMessages} />
      )}
    </FormGroup>
  );
};
