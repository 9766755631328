import { Label } from "@blueprintjs/core";
import classnames from "classnames";
import { observer } from "mobx-react";
import { evaluationStore } from "../../../stores/EvaluationStore";
import "./styles.scss";

export interface SelectableLabelProps {
  name: string;
  fieldId: string;
  isSelectedField: boolean;
  hasActiveChangeRequest: boolean;
  isResovledChangeRequest: boolean;
  isSelectedChangeRequest: boolean;
  hasActiveInternalComment: boolean;
  value: unknown;
  description?: string;
  shouldDisableFieldLabelClick?: boolean;
}

export const SelectableLabel: React.FC<SelectableLabelProps> = observer(
  ({
    name,
    fieldId,
    hasActiveChangeRequest,
    isSelectedField,
    isSelectedChangeRequest,
    isResovledChangeRequest,
    hasActiveInternalComment,
    shouldDisableFieldLabelClick,
  }) => {
    return (
      <Label
        onClick={() => {
          if (!shouldDisableFieldLabelClick) {
            evaluationStore.setSelectedField(fieldId);
          }
        }}
        className={classnames({
          "is-selected-field": isSelectedField,
          "has-active-change-request": hasActiveChangeRequest,
          "is-selected-change-request": isSelectedChangeRequest,
          "is-resovled-change-request": isResovledChangeRequest,
          "has-active-internal-comment": hasActiveInternalComment,
        })}
      >
        <span className="label-title">{name}</span>
      </Label>
    );
  }
);
