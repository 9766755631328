import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useNavigate } from "react-router";
import "./styles.scss";
import { openApplicationIncompleteDialog } from "../../Dialogs/ApplicationIncomplete/ApplicationIncompleteDialog";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";

export interface ApplicationFormDashboardNavigationButtonProps {
  wildfireApplicationId: string;
  canBeSaved: boolean;
  onApplicationIncompleteDialogOpened: () => void;
}
export const ApplicationFormDashboardNavigationButton: React.FC<
  ApplicationFormDashboardNavigationButtonProps
> = (props) => {
  const navigate = useNavigate();

  const navigateToDashboard = () => navigate(`${wildfireRoutePrefix}/board`);

  const handleButtonClick = () => {
    if (props.canBeSaved) {
      navigateToDashboard();
    } else {
      props.onApplicationIncompleteDialogOpened?.();
      openApplicationIncompleteDialog(
        props.wildfireApplicationId,
        navigateToDashboard
      );
    }
  };

  return (
    <Button
      className="wildfire-dashboard-navigation-button"
      minimal
      text="Back to Dashboard"
      intent={Intent.PRIMARY}
      icon={IconNames.ARROW_LEFT}
      onClick={handleButtonClick}
    />
  );
};
