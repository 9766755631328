import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useNavigate } from "react-router";
import { wildfireEvaluationApiClient } from "../../../lib/apiClients/application/wildfireApplicationApiClient";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";

export interface CancelMadronusIntegrationButtonProps {
  evaluationId: string;
  currentApplicationFormPart: string;
}

export const CancelMadronusIntegrationButton: React.FC<
  CancelMadronusIntegrationButtonProps
> = ({
  evaluationId,
  currentApplicationFormPart,
}: CancelMadronusIntegrationButtonProps) => {
  const navigate = useNavigate();

  return (
    <Button
      text="Cancel and Convert to DIY Service"
      intent={Intent.WARNING}
      rightIcon={IconNames.ARROW_RIGHT}
      onClick={async () =>
        await wildfireEvaluationApiClient
          .cancelMadronusIntegration(evaluationId)
          .then(() =>
            navigate(
              `${wildfireRoutePrefix}/${evaluationId}/application-form/${currentApplicationFormPart}`
            )
          )
      }
    />
  );
};
