import { Intent, Tag } from "@blueprintjs/core";
import { EvaluationStep } from "../../../types/evaluation/Evaluation";
import { UserRoleType } from "../../../../common/Components/User/userTypes";

const evaluationLabelMapping: {
  [key: string]: (
    step: EvaluationStep,
    role: UserRoleType,
    canViewPendingLabels?: boolean
  ) => boolean;
} = {
  "Pending IBHS Eligibility Review": (step, role, canViewPendingLabels) =>
    step === EvaluationStep.EligibilityReview &&
    (role === "FC/FMF Applicant" || role === "FC/FMF Evaluator") &&
    canViewPendingLabels === true,

  "Pending Evaluator Field Inspection": (step, role, _) =>
    step === EvaluationStep.FieldInspectionsSubmitted &&
    role === "FC/FMF Applicant",

  "Pending Compliance Forms": (step, role, _) =>
    step === EvaluationStep.ComplianceFormSubmitted &&
    (role === "FC/FMF Applicant" || role === "FC/FMF Evaluator"),

  "Pending Compliance Letter": (step, role, _) =>
    step === EvaluationStep.EvaluatorComplianceLetterSubmitted &&
    role === "FC/FMF Applicant",

  "Pending IBHS Final Review": (step, role, canViewPendingLabels) =>
    step === EvaluationStep.FinalReview &&
    (role === "FC/FMF Applicant" || role === "FC/FMF Evaluator") &&
    canViewPendingLabels === true,
};

interface EvaluationLabelProps {
  step: EvaluationStep;
  role: UserRoleType;
  canViewPendingLabels?: boolean;
}

export const EvaluationLabel: React.FC<EvaluationLabelProps> = ({
  step,
  role,
  canViewPendingLabels,
}) => {
  const labels = Object.keys(evaluationLabelMapping);
  const label = labels.find((key) =>
    evaluationLabelMapping[key](step, role, canViewPendingLabels)
  );

  return label ? <Tag intent={Intent.PRIMARY}>{label}</Tag> : <></>;
};
