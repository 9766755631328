import { useEffect, useState } from "react";
import { evaluationStore } from "../../../../stores/EvaluationStore";

export const useComplexCommercialEvaluationBuildingsCard = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const createBuilding = async (complexCommercialEvaluationId: string) => {
    setIsSaving(true);
    await evaluationStore
      .createComplexCommercialEvaluationBuilding(complexCommercialEvaluationId)
      .finally(() => setIsSaving(false));
  };

  useEffect(() => {
    if (
      evaluationStore.isComplexCommercialEvaluationAndNeedToFillOutBuildings
    ) {
      scrollToBuildingsForm();
      checkIfBuildingsAreFinishedBeingProcessed();
    }
  }, []);

  const scrollToBuildingsForm = () => {
    const scrollToBuildingsButton = document.getElementById(
      "evaluation-add-building-button"
    );
    if (!scrollToBuildingsButton) {
      return;
    }
    setTimeout(() => {
      scrollToBuildingsButton.click();
    }, 1000);
  };

  const checkIfBuildingsAreFinishedBeingProcessed = async () => {
    if (!evaluationStore.evaluation?.areBuildingsFinishedBeingProcessed) {
      setIsFetching(true);
      const intervalId = setInterval(async () => {
        await evaluationStore.getAreBuildingsFinishedBeingProcessed();

        if (evaluationStore.evaluation?.areBuildingsFinishedBeingProcessed) {
          clearInterval(intervalId);
          await evaluationStore.getComplexCommercialEvaluationBuildings(
            evaluationStore.evaluation?.id || ""
          );
          setIsFetching(false);
        }
      }, 5000);
    }
  };

  return {
    createBuilding,
    isSaving,
    scrollToBuildingsForm,
    isFetching,
  };
};
