import React from "react";
import { WildfireEvaluation } from "../../types/WildfireApplication/WildfireApplication";
import { EvaluationFormModel } from "../../forms/EvaluationFormModel";
import { formatSecondaryAddressInfo } from "../../lib/application/applicationUtil";
import "./styles.scss";

interface WildfireAppHeaderProps {
  wildfireEvaluation: WildfireEvaluation | EvaluationFormModel;
}

const WildfireAppHeader: React.FC<WildfireAppHeaderProps> = (props) => {
  const wfpApplicationId =
    props.wildfireEvaluation.wildfireApplication.wildfirePreparedApplicationId;

  const getFormatedSecondaryAddressInfo = (): string => {
    const address =
      props.wildfireEvaluation.wildfireApplication.applicationForm
        ?.streetAddress;
    const hasAddress =
      !!address &&
      !!address.street &&
      !!address.city &&
      !!address.state_AsString &&
      !!address.zip;

    // WFP ID and Address (if it exists)
    return hasAddress ? `, ${formatSecondaryAddressInfo(address)}` : "";
  };

  const getPrimaryHeaderInfo = (): string => {
    const address =
      props.wildfireEvaluation.wildfireApplication.applicationForm
        ?.streetAddress;
    const hasAddress =
      !!address &&
      !!address.street &&
      !!address.city &&
      !!address.state_AsString &&
      !!address.zip;

    // WFP ID and Address (if it exists)
    return hasAddress ? `${address.street}` : "";
  };

  return (
    <div className="wildfire-app-header">
      <div className="wildfire-app-header__primary">Wildfire Prepared - </div>
      <div className="wildfire-app-header__address">
        <div className="wildfire-app-header__address__primary">{`${getPrimaryHeaderInfo()}`}</div>
        <div className="wildfire-app-header__address__secondary">
          {`${getFormatedSecondaryAddressInfo()}`}
        </div>
      </div>
      <div className="wildfire-app-header__application-id">
        {` (${wfpApplicationId})`}
      </div>
    </div>
  );
};

export default WildfireAppHeader;
