import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { FCFMFRedesignationEvaluatorEmailFormParts } from "../../../common/types/Redesignation/BaseFCFMFRedesignationEvaluatorEmailFormModel";
import { CommercialRedesignationEvaluationEmailFormModel } from "../../types/CommercialRedesignationEvaluationEmailFormModel";
import { Evaluation_CommercialRedesignationFormModel } from "../../types/CommercialRedesignationFormModel";

class CommercialRedesignationEvaluatorEmailFormAPIClient {
  updateCommercialRedesignationEvaluatorEmailForm = async (
    evaluationId: string,
    redesignationEvaluatorEmailForm: CommercialRedesignationEvaluationEmailFormModel,
    fieldKey?: string
  ): Promise<Evaluation_CommercialRedesignationFormModel> => {
    return baseApiClient.put<Evaluation_CommercialRedesignationFormModel>(
      `/commercial/redesignation-evaluator-email-form/${evaluationId}`,
      {
        redesignationEvaluatorEmailForm,
        fieldKey,
      }
    );
  };

  submitCommercialRedesignationEvaluatorEmailForm = async (
    wildfireApplicationId: string,
    redesignationEvaluatorEmailForm: CommercialRedesignationEvaluationEmailFormModel,
    formPart?: FCFMFRedesignationEvaluatorEmailFormParts
  ): Promise<Evaluation_CommercialRedesignationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const applicationFormPartValue = (
      formPart ??
      (Object.values(
        FCFMFRedesignationEvaluatorEmailFormParts
      ).pop() as FCFMFRedesignationEvaluatorEmailFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_CommercialRedesignationFormModel>(
      `/commercial/redesignation-evaluator-email-form/${wildfireApplicationId}/${applicationFormPartValue}`,
      redesignationEvaluatorEmailForm
    );
  };
}

export const commercialRedesignationEvaluatorEmailFormAPIClient =
  new CommercialRedesignationEvaluatorEmailFormAPIClient();
