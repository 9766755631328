import { Button, Intent } from "@blueprintjs/core";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import { useState } from "react";
import { AppToaster } from "../../Toast/Toast";
import { evaluationStore } from "../../../stores/EvaluationStore";
import { dialogsViewerStore } from "../../../stores/DialogsViewerStore";

interface SendForwardActionButtonProps {
  isSendForwardDisabled: boolean;
  errors?: string[];
  isLoading?: boolean;
}

const SendForwardActionButton: React.FC<SendForwardActionButtonProps> = ({
  isSendForwardDisabled,
  errors,
  isLoading,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  if (errors && errors.length > 0) {
    return (
      <Popover2
        interactionKind="hover"
        placement="bottom-end"
        portalClassName="tooltip-popover"
        popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
        content={
          <span>
            The following field(s) must be complete prior to moving forward:
            <ul>
              {errors.map((error, idx) => (
                <li key={idx}>{error}</li>
              ))}
            </ul>
          </span>
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        renderTarget={({ isOpen, ...targetProps }) => (
          <div {...targetProps}>
            <Button
              text="Send Forward"
              disabled
              minimal
              intent={Intent.PRIMARY}
            />
          </div>
        )}
      />
    );
  }

  // Compliance Letter status
  if (
    evaluationStore.isCommercialOrMultifamilyEvaluation &&
    evaluationStore.isEvaluationOnComplianceLetterStep &&
    !evaluationStore.isRedesignationEvaluation
  ) {
    return (
      <Button
        text="Send Forward"
        disabled={isSendForwardDisabled}
        minimal
        intent={Intent.PRIMARY}
        loading={isSaving}
        onClick={() =>
          dialogsViewerStore.setIsComplianceLetterSendForwardDialogOpen(true)
        }
      />
    );
  }

  return (
    <Button
      text="Send Forward"
      disabled={isSendForwardDisabled}
      minimal
      intent={Intent.PRIMARY}
      loading={isSaving || isLoading}
      onClick={async () => {
        setIsSaving(true);
        await evaluationStore
          .sendForward()
          .then(() => {
            AppToaster.show({
              message: (
                <div>
                  <h3>Step Complete</h3>A status update email has been sent.
                </div>
              ),
              intent: Intent.SUCCESS,
            });
          })
          .finally(() => {
            setIsSaving(false);
          });
      }}
    />
  );
};

export default SendForwardActionButton;
