import _envVar from "../../config/variables";
import { Configuration } from "@azure/msal-browser";

const baseAuthorityURL = `https://${_envVar.domain}/${_envVar.tenantId}/`;

export const ibhsB2CPolicies = {
  authorities: {
    signUpSignIn: {
      wildfireAuthority: `${baseAuthorityURL}/${_envVar.wildfire_SignUpSignInUserFlow}`,
      fortifiedHomeAuthority: `${baseAuthorityURL}/${_envVar.fortified_Home_SignUpSignInUserFlow}`,
      fortifiedCommercialMultifamilyAuthority: `${baseAuthorityURL}/${_envVar.fortified_Commercial_Multifamily_SignUpSignInUserFlow}`,
    },
  },
  authorityDomain: [_envVar.domain],
};

export const loginRequest = {
  scopes: [_envVar.scope, "openid", "offline_access", "profile"],
};

export const baseMsalConfig: Configuration = {
  auth: {
    clientId: _envVar.clientId,
    knownAuthorities: ibhsB2CPolicies.authorityDomain,
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false,
    allowRedirectInIframe: true,
  },
};
