import { UserRoleType } from "../../../../common/Components/User/userTypes";
import FortifiedGridColumn from "../../../types/fortifiedGrid/FortifiedGridColumn";

const gridColumnsConfiguration: FortifiedGridColumn[] = [
  new FortifiedGridColumn({
    field: "role_AsString",
    headerText: "Role",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "firstName",
    headerText: "First Name",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "lastName",
    headerText: "Last Name",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "email",
    headerText: "Email",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "companyName",
    headerText: "Company Name",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "modifiedAt_AsDateOnly",
    headerText: "Updated Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
  }),
  new FortifiedGridColumn({
    field: "modifiedBy_AsName",
    headerText: "Updated By",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "createdAt_AsDateOnly",
    headerText: "Created Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
  }),
  new FortifiedGridColumn({
    field: "createdBy_AsName",
    headerText: "Created By",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "isActive",
    headerText: "Active",
    type: "boolean",
    displayAsCheckBox: true,
    textAlign: "Center",
  }),
];

const gridRoleColumnsConfigurationDefaultFields = [
  "role_AsString",
  "firstName",
  "lastName",
  "email",
  "companyName",
  "modifiedAt_AsDateOnly",
  "modifiedBy_AsName",
  "createdAt_AsDateOnly",
  "createdBy_AsName",
  "isActive",
];

export const gridRoleColumnsConfiguration: Record<
  "Default",
  FortifiedGridColumn[] | undefined
> = {
  Default: [
    ...gridColumnsConfiguration
      .filter((x) =>
        gridRoleColumnsConfigurationDefaultFields.includes(x.field)
      )
      .sort(function (a, b) {
        return (
          gridRoleColumnsConfigurationDefaultFields.indexOf(a.field) -
          gridRoleColumnsConfigurationDefaultFields.indexOf(b.field)
        );
      }),
  ],
};

export const getGridColumnsConfigurationByRole = (_role?: UserRoleType) => {
  return gridRoleColumnsConfiguration.Default as FortifiedGridColumn[];
};
