import { Button, Intent, Menu, MenuItem, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Popover2 } from "@blueprintjs/popover2";
import { useState } from "react";
import { browserDownloadFileResponse } from "../../../../common/utils/download";
import { RelativityTooltip } from "@ucl/library";

interface DownloadCertificateActionButtonProps {
  shouldDisplayCertificateGenerationButton: boolean;
  canRegenerateCertificate: boolean;
  shouldDisableDownload: boolean;
  downloadCertificate: () => Promise<unknown>;
  regenerateCertificate: () => Promise<unknown>;
}

const DownloadCertificateActionButton: React.FC<
  DownloadCertificateActionButtonProps
> = ({
  shouldDisplayCertificateGenerationButton,
  canRegenerateCertificate,
  shouldDisableDownload,
  downloadCertificate,
  regenerateCertificate,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const certificateName = "Certificate.pdf";
  return (
    <>
      <Popover2
        position={Position.BOTTOM}
        content={
          <Menu>
            <MenuItem
              icon={IconNames.DOWNLOAD}
              text="Download"
              onClick={async () => {
                setIsLoading(true);
                await downloadCertificate().then((response) =>
                  browserDownloadFileResponse(response, certificateName)
                );
                setIsLoading(false);
              }}
            />
            {canRegenerateCertificate && (
              <MenuItem
                icon={IconNames.REFRESH}
                text="Regenerate"
                onClick={async () => {
                  setIsLoading(true);
                  await regenerateCertificate().then((response) =>
                    browserDownloadFileResponse(response, certificateName)
                  );
                  setIsLoading(false);
                }}
              />
            )}
          </Menu>
        }
      >
        <RelativityTooltip
          shouldShowTooltip={shouldDisableDownload}
          compact={true}
          className="certificate-tooltip-container"
          content={<div className="certificate-tooltip"></div>}
        >
          <Button
            loading={isLoading}
            intent={shouldDisableDownload ? Intent.NONE : Intent.SUCCESS}
            rightIcon={IconNames.CARET_DOWN}
            icon={IconNames.DOCUMENT}
            hidden={!shouldDisplayCertificateGenerationButton}
            disabled={shouldDisableDownload}
          >
            Certificate
          </Button>
        </RelativityTooltip>
      </Popover2>
    </>
  );
};

export default DownloadCertificateActionButton;
