interface HomeSCSHIterationEngineHeaderProps {
  evaluationId: string;
}

const HomeSCSHIterationEngineHeader: React.FC<
  HomeSCSHIterationEngineHeaderProps
> = (props) => {
  console.log(props.evaluationId);

  return (
    <div className="wildfire-iteration-engine__header">
      <div className="left-panel"></div>
      <div className="right-panel"></div>
    </div>
  );
};

export default HomeSCSHIterationEngineHeader;
