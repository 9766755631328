import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useAccount,
} from "@azure/msal-react";
import { RouterProvider } from "react-router-dom";
import { usePagesAuthentication } from "./usePagesAuthentication";
import ErrorWrapper from "../../../common/Components/Error/ErrorWrapper";
import useUserGuidingWithIdentification from "../../../common/customHooks/userGuiding/useUserGuidingWithIdentification";
import { fortifiedUserStore } from "../../stores/FortifiedUserStore";
import { useProductTypeHandler } from "../../providers/ProductTypeHandlerProvider";
import type { Router as RemixRouter } from "@remix-run/router";

interface PagesProps {
  router: RemixRouter;
  externalRouter?: RemixRouter;
}

const AuthenticatedPages: React.FC = () => {
  const { msalInstance, router, externalRouter } = useProductTypeHandler();
  return (
    <>
      {msalInstance && router && (
        <MsalProvider instance={msalInstance}>
          <Pages router={router} externalRouter={externalRouter} />
        </MsalProvider>
      )}
    </>
  );
};

const Pages: React.FC<PagesProps> = (props) => {
  const { isInitialized, isUserAuthenticated, hasError } =
    usePagesAuthentication();

  const account = useAccount();
  useUserGuidingWithIdentification({ user: fortifiedUserStore.user });

  if (hasError) {
    return <ErrorWrapper />;
  }

  const shouldRenderAuth = isUserAuthenticated && isInitialized && account;

  const shouldRenderExternal = !isUserAuthenticated && isInitialized;

  return (
    <>
      {!!shouldRenderAuth && (
        <AuthenticatedTemplate>
          <RouterProvider router={props.router} />
        </AuthenticatedTemplate>
      )}
      {!!shouldRenderExternal && !!props.externalRouter && (
        <UnauthenticatedTemplate>
          <RouterProvider router={props.externalRouter} />
        </UnauthenticatedTemplate>
      )}
    </>
  );
};

export default AuthenticatedPages;
