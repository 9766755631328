import { Divider, Spinner, SpinnerSize } from "@blueprintjs/core";
import React from "react";
import { SelectableLabelWithToolTip } from "../../Fields/SelectableLabel/SelectableLabelWithToolTip";
import { useEvaluationHistory } from "./useEvaluationHistory";

export interface EvaluationHistoryProps {
  evaluationId: string;

  readonly?: boolean;
}

const EvaluationHistory: React.FC<EvaluationHistoryProps> = (props) => {
  const {
    isLoading,
    shouldShowEvaluationHistory,
    evaluationRedesignations,
    shouldHideHyperlink,
    getEvaluationLink,
    getHistoryRowDisplayText,
  } = useEvaluationHistory(props);
  return (
    <>
      {shouldShowEvaluationHistory && (
        <div className="evaluation-history">
          <Divider className="evaluation-container__status__divider" />
          <SelectableLabelWithToolTip name="History" />

          {isLoading && <Spinner size={SpinnerSize.SMALL} />}
          {!isLoading && (
            <div className="history-list">
              {evaluationRedesignations.map((child) => (
                <div key={child.id}>
                  {!shouldHideHyperlink(child) && (
                    <a key={child.id} href={getEvaluationLink(child)}>
                      {getHistoryRowDisplayText(child)}
                    </a>
                  )}
                  {shouldHideHyperlink(child) && (
                    <div key={child.id}>{getHistoryRowDisplayText(child)}</div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EvaluationHistory;
