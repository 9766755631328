import { action, computed, makeObservable, observable } from "mobx";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import { L10n } from "@syncfusion/ej2-base";
import {
  ActionEventArgs,
  AddEventArgs,
  Column,
  DeleteEventArgs,
  EditEventArgs,
  FailureEventArgs,
  FilterEventArgs,
  FilterSearchBeginEventArgs,
  GridColumn,
  GridColumnDirTypecast,
  GridColumnModel,
  GridComponent,
  GridModel,
  GroupEventArgs,
  PageEventArgs,
  PredicateModel,
  RecordClickEventArgs,
  SaveEventArgs,
  SearchEventArgs,
  SortDescriptorModel,
  SortEventArgs,
} from "@syncfusion/ej2-react-grids";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import FortifiedGridHeightSettings from "../types/fortifiedGrid/FortifiedGridHeightSettings";
import FortifiedGridColumn from "../types/fortifiedGrid/FortifiedGridColumn";
import FortifiedODataV4Adaptor from "../components/FortifiedGrid/FortifiedODataV4Adaptor";
import { FortifiedGridModel } from "../types/fortifiedGrid/FortifiedGridModel";
import FortifiedGridRecordClickEventArgs from "../types/fortifiedGrid/FortifiedGridRecordClickEventArgs";
import { FortifiedODataBeforeSendArgs } from "../types/fortifiedGrid/FortifiedODataBeforeSendArgs";
import { FortifiedGridFilterSettings } from "../types/fortifiedGrid/FortifiedGridFilterSettings";
import { FortifiedGridPersistData } from "../types/fortifiedGrid/FortifiedGridPersistData";
import { AppToaster } from "../components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { FortifiedGridActionFailureError } from "../types/fortifiedGrid/FortifiedGridActionFailureError";
import { fortifiedGridViewModuleStore } from "./FortifiedGridViewModuleStore";

export class FortifiedGridStore {
  // computed
  public get filtersCount(): number {
    const filtersAndSortsCount =
      (this.filterSettings?.rawFilterSettings?.length ?? 0) +
      (this.filterSettings?.rawSortSettings?.length ?? 0);

    return filtersAndSortsCount;
  }

  // public
  public gridInstance?: GridComponent;
  public requestFilterQuery?: string;
  public requestType?: string;
  public filterSettings?: FortifiedGridFilterSettings;
  public persistData?: FortifiedGridPersistData;
  public defaultSort?: SortDescriptorModel;

  public clearFilters = (
    fortifiedGridFilterSettings?: FortifiedGridFilterSettings
  ) => {
    if (!fortifiedGridFilterSettings) {
      fortifiedGridFilterSettings = this.getGridFilterSettings(
        this.gridInstance
      );
    }

    // Clear  Filters (Direct, avoid errors)
    const fieldsToRemove = fortifiedGridFilterSettings.rawFilterSettings?.map(
      (x) => x.field
    );
    if (
      fortifiedGridFilterSettings.rawFilterSettings?.length > 0 &&
      !!this.gridInstance
    ) {
      this.gridInstance.filterSettings.columns =
        this.gridInstance.filterSettings.columns?.filter(
          (x) => !fieldsToRemove.includes(String(x.field))
        );

      this.gridInstance.setProperties({
        filterSettings: this.gridInstance.filterSettings,
      });
    }

    // Clear Sorts
    if (fortifiedGridFilterSettings.rawSortSettings?.length > 0) {
      fortifiedGridFilterSettings.rawSortSettings.forEach((col) => {
        this.gridInstance?.removeSortColumn(String(col.field));
      });
    }

    if (this.gridInstance?.headerModule) this.gridInstance?.refreshHeader();
  };

  public removeSort = (field: string) => {
    this.clearFilters({
      rawSortSettings: [{ field: field }],
    } as FortifiedGridFilterSettings);
  };

  public removeFilter = (field: string) => {
    this.clearFilters({
      rawFilterSettings: [{ field: field }],
    } as FortifiedGridFilterSettings);
  };

  // private
  private fortifiedGridHeightSettings: FortifiedGridHeightSettings = {
    height: 600,
    autoHeightOffset: 250,
  };

  private defaultGridColumnSettings: GridColumnModel | GridColumnDirTypecast = {
    textAlign: "Left",
    displayAsCheckBox: false,
  };

  private defaultGridSettings: GridModel & DefaultHtmlAttributes = {
    enableColumnVirtualization: true,
    enableInfiniteScrolling: true,
    height: this.fortifiedGridHeightSettings.height,
    allowReordering: true,
    allowResizing: true,
    resizeSettings: { mode: "Normal" },
    pageSettings: { pageSize: 20 },
    allowExcelExport: true,
    allowSorting: true,
    allowFiltering: true,
    loadingIndicator: { indicatorType: "Shimmer" },
    enableVirtualMaskRow: true,
    filterSettings: {
      type: "Excel",
      ignoreAccent: true,
      operators: {
        stringOperator: [{ value: "contains", text: "Contains " }],
      },
    },
    toolbar: [
      { text: "Search" },
      {
        text: "Export to Excel",
        tooltipText: "Export to Excel",
        prefixIcon: "e-upload-2",
        id: "ExcelExport",
      },
    ],
    searchSettings: {
      operator: "contains",
      ignoreCase: true,
    },
  };

  constructor() {
    makeObservable(this, {
      filtersCount: computed,
      gridInstance: observable,
      requestFilterQuery: observable,
      requestType: observable,
      filterSettings: observable,
      persistData: observable,
      setGridInstance: action,
      setRequestFilterQuery: action,
      setRequestType: action,
      setFilterSettings: action,
      setPersistData: action,
      clearFilters: action,
    });
  }

  public setup = <T extends Object>(args: {
    dataSource: string;
    accessToken: string;
    columnsConfiguration: Readonly<FortifiedGridColumn[]>;
    gridConfiguration?: Readonly<FortifiedGridModel<T>>;
  }): GridModel & DefaultHtmlAttributes => {
    // Setup Grid Settings
    const gridSettings = this.setupGridSettings(args);
    return gridSettings;
  };

  private setupGridSettings = <T extends Object>(
    args: FortifiedGridSetup<T>
  ) => {
    const gridSettings = {
      ...this.defaultGridSettings,
      ...args.gridConfiguration,
    };

    // Update Filter and Sort Settings
    gridSettings.filterSettings = {
      ...gridSettings.filterSettings,
      ...{ columns: args.filterConfiguration },
    };

    gridSettings.sortSettings = {
      ...gridSettings.sortSettings,
      ...{ columns: args.sortConfiguration },
    };

    // Setup Properties
    gridSettings.columns = this.setupGridSettingsColumns(
      args.columnsConfiguration
    );
    gridSettings.query = this.setupGridSettingsQuery(args.columnsConfiguration);
    gridSettings.dataSource = this.setupODataSource(
      args.dataSource,
      args.accessToken,
      args.columnsConfiguration as GridColumn[]
    );

    // Setup Events
    gridSettings.recordClick = this.setupRecordClickEvent(
      args.gridConfiguration
    );
    gridSettings.toolbarClick = this.setupToolbarClickEvent(
      args.gridConfiguration
    );
    gridSettings.dataBound = this.setupDataboundEvent(args.gridConfiguration);
    gridSettings.created = this.setupCreatedEvent(args.gridConfiguration);
    gridSettings.actionBegin = this.setupActionBeginEvent(
      args.gridConfiguration
    );
    gridSettings.actionFailure = this.setupActionFailureEvent(
      args.gridConfiguration
    );

    // Set default sort
    this.defaultSort = args.gridConfiguration?.defaultSort;

    // Update locale for boolean values
    L10n.load({
      "en-US": {
        grid: {
          True: "Yes",
          False: "No",
        },
      },
    });

    // Return Grid Model
    return gridSettings;
  };

  private setupGridSettingsColumns = (
    columnsConfiguration: Readonly<FortifiedGridColumn[]>
  ) => {
    const gridColumnSettings = columnsConfiguration.map((colsConfig) => ({
      ...this.defaultGridColumnSettings,
      ...colsConfig,
    })) as FortifiedGridColumn[];

    return gridColumnSettings;
  };

  private setupGridSettingsQuery = (
    columnsConfiguration: Readonly<FortifiedGridColumn[]>
  ) => {
    const query = new Query();
    query?.select(columnsConfiguration.map((x) => x.field));

    if (fortifiedGridViewModuleStore.currentGridView?.gridId === "board-grid") {
      query?.addParams("gridId", "board-grid");
      query?.addParams(
        "gridViewTitle",
        fortifiedGridViewModuleStore.currentGridView?.title
      );
    }
    return query;
  };

  private setupODataSource = (
    odataUrl: string,
    accessToken: string,
    columnsConfiguration?: GridColumn[]
  ): DataManager => {
    const dataManager = new DataManager({
      url: odataUrl,
      adaptor: new FortifiedODataV4Adaptor(
        columnsConfiguration,
        this.onBeforeSend
      ),
      crossDomain: true,
      headers: [{ Authorization: `Bearer ${accessToken}` }],
    });
    dataManager.dateParse = false;
    return dataManager;
  };

  private setupRecordClickEvent = <T extends Object>(
    gridConfiguration?: Readonly<FortifiedGridModel<T>>
  ) => {
    return (x: RecordClickEventArgs) => {
      if (gridConfiguration?.recordClick) {
        gridConfiguration.recordClick(x);
      }

      if (gridConfiguration?.onRecordClick) {
        const recordClickEventArgs = {
          ...x,
        } as FortifiedGridRecordClickEventArgs<T>;
        gridConfiguration.onRecordClick(recordClickEventArgs);
      }
    };
  };

  private setupToolbarClickEvent = <T extends Object>(
    gridConfiguration?: Readonly<FortifiedGridModel<T>>
  ) => {
    return (clickEventArgs: ClickEventArgs) => {
      if (gridConfiguration?.toolbarClick) {
        gridConfiguration.toolbarClick(clickEventArgs);
      }

      if (clickEventArgs.item.id === "ExcelExport") {
        this.gridInstance?.excelExport({
          fileName: gridConfiguration?.exportFilename ?? "Export.xlsx",
        });
      }
    };
  };

  private setupDataboundEvent = <T extends Object>(
    gridConfiguration?: Readonly<FortifiedGridModel<T>>
  ) => {
    return (obj: Object) => {
      if (gridConfiguration?.dataBound) {
        gridConfiguration.dataBound(obj);
      }

      this.gridInstance?.autoFitColumns(
        (this.gridInstance?.columns as Column[])
          .filter((x) =>
            // only autofit columns whose width is set at its default
            typeof x.width === "number" ? x.width === 200 : true
          )
          .map((x) => x.foreignKeyField)
      );
    };
  };

  private setupCreatedEvent = <T extends Object>(
    gridConfiguration?: Readonly<FortifiedGridModel<T>>
  ) => {
    return (obj: Object) => {
      if (gridConfiguration?.created) {
        gridConfiguration.created(obj);
      }

      this.storeFilterSettings();
    };
  };

  private setupActionBeginEvent = <T extends Object>(
    gridConfiguration?: Readonly<FortifiedGridModel<T>>
  ) => {
    return (
      eventArgs:
        | PageEventArgs
        | GroupEventArgs
        | FilterEventArgs
        | SearchEventArgs
        | SortEventArgs
        | AddEventArgs
        | SaveEventArgs
        | EditEventArgs
        | DeleteEventArgs
        | ActionEventArgs
        | FilterSearchBeginEventArgs
    ) => {
      if (gridConfiguration?.actionBegin) {
        gridConfiguration.actionBegin(eventArgs);
      }

      this.setRequestType(eventArgs.requestType);

      // Set Filter Choice Count
      if (eventArgs.requestType === "filterchoicerequest") {
        (eventArgs as FilterSearchBeginEventArgs).filterChoiceCount = 3000;
      }
    };
  };

  private setupActionFailureEvent = <T extends Object>(
    gridConfiguration?: Readonly<FortifiedGridModel<T>>
  ) => {
    return (eventArgs: FailureEventArgs) => {
      if (gridConfiguration?.actionFailure) {
        gridConfiguration.actionFailure(eventArgs);
      }

      // Process Error
      const error =
        !!eventArgs.error &&
        (eventArgs.error as unknown as FortifiedGridActionFailureError);
      if (!!error && [401, 403].includes(error.error.status)) {
        AppToaster.show({
          message: "Authorization Error. Please refresh and try again.",
          intent: Intent.WARNING,
        });
      } else {
        AppToaster.show({
          message: "An error ocurred executing the current action.",
          intent: Intent.DANGER,
        });
      }

      console.error(eventArgs);
    };
  };

  private initializeAutoHeight = () => {
    // Inner Function
    const autoHeight = () => {
      if (this.gridInstance) {
        this.gridInstance.height =
          window.innerHeight -
          this.fortifiedGridHeightSettings.autoHeightOffset;
      }
    };

    // Init
    autoHeight();

    // On Resize
    window.addEventListener("resize", () => {
      autoHeight();
    });
  };

  public initialize = (newGridInstance: GridComponent) => {
    this.setGridInstance(newGridInstance);
    this.storeFilterSettings();
    this.initializeAutoHeight();
  };

  public reset = () => {
    this.setGridInstance(undefined);
  };

  public refresh = () => {
    this.gridInstance?.refresh();
  };

  private onBeforeSend = ({
    queryString,
  }: FortifiedODataBeforeSendArgs): string => {
    this.setRequestFilterQuery(queryString);

    if (this.requestType) {
      this.storeFilterSettings(this.requestType);
    }

    // Initial Sorting
    if (this.defaultSort && !queryString.includes("$orderby")) {
      queryString =
        queryString +
        `&$orderby=${this.defaultSort.field}%20${
          this.defaultSort.direction === "Ascending" ? "asc" : "desc"
        }`;
    }

    // Grouping Filters missing Filter
    if (
      queryString.includes("$apply=groupby") &&
      !queryString.includes("$filter")
    ) {
      const params = new URLSearchParams(this.filterSettings?.rawQuery);
      if (params.has("$filter")) {
        queryString =
          queryString +
          `&$filter=${encodeURIComponent(params.get("$filter") as string)}`;
      }
    }

    return queryString;
  };

  private storeFilterSettings = (requestType?: string) => {
    if (!this.gridInstance) return;

    if (
      !requestType ||
      ["sorting", "filtering", "searching", "reorder", "refresh"].includes(
        String(requestType)
      )
    ) {
      // Set Persist Data
      const persistData = JSON.parse(
        this.gridInstance?.getPersistData()
      ) as FortifiedGridPersistData;
      this.setPersistData(persistData);

      // Set Filter Settings
      const filterSettings = this.getGridFilterSettings(this.gridInstance);

      if (filterSettings) {
        this.setFilterSettings(filterSettings);
      }
    }
  };

  private getGridFilterSettings = (
    scopeGridInstance?: GridComponent
  ): FortifiedGridFilterSettings => {
    const gridFilterSettings = {
      rawQuery: this.requestFilterQuery,
      rawSearch: scopeGridInstance?.searchSettings.key,
      rawColumnSettings: (scopeGridInstance?.columns as Column[]).filter(
        (x) => x.visible
      ),
      rawFilterSettings: this.persistData?.filterSettings.columns || [],
      rawSortSettings: this.persistData?.sortSettings.columns || [],
    } as FortifiedGridFilterSettings;

    return gridFilterSettings;
  };

  public setGridInstance = (scopeGridInstance?: GridComponent) => {
    this.gridInstance = scopeGridInstance;
  };

  public setRequestFilterQuery = (filterQuery?: string) => {
    this.requestFilterQuery = filterQuery;
  };

  public setRequestType = (requestType?: string) => {
    this.requestType = requestType;
  };

  public setFilterSettings = (
    gridFilterSettings?: FortifiedGridFilterSettings
  ) => {
    this.filterSettings = gridFilterSettings;
  };

  public setPersistData = (persistData: FortifiedGridPersistData) => {
    this.persistData = persistData;
  };
}

export const fortifiedGridStore = new FortifiedGridStore();

export interface FortifiedGridSetup<T extends Object> {
  dataSource: string;
  accessToken: string;
  columnsConfiguration: Readonly<FortifiedGridColumn[]>;
  filterConfiguration?: PredicateModel[];
  sortConfiguration?: SortDescriptorModel[];
  gridConfiguration?: Readonly<FortifiedGridModel<T>>;
}
