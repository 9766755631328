import { Button, Intent } from "@blueprintjs/core";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";

interface ProceedToIBHSReviewActionButtonProps {}

const ProceedToIBHSReviewActionButton: React.FC<
  ProceedToIBHSReviewActionButtonProps
> = ({}) => {
  return (
    <Button
      intent={Intent.PRIMARY}
      text="Proceed to IBHS Review"
      onClick={() => {
        dialogsViewerStore.setIsProceedToIBHSReviewDialogOpen(true);
      }}
    />
  );
};

export default ProceedToIBHSReviewActionButton;
