import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { FCFMFRedesignationFormParts } from "../../common/types/Redesignation/BaseFCFMFRedesignationFormModel";
import MultifamilyRedesignationFormContainer from "../components/forms/ApplicationForm/Container/MultifamilyRedesignationFormContainer";

export interface MultifamilyRedesignationFormPageProps {
  evaluationId?: string;
  formPart?: FCFMFRedesignationFormParts;
}

const MultifamilyRedesignationFormPageComponent: FC<
  MultifamilyRedesignationFormPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <MultifamilyRedesignationFormContainer
            evaluationId={props.evaluationId}
            formPart={props.formPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const MultifamilyRedesignationFormPage = withAITracking(
  reactPlugin,
  MultifamilyRedesignationFormPageComponent,
  "MultifamilyRedesignationFormPage",
  "tracking-container"
);
