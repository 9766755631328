import { PrimaryCard, SecondaryCard } from "@ucl/library";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import FormChecklistProgress from "../../../../../../Common/components/Forms/FormChecklistProgress/FormChecklistProgress";
import { multifamilyRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedCommercialMultifamilyRouteConfig";
import { FCFMFEvaluationCompanyAssignmentFormParts } from "../../../../../common/types/Evaluation/BaseFCFMFEvaluationCompanyAssignmentForm";
import { FieldSchemaFactoryProvider } from "../../../../../common/customHooks/useFCFMFFieldSchemaFactory";
import MultifamilyEvaluationCompanyAssignmentForm from "../Form/MultifamilyEvaluationCompanyAssignmentForm";
import useMultifamilyEvaluationCompanyAssignmentFormContainer from "./useMultifamilyEvaluationCompanyAssignmentContainer";

export interface MultifamilyEvaluationCompanyAssignmentFormContainerProps {
  evaluationId: string;
  evaluationCompanyAssignmentFormPart?: FCFMFEvaluationCompanyAssignmentFormParts;
}

export const MultifamilyEvaluationCompanyAssignmentFormContainer = (
  props: MultifamilyEvaluationCompanyAssignmentFormContainerProps
) => {
  const {
    evaluationCompanyAssignmentChecklistParts,
    getEvaluationCompanyAssignmentPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    evaluationCompanyAssignmentFormPart,
    commercialSchemaFactory,
  } = useMultifamilyEvaluationCompanyAssignmentFormContainer(props);

  return (
    <>
      {!commercialSchemaFactory ? (
        <Loading />
      ) : (
        <div className="wildfire-form-page">
          <div className="wildfire-form-progress">
            <div className="wildfire-form-progress__steps">
              <FormChecklistProgress
                checklistParts={evaluationCompanyAssignmentChecklistParts}
                evaluationId={props.evaluationId}
                formURL={`${multifamilyRoutePrefix}/${props.evaluationId}/company-assignment-form`}
                title={"Evaluator Assignment Form"}
                activeFormPart={evaluationCompanyAssignmentFormPart}
                getChecklistStatus={
                  getEvaluationCompanyAssignmentPartChecklistStatus
                }
              />
            </div>
          </div>
          <section className="wildfire-form-content">
            <div className="wildfire-form-content__header"></div>
            <PrimaryCard
              className="wildfire-form-primary-card"
              headerContent={
                <div className="wildfire-form-primary-card__header">
                  Multifamily: Evaluation Evaluator Assignment Form
                </div>
              }
            >
              <SecondaryCard
                className="wildfire-form-secondary-card"
                hideBody={false}
                isCollapsible={false}
              >
                <FieldSchemaFactoryProvider
                  schemaFactory={commercialSchemaFactory}
                >
                  <MultifamilyEvaluationCompanyAssignmentForm
                    key={evaluationCompanyAssignmentFormPart}
                    evaluationId={props.evaluationId}
                    isIterationEnginePage={false}
                    formPart={evaluationCompanyAssignmentFormPart}
                    disableForm={false}
                    setFormModel={setEvaluationFormModel}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                  />
                </FieldSchemaFactoryProvider>
              </SecondaryCard>
            </PrimaryCard>
          </section>
        </div>
      )}
    </>
  );
};
export default MultifamilyEvaluationCompanyAssignmentFormContainer;
