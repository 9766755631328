import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC, useEffect, useState } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import wildfireBaseApiClient from "../../../../wildfire/lib/wildfireBaseApiClient";
import { FCFMFSchemaFactoryV2 } from "../../common/customHooks/useFCFMFFieldSchemaFactory";
import { Schema } from "@ucl/library";
import CommercialIterationEngine from "../components/IterationEngine/CommercialIterationEngine";
import { evaluationStore } from "../../../../foritfied/stores/EvaluationStore";

export interface CommercialIterationEnginePageProps {
  evaluationId?: string;
}

const CommercialIterationEnginePageComponent: FC<
  CommercialIterationEnginePageProps
> = (props) => {
  const [commercialSchemaFactory, setCommercialSchemaFactory] =
    useState<FCFMFSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireBaseApiClient.get<Schema>(`/wildfire/schema`);
    const newSchemaFactory = new FCFMFSchemaFactoryV2(schema);
    setCommercialSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!commercialSchemaFactory) {
      getSchema();
    }
  }, []);

  //TODO: Ideally we should not use this in the new system.
  //For time sake, we will use this for now. This is only used for the Header Buttons
  evaluationStore.init(props.evaluationId || "");

  return (
    <>
      {props.evaluationId && commercialSchemaFactory && (
        <UnSavedChangesBlockerProvider>
          <CommercialIterationEngine
            evaluationId={props.evaluationId}
            commercialSchemaFactory={commercialSchemaFactory}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const CommercialIterationEnginePage = withAITracking(
  reactPlugin,
  CommercialIterationEnginePageComponent,
  "CommercialIterationEnginePage",
  "tracking-container"
);
