import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { HomeBasicInformationFormParts } from "../../../common/types/BaseHomeEvaluationBasicInformationForm";
import { HomeSCSHEvaluationBasicInformationFormModel } from "../../types/HomeSCSHEvaluationBasicInformationFormModel";
import { Evaluation_HomeSCSHEvaluationFormModel } from "../../types/HomeSCSHEvaluationFormModel";

class HomeSCSHEvaluationBasicInformationAPIClient {
  updateHomeSCSHBasicInformationForm = async (
    evaluationId: string,
    basicInformationForm: HomeSCSHEvaluationBasicInformationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeSCSHEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeSCSHEvaluationFormModel>(
      `/home-scsh/basic-information-form/${evaluationId}`,
      {
        basicInformationForm,
        fieldKey,
      }
    );
  };

  submitHomeSCSHBasicInformationForm = async (
    evaluationId: string,
    basicInformationForm: HomeSCSHEvaluationBasicInformationFormModel,
    basicInformationFormPart?: HomeBasicInformationFormParts
  ): Promise<Evaluation_HomeSCSHEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const basicInformationFormPartValue = (
      basicInformationFormPart ??
      (Object.values(
        HomeBasicInformationFormParts
      ).pop() as HomeBasicInformationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeSCSHEvaluationFormModel>(
      `/home-scsh/basic-information-form/${evaluationId}/${basicInformationFormPartValue}`,
      basicInformationForm
    );
  };
}

export const homeSCSHEvaluationBasicInformationAPIClient =
  new HomeSCSHEvaluationBasicInformationAPIClient();
