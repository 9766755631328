import {
  Alignment,
  BreadcrumbProps,
  Breadcrumbs,
  Divider,
  Navbar,
} from "@blueprintjs/core";
import logo from "../../assets/Fortified_transparent.png";
import "./styles.scss";

export interface ExternalPageHeaderProps {
  fortifiedId?: string;
  address?: string;
  buildingName?: string;
  name?: string;
  breadCrumbs?: BreadcrumbProps[];
  renderActions?: () => React.ReactNode;
  renderWarning?: () => React.ReactNode;
}

export const buildHeading = (
  fortifiedId?: string,
  address?: string,
  buildingName?: string
) => {
  return fortifiedId && address && buildingName ? (
    <span>
      {buildingName}, {address} ({fortifiedId})
    </span>
  ) : (
    <span></span>
  );
};

const ExternalPageHeader: React.FC<ExternalPageHeaderProps> = ({
  fortifiedId,
  address,
  buildingName,
  name,
  breadCrumbs,
  renderActions,
  renderWarning,
}) => {
  return (
    <Navbar className="external-navbar">
      <Navbar.Group className="external-navbar_left" align={Alignment.LEFT}>
        <img src={logo} width={100} />
        <span className="external-navbar_left_title">{name}</span>
        {breadCrumbs && <Breadcrumbs items={breadCrumbs} />}
        <Divider />
        <span className="external-navbar__sub-title">
          {buildHeading(fortifiedId, address, buildingName)}
        </span>
      </Navbar.Group>
      <Navbar.Group>{renderWarning && renderWarning()}</Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        {renderActions && <div>{renderActions()}</div>}
      </Navbar.Group>
    </Navbar>
  );
};

export default ExternalPageHeader;
