import React, { useEffect, useState } from "react";
import { ComplexCommercialEvaluationBuildingRelationship } from "../../../../types/evaluation/Evaluation";
import { evaluationApiClient } from "../../../../lib/apiClients/evaluation/evaluationApiClient";
import { evaluationStore } from "../../../../stores/EvaluationStore";
import { Divider, Spinner, SpinnerSize, Tab, Tabs } from "@blueprintjs/core";
import { SelectableLabelWithToolTip } from "../../../Fields/SelectableLabel/SelectableLabelWithToolTip";
import { observer } from "mobx-react";
import { useProductTypeHandler } from "../../../../providers/ProductTypeHandlerProvider";
import { parseDateToDateString } from "@ucl/library/lib/utils/date";

export interface ComplexCommercialEvaluationRelationshipViewProps {
  evaluationId: string;
  readonly?: boolean;
}

export const ComplexCommercialEvaluationRelationshipView: React.FC<
  ComplexCommercialEvaluationRelationshipViewProps
> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [
    complexEvaluationBuildingRelationship,
    setComplexEvaluationBuildingRelationship,
  ] = useState<ComplexCommercialEvaluationBuildingRelationship>();

  const { routePrefix } = useProductTypeHandler();

  const getComplexCommercialEvaluationId = () => {
    const evaluation = evaluationStore.evaluation;

    if (!evaluation) {
      return null;
    }

    if (evaluation.isComplexCommercialEvaluation) {
      return evaluation.id;
    } else if (evaluation.isComplexCommercialEvaluationBuilding) {
      return evaluation.complexCommercialAndMultifamilyEvaluationId;
    }
  };

  const getParentRowDisplayText = (
    family: ComplexCommercialEvaluationBuildingRelationship
  ) => {
    return `Project - ${parseDateToDateString(family.createdAt)}`;
  };

  const getBuildingRowDisplayText = (index: number) => {
    return `Building ${index + 1}`;
  };

  const getComplexEvaluationBuildingRelationship = async (
    complexCommercialEvaluationId: string
  ) => {
    const buildingRelationship =
      await evaluationApiClient.getComplexCommercialEvaluationBuildingRelationship(
        complexCommercialEvaluationId
      );

    setComplexEvaluationBuildingRelationship(buildingRelationship);
    setIsLoading(false);
  };

  const checkIfBuildingsAreFinishedBeingProcessed = async (
    complexCommercialEvaluationId: string
  ) => {
    if (
      !evaluationStore.evaluation?.areBuildingsFinishedBeingProcessed &&
      evaluationStore.isComplexCommercialEvaluation
    ) {
      setIsLoading(true);
      const intervalId = setInterval(async () => {
        if (evaluationStore.evaluation?.areBuildingsFinishedBeingProcessed) {
          clearInterval(intervalId);
          getComplexEvaluationBuildingRelationship(
            complexCommercialEvaluationId
          );
          setIsLoading(false);
        }
      }, 5000);
    }
  };

  useEffect(() => {
    const complexCommercialEvaluationId = getComplexCommercialEvaluationId();

    if (complexCommercialEvaluationId) {
      checkIfBuildingsAreFinishedBeingProcessed(complexCommercialEvaluationId);
      getComplexEvaluationBuildingRelationship(
        complexCommercialEvaluationId
      ).catch(console.error);
    }
  }, [evaluationStore.complexCommercialEvaluationBuildings]);

  return (
    <>
      {getComplexCommercialEvaluationId() && (
        <div className="evaluation-relationships">
          <Divider className="evaluation-container__status__divider" />
          <SelectableLabelWithToolTip name="Buildings" />

          {isLoading && <Spinner size={SpinnerSize.SMALL} />}
          {!isLoading && complexEvaluationBuildingRelationship && (
            <Tabs
              vertical
              animate={false}
              selectedTabId={props.evaluationId}
              onChange={(newTabId: number) =>
                window.open(`${routePrefix}/evaluation/${newTabId}`, "_self")
              }
            >
              <Tab
                key={complexEvaluationBuildingRelationship.id}
                id={complexEvaluationBuildingRelationship.id}
                title={getParentRowDisplayText(
                  complexEvaluationBuildingRelationship
                )}
                disabled={
                  complexEvaluationBuildingRelationship.id ===
                  props.evaluationId
                }
              />
              {complexEvaluationBuildingRelationship.buildings.map(
                (building, index) => (
                  <Tab
                    key={building.id}
                    id={building.id}
                    title={getBuildingRowDisplayText(index)}
                    disabled={building.id === props.evaluationId}
                  />
                )
              )}
            </Tabs>
          )}
        </div>
      )}
    </>
  );
};

export default observer(ComplexCommercialEvaluationRelationshipView);
