import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  buildLink,
  NotifySubmitterLinkProps,
} from "./NotifySubmitterLinkBuilder";
import "./styles.scss";

export const NotifySubmitterLink: React.FC<NotifySubmitterLinkProps> = ({
  mailTo,
  fortifiedId,
  address,
  formLinks,
}) => {
  return (
    <div className="email-notification">
      <a
        href={buildLink({
          mailTo,
          fortifiedId,
          address,
          formLinks,
        })}
        target="_blank"
        rel="noreferrer"
      >
        <Icon icon={IconNames.ENVELOPE} />
        Notify Submitter
      </a>
    </div>
  );
};
