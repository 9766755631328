import * as msal from "@azure/msal-browser";
import _envVar from "../../config/variables";
import { baseMsalConfig, ibhsB2CPolicies, loginRequest } from ".";

// Specific configuration for Fortified Home, extending the base configuration
export const fortifiedHomeMsalConfig = {
  ...baseMsalConfig,
  auth: {
    ...baseMsalConfig.auth,
    authority: ibhsB2CPolicies.authorities.signUpSignIn.fortifiedHomeAuthority,
    redirectUri: _envVar.fortified_Home_RedirectUri,
    postLogoutRedirectUri: _envVar.fortified_Home_PostLogoutRedirectUri,
  },
};

export const fortifiedHomeMsalInstance = new msal.PublicClientApplication(
  fortifiedHomeMsalConfig
);

export const fortifiedHomeLogout = async () => {
  const loggedInAccount = fortifiedHomeMsalInstance.getActiveAccount();

  await fortifiedHomeMsalInstance.logoutRedirect({
    account: loggedInAccount,
    postLogoutRedirectUri: fortifiedHomeMsalConfig.auth.postLogoutRedirectUri,
  });
};

export const getFortifiedHomeAccessToken = async () => {
  try {
    const token = await fortifiedHomeMsalInstance.acquireTokenSilent(
      loginRequest
    );
    return token.accessToken;
  } catch (e) {
    fortifiedHomeMsalInstance.loginRedirect(loginRequest);
  }
};
