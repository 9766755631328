import { WildfireBoxContentExplorerValue } from "../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum FCFMFComplianceLetterParts {
  ComplianceLetterPhotoUploader = "compliance-letter-photo-uploader",
}

export interface BaseFCFMFComplianceLetterModel
  extends BaseFCFMFComplianceLetterFileUploaders {}

interface BaseFCFMFComplianceLetterFileUploaders {
  complianceLetterPhotoUploader: WildfireBoxContentExplorerValue;
}
