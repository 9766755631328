import { makeObservable, observable } from "mobx";
import { DecisionsApplicationStatus } from "../types/decisions/ApplicationStatus";
import { IterationEngineChangeRequest } from "../types/viewModels/IterationEngineChangeRequest";

export class IterationEngineStore {
  public changeRequests: Record<string, Partial<IterationEngineChangeRequest>> =
    {};
  public applicationStatus?: DecisionsApplicationStatus;
  constructor() {
    makeObservable(this, {
      applicationStatus: observable,
      changeRequests: observable,
    });
  }
}

export const iterationEngineStore = new IterationEngineStore();
