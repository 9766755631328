import { useEffect, useState } from "react";

export const useTitle = (defaultTitle: string) => {
  const [title, setTitle] = useState<string>(defaultTitle);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    document.title = defaultTitle;
    return () => {
      document.title = defaultTitle;
    };
  }, []);

  return { setTitle };
};
