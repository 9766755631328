import { FormGroup } from "@blueprintjs/core";
import { Option } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import React from "react";
import { MultiSelectFieldProps, MultiSelectFieldValue } from "../types";
import { MultiSelect2 } from "@blueprintjs/select";
import { MenuItem2 } from "@blueprintjs/popover2";
import { SelectableLabelWithToolTip } from "../SelectableLabel/SelectableLabelWithToolTip";

export const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
  value,
  description,
  label,
  optionValues,
  onSubmit,
  hidden,
  errorMessages,
  isRequired,
  disabled,
}) => {
  if (hidden) {
    return null;
  }

  const [localValue, setLocalValue] = React.useState<MultiSelectFieldValue>(
    value || []
  );

  return (
    <FormGroup
      className={`${
        isRequired && localValue.length === 0
          ? "has-required-background"
          : "select-field"
      }`}
    >
      {label && (
        <SelectableLabelWithToolTip name={label} description={description} />
      )}
      <div className="multi-select-container">
        <MultiSelect2<Option<number>>
          className="multi-select-input"
          disabled={disabled}
          selectedItems={
            localValue
              .map((value) =>
                optionValues.find(
                  (optionValue) => String(optionValue.value) === String(value)
                )
              )
              .filter((value) => !!value) as Option<number>[]
          }
          popoverProps={{
            position: "auto",
            hasBackdrop: true,
            minimal: false,
            canEscapeKeyClose: true,
          }}
          scrollToActiveItem={true}
          items={optionValues}
          itemRenderer={(item, itemProps) => {
            return (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <MenuItem2
                className="select__menu-item"
                key={itemProps.index}
                text={item.label}
                active={localValue.some(
                  (value) => String(value) === String(item.value)
                )}
                onClick={itemProps.handleClick}
                {...itemProps}
              />
            );
          }}
          onClear={() => setLocalValue([])}
          noResults={"No Results"}
          resetOnSelect={true}
          onRemove={(item) => {
            const newValue = localValue.filter(
              (value) => String(value) !== String(item.value)
            );
            setLocalValue(newValue);
            onSubmit(newValue);
          }}
          onItemSelect={(item) => {
            let newValue = [];
            if (
              localValue.some((value) => String(value) === String(item.value))
            ) {
              newValue = localValue.filter(
                (value) => String(value) !== String(item.value)
              );
              setLocalValue(newValue);
            } else {
              newValue = [...localValue, item.value];
              setLocalValue(newValue);
            }
            onSubmit(newValue);
          }}
          tagRenderer={(item) => item.label}
          itemListPredicate={(query, items) => {
            query = query.toLowerCase();

            return items.filter((optionValue) =>
              optionValue.label.toLowerCase().includes(query)
            );
          }}
        />
      </div>
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
