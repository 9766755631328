import {
  AddressFieldValue,
  EmailFieldValue,
  EnumRadioFieldValue,
  PhoneNumberFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum FCFMFRedesignationFormParts {
  ApplicationInformation = "application-information",
  GeneralDetails = "general-details",
}

export interface BaseFCFMFRedesignationFormModel
  extends ApplicationInformation,
    GeneralDetails {}

interface ApplicationInformation {
  applicantFirstName: TextFieldValue;
  applicantLastName: TextFieldValue;
  businessName: TextFieldValue;
  businessAddress: AddressFieldValue;
  hasTheBuildingNameChanged: EnumRadioFieldValue;
  buildingName: TextFieldValue;
  businessPhoneNumber: PhoneNumberFieldValue;
  businessEmail: EmailFieldValue;
  areYouTheBuildingOwner: EnumRadioFieldValue;
  title: TextFieldValue;
  companyName: TextFieldValue;
  phone: PhoneNumberFieldValue;
  email: EmailFieldValue;
}

interface GeneralDetails {
  hasRenovationsModificationsAdditionsOrRepairs: EnumRadioFieldValue;
  hasContactedIBHSAndEvaluator: EnumRadioFieldValue;
  evaluatorDocumentationConfirmationPhotos: WildfireBoxContentExplorerValue;
  buildingRenovationsModificationsOrAdditionsPhotos: WildfireBoxContentExplorerValue;
}
