import { EvaluationStep } from "../../../../../../foritfied/types/evaluation/Evaluation";
import { SendToComplianceFormsButton } from "../../../../common/components/Button/SendToComplianceForms/SendToComplianceFormsButton";
import { SendToEvaluatorAssignmentButton } from "../../../../common/components/Button/SendToEvaluatorAssignment/SendToEvaluatorAssignmentButton";
import { SendToFieldInspectionsButton } from "../../../../common/components/Button/SendToFieldInspections/SendToFieldInspectionsButton";
import { SendToIBHSReviewButton } from "../../../../common/components/Button/SendToIBHSReview/SendToIBHSReviewButton";
import { Evaluation_MultifamilyEvaluation } from "../../../types/MultifamilyEvaluation";
import { multifamilyEvaluationAPIClient } from "../../../lib/apiClients/multifamilyEvaluationAPIClient";
import { permissionStore } from "../../../../../../foritfied/stores/PermissionStore";
import { SendToComplianceLetterButton } from "../../../../common/components/Button/SendToComplianceLetter/SendToComplianceLetterButton";
import {
  MessageComponent,
  Severity,
} from "@syncfusion/ej2-react-notifications";
import { evaluationStore } from "../../../../../../foritfied/stores/EvaluationStore";
import LegacyEvaluationActionLink from "../../../../../../foritfied/components/Evaluation/EvaluationActions/LegacyEvaluationActionLink";
import LegacyEvaluationDataActionLink from "../../../../../../foritfied/components/Evaluation/EvaluationActions/LegacyEvaluationDataActionLink";
import ProceedToFCFMFPaymentActionButton from "../../../../../../foritfied/components/Checklist/ProceedToFCFMFPaymentActionButton";
import WithdrawEvaluationActionButton from "../../../../../../foritfied/components/Evaluation/EvaluationActions/WithdrawEvaluationActionButton";
import DownloadCertificateActionButton from "../../../../../../foritfied/components/Evaluation/EvaluationActions/DownloadCertificateActionButton";
import ViewRejectionReasonsActionButton from "../../../../../../foritfied/components/Evaluation/EvaluationActions/ViewRejectionReasonsActionButton";
import PostDesignationFilesActionButton from "../../../../../../foritfied/components/Evaluation/EvaluationActions/PostDesignationFilesActionButton";
import ClaimEvaluationActionButton from "../../../../../../foritfied/components/Evaluation/EvaluationActions/ClaimEvaluationActionButton";
import ResubmitEvaluationActionButton from "../../../../../../foritfied/components/Evaluation/EvaluationActions/ResubmitEvaluationActionButton";
import { EvaluationLabel } from "../../../../../../foritfied/components/Evaluation/EvaluationActions/EvaluationLabel";
import { fortifiedUserStore } from "../../../../../../foritfied/stores/FortifiedUserStore";
import SendForReviewButton from "../../../../common/components/Button/SendForReview/SendForReviewActionButton";
import RequestChangesButton from "../../../../common/components/Button/RequestChanges/RequestChangesActionButton";
import { Button, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { dialogsViewerStore } from "../../../../../../foritfied/stores/DialogsViewerStore";
import "./styles.scss";
import { showSuccessAppToaster } from "@ucl/library/lib/components/Toast/Toast";

interface MultifamilyIterationEngineHeaderProps {
  evaluationId: string;
  evaluation: Evaluation_MultifamilyEvaluation;
  setEvaluation: (evaluation: Evaluation_MultifamilyEvaluation) => void;
  allChangeRequestsAreResolved: boolean;
  hasNoUnaddressedChangeRequests: boolean;
  addressedAtLeastOneChangeRequest: boolean;
  isEvaluationClaimedByCurrentUser: boolean;
  refreshEvaluation: () => Promise<void>;
}

const MultifamilyIterationEngineHeader: React.FC<
  MultifamilyIterationEngineHeaderProps
> = (props) => {
  const canMoveWorkflowForward =
    permissionStore.canViewSendForwardV2 &&
    props.isEvaluationClaimedByCurrentUser;

  const canSendToEvaluatorAssignment =
    canMoveWorkflowForward &&
    props.evaluation.step === EvaluationStep.EligibilityReview;

  const canSendToComplianceForms =
    canMoveWorkflowForward &&
    props.evaluation.step === EvaluationStep.EvaluatorAssignment;

  const canSendToFieldInspections =
    canMoveWorkflowForward &&
    props.evaluation.step === EvaluationStep.ComplianceFormSubmitted;

  const canSendToComplianceLetter =
    canMoveWorkflowForward &&
    props.evaluation.step === EvaluationStep.FieldInspectionsSubmitted;

  const canSendToIBHSReview =
    canMoveWorkflowForward &&
    props.evaluation.step === EvaluationStep.EvaluatorComplianceLetterSubmitted;

  const hasFinalDetermination = props.evaluation.hasFinalDetermination === true;

  const canSendForReview =
    (props.addressedAtLeastOneChangeRequest &&
      props.hasNoUnaddressedChangeRequests &&
      permissionStore.canViewSendForReview) ||
    props.evaluation?.step === EvaluationStep.ComplianceFormSubmitted;

  const afterAction = (evaluation: Evaluation_MultifamilyEvaluation) => {
    props.setEvaluation(evaluation);
  };

  const canViewAuditorButtons = () => {
    if (hasFinalDetermination) {
      return false;
    }
    switch (props.evaluation.step) {
      case EvaluationStep.EligibilityReview:
      case EvaluationStep.FinalReview:
        return (
          fortifiedUserStore.hasRole("FC/FMF Auditor") ||
          fortifiedUserStore.hasRole("FH Auditor") ||
          fortifiedUserStore.hasRole("IBHS Admin") ||
          fortifiedUserStore.hasRole("Relativity Admin") ||
          fortifiedUserStore.hasRole("FC/FMF Admin")
        );
      case EvaluationStep.ComplianceFormSubmitted:
      case EvaluationStep.FieldInspectionsSubmitted:
        return (
          fortifiedUserStore.hasRole("FC/FMF Evaluator") ||
          fortifiedUserStore.hasRole("IBHS Admin") ||
          fortifiedUserStore.hasRole("Relativity Admin") ||
          fortifiedUserStore.hasRole("FC/FMF Admin")
        );
      default:
        return false;
    }
  };

  const canAudit =
    props.isEvaluationClaimedByCurrentUser && canViewAuditorButtons();

  return (
    <div className="wildfire-iteration-engine__header">
      <div className="left-panel">
        {canSendToEvaluatorAssignment && (
          <SendToEvaluatorAssignmentButton
            evaluationId={props.evaluationId}
            isDisabled={!props.allChangeRequestsAreResolved}
            sendToEvaluatorAssignment={async () => {
              await multifamilyEvaluationAPIClient
                .moveToEvaluatorAssignmentStep(props.evaluationId)
                .then((evaluation) => {
                  afterAction(evaluation);
                  showSuccessAppToaster(
                    "Evaluation moved to Evaluator Assignment"
                  );
                });
            }}
          />
        )}
        {canSendToComplianceForms && (
          <SendToComplianceFormsButton
            evaluationId={props.evaluationId}
            isDisabled={
              !props.evaluation.multifamilyEvaluation
                ?.multifamilyEvaluationCompanyAssignmentForm
                ?.isEvaluationCompanyAssignmentFormComplete
            }
            sendToComplianceForms={async () => {
              await multifamilyEvaluationAPIClient
                .moveToComplianceFormsStep(props.evaluationId)
                .then((evaluation) => {
                  afterAction(evaluation);
                  showSuccessAppToaster("Evaluation moved to Compliance Forms");
                });
            }}
          />
        )}
        {canSendToFieldInspections && (
          <SendToFieldInspectionsButton
            evaluationId={props.evaluationId}
            isDisabled={
              !props.evaluation.multifamilyEvaluation
                ?.multifamilyEvaluationComplianceForm?.isFormComplete
            }
            sendToFieldInspections={async () => {
              await multifamilyEvaluationAPIClient
                .moveToFieldInspectionsStep(props.evaluationId)
                .then((evaluation) => {
                  afterAction(evaluation);
                  showSuccessAppToaster(
                    "Evaluation moved to Field Inspections"
                  );
                });
            }}
          />
        )}
        {canSendToComplianceLetter && (
          <SendToComplianceLetterButton
            evaluationId={props.evaluationId}
            isDisabled={
              !props.evaluation.multifamilyEvaluation
                ?.multifamilyEvaluationFieldInspectionForm
                ?.isFileUploadersComplete
            }
            sendToComplianceLetter={async () => {
              await multifamilyEvaluationAPIClient
                .moveToComplianceLetterStep(props.evaluationId)
                .then((evaluation) => {
                  afterAction(evaluation);
                  showSuccessAppToaster(
                    "Evaluation moved to Compliance Letter"
                  );
                });
            }}
          />
        )}
        {canSendToIBHSReview && (
          <SendToIBHSReviewButton
            evaluationId={props.evaluationId}
            isDisabled={
              !props.evaluation.multifamilyEvaluation
                ?.multifamilyEvaluationComplianceLetterForm?.isFormComplete
            }
            sendToIBHSReview={async () => {
              await multifamilyEvaluationAPIClient
                .moveToIBHSReviewStep(props.evaluationId)
                .then((evaluation) => {
                  afterAction(evaluation);
                  showSuccessAppToaster("Evaluation moved to IBHS Review");
                });
            }}
          />
        )}
      </div>
      <div className="right-panel">
        {evaluationStore.isEvaluationOnRequiredPaymentStep && (
          <MessageComponent
            content={"Payment still needs to be submitted"}
            severity={Severity.Warning}
          />
        )}
        {evaluationStore.isLegacyEvaluation &&
          permissionStore.canViewLegacyEvaluationFiles && (
            <LegacyEvaluationActionLink />
          )}
        {evaluationStore.isLegacyEvaluation &&
          permissionStore.canViewLegacyEvaluationFiles && (
            <LegacyEvaluationDataActionLink />
          )}
        {permissionStore.canWithdraw(evaluationStore.evaluation) &&
          !hasFinalDetermination && <WithdrawEvaluationActionButton />}
        {props.evaluation?.multifamilyEvaluation?.isFCFMFPaymentStep && (
          <ProceedToFCFMFPaymentActionButton />
        )}
        {props.evaluation.approvedAt && <DownloadCertificateActionButton />}
        {props.evaluation?.isApproved === false && (
          <ViewRejectionReasonsActionButton />
        )}
        {props.evaluation.isApproved && (
          <PostDesignationFilesActionButton
            evaluationId={props.evaluation?.id}
            rootBoxFolderId={props.evaluation?.externalFolderId}
            postDesignationBoxFolderId={
              evaluationStore.evaluation?.postDesignationBoxFolderId
            }
          />
        )}
        {evaluationStore.canResubmit && <ResubmitEvaluationActionButton />}
        {permissionStore.canClaimEvaluations && (
          <ClaimEvaluationActionButton
            afterClaimed={props.refreshEvaluation}
            isEvaluationClaimedByCurrentUser={
              props.isEvaluationClaimedByCurrentUser
            }
          />
        )}
        {!evaluationStore.doesEvaluationHaveFinalDetermination &&
          props.evaluation.step &&
          fortifiedUserStore.displayRole && (
            <EvaluationLabel
              step={props.evaluation.step}
              role={fortifiedUserStore.displayRole}
              canViewPendingLabels={permissionStore.canViewPendingLabels}
            />
          )}
        <SendForReviewButton
          shouldDisplaySendForReview={permissionStore.canViewSendForReview}
          isSendForReviewDisabled={!canSendForReview}
          sendForReview={async () =>
            await multifamilyEvaluationAPIClient.sendChangesForReviewEmail(
              props.evaluationId
            )
          }
        />
        {canAudit && (
          <>
            {props.evaluation.step == EvaluationStep.FinalReview && (
              <Button
                text="Approve"
                intent={Intent.SUCCESS}
                icon={IconNames.TICK_CIRCLE}
                disabled={!props.allChangeRequestsAreResolved}
                minimal
                onClick={() => {
                  dialogsViewerStore.setIsAuditorApprovalDialogOpen(true, {
                    afterApproval: props.refreshEvaluation,
                  });
                }}
              />
            )}

            <RequestChangesButton
              isRequestChangesDisabled={props.hasNoUnaddressedChangeRequests}
              requestChanges={async () =>
                await multifamilyEvaluationAPIClient.sendRequestedChangesEmail(
                  props.evaluationId
                )
              }
            />

            <Button
              text="Reject"
              intent={Intent.DANGER}
              icon={IconNames.DELETE}
              minimal
              onClick={() =>
                dialogsViewerStore.setIsAuditorRejectionDialogOpen(true, {
                  afterRejection: props.refreshEvaluation,
                })
              }
              disabled={!props.isEvaluationClaimedByCurrentUser}
            />
          </>
        )}
        {permissionStore.canViewAdminButton && (
          <Button
            minimal
            large
            onClick={() => {
              dialogsViewerStore.setIsAdminDialogOpen(true, {
                evaluationId: props.evaluationId,
              });
            }}
          >
            <Icon icon={IconNames.COG} size={16} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default MultifamilyIterationEngineHeader;
