import { ReactNode } from "react";
import parse from "html-react-parser";
import { sanitize } from "dompurify";

export const sanitizeHtml = (html: string): string => {
  return sanitize(html, { USE_PROFILES: { html: true } });
};

export const parseUntrustedHtml = (html: string): ReactNode => {
  return parse(sanitizeHtml(html));
};

export const parseUntrustedHtmlAsPlaintext = (html: string): ReactNode => {
  return parse(
    sanitize(html, {
      ALLOWED_TAGS: [],
      ALLOWED_ATTR: [],
      KEEP_CONTENT: true,
    })
  );
};
