import baseApiClient from "../baseApiClient";
import baseExternalApiClient from "../baseExternalApiClient";
import { UserReadDTO, UserUpdateDTO } from "./types";

class UserApiClient {
  getBoxToken = async () => {
    return baseApiClient.get<string>(`user/box-token`);
  };

  getExternalViewBoxToken = async (parentFolderId: string) => {
    return baseExternalApiClient.get<string>(
      `user/external-view/box-token/${parentFolderId}`
    );
  };

  signIn = async () => {
    return baseApiClient.post<UserReadDTO>(`user/signin`);
  };

  getUserById = async (id: string) => {
    return baseApiClient.get<UserReadDTO | undefined>(`user/${id}`);
  };

  updateUserById = async (id: string, data: UserUpdateDTO) => {
    return baseApiClient.put<UserReadDTO | undefined>(`user/${id}`, data);
  };
}

export const userApiClient = new UserApiClient();
