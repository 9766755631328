import { Observer } from "mobx-react";
import { evaluationStore } from "../../../../stores/EvaluationStore";
import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import "./styles.scss";
import { useState } from "react";
import { ComplexCommercialEvaluationBuilding } from "../../../../types/evaluation/Evaluation";
import { ComplexCommercialEvaluationBuildingForm } from "./ComplexCommercialEvaluationBuildingForm";
import { SecondaryCard, SecondaryCardHeader } from "@ucl/library";

export interface ComplexCommercialEvaluationBuildingFormProps {
  building: ComplexCommercialEvaluationBuilding;
  buildingCount: number;
  isSaving: boolean;
}

const ComplexCommercialEvaluationBuildingCard: React.FC<
  ComplexCommercialEvaluationBuildingFormProps
> = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteBuilding = async () => {
    setIsDeleting(true);
    await evaluationStore
      .deleteComplexCommercialEvaluationBuilding(props.building.id)
      .finally(() => setIsDeleting(false));
  };

  return (
    <Observer>
      {() => (
        <SecondaryCard
          id={`complex-building-form-${props.building.id}`}
          key={props.building.id}
          isCollapsible
          headerContent={
            <SecondaryCardHeader label={`Building ${props.buildingCount}`}>
              <h4 className="bp4-heading status-heading">{`Status: ${props.building.status_AsString}`}</h4>
              <Button
                intent={Intent.DANGER}
                icon={IconNames.Trash}
                loading={isDeleting}
                disabled={
                  !evaluationStore.isComplexCommercialEvaluationBuildingFormAbleToBeModified ||
                  props.isSaving
                }
                minimal
                large
                onClick={async () => {
                  await deleteBuilding();
                }}
              />
            </SecondaryCardHeader>
          }
        >
          <ComplexCommercialEvaluationBuildingForm
            evaluationForm={props.building.commercialApplicationForm}
            updateEvaluationItemData={
              evaluationStore.updateBuildingEvaluationItemData
            }
            complexCommercialEvaluationBuildingId={props.building.id}
            isDeleting={isDeleting}
            isSaving={props.isSaving}
          />
        </SecondaryCard>
      )}
    </Observer>
  );
};

export default ComplexCommercialEvaluationBuildingCard;
