import React, { useEffect, useState } from "react";
import { Divider, Spinner, SpinnerSize, Tab, Tabs } from "@blueprintjs/core";
import { parseDateToDateString } from "@ucl/library/lib/utils/date";
import { ComplexCommercialEvaluationBuildingRelationship } from "../../../../../foritfied/types/evaluation/Evaluation";
import { evaluationApiClient } from "../../../../../foritfied/lib/apiClients/evaluation/evaluationApiClient";
import { SelectableLabelWithToolTip } from "../../../../../foritfied/components/Fields/SelectableLabel/SelectableLabelWithToolTip";
import { Evaluation_MultifamilyEvaluation } from "../../types/MultifamilyEvaluation";
import { multifamilyEvaluationAPIClient } from "../../lib/apiClients/multifamilyEvaluationAPIClient";

export interface ComplexMultifamilyEvaluationRelationshipViewV2Props {
  evaluationId: string;
}

export const ComplexMultifamilyEvaluationRelationshipViewV2: React.FC<
  ComplexMultifamilyEvaluationRelationshipViewV2Props
> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [
    complexEvaluationBuildingRelationship,
    setComplexEvaluationBuildingRelationship,
  ] = useState<ComplexCommercialEvaluationBuildingRelationship>();

  const [evaluation, setEvaluation] =
    useState<Evaluation_MultifamilyEvaluation>();
  const [complexCommercialEvaluationId, setComplexCommercialEvaluationId] =
    useState<string>();

  const init = async () => {
    const evaluation =
      await multifamilyEvaluationAPIClient.getMultifamilyEvaluation(
        props.evaluationId
      );
    setEvaluation(evaluation);

    if (!evaluation) {
      return null;
    }

    if (evaluation.isComplexCommercialEvaluation) {
      setComplexCommercialEvaluationId(evaluation.id);
      return evaluation.id;
    } else if (evaluation.isComplexCommercialEvaluationBuilding) {
      setComplexCommercialEvaluationId(
        evaluation.complexCommercialAndMultifamilyEvaluationId
      );
      return evaluation.complexCommercialAndMultifamilyEvaluationId;
    }
  };

  const getParentRowDisplayText = (
    family: ComplexCommercialEvaluationBuildingRelationship
  ) => {
    return `Project - ${parseDateToDateString(family.createdAt)}`;
  };

  const getBuildingRowDisplayText = (index: number) => {
    return `Building ${index + 1}`;
  };

  const getComplexEvaluationBuildingRelationship = async (
    complexCommercialEvaluationId: string
  ) => {
    const buildingRelationship =
      await evaluationApiClient.getComplexCommercialEvaluationBuildingRelationship(
        complexCommercialEvaluationId
      );

    setComplexEvaluationBuildingRelationship(buildingRelationship);
    setIsLoading(false);
  };

  const checkIfBuildingsAreFinishedBeingProcessed = async (
    complexCommercialEvaluationId: string
  ) => {
    if (
      !evaluation?.areBuildingsFinishedBeingProcessed &&
      evaluation?.isComplexCommercialEvaluation
    ) {
      setIsLoading(true);
      const intervalId = setInterval(async () => {
        if (evaluation?.areBuildingsFinishedBeingProcessed) {
          clearInterval(intervalId);
          getComplexEvaluationBuildingRelationship(
            complexCommercialEvaluationId
          );
          setIsLoading(false);
        }
      }, 5000);
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (complexCommercialEvaluationId) {
      checkIfBuildingsAreFinishedBeingProcessed(complexCommercialEvaluationId);
      getComplexEvaluationBuildingRelationship(
        complexCommercialEvaluationId
      ).catch(console.error);
    }
  }, [complexCommercialEvaluationId]);

  return (
    <>
      {complexCommercialEvaluationId && (
        <div className="evaluation-relationships">
          <Divider className="evaluation-container__status__divider" />
          <SelectableLabelWithToolTip name="Buildings" />

          {isLoading && (
            <div>
              <Spinner size={SpinnerSize.SMALL} />
            </div>
          )}
          {!isLoading && complexEvaluationBuildingRelationship && (
            <Tabs
              vertical
              animate={false}
              selectedTabId={props.evaluationId}
              onChange={(newTabId: number) =>
                window.open(
                  `/fc-fmf/multifamily/iteration-engine/${newTabId}`,
                  "_self"
                )
              }
            >
              <Tab
                key={complexEvaluationBuildingRelationship.id}
                id={complexEvaluationBuildingRelationship.id}
                title={getParentRowDisplayText(
                  complexEvaluationBuildingRelationship
                )}
                disabled={
                  complexEvaluationBuildingRelationship.id ===
                  props.evaluationId
                }
              />
              {complexEvaluationBuildingRelationship.buildings.map(
                (building, index) => (
                  <Tab
                    key={building.id}
                    id={building.id}
                    title={getBuildingRowDisplayText(index)}
                    disabled={building.id === props.evaluationId}
                  />
                )
              )}
            </Tabs>
          )}
        </div>
      )}
    </>
  );
};

export default ComplexMultifamilyEvaluationRelationshipViewV2;
