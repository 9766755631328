import {
  FieldFormV2,
  FieldSchemaFormBuilderV2,
  RelativityTooltip,
} from "@ucl/library";
import { Button, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import useCommercialApplicationForm from "./useMultifamilyRedesignationForm";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { useNavigate } from "react-router";
import { FCFMFApplicationFormParts } from "../../../../../common/types/Evaluation/BaseFCFMFApplicationForm";
import { multifamilyRedesignationRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedCommercialMultifamilyRouteConfig";
import { FCFMFRedesignationFormParts } from "../../../../../common/types/Redesignation/BaseFCFMFRedesignationFormModel";
import { Evaluation_MultifamilyRedesignationFormModel } from "../../../../types/MultifamilyRedesignationFormModel";
import classnames from "classnames";

export interface MultifamilyRedesignationFormProps {
  evaluationId: string;
  isIterationEnginePage?: boolean;
  formPart?: FCFMFRedesignationFormParts;
  onFormSave?: (fieldKey: string, value: any) => Promise<void>;
  setFormModel?: (
    formModel: Evaluation_MultifamilyRedesignationFormModel
  ) => void;
  disableForm?: boolean;
  onInitialized?: () => void;
  setHasUnsavedChanges: (value: boolean) => void;
  hideFormControls?: boolean;
}

export const MultifamilyRedesignationForm = (
  props: MultifamilyRedesignationFormProps
) => {
  const {
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    applicationFormModel,
    isFirstPartOfForm,
    isLastPartOfForm,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
    setShouldSubmit,
  } = useCommercialApplicationForm(props);

  const navigate = useNavigate();

  return (
    <div ref={containerRef} className="wildfire-form-container">
      {noBuilders || isLoading ? (
        <Loading />
      ) : !applicationFormModel ? (
        <NonIdealState />
      ) : (
        <>
          <FieldFormV2<Evaluation_MultifamilyRedesignationFormModel>
            isDisabled={isSubmitting || props.disableForm}
            value={applicationFormModel}
            fieldSchemaFormBuilder={
              getApplicationFormPartBuilder() as FieldSchemaFormBuilderV2<Evaluation_MultifamilyRedesignationFormModel>
            }
            onFormSubmit={async (form) => {
              await handleFormSubmit(form);
            }}
            ref={formRef}
            onFieldChanged={async (form, value, fieldProps) => {
              await onFormFieldChange(form, value, fieldProps);
            }}
          />
          {!props.hideFormControls && (
            <div className="wildfire-form-footer">
              {!isFirstPartOfForm && (
                <Button
                  className={
                    "wildfire-form-back-button " +
                    "wildfire-form-navigation-button"
                  }
                  text="Back"
                  icon={IconNames.ARROW_LEFT}
                  onClick={() => {
                    // Navigate to the previous part of the form
                    const previousFormPart = Object.values(
                      FCFMFApplicationFormParts
                    )[
                      Object.values(FCFMFRedesignationFormParts).indexOf(
                        props.formPart!
                      ) - 1
                    ].valueOf();
                    navigate(
                      `${multifamilyRedesignationRoutePrefix}/${props.evaluationId}/form/${previousFormPart}`
                    );
                  }}
                />
              )}
              {!isLastPartOfForm && (
                <RelativityTooltip shouldShowTooltip={false} content={""}>
                  <Button
                    className={classnames({
                      "wildfire-form-submit-button": isLastPartOfForm,
                      "wildfire-form-wide-button": isLastPartOfForm,
                      "wildfire-form-continue-button": !isLastPartOfForm,
                      "wildfire-form-navigation-button": !isLastPartOfForm,
                    })}
                    text={"Continue"}
                    rightIcon={IconNames.ARROW_RIGHT}
                    loading={isSubmitting}
                    onClick={() => {
                      if (formRef.current) {
                        props.setHasUnsavedChanges(false);
                        setShouldSubmit(true);
                        setIsSubmitting(true);
                        formRef.current.submit();
                      }
                    }}
                  />
                </RelativityTooltip>
              )}
              {isLastPartOfForm && (
                <RelativityTooltip shouldShowTooltip={false} content={""}>
                  <Button
                    className="wildfire-form-submit-button"
                    text={"Submit"}
                    rightIcon={IconNames.ARROW_RIGHT}
                    loading={isSubmitting}
                    onClick={() => {
                      if (formRef.current) {
                        props.setHasUnsavedChanges(false);
                        setShouldSubmit(true);
                        setIsSubmitting(true);
                        formRef.current.submit();
                      }
                    }}
                  />
                </RelativityTooltip>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MultifamilyRedesignationForm;
