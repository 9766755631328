import { MessageComponent } from "@syncfusion/ej2-react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";
import { useRef, useState } from "react";
import { ChangeRequestV2 } from "../IterationEngine/types/ChangeRequestV2";

export interface AutoRejectWarningBannerProps {
  longestOutstandingChangeRequest: ChangeRequestV2 | undefined;
}

export const AutoRejectWarningBanner: React.FC<AutoRejectWarningBannerProps> = (
  props
) => {
  const daysTillAutoRejection =
    props.longestOutstandingChangeRequest
      ?.wildfireDaysTillEligibleForAutoRejection ?? 0;
  const autoRejectText = `Application will automatically close in ${daysTillAutoRejection} days, if all changes are not addressed`;

  const [isVisible, setIsVisible] = useState(true);

  const autoRejectMessageRef = useRef<MessageComponent | null>(null);

  const shouldShowBanner = !!props.longestOutstandingChangeRequest && isVisible;

  return (
    <div>
      {shouldShowBanner && (
        <div className="auto-reject-warning-banner">
          <FontAwesomeIcon
            className="auto-reject-warning-banner__icon"
            icon={faExclamationTriangle}
            size={"lg"}
          />
          <MessageComponent
            ref={autoRejectMessageRef}
            className="auto-reject-warning-banner__message"
            content={autoRejectText}
            showCloseIcon={true}
            showIcon={false}
            closed={() => {
              setIsVisible(!isVisible);
            }}
            visible={isVisible}
          />
        </div>
      )}
    </div>
  );
};
