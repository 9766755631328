import { Classes, Dialog } from "@blueprintjs/core";
import { DialogFooter } from "./DialogFooter";
import { BaseDialogProps } from "./types";

export interface EvaluatorReviewSubmissionDialogProps extends BaseDialogProps {}

export const EvaluatorReviewSubmissionDialog: React.FC<
  EvaluatorReviewSubmissionDialogProps
> = ({ isOpen, closeDialog, submitDialog }) => {
  return (
    <Dialog isOpen={isOpen} hasBackdrop={false}>
      <div className={Classes.DIALOG_HEADER}>Confirmation</div>
      <div className={Classes.DIALOG_BODY}>
        Are you sure you want to send for review?
      </div>
      <DialogFooter closeDialog={closeDialog} submitDialog={submitDialog} />
    </Dialog>
  );
};
