import { FieldEvaluationFormPageProps } from "./FieldEvaluationFormPage";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../common/types/Forms/FormChecklist";
import { EvaluationFormModel } from "../../forms/EvaluationFormModel";
import { OtherFieldEvaluationFormParts } from "../../forms/OtherFieldEvaluationForm/OtherFieldEvaluationFormModel";

export interface OtherFieldEvaluationFormPartsProps {
  fieldEvalautionFormProps: FieldEvaluationFormPageProps;
  fieldEvaluationFormModel: EvaluationFormModel | undefined;
}

const useOtherFieldEvaluationFormParts = (
  props: OtherFieldEvaluationFormPartsProps
) => {
  const otherFormModel =
    props.fieldEvaluationFormModel?.wildfireApplication
      .otherFieldEvaluationForm;

  const getNextOtherUnsubmittedFormPart = () => {
    if (!otherFormModel) {
      return undefined;
    }

    if (!otherFormModel.isOtherStructuresAndOutbuildingsComplete) {
      return OtherFieldEvaluationFormParts.OtherStructuresAndOutbuildings;
    }
    // Form is complete
    return undefined;
  };

  const getOtherFieldEvaluationPartChecklistStatus = (
    formPart: OtherFieldEvaluationFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextOtherUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case OtherFieldEvaluationFormParts.OtherStructuresAndOutbuildings:
        return otherFormModel?.isOtherStructuresAndOutbuildingsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const otherFieldEvaluationChecklistParts: FormChecklistPart<OtherFieldEvaluationFormParts>[] =
    [
      {
        formPart: OtherFieldEvaluationFormParts.OtherStructuresAndOutbuildings,
        displayName: "Other Structures/Outbuildings",
        requiredFieldCount:
          otherFormModel?.otherStructuresAndOutbuildingsRequiredFieldCount,
      },
    ];

  return {
    otherFieldEvaluationChecklistParts,
    getOtherFieldEvaluationPartChecklistStatus,
  };
};

export default useOtherFieldEvaluationFormParts;
