import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { reactPlugin } from "../../AppInsights";
import { wildfireUserStore } from "../stores/WildfireUserStore";
import { WildfireEvaluationGrid } from "../components/Grid/EvaluationGrid/WildfireEvaluationGrid";
import { WildfireApplicantBoard } from "../components/Card/ApplicantBoard/WildfireApplicantBoard";

export interface BoardProps {}

const WildfireBoardPageComponent: React.FC<BoardProps> = () => {
  return (
    <div className="board-page">
      {wildfireUserStore.isWildfireApplicant && <WildfireApplicantBoard />}
      {(wildfireUserStore.isWildfireAdmin ||
        wildfireUserStore.isWildfireEvaluator ||
        wildfireUserStore.isWildfireAuditor) && <WildfireEvaluationGrid />}
    </div>
  );
};

export const WildfireBoardPage = withAITracking(
  reactPlugin,
  WildfireBoardPageComponent,
  "WildfireBoardPage",
  "tracking-container"
);
