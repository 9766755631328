import { createContext } from "react";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { EvaluationCommentV2 } from "../IterationEngine/types/EvaluationCommentV2";
import {
  UseEvaluationCommentsProps,
  useEvaluationIterationEngineComments,
} from "../../../common/customHooks/IterationEngine/Comments/useEvaluationIterationEngineComments";
import { wildfireEvaluationCommentApiClient } from "../../lib/apiClients/comment/wildfireEvaluationCommentApiClient";

interface EvaluationCommentContextProps {
  comments: EvaluationCommentV2[];
  setComments?: (comments: EvaluationCommentV2[]) => void;
  createComment: (
    wildfireEvaluationId: string,
    comment: string,
    evaluationFieldKey: string,
    evaluationFieldLabel: string,
    isPublic: boolean
  ) => Promise<void>;
  deleteComment: (
    wildfireEvaluationId: string,
    commentId: string,
    evaluationFieldKey: string
  ) => Promise<void>;

  selectedField?: BaseFieldProps<any>;
  setSelectedKeyAndGetComments?: (
    wildfireEvaluationId?: string,
    fieldKey?: string
  ) => Promise<void>;
  setSelectedFieldAndGetComments?: (
    wildfireEvaluationId: string,
    fieldProps: BaseFieldProps<any>
  ) => Promise<void>;
  selectedKey: string | undefined;
}

export const WildfireEvaluationCommentContext =
  createContext<EvaluationCommentContextProps>({
    comments: [],
    selectedKey: undefined,
    createComment: async () => {
      console.warn("createComment not implemented");
    },
    deleteComment: async () => {
      console.warn("deleteComment not implemented");
    },
  });

export const WildfireEvaluationCommentProvider: React.FC<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const useEvaluationIterationEngineCommentsProps: UseEvaluationCommentsProps =
    {
      getEvaluationComments:
        wildfireEvaluationCommentApiClient.getEvaluationComments,
      createEvaluationComments:
        wildfireEvaluationCommentApiClient.createEvaluationComments,
      createEvaluationComment:
        wildfireEvaluationCommentApiClient.createEvaluationComment,
      deleteEvaluationComment:
        wildfireEvaluationCommentApiClient.deleteEvaluationComment,
    };

  const {
    comments,
    setComments,
    createComment,
    deleteComment,
    selectedKey,
    selectedField,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
  } = useEvaluationIterationEngineComments(
    useEvaluationIterationEngineCommentsProps
  );

  return (
    <WildfireEvaluationCommentContext.Provider
      value={{
        comments,
        setComments,
        createComment,
        deleteComment,
        selectedKey,
        selectedField,
        setSelectedFieldAndGetComments,
        setSelectedKeyAndGetComments,
      }}
    >
      {children}
    </WildfireEvaluationCommentContext.Provider>
  );
};
