import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { Evaluation_HomeStandardEvaluation } from "../../types/HomeEvaluation";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";

class HomeStandardEvaluationAPIClient {
  createHomeStandardEvaluation = async () => {
    return baseApiClient.post<Evaluation_HomeStandardEvaluation>(
      `home-standard/evaluation`
    );
  };

  getHomeStandardEvaluation = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeStandardEvaluation>(
      `home-standard/evaluation/${evaluationId}`
    );
  };

  getHomeStandardEvaluationFormModel = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeStandardEvaluationFormModel>(
      `home-standard/evaluation/${evaluationId}`
    );
  };
}

export const homeStandardEvaluationAPIClient =
  new HomeStandardEvaluationAPIClient();
