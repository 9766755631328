import { FormGroup, InputGroup } from "@blueprintjs/core";
import React from "react";
import { FormstackName } from "../../../types/formstack/fieldValueTypes/FormstackName";
import { NameField as InterationEngineNameField } from "../../../types/viewModels/IterationEngineViewModel";
import { BaseFieldProps } from "../types";
import "./styles.scss";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";

export interface NameFieldProps
  extends BaseFieldProps<FormstackName>,
    Omit<InterationEngineNameField, "id" | "sortOrder" | "isHidden"> {}

export const NameField: React.FC<NameFieldProps> = ({
  fieldId,
  label,
  value,
  onSubmit,
  isEmpty,
  isSelectedField,
  hasActiveChangeRequest,
  isSelectedChangeRequest,
  isResovledChangeRequest,
  hasActiveInternalComment,
  disabled,
  isExternalView,
}) => {
  const [localValue, setLocalValue] = React.useState<FormstackName>(value);

  if (isEmpty(localValue)) {
    return null;
  }

  return (
    <FormGroup className="base-field">
      <SelectableLabel
        name={label}
        fieldId={fieldId}
        value={value}
        isSelectedField={isSelectedField}
        isResovledChangeRequest={isResovledChangeRequest}
        hasActiveChangeRequest={hasActiveChangeRequest}
        isSelectedChangeRequest={isSelectedChangeRequest}
        hasActiveInternalComment={hasActiveInternalComment}
        shouldDisableFieldLabelClick={isExternalView}
      />
      <div className="name-field">
        <div className="name-field_input">
          <InputGroup
            defaultValue={value.first}
            inputMode="text"
            disabled={disabled}
            onChange={(e) => {
              setLocalValue({ ...localValue, first: e.target.value });
            }}
            onBlur={() => onSubmit(JSON.stringify(localValue))}
          />
          <span className="name-field_input_helper">First Name</span>
        </div>
        <div className="name-field_input">
          <InputGroup
            defaultValue={value.last}
            inputMode="text"
            disabled={disabled}
            onChange={(e) => {
              setLocalValue({ ...localValue, last: e.target.value });
            }}
            onBlur={() => onSubmit(JSON.stringify(localValue))}
          />
          <span className="name-field_input_helper">Last Name</span>
        </div>
      </div>
    </FormGroup>
  );
};
