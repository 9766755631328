import { dialogStore, Footer } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import "../styles.scss";
import WildfireSecondaryButton from "../../Button/DialogFooterButtons/SecondaryButton/SecondaryButton";
import WildfireSubmitButton from "../../Button/DialogFooterButtons/SubmitButton/SubmitButton";

const dialogIdBase = "wildfire-application-incomplete-dialog";

const getDialogId = (wildfireApplicationId: string): string =>
  `${dialogIdBase}_${wildfireApplicationId}`;

const buildApplicationIncompleteDialogProps = (
  wildfireApplicationId: string,
  onLeaveAnyway: () => void
): DialogProps => {
  return {
    id: getDialogId(wildfireApplicationId),
    className: dialogIdBase,
    header: "Application Incomplete Warning",
    allowDragging: false,
    content: () => (
      <>
        <div className={`wildfire-dialog-body-text`}>
          <strong>Warning:</strong> Your application cannot be saved without a
          valid Street Address.
          <br />
          Please input a Street Address to save your application and be able to
          continue it later.
        </div>
        <Footer>
          <WildfireSecondaryButton
            buttonText="Leave Anyway"
            onClick={() => {
              closeApplicationIncompleteDialog(wildfireApplicationId);
              onLeaveAnyway();
            }}
          />
          <WildfireSubmitButton
            buttonText="Return to Application"
            onClick={() =>
              closeApplicationIncompleteDialog(wildfireApplicationId)
            }
          />
        </Footer>
      </>
    ),
  };
};

export const openApplicationIncompleteDialog = (
  wildfireApplicationId: string,
  onLeaveAnyway: () => void
) =>
  dialogStore.openDialog(
    buildApplicationIncompleteDialogProps(wildfireApplicationId, onLeaveAnyway)
  );

export const closeApplicationIncompleteDialog = (
  wildfireApplicationId: string
) => dialogStore.closeDialog(getDialogId(wildfireApplicationId));
