export const getScrollContainer = (
  element: HTMLElement
): HTMLElement | null => {
  if (!element) {
    return null;
  }
  if (
    // Determine if element has a scrollbar
    element.scrollHeight > element.clientHeight &&
    window.getComputedStyle(element).overflowY !== "hidden"
  ) {
    return element;
  } else {
    return getScrollContainer(element.parentElement as HTMLElement);
  }
};
