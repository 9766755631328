import { FormGroup } from "@blueprintjs/core";
import React, { useEffect } from "react";
import {
  ChangeEventArgs,
  NumericTextBoxComponent,
  FormValidatorModel,
} from "@syncfusion/ej2-react-inputs";
import { FieldType } from "../types";
import { MinimalSelectableLabel } from "../SelectableLabel/MinimalSelectableLabel";

export interface NumericFieldProps {
  decimals?: number;
  min?: number;
  max?: number;
  format?: string;
  currency?: string;
  placeholder: string;
}
export interface NumericTextFieldProps<T> {
  label?: string;
  errorMessages?: string[];
  description?: string;
  hidden?: boolean;
  disabled?: boolean;
  formId?: string;
  form?: unknown;
  width?: string;
  maxLength?: number;
  tabId?: string;
  numericFieldProps?: NumericFieldProps;
  validationModel?: FormValidatorModel;
  fieldName?: string;
  type: FieldType;
  value: number;
  onChange: (value: T) => void;
}
export const NumericField: React.FC<NumericTextFieldProps<number>> = ({
  label,
  value,
  disabled,
  hidden,
  type,
  numericFieldProps,
  errorMessages,
  fieldName,
  onChange,
}) => {
  if (hidden) {
    return null;
  }
  const [localValue, setLocalValue] = React.useState<number>(value);

  useEffect(() => {
    onChange(localValue);
  }, [localValue]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <FormGroup
      className={`${
        !value && value !== 0 ? "has-required-background" : "base-field"
      }`}
    >
      {label && <MinimalSelectableLabel label="" />}
      <NumericTextBoxComponent
        type={type}
        name={fieldName}
        value={localValue}
        enabled={!disabled}
        min={numericFieldProps?.min}
        max={numericFieldProps?.max}
        showSpinButton={false}
        currency="numericFieldProps?.currency"
        format={numericFieldProps?.format}
        validateDecimalOnType={true}
        change={(e: ChangeEventArgs) => {
          if (typeof e.value !== "undefined") {
            setLocalValue(e.value);
          }
        }}
        decimals={numericFieldProps?.decimals}
        placeholder={numericFieldProps?.placeholder}
      />
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage: string, idx: number) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
