import { useEffect, useState } from "react";
import { ExistingComment } from "../../../../common/apiClients/comments/types";
import { evaluationApiClient } from "../../../../foritfied/lib/apiClients/evaluation/evaluationApiClient";
import { commentApiClient } from "../../../../common/apiClients/comments/commentApiClient";
import { GridCommentsProps } from "./GridCommentsComponent";

export const useGridComments = (props: GridCommentsProps) => {
  const { evaluationId, refreshGrid } = props;
  const [localEvaluationComments, setLocalEvaluationComments] = useState<
    ExistingComment[]
  >([]);

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    getComments();
  }, [evaluationId]);

  const getComments = async () => {
    evaluationApiClient.getEvaluationComments(evaluationId).then((comments) => {
      setLocalEvaluationComments(comments);
      setIsInitialized(true);
    });
  };

  const createComment = async (evaluationId: string, comment: string) => {
    await commentApiClient
      .createComment({
        evaluationId: evaluationId,
        message: comment,
      })
      .then(async () => {
        await getComments().then(async () => {
          await refreshGrid();
        });
      })
      .catch((error) => console.error(error));
  };

  const markCommentAsRead = async (commentId: string) => {
    await commentApiClient
      .markCommentAsRead(commentId)
      .then(async () => {
        await getComments().then(async () => {
          await refreshGrid();
        });
      })
      .catch((error) => console.error(error));
  };

  const markCommentAsUnread = async (commentId: string) => {
    await commentApiClient
      .markCommentAsUnread(commentId)
      .then(async () => {
        await getComments().then(async () => {
          await refreshGrid();
        });
      })
      .catch((error) => console.error(error));
  };

  const updateComment = async (
    commentId: string,
    comment: ExistingComment,
    updatedMessage: string
  ) => {
    await commentApiClient
      .updateComment(commentId, {
        ...comment,
        message: updatedMessage,
      })
      .then(async () => {
        await getComments().then(async () => {
          await refreshGrid();
        });
      })
      .catch((error) => console.error(error));
  };

  const deleteComment = async (commentId: string) => {
    await commentApiClient
      .deleteComment(commentId)
      .then(async () => {
        await getComments().then(async () => {
          await refreshGrid();
        });
      })
      .catch((error) => console.error(error));
  };

  return {
    localEvaluationComments,
    createComment,
    markCommentAsRead,
    markCommentAsUnread,
    updateComment,
    deleteComment,
    isInitialized,
  };
};

export default useGridComments;
