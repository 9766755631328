import { evaluationStore } from "../../stores/EvaluationStore";

interface FCFMFEvaluationComfirmationInfoProps {}

const FCFMFEvaluationComfirmationInfo: React.FC<
  FCFMFEvaluationComfirmationInfoProps
> = ({}) => {
  const renderGrossRoofArea = () => {
    if (evaluationStore.isComplexCommercialEvaluation) {
      return (
        <p>
          Gross Roof Area:{" "}
          <strong>
            Individually verify on all of this evaluation&apos;s buildings
          </strong>
        </p>
      );
    } else {
      return (
        <p>
          Gross Roof Area:{" "}
          <strong>{evaluationStore.evaluationGrossRoofAreaString} sqft</strong>
        </p>
      );
    }
  };

  const renderTotalFloorArea = () => {
    if (evaluationStore.isComplexCommercialEvaluation) {
      return (
        <p>
          Total Floor Area:{" "}
          <strong>
            Individually verify on all of this evaluation&apos;s buildings
          </strong>
        </p>
      );
    } else {
      return (
        <p>
          Total Floor Area:{" "}
          <strong>{evaluationStore.evaluationTotalFloorAreaString} sqft</strong>
        </p>
      );
    }
  };

  return (
    <div>
      <h3>Please verify the following fields before continuing: </h3>
      <p>
        Designation Level:{" "}
        <strong>{evaluationStore.evaluationDesignationLevelString}</strong>
      </p>
      <p>
        Hazard Type:{" "}
        <strong>{evaluationStore.evaluationHazardTypeString}</strong>
      </p>
      <p>
        Hail Supplement:{" "}
        <strong>{evaluationStore.evaluationHasHailSupplementString}</strong>
      </p>
      <p>
        Building Address:{" "}
        <strong>
          Individually verify on all of this evaluation&apos;s buildings
        </strong>
      </p>
      <p>
        New Construction:{" "}
        <strong>{evaluationStore.evaluationIsNewConstruction}</strong>
      </p>
      {evaluationStore.evaluationUsesRoofAreaForFeeCalculations &&
        renderGrossRoofArea()}
      {evaluationStore.evaluationUsesFloorAreaForFeeCalculations &&
        renderTotalFloorArea()}
    </div>
  );
};

export default FCFMFEvaluationComfirmationInfo;
