import {
  UserReadDTO,
  UserUpdateDTO,
} from "../../../foritfied/lib/apiClients/user/types";
import wildfireBaseApiClient from "../wildfireBaseApiClient";

class WildfireUserApiClient {
  getBoxToken = async () => {
    return wildfireBaseApiClient.get<string>(`user/box-token`);
  };

  signIn = async () => {
    return wildfireBaseApiClient.post<UserReadDTO>(`user/signin`);
  };

  getUserById = async (id: string) => {
    return wildfireBaseApiClient.get<UserReadDTO | undefined>(`user/${id}`);
  };

  updateUserById = async (id: string, data: UserUpdateDTO) => {
    return wildfireBaseApiClient.put<UserReadDTO | undefined>(`user/${id}`, {
      ...data,
      company: "",
    });
  };
}

export const wildfireUserApiClient = new WildfireUserApiClient();
