import { Button, H2 } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Observer } from "mobx-react";
import React from "react";
import { PageSidebar } from "../components/PageSidebar/PageSidebar";
import reports, { FortifiedReportTypes } from "./reports/reports";
import report_background from "../assets/report_background.png";
import "./styles.scss";
import { fortifiedUserStore } from "../stores/FortifiedUserStore";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../AppInsights";
import { Header } from "../components/Header/Header";
import { useNavigate } from "react-router-dom";
import { permissionStore } from "../stores/PermissionStore";
import { useProductTypeHandler } from "../providers/ProductTypeHandlerProvider";

const ReportsPageComponent: React.FC = () => {
  const navigate = useNavigate();
  const { routePrefix } = useProductTypeHandler();

  return (
    <div className="fortified-page board-page reports-page">
      <Observer>{() => <Header />}</Observer>
      <div className="content">
        <PageSidebar activePage="reports" />
        <div className="page-main-content">
          <>
            {permissionStore.canViewReportsPage && (
              <div className="reports-page__reports">
                {Object.keys(reports)
                  .filter((reportType) => {
                    const report = reports[reportType as FortifiedReportTypes];

                    if (
                      report.viewerRoles.some((viewerRole) =>
                        fortifiedUserStore.hasRole(viewerRole)
                      )
                    ) {
                      return true;
                    }
                    return false;
                  })
                  .map((reportType) => {
                    const report = reports[reportType as FortifiedReportTypes];

                    return (
                      <div
                        key={reportType}
                        className="reports-page__reports__report-item"
                      >
                        <img src={report_background} alt="Report Background" />
                        <div className="reports-page__reports__report-item__overlay-container">
                          <H2>{report.title}</H2>
                          <Button
                            minimal
                            intent="primary"
                            outlined
                            text="View Report"
                            icon={IconNames.EYE_OPEN}
                            onClick={() => {
                              navigate(`${routePrefix}/reports/${report.slug}`);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export const ReportsPage = withAITracking(
  reactPlugin,
  ReportsPageComponent,
  "ReportsPage",
  "tracking-container"
);
