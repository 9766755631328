import { PrimaryCard, SecondaryCard } from "@ucl/library";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { Navigate } from "react-router-dom";
import FormChecklistProgress from "../../../../../../Common/components/Forms/FormChecklistProgress/FormChecklistProgress";
import { FieldSchemaFactoryProvider } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { fortifiedHomeSCSHRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { HomeSCSHEvaluationForm } from "../Form/HomeSCSHEvaluationForm";
import { SCSHEvaluationForm_FormParts } from "../../../../types/HomeSCSHEvaluation_EvaluationFormModel";
import useHomeSCSHEvaluationFormContainer from "./useHomeSCSHEvaluationFormContainer";

export interface HomeSCSHEvaluationFormContainerProps {
  evaluationId: string;
  formPart?: SCSHEvaluationForm_FormParts;
}

export const HomeSCSHEvaluationFormContainer = (
  props: HomeSCSHEvaluationFormContainerProps
) => {
  const {
    checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    homeSchemaFactory,
  } = useHomeSCSHEvaluationFormContainer(props);

  return (
    <>
      {!formPart ||
      !Object.values(SCSHEvaluationForm_FormParts).includes(formPart) ? (
        // TODO: Show error page if the application form part is not valid
        <Navigate
          to={`${fortifiedHomeSCSHRoutePrefix}/${
            props.evaluationId
          }/evaluation-form/${getBestFormPart().valueOf()}`}
        />
      ) : !homeSchemaFactory ? (
        <Loading />
      ) : (
        <div className="wildfire-form-page">
          <div className="wildfire-form-progress">
            <div className="wildfire-form-progress__steps">
              <FormChecklistProgress
                checklistParts={checklistParts}
                evaluationId={props.evaluationId}
                formURL={`${fortifiedHomeSCSHRoutePrefix}/${props.evaluationId}/evaluation-form`}
                title={"SCSH Information"}
                activeFormPart={formPart}
                getChecklistStatus={getApplicationPartChecklistStatus}
              />
            </div>
          </div>
          <section className="wildfire-form-content">
            <div className="wildfire-form-content__header"></div>
            <PrimaryCard
              className="wildfire-form-primary-card"
              headerContent={
                <div className="wildfire-form-primary-card__header">
                  Home SCSH Information:{" "}
                  {
                    checklistParts.find((value) => value.formPart === formPart)
                      ?.displayName
                  }
                </div>
              }
            >
              <SecondaryCard
                className="wildfire-form-secondary-card"
                hideBody={false}
                isCollapsible={false}
              >
                <FieldSchemaFactoryProvider schemaFactory={homeSchemaFactory}>
                  <HomeSCSHEvaluationForm
                    key={formPart}
                    evaluationId={props.evaluationId}
                    isIterationEnginePage={false}
                    formPart={formPart}
                    disableForm={false}
                    setFormModel={setEvaluationFormModel}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                  />
                </FieldSchemaFactoryProvider>
              </SecondaryCard>
            </PrimaryCard>
          </section>
        </div>
      )}
    </>
  );
};
export default HomeSCSHEvaluationFormContainer;
