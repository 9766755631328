import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { Evaluation_MultifamilyRedesignation } from "../../types/MultifamilyRedesignation";
import { Evaluation_MultifamilyRedesignationFormModel } from "../../types/MultifamilyRedesignationFormModel";

class MultifamilyRedesignationAPIClient {
  createMultifamilyRedesignation = async (parentEvaluationId: string) => {
    return baseApiClient.post<Evaluation_MultifamilyRedesignation>(
      `multifamily/redesignation/${parentEvaluationId}`
    );
  };

  getMultifamilyRedesignation = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_MultifamilyRedesignation>(
      `multifamily/redesignation/${evaluationId}`
    );
  };

  getMultifamilyRedesignationFormModel = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_MultifamilyRedesignationFormModel>(
      `multifamily/redesignation/${evaluationId}`
    );
  };
}

export const multifamilyRedesignationAPIClient =
  new MultifamilyRedesignationAPIClient();
