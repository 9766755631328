import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useContext } from "react";
import { reactPlugin } from "../../AppInsights";
import { WildfireUserGrid } from "../components/Grid/UserGrid/WildfireUserGrid";
import { PermissionsContext, PermissionsContextProps } from "@ucl/library";

export const WildfireAdminPageComponent: React.FC = () => {
  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  return (
    <div className="admin-page">
      {hasPermission("CanViewAdminPanel") && <WildfireUserGrid />}
    </div>
  );
};

export const WildfireAdminPage = withAITracking(
  reactPlugin,
  WildfireAdminPageComponent,
  "WildfireAdminPage",
  "tracking-container"
);
