import { autorun } from "mobx";
import React from "react";
import FortifiedGridColumn from "../../foritfied/types/fortifiedGrid/FortifiedGridColumn";
import { fortifiedGridStore } from "../../foritfied/stores/FortifiedGridStore";
import { fortifiedGridViewModuleStore } from "../../foritfied/stores/FortifiedGridViewModuleStore";
import { AppToaster } from "../../foritfied/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { UserRoleType } from "../Components/User/userTypes";
import { BoardGridView } from "../../foritfied/lib/gridColumnConfigurations/boardViewsConfiguration";
import { useAccount } from "@azure/msal-react";

export const useFortifiedGridViewModule = (
  gridId: string,
  gridTitle: string,
  getColumnsConfigurationByRole: (role?: UserRoleType) => FortifiedGridColumn[],
  getDefaultViewByRole: (role?: UserRoleType) => BoardGridView
) => {
  const [queryKey, setQueryKey] = React.useState<string>();

  const account = useAccount();

  // Observe and Save Filter Settings
  React.useEffect(() => {
    autorun(async () => {
      if (!!fortifiedGridStore.filterSettings) {
        const requestKey = JSON.stringify(
          fortifiedGridViewModuleStore.getViewFilterSettings(
            fortifiedGridStore.filterSettings
          )
        );
        if (requestKey !== queryKey) {
          setQueryKey(requestKey);
        }
      }
    });
  }, []);

  React.useEffect(() => {
    updateViewFilterSettings();
  }, [queryKey]);

  const updateViewFilterSettings = async () => {
    if (!queryKey) {
      return;
    }
    if (fortifiedGridStore.filterSettings) {
      await fortifiedGridViewModuleStore.updateViewFilterSettings(
        gridId,
        fortifiedGridStore.filterSettings
      );
    }
  };

  const loadUserViews = async () => {
    await fortifiedGridViewModuleStore
      .loadViews(
        gridTitle,
        gridId,
        getColumnsConfigurationByRole,
        getDefaultViewByRole
      )
      .catch(() => {
        AppToaster.show({
          message: "An error ocurred loading the data.",
          intent: Intent.DANGER,
        });
      });
  };

  // Load View
  React.useEffect(() => {
    if (account) {
      loadUserViews();
    }
  }, []);

  // Reset
  React.useEffect(() => {
    return () => {
      fortifiedGridViewModuleStore.reset();
    };
  }, []);
};
