import { CSSProperties } from "react";

export const iframePrintMediaStyles = "@page { size: landscape; }";

export const iframeStyles: CSSProperties = {
  border: "none",
};

export const printableCertificateStyles: CSSProperties = {
  position: "relative",
  display: "flex",
  width: "1055px",
  height: "815px",
};

export const printableCertificateCertificationStyles: CSSProperties = {
  width: "1055px",
  height: "815px",
};

export const printableCertificateOverlayStyles: CSSProperties = {
  height: "100%",
  width: "100%",
  position: "absolute",
  fontSize: "24px",
  color: "black",
  fontWeight: "bold",
};

export const commercialAddressStyles: CSSProperties = {
  position: "relative",
  top: "45%",
  left: "50%",
  width: "fit-content",
  textAlign: "center",
};

export const multifamilyAddressStyles: CSSProperties = {
  position: "relative",
  top: "47.5%",
  left: "57.5%",
  width: "fit-content",
  textAlign: "center",
};

export const commercialApprovalDateStyles: CSSProperties = {
  position: "relative",
  top: "62.5%",
  left: "62.5%",
  width: "fit-content",
};

export const multifamilyApprovalDateStyles: CSSProperties = {
  position: "relative",
  top: "65%",
  left: "66.5%",
  width: "fit-content",
};

export const commercialEvaluationIdStyles: CSSProperties = {
  position: "relative",
  top: "85.9%",
  left: "68%",
  width: "fit-content",
  fontSize: "9.5px",
};

export const multifamilyEvaluationIdStyles: CSSProperties = {
  position: "relative",
  top: "85.4%",
  left: "68%",
  width: "fit-content",
  fontSize: "9.5px",
};

export const commercialExpirationDateStyles: CSSProperties = {
  position: "relative",
  top: "84.5%",
  left: "86.5%",
  fontSize: "9.5px",
  width: "fit-content",
};

export const multifamilyExpirationDateStyles: CSSProperties = {
  position: "relative",
  top: "84%",
  left: "86.5%",
  fontSize: "9.5px",
  width: "fit-content",
};
