import { useEffect, useState } from "react";
import { Evaluation_CommercialEvaluation } from "../types/CommercialEvaluation";
import { commercialEvaluationAPIClient } from "../lib/apiClients/commercialEvaluationAPIClient";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../common/Components/Error/ErrorStore";

export const useCommercialEvaluation = (evaluationId: string) => {
  const [commercialEvaluation, setCommercialEvaluation] =
    useState<Evaluation_CommercialEvaluation | null>();
  const [isApplicationLoading, setIsApplicationLoading] = useState(false);

  useEffect(() => {
    getCommercialEvaluation(evaluationId);
  }, [evaluationId]);

  const getCommercialEvaluation = async (evaluationId: string) => {
    await commercialEvaluationAPIClient
      .getCommercialEvaluation(evaluationId)
      .then((response) => {
        setCommercialEvaluation(response);
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      });
  };

  const refreshCommercialEvaluation = async () => {
    setIsApplicationLoading(true);
    if (commercialEvaluation?.id) {
      await commercialEvaluationAPIClient
        .getCommercialEvaluation(commercialEvaluation?.id)
        .then((response) => {
          setCommercialEvaluation(response);
          //setWildfireEvaluationHeader(response);
        })
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        })
        .finally(() => {
          setIsApplicationLoading(false);
        });
    }
  };

  return {
    commercialEvaluation,
    refreshCommercialEvaluation,
    isApplicationLoading,
    setCommercialEvaluation,
  };
};
