import { FormGroup, RadioGroup } from "@blueprintjs/core";
import React from "react";
import { FormstackRadio } from "../../../types/formstack/fieldValueTypes/FormstackRadio";
import { RadioField as InterationEngineRadioField } from "../../../types/viewModels/IterationEngineViewModel";
import { BaseFieldProps } from "../types";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";

export interface RadioFieldProps
  extends BaseFieldProps<FormstackRadio>,
    Omit<InterationEngineRadioField, "id" | "sortOrder" | "isHidden"> {}

export const RadioField: React.FC<RadioFieldProps> = ({
  fieldId,
  label,
  value,
  optionValues,
  onSubmit,
  isEmpty,
  disabled,
  isSelectedField,
  hasActiveChangeRequest,
  isSelectedChangeRequest,
  isResovledChangeRequest,
  hasActiveInternalComment,
  isExternalView,
}) => {
  const [localValue, setLocalValue] = React.useState<FormstackRadio>(value);
  if (isEmpty(localValue)) {
    return null;
  }

  return (
    <FormGroup className="radio-field">
      <SelectableLabel
        fieldId={fieldId}
        name={label}
        value={localValue}
        isSelectedField={isSelectedField}
        hasActiveChangeRequest={hasActiveChangeRequest}
        isSelectedChangeRequest={isSelectedChangeRequest}
        isResovledChangeRequest={isResovledChangeRequest}
        hasActiveInternalComment={hasActiveInternalComment}
        shouldDisableFieldLabelClick={isExternalView}
      />
      <RadioGroup
        disabled={disabled}
        onChange={(e) => {
          setLocalValue(e.currentTarget.value);
          onSubmit(e.currentTarget.value);
        }}
        selectedValue={localValue}
        options={optionValues
          .filter((option) => option.value)
          .map((option, idx) => ({
            ...option,
            key: idx,
          }))}
      />
    </FormGroup>
  );
};
