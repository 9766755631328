import React, { useContext } from "react";
import { BackToLandingPageButton } from "../../Button/BackToLandingPageButton/BackToLandingPageButton";
import WildfireSendForwardActionButton from "../../Button/WildfireSendForwardActionButton/WildfireSendForwardActionButton";
import { EvaluatorAssignmentButton } from "../../Button/EvaluatorAssignmentButton/EvaluatorAssignmentButton";
import { WildfireEvaluationSteps } from "../../../../foritfied/types/evaluation/Evaluation";
import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useNavigate } from "react-router";
import { WildfireRejectionFormModel } from "../../Dialogs/Rejection/WildfireRejectionForm";
import { EvaluationStatus } from "../../../types/WildfireApplication/WildfireApplication";
import { WildfireRejectActionButton } from "../../Button/WildfireRejectActionButton/WildfireRejectActionButton";
import DownloadCertificateActionButton from "../../Button/DownloadCertificateButton/DownloadCertificateButton";
import { WildfireApproveActionButton } from "../../Button/WildfireApproveActionButton/WildfireApproveActionButton";
import {
  PermissionsContext,
  PermissionsContextProps,
  RelativityTooltip,
} from "@ucl/library";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { wildfireUserStore } from "../../../stores/WildfireUserStore";
import ClaimApplicationActionButton from "../../Button/ClaimApplication/ClaimApplicationActionButton";
import { openLegacyWildfireEvaluationFilesDialog } from "../../Dialogs/LegacyWildfireEvaluationFiles/LegacyWildfireEvaluationFilesDialog";
import { ApplicationCommentsButton } from "../../Button/ApplicationCommentsButton.tsx/ApplicationCommentsButton";
import WildfireCloseEvaluationButton from "../../Button/WildfireCloseEvaluationButton/WildfireCloseEvaluationButton";
import { AutoRejectWarningBanner } from "../../Banners/AutoRejectWarningBanner";
import { ChangeRequestV2 } from "../types/ChangeRequestV2";
import AdminPanelButton from "../../Button/AdminPanelButton.tsx/AdminPanelButton";

interface IterationEngineHeaderProps {
  evaluationId: string;
  auditorId: string;
  wildfireApplicationId: string;
  unreadCommentsCount?: string;
  externalUnreadCommentCount?: number;
  shouldUseExternalUnreadCommentCount?: boolean;
  wildfireApplicationOwnerId: string;
  step: WildfireEvaluationSteps;
  status: EvaluationStatus;
  resolvedAllChangeRequest: boolean;
  hasOneNewChangeRequest: boolean;
  hasNoUnaddressedChangeRequests: boolean;
  refreshWildfireApplication: () => Promise<void>;
  sendForward: () => Promise<void>;
  isSendForwardLoading: boolean;
  approve: () => Promise<void>;
  isApproveLoading: boolean;
  reject: (form: WildfireRejectionFormModel) => Promise<void>;
  isRejectLoading: boolean;
  requestChanges: () => Promise<void>;
  isRequestChangesLoading: boolean;
  downloadCertificate: () => Promise<unknown>;
  regenerateCertificate: () => Promise<unknown>;
  auditorNotes: string;
  isWithdrawn: boolean;
  shouldShowAutoRejectBanner: boolean;
  longestOutstandingChangeRequest: ChangeRequestV2 | undefined;
  homeOwnerId: string;
  moveableStepOptionsByAdmin: {
    label: string;
    value: WildfireEvaluationSteps;
  }[];
  externalFolderId?: string;
  legacyEvaluationSource?: string;
}

const IterationEngineHeader: React.FC<IterationEngineHeaderProps> = (props) => {
  const navigate = useNavigate();

  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  const inIBHSEligibilityReview =
    props.step ===
    WildfireEvaluationSteps.WildfireApplicationIBHSEligibilityReview;

  const inIBHSFinalReview =
    props.step === WildfireEvaluationSteps.WildfireApplicationIBHSReview;

  const isApproved = props.status === "Designated";
  const isRejected = props.status === "Not Designated";
  const hasFinalDetermination = isApproved || isRejected;

  const isEvaluationClaimedByCurrentUser =
    wildfireUserStore.user?.id === props.auditorId;

  const canViewAdminPanelButton = hasPermission("CanViewAdminPanelButton");

  const canViewEvaluatorAssignmentButton =
    hasPermission("CanViewAssignEvaluatorButton") &&
    props.step ===
      WildfireEvaluationSteps.WildfireApplicationEvaluatorAssignment;

  const canViewApproveButton =
    hasPermission("CanViewApproveButtonInIBHSReviewStep") && inIBHSFinalReview;

  const canViewDownloadCertificateButton =
    hasPermission("CanViewDownloadCertificateButton") && isApproved;

  const canViewRejectButton =
    (inIBHSEligibilityReview &&
      hasPermission("CanViewRejectButtonInIBHSEligibilityReviewStep")) ||
    (inIBHSFinalReview && hasPermission("CanViewRejectButtonInIBHSReviewStep"));

  const canViewSendForwardButton =
    hasPermission("CanViewSendForwardButtonInIBHSEligibilityReviewStep") &&
    inIBHSEligibilityReview;

  const canViewSendForReviewButton =
    hasPermission("CanSendForReview") &&
    (inIBHSEligibilityReview || inIBHSFinalReview) &&
    !hasFinalDetermination &&
    props.hasNoUnaddressedChangeRequests;

  const canRequestChanges =
    ((hasPermission("CanViewRequestChangesButtonInIBHSEligibilityReviewStep") &&
      inIBHSEligibilityReview) ||
      (hasPermission("CanViewRequestChangesButtonInIBHSReviewStep") &&
        inIBHSFinalReview)) &&
    !hasFinalDetermination &&
    props.hasOneNewChangeRequest;

  const canViewLegacyWildfireEvaluationFiles =
    hasPermission("CanViewLegacyWildfireEvaluationFiles") &&
    !!props.legacyEvaluationSource;

  const canCloseEvaluation =
    !hasFinalDetermination &&
    !inIBHSEligibilityReview &&
    (hasPermission("CanViewWithdrawButton") ||
      hasPermission("CanViewCancelButton"));

  const shouldShowAutoRejectBanner = props.shouldShowAutoRejectBanner;

  return (
    <div className="wildfire-iteration-engine__header">
      <div className="left-panel">
        <BackToLandingPageButton />
        {canViewSendForReviewButton && (
          <Button
            minimal
            className="wildfire-send-for-review-button"
            text="Send For Review"
            intent={Intent.PRIMARY}
            rightIcon={IconNames.ARROW_RIGHT}
            onClick={async () => {
              AppToaster.show({
                message: (
                  <div>
                    <h3>Success</h3>Sent For Review.
                  </div>
                ),
                intent: Intent.PRIMARY,
              });

              navigate(`${wildfireRoutePrefix}/board`);
            }}
          ></Button>
        )}
        {canViewApproveButton && (
          <WildfireApproveActionButton
            wildfireApplicationId={props.wildfireApplicationId}
            hasFinalDetermination={hasFinalDetermination}
            status={props.status}
            approve={props.approve}
            isApproveLoading={props.isApproveLoading}
            isEvaluationClaimedByCurrentUser={isEvaluationClaimedByCurrentUser}
            isWithdrawn={props.isWithdrawn}
          />
        )}
        {canViewRejectButton && (
          <WildfireRejectActionButton
            wildfireApplicationId={props.wildfireApplicationId}
            hasFinalDetermination={hasFinalDetermination}
            step={props.step}
            status={props.status}
            reject={props.reject}
            isRejectLoading={props.isRejectLoading}
            isEvaluationClaimedByCurrentUser={isEvaluationClaimedByCurrentUser}
            isWithdrawn={props.isWithdrawn}
            hasWithdrawPermission={hasPermission("CanViewWithdrawButton")}
            refreshWildfireEvaluation={props.refreshWildfireApplication}
            evaluationId={props.evaluationId}
            auditorNotes={props.auditorNotes}
            isRejected={isRejected}
          />
        )}
        {canRequestChanges && (
          <RelativityTooltip
            shouldShowTooltip={!isEvaluationClaimedByCurrentUser}
            content={"Claim Evaluation to Request Changes"}
          >
            <Button
              minimal
              className="wildfire-request-changes-button"
              text="Request Changes"
              intent={Intent.WARNING}
              disabled={!isEvaluationClaimedByCurrentUser || props.isWithdrawn}
              loading={props.isRequestChangesLoading}
              icon={IconNames.ArrowRight}
              onClick={async () => {
                await props.requestChanges().then(() => {
                  AppToaster.show({
                    message: (
                      <div>
                        <h3>Success</h3>Changes Requested.
                      </div>
                    ),
                    intent: Intent.WARNING,
                  });
                  navigate(`${wildfireRoutePrefix}/board`);
                });
              }}
            ></Button>
          </RelativityTooltip>
        )}
        {canViewSendForwardButton && (
          <WildfireSendForwardActionButton
            sendForward={props.sendForward}
            isSendForwardLoading={props.isSendForwardLoading}
            resolvedAllChangeRequests={props.resolvedAllChangeRequest}
            isEvaluationClaimedByCurrentUser={isEvaluationClaimedByCurrentUser}
            isWithdrawn={props.isWithdrawn}
          />
        )}
        {canViewEvaluatorAssignmentButton && (
          <EvaluatorAssignmentButton
            evaluationId={props.evaluationId}
            onSubmit={() => navigate(`${wildfireRoutePrefix}/board`)}
            isEvaluationClaimedByCurrentUser={isEvaluationClaimedByCurrentUser}
          />
        )}
        {canCloseEvaluation && (
          <WildfireCloseEvaluationButton
            evaluationId={props.evaluationId}
            isClosed={props.isWithdrawn}
            refreshWildfireEvaluation={props.refreshWildfireApplication}
            hasWithdrawPermission={hasPermission("CanViewWithdrawButton")}
            auditorNotes={props.auditorNotes}
          />
        )}
        {(wildfireUserStore.isWildfireAdmin ||
          wildfireUserStore.isWildfireAuditor) && (
          <ClaimApplicationActionButton
            evaluationId={props.evaluationId}
            isEvaluationClaimedByCurrentUser={isEvaluationClaimedByCurrentUser}
            refreshWildfireEvaluation={props.refreshWildfireApplication}
          />
        )}
        {canViewDownloadCertificateButton && (
          <DownloadCertificateActionButton
            downloadCertificate={props.downloadCertificate}
            regenerateCertificate={props.regenerateCertificate}
            canRegenerateCertificate={
              wildfireUserStore.isWildfireAdmin ||
              wildfireUserStore.isWildfireAuditor
            }
            shouldDisableDownload={false}
            shouldDisplayCertificateGenerationButton={true}
          />
        )}
        {canViewLegacyWildfireEvaluationFiles && (
          <Button
            text="Legacy Files"
            icon={IconNames.FolderClose}
            minimal
            large
            onClick={() =>
              openLegacyWildfireEvaluationFilesDialog(
                props.wildfireApplicationId,
                props.externalFolderId
              )
            }
          />
        )}
        <ApplicationCommentsButton
          evaluationId={props.evaluationId}
          wildfirePreparedId={props.wildfireApplicationId}
          fetchWildfireApplications={props.refreshWildfireApplication}
          externalUnreadCommentCount={props.externalUnreadCommentCount}
          unreadCommentsCount={props.unreadCommentsCount}
          shouldUseExternalUnreadCommentCount={
            props.shouldUseExternalUnreadCommentCount
          }
        />
        {canViewAdminPanelButton && (
          <AdminPanelButton
            evaluationId={props.evaluationId}
            homeOwnerId={props.homeOwnerId}
            auditorId={props.auditorId}
            status={props.status}
            moveableStepOptionsByAdmin={props.moveableStepOptionsByAdmin}
            refreshWildfireApplication={props.refreshWildfireApplication}
          />
        )}
      </div>
      <div className="right-panel">
        {shouldShowAutoRejectBanner && (
          <AutoRejectWarningBanner
            longestOutstandingChangeRequest={
              props.longestOutstandingChangeRequest
            }
          />
        )}
      </div>
    </div>
  );
};

export default IterationEngineHeader;
