import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { FC, useState } from "react";
import { evaluationApiClient } from "../../../lib/apiClients/evaluation/evaluationApiClient";
import { useNavigate } from "react-router";
import { evaluationStore } from "../../../stores/EvaluationStore";
import { TraineeAuditStatuses } from "../../../types/evaluation/Evaluation";
import { useProductTypeHandler } from "../../../providers/ProductTypeHandlerProvider";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { RelativityTooltip } from "@ucl/library";

interface TraineeReturnChangesRequiredActionButtonProps {
  evaluationId: string;
  disabled: boolean;
}

const TraineeReturnChangesRequiredActionButton: FC<
  TraineeReturnChangesRequiredActionButtonProps
> = ({ evaluationId, disabled }) => {
  const navigate = useNavigate();
  const { routePrefix } = useProductTypeHandler();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const returnToTraineeWithChangesRequired = async () => {
    setIsLoading(true);
    try {
      await evaluationApiClient.returnTraineeAuditWithChangesRequired(
        evaluationId
      );
      AppToaster.show({
        intent: Intent.SUCCESS,
        message:
          "Sucessfully returned Evaluation to Trainee with Changes Required",
        timeout: 5000,
      });
      // Navigate back to board
      navigate(`${routePrefix}`);
    } catch (err) {
      console.error(err);
      AppToaster.show({
        intent: Intent.DANGER,
        message:
          "Unexpected error occured while returning Evaluation to Trainee with Changes Required",
        timeout: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getTooltipText = () => {
    switch (evaluationStore.evaluation?.traineeAuditStatus) {
      case TraineeAuditStatuses.IBHSReview:
        return "This Evaluation is under initial review by an Auditor Trainee.";
      case TraineeAuditStatuses.ChangesRequired:
        return "This Evaluation has already been returned to the Trainee with Changes Required.";
      default:
        return (
          "Unexpected Trainee Audit Status: " +
          evaluationStore.evaluation?.traineeAuditStatus
        );
    }
  };

  return (
    <RelativityTooltip
      shouldShowTooltip={disabled}
      compact={true}
      className="trainee-return-changes-required-tooltip-container"
      content={
        <div className="trainee-return-changes-required-tooltip">
          {getTooltipText()}
        </div>
      }
    >
      <Button
        minimal
        intent={Intent.PRIMARY}
        icon={IconNames.REDO}
        text="Send Back to Trainee"
        onClick={returnToTraineeWithChangesRequired}
        loading={isLoading}
        disabled={disabled}
      />
    </RelativityTooltip>
  );
};

export default TraineeReturnChangesRequiredActionButton;
