import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  Column,
  ColumnModel,
  GridComponent,
} from "@syncfusion/ej2-react-grids";
import { get } from "lodash";
import { openGridCommentsDialog } from "../../../Dialogs/GridComments/GridCommentsDialog";
//import { wildfireUserStore } from "../../../../stores/WildfireUserStore";

interface WildfireCommentsGridClickableIconColumnOptions extends ColumnModel {
  externalUnreadCommentCount: string;
}

export interface UnreadCommentsDTO {
  UserId: string;
  UnreadCount: number;
}

export default class WildfireCommentsGridClickableIconColumn extends Column {
  constructor(opts: WildfireCommentsGridClickableIconColumnOptions) {
    const { externalUnreadCommentCount, ...superOpts } = opts;
    super({
      ...superOpts,
      template: ((model: Record<string, any>) => {
        const modelId = model["id"];
        const fortifiedId = model["fortifiedId"];
        const grid = get(model, "column.parent") as GridComponent;

        const rawFieldValue = model[externalUnreadCommentCount] as number;

        const fieldValue_AsNumber: number = +rawFieldValue;
        const hasUnreadMessages = fieldValue_AsNumber > 0;
        const commentDisplay = hasUnreadMessages
          ? "active-grid-icon"
          : "base-grid-icon";

        return (
          <div
            className="icon-grid-column"
            onClick={(e) => {
              e.stopPropagation();
              openGridCommentsDialog(modelId, fortifiedId, async () => {
                return Promise.resolve(grid.refresh());
              });
            }}
          >
            <Icon className={commentDisplay} icon={IconNames.COMMENT}></Icon>
            {hasUnreadMessages && (
              <div className="grid-icon-value">
                {Math.min(fieldValue_AsNumber, 99)}
              </div>
            )}
          </div>
        );
      }) as any,
    });
  }
}
