import { FieldFormV2Module } from "@ucl/library";
import { MultifamilyApplicationFormProps } from "./MultifamilyApplicationForm";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../../../../common/Components/Error/ErrorStore";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { useNavigate } from "react-router";
import variables from "../../../../../../../common/config/variables";
import { FCFMFApplicationFormParts } from "../../../../../common/types/Evaluation/BaseFCFMFApplicationForm";
import { multifamilyRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedCommercialMultifamilyRouteConfig";
import { useFCFMFFieldSchemaFactory } from "../../../../../common/customHooks/useFCFMFFieldSchemaFactory";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../../../types/MultifamilyEvaluationFormModel";
import { multifamilyEvaluationAPIClient } from "../../../../lib/apiClients/multifamilyEvaluationAPIClient";
import { multifamilyEvaluationApplicationFormAPIClient } from "../../../../lib/apiClients/multifamilyEvaluationApplicationFormAPIClient";
import { openPaymentCreditDialogV2 } from "../../../../../../Common/components/Dialogs/PaymentCreditDialogV2";
import { ProductTypes } from "../../../../../../../foritfied/types/evaluation/Evaluation";
import { YesOrNoSelection } from "../../../../../../../wildfire/types/FormFields";

function useMultifamilyApplicationForm(props: MultifamilyApplicationFormProps) {
  const { builders, wieBuilders } = useFCFMFFieldSchemaFactory();
  const navigate = useNavigate();

  const formRef =
    useRef<FieldFormV2Module<Evaluation_MultifamilyEvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [applicationFormModel, setApplicationFormModel] =
    useState<Evaluation_MultifamilyEvaluationFormModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (applicationFormModel) {
      setApplicationFormModel({ ...applicationFormModel });
    }
  }, [wieBuilders]);

  useEffect(() => {
    fetchApplicationForm();
  }, []);

  useEffect(() => {
    if (!!applicationFormModel) {
      setApplicationFormModel(applicationFormModel);

      if (props.setFormModel) {
        props.setFormModel(applicationFormModel);
      }
    }
  }, [applicationFormModel]);

  const isFirstPartOfForm =
    props.formPart === Object.values(FCFMFApplicationFormParts)[0];

  const isLastPartOfForm =
    props.formPart === Object.values(FCFMFApplicationFormParts).pop();

  const noBuilders = !builders && !wieBuilders;

  const fetchApplicationForm = async () => {
    setIsLoading(true);
    await multifamilyEvaluationAPIClient
      .getMultifamilyEvaluationFormModel(props.evaluationId)
      .then((response) => {
        setApplicationFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: Evaluation_MultifamilyEvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormUpdate(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: Evaluation_MultifamilyEvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getApplicationFormPartBuilder = () => {
    if (props.isIterationEnginePage) {
      return wieBuilders?.MultifamilyApplicationForm;
    }
    switch (props.formPart) {
      case FCFMFApplicationFormParts.ApplicationInformation:
        return builders?.MultifamilyApplicationInformation;
      case FCFMFApplicationFormParts.EmailNotifications:
        return builders?.MultifamilyEmailNotifications;
      case FCFMFApplicationFormParts.GeneralProjectDetails:
        return builders?.MultifamilyGeneralProjectDetails;
      case FCFMFApplicationFormParts.NewConstructionsAndNewAdditions:
        return builders?.MultifamilyNewConstructionsAndNewAdditions;
      case FCFMFApplicationFormParts.ExistingConstructionReroofing:
        return builders?.MultifamilyExistingConstructionReroofing;
      case FCFMFApplicationFormParts.ProjectConstructionDetails:
        return builders?.MultifamilyProjectConstructionDetails;
      case FCFMFApplicationFormParts.ExistingRoofCoverSystem:
        return builders?.MultifamilyExistingRoofCoverSystems;
      case FCFMFApplicationFormParts.FileUploaders:
        return builders?.MultifamilyFileUploaders;
      case FCFMFApplicationFormParts.ConfirmationFields:
        return builders?.MultifamilyConfirmation;
      default:
        return builders?.MultifamilyApplicationForm;
    }
  };

  const handleFormSubmit = async (
    form: Evaluation_MultifamilyEvaluationFormModel
  ) => {
    setApplicationFormModel(form);

    const allChildFormsValid = await submitAllChildForms();

    if (!allChildFormsValid) {
      setIsSubmitting(false);
      AppToaster.show({
        message:
          "Please complete all required building fields before submitting",
        intent: Intent.WARNING,
        icon: "warning-sign",
      });

      // Reset childFormErrorsRef after submission
      childFormErrorsRef.current = {};
      return;
    }

    await multifamilyEvaluationApplicationFormAPIClient
      .submitMultifamilyApplicationForm(
        form.id,
        form.multifamilyEvaluation.multifamilyEvaluationApplicationForm,
        props.formPart
      )
      .then(async (response) => {
        await handleFormSubmitResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormUpdate = async (
    form: Evaluation_MultifamilyEvaluationFormModel,
    fieldKey?: string
  ) => {
    await multifamilyEvaluationApplicationFormAPIClient
      .updateMultifamilyApplicationForm(
        form.id,
        form.multifamilyEvaluation.multifamilyEvaluationApplicationForm,
        fieldKey
      )
      .then(async (response) => {
        await handleFormUpdateResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const hasPaymentWorkflow = applicationFormModel?.hasFCFMFPaymentWorkflow;

  const shouldNavigateToPaymentForm =
    applicationFormModel?.multifamilyEvaluation.canProceedToApplicationFee &&
    hasPaymentWorkflow &&
    isLastPartOfForm;

  const shouldNavigateToBoard = !hasPaymentWorkflow && isLastPartOfForm;

  const submitButtonText = hasPaymentWorkflow
    ? "Proceed to Application Fee"
    : "Submit for Eligibility Review";

  const hasMultipleBuildings =
    applicationFormModel?.multifamilyEvaluation
      ?.multifamilyEvaluationApplicationForm?.propertyHasMoreThanOneBuilding ===
    YesOrNoSelection.Yes;

  const shouldShowBuildingsCard =
    hasMultipleBuildings &&
    (props.formPart === FCFMFApplicationFormParts.GeneralProjectDetails ||
      props.isIterationEnginePage);

  const handleFormSubmitResponse = async (
    response: Evaluation_MultifamilyEvaluationFormModel
  ) => {
    props.setHasUnsavedChanges(false);

    setApplicationFormModel(response);

    if (shouldNavigateToPaymentForm) {
      await multifamilyEvaluationAPIClient
        .getApplicationFeePaymentDetails(response.id)
        .then((paymentDetails) => {
          if (paymentDetails.hasSufficientPaymentCredits) {
            openPaymentCreditDialogV2(
              paymentDetails,
              applicationFormModel.fortifiedId || "",
              ProductTypes.FortifiedMultifamily,
              applicationFormModel.step,
              setApplicationFormModel,
              props.evaluationId
            );
          } else {
            window.location.href =
              paymentDetails.paymentLink +
              `?${new URLSearchParams({
                fortified_id: applicationFormModel.fortifiedId || "",
                ibhs_formstack_env: variables.formstackEnvironment,
              })}`;
          }
        });
    } else if (shouldNavigateToBoard) {
      navigate("/fc-fmf");
    } else {
      // Navigate to the next visible form part
      const currentFormPartIndex = Object.values(
        FCFMFApplicationFormParts
      ).indexOf(props.formPart!);

      // Find the next visible form part
      let nextFormPart = null;
      for (
        let i = currentFormPartIndex + 1;
        i < Object.values(FCFMFApplicationFormParts).length;
        i++
      ) {
        const formPart = Object.values(FCFMFApplicationFormParts)[i];

        // Check if formChecklistParts exists and if the form part is hidden
        const checklistPart = props.formChecklistParts?.find(
          (part) => part.formPart === formPart
        );

        // If formChecklistParts doesn't exist, assume the part is not hidden
        if (!checklistPart || !checklistPart.hidden) {
          nextFormPart = formPart;
          break;
        }
      }

      if (nextFormPart) {
        // Navigate to the next visible part
        navigate(
          `${multifamilyRoutePrefix}/${props.evaluationId}/application-form/${nextFormPart}`
        );
      }
    }
  };

  const handleFormUpdateResponse = async (
    response: Evaluation_MultifamilyEvaluationFormModel
  ) => {
    props.setHasUnsavedChanges(false);

    handlePartialResponseFormUpdate(response);
  };

  const handlePartialResponseFormUpdate = (
    response: Evaluation_MultifamilyEvaluationFormModel
  ) => {
    //This is used to combat save on blur issues. This only updates fields that are essetnial to the form.
    //These are set in the API response. Field Counts and sub section completion status
    setApplicationFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        emailNotificationsRequiredFieldCount,
        applicationInformationRequiredFieldCount,
        newConstructionsAndNewAdditionsRequiredFieldCount,
        existingConstructionReroofingRequiredFieldCount,
        projectConstructionDetailsRequiredFieldCount,
        existingRoofCoverSystemRequiredFieldCount,
        fileUploadersRequiredFieldCount,
        confirmationFieldsRequiredFieldCount,
        generalProjectDetailsRequiredFieldCount,
        isApplicationInformationComplete,
        isEmailNotificationsComplete,
        isGeneralProjectDetailsComplete,
        isNewConstructionsAndNewAdditionsComplete,
        isExistingConstructionReroofingComplete,
        isProjectConstructionDetailsComplete,
        isExistingRoofCoverSystemComplete,
        isFileUploadersComplete,
        isConfirmationFieldsComplete,
        architecturalDrawings,
        architecturalStructuralDrawings,
      } = response.multifamilyEvaluation.multifamilyEvaluationApplicationForm;

      const {
        canProceedToApplicationFee,
        isComplexCommercialEvaluationBuildingFormAbleToBeModified,
      } = response.multifamilyEvaluation;

      const { isComplexCommercialEvaluation } = response;

      return {
        ...prevState,
        isComplexCommercialEvaluation,
        multifamilyEvaluation: {
          ...prevState.multifamilyEvaluation,
          canProceedToApplicationFee,
          isComplexCommercialEvaluationBuildingFormAbleToBeModified,
          multifamilyEvaluationApplicationForm: {
            ...prevState.multifamilyEvaluation
              .multifamilyEvaluationApplicationForm,
            emailNotificationsRequiredFieldCount,
            applicationInformationRequiredFieldCount,
            newConstructionsAndNewAdditionsRequiredFieldCount,
            existingConstructionReroofingRequiredFieldCount,
            projectConstructionDetailsRequiredFieldCount,
            existingRoofCoverSystemRequiredFieldCount,
            fileUploadersRequiredFieldCount,
            confirmationFieldsRequiredFieldCount,
            generalProjectDetailsRequiredFieldCount,
            isApplicationInformationComplete,
            isEmailNotificationsComplete,
            isGeneralProjectDetailsComplete,
            isNewConstructionsAndNewAdditionsComplete,
            isExistingConstructionReroofingComplete,
            isProjectConstructionDetailsComplete,
            isExistingRoofCoverSystemComplete,
            isFileUploadersComplete,
            isConfirmationFieldsComplete,
            architecturalDrawings,
            architecturalStructuralDrawings,
          },
        },
      };
    });
  };

  //Building Logic

  const childFormRefs = useRef<
    Map<
      string,
      React.RefObject<
        FieldFormV2Module<Evaluation_MultifamilyEvaluationFormModel>
      >
    >
  >(new Map());

  const submitAllChildForms = async (): Promise<boolean> => {
    const submissionPromises = Array.from(childFormRefs.current.entries()).map(
      async ([id, childRef]) => {
        if (childRef.current) {
          try {
            await new Promise<void>((resolve, reject) => {
              if (childRef.current) {
                // Assume each child form has an onError callback that calls updateChildFormError
                childRef.current.submit();

                // Small delay to ensure error state is captured
                setTimeout(() => {
                  const hasError = childFormErrorsRef.current[id];
                  if (hasError) {
                    reject(new Error(`Child form ${id} has an error`));
                  } else {
                    resolve();
                  }
                }, 5000); // Adjust delay as necessary for your error capture
              }
            });
            return true; // No error, submission successful
          } catch {
            return false; // Error occurred, submission failed
          }
        }
        return true; // No child ref, consider submission successful
      }
    );

    // Await all submissions and return true only if all were successful
    const results = await Promise.all(submissionPromises);
    return results.every((result) => result); // True if all succeeded
  };

  const childFormErrorsRef = useRef<Record<string, boolean>>({});

  const updateChildFormError = (formId: string, hasError: boolean) => {
    childFormErrorsRef.current = {
      ...childFormErrorsRef.current,
      [formId]: hasError,
    };
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    applicationFormModel,
    isFirstPartOfForm,
    isLastPartOfForm,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
    submitButtonText,
    shouldShowBuildingsCard,
    childFormRefs,
    updateChildFormError,
  };
}

export default useMultifamilyApplicationForm;
