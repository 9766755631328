import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../../multifamily/types/MultifamilyEvaluationFormModel";
import { MultifamilyEvaluationFieldInspectionFormModel } from "../../types/MultifamilyFieldInspectionFormModel";

class MultifamilyEvaluationFieldInspectionFormAPIClient {
  updateMultifamilyEvaluationFieldInspectionForm = async (
    evaluationId: string,
    evaluationFieldInspectionForm: MultifamilyEvaluationFieldInspectionFormModel,
    fieldKey?: string
  ): Promise<Evaluation_MultifamilyEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_MultifamilyEvaluationFormModel>(
      `/multifamily/evaluation-field-inspection-form/${evaluationId}`,
      {
        evaluationFieldInspectionForm,
        fieldKey,
      }
    );
  };

  submitMultifamilyEvaluationFieldInspectionForm = async (
    evaluationId: string,
    evaluationFieldInspectionForm: MultifamilyEvaluationFieldInspectionFormModel
  ): Promise<Evaluation_MultifamilyEvaluationFormModel> => {
    return baseApiClient.post<Evaluation_MultifamilyEvaluationFormModel>(
      `/multifamily/evaluation-field-inspection-form/${evaluationId}`,
      evaluationFieldInspectionForm
    );
  };
}

export const multifamilyEvaluationFieldInspectionFormAPIClient =
  new MultifamilyEvaluationFieldInspectionFormAPIClient();
