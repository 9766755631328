import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { FCFMFRedesignationFormParts } from "../../../common/types/Redesignation/BaseFCFMFRedesignationFormModel";
import { CommercialRedesignationForm_FormModel } from "../../types/CommercialRedesignationForm_FormModel";
import { Evaluation_CommercialRedesignationFormModel } from "../../types/CommercialRedesignationFormModel";

class CommercialRedesignationApplicationFormAPIClient {
  updateCommercialRedesignationForm = async (
    evaluationId: string,
    redesignationForm: CommercialRedesignationForm_FormModel,
    fieldKey?: string
  ): Promise<Evaluation_CommercialRedesignationFormModel> => {
    return baseApiClient.put<Evaluation_CommercialRedesignationFormModel>(
      `/commercial/redesignation-form/${evaluationId}`,
      {
        redesignationForm,
        fieldKey,
      }
    );
  };

  submitCommercialRedesignationForm = async (
    wildfireApplicationId: string,
    redesignationForm: CommercialRedesignationForm_FormModel,
    formPart?: FCFMFRedesignationFormParts
  ): Promise<Evaluation_CommercialRedesignationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const applicationFormPartValue = (
      formPart ??
      (Object.values(
        FCFMFRedesignationFormParts
      ).pop() as FCFMFRedesignationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_CommercialRedesignationFormModel>(
      `/commercial/redesignation-form/${wildfireApplicationId}/${applicationFormPartValue}`,
      redesignationForm
    );
  };
}

export const commercialRedesignationpplicationFormAPIClient =
  new CommercialRedesignationApplicationFormAPIClient();
