import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import { showSuccessAppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { useState } from "react";

interface SendForReviewButtonProps {
  shouldDisplaySendForReview: boolean;
  isSendForReviewDisabled: boolean;
  sendForReview: () => Promise<void>;
}

const SendForReviewButton: React.FC<SendForReviewButtonProps> = ({
  shouldDisplaySendForReview,
  isSendForReviewDisabled,
  sendForReview,
}) => {
  const shouldDisplaySendForReviewWithChangeRequest =
    shouldDisplaySendForReview;

  const [isloading, setIsLoading] = useState(false);

  if (shouldDisplaySendForReviewWithChangeRequest) {
    return isSendForReviewDisabled ? (
      <Popover2
        interactionKind="hover"
        placement="bottom-end"
        portalClassName="tooltip-popover"
        popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
        content={
          <span>
            All change requests must be addressed before sending this for
            review. If you have addressed all change requests and sent this for
            review, then no further action is required at this time.
          </span>
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        renderTarget={({ isOpen, ...targetProps }) => (
          <div {...targetProps}>
            <Button
              icon={IconNames.KEY_ENTER}
              text="Send for Review"
              minimal
              intent={Intent.PRIMARY}
              onClick={async () => {
                await sendForReview();
              }}
              disabled={isSendForReviewDisabled}
            />
          </div>
        )}
      />
    ) : (
      <Button
        icon={IconNames.KEY_ENTER}
        text="Send for Review"
        minimal
        intent={Intent.PRIMARY}
        loading={isloading}
        onClick={async () => {
          setIsLoading(true);
          await sendForReview()
            .then(() => showSuccessAppToaster("Evaluation sent for review"))
            .finally(() => setIsLoading(false));
        }}
      />
    );
  }

  return null;
};

export default SendForReviewButton;
