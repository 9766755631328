import { Button, Intent } from "@blueprintjs/core";
import { useState } from "react";
import { dialogsViewerStore } from "../../../stores/DialogsViewerStore";
import { evaluationStore } from "../../../stores/EvaluationStore";
import { AppToaster } from "../../Toast/Toast";

interface WithdrawEvaluationActionButtonProps {}

const WithdrawEvaluationActionButton: React.FC<
  WithdrawEvaluationActionButtonProps
> = ({}) => {
  const [isLoading, setIsLoading] = useState(false);

  return !!evaluationStore.evaluation?.isWithdrawn ? (
    <Button
      loading={isLoading}
      intent={Intent.SUCCESS}
      onClick={async () => {
        setIsLoading(true);
        await evaluationStore
          .reactivateEvaluation()
          .then(() => {
            AppToaster.show({
              message: (
                <div>
                  <h3>Success</h3>Evaluation Reactivated.
                </div>
              ),
              intent: Intent.SUCCESS,
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }}
    >
      Reactivate
    </Button>
  ) : (
    <Button
      minimal
      intent={Intent.DANGER}
      onClick={() => {
        dialogsViewerStore.setIsWithdrawEvaluationDialogOpen(true);
      }}
    >
      Withdraw
    </Button>
  );
};

export default WithdrawEvaluationActionButton;
