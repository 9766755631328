import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { FCFMFApplicationFormParts } from "../../common/types/Evaluation/BaseFCFMFApplicationForm";
import MultifamilyApplicationFormContainer from "../components/forms/ApplicationForm/Container/MultifamilyApplicationFormContainer";

export interface MultifamilyApplicationFormPageProps {
  evaluationId?: string;
  multifamilyApplicationFormPart?: FCFMFApplicationFormParts;
}

const MultifamilyApplicationFormPageComponent: FC<
  MultifamilyApplicationFormPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <MultifamilyApplicationFormContainer
            evaluationId={props.evaluationId}
            applicationFormPart={props.multifamilyApplicationFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const MultifamilyApplicationFormPage = withAITracking(
  reactPlugin,
  MultifamilyApplicationFormPageComponent,
  "MultifamilyApplicationForm",
  "tracking-container"
);
