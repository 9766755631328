import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import { evaluationStore } from "../../../stores/EvaluationStore";
import { dialogsViewerStore } from "../../../stores/DialogsViewerStore";

interface ApproveEvaluationActionButtonProps {
  isDisabled: boolean;
  disabledTooltip?: string;
  errors?: string[];
}

const ApproveEvaluationActionButton: React.FC<
  ApproveEvaluationActionButtonProps
> = ({ isDisabled, disabledTooltip, errors }) => {
  const disabledWithTooltip = isDisabled && disabledTooltip;
  const hasErrors = errors && errors.length > 0;

  if (disabledWithTooltip || hasErrors) {
    return (
      <Popover2
        interactionKind="hover"
        placement="bottom-end"
        portalClassName="tooltip-popover"
        popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
        content={
          <span>
            {hasErrors && (
              <div>
                The following field(s) must be complete prior to moving forward:
                <ul>
                  {errors?.map((error, idx) => (
                    <li key={idx}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
            {disabledWithTooltip && <div>{disabledTooltip}</div>}
          </span>
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        renderTarget={({ isOpen, ...targetProps }) => (
          <div {...targetProps}>
            <Button
              text="Approve"
              intent={Intent.SUCCESS}
              icon={IconNames.TICK_CIRCLE}
              disabled
              minimal
            />
          </div>
        )}
      />
    );
  }

  // Compliance Letter status
  if (
    evaluationStore.isCommercialOrMultifamilyEvaluation &&
    evaluationStore.isEvaluationOnComplianceLetterStep
  ) {
    return (
      <Button
        text="Approve"
        intent={Intent.SUCCESS}
        icon={IconNames.TICK_CIRCLE}
        disabled={isDisabled}
        minimal
        onClick={() => {
          dialogsViewerStore.setIsComplianceLetterSendForwardDialogOpen(true);
        }}
      />
    );
  }

  // IBHS Review status
  if (
    evaluationStore.isCommercialOrMultifamilyEvaluation &&
    evaluationStore.isEvaluationOnFinalReviewStep &&
    !evaluationStore.isRedesignationEvaluation
  ) {
    return (
      <Button
        text="Approve"
        intent={Intent.SUCCESS}
        icon={IconNames.TICK_CIRCLE}
        disabled={isDisabled}
        minimal
        onClick={() => {
          dialogsViewerStore.setIsIBHSReviewApprovalDialogOpen(true);
        }}
      />
    );
  }

  return (
    <Button
      text="Approve"
      intent={Intent.SUCCESS}
      icon={IconNames.TICK_CIRCLE}
      disabled={isDisabled}
      minimal
      onClick={() => {
        dialogsViewerStore.setIsAuditorApprovalDialogOpen(true);
      }}
    />
  );
};

export default ApproveEvaluationActionButton;
