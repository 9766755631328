import { Button, ButtonGroup, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { RelativityTooltip } from "@ucl/library";
import { FC } from "react";

interface CommentModeSelectorProps {
  isPublic: boolean;
  setIsPublic: (isPublic: boolean) => void;
  isPublicDisabled: boolean;
}

export const CommentModeSelector: FC<CommentModeSelectorProps> = ({
  isPublic,
  setIsPublic,
  isPublicDisabled,
}) => {
  return (
    <div className="comment-mode-selector">
      <ButtonGroup>
        <RelativityTooltip
          compact
          shouldShowTooltip={isPublicDisabled}
          className="comment-mode-selector-tooltip-container"
          content={
            <div className="comment-mode-selector-tooltip">
              Please claim the evaluation to leave Public comments and create
              Change Requests.
            </div>
          }
        >
          <Button
            intent={isPublic ? Intent.PRIMARY : Intent.NONE}
            text="Public"
            icon={IconNames.GLOBE}
            active={isPublic}
            onClick={() => setIsPublic(true)}
            disabled={isPublicDisabled}
          />
        </RelativityTooltip>
        <Button
          intent={isPublic ? Intent.NONE : Intent.PRIMARY}
          text="Internal"
          rightIcon={IconNames.LOCK}
          active={!isPublic}
          onClick={() => setIsPublic(false)}
        />
      </ButtonGroup>
    </div>
  );
};
