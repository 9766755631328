import { Footer, SecondaryButton } from "@ucl/library";
import { useNavigate } from "react-router";
import { closeWildfireUnsavedChangesDialog } from "./UnsavedChangesDialogBuilder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

export interface UnsavedChangesDialogProps {
  location: string;
}

export const UnsavedChangesDialog: React.FC<UnsavedChangesDialogProps> = ({
  location,
}) => {
  const [isWaiting, setIsWaiting] = useState<boolean>(true);

  const navigate = useNavigate();

  const handleLeave = () => {
    navigate(location);
    closeWildfireUnsavedChangesDialog();
  };

  const handleStay = () => {
    closeWildfireUnsavedChangesDialog();
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsWaiting(false);
    }, 2500);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div>
      <p>
        We save your changes automatically in the background as you fill out the
        form. However, if you leave this page now, some of your recent changes
        may not be saved. Do you still want to leave without saving all your
        changes?
      </p>

      <Footer>
        <SecondaryButton
          cssClass="wildfire-unsaved-changes-leave-button"
          onClick={() => {
            handleLeave();
          }}
          disabled={isWaiting}
        >
          Leave
          <FontAwesomeIcon icon={faClose} />
        </SecondaryButton>

        <SecondaryButton
          cssClass="wildfire-unsaved-changes-stay-button"
          onClick={() => {
            handleStay();
          }}
        >
          Stay
          <FontAwesomeIcon icon={faArrowRight} />
        </SecondaryButton>
      </Footer>
    </div>
  );
};
