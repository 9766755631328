import { Intent } from "@blueprintjs/core";
import { IconName, IconNames } from "@blueprintjs/icons";

export const changeRequestStatusMapToIntent: Record<string, Intent> = {
  Unaddressed: Intent.DANGER,
  Addressed: Intent.PRIMARY,
  Unresolved: Intent.WARNING,
  Resolved: Intent.SUCCESS,
};

export const changeRequestStatusMapToIcon: Record<
  string,
  IconName | undefined
> = {
  Unaddressed: undefined,
  Addressed: IconNames.TICK_CIRCLE,
  Unresolved: undefined,
  Resolved: IconNames.TICK_CIRCLE,
};

export type IterationEngineChangeRequestStatus =
  | "new"
  | "addressed"
  | "resolved"
  // here are the UI only values
  | "dirty-new"
  | "dirty-addressed-by-value"
  | "dirty-addressed-by-comment"
  | "dirty-addressed-by-file";

export interface IterationEngineChangeRequest {
  id: string;
  fortifiedId: string;
  fieldId: string;
  oldValue: string;
  newValue: string;
  status: IterationEngineChangeRequestStatus;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date | null;
  updatedBy: string;
  deletedAt: Date | null;
  deletedBy: string;
}
