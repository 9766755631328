import baseApiClient from "../../../foritfied/lib/apiClients/baseApiClient";
import { DocumentUploadRequest } from "./types";

class DocumentApiClient {
  uploadImage = async (data: DocumentUploadRequest) => {
    const formData = new FormData();
    formData.append("fileStream", data.file);
    formData.append("fileName", data.fileName);
    formData.append("parentFolderId", data.parentFolderId);
    formData.append("description", data.description || "");
    return baseApiClient.post<DocumentUploadRequest>(
      `document/upload`,
      formData
    );
  };

  uploadAnnotatedImage = async (data: DocumentUploadRequest) => {
    const formData = new FormData();
    formData.append("fileStream", data.file);
    formData.append("existingFileId", data.existingFileId);
    formData.append("fileName", data.fileName);
    formData.append("parentFolderId", data.parentFolderId);
    formData.append("description", data.description || "");
    return baseApiClient.post<DocumentUploadRequest>(
      `document/upload-annotated`,
      formData
    );
  };
}

export const documentApiClient = new DocumentApiClient();
