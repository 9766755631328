import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { MultifamilyEvaluationCompanyAssignmentFormModel } from "../../types/MultifamilyEvaluationCompanyAssignmentFormModel";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../types/MultifamilyEvaluationFormModel";

class MultifamilyEvaluationCompanyAssignmentFormAPIClient {
  updateMultifamilyEvaluationCompanyAssignmentForm = async (
    evaluationId: string,
    evaluationCompanyAssignmentForm: MultifamilyEvaluationCompanyAssignmentFormModel,
    fieldKey?: string
  ): Promise<Evaluation_MultifamilyEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_MultifamilyEvaluationFormModel>(
      `/multifamily/evaluation-company-assignment-form/${evaluationId}`,
      {
        evaluationCompanyAssignmentForm,
        fieldKey,
      }
    );
  };

  submitMultifamilyEvaluationCompanyAssignmentForm = async (
    evaluationId: string,
    evaluationCompanyAssignmentForm: MultifamilyEvaluationCompanyAssignmentFormModel
  ): Promise<Evaluation_MultifamilyEvaluationFormModel> => {
    return baseApiClient.post<Evaluation_MultifamilyEvaluationFormModel>(
      `/multifamily/evaluation-company-assignment-form/${evaluationId}`,
      evaluationCompanyAssignmentForm
    );
  };
}

export const multifamilyEvaluationCompanyAssignmentFormAPIClient =
  new MultifamilyEvaluationCompanyAssignmentFormAPIClient();
