import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { FCFMFComplianceFormParts } from "../../../common/types/Evaluation/BaseFCFMFComplianceForm";
import { MultifamilyComplianceFormModel } from "../../types/MultifamilyComplianceFormModel";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../types/MultifamilyEvaluationFormModel";

class MultifamilyEvaluationComplianceFormAPIClient {
  updateMultifamilyComplianceForm = async (
    evaluationId: string,
    multifamilyComplianceForm: MultifamilyComplianceFormModel,
    fieldKey?: string
  ): Promise<Evaluation_MultifamilyEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_MultifamilyEvaluationFormModel>(
      `/multifamily/compliance-form/${evaluationId}`,
      {
        multifamilyComplianceForm,
        fieldKey,
      }
    );
  };

  submitMultifamilyComplianceForm = async (
    wildfireApplicationId: string,
    complianceForm: MultifamilyComplianceFormModel,
    complianceFormPart?: FCFMFComplianceFormParts
  ): Promise<Evaluation_MultifamilyEvaluationFormModel> => {
    // If complianceFormPart is not provided, default to the last part of the form
    const complianceFormPartValue = (
      complianceFormPart ??
      (Object.values(
        FCFMFComplianceFormParts
      ).pop() as FCFMFComplianceFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_MultifamilyEvaluationFormModel>(
      `/multifamily/compliance-form/${wildfireApplicationId}/${complianceFormPartValue}`,
      complianceForm
    );
  };
}

export const multifamilyEvaluationComplianceFormAPIClient =
  new MultifamilyEvaluationComplianceFormAPIClient();
