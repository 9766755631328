import { Label } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { observer } from "mobx-react";
import { Icon } from "@blueprintjs/core";
import "./styles.scss";
import { WithTooltip } from "../../Tooltip/Tooltip";

export interface SelectableLabelProps {
  name: string;
  description?: string;
}

export const SelectableLabelWithToolTip: React.FC<SelectableLabelProps> =
  observer(({ name, description }) => {
    return (
      <Label className="field-label">
        <span className="label-title">{name}</span>
        <WithTooltip
          placement="auto"
          content={description || ""}
          interactionKind={"hover"}
          shouldShowTooltip={true}
          disabled={!description}
        >
          {description && <Icon iconSize={15} icon={IconNames.INFO_SIGN} />}
        </WithTooltip>
      </Label>
    );
  });
