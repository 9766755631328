import { Classes, Dialog, TextArea } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { evaluationApiClient } from "../../lib/apiClients/evaluation/evaluationApiClient";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { fortifiedGridStore } from "../../stores/FortifiedGridStore";
import { DialogFooter } from "./DialogFooter";
import { BaseDialogProps } from "./types";
import { MinimalSelectableLabel } from "../Fields/SelectableLabel/MinimalSelectableLabel";

export interface AuditorNotesDialogProps extends BaseDialogProps {}

export const AuditorNotesDialog: React.FC<AuditorNotesDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [localNote, setLocalNote] = useState<string>();

  useEffect(() => {
    if (!!dialogsViewerStore.auditorNotesDialogOpenOptions) {
      setLocalNote(
        dialogsViewerStore.auditorNotesDialogOpenOptions.auditorNotes
      );
    }
    return () => setLocalNote(localNote);
  }, [dialogsViewerStore.auditorNotesDialogOpenOptions]);

  return (
    <Dialog
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Internal Notes"}
      onClose={closeDialog}
      className="auditor-notes-dialog"
    >
      <div className={Classes.DIALOG_BODY}>
        <MinimalSelectableLabel label="This note is only visible to IBHS." />
        <TextArea
          className="notes-textarea"
          rows={5}
          onChange={(e) => {
            setLocalNote(e.target.value);
          }}
          value={localNote}
          large={true}
          fill={true}
        />
      </div>
      <DialogFooter
        closeButtonText="Cancel"
        submitButtonText="Save"
        closeDialog={closeDialog}
        submitDialog={async () => {
          if (!!dialogsViewerStore.auditorNotesDialogOpenOptions) {
            await evaluationApiClient
              .updateAuditorNotes(
                dialogsViewerStore.auditorNotesDialogOpenOptions.evaluationId,
                { auditorNotes: localNote }
              )
              .then(() => {
                fortifiedGridStore.refresh();
                submitDialog();
              })
              .finally(() => {
                closeDialog();
              });
          }
        }}
      />
    </Dialog>
  );
};
