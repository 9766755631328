import {
  EnumSingleSelectFieldProps,
  FieldSchemaFormBuilderV2,
  NumericFieldProps,
  SchemaFactoryV2,
} from "@ucl/library";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { get } from "lodash";
import { EvaluationFormModel } from "../EvaluationFormModel";

const modelName = "Evaluation";
const subModelName = "WildfireApplication";
const formName = "HomeExteriorFieldEvaluationForm";
const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Vents
const ventsFields = (
  form: EvaluationFormModel,
  isIEBuilder?: boolean,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("VentsPhotoFolder"),
    defaultToUpload: !isIEBuilder,
    hasLimitedFileTypes: true,
    errorMessages: get(errors, "ventsPhotoFolder.FileCount"),
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire" &&
      !form.areLegacyFileUploadersRequired,
  } as WildfireBoxContentExplorerProps,
  buildFieldKey("AreGableEndVentsFireResistant"),
  buildFieldKey("AreVentsUnderOpenFramedEaveCoveredWithMetalMesh"),
  buildFieldKey("AreCrawlSpaceVentsCoveredWithMetalMesh"),
  buildFieldKey("DoesDryerVentHaveLouverOrFlap"),
  buildFieldKey("IsDryerVentMetalWithLouverOrFlap"),
];

const ventsRequiredFields = [
  buildFieldKey("AreGableEndVentsFireResistant"),
  buildFieldKey("AreVentsUnderOpenFramedEaveCoveredWithMetalMesh"),
  buildFieldKey("AreCrawlSpaceVentsCoveredWithMetalMesh"),
  buildFieldKey("DoesDryerVentHaveLouverOrFlap"),
  buildFieldKey("IsDryerVentMetalWithLouverOrFlap"),
  buildFieldKey("VentsPhotoFolder"),
];

export const homeExteriorFieldEvaluationVentsBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        ventsFields(form, false, errors),
        onValueChange,
        form,
        errors,
        ventsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Part 2: Six Inch Vertical
const sixInchVerticalFields = (
  form: EvaluationFormModel,
  isIEBuilder?: boolean,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("SixInchVerticalPhotoFolder"),
    defaultToUpload: !isIEBuilder,
    hasLimitedFileTypes: true,
    errorMessages: get(errors, "sixInchVerticalPhotoFolder.FileCount"),
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire" &&
      !form.areLegacyFileUploadersRequired,
  } as WildfireBoxContentExplorerProps,
  buildFieldKey("DoAllExteriorWallsHaveSixInchNoncombustibleVertical"),
];
const sixInchVerticalRequiredFields = [
  buildFieldKey("DoAllExteriorWallsHaveSixInchNoncombustibleVertical"),
  buildFieldKey("SixInchVerticalPhotoFolder"),
];

export const homeExteriorFieldEvaluationSixInchVerticalBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        sixInchVerticalFields(form, false, errors),
        onValueChange,
        form,
        errors,
        sixInchVerticalRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Part 3: Siding Material
const sidingMaterialFields = (
  form: EvaluationFormModel,
  isIEBuilder?: boolean,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("SidingMaterialPhotoFolder"),
    defaultToUpload: !isIEBuilder,
    hasLimitedFileTypes: true,
    errorMessages: get(errors, "sidingMaterialPhotoFolder.FileCount"),
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire" &&
      !form.areLegacyFileUploadersRequired,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("DominantWallCoverPercentage"),
    min: 0,
    max: 100,
    format: "0'%'",
    validateDecimalOnType: true,
    decimals: 0,
  } as NumericFieldProps,
  buildFieldKey("DominantWallCoverMaterial"),
  {
    fieldKey: buildFieldKey("SecondaryWallCoverPercentage"),
    min: 0,
    max: 100,
    format: "0'%'",
    hidden:
      form.wildfireApplication.homeExteriorFieldEvaluationForm
        .dominantWallCoverPercentage === 100 ||
      form.wildfireApplication.homeExteriorFieldEvaluationForm
        .dominantWallCoverPercentage === null,
    validateDecimalOnType: true,
    decimals: 0,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("SecondaryWallCoverMaterial"),
    hidden:
      form.wildfireApplication.homeExteriorFieldEvaluationForm
        .dominantWallCoverPercentage === 100 ||
      form.wildfireApplication.homeExteriorFieldEvaluationForm
        .dominantWallCoverPercentage === null,
  } as EnumSingleSelectFieldProps,

  buildFieldKey("AreDecorativeShuttersNoncombustible"),
  buildFieldKey("AreFunctionalShuttersPresentOnAllWindows"),
  buildFieldKey("IsSpaceUnderBayWindowsEnclosed"),
];
const sidingMaterialRequiredFields: string[] = [
  buildFieldKey("DominantWallCoverPercentage"),
  buildFieldKey("DominantWallCoverMaterial"),
  buildFieldKey("SecondaryWallCoverPercentage"),
  buildFieldKey("SecondaryWallCoverMaterial"),
  buildFieldKey("AreDecorativeShuttersNoncombustible"),
  buildFieldKey("AreFunctionalShuttersPresentOnAllWindows"),
  buildFieldKey("SidingMaterialPhotoFolder"),
  buildFieldKey("IsSpaceUnderBayWindowsEnclosed"),
];

export const homeExteriorFieldEvaluationSidingMaterialBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        sidingMaterialFields(form, false, errors),
        onValueChange,
        form,
        errors,
        sidingMaterialRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Part 4: Windows
const windowsFields = (
  form: EvaluationFormModel,
  isIEBuilder?: boolean,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("WindowsPhotoFolder"),
    defaultToUpload: !isIEBuilder,
    hasLimitedFileTypes: true,
    errorMessages: get(errors, "windowsPhotoFolder.FileCount"),
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire" &&
      !form.areLegacyFileUploadersRequired,
  } as WildfireBoxContentExplorerProps,
  buildFieldKey("AreAllWindowsCompliant"),
];
const windowsRequiredFields: string[] = [
  buildFieldKey("AreAllWindowsCompliant"),
  buildFieldKey("WindowsPhotoFolder"),
];

export const homeExteriorFieldEvaluationWindowsBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        windowsFields(form, false, errors),
        onValueChange,
        form,
        errors,
        windowsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Part 5: Doors
const doorsFields = (
  form: EvaluationFormModel,
  isIEBuilder?: boolean,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("DoorsPhotoFolder"),
    defaultToUpload: !isIEBuilder,
    hasLimitedFileTypes: true,
    errorMessages: get(errors, "doorsPhotoFolder.FileCount"),
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire" &&
      !form.areLegacyFileUploadersRequired,
  } as WildfireBoxContentExplorerProps,
  buildFieldKey("AreAllExteriorDoorsCompliant"),
];
const doorsRequiredFields: string[] = [
  buildFieldKey("AreAllExteriorDoorsCompliant"),
  buildFieldKey("DoorsPhotoFolder"),
];

export const homeExteriorFieldEvaluationDoorsBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        doorsFields(form, false, errors),
        onValueChange,
        form,
        errors,
        doorsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Part 6: Decks
const decksFields = (
  form: EvaluationFormModel,
  isIEBuilder?: boolean,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("DecksPhotoFolder"),
    defaultToUpload: !isIEBuilder,
    hasLimitedFileTypes: true,
    errorMessages: get(errors, "decksPhotoFolder.FileCount"),
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire" &&
      !form.areLegacyFileUploadersRequired,
  } as WildfireBoxContentExplorerProps,
  buildFieldKey("DoesAreaAroundDecksHaveNoncombustibleSpace"),
  buildFieldKey("IsTopSurfaceOfDecksClearOfYardDebris"),
  buildFieldKey("IsTopSurfaceOfDecksFreeFromTreesAndShrubs"),
  buildFieldKey("DoesTopSurfaceOfDecksHaveNoncombustibleItems"),
  buildFieldKey("IsUnderneathDecksFreeFromVegetation"),
  buildFieldKey("AreDecksClearOfStoredItems"),
  buildFieldKey("AreDecksFullyEnclosedUnderneath"),
  buildFieldKey("IsAttachedDeckStructureMetal"),
  buildFieldKey("DoesDetachedDeckMeetAttachedDeckRequirements"),
  buildFieldKey("AreDecksConstructedWithNoncombustibleMaterials"),
];
const decksRequiredFields = [
  buildFieldKey("DoesAreaAroundDecksHaveNoncombustibleSpace"),
  buildFieldKey("IsTopSurfaceOfDecksClearOfYardDebris"),
  buildFieldKey("IsTopSurfaceOfDecksFreeFromTreesAndShrubs"),
  buildFieldKey("DoesTopSurfaceOfDecksHaveNoncombustibleItems"),
  buildFieldKey("IsUnderneathDecksFreeFromVegetation"),
  buildFieldKey("AreDecksClearOfStoredItems"),
  buildFieldKey("AreDecksFullyEnclosedUnderneath"),
  buildFieldKey("IsAttachedDeckStructureMetal"),
  buildFieldKey("DoesDetachedDeckMeetAttachedDeckRequirements"),
  buildFieldKey("AreDecksConstructedWithNoncombustibleMaterials"),
  buildFieldKey("DecksPhotoFolder"),
];

export const homeExteriorFieldEvaluationDecksBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        decksFields(form, false, errors),
        onValueChange,
        form,
        errors,
        decksRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Full form builder with all parts included
export const homeExteriorFieldEvaluationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        [
          ...ventsFields(form, false, errors),
          ...sixInchVerticalFields(form, false, errors),
          ...sidingMaterialFields(form, false, errors),
          ...windowsFields(form, false, errors),
          ...doorsFields(form, false, errors),
          ...decksFields(form, false, errors),
        ],
        onValueChange,
        form,
        errors,
        [
          ...ventsRequiredFields,
          ...sixInchVerticalRequiredFields,
          ...sidingMaterialRequiredFields,
          ...windowsRequiredFields,
          ...doorsRequiredFields,
          ...decksRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const wieHomeExteriorFieldEvaluationFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        [
          ...ventsFields(form, true, errors),
          ...sixInchVerticalFields(form, true, errors),
          ...sidingMaterialFields(form, true, errors),
          ...windowsFields(form, true, errors),
          ...doorsFields(form, true, errors),
          ...decksFields(form, true, errors),
        ],
        onValueChange,
        form,
        errors,
        [
          ...ventsRequiredFields,
          ...sixInchVerticalRequiredFields,
          ...sidingMaterialRequiredFields,
          ...windowsRequiredFields,
          ...doorsRequiredFields,
          ...decksRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];

    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};
