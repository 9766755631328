import { Button, Intent, TextArea } from "@blueprintjs/core";
import React, { useState } from "react";
import { evaluationStore } from "../../stores/EvaluationStore";
import { ChangeRequestStatus } from "../../types/evaluation/ChangeRequest";
import { AppToaster } from "../Toast/Toast";
import { permissionStore } from "../../stores/PermissionStore";
import { CommentModeSelector } from "./CommentModeSelector";
import { fortifiedUserStore } from "../../stores/FortifiedUserStore";
import { autorun } from "mobx";

export interface CommentInputProps {
  canProvideChangeRequestResolution: boolean;
  onResolveChangeRequest: () => void;
  onUnresolveChangeRequest: () => void;
  onSubmitComment: (message: string, isPublic: boolean) => Promise<void>;
  changeRequestId?: string;
  changeRequestStatus?: ChangeRequestStatus;
}

export const CommentInput: React.FC<CommentInputProps> = ({
  canProvideChangeRequestResolution,
  onSubmitComment,
  onResolveChangeRequest,
  onUnresolveChangeRequest,
  changeRequestId,
  changeRequestStatus,
}) => {
  const [isBusyDoingWork, setIsBusyDoingWork] = React.useState(false);
  const [isSubmittingComment, setIsSubmittingComment] = React.useState(false);
  const [unsavedComment, setUnsavedComment] = React.useState("");

  // Workaround to get Public comment button to enable when the Claim Evaluation button is clicked
  // Ideally, this could be reduced to:
  // const isTraineeAuditAdminOnUnclaimedEvaluation =
  //   fortifiedUserStore.isTraineeAuditAdmin &&
  //   !evaluationStore.isEvaluationClaimedByCurrentUser;
  // With no useState and no autorun

  const getIsTraineeAuditAdminOnUnclaimedEvaluation = () =>
    fortifiedUserStore.isTraineeAuditAdmin &&
    !evaluationStore.isEvaluationClaimedByCurrentUser;

  const [
    isTraineeAuditAdminOnUnclaimedEvaluation,
    setIsTraineeAuditAdminOnUnclaimedEvaluation,
  ] = useState(getIsTraineeAuditAdminOnUnclaimedEvaluation);

  autorun(() => {
    const newValue = getIsTraineeAuditAdminOnUnclaimedEvaluation();

    if (isTraineeAuditAdminOnUnclaimedEvaluation !== newValue) {
      setIsTraineeAuditAdminOnUnclaimedEvaluation(newValue);
    }
  });

  // Comments default to public for all users except auditor trainees
  // And trainee audit admins who have not claimed the evaluation
  const [isPublic, setIsPublic] = useState<boolean>(
    !fortifiedUserStore.isAuditorTrainee &&
      !isTraineeAuditAdminOnUnclaimedEvaluation
  );

  const showResolveButton =
    !!changeRequestId &&
    changeRequestStatus !== ChangeRequestStatus.Resolved &&
    canProvideChangeRequestResolution;
  const showUnresolveButton =
    !!changeRequestId &&
    changeRequestStatus === ChangeRequestStatus.Resolved &&
    canProvideChangeRequestResolution;

  return (
    <div className="comment-input">
      {showResolveButton && (
        <Button
          text="Resolve"
          className="comment-input_resolve-button"
          disabled={isBusyDoingWork}
          intent={Intent.SUCCESS}
          onClick={async () => {
            setIsBusyDoingWork(true);
            await onResolveChangeRequest();

            if (evaluationStore.evaluation?.id) {
              await evaluationStore.init_change_requests(
                evaluationStore.evaluation.id
              );
            }

            setIsBusyDoingWork(false);
            AppToaster.show({
              message: "Change Request Resolved Successfully!",
              intent: Intent.SUCCESS,
            });
          }}
        />
      )}
      {showUnresolveButton && (
        <Button
          text="Unresolve"
          intent={Intent.DANGER}
          className="comment-input_unresolve-button"
          disabled={isBusyDoingWork}
          onClick={async () => {
            setIsBusyDoingWork(true);
            await onUnresolveChangeRequest();

            if (evaluationStore.evaluation?.id) {
              evaluationStore.init(evaluationStore.evaluation.id);
            }

            setIsBusyDoingWork(false);
            AppToaster.show({
              message: "Change Request Unresolved Successfully!",
              intent: Intent.SUCCESS,
            });
          }}
        />
      )}
      {permissionStore.canViewCommentModeSelector && (
        <CommentModeSelector
          isPublic={isPublic}
          setIsPublic={setIsPublic}
          isPublicDisabled={isTraineeAuditAdminOnUnclaimedEvaluation}
        />
      )}
      <TextArea
        className="comment-textarea"
        rows={5}
        value={unsavedComment}
        placeholder="Add a new comment..."
        onChange={(e) => {
          setUnsavedComment(e.target.value);
        }}
      />
      <div className="comment-input_actions">
        <Button
          text="Submit Comment"
          intent={Intent.PRIMARY}
          className="comment-input__submit-button"
          disabled={
            !unsavedComment ||
            unsavedComment.trim().length === 0 ||
            isSubmittingComment
          }
          loading={isSubmittingComment}
          onClick={async () => {
            setIsSubmittingComment(true);
            await onSubmitComment(unsavedComment, isPublic)
              .then(() => {
                if (evaluationStore.evaluation?.id) {
                  evaluationStore.init(
                    evaluationStore.evaluation.id,
                    !!changeRequestId ? changeRequestId : "new"
                  );
                }

                setUnsavedComment("");
              })
              .finally(() => {
                setIsSubmittingComment(false);
              });
          }}
        />
      </div>
    </div>
  );
};
