import { EnumSingleSelectFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";

export enum FCFMFEvaluationCompanyAssignmentFormParts {
  EvaluationCompanyAssignment = "evaluation-company-assignment",
}

export interface BaseFCFMFEvaluationCompanyAssignmentFormModel
  extends BaseFCFMFEvaluationCompanyAssignmentFormFields {}

interface BaseFCFMFEvaluationCompanyAssignmentFormFields {
  evaluatorCompany: EnumSingleSelectFieldValue;
}
