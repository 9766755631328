import { PrimaryCard } from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import classNames from "classnames";
import { EvaluationStep } from "../../../../../../foritfied/types/evaluation/Evaluation";
import { UnsavedChangesBlockerContext } from "../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import HomeSCSHEvaluationBasicInformationForm from "../BasicInformationForm/Form/HomeSCSHEvaluationBasicInformationForm";

interface HomeSCSHIterationEngineFormsProps {
  evaluationId: string;
  evaluationStep: EvaluationStep;
  onFormSave: (fieldKey: string) => Promise<void>;
  onAllFormsInitialized: () => void;
  fieldsContainerClassName: string;
}

const HomeSCSHIterationEngineForms: React.FC<
  HomeSCSHIterationEngineFormsProps
> = (props) => {
  //const loadEvaluationForms = props.evaluationStep === EvaluationStep.FinalReview;
  const [applicationFormInitiaized, setApplicationFormInitiaized] =
    useState<boolean>();
  // const [
  //   roofFieldEvaluationFormInitiaized,
  //   setRoofFieldEvaluationFormInitiaized,
  // ] = useState(!loadEvaluationForms);
  // const [
  //   homeExteriorFieldEvaluationFormInitiaized,
  //   setHomeExteriorFieldEvaluationFormInitiaized,
  // ] = useState(!loadEvaluationForms);
  // const [
  //   landscapeFieldEvaluationFormInitiaized,
  //   setLandscapeFieldEvaluationFormInitiaized,
  // ] = useState(!loadEvaluationForms);
  // const [
  //   otherFieldEvaluationFormInitiaized,
  //   setOtherFieldEvaluationFormInitiaized,
  // ] = useState(!loadEvaluationForms);
  const [allFormsInitialized, setAllFormsInitialized] = useState(false);

  useEffect(() => {
    const formsInitialized = applicationFormInitiaized;
    // &&
    // roofFieldEvaluationFormInitiaized &&
    // homeExteriorFieldEvaluationFormInitiaized &&
    // landscapeFieldEvaluationFormInitiaized &&
    // otherFieldEvaluationFormInitiaized;

    if (formsInitialized) {
      setTimeout(() => {
        setAllFormsInitialized(true);
        props.onAllFormsInitialized();
      }, 600);
    } else {
      setAllFormsInitialized(false);
    }
  }, [
    applicationFormInitiaized,
    // roofFieldEvaluationFormInitiaized,
    // homeExteriorFieldEvaluationFormInitiaized,
    // landscapeFieldEvaluationFormInitiaized,
    // otherFieldEvaluationFormInitiaized,
  ]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  return (
    <>
      {!allFormsInitialized && <Loading />}
      <div
        className={classNames(props.fieldsContainerClassName, {
          "evaluation-forms-container-initialized": allFormsInitialized,
        })}
      >
        <PrimaryCard headerContent="Home SCSH Basic Information Form">
          <HomeSCSHEvaluationBasicInformationForm
            evaluationId={props.evaluationId}
            isIterationEnginePage={true}
            hideFormControls={true}
            onInitialized={() => {
              setApplicationFormInitiaized(true);
            }}
            onFormSave={async (fieldKey) => {
              props.onFormSave(fieldKey);
            }}
            setHasUnsavedChanges={setHasUnsavedChanges}
          />
        </PrimaryCard>
      </div>
    </>
  );
};

export default HomeSCSHIterationEngineForms;
