import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  Intent,
  RadioGroup,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { evaluationStore } from "../../stores/EvaluationStore";
import { BaseDialogProps } from "./types";
import { useNavigate } from "react-router-dom";
import { MinimalSelectableLabel } from "../Fields/SelectableLabel/MinimalSelectableLabel";
import { useProductTypeHandler } from "../../providers/ProductTypeHandlerProvider";

export interface ApplyPaymentCreditDialogProps extends BaseDialogProps {}

export const ApplyPaymentCreditDialog: React.FC<
  ApplyPaymentCreditDialogProps
> = ({ isOpen, closeDialog, submitDialog }) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = useState<"credit" | "card">(
    "credit"
  );

  const navigate = useNavigate();
  const { routePrefix } = useProductTypeHandler();

  return (
    <Dialog isOpen={isOpen} hasBackdrop={false}>
      <div className={Classes.DIALOG_HEADER}>Select Payment Method</div>
      <div className={Classes.DIALOG_BODY}>
        <form
          id="apply-payment-credit-form"
          onSubmit={async (e) => {
            e.preventDefault();

            setIsSaving(true);

            switch (paymentMethod) {
              case "card": {
                if (evaluationStore.paymentLink) {
                  window.location.href = evaluationStore.paymentLink;
                }

                break;
              }
              case "credit": {
                await evaluationStore
                  .applyPaymentCredit()
                  .then(() => {
                    submitDialog();
                  })
                  .finally(() => {
                    setIsSaving(false);
                    navigate(`${routePrefix}`);
                  });

                break;
              }
              default: {
                setIsSaving(false);

                break;
              }
            }
          }}
        >
          <FormGroup className="base-field">
            <MinimalSelectableLabel label={"How would you like to pay?"} />
            <RadioGroup
              onChange={(e) => {
                setPaymentMethod(e.currentTarget.value as "credit" | "card");
              }}
              selectedValue={paymentMethod}
              options={[
                { label: "IBHS Credits", value: "credit" },
                { label: "Credit or Debit Card", value: "card" },
              ].map((option, idx) => ({
                ...option,
                key: idx,
              }))}
            />
          </FormGroup>
        </form>
        <div>
          * After payment is submitted, this Evaluation will be automatically
          sent to IBHS for review.
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text={"Cancel"}
          onClick={closeDialog}
        />
        <Button
          intent={Intent.SUCCESS}
          text={paymentMethod === "credit" ? "Apply Credits" : "Pay"}
          type="submit"
          form="apply-payment-credit-form"
          loading={isSaving}
        />
      </div>
    </Dialog>
  );
};
