import baseApiClient from "../baseApiClient";
import { UserReadDTO } from "../user/types";

export interface AdminEvaluationAddressReadDTO {
  street?: string;
  street2?: string;
  city?: string;
  state?: number;
  zip?: string;
  county?: string;
}

export interface AdminEvaluationReadDTO {
  step: number;
  designationLevel: number;
  hazard: number;
  evaluatorId: string;
  auditorId: string;
  buildingAddress: AdminEvaluationAddressReadDTO;
  isNewRoof: boolean;
  expiresAt?: string;
  approvedAt?: string;
}

export interface AdminEvaluationUpdateDTO {
  step: number;
  designationLevel: number;
  hazard: number;
  evaluatorId: string;
  auditorId: string;
  buildingAddress: AdminEvaluationAddressReadDTO;
  isNewRoof: boolean;
  expiresAt?: string;
  approvedAt?: string;
}

class AdminApiClient {
  getAdminEvaluation = async (evaluationId: string) => {
    return baseApiClient.get<AdminEvaluationReadDTO>(
      `admin/evaluation/${evaluationId}`
    );
  };

  updateAdminEvaluation = async (
    evaluationId: string,
    dto: AdminEvaluationUpdateDTO
  ) => {
    return baseApiClient.put(`admin/evaluation/${evaluationId}`, dto);
  };

  getEvaluators = async () => {
    return baseApiClient.get<UserReadDTO[]>(`admin/home-evaluators`);
  };

  getAuditors = async () => {
    return baseApiClient.get<UserReadDTO[]>(`admin/home-auditors`);
  };
}

export const adminApiClient = new AdminApiClient();
