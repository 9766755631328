/* eslint-disable react/display-name */
import {
  FieldFormV2,
  FieldFormV2Module,
  FieldSchemaFormBuilderV2,
} from "@ucl/library";
import { NonIdealState } from "@blueprintjs/core";
import useCommercialApplicationForm from "./useCommercialBuildingApplicationForm";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { Evaluation_CommercialEvaluationFormModel } from "../../../../commercial/types/CommercialEvaluationFormModel";
import { forwardRef } from "react";

export interface CommercialBuildingApplicationFormProps {
  evaluationId: string;
  isIterationEnginePage?: boolean;
  onFormSave?: (fieldKey: string, value: any) => Promise<void>;
  setFormModel?: (formModel: Evaluation_CommercialEvaluationFormModel) => void;
  disableForm?: boolean;
  onInitialized?: () => void;
  setHasUnsavedChanges: (value: boolean) => void;
  hideFormControls?: boolean;
  updateChildFormError: (formId: string, hasError: boolean) => void;
  updateChildRequiredFieldCount?: (childId: string, count: number) => void;
}

export const CommercialBuildingApplicationForm = forwardRef<
  FieldFormV2Module<Evaluation_CommercialEvaluationFormModel>,
  CommercialBuildingApplicationFormProps
>((props, ref) => {
  const {
    formRef,
    isLoading,
    isSubmitting,
    applicationFormModel,
    getFormBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
  } = useCommercialApplicationForm(props);

  return (
    <div ref={containerRef} className="wildfire-form-container">
      {noBuilders || isLoading ? (
        <Loading />
      ) : !applicationFormModel ? (
        <NonIdealState />
      ) : (
        <>
          <FieldFormV2<Evaluation_CommercialEvaluationFormModel>
            isDisabled={isSubmitting || props.disableForm}
            value={applicationFormModel}
            fieldSchemaFormBuilder={
              getFormBuilder() as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>
            }
            onFormSubmit={async (form) => {
              await handleFormSubmit(form);
            }}
            ref={ref ? ref : formRef}
            onFieldChanged={async (form, value, fieldProps) => {
              await onFormFieldChange(form, value, fieldProps);
            }}
          />
        </>
      )}
    </div>
  );
});

export default CommercialBuildingApplicationForm;

(
  CommercialBuildingApplicationForm as React.ForwardRefExoticComponent<CommercialBuildingApplicationFormProps>
).displayName = "CommercialBuildingApplicationForm";
