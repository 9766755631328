import { FieldFormV2Module } from "@ucl/library";
import { useFCFMFFieldSchemaFactory } from "../../../../../common/customHooks/useFCFMFFieldSchemaFactory";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../../../../common/Components/Error/ErrorStore";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../../../types/MultifamilyEvaluationFormModel";
import { multifamilyEvaluationAPIClient } from "../../../../lib/apiClients/multifamilyEvaluationAPIClient";
import { MultifamilyComplianceLetterProps } from "./MultifamilyComplianceLetter";
import { multifamilyEvaluationComplianceLetterAPIClient } from "../../../../lib/apiClients/multifamilyEvaluationComplianceLetterAPIClient";

function useMultifamilyComplianceLetter(
  props: MultifamilyComplianceLetterProps
) {
  const { builders, wieBuilders } = useFCFMFFieldSchemaFactory();

  const formRef =
    useRef<FieldFormV2Module<Evaluation_MultifamilyEvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [complianceLetterModel, setComplianceLetterModel] =
    useState<Evaluation_MultifamilyEvaluationFormModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (complianceLetterModel) {
      setComplianceLetterModel({ ...complianceLetterModel });
    }
  }, [wieBuilders]);

  useEffect(() => {
    fetchComplianceLetter();
  }, []);

  useEffect(() => {
    if (!!complianceLetterModel) {
      setComplianceLetterModel(complianceLetterModel);

      if (props.setFormModel) {
        props.setFormModel(complianceLetterModel);
      }
    }
  }, [complianceLetterModel]);

  // Differentiates between save and submit
  let shouldSubmit = false;
  const setShouldSubmit = (value: boolean) => {
    shouldSubmit = value;
  };

  const noBuilders = !wieBuilders && !builders;

  const fetchComplianceLetter = async () => {
    setIsLoading(true);
    await multifamilyEvaluationAPIClient
      .getMultifamilyEvaluationFormModel(props.evaluationId)
      .then((response) => {
        setComplianceLetterModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: Evaluation_MultifamilyEvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormSubmit(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: Evaluation_MultifamilyEvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getComplianceLetterPartBuilder = () => {
    return wieBuilders?.MultifamilyComplianceLetter; // returns the actual UI elements to be rendered on the form.
  };

  const handleFormSubmit = async (
    form: Evaluation_MultifamilyEvaluationFormModel,
    fieldKey?: string
  ) => {
    setComplianceLetterModel(form);
    await (shouldSubmit
      ? multifamilyEvaluationComplianceLetterAPIClient.submitMultifamilyComplianceLetter(
          form.id,
          form.multifamilyEvaluation.multifamilyEvaluationComplianceLetterForm,
          props.formPart
        )
      : multifamilyEvaluationComplianceLetterAPIClient.updateMultifamilyComplianceLetter(
          form.id,
          form.multifamilyEvaluation.multifamilyEvaluationComplianceLetterForm,
          fieldKey
        )
    )
      .then(async (response) => {
        handleFormResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = async (
    response: Evaluation_MultifamilyEvaluationFormModel
  ) => {
    props.setHasUnsavedChanges(false);
    handlePartialResponseFormUpdate(response);
  };

  const handlePartialResponseFormUpdate = (
    response: Evaluation_MultifamilyEvaluationFormModel
  ) => {
    //This is used to combat save on blur issues. This only updates fields that are essential to the form.
    //These are set in the API response. Field Counts and sub section completion status
    setComplianceLetterModel((prevState) => {
      if (!prevState) return prevState;

      const {
        complianceLetterPhotoUploader,
        isFormComplete: isComplianceLetterComplete,
        multifamilyEvaluationComplianceLetterRequiredFieldcount,
      } = response.multifamilyEvaluation
        .multifamilyEvaluationComplianceLetterForm;

      const { canProceedToApplicationFee } = response.multifamilyEvaluation;

      return {
        ...prevState,
        multifamilyEvaluation: {
          ...prevState.multifamilyEvaluation,
          canProceedToApplicationFee,
          multifamilyEvaluationComplianceLetterForm: {
            ...prevState.multifamilyEvaluation
              .multifamilyEvaluationComplianceLetterForm,
            complianceLetterPhotoUploader,
            isComplianceLetterComplete,
            multifamilyEvaluationComplianceLetterRequiredFieldcount,
          },
        },
      };
    });
  };

  return {
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    complianceLetterModel,
    getComplianceLetterPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
    setShouldSubmit,
  };
}

export default useMultifamilyComplianceLetter;
