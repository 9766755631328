import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import React from "react";
import { reactPlugin } from "../../AppInsights";
import { PageSidebar } from "../components/PageSidebar/PageSidebar";
import "./styles.scss";
import { HelpCenterComponent } from "./HelpCenterPage";
import { Header } from "../components/Header/Header";

export const HelpPageComponent: React.FC = () => {
  return (
    <div className="fortified-page board-page">
      <Observer>{() => <Header />}</Observer>
      <div className="content">
        <PageSidebar activePage="help" />
        <div className="page-main-content">
          <HelpCenterComponent />
        </div>
      </div>
    </div>
  );
};

export const HelpPage = withAITracking(
  reactPlugin,
  HelpPageComponent,
  "HelpPage",
  "tracking-container"
);
