import React, { createContext, useEffect, useState } from "react";
import { useBlocker } from "react-router";
import { openWildfireUnsavedChangesDialog } from "../Dialogs/UnsavedChanges/UnsavedChangesDialogBuilder";

interface UnsavedChangesBlockerContextProps {
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}

const defaultValue: UnsavedChangesBlockerContextProps = {
  hasUnsavedChanges: false,
  setHasUnsavedChanges: (): void => {
    throw new Error(`Function not implemented.`);
  },
};

export const UnsavedChangesBlockerContext =
  createContext<UnsavedChangesBlockerContextProps>(defaultValue);

export const UnSavedChangesBlockerProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  const blocker = useBlocker(hasUnsavedChanges);

  useEffect(() => {
    if (hasUnsavedChanges && blocker.state === "blocked") {
      openWildfireUnsavedChangesDialog(blocker.location.pathname);
    }
  }, [blocker.state]);

  return (
    <UnsavedChangesBlockerContext.Provider
      value={{ hasUnsavedChanges, setHasUnsavedChanges }}
    >
      {children}
    </UnsavedChangesBlockerContext.Provider>
  );
};
