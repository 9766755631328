import { Intent } from "@blueprintjs/core";
import { FieldFormV2Module } from "@ucl/library";
import { useFieldSchemaFactory } from "../../../customHooks/useFieldSchemaFactory";
import { RoofFieldEvaluationFormParts } from "../../../forms/RoofFieldEvaluationForm/RoofFieldEvaluationFormModel";
import { RoofFieldEvaluationFormProps } from "./RoofFieldEvaluationForm";
import { useCallback, useEffect, useRef, useState } from "react";
import { wildfireEvaluationApiClient } from "../../../lib/apiClients/application/wildfireApplicationApiClient";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../common/Components/Error/ErrorStore";
import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { useNavigate } from "react-router";
import { EvaluationFormModel } from "../../../forms/EvaluationFormModel";
import { HomeExteriorFieldEvaluationFormParts } from "../../../forms/HomeExteriorFieldEvaluationForm/HomeExteriorFieldEvaluationFormModel";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { getScrollContainer } from "../../../utils/getScrollContainer";

export function useRoofFieldEvaluationForm(
  props: RoofFieldEvaluationFormProps
) {
  const navigate = useNavigate();

  const { builders, wieBuilders } = useFieldSchemaFactory();
  const formRef = useRef<FieldFormV2Module<EvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!props.isWiePage && !!containerRef.current) {
      const scrollableParent = getScrollContainer(containerRef.current);

      scrollableParent?.scrollTo(0, 0);
    }
  }, [props.formPart]);

  const [roofFieldEvaluationFormModel, setRoofFieldEvaluationFormModel] =
    useState<EvaluationFormModel>();

  // Differentiates between save and submit
  let shouldSubmit = false;
  const setShouldSubmit = (value: boolean) => {
    shouldSubmit = value;
  };

  useEffect(() => {
    if (roofFieldEvaluationFormModel) {
      setRoofFieldEvaluationFormModel({ ...roofFieldEvaluationFormModel });
    }
  }, [wieBuilders]);

  const isFirstPartOfForm =
    props.formPart === Object.values(RoofFieldEvaluationFormParts)[0];

  const isLastPartOfForm =
    props.formPart === Object.values(RoofFieldEvaluationFormParts).pop();

  const fetchRoofFieldEvaluationForm = async () => {
    setIsLoading(true);
    await wildfireEvaluationApiClient
      .getRoofFieldEvaluationForm(props.wildfireEvaluationId)
      .then((response) => {
        setRoofFieldEvaluationFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!!props.formModel) {
      setRoofFieldEvaluationFormModel(props.formModel);
      setIsLoading(false);
    } else {
      fetchRoofFieldEvaluationForm();
    }
  }, [props.formModel]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (!!roofFieldEvaluationFormModel) {
      setRoofFieldEvaluationFormModel(roofFieldEvaluationFormModel);

      if (props.setFormModel) {
        props.setFormModel(roofFieldEvaluationFormModel);
      }
    }
  }, [roofFieldEvaluationFormModel]);

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: EvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormSubmit(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: EvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getRoofFieldEvaluationFormPartBuilder = () => {
    if (props.isWiePage) {
      return wieBuilders?.RoofFieldEvaluationForm;
    }
    switch (props.formPart) {
      case RoofFieldEvaluationFormParts.CoverMaterial:
        return builders?.RoofFieldEvaluationFormCoverMaterialPart;
      case RoofFieldEvaluationFormParts.OtherRoofCoverConditions:
        return builders?.RoofFieldEvaluationFormOtherRoofCoverConditionsPart;
      case RoofFieldEvaluationFormParts.GuttersAndDownspouts:
        return builders?.RoofFieldEvaluationFormGuttersAndDownspoutsPart;
      case RoofFieldEvaluationFormParts.EavesAndSoffits:
        return builders?.RoofFieldEvaluationFormEavesAndSoffitsPart;
      case RoofFieldEvaluationFormParts.Vents:
        return builders?.RoofFieldEvaluationFormVentsPart;
      default:
        return builders?.RoofFieldEvaluationForm;
    }
  };

  const handleFormSubmit = async (
    form: EvaluationFormModel,
    fieldKey?: string
  ) => {
    setRoofFieldEvaluationFormModel(form);

    await (shouldSubmit
      ? wildfireEvaluationApiClient.submitRoofFieldEvaluationForm(
          form.wildfireApplication.id,
          form.wildfireApplication.roofFieldEvaluationForm,
          props.formPart
        )
      : wildfireEvaluationApiClient.updateRoofFieldEvaluationForm(
          form.wildfireApplication.id,
          form.wildfireApplication.roofFieldEvaluationForm,
          fieldKey
        )
    )
      .then(async (response) => {
        handleFormResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = (response: EvaluationFormModel) => {
    props.setHasUnsavedChanges(false);
    if (shouldSubmit) {
      setRoofFieldEvaluationFormModel(response);
      if (isLastPartOfForm || !props.formPart) {
        // Navigate to first part of next form
        navigate(
          `${wildfireRoutePrefix}/${
            props.wildfireEvaluationId
          }/home-exterior-field-evaluation-form/${Object.values(
            HomeExteriorFieldEvaluationFormParts
          )[0].valueOf()}`
        );
      } else {
        // Navigate to next part of form
        navigate(
          `${wildfireRoutePrefix}/${
            props.wildfireEvaluationId
          }/roof-field-evaluation-form/${Object.values(
            RoofFieldEvaluationFormParts
          )[
            Object.values(RoofFieldEvaluationFormParts).indexOf(
              props.formPart
            ) + 1
          ].valueOf()}`
        );
      }
    } else {
      handlePartialResponseFormUpdate(response);
    }
  };

  const handlePartialResponseFormUpdate = (response: EvaluationFormModel) => {
    //This is used to combat save on blur issues. This only updates fields that are essetnial to the form.
    //These are set in the API response. Field Counts and sub section completion status
    setRoofFieldEvaluationFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        ventsRequiredFieldCount,
        eavesAndSoffitsRequiredFieldCount,
        guttersAndDownspoutsRequiredFieldCount,
        otherRoofCoverConditionsRequiredFieldCount,
        coverMaterialRequiredFieldCount,
        submitted,
        coverMaterialSubmitted,
        otherRoofCoverConditionsSubmitted,
        guttersAndDownspoutsSubmitted,
        eavesAndSoffitsSubmitted,
        ventsSubmitted,
        ventsPhotoFolder,
        eavesAndSoffitsPhotoFolder,
        guttersAndDownspoutsPhotoFolder,
        roofCoverMaterialPhotoFolder,
        isCoverMaterialComplete,
        isOtherRoofCoverConditionsComplete,
        isGuttersAndDownspoutsComplete,
        isEavesAndSoffitsComplete,
        isVentsComplete,
      } = response.wildfireApplication.roofFieldEvaluationForm;

      return {
        ...prevState,
        wildfireApplication: {
          ...prevState.wildfireApplication,
          roofFieldEvaluationForm: {
            ...prevState.wildfireApplication.roofFieldEvaluationForm,
            ventsRequiredFieldCount,
            eavesAndSoffitsRequiredFieldCount,
            guttersAndDownspoutsRequiredFieldCount,
            otherRoofCoverConditionsRequiredFieldCount,
            coverMaterialRequiredFieldCount,
            submitted,
            coverMaterialSubmitted,
            otherRoofCoverConditionsSubmitted,
            guttersAndDownspoutsSubmitted,
            eavesAndSoffitsSubmitted,
            ventsSubmitted,
            ventsPhotoFolder,
            eavesAndSoffitsPhotoFolder,
            guttersAndDownspoutsPhotoFolder,
            roofCoverMaterialPhotoFolder,
            isCoverMaterialComplete,
            isOtherRoofCoverConditionsComplete,
            isGuttersAndDownspoutsComplete,
            isEavesAndSoffitsComplete,
            isVentsComplete,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    roofFieldEvaluationFormModel,
    setShouldSubmit,
    isFirstPartOfForm,
    isLastPartOfForm,
    getRoofFieldEvaluationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    isInitialized,
  };
}

export default useRoofFieldEvaluationForm;
