import { GridColumn } from "@ucl/library";
import { UserRoleType } from "../../../../../common/Components/User/userTypes";
import WildfireAuditorGridClickableIconColumn from "../gridColumn/WildfireAuditorGridClickableIconColumn";
import {
  defaultFields,
  wildfireAdmin_AllFields,
  wildfireAdmin_DefaultFields,
  wildfireApplicantFields,
  wildfireEvaluatorFields,
} from "./wildfireEvaluationGridColumnTypes";
import WildfireCommentsGridClickableIconColumn from "../gridColumn/WildfireCommentsGridClickableIconColumn";
import GridColoredColumn from "../gridColumn/GridColoredColumn";
import WildfireCertificateGridClickableIconColumn from "../gridColumn/WildfireCertificateGridClickableIconColumn";

// Wildfire Board Grid Column Configuration
export const gridColumnsConfiguration = () => {
  const wildfireEvaluationColumnsConfiguration: GridColumn[] = [
    new WildfireCommentsGridClickableIconColumn({
      field: "externalUnreadCommentCount",
      headerText: "Comments",
      type: "number",
      width: 110,
      externalUnreadCommentCount: "externalUnreadCommentCount",
      allowFiltering: false,
      allowSearching: false,
    }),
    {
      field: "lastUnreadCommentDate",
      headerText: "Last Unread Comment Date/Time",
      type: "datetime",
      format: { format: "MM/dd/yyyy hh:mm:ss a", type: "datetime" },
    } as GridColumn,
    {
      field: "id",
      headerText: "GUID",
      type: "string",
      visible: true,
    } as GridColumn,
    {
      field: "fortifiedId",
      headerText: "Wildfire ID",
      type: "string",
      visible: true,
    } as GridColumn,
    new WildfireAuditorGridClickableIconColumn({
      field: "auditorNotes",
      headerText: "Auditor Notes",
      type: "string",
      columnName: "auditorNotes",
      icon: "edit",
      width: 150,
    }),
    {
      field: "productType_AsString",
      headerText: "Product",
      type: "string",
    } as GridColumn,
    new GridColoredColumn<string>({
      field: "status_AsString",
      headerText: "Status",
      type: "string",
      shouldUpdateFieldColor: (status: string) => status === "Closed",
      color: "Red",
      fieldToColor: "status_AsString",
      formatField: (value: string) => value,
    }),
    new GridColoredColumn<string>({
      field: "previousStatus_AsString",
      headerText: "Previous Status",
      type: "string",
      shouldUpdateFieldColor: (status: string) => status === "Closed",
      color: "Red",
      fieldToColor: "previousStatus_AsString",
      formatField: (value: string) => value,
    }),
    {
      field: "statusUpdatedAt",
      headerText: "Status Updated Date/Time",
      type: "datetime",
      format: { format: "MM/dd/yyyy hh:mm:ss a", type: "datetime" },
    } as GridColumn,
    {
      field: "daysSinceStatusUpdated",
      headerText: "Days Since Status Updated",
      type: "number",
    } as GridColumn,
    {
      field: "totalAmountPaid",
      headerText: "Total Paid",
      type: "number",
      format: { format: "C2", currency: "en-US" },
    } as GridColumn,
    {
      field: "buildingAddress",
      headerText: "Home Building Address",
      type: "string",
    } as GridColumn,
    {
      field: "buildingAddress2",
      headerText: "Home Building Address 2",
      type: "string",
    } as GridColumn,
    {
      field: "buildingCity",
      headerText: "Home Address City",
      type: "string",
    } as GridColumn,
    {
      field: "buildingState_AsString",
      headerText: "Home Address State",
      type: "string",
    } as GridColumn,
    {
      field: "buildingZip",
      headerText: "Home Address Zip",
      type: "string",
    } as GridColumn,
    {
      field: "buildingCounty",
      headerText: "Home Address County",
      type: "string",
    } as GridColumn,
    {
      field: "wildfire_HasGate",
      headerText: "Has Gate",
      type: "string",
    } as GridColumn,
    {
      field: "wildfire_NeedsAppointment",
      headerText: "Needs Appointment",
      type: "string",
    } as GridColumn,
    {
      field: "wildfire_HomeServiceOption",
      headerText: "Home Service Option",
      type: "string",
    } as GridColumn,
    {
      field: "homeOwnerFirstName",
      headerText: "Homeowner First Name",
      type: "string",
    } as GridColumn,
    {
      field: "homeOwnerLastName",
      headerText: "Homeowner Last Name",
      type: "string",
    } as GridColumn,
    {
      field: "homeOwnerEmail",
      headerText: "Homeowner Email",
      type: "string",
    } as GridColumn,
    {
      field: "homeOwnerPhone",
      headerText: "Homeowner Phone",
      type: "string",
    } as GridColumn,
    {
      field: "wildfire_CoOwnerFirstName",
      headerText: "Co-Owner First Name",
      type: "string",
    } as GridColumn,
    {
      field: "wildfire_CoOwnerLastName",
      headerText: "Co-Owner Last Name",
      type: "string",
    } as GridColumn,
    {
      field: "wildfire_CoOwnerEmail",
      headerText: "Co-Owner Email",
      type: "string",
    } as GridColumn,
    {
      field: "wildfire_CoOwnerPhone",
      headerText: "Co-Owner Phone",
      type: "string",
    } as GridColumn,
    {
      field: "wildfire_PaymentDate",
      headerText: "Payment Date",
      type: "datetime",
      format: { format: "MM/dd/yyyy", type: "date" },
    } as GridColumn,
    {
      field: "evaluationCompany",
      headerText: "Evaluation Company",
      type: "string",
    } as GridColumn,
    {
      field: "wildfire_EvaluationPartnerSentDate",
      headerText: "Evaluation Partner Sent Date",
      type: "datetime",
      format: { format: "MM/dd/yyyy", type: "date" },
    } as GridColumn,
    {
      field: "wildfire_EvaluationPartnerInspectionDate",
      headerText: "Evaluation Partner Inspection Date",
      type: "datetime",
      format: { format: "MM/dd/yyyy", type: "date" },
    } as GridColumn,
    {
      field: "wildfire_EvaluationPartnerReceivedDate",
      headerText: "Evaluation Partner Received Date",
      type: "datetime",
      format: { format: "MM/dd/yyyy", type: "date" },
    } as GridColumn,
    {
      field: "wildfire_DesignationLevel",
      headerText: "Designation Level",
      type: "string",
    } as GridColumn,
    new WildfireCertificateGridClickableIconColumn({
      field: "downloadCertificate",
      headerText: "Certificate",
      type: "string",
      icon: "download",
      width: 100,
      allowFiltering: false,
      allowSearching: false,
      allowSorting: false,
    }),
    {
      field: "submittedDate",
      headerText: "Submitted Date",
      type: "datetime",
      format: { format: "MM/dd/yyyy", type: "date" },
    } as GridColumn,
    {
      field: "approvedAt",
      headerText: "Approval Date",
      type: "datetime",
      format: { format: "MM/dd/yyyy", type: "date" },
    } as GridColumn,
    {
      field: "expiresAt",
      headerText: "Expiration Date",
      type: "datetime",
      format: { format: "MM/dd/yyyy", type: "date" },
    } as GridColumn,
    {
      field: "rejectionReasons_AsCSV",
      headerText: "Rejection Reasons",
      width: 140,
      type: "string",
    } as GridColumn,
    {
      field: "wildfire_EvaluationPhase_AsString",
      headerText: "Evaluation Phase",
      type: "string",
    } as GridColumn,
    {
      field: "createdAt_AsDateOnly",
      headerText: "Created Date",
      type: "datetime",
      format: { format: "MM/dd/yyyy", type: "date" },
    } as GridColumn,
    {
      field: "createdAt",
      headerText: "Created Date/Time",
      type: "datetime",
      format: { format: "MM/dd/yyyy hh:mm:ss a", type: "datetime" },
    } as GridColumn,
    {
      field: "modifiedAt_AsDateOnly",
      headerText: "Updated Date",
      type: "datetime",
      format: { format: "MM/dd/yyyy", type: "date" },
    } as GridColumn,
    {
      field: "modifiedAt",
      headerText: "Updated Date/Time",
      type: "datetime",
      format: { format: "MM/dd/yyyy hh:mm:ss a", type: "datetime" },
    } as GridColumn,
    new GridColumn({
      field: "createdBy_AsName",
      headerText: "Created By",
      type: "string",
    }),
    new GridColumn({
      field: "modifiedBy_AsName",
      headerText: "Updated By",
      type: "string",
    }),
    {
      field: "auditor_AsFullName",
      headerText: "Auditor",
      type: "string",
    } as GridColumn,
    {
      field: "auditStartDate",
      headerText: "Audit Start Date",
      type: "datetime",
      format: { format: "MM/dd/yyyy", type: "datetime" },
    } as GridColumn,
    {
      field: "auditEndDate",
      headerText: "Audit End Date",
      type: "datetime",
      format: { format: "MM/dd/yyyy", type: "datetime" },
    } as GridColumn,
    {
      field: "isWithdrawn",
      headerText: "Is Closed",
      type: "boolean",
    } as GridColumn,
    {
      field: "withdrawnDate",
      headerText: "Closed Date",
      type: "datetime",
      format: { format: "MM/dd/yyyy", type: "datetime" },
    } as GridColumn,
    {
      field: "withdrawnBy",
      headerText: "Closed By",
      type: "string",
    } as GridColumn,
    {
      field: "withdrawnBy_AsName",
      headerText: "Closed By Name",
      type: "string",
    } as GridColumn,
    {
      field: "withdrawnReason",
      headerText: "Closed Reason",
      type: "string",
    } as GridColumn,
    {
      field: "reactivateDate",
      headerText: "Reactivate Date",
      type: "datetime",
      format: { format: "MM/dd/yyyy", type: "datetime" },
    } as GridColumn,
    {
      field: "wildfire_HasSecondaryContact",
      headerText: "Has Secondary Contact",
      type: "string",
    } as GridColumn,
    {
      field: "wildfire_SecondaryContactFirstName",
      headerText: "Secondary Contact First Name",
      type: "string",
    } as GridColumn,
    {
      field: "wildfire_SecondaryContactLastName",
      headerText: "Secondary Contact Last Name",
      type: "string",
    } as GridColumn,
    {
      field: "wildfire_SecondaryContactEmail",
      headerText: "Secondary Contact Email",
      type: "string",
    } as GridColumn,
    {
      field: "wildfire_SecondaryContactPhoneNumber",
      headerText: "Secondary Contact Phone Number",
      type: "string",
    } as GridColumn,
    {
      field: "wildfire_HasHotTub",
      headerText: "Has Hot Tub",
      type: "string",
    } as GridColumn,
    {
      field: "wildfire_HasStationaryPropaneTank",
      headerText: "Has Stationary Propane Tank",
      type: "string",
    } as GridColumn,
    {
      field: "lastChangeRequestAddressedAtDate_AsDateOnly",
      headerText: "Last Change Addressed Date",
      type: "datetime",
      format: { format: "MM/dd/yyyy", type: "date" },
    } as GridColumn,
    {
      field: "lastChangeRequestAddressedAtDate",
      headerText: "Last Change Addressed Date/Time",
      type: "datetime",
      format: { format: "MM/dd/yyyy hh:mm:ss a", type: "datetime" },
    } as GridColumn,
    {
      field: "newChangeRequestCount",
      headerText: "Open Change Requests",
      type: "number",
    } as GridColumn,
    {
      field: "daysInChangesRequired",
      headerText: "Days In Changes Required",
      type: "number",
    } as GridColumn,
    {
      field: "addressedChangeRequestCount",
      headerText: "Addressed Change Requests",
      type: "number",
    } as GridColumn,
    {
      field: "isInIBHSReview",
      headerText: "Is In IBHS Review",
      type: "boolean",
      width: 130,
    } as GridColumn,
    {
      field: "isInIBHSEligibilityReview",
      headerText: "Is In IBHS Eligibility Review",
      type: "boolean",
      width: 130,
    } as GridColumn,
  ];

  return wildfireEvaluationColumnsConfiguration;
};

export const allGridColumnsConfigurationByRole: Record<
  UserRoleType,
  GridColumn[]
> = {
  "FC/FMF Applicant": [],
  "FC/FMF Evaluator": [],
  "FC/FMF Auditor": [],
  "FH Evaluator": [],
  "FH Auditor": [],
  "FH Roofer": [],
  "FH Direct Submit Roofer": [],
  "FH Engineer": [],
  "FH Contractor": [],
  "IBHS Admin": [],
  "IBHS Partner": [],
  "FH Evaluator Admin": [],
  "Relativity Admin": [],
  "NCIUA Partner": [],
  "FC/FMF Admin": [],
  "FH Auditor Trainee": [],
  "FH Applicant": [],
  "WFP Applicant": [
    ...gridColumnsConfiguration()
      .filter((x) => wildfireApplicantFields.includes(x.field))
      .sort(function (a, b) {
        return (
          wildfireApplicantFields.indexOf(a.field) -
          wildfireApplicantFields.indexOf(b.field)
        );
      }),
  ],
  "WFP Admin": [
    ...gridColumnsConfiguration()
      .filter((x) => wildfireAdmin_AllFields.includes(x.field))
      .sort(function (a, b) {
        return (
          wildfireAdmin_AllFields.indexOf(a.field) -
          wildfireAdmin_AllFields.indexOf(b.field)
        );
      }),
  ],
  "WFP Evaluator": [
    ...gridColumnsConfiguration()
      .filter((x) => wildfireEvaluatorFields.includes(x.field))
      .sort(function (a, b) {
        return (
          wildfireEvaluatorFields.indexOf(a.field) -
          wildfireEvaluatorFields.indexOf(b.field)
        );
      }),
  ],
  "WFP Auditor": [
    ...gridColumnsConfiguration()
      .filter((x) => wildfireAdmin_AllFields.includes(x.field))
      .sort(function (a, b) {
        return (
          wildfireAdmin_AllFields.indexOf(a.field) -
          wildfireAdmin_AllFields.indexOf(b.field)
        );
      }),
  ],
};

export const defaultGridColumnsConfigurationByRole: Record<
  UserRoleType,
  GridColumn[]
> = {
  "FC/FMF Applicant": [],
  "FC/FMF Evaluator": [],
  "FC/FMF Auditor": [],
  "FH Evaluator": [],
  "FH Auditor": [],
  "FH Roofer": [],
  "FH Direct Submit Roofer": [],
  "FH Engineer": [],
  "FH Contractor": [],
  "IBHS Admin": [],
  "IBHS Partner": [],
  "FH Evaluator Admin": [],
  "Relativity Admin": [],
  "NCIUA Partner": [],
  "FC/FMF Admin": [],
  "FH Auditor Trainee": [],
  "FH Applicant": [],
  "WFP Applicant": [
    ...gridColumnsConfiguration()
      .filter((x) => wildfireApplicantFields.includes(x.field))
      .sort(function (a, b) {
        return (
          wildfireApplicantFields.indexOf(a.field) -
          wildfireApplicantFields.indexOf(b.field)
        );
      }),
  ],
  "WFP Admin": [
    ...gridColumnsConfiguration()
      .filter((x) => wildfireAdmin_DefaultFields.includes(x.field))
      .sort(function (a, b) {
        return (
          wildfireAdmin_DefaultFields.indexOf(a.field) -
          wildfireAdmin_DefaultFields.indexOf(b.field)
        );
      }),
  ],
  "WFP Evaluator": [
    ...gridColumnsConfiguration()
      .filter((x) => wildfireEvaluatorFields.includes(x.field))
      .sort(function (a, b) {
        return (
          wildfireEvaluatorFields.indexOf(a.field) -
          wildfireEvaluatorFields.indexOf(b.field)
        );
      }),
  ],
  "WFP Auditor": [
    ...gridColumnsConfiguration()
      .filter((x) => wildfireAdmin_DefaultFields.includes(x.field))
      .sort(function (a, b) {
        return (
          wildfireAdmin_DefaultFields.indexOf(a.field) -
          wildfireAdmin_DefaultFields.indexOf(b.field)
        );
      }),
  ],
};

export const getAllGridColumnsConfigurationByRole = (
  role?: UserRoleType
): GridColumn[] => {
  if (!role) {
    return [
      ...gridColumnsConfiguration()
        .filter((x) => defaultFields.includes(x.field))
        .sort(function (a, b) {
          return (
            defaultFields.indexOf(a.field) - defaultFields.indexOf(b.field)
          );
        }),
    ];
  }

  return allGridColumnsConfigurationByRole[role];
};

export const getDefaultGridColumnsConfigurationByRole = (
  role?: UserRoleType
): GridColumn[] => {
  if (!role) {
    return [
      ...gridColumnsConfiguration()
        .filter((x) => defaultFields.includes(x.field))
        .sort(function (a, b) {
          return (
            defaultFields.indexOf(a.field) - defaultFields.indexOf(b.field)
          );
        }),
    ];
  }

  return defaultGridColumnsConfigurationByRole[role];
};
