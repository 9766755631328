import { FeatureToggleSet } from "../FeatureToggles";
import baseApiClient from "../../../../foritfied/lib/apiClients/baseApiClient";

class FeatureToggleApiClient {
  getFeatureToggleSet = async () => {
    return baseApiClient.get<FeatureToggleSet>("feature-toggle/toggle");
  };
}

//TODO - Convet this to Wildfire based toggle API client
export const featureToggleApiClient = new FeatureToggleApiClient();
