import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { Evaluation_CommercialEvaluationFormModel } from "../../../types/CommercialEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import get from "lodash/get";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

const modelName = "Evaluation";
const subModelName = "CommercialEvaluation";
const formName = "CommercialEvaluationComplianceForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const fileUploaderFields = (
  _form: Evaluation_CommercialEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("ComplianceFormPhotoUploader"),
    defaultToUpload: false,
    errorMessages: get(errors, "complianceFormPhotoUploader.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

const complianceFormPhotoUploaderRequiredFields = [
  buildFieldKey("ComplianceFormPhotoUploader"),
];

const complianceFormFields = () => [buildFieldKey("EvaluatorEmail")];

export const commercialComplianceFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    return factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
      modelName,
      [...complianceFormFields(), ...fileUploaderFields(form, errors)],
      onValueChange,
      form,
      errors,
      [...complianceFormPhotoUploaderRequiredFields],
      {
        FileUploader: WildfireBoxContentExplorer,
      }
    );
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};

export const commercialIterationEngineComplianceFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        [...complianceFormFields(), ...fileUploaderFields(form, errors)],
        onValueChange,
        form,
        errors,
        [...complianceFormPhotoUploaderRequiredFields],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};
