import { FormGroup, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Predicate, Query } from "@syncfusion/ej2-data";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import {
  AutoCompleteComponent,
  AutoCompleteModel,
} from "@syncfusion/ej2-react-dropdowns";
import classNames from "classnames";
import { has } from "lodash";
import React from "react";
import { FieldLabel } from "../../FieldLabel/FieldLabel";
import {
  BaseFieldProps,
  AsyncAutocompleteFieldValue,
} from "../../types/fieldTypes";
import { notInListValue } from "./AsyncAutoCompleteODataV4Adaptor";
import "./styles.scss";
import { useAsyncAutoCompleteField } from "./useAsyncAutoCompleteField";
import { FormFieldErrorContainer } from "../../FieldError/FormFieldErrorContainer";

export interface AsyncAutoCompleteQuerySettings {
  fieldNames: string[];
  takeValue?: number;
  predicate: Predicate;
}

export interface AsyncAutoCompleteFieldProps extends BaseFieldProps {
  apiBaseUrl: string;
  odataUrl: string;
  getAuthRequestHeader: () =>
    | Promise<Record<string, string> | undefined>
    | Promise<string | undefined>;
  autoCompleteSettings?: Omit<
    AutoCompleteModel,
    | "placeholder"
    | "value"
    | "disabled"
    | "select"
    | "focus"
    | "onClick"
    | "change"
    | "filtering"
  > &
    DefaultHtmlAttributes;
  fieldNames?: string | string[];
  searchBy?: (searchText: string) => Predicate;
  sortBy?: string;
  /** Use querySettings to allow an initial filter of more then one predicate */
  /** ...allows correct paranthesis grouping of OR statements */
  querySettings?: AsyncAutoCompleteQuerySettings;
  /** System not allows groupby if not in list option is set up */
  hasNotInListOption?: boolean;
  groupByActiveStatus?: boolean;
  value: AsyncAutocompleteFieldValue;
}

export const AsyncAutoCompleteField: React.FC<AsyncAutoCompleteFieldProps> = (
  props
) => {
  const {
    autoCompleteRef,
    ready,
    isLoading,
    setIsLoading,
    isDataLoaded,
    setIsDataLoaded,
    localValue,
    autoCompleteSettings,
    handleValueChange,
    buildDefaultQuery,
  } = useAsyncAutoCompleteField(props);

  return (
    <FormGroup
      className={classNames(
        `${
          props.isRequired && !localValue
            ? "has-required-background"
            : "async-autocomplete-field"
        }`
      )}
    >
      {props.label && (
        <FieldLabel
          labelName={props.label}
          tooltipDescription={props.description}
        />
      )}
      <div className="async-autocomplete-toolbar">
        {ready && autoCompleteSettings?.dataSource && (
          <div className="async-autocomplete-input-container">
            <AutoCompleteComponent
              ref={autoCompleteRef}
              id="async-autocomplete-search-list"
              className="async-autocomplete-input"
              onChange={(e: { value: AsyncAutocompleteFieldValue }) => {
                handleValueChange(e.value as string);
              }}
              readonly={props.disabled || isLoading}
              placeholder={isLoading ? "Loading..." : "Type to search..."}
              actionBegin={(args: { query?: Query }) => {
                /** Initital or Filtering */
                if (has(args, "query")) {
                  if (!isDataLoaded) {
                    /** Load Initial Value */
                    setIsLoading(true);

                    if (props.value && props.value !== notInListValue) {
                      const query = new Query().where(
                        new Predicate(
                          String(autoCompleteSettings.fields?.value),
                          "equal",
                          String(props.value),
                          true
                        )
                      );
                      args.query = query;
                    } else if (props.value === notInListValue) {
                      args.query = buildDefaultQuery();
                    }
                    setIsLoading(false);
                  }
                  /** Initital */
                } else {
                  /** Load */
                  (args as any).query =
                    props.autoCompleteSettings?.query || buildDefaultQuery();
                }
              }}
              dataBound={() => {
                setIsDataLoaded(true);
                setIsLoading(false);
              }}
              {...autoCompleteSettings}
            />
            <Icon icon={IconNames.Search} size={14} />
          </div>
        )}
      </div>
      {props.errorMessages && (
        <FormFieldErrorContainer errorMessages={props.errorMessages} />
      )}
    </FormGroup>
  );
};
