import { Label } from "@blueprintjs/core";
import { observer } from "mobx-react";
import "./styles.scss";

export interface MinimalSelectableLabelProps {
  label: string;
}

export const MinimalSelectableLabel: React.FC<MinimalSelectableLabelProps> =
  observer(({ label }) => {
    return (
      <Label className="field-label">
        <span className="label-title">{label}</span>
      </Label>
    );
  });
