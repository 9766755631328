import classNames from "classnames";
import {
  fortifiedHomeRoutePrefix,
  fortified_Home_browserRouter,
} from "../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { ProductTypeHandlerProvider } from "../../foritfied/providers/ProductTypeHandlerProvider";
import variables from "../config/variables";
import {
  fortifiedHomeLogout,
  fortifiedHomeMsalInstance,
  getFortifiedHomeAccessToken,
} from "../lib/msal/fortifiedHomeMsal";
import { wildfireRoutePrefix } from "../../wildfire/pages/index/wildfireRouteConfig";
import { fortifiedCommercialMultifamilyRoutePrefix } from "../../foritfied/pages/index/fortifiedCommercialMultifamilyRouteConfig";
import ProductTypeNavigator from "./ProductTypeNavigator";
import Pages from "../../foritfied/pages/index/index";
import WildfirePages from "../../wildfire/pages/index/index";
import { PermissionsProvider } from "@ucl/library";
import { permissionsApiClient } from "../apiClients/permissionsApiClient";
import { FCFMFProductTypeHandler } from "./FCFMFProductTypeHandler";

export const productTypeRouteConfig = [
  {
    pathPrefix: `${wildfireRoutePrefix}`,
    component: <WildfirePages />,
  },
  {
    pathPrefix: `${fortifiedHomeRoutePrefix}`,
    component: (
      <div
        className={classNames(
          "app-container",
          variables.theme ? `theme-${variables.theme}` : ""
        )}
      >
        <ProductTypeHandlerProvider
          routePrefix={fortifiedHomeRoutePrefix}
          getAccessToken={getFortifiedHomeAccessToken}
          msalInstance={fortifiedHomeMsalInstance}
          router={fortified_Home_browserRouter}
          logoutUser={fortifiedHomeLogout}
        >
          <Pages />
        </ProductTypeHandlerProvider>
      </div>
    ),
  },
  {
    pathPrefix: `${fortifiedCommercialMultifamilyRoutePrefix}`,
    component: (
      <div
        className={classNames(
          "app-container",
          variables.theme ? `theme-${variables.theme}` : ""
        )}
      >
        <PermissionsProvider
          getPermissions={permissionsApiClient.getPermissions}
        >
          <FCFMFProductTypeHandler />
        </PermissionsProvider>
      </div>
    ),
  },
  {
    pathPrefix: `/`,
    component: <ProductTypeNavigator />,
  },
];
