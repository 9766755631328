import { Schema } from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import wildfireBaseApiClient from "../../../../../../../wildfire/lib/wildfireBaseApiClient";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { HomeBasicInformationFormParts } from "../../../../../common/types/BaseHomeEvaluationBasicInformationForm";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeSCSHBasicInformationFormContainerProps } from "./HomeSCSHBasicInformationFormContainer";
import { Evaluation_HomeSCSHEvaluationFormModel } from "../../../../types/HomeSCSHEvaluationFormModel";

function useHomeSCSHBasicInformationFormContainer(
  props: HomeSCSHBasicInformationFormContainerProps
) {
  const [homeSchemaFactory, setHomeSchemaFactory] =
    useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireBaseApiClient.get<Schema>(`/wildfire/schema`);
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setHomeSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!homeSchemaFactory) {
      getSchema();
    }
    //return () => {
    //     setDefaultHeader();
    //   };
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.basicInformationFormPart]);

  // const { setWildfireEvaluationHeader, setDefaultHeader } =
  //   useContext(AppHeaderContext);
  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    HomeBasicInformationFormParts | undefined
  >(props.basicInformationFormPart);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_HomeSCSHEvaluationFormModel>();

  const basicInformationForm =
    evaluationFormModel?.homeSCSHEvaluation.basicInformationForm;

  const getNextUnsubmittedFormPart = () => {
    if (!basicInformationForm) {
      return null;
    }

    if (!basicInformationForm.isHomeownerInformationComplete) {
      return HomeBasicInformationFormParts.HomeownerInformation;
    } else if (!basicInformationForm.isPropertyDetailsComplete) {
      return HomeBasicInformationFormParts.PropertyDetails;
    } else if (!basicInformationForm.isConfirmationAffirmationsComplete) {
      return HomeBasicInformationFormParts.ConfirmationAffirmations;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart = (): HomeBasicInformationFormParts => {
    // Default to the first part of the form if we can't find a better option
    const defaultFormPart = Object.values(HomeBasicInformationFormParts)[0];

    // If the application form part is provided (by URL), use it
    if (!!props.basicInformationFormPart) {
      return props.basicInformationFormPart;
    }

    // Otherwise, try to find the first unsubmitted part of the form
    // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
    return getNextUnsubmittedFormPart() ?? defaultFormPart;
  };

  const getApplicationPartChecklistStatus = (
    formPart: HomeBasicInformationFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case HomeBasicInformationFormParts.HomeownerInformation:
        return basicInformationForm?.isHomeownerInformationComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case HomeBasicInformationFormParts.PropertyDetails:
        return basicInformationForm?.isPropertyDetailsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case HomeBasicInformationFormParts.ConfirmationAffirmations:
        return basicInformationForm?.isConfirmationAffirmationsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const checklistParts: FormChecklistPart<HomeBasicInformationFormParts>[] = [
    {
      formPart: HomeBasicInformationFormParts.HomeownerInformation,
      displayName: "Homeowner Information",
      requiredFieldCount:
        basicInformationForm?.homeownerInformationRequiredFieldCount,
    },
    {
      formPart: HomeBasicInformationFormParts.PropertyDetails,
      displayName: "Property Details",
      requiredFieldCount:
        basicInformationForm?.propertyDetailsRequiredFieldCount,
      disabled: basicInformationForm?.isHomeownerInformationComplete !== true,
    },
    {
      formPart: HomeBasicInformationFormParts.ConfirmationAffirmations,
      displayName: "Confirmation & Affirmations",
      requiredFieldCount:
        basicInformationForm?.confirmationAffirmationsRequiredFieldCount,
      disabled: basicInformationForm?.isPropertyDetailsComplete !== true,
    },
  ];

  return {
    checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    homeSchemaFactory,
  };
}

export default useHomeSCSHBasicInformationFormContainer;
