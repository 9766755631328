import { FormGroup, TextArea } from "@blueprintjs/core";
import { useState } from "react";
import { FormstackText } from "../../../types/formstack/fieldValueTypes/FormstackText";
import { TextAreaField as InterationEngineTextAreaField } from "../../../types/viewModels/IterationEngineViewModel";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { BaseFieldProps } from "../types";
import "./styles.scss";

export interface TextAreaFieldProps
  extends BaseFieldProps<FormstackText>,
    Omit<InterationEngineTextAreaField, "id" | "sortOrder" | "isHidden"> {
  label: string;
  type: "Textarea";
  value: FormstackText;
  textAreaClassName?: string;
}

export const TextAreaField: React.FC<TextAreaFieldProps> = ({
  fieldId,
  label,
  value,
  textAreaClassName,
  onSubmit,
  isEmpty,
  disabled,
  isSelectedField,
  hasActiveChangeRequest,
  isSelectedChangeRequest,
  isResovledChangeRequest,
  hasActiveInternalComment,
  isExternalView,
}) => {
  if (isEmpty(value)) {
    return null;
  }
  const [localValue, setLocalValue] = useState<string>(value);

  return (
    <FormGroup>
      <SelectableLabel
        name={label}
        fieldId={fieldId}
        value={localValue}
        isSelectedField={isSelectedField}
        hasActiveChangeRequest={hasActiveChangeRequest}
        isSelectedChangeRequest={isSelectedChangeRequest}
        isResovledChangeRequest={isResovledChangeRequest}
        hasActiveInternalComment={hasActiveInternalComment}
        shouldDisableFieldLabelClick={isExternalView}
      />
      <TextArea
        name={label}
        className={textAreaClassName}
        value={localValue}
        disabled={disabled}
        onChange={(e) => setLocalValue(e.target.value)}
        onBlur={() => onSubmit(localValue)}
      />
    </FormGroup>
  );
};
