import { PermissionsContext, PermissionsContextProps } from "@ucl/library";
import { useContext } from "react";
import { WildfireEvaluationSteps } from "../../foritfied/types/evaluation/Evaluation";
import { EvaluationStatus } from "../types/WildfireApplication/WildfireApplication";

const useWildfirePermissons = (
  step: WildfireEvaluationSteps | undefined,
  status: EvaluationStatus | undefined
) => {
  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  const isInFinalStatus =
    status === "Designated" ||
    status === "Not Designated" ||
    status === "Closed";

  const canEditFormInApplicationStep =
    hasPermission("CanEditFormsInApplicationStep") &&
    step === WildfireEvaluationSteps.WildfireApplication &&
    !isInFinalStatus;

  const canEditFormInApplicationFeeStep =
    hasPermission("CanEditFormsInApplicationFeeStep") &&
    step === WildfireEvaluationSteps.WildfireApplicationPayment &&
    !isInFinalStatus;

  const canEditInPhotoRequirementsStep =
    hasPermission("CanEditFormsInPhotoRequirementsStep") &&
    step === WildfireEvaluationSteps.WildfireApplicationEligibilityPhotos &&
    !isInFinalStatus;

  const canEditFormInIBHSEligibilityStep =
    hasPermission("CanEditFormsInIBHSEligibilityReviewStep") &&
    step === WildfireEvaluationSteps.WildfireApplicationIBHSEligibilityReview &&
    !isInFinalStatus;

  const canEditFormInEvaluatorAssignmentStep =
    hasPermission("CanEditFormsInEvaluatorAssignmentStep") &&
    step === WildfireEvaluationSteps.WildfireApplicationEvaluatorAssignment &&
    !isInFinalStatus;

  const canEditFormInFieldEvaluationsStep =
    hasPermission("CanEditFormsInFieldEvaluationsStep") &&
    step === WildfireEvaluationSteps.WildfireApplicationFieldEvaluations &&
    !isInFinalStatus;

  const canEditFormInIBHSReviewStep =
    hasPermission("CanEditFormsInIBHSReviewStep") &&
    step === WildfireEvaluationSteps.WildfireApplicationIBHSReview &&
    !isInFinalStatus;

  const canEditFormsInFinalStatus =
    hasPermission("CanEditFormsInFinalStatus") && isInFinalStatus;

  const canEditForm =
    canEditFormInApplicationStep ||
    canEditFormInApplicationFeeStep ||
    canEditFormInIBHSEligibilityStep ||
    canEditFormInEvaluatorAssignmentStep ||
    canEditFormInFieldEvaluationsStep ||
    canEditInPhotoRequirementsStep ||
    canEditFormInIBHSReviewStep ||
    canEditFormsInFinalStatus;

  return { canEditForm };
};

export default useWildfirePermissons;
