import {
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { FieldSchemaModel } from "../fieldSchemaConfig";

export enum LandscapeFieldEvaluationFormParts {
  ZeroToFiveFeet = "0-5-feet",
  FiveToThirtyFeet = "5-30-feet",
}

export interface LandscapeFieldEvaluationFormModel extends FieldSchemaModel {
  // Relationships
  wildfireApplicationEvaluationId: string;

  // Metadata
  zeroToFiveFeetRequiredFieldCount: number;
  fiveToThirtyFeetRequiredFieldCount: number;

  isZeroToFiveFeetComplete: boolean;
  isFiveToThirtyFeetComplete: boolean;

  zeroToFiveFeetSubmitted: boolean;
  fiveToThirtyFeetSubmitted: boolean;
  submitted: boolean;

  // Part 1: 0-5 Feet
  zeroToFiveFeetPhotosFolder: WildfireBoxContentExplorerValue;
  isGroundCoverIntheHIZNoncombustible: EnumRadioFieldValue;
  isHIZFreeFromCombustibleItems: EnumRadioFieldValue;
  isHIZFreeOfVehicles: EnumRadioFieldValue;
  isFencingNearHomeNonCombustible: EnumRadioFieldValue;

  // Part 2: 5-30 Feet

  fiveToThirtyFeetPhotosFolder: WildfireBoxContentExplorerValue;
  isYardFreeOfDebris: EnumRadioFieldValue;
  treesSpacedApartAndPruned: EnumRadioFieldValue;
  treesAndShrubsSpacingCompliance: EnumRadioFieldValue;
  smallVegetationSpacingCompliance: EnumRadioFieldValue;
  hasAllDeadVegetationRemoved: EnumRadioFieldValue;
  isGapBetweenBackToBackFencesSufficient: EnumSingleSelectFieldValue;
}
