import { PrimaryCard, SecondaryCard } from "@ucl/library";
import { FieldSchemaFactoryProvider } from "../../../../../common/customHooks/useFCFMFFieldSchemaFactory";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { Navigate } from "react-router-dom";
import FormChecklistProgress from "../../../../../../Common/components/Forms/FormChecklistProgress/FormChecklistProgress";
import { multifamilyRedesignationRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedCommercialMultifamilyRouteConfig";
import { FCFMFRedesignationFormParts } from "../../../../../common/types/Redesignation/BaseFCFMFRedesignationFormModel";
import useMultifamilyRedesignationFormContainer from "./useMultifamilyRedesignationFormContainer";
import MultifamilyRedesignationForm from "../Form/MultifamilyRedesignationForm";

export interface MultifamilyRedesignationFormContainerProps {
  evaluationId: string;
  formPart?: FCFMFRedesignationFormParts;
}

export const MultifamilyRedesignationFormContainer = (
  props: MultifamilyRedesignationFormContainerProps
) => {
  const {
    applicationChecklistParts,
    getFormPartChecklistStatus,
    setFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    schemaFactory,
  } = useMultifamilyRedesignationFormContainer(props);

  return (
    <>
      {!formPart ||
      !Object.values(FCFMFRedesignationFormParts).includes(formPart) ? (
        // TODO: Show error page if the application form part is not valid
        <Navigate
          to={`${multifamilyRedesignationRoutePrefix}/${
            props.evaluationId
          }/form/${getBestFormPart().valueOf()}`}
        />
      ) : !schemaFactory ? (
        <Loading />
      ) : (
        <div className="wildfire-form-page">
          <div className="wildfire-form-progress">
            <div className="wildfire-form-progress__steps">
              <FormChecklistProgress
                checklistParts={applicationChecklistParts}
                evaluationId={props.evaluationId}
                formURL={`${multifamilyRedesignationRoutePrefix}/${props.evaluationId}/form`}
                title={"Redesignation Form"}
                activeFormPart={formPart}
                getChecklistStatus={getFormPartChecklistStatus}
              />
            </div>
          </div>
          <section className="wildfire-form-content">
            <div className="wildfire-form-content__header"></div>
            <PrimaryCard
              className="wildfire-form-primary-card"
              headerContent={
                <div className="wildfire-form-primary-card__header">
                  Multifamily Redesignation Form:{" "}
                  {
                    applicationChecklistParts.find(
                      (value) => value.formPart === formPart
                    )?.displayName
                  }
                </div>
              }
            >
              <SecondaryCard
                className="wildfire-form-secondary-card"
                hideBody={false}
                isCollapsible={false}
              >
                <FieldSchemaFactoryProvider schemaFactory={schemaFactory}>
                  <MultifamilyRedesignationForm
                    key={formPart}
                    evaluationId={props.evaluationId}
                    isIterationEnginePage={false}
                    formPart={formPart}
                    disableForm={false}
                    setFormModel={setFormModel}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                  />
                </FieldSchemaFactoryProvider>
              </SecondaryCard>
            </PrimaryCard>
          </section>
        </div>
      )}
    </>
  );
};
export default MultifamilyRedesignationFormContainer;
