import { useEffect, useState } from "react";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../common/Components/Error/ErrorStore";
import { Evaluation_MultifamilyEvaluation } from "../types/MultifamilyEvaluation";
import { multifamilyEvaluationAPIClient } from "../lib/apiClients/multifamilyEvaluationAPIClient";

export const useMultifamilyEvaluation = (evaluationId: string) => {
  const [multifamilyEvaluation, setMultifamilyEvaluation] =
    useState<Evaluation_MultifamilyEvaluation | null>();
  const [isApplicationLoading, setIsApplicationLoading] = useState(false);

  useEffect(() => {
    getMultifamilyEvaluation(evaluationId);
  }, [evaluationId]);

  const getMultifamilyEvaluation = async (evaluationId: string) => {
    await multifamilyEvaluationAPIClient
      .getMultifamilyEvaluation(evaluationId)
      .then((response) => {
        setMultifamilyEvaluation(response);
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      });
  };

  const refreshMultifamilyEvaluation = async () => {
    setIsApplicationLoading(true);
    if (multifamilyEvaluation?.id) {
      await multifamilyEvaluationAPIClient
        .getMultifamilyEvaluation(multifamilyEvaluation?.id)
        .then((response) => {
          setMultifamilyEvaluation(response);
          //setWildfireEvaluationHeader(response);
        })
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        })
        .finally(() => {
          setIsApplicationLoading(false);
        });
    }
  };

  return {
    multifamilyEvaluation,
    refreshMultifamilyEvaluation,
    isApplicationLoading,
    setMultifamilyEvaluation,
  };
};
