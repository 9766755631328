import { PrimaryCard, SecondaryCard } from "@ucl/library";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import FormChecklistProgress from "../../../../../../Common/components/Forms/FormChecklistProgress/FormChecklistProgress";
import { commercialRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedCommercialMultifamilyRouteConfig";
import { FCFMFEvaluationFieldInspectionFormParts } from "../../../../../common/types/Evaluation/BaseFCFMFEvaluationFieldInspectionForm";
import CommercialEvaluationFieldInspectionForm from "../Form/CommercialEvaluationFieldInspectionForm";
import useCommercialEvaluationFieldInspectionFormContainer from "./useCommercialEvaluationFieldInspectionContainer";
import { FieldSchemaFactoryProvider } from "../../../../../common/customHooks/useFCFMFFieldSchemaFactory";

export interface CommercialEvaluationFieldInspectionFormContainerProps {
  evaluationId: string;
  evaluationFieldInspectionFormPart?: FCFMFEvaluationFieldInspectionFormParts;
}

export const CommercialEvaluationFieldInspectionFormContainer = (
  props: CommercialEvaluationFieldInspectionFormContainerProps
) => {
  const {
    evaluationFieldInspectionChecklistParts,
    getEvaluationFieldInspectionPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    evaluationFieldInspectionFormPart,
    commercialSchemaFactory,
  } = useCommercialEvaluationFieldInspectionFormContainer(props);

  return (
    <>
      {!commercialSchemaFactory ? (
        <Loading />
      ) : (
        <div className="wildfire-form-page">
          <div className="wildfire-form-progress">
            <div className="wildfire-form-progress__steps">
              <FormChecklistProgress
                checklistParts={evaluationFieldInspectionChecklistParts}
                evaluationId={props.evaluationId}
                formURL={`${commercialRoutePrefix}/${props.evaluationId}/field-inspection-form`}
                title={"Field Inspection Form"}
                activeFormPart={evaluationFieldInspectionFormPart}
                getChecklistStatus={
                  getEvaluationFieldInspectionPartChecklistStatus
                }
              />
            </div>
          </div>
          <section className="wildfire-form-content">
            <div className="wildfire-form-content__header"></div>
            <PrimaryCard
              className="wildfire-form-primary-card"
              headerContent={
                <div className="wildfire-form-primary-card__header">
                  Commercial: Evaluation Field Inspection Form
                </div>
              }
            >
              <SecondaryCard
                className="wildfire-form-secondary-card"
                hideBody={false}
                isCollapsible={false}
              >
                <FieldSchemaFactoryProvider
                  schemaFactory={commercialSchemaFactory}
                >
                  <CommercialEvaluationFieldInspectionForm
                    key={evaluationFieldInspectionFormPart}
                    evaluationId={props.evaluationId}
                    isIterationEnginePage={false}
                    formPart={evaluationFieldInspectionFormPart}
                    disableForm={false}
                    setFormModel={setEvaluationFormModel}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                  />
                </FieldSchemaFactoryProvider>
              </SecondaryCard>
            </PrimaryCard>
          </section>
        </div>
      )}
    </>
  );
};
export default CommercialEvaluationFieldInspectionFormContainer;
