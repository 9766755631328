import { useContext, useEffect, useState } from "react";
import { FieldEvaluationFormPageProps } from "./FieldEvaluationFormPage";
import { EvaluationFormModel } from "../../forms/EvaluationFormModel";
import { wildfireEvaluationApiClient } from "../../lib/apiClients/application/wildfireApplicationApiClient";
import {
  errorStore,
  genericErrorMessage,
} from "../../../common/Components/Error/ErrorStore";
import { RoofFieldEvaluationFormParts } from "../../forms/RoofFieldEvaluationForm/RoofFieldEvaluationFormModel";
import { HomeExteriorFieldEvaluationFormParts } from "../../forms/HomeExteriorFieldEvaluationForm/HomeExteriorFieldEvaluationFormModel";
import { LandscapeFieldEvaluationFormParts } from "../../forms/LandscapeFieldEvaluationForm/LandscapeFieldEvaluationFormModel";
import { OtherFieldEvaluationFormParts } from "../../forms/OtherFieldEvaluationForm/OtherFieldEvaluationFormModel";
import { AppHeaderContext } from "../../components/Provider/AppHeaderContext";

const useFieldEvaluationForms = (props: FieldEvaluationFormPageProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const [fieldEvaluationFormModel, setFieldEvaluationFormModel] =
    useState<EvaluationFormModel>();

  const { setWildfireEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    return () => {
      setDefaultHeader();
    };
  }, []);

  const fetchFieldEvaluationForms = async () => {
    await wildfireEvaluationApiClient
      .getWildfireEvaluationWithFormsById(props.wildfireEvaluationId)
      .then((response) => {
        setFieldEvaluationFormModel(response);
        setWildfireEvaluationHeader(response);
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [roofFieldEvaluationFormPart, setRoofFieldEvaluationFormPart] =
    useState<RoofFieldEvaluationFormParts | undefined>(props.roofFormPart);

  const [
    homeExteriorFieldEvaluationFormPart,
    setHomeExteriorFieldEvaluationFormPart,
  ] = useState<HomeExteriorFieldEvaluationFormParts | undefined>(
    props.homeExteriorFormPart
  );

  const [
    landscapeFieldEvaluationFormPart,
    setLandscapeFieldEvaluationFormPart,
  ] = useState<LandscapeFieldEvaluationFormParts | undefined>(
    props.landscapeFormPart
  );

  const [otherFieldEvaluationFormPart, setOtherFieldEvaluationFormPart] =
    useState<OtherFieldEvaluationFormParts | undefined>(props.otherFormPart);

  useEffect(() => {
    fetchFieldEvaluationForms();

    setLandscapeFieldEvaluationFormPart(props.landscapeFormPart);
    setRoofFieldEvaluationFormPart(props.roofFormPart);
    setHomeExteriorFieldEvaluationFormPart(props.homeExteriorFormPart);
    setOtherFieldEvaluationFormPart(props.otherFormPart);
  }, [
    props.otherFormPart,
    props.landscapeFormPart,
    props.homeExteriorFormPart,
    props.roofFormPart,
  ]);

  return {
    isLoading,
    fieldEvaluationFormModel,
    fetchFieldEvaluationForms,
    setFieldEvaluationFormModel,
    otherFieldEvaluationFormPart,
    landscapeFieldEvaluationFormPart,
    homeExteriorFieldEvaluationFormPart,
    roofFieldEvaluationFormPart,
  };
};

export default useFieldEvaluationForms;
