import { Button, Intent, Menu, MenuItem, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Popover2 } from "@blueprintjs/popover2";
import { useState } from "react";
import { evaluationStore } from "../../../stores/EvaluationStore";
import { permissionStore } from "../../../stores/PermissionStore";
import { ApprovalTypes } from "../../../types/evaluation/Evaluation";
import "./styles.scss";
import { browserDownloadFileResponse } from "../../../../common/utils/download";
import { RelativityTooltip } from "@ucl/library";

interface DownloadCertificateActionButtonProps {}

const DownloadCertificateActionButton: React.FC<
  DownloadCertificateActionButtonProps
> = ({}) => {
  const [isLoading, setIsLoading] = useState(false);

  const certificateName = "Certificate.pdf";
  const fileName = evaluationStore.evaluation?.fortifiedId
    ? `${evaluationStore.evaluation?.fortifiedId}-${certificateName}`
    : `${certificateName}`;

  const isCertificateButtonDisplayed =
    !!evaluationStore.evaluation?.isApproved &&
    evaluationStore.evaluation?.approvalType === ApprovalTypes.Certificate;

  const shouldDisableDownload = !permissionStore.canViewFCFMFApprovalDocuments;

  return (
    <>
      <Popover2
        position={Position.BOTTOM}
        content={
          <Menu>
            <MenuItem
              icon={IconNames.DOWNLOAD}
              text="Download"
              onClick={async () => {
                setIsLoading(true);
                await evaluationStore
                  .getEvaluationCertificate()
                  .then((response) => {
                    browserDownloadFileResponse(response.data, fileName);
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            />
            {permissionStore.canRegenerateCertificate && (
              <MenuItem
                icon={IconNames.REFRESH}
                text="Regenerate"
                onClick={async () => {
                  setIsLoading(true);
                  await evaluationStore
                    .regenerateEvaluationCertificate()
                    .then((response) => {
                      browserDownloadFileResponse(response.data, fileName);
                    })
                    .finally(() => {
                      setIsLoading(false);
                    });
                }}
              />
            )}
          </Menu>
        }
      >
        <RelativityTooltip
          shouldShowTooltip={shouldDisableDownload}
          compact={true}
          className="certificate-tooltip-container"
          content={
            <div className="certificate-tooltip">
              Available for download after Certificate Payment has been
              successfully processed
            </div>
          }
        >
          <Button
            loading={isLoading}
            intent={shouldDisableDownload ? Intent.NONE : Intent.SUCCESS}
            rightIcon={IconNames.CARET_DOWN}
            icon={IconNames.DOCUMENT}
            hidden={!isCertificateButtonDisplayed}
            disabled={shouldDisableDownload}
          >
            Certificate
          </Button>
        </RelativityTooltip>
      </Popover2>
    </>
  );
};

export default DownloadCertificateActionButton;
