import {
  PermissionsContext,
  PermissionsContextProps,
  PrimaryCard,
  PrimaryCardHeader,
  RelativityTooltip,
} from "@ucl/library";
import { WildfireEvaluation } from "../../../types/WildfireApplication/WildfireApplication";
import { IconNames } from "@blueprintjs/icons";
import { Icon } from "@blueprintjs/core";
import useWildfireApplicationOverviewCard from "./useWildfireApplicationOverviewCard";
import "./styles.scss";
import { ApplicationCommentsButton } from "../../Button/ApplicationCommentsButton.tsx/ApplicationCommentsButton";
import WildfireCloseEvaluationButton from "../../Button/WildfireCloseEvaluationButton/WildfireCloseEvaluationButton";
import { useContext } from "react";
import { ProgressBarComponent } from "@syncfusion/ej2-react-progressbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface WildfireApplicationOverviewCardProps {
  wildfireEvaluation: WildfireEvaluation;
  fetchWildfireApplications: () => Promise<void>;
  index: number;
}

export enum ApplicantStatus {
  "Application" = 1,
  "Eligibility Review" = 2,
  "Field Evaluations" = 3,
  "IBHS Review" = 4,
  "Designation" = 5,
}

export const WildfireApplicationOverviewCard: React.FC<
  WildfireApplicationOverviewCardProps
> = ({ wildfireEvaluation, fetchWildfireApplications, index }) => {
  const {
    status,
    secondaryHeaderInfo,
    primaryHeaderInfo,
    statusBadgeClass,
    statusSummary,
    nextStatusSummary,
    statusCallout,
    actionButtons,
    currentApplicantStep,
    applicantDisplayStatuses,
    hasFinalDetermination,
    getStatusClass,
    getStatusIcon,
    getApplicantProgressValue,
    actionText,
  } = useWildfireApplicationOverviewCard({
    wildfireEvaluation,
    fetchWildfireApplications,
    index,
  });

  const displayStatusToUse =
    status === "Evaluator Assignment" ? "Field Evaluations" : status;

  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  return (
    <div className="wildfire-application-overview-card">
      <PrimaryCard>
        <PrimaryCardHeader label="">
          <span className="wildfire-application-overview-card-header">
            <div className="wildfire-application-overview-card-header-text">
              <div className="wildfire-application-overview-card-header-text-primary-info">
                <Icon icon={IconNames.MAP_MARKER} color="#0E1624" />
                <div>{primaryHeaderInfo}</div>
              </div>
              <div className="wildfire-application-overview-card-header-text-secondary-info">
                {secondaryHeaderInfo}
              </div>
            </div>
            <div className="wildfire-application-overview-card-header__buttons">
              <ApplicationCommentsButton
                evaluationId={wildfireEvaluation.id}
                wildfirePreparedId={
                  wildfireEvaluation.wildfireApplication
                    .wildfirePreparedApplicationId
                }
                unreadCommentsCount={wildfireEvaluation.unreadCommentsCount}
                fetchWildfireApplications={fetchWildfireApplications}
              />
              {!hasFinalDetermination && (
                <WildfireCloseEvaluationButton
                  evaluationId={wildfireEvaluation.id}
                  isClosed={wildfireEvaluation.isWithdrawn}
                  refreshWildfireEvaluation={fetchWildfireApplications}
                  hasWithdrawPermission={hasPermission("CanViewWithdrawButton")}
                  auditorNotes={wildfireEvaluation.auditorNotes}
                />
              )}
            </div>
          </span>
          <RelativityTooltip
            className="wildfire-application-overview-card-progress-container"
            shouldShowTooltip={true}
            content={
              <div>
                {applicantDisplayStatuses.map((status, idx) => (
                  <div key={status}>
                    <span key={status}>{`${idx + 1}. ${
                      ApplicantStatus[status]
                    }`}</span>
                    <br />
                  </div>
                ))}
              </div>
            }
          >
            <span>
              <div className="wildfire-application-overview-card__status-bar">
                {applicantDisplayStatuses.map((status, idx) => (
                  <div
                    id={`${ApplicantStatus[status]}`}
                    className={`wildfire-application-overview-card__status-bar__item ${getStatusClass(
                      status,
                      currentApplicantStep,
                      wildfireEvaluation.status_AsString
                    )}`}
                    key={status}
                  >
                    <FontAwesomeIcon
                      className="wildfire-application-overview-card__status-bar__item__icon"
                      icon={getStatusIcon(
                        status,
                        currentApplicantStep,
                        wildfireEvaluation.status_AsString
                      )}
                    />
                    <span>{`${idx + 1}. ${ApplicantStatus[status]}`}</span>
                  </div>
                ))}
              </div>
              <ProgressBarComponent
                id={`${index}`}
                type="Linear"
                secondaryProgress={currentApplicantStep}
                value={getApplicantProgressValue()}
                progressColor="#26cf8b"
                animation={{ enable: true, duration: 2000, delay: 0 }}
                progressThickness={8}
                trackThickness={8}
                secondaryProgressColor="#ffbd63"
                minimum={0}
                maximum={applicantDisplayStatuses.length}
                gapWidth={8}
                segmentCount={applicantDisplayStatuses.length}
                cornerRadius={"Round"}
              />
            </span>
          </RelativityTooltip>
        </PrimaryCardHeader>
        <div className="wildfire-application-overview-card-body">
          <div className="wildfire-application-overview-card-body-column">
            <h2>
              Application Status:{" "}
              <span
                className={`wildfire-application-status-badge wildfire-application-status-badge__${statusBadgeClass}`}
              >
                {displayStatusToUse}
              </span>
            </h2>
            <div className="wildfire-application-overview-card-body-list">
              {statusSummary.map((summary_line, index) => (
                <div
                  key={`${wildfireEvaluation.id}_current_summary_item_${index}`}
                >
                  {summary_line}
                </div>
              ))}
            </div>
            {statusCallout}
          </div>
          <div className="wildfire-application-overview-card-body-column">
            <h2>{actionText}</h2>
            <div className="wildfire-application-overview-card-body-list">
              {nextStatusSummary.map((summary_line, index) => (
                <div
                  key={`${wildfireEvaluation.id}_next_summary_item_${index}`}
                >
                  {summary_line}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="wildfire-application-overview-card-footer">
          {actionButtons}
        </div>
      </PrimaryCard>
    </div>
  );
};
