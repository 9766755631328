import { createContext, useContext, ReactNode, FunctionComponent } from "react";
import * as msal from "@azure/msal-browser";
import type { Router as RemixRouter } from "@remix-run/router";

// Define the shape of the context
interface ProductTypeHandlerContextValue {
  routePrefix: string;
  getAccessToken: () => Promise<string | undefined>;
  logoutUser: () => Promise<void>;
  msalInstance?: msal.PublicClientApplication;
  router?: RemixRouter;
  externalRouter?: RemixRouter;
}

// Creating the context with a default value
const ProductTypeHandlerContext = createContext<ProductTypeHandlerContextValue>(
  {
    routePrefix: "",
    logoutUser: async () => undefined,
    getAccessToken: async () => undefined,
    msalInstance: undefined,
    router: undefined,
    externalRouter: undefined,
  }
);

// Custom hook for consuming the context
export const useProductTypeHandler = () =>
  useContext(ProductTypeHandlerContext);

export const ProductTypeHandlerProvider: FunctionComponent<{
  children: ReactNode;
  routePrefix: string;
  getAccessToken: () => Promise<string | undefined>;
  logoutUser: () => Promise<void>;
  msalInstance?: msal.PublicClientApplication;
  router?: RemixRouter;
  externalRouter?: RemixRouter;
}> = ({
  children,
  routePrefix,
  getAccessToken,
  logoutUser,
  msalInstance,
  router,
  externalRouter,
}) => (
  <ProductTypeHandlerContext.Provider
    value={{
      routePrefix,
      msalInstance,
      router,
      externalRouter,
      getAccessToken,
      logoutUser,
    }}
  >
    {children}
  </ProductTypeHandlerContext.Provider>
);
