import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { Evaluation_HomeSCSHEvaluation } from "../../types/HomeSCSHEvaluation";
import { Evaluation_HomeSCSHEvaluationFormModel } from "../../types/HomeSCSHEvaluationFormModel";

class HomeSCSHEvaluationAPIClient {
  createHomeSCSHEvaluation = async () => {
    return baseApiClient.post<Evaluation_HomeSCSHEvaluation>(
      `home-scsh/evaluation`
    );
  };

  getHomeSCSHEvaluation = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeSCSHEvaluation>(
      `home-scsh/evaluation/${evaluationId}`
    );
  };

  getHomeSCSHEvaluationFormModel = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeSCSHEvaluationFormModel>(
      `home-scsh/evaluation/${evaluationId}`
    );
  };
}

export const homeSCSHEvaluationAPIClient = new HomeSCSHEvaluationAPIClient();
