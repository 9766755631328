const formatFileField = (data: string): string => {
  try {
    // Parse the JSON string to an object
    const jsonData = JSON.parse(data);

    // Recursive function to search for the FileCount property
    const findFileCount = (obj: any): string | null => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (key.endsWith("FileCount") && typeof obj[key] === "string") {
            return obj[key];
          } else if (typeof obj[key] === "object" && obj[key] !== null) {
            const result = findFileCount(obj[key]);
            if (result !== null) {
              return result;
            }
          }
        }
      }
      return null;
    };

    // Extract the FileCount
    const fileCount = findFileCount(jsonData);

    // Return the formatted string
    return fileCount !== null ? `File Count: ${fileCount}` : data;
  } catch (error) {
    // If parsing fails, return the original data
    return data;
  }
};

export const formatField = (
  fieldData: string | undefined,
  fieldKey: string
): string => {
  if (!fieldData) {
    return "None";
  }

  if (fieldKey.includes("Folder")) {
    return formatFileField(fieldData);
  }

  return fieldData;
};
