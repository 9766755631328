import {
  Button,
  Card,
  Classes,
  Dialog,
  FormGroup,
  TextArea,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Observer } from "mobx-react";
import React, { useEffect } from "react";
import { useState } from "react";
import { commentApiClient } from "../../../common/apiClients/comments/commentApiClient";
import {
  Comment,
  ExistingComment,
} from "../../../common/apiClients/comments/types";
import { evaluationApiClient } from "../../lib/apiClients/evaluation/evaluationApiClient";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { fortifiedGridStore } from "../../stores/FortifiedGridStore";
import { fortifiedUserStore } from "../../stores/FortifiedUserStore";
import { parseLongDate } from "../../../common/utils/date";

import { DialogFooter } from "./DialogFooter";
import { BaseDialogProps } from "./types";
import { permissionStore } from "../../stores/PermissionStore";
import { MinimalSelectableLabel } from "../Fields/SelectableLabel/MinimalSelectableLabel";

const defaultCommentFormValue: Comment = {
  evaluationId: "",
  message: "",
};

const commentDialogTitle = () => {
  if (!!dialogsViewerStore.commentDialogOpenOptions) {
    return `${dialogsViewerStore.commentDialogOpenOptions.productType_AsString} - ${dialogsViewerStore.commentDialogOpenOptions.fortifiedId}`;
  }
};
export interface CommentsDialogProps extends BaseDialogProps {}
export const CommentsDialog: React.FC<CommentsDialogProps> = ({
  isOpen,
  closeDialog,
}) => {
  const [localEvaluationComments, setLocalEvaluationComments] = useState<
    ExistingComment[]
  >([]);

  const [createCommentformValue, setCreateCommentFormValue] =
    React.useState<Comment>(defaultCommentFormValue);

  const [activeComment, setActiveComment] = useState<string>();
  const [activeCommentText, setActiveCommentText] = useState<string>();
  const isActiveComment = (comment: ExistingComment) => {
    return comment.id === activeComment;
  };

  const [needsRefresh, setNeedsRefresh] = useState<boolean>(true);

  useEffect(() => {
    if (!!dialogsViewerStore.commentDialogOpenOptions) {
      evaluationApiClient
        .getEvaluationComments(
          dialogsViewerStore.commentDialogOpenOptions.evaluationId
        )
        .then((comments) => {
          setLocalEvaluationComments(comments);
        });
      setNeedsRefresh(false);
    }
  }, [dialogsViewerStore.commentDialogOpenOptions, needsRefresh]);

  return (
    <Dialog
      className="comment-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={commentDialogTitle() || "Evaluation Comments"}
      onClose={closeDialog}
    >
      <Observer>
        {() => (
          <div className={Classes.DIALOG_BODY}>
            {localEvaluationComments &&
              localEvaluationComments.map((comment) => {
                return (
                  <Card className="comment-card" key={comment.id}>
                    <div className="comment-card-header">
                      <div>
                        <div>
                          <div>
                            {!permissionStore.canViewAuditorName &&
                            fortifiedUserStore.isAnAuditor(comment.creatorRole)
                              ? `(${comment.creatorRole_AsString})`
                              : `${comment.createdBy_AsName} (${comment.creatorRole_AsString})`}
                          </div>
                        </div>
                        <div className="comment-card-date">
                          {parseLongDate(comment.createdAt)}
                        </div>
                      </div>
                      <div className="comment-card-actions">
                        {!comment.canModify && !comment.isRead && (
                          <Button
                            className="comment-card-view-button"
                            minimal={true}
                            icon={IconNames.EYE_ON}
                            onClick={async () => {
                              commentApiClient
                                .markCommentAsRead(comment.id)
                                .then(() => {
                                  fortifiedGridStore.refresh();
                                })
                                .finally(() => {
                                  setNeedsRefresh(true);
                                });
                            }}
                          >
                            Mark As Read
                          </Button>
                        )}

                        {comment.isRead &&
                          comment.viewedBy !== fortifiedUserStore.user?.id && (
                            <div className="comment-card-viewer-name">
                              {`Viewed By: ${comment.viewedBy_AsName}`}
                            </div>
                          )}
                        {!!comment.isRead &&
                          comment.viewedBy === fortifiedUserStore.user?.id && (
                            <div className="comment-card-viewer-name">
                              <Button
                                className="comment-card-view-button"
                                minimal={true}
                                icon={IconNames.EYE_OFF}
                                onClick={async () => {
                                  commentApiClient
                                    .markCommentAsUnread(comment.id)
                                    .then(() => {
                                      fortifiedGridStore.refresh();
                                    })
                                    .finally(() => {
                                      setNeedsRefresh(true);
                                    });
                                }}
                              >
                                Mark As Unread
                              </Button>
                            </div>
                          )}

                        {comment.canModify && (
                          <>
                            {isActiveComment(comment) && (
                              <Button
                                icon={IconNames.FLOPPY_DISK}
                                minimal={true}
                                onClick={async () => {
                                  await commentApiClient
                                    .updateComment(comment.id, {
                                      ...comment,
                                      message: activeCommentText,
                                    })
                                    .then(() => {
                                      setActiveComment(undefined);
                                    })
                                    .finally(() => {
                                      setNeedsRefresh(true);
                                    });
                                }}
                              />
                            )}
                            {!isActiveComment(comment) && (
                              <Button
                                icon={IconNames.EDIT}
                                minimal={true}
                                onClick={() => {
                                  setActiveComment(comment.id);
                                  setActiveCommentText(comment.message);
                                }}
                              />
                            )}

                            <Button
                              icon={IconNames.TRASH}
                              minimal={true}
                              onClick={async () => {
                                commentApiClient
                                  .deleteComment(comment.id)
                                  .finally(() => {
                                    setNeedsRefresh(true);
                                  });
                              }}
                            ></Button>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="base-field">
                      {isActiveComment(comment) && (
                        <form id="save-comment">
                          <FormGroup className="base-field">
                            <TextArea
                              className="comment-textarea"
                              disabled={!isActiveComment(comment)}
                              value={activeCommentText}
                              rows={5}
                              onChange={(e) => {
                                setActiveCommentText(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </form>
                      )}
                      {!isActiveComment(comment) && (
                        <FormGroup className="base-field">
                          <TextArea
                            className="comment-textarea"
                            disabled={!isActiveComment(comment)}
                            value={comment.message}
                            rows={5}
                          />
                        </FormGroup>
                      )}
                    </div>
                  </Card>
                );
              })}
          </div>
        )}
      </Observer>
      {permissionStore.canCreateBoardComments ? (
        <DialogFooter
          closeDialog={closeDialog}
          submitButtonText={"Add"}
          closeButtonText={"Cancel"}
          submitDialog={async () => {
            if (!!dialogsViewerStore.commentDialogOpenOptions) {
              createCommentformValue.evaluationId =
                dialogsViewerStore.commentDialogOpenOptions.evaluationId;

              await commentApiClient
                .createComment(createCommentformValue)
                .finally(() => {
                  setCreateCommentFormValue(defaultCommentFormValue);
                  setNeedsRefresh(true);
                });
            }
          }}
        >
          <form id="save-comment">
            <FormGroup className="base-field">
              <div className="comment-create-footer">
                <MinimalSelectableLabel label={"Add Comment"} />
              </div>

              <TextArea
                className="comment-textarea"
                value={createCommentformValue.message}
                rows={5}
                onChange={(e) => {
                  setCreateCommentFormValue((prev) => ({
                    ...prev,
                    message: e.target.value,
                  }));
                }}
              />
            </FormGroup>
          </form>
        </DialogFooter>
      ) : (
        <DialogFooter
          closeDialog={closeDialog}
          closeButtonText={"Close"}
          isSubmitButtonHidden={true}
          submitDialog={async () => undefined} // Do nothing
        />
      )}
    </Dialog>
  );
};
