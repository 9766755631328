import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useNavigate } from "react-router";
import "./styles.scss";
import { createNewWildfireApplication } from "../../../lib/application/applicationUtil";
import { useState } from "react";

export interface CreateWildfireApplicationButtonProps {}
export const CreateWildfireApplicationButton: React.FC<
  CreateWildfireApplicationButtonProps
> = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigateToNewWildfireApplication = async () => {
    navigate(await createNewWildfireApplication());
  };

  return (
    <Button
      minimal
      className="create-wildfire-application-button-small"
      intent={Intent.PRIMARY}
      icon={IconNames.Add}
      text="New Application"
      loading={isLoading}
      onClick={async () => {
        setIsLoading(true);
        await navigateToNewWildfireApplication();
        setIsLoading(false);
      }}
    />
  );
};
