import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { FCFMFRedesignationEvaluatorEmailFormParts } from "../../../common/types/Redesignation/BaseFCFMFRedesignationEvaluatorEmailFormModel";
import { MultifamilyRedesignationEvaluationEmailFormModel } from "../../types/MultifamilyRedesignationEvaluationEmailFormModel";
import { Evaluation_MultifamilyRedesignationFormModel } from "../../types/MultifamilyRedesignationFormModel";

class MultifamilyRedesignationEvaluatorEmailFormAPIClient {
  updateMultifamilyRedesignationEvaluatorEmailForm = async (
    evaluationId: string,
    redesignationEvaluatorEmailForm: MultifamilyRedesignationEvaluationEmailFormModel,
    fieldKey?: string
  ): Promise<Evaluation_MultifamilyRedesignationFormModel> => {
    return baseApiClient.put<Evaluation_MultifamilyRedesignationFormModel>(
      `/multifamily/redesignation-evaluator-email-form/${evaluationId}`,
      {
        redesignationEvaluatorEmailForm,
        fieldKey,
      }
    );
  };

  submitMultifamilyRedesignationEvaluatorEmailForm = async (
    wildfireApplicationId: string,
    redesignationEvaluatorEmailForm: MultifamilyRedesignationEvaluationEmailFormModel,
    formPart?: FCFMFRedesignationEvaluatorEmailFormParts
  ): Promise<Evaluation_MultifamilyRedesignationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const applicationFormPartValue = (
      formPart ??
      (Object.values(
        FCFMFRedesignationEvaluatorEmailFormParts
      ).pop() as FCFMFRedesignationEvaluatorEmailFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_MultifamilyRedesignationFormModel>(
      `/multifamily/redesignation-evaluator-email-form/${wildfireApplicationId}/${applicationFormPartValue}`,
      redesignationEvaluatorEmailForm
    );
  };
}

export const multifamilyRedesignationEvaluatorEmailFormAPIClient =
  new MultifamilyRedesignationEvaluatorEmailFormAPIClient();
