import { FormGroup } from "@blueprintjs/core";
import { DateInput2 } from "@blueprintjs/datetime2";
import React, { useEffect, useState } from "react";
import { DatetimeField as IterationEngineDatetimeField } from "../../../types/viewModels/IterationEngineViewModel";
import { BaseFieldProps } from "../types";
import "./styles.scss";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";

export interface DatetimeFieldProps
  extends BaseFieldProps<string>,
    Omit<IterationEngineDatetimeField, "id" | "sortOrder" | "isHidden"> {}

export const DatetimeField: React.FC<DatetimeFieldProps> = ({
  fieldId,
  label,
  value,
  disabled,
  isEmpty,
  onChange,
  onSubmit,
  hasActiveChangeRequest,
  isSelectedChangeRequest,
  isResovledChangeRequest,
  hasActiveInternalComment,
  isSelectedField,
  isExternalView,
}) => {
  if (isEmpty(value)) {
    return null;
  }

  const [localValue, setLocalValue] = useState<string | null>(value);

  useEffect(() => {
    if (onChange && localValue && localValue !== value) {
      onChange(localValue);
    }

    if (localValue !== value && localValue) {
      onSubmit(localValue);
    }
  }, [localValue]);

  return (
    <FormGroup className="base-field">
      <SelectableLabel
        name={label}
        fieldId={fieldId}
        value={value}
        hasActiveChangeRequest={hasActiveChangeRequest}
        isSelectedChangeRequest={isSelectedChangeRequest}
        isResovledChangeRequest={isResovledChangeRequest}
        hasActiveInternalComment={hasActiveInternalComment}
        isSelectedField={isSelectedField}
        shouldDisableFieldLabelClick={isExternalView}
      />
      <DateInput2
        value={localValue}
        minDate={new Date(new Date().getFullYear() - 100, 0, 1)} // Jan 1, 100 years in the past
        maxDate={new Date(new Date().getFullYear() + 20, 11, 31)} // Dec 31, 20 years in the future
        formatDate={(date) => date?.toLocaleDateString()}
        parseDate={(str) => new Date(str)}
        onChange={(date) => {
          setLocalValue(date);
        }}
        disabled={disabled}
        closeOnSelection
      />
    </FormGroup>
  );
};
