import { Button, Intent } from "@blueprintjs/core";
import { InformationalBanner } from "../../InformationalBanner";
import { IconNames } from "@blueprintjs/icons";
import "./styles.scss";

export interface EligibilityBannerProps {}

export const EligibilityBanner: React.FC<
  EligibilityBannerProps
> = ({}: EligibilityBannerProps) => {
  return (
    <InformationalBanner
      title="Important Note"
      content={
        <>
          <p>
            Designation certificate requirements are stringent. Protected trees
            may disqualify some homes, and some homeowners may have to work with
            neighbor(s) to meet the requirements within 5 feet due to setbacks.
          </p>
          <p>
            One of the most stringent required actions is creating a 5-foot
            noncombustible buffer around your home and decks. ALL vegetation,
            trees including overhanging branches, grass/artificial turf,
            wood/rubber mulch, wood/vinyl fencing, and any stored items within 5
            feet of your home must have been removed.
          </p>
          <div className="eligibility-banner-link-button-container">
            <Button
              className="eligibility-banner-link-button"
              intent={Intent.PRIMARY}
              text="Get the How-To Prepare My Home Checklist"
              rightIcon={IconNames.ARROW_RIGHT}
              onClick={() => {
                window.open(
                  "https://wildfireprepared.org/wp-content/uploads/WPH-How-To-Prepare-My-Home-Checklist.pdf",
                  "_blank"
                );
              }}
            />
            <Button
              className="eligibility-banner-link-button"
              intent={Intent.PRIMARY}
              text="Take the Optional Free Quiz"
              rightIcon={IconNames.ARROW_RIGHT}
              onClick={() => {
                window.open(
                  "https://wildfireprepared.org/wildfire-prepared-home-base-assessment/",
                  "_blank"
                );
              }}
            />
          </div>
        </>
      }
    />
  );
};
