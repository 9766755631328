import { FieldFormV2, FieldSchemaFormBuilderV2 } from "@ucl/library";
import { NonIdealState } from "@blueprintjs/core";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../../../../types/MultifamilyEvaluationFormModel";
import useSimpleMultifamilyApplicationForm from "./useSimpleMultifamilyApplicationForm";

export interface SimpleMultifamilyApplicationFormProps {
  form: Evaluation_MultifamilyEvaluationFormModel;
}

export const SimpleMultifamilyApplicationForm = (
  props: SimpleMultifamilyApplicationFormProps
) => {
  const {
    formRef,
    applicationFormModel,
    getApplicationFormPartBuilder,
    noBuilders,
    containerRef,
  } = useSimpleMultifamilyApplicationForm(props);

  return (
    <div ref={containerRef} className="wildfire-form-container">
      {noBuilders ? (
        <Loading />
      ) : !applicationFormModel ? (
        <NonIdealState />
      ) : (
        <>
          <FieldFormV2<Evaluation_MultifamilyEvaluationFormModel>
            isDisabled={true}
            value={applicationFormModel}
            fieldSchemaFormBuilder={
              getApplicationFormPartBuilder() as FieldSchemaFormBuilderV2<Evaluation_MultifamilyEvaluationFormModel>
            }
            ref={formRef}
          />
        </>
      )}
    </div>
  );
};

export default SimpleMultifamilyApplicationForm;
