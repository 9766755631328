import { ChangeRequest } from "../../../types/evaluation/ChangeRequest";
import { EvaluationComment } from "../../../types/evaluation/EvaluationComment";
import baseApiClient from "../baseApiClient";

class EvaluationCommentApiClient {
  getEvaluationItemDataComments = async (
    id: string,
    evaluationItemDataId: string
  ) => {
    return await baseApiClient.get<EvaluationComment[]>(
      `evaluation/${id}/data/${evaluationItemDataId}/comment`
    );
  };

  createEvaluationComment = async (
    evaluationId: string,
    evaluationItemDataId: string,
    comment: string,
    isPublic: boolean
  ) => {
    return await baseApiClient.post<{
      evaluationComment: EvaluationComment;
      changeRequest: ChangeRequest;
    }>(`evaluation/${evaluationId}/comment`, {
      evaluationItemDataId,
      comment,
      isPublic,
    });
  };

  deleteEvaluationComment = async (
    evaluationId: string,
    evaluationCommentId: string
  ) => {
    return await baseApiClient.delete<EvaluationComment>(
      `evaluation/${evaluationId}/comment/${evaluationCommentId}`
    );
  };
}

export const evaluationCommentApiClient = new EvaluationCommentApiClient();
