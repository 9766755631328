import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { wildfireUserStore } from "../../stores/WildfireUserStore";
import { AccountInfo } from "@azure/msal-browser";

//TODO: Additional Clean Up needed on MSAL Login, Logout and Redirect.
// See Here for Additional Documentation:
//https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md#usemsal-hook
//https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#browserautherrors

export const useWildfirePagesAuthentication = () => {
  const account = useAccount();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated({
    homeAccountId: account?.homeAccountId,
  });

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    if (!account) {
      handleLogin();
    } else {
      await handleActiveAccount(account);
    }
  };

  const handleLogin = async () => {
    await instance.initialize();
    await instance
      .handleRedirectPromise()
      .then(async (response) => {
        if (response?.account) {
          instance.setActiveAccount(response.account);
          await handleActiveAccount(response.account);
        } else {
          await instance.loginRedirect();
        }
      })
      .catch(async (error) => {
        console.error(error);
      });
  };

  const handleActiveAccount = async (account: AccountInfo) => {
    await wildfireUserStore.initAccount(account);
    setIsInitialized(true);
  };

  return { isInitialized, isAuthenticated };
};
