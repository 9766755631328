export interface FormOption<T extends object> {
  key: string;
  label: string;
  value: T[keyof T];
}
export const enumToOptions = <T extends object>(
  enumObject: T,
  getOptionLabel?: (key: T[keyof T]) => string
): FormOption<T>[] => {
  return Object.entries(enumObject)
    .filter(([_, value]) => typeof value === "number")
    .map(([key, value]) => ({
      key,
      label: getOptionLabel?.(value) ?? key,
      value,
    }));
};

export const enumToOptionsWithInstanceData = <T extends object>(
  enumObject: T,
  includedValues: Array<T[keyof T]>, // Array of enum values to include
  getOptionLabel?: (value: T[keyof T]) => string
): FormOption<Record<string, number>>[] => {
  return Object.entries(enumObject)
    .filter(
      ([_, value]) =>
        typeof value === "number" &&
        includedValues.includes(value as T[keyof T])
    )
    .map(([key, value]) => ({
      key,
      label: getOptionLabel ? getOptionLabel(value as T[keyof T]) : key,
      value: value as number,
    }));
};
