import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { changeRequestApiClient } from "../lib/apiClients/evaluation/changeRequestApiClient";
import {
  ChangeRequest,
  ChangeRequestStatus,
} from "../types/evaluation/ChangeRequest";
import { evaluationStore } from "./EvaluationStore";
import { iterationEngineViewerStore } from "./IterationEngineViewerStore";

class EvaluationChangeRequestStore {
  public changeRequests: ChangeRequest[] = [];

  constructor() {
    makeObservable(this, {
      changeRequests: observable,
      unresolvedChangeRequest: computed,
      selectedChangeRequest: computed,
      currentChangeRequestPosition: computed,
      navigateToChangeRequest: action,
      updateChangeRequest: action,
    });
  }

  get unresolvedChangeRequest() {
    return this.changeRequests.filter(
      (changeRequest) => changeRequest.status !== ChangeRequestStatus.Resolved
    );
  }

  get currentChangeRequestPosition() {
    return this.selectedChangeRequest
      ? this.unresolvedChangeRequest.indexOf(this.selectedChangeRequest)
      : 0;
  }

  get selectedChangeRequest() {
    return this.changeRequests.find(
      (changeRequest) =>
        changeRequest.evaluationItemDataId === evaluationStore.selectedField?.id
    );
  }

  get hasNoUnaddressedChangeRequests() {
    return this.changeRequests.every(
      (changeRequest) => changeRequest.status !== ChangeRequestStatus.New
    );
  }

  get addressedAtLeastOneChangeRequest() {
    return (
      this.changeRequests.length > 0 &&
      this.changeRequests.some(
        (c) => c.status === ChangeRequestStatus.Addressed
      )
    );
  }

  updateChangeRequest = (changeRequest?: ChangeRequest) => {
    let isNew = true;
    if (!changeRequest) {
      return;
    }

    const newChangeRequests = this.changeRequests.map((ogChangeRequest) => {
      if (ogChangeRequest.id === changeRequest.id) {
        isNew = false;
        return changeRequest;
      }
      return ogChangeRequest;
    });

    if (isNew) {
      newChangeRequests.push(changeRequest);
    }

    this.changeRequests = newChangeRequests;
  };

  navigateToChangeRequest = (idx: number) => {
    const toChangeRequest = this.unresolvedChangeRequest[idx];

    if (toChangeRequest) {
      evaluationStore.setSelectedField(toChangeRequest.evaluationItemDataId);

      setTimeout(() => {
        iterationEngineViewerStore.scrollToRef();
      }, 1);
    }
  };

  setChangeRequests = async (
    evaluationId: string,
    changeRequestId?: string,
    complexCommercialEvaluationBuildingIds?: string[]
  ) => {
    let allChangeRequests: ChangeRequest[] = [];

    const evaluationChangeRequestsPromise =
      changeRequestApiClient.getChangeRequestsByEvaluation(evaluationId);

    let complexCommercialChangeRequestsPromises: Promise<ChangeRequest[]>[] =
      [];

    if (complexCommercialEvaluationBuildingIds) {
      complexCommercialChangeRequestsPromises =
        complexCommercialEvaluationBuildingIds.map(
          async (buildingId) =>
            await changeRequestApiClient.getChangeRequestsByEvaluation(
              buildingId
            )
        );
    }

    const [evaluationChangeRequests, ...complexCommercialChangeRequestsArrays] =
      await Promise.all([
        evaluationChangeRequestsPromise,
        ...complexCommercialChangeRequestsPromises,
      ]);

    // Combine the arrays of change requests
    allChangeRequests = [
      ...evaluationChangeRequests,
      ...complexCommercialChangeRequestsArrays.flat(),
    ];

    runInAction(() => {
      this.changeRequests = allChangeRequests.sort(
        (prev, next) =>
          (evaluationStore.getEvaluationFieldById(prev.evaluationItemDataId)
            ?.sortOrder || 0) -
          (evaluationStore.getEvaluationFieldById(next.evaluationItemDataId)
            ?.sortOrder || 0)
      );

      if (this.changeRequests.length > 0 && !!changeRequestId) {
        const latestChangeRequest =
          changeRequestId === "new"
            ? allChangeRequests.reduce((prev, current) =>
                prev.createdAt > current.createdAt ? prev : current
              )
            : allChangeRequests.find((x) => x.id === changeRequestId);

        const latestChangeRequestIndex = latestChangeRequest
          ? allChangeRequests.indexOf(latestChangeRequest)
          : 0;

        this.navigateToChangeRequest(latestChangeRequestIndex);
      } else {
        this.navigateToChangeRequest(0);
      }
    });
  };

  resolveChangeRequest = async () => {
    if (!(evaluationStore.evaluation && this.selectedChangeRequest)) {
      return;
    }

    await changeRequestApiClient.resolveChangeRequest(
      evaluationStore.evaluation.id,
      this.selectedChangeRequest.id
    );

    this.changeRequests = this.changeRequests.map((changeRequest) => {
      return changeRequest.id === this.selectedChangeRequest?.id
        ? {
            ...changeRequest,
            status: ChangeRequestStatus.Resolved,
            status_AsString: "Resolved",
          }
        : changeRequest;
    });

    this.setSelectedFieldToCloest();
  };

  unresolveChangeRequest = async () => {
    if (!(evaluationStore.evaluation && this.selectedChangeRequest)) {
      return;
    }

    await changeRequestApiClient.unresolveChangeRequest(
      evaluationStore.evaluation.id,
      this.selectedChangeRequest.id
    );

    this.changeRequests = this.changeRequests.map((changeRequest) => {
      return changeRequest.id === this.selectedChangeRequest?.id
        ? {
            ...changeRequest,
            status: ChangeRequestStatus.New,
            status_AsString: "New",
          }
        : changeRequest;
    });

    // iterationEngineCommentViewerStore.setPositionToCurrentSelectField();
  };

  setSelectedFieldToCloest = () => {
    if (this.unresolvedChangeRequest.length === 0) {
      return;
    }

    this.navigateToChangeRequest(
      this.currentChangeRequestPosition - 1 < 0
        ? 0
        : this.currentChangeRequestPosition - 1
    );
  };

  getFieldChangeRequestState = (itemDataId: string) => {
    const match = (cr: ChangeRequest) => cr.evaluationItemDataId === itemDataId;
    const changeRequest = this.changeRequests.find(match);
    const isActive = !!changeRequest;

    return {
      isSelected:
        this.selectedChangeRequest?.evaluationItemDataId === itemDataId,
      isActive,
      isActiveAndUnresolved:
        isActive && changeRequest.status !== ChangeRequestStatus.Resolved,
      isActiveAndResolved:
        isActive && changeRequest.status === ChangeRequestStatus.Resolved,
    };
  };

  get longestOutstandingChangeRequest() {
    const changeRequest = this.changeRequests.reduce((prev, current) => {
      const prevDays = prev.daysInChangesRequired || 0;
      const currentDays = current.daysInChangesRequired || 0;
      return prevDays > currentDays ? prev : current;
    });

    return changeRequest;
  }
}

export const evaluationChangeRequestStore = new EvaluationChangeRequestStore();
