import { FormGroup, InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";
import { FormstackAddress } from "../../../types/formstack/fieldValueTypes/FormstackAddress";
import { AddressField as InterationAddressField } from "../../../types/viewModels/IterationEngineViewModel";
import { BaseFieldProps } from "../types";
import "./styles.scss";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";

export interface AddressFieldProps
  extends BaseFieldProps<string>,
    Omit<InterationAddressField, "id" | "sortOrder" | "isHidden"> {}

export const AddressField: React.FC<AddressFieldProps> = observer(
  ({
    fieldId,
    label,
    value,
    isSelectedField,
    hasActiveChangeRequest,
    isSelectedChangeRequest,
    isResovledChangeRequest,
    hasActiveInternalComment,
    disabled,
    onSubmit,
    isExternalView,
  }) => {
    const getDefaultAddress = (): FormstackAddress => {
      return {
        address: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
      };
    };

    const [localAddress, setLocalAddress] = React.useState<FormstackAddress>(
      value || getDefaultAddress()
    );

    return (
      <FormGroup>
        <SelectableLabel
          name={label}
          fieldId={fieldId}
          value={value}
          isSelectedField={isSelectedField}
          hasActiveChangeRequest={hasActiveChangeRequest}
          isSelectedChangeRequest={isSelectedChangeRequest}
          isResovledChangeRequest={isResovledChangeRequest}
          hasActiveInternalComment={hasActiveInternalComment}
          shouldDisableFieldLabelClick={isExternalView}
        />

        <div className="address-field_input">
          <InputGroup
            defaultValue={localAddress.address}
            disabled={disabled}
            onChange={(e) => {
              setLocalAddress({ ...localAddress, address: e.target.value });
            }}
            onBlur={() => onSubmit(JSON.stringify(localAddress))}
          />
          <span className="address-field_input_helper">Address</span>
        </div>
        <div className="address-field_input">
          <InputGroup
            defaultValue={localAddress.address2}
            disabled={disabled}
            onChange={(e) => {
              setLocalAddress({ ...localAddress, address2: e.target.value });
            }}
            onBlur={() => onSubmit(JSON.stringify(localAddress))}
          />
          <span className="address-field_input_helper">Address 2</span>
        </div>
        <div className="flex-field">
          <div className="address-field_input">
            <InputGroup
              defaultValue={localAddress.city}
              disabled={disabled}
              onChange={(e) => {
                setLocalAddress({ ...localAddress, city: e.target.value });
              }}
              onBlur={() => onSubmit(JSON.stringify(localAddress))}
            />
            <span className="address-field_input_helper">City</span>
          </div>
          <div className="address-field_input">
            <InputGroup
              defaultValue={localAddress.state}
              disabled={disabled}
              onChange={(e) => {
                setLocalAddress({ ...localAddress, state: e.target.value });
              }}
              onBlur={() => onSubmit(JSON.stringify(localAddress))}
            />
            <span className="address-field_input_helper">State</span>
          </div>
          <div className="address-field_input">
            <InputGroup
              defaultValue={localAddress.zip}
              disabled={disabled}
              onChange={(e) => {
                setLocalAddress({ ...localAddress, zip: e.target.value });
              }}
              onBlur={() => onSubmit(JSON.stringify(localAddress))}
            />
            <span className="address-field_input_helper">Zip</span>
          </div>
        </div>
      </FormGroup>
    );
  }
);
