import { Card } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import React from "react";
import { ExternalEvaluationBidSummary } from "../../../types/evaluation/Evaluation";
import { ExternalEvaluationForm } from "./ExternalEvaluationForm";

export interface ExternalEvaluationBidSummaryContainerProps {
  externalEvaluationSummary: ExternalEvaluationBidSummary;
}

const ExternalEvaluationBidSummaryContainer: React.FC<
  ExternalEvaluationBidSummaryContainerProps
> = ({ externalEvaluationSummary }) => {
  return (
    <Observer>
      {() => {
        return (
          <div className={`evaluation-container__content`}>
            <Card
              id="evaluation-form"
              className="evaluation-container__external-card"
            >
              <div>
                {externalEvaluationSummary.evaluationForms.map((form) => {
                  return (
                    <div key={form.id} id={form.id}>
                      {
                        <ExternalEvaluationForm
                          evaluationForm={form}
                          externalFolderId={
                            externalEvaluationSummary.externalFolderId
                          }
                        />
                      }
                    </div>
                  );
                })}
              </div>
            </Card>
          </div>
        );
      }}
    </Observer>
  );
};

export default ExternalEvaluationBidSummaryContainer;
