import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../AppInsights";
import { reports } from "./reports/reports";
import { Button, H2 } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import report_background from "../assets/report_background.png";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { PermissionsContext, PermissionsContextProps } from "@ucl/library";
import { wildfireRoutePrefix } from "./index/wildfireRouteConfig";

export interface WildfireReportsPageProps {}

const WildfireReportsPageComponent: React.FC<WildfireReportsPageProps> = () => {
  const navigate = useNavigate();
  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  return (
    <div className="reports-page">
      {hasPermission("CanViewReportsPanel") && (
        <div className="reports-page__reports">
          {Object.values(reports(hasPermission))
            .filter((value, _index, _array) => value.hasViewPermission)
            .map((report) => {
              return (
                <div
                  key={report.slug}
                  className="reports-page__reports__report-item"
                >
                  <img
                    src={report_background}
                    width={492}
                    height={331}
                    alt="Report Background"
                  />
                  <div className="reports-page__reports__report-item__overlay-container">
                    <H2>{report.title}</H2>
                    <Button
                      minimal
                      intent="primary"
                      outlined
                      text="View Report"
                      icon={IconNames.EYE_OPEN}
                      onClick={() => {
                        navigate(
                          `${wildfireRoutePrefix}/reports/${report.slug}`
                        );
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export const WildfireReportsPage = withAITracking(
  reactPlugin,
  WildfireReportsPageComponent,
  "WildfireReportsPage",
  "tracking-container"
);
