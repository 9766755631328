import { ProductTypeHandlerProvider } from "../../foritfied/providers/ProductTypeHandlerProvider";
import {
  fortified_Commercial_Multifamily_browserRouter,
  fortifiedCommercialMultifamilyRoutePrefix,
} from "../../foritfied/pages/index/fortifiedCommercialMultifamilyRouteConfig";
import {
  fortifiedCommercialMultifamilyLogout,
  fortifiedCommercialMultifamilyMsalInstance,
  getFortifiedCommercialMultifamilyAccessToken,
} from "../lib/msal/fortifiedCommercialMultifamilyMsal";
import { fortified_Commercial_Multifamily_ExternalBrowserRouter } from "../../foritfied/pages/index/fortifiedCommercialMultifamilyExternalRouteConfig";
import Pages from "../../foritfied/pages/index/index";

export const FCFMFProductTypeHandler = () => {
  return (
    <ProductTypeHandlerProvider
      routePrefix={fortifiedCommercialMultifamilyRoutePrefix}
      getAccessToken={getFortifiedCommercialMultifamilyAccessToken}
      msalInstance={fortifiedCommercialMultifamilyMsalInstance}
      router={fortified_Commercial_Multifamily_browserRouter()}
      externalRouter={fortified_Commercial_Multifamily_ExternalBrowserRouter}
      logoutUser={fortifiedCommercialMultifamilyLogout}
    >
      <Pages />
    </ProductTypeHandlerProvider>
  );
};
