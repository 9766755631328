import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import React from "react";
import { useParams } from "react-router";
import { Header } from "../../components/Header/Header";
import { PageSidebar } from "../../components/PageSidebar/PageSidebar";
import "../styles.scss";
import reports, { FortifiedReportTypes } from "./reports";
import { reactPlugin } from "../../../AppInsights";
import {
  errorStore,
  genericErrorMessage,
} from "../../../common/Components/Error/ErrorStore";
import { permissionStore } from "../../stores/PermissionStore";
import { useProductTypeHandler } from "../../providers/ProductTypeHandlerProvider";

export const ReportViewerPageComponent: React.FC = () => {
  const { reportType } = useParams<{ reportType: FortifiedReportTypes }>();

  const { routePrefix } = useProductTypeHandler();

  if (!reportType) {
    errorStore.setErrorMessage(genericErrorMessage);
    return null;
  }

  const report = reports[reportType];

  return (
    <div className="fortified-page board-page">
      <Observer>
        {() => (
          <Header
            breadCrumbs={[
              { href: `${routePrefix}/reports`, text: "Reports" },
              { text: report.title },
            ]}
          />
        )}
      </Observer>
      <div className="content">
        <PageSidebar activePage="reports" />
        <>
          {permissionStore.canViewReportsPage && (
            <div className="page-main-content">
              <iframe
                title={report.title}
                width="100%"
                height="100%"
                src={report.reportLink}
                frameBorder="0"
              ></iframe>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export const ReportViewerPage = withAITracking(
  reactPlugin,
  ReportViewerPageComponent,
  "ReportViewerPage",
  "tracking-container"
);
