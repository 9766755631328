import { FieldFormV2, FieldSchemaFormBuilderV2 } from "@ucl/library";
import { Button, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useNavigate } from "react-router";
import { RoofFieldEvaluationFormParts } from "../../../forms/RoofFieldEvaluationForm/RoofFieldEvaluationFormModel";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import "../styles.scss";
import useRoofFieldEvaluationForm from "./useRoofFieldEvaluationForm";
import { EvaluationFormModel } from "../../../forms/EvaluationFormModel";
import classnames from "classnames";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";

export interface RoofFieldEvaluationFormProps {
  wildfireEvaluationId: string;
  isWiePage?: boolean;
  formPart?: RoofFieldEvaluationFormParts;
  formModel?: EvaluationFormModel;
  setFormModel?: (model: EvaluationFormModel) => void;
  showFormControls?: boolean;
  onFormSave?: (fieldKey: string, value: any) => Promise<void>;
  disableForm?: boolean;
  onInitialized?: () => void;
  setHasUnsavedChanges: (value: boolean) => void;
}

export const RoofFieldEvaluationForm = (
  props: RoofFieldEvaluationFormProps
) => {
  const navigate = useNavigate();

  const {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    roofFieldEvaluationFormModel,
    setShouldSubmit,
    isFirstPartOfForm,
    isLastPartOfForm,
    getRoofFieldEvaluationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    isInitialized,
  } = useRoofFieldEvaluationForm(props);

  const noBuilders = !builders && !wieBuilders;
  return (
    <div className="wildfire-form-container">
      {(noBuilders || isLoading || !isInitialized) && <Loading />}
      {!roofFieldEvaluationFormModel && <NonIdealState />}
      {!noBuilders && !isLoading && isInitialized && (
        <>
          <FieldFormV2<EvaluationFormModel>
            isDisabled={isSubmitting || props.disableForm}
            value={roofFieldEvaluationFormModel}
            fieldSchemaFormBuilder={
              getRoofFieldEvaluationFormPartBuilder() as FieldSchemaFormBuilderV2<EvaluationFormModel>
            }
            onFormSubmit={async (form) => handleFormSubmit(form)}
            ref={formRef}
            onFieldChanged={async (form, value, fieldProps) => {
              await onFormFieldChange(form, value, fieldProps);
            }}
          />

          {(props.showFormControls ?? true) && (
            <div className="wildfire-form-footer">
              {!isFirstPartOfForm && (
                <Button
                  className={
                    "wildfire-form-back-button " +
                    "wildfire-form-navigation-button"
                  }
                  text="Back"
                  icon={IconNames.ARROW_LEFT}
                  onClick={() => {
                    // Navigate to the previous part of the form
                    navigate(
                      `${wildfireRoutePrefix}/${
                        props.wildfireEvaluationId
                      }/roof-field-evaluation-form/${Object.values(
                        RoofFieldEvaluationFormParts
                      )[
                        Object.values(RoofFieldEvaluationFormParts).indexOf(
                          props.formPart!
                        ) - 1
                      ].valueOf()}`
                    );
                  }}
                />
              )}
              <Button
                className={classnames({
                  "wildfire-form-submit-button": isLastPartOfForm,
                  "wildfire-form-continue-button": !isLastPartOfForm,
                  "wildfire-form-navigation-button": !isLastPartOfForm,
                })}
                text={isLastPartOfForm ? "Submit" : "Continue"}
                rightIcon={
                  isLastPartOfForm ? IconNames.TICK : IconNames.ARROW_RIGHT
                }
                disabled={isSubmitting || props.disableForm}
                loading={isSubmitting}
                onClick={() => {
                  if (formRef.current) {
                    props.setHasUnsavedChanges(false);
                    setShouldSubmit(true);
                    setIsSubmitting(true);
                    formRef.current.submit();
                  }
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RoofFieldEvaluationForm;
