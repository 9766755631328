import { Tab, Tabs } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { useState } from "react";
import { Evaluation, EvaluationForm } from "../../types/evaluation/Evaluation";
import { EvaluationSchema } from "../../types/evaluation/EvaluationSchema";
import { ChecklistTabPanel } from "./ChecklistTabPanel";
import "./styles.scss";
import EvaluationHistory from "../Evaluation/History/EvaluationHistory";

const whereUserRoleIsFormCompleter = (
  forms: EvaluationForm[],
  userRoleId: number
) =>
  forms.some((form) => form.evaluationItemCompleterRoles.includes(userRoleId));

interface ChecklistContainerProps {
  evaluation: Evaluation;
  schema: EvaluationSchema;
}

export const ChecklistContainer: React.FC<ChecklistContainerProps> = ({
  evaluation,
  schema,
}) => {
  const [selectedTabId, setSelectedTabId] = useState(0);

  const tabs = schema.userRoles.filter((userRole) =>
    whereUserRoleIsFormCompleter(evaluation.evaluationForms, userRole.id)
  );

  return (
    <Observer>
      {() => (
        <div className="checklist-container">
          <Tabs
            vertical
            selectedTabId={selectedTabId}
            onChange={(newTabId: number) => setSelectedTabId(newTabId)}
          >
            {[{ id: 0, label: "All" }].concat(tabs).map((tab) => {
              return (
                <Tab
                  key={tab.id}
                  id={tab.id}
                  title={tab.label}
                  panel={
                    <ChecklistTabPanel
                      selectedUserRole={schema.userRoles.find(
                        (role) => role.id === selectedTabId
                      )}
                      evaluation={evaluation}
                      schema={schema}
                    />
                  }
                />
              );
            })}
            <EvaluationHistory evaluationId={evaluation.id} />
          </Tabs>
        </div>
      )}
    </Observer>
  );
};
