import "../styles.scss";

export interface FormFieldErrorContainerProps {
  errorMessages: string[];
}

/**Primary Container for field error display*/
export const FormFieldErrorContainer: React.FC<
  FormFieldErrorContainerProps
> = ({ errorMessages }) => {
  return (
    <div>
      {errorMessages.map((errorMessage, idx) => (
        <p key={idx} className="error-message">
          {errorMessage}
        </p>
      ))}
    </div>
  );
};
