import { Observer } from "mobx-react";
import { evaluationStore } from "../../stores/EvaluationStore";
import { iterationEngineViewerStore } from "../../stores/IterationEngineViewerStore";
import { CommentContainer } from "../Comment/CommentContainer";
import "./evaluation.scss";
import { EvaluationFormWrapper } from "./EvaluationFormWrapper";
import { EvaluationStatuses } from "./EvaluationStatuses";
import { useEffect } from "react";
import { permissionStore } from "../../stores/PermissionStore";
import { useNavigate } from "react-router";
import { useProductTypeHandler } from "../../providers/ProductTypeHandlerProvider";

interface EvaluationContainerProps {}

export const EvaluationContainer: React.FC<EvaluationContainerProps> = () => {
  const navigate = useNavigate();
  const { routePrefix } = useProductTypeHandler();

  useEffect(() => {
    if (
      permissionStore.shouldNavigateToBidsPage &&
      evaluationStore.evaluation
    ) {
      navigate(`${routePrefix}/bid/${evaluationStore.evaluation.id}`);
    }
  }, [evaluationStore.evaluation?.step]);

  return (
    <Observer>
      {() => {
        return !!evaluationStore.schema && !!evaluationStore.evaluation ? (
          <>
            <div className="evaluation-container">
              <EvaluationStatuses
                evaluationStatus={evaluationStore.evaluation.step}
                isApproved={evaluationStore.evaluation.isApproved}
                evaluationSchema={evaluationStore.schema}
                formTabs={evaluationStore.evaluation.evaluationForms
                  .filter(
                    (form) =>
                      form.evaluationFields.filter((field) => field.isActive)
                        .length > 0
                  )
                  .sort((a, b) => a.sortOrder - b.sortOrder)
                  .map((form) => ({
                    id: form.evaluationItemType,
                    label: form.evaluationItemTypes_AsString,
                  }))}
                selectedTabId={iterationEngineViewerStore.selectedTabId}
                setSelectedTabId={iterationEngineViewerStore.setSelectedTabId}
                evaluationId={evaluationStore.evaluation.id}
                expiresAt={evaluationStore.evaluation.expiresAt}
                isRedesignation={evaluationStore.evaluation.isRedesignation}
                evaluationExpirationDateType_AsString={
                  evaluationStore.evaluation
                    .previousEvaluationExpirationDateType_AsString
                }
              />
              <EvaluationFormWrapper
                evaluation={evaluationStore.evaluation}
                formFilter={
                  evaluationStore.isFortifiedHomeEvaluation
                    ? (form) =>
                        form.evaluationItemType ===
                        iterationEngineViewerStore.selectedTabId
                    : (_) => true
                }
              />
              <CommentContainer />
            </div>
          </>
        ) : null;
      }}
    </Observer>
  );
};
