import { SecondaryButton } from "@ucl/library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";
import classNames from "classnames";

interface WildfireSecondaryButtonProps {
  buttonText?: string;
  isLoading?: boolean;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const WildfireSecondaryButton: React.FC<WildfireSecondaryButtonProps> = (
  props
) => {
  const buttonText = !!props.buttonText ? props.buttonText : "Cancel";

  return (
    <div className={classNames(["wildfire-secondary-button", props.className])}>
      <SecondaryButton disabled={props.disabled} onClick={props.onClick}>
        <FontAwesomeIcon icon={faClose} /> <div>{buttonText}</div>
      </SecondaryButton>
    </div>
  );
};

export default WildfireSecondaryButton;
