import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { FCFMFFieldSchemaModel } from "./fcfmfFieldSchemaConfig";
import { commercialIterationEngineApplicationFormBuilder } from "../../../commercial/components/forms/ApplicationForm/CommercialApplicationFormBuilder";
import { commercialIterationEngineEvaluationCompanyAssignmentFormBuilder } from "../../../commercial/components/forms/EvaluationCompanyAssignmentForm/CommercialEvaluationCompanyAssignmentFormBuilder";
import { multifamilyIterationEngineEvaluationCompanyAssignmentFormBuilder } from "../../../multifamily/components/forms/EvaluationCompanyAssignmentForm/MultifamilyEvaluationCompanyAssignmentFormBuilder";
import { multifamilyIterationEngineEvaluationFieldInspectionFormBuilder } from "../../../multifamily/components/forms/EvaluationFieldInspectionForm/MultifamilyEvaluationFieldInspectionFormBuilder";
import { commercialIterationEngineComplianceFormBuilder } from "../../../commercial/components/forms/ComplianceForm/CommercialComplianceFormBuilder";
import { multifamilyIterationEngineComplianceFormBuilder } from "../../../multifamily/components/forms/ComplianceForm/MultifamilyComplianceFormBuilder";
import { commercialIterationEngineComplianceLetterBuilder } from "../../../commercial/components/forms/ComplianceLetter/CommercialComplianceLetterBuilder";
import { multifamilyIterationEngineComplianceLetterBuilder } from "../../../multifamily/components/forms/ComplianceLetter/MultifamilyComplianceLetterBuilder";
import { commercialIterationEngineEvaluationFieldInspectionFormBuilder } from "../../../commercial/components/forms/EvaluationFieldInspectionForm/CommercialEvaluationFieldInspectionFormBuilder";
import { commercialIterationEngineRedesignationFormBuilder } from "../../../commercialRedesignation/components/forms/ApplicationForm/CommercialRedesignationFormBuilder";
import { multifamilyIterationEngineRedesignationFormBuilder } from "../../../multifamilyRedesignation/components/forms/ApplicationForm/MultifamilyRedesignationFormBuilder";
import { commercialIterationEngineRedesignationEvaluatorEmailFormBuilder } from "../../../commercialRedesignation/components/forms/RedesignationEvaluatorEmailForm/CommercialRedesignationEvaluatorEmailFormBuilder";
import { multifamilyIterationEngineRedesignationEvaluatorEmailFormBuilder } from "../../../multifamilyRedesignation/components/forms/RedesignationEvaluatorEmailForm/MultifamilyRedesignationEvaluatorEmailFormBuilder";
import { commercialBuildingIterationEngineApplicationFormBuilder } from "../../../commercial/components/ComplexCommercialEvaluationBuilding/Form/CommercialBuildingApplicationFormBuilder";
import { multifamilyBuildingIterationEngineApplicationFormBuilder } from "../../../multifamily/components/ComplexCommercialEvaluationBuilding/Form/MultifamilyBuildingApplicationFormBuilder";
import { multifamilyIterationEngineApplicationFormBuilder } from "../../../multifamily/components/forms/ApplicationForm/MultifamilyApplicationFormBuilder";

export type FCFMFIterationEngineFieldSchemaFormType =
  typeof FCFMFIterationEngineFieldSchemaFormTypeList[number];

export const FCFMFIterationEngineFieldSchemaFormTypeList = [
  "CommercialApplicationForm",
  "CommercialEvaluationCompanyAssignmentForm",
  "CommercialComplianceForm",
  "CommercialComplianceLetter",
  "CommercialEvaluationFieldInspectionForm",

  "CommercialBuildingApplicationForm",
  "CommercialRedesignationForm",
  "CommercialRedesignationEvaluatorEmailForm",

  "MultifamilyApplicationForm",
  "MultifamilyEvaluationCompanyAssignmentForm",
  "MultifamilyEvaluationFieldInspectionForm",
  "MultifamilyComplianceForm",
  "MultifamilyComplianceLetter",

  "MultifamilyBuildingApplicationForm",
  "MultifamilyRedesignationForm",
  "MultifamilyRedesignationEvaluatorEmailForm",
] as const;

export type FCFMFIterationEngineFieldSchemaFormBuildersConfig = Record<
  FCFMFIterationEngineFieldSchemaFormType,
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ) => Partial<FieldSchemaFormBuilderV2<FCFMFFieldSchemaModel>>
>;
export const fcfmfIterationEngineFieldSchemaFormBuilders: FCFMFIterationEngineFieldSchemaFormBuildersConfig =
  {
    CommercialApplicationForm: commercialIterationEngineApplicationFormBuilder,
    CommercialEvaluationCompanyAssignmentForm:
      commercialIterationEngineEvaluationCompanyAssignmentFormBuilder,
    CommercialEvaluationFieldInspectionForm:
      commercialIterationEngineEvaluationFieldInspectionFormBuilder,
    CommercialComplianceForm: commercialIterationEngineComplianceFormBuilder,
    CommercialComplianceLetter:
      commercialIterationEngineComplianceLetterBuilder,

    CommercialBuildingApplicationForm:
      commercialBuildingIterationEngineApplicationFormBuilder,
    CommercialRedesignationForm:
      commercialIterationEngineRedesignationFormBuilder,
    CommercialRedesignationEvaluatorEmailForm:
      commercialIterationEngineRedesignationEvaluatorEmailFormBuilder,

    MultifamilyApplicationForm:
      multifamilyIterationEngineApplicationFormBuilder,
    MultifamilyComplianceForm: multifamilyIterationEngineComplianceFormBuilder,
    MultifamilyComplianceLetter:
      multifamilyIterationEngineComplianceLetterBuilder,
    MultifamilyEvaluationCompanyAssignmentForm:
      multifamilyIterationEngineEvaluationCompanyAssignmentFormBuilder,
    MultifamilyEvaluationFieldInspectionForm:
      multifamilyIterationEngineEvaluationFieldInspectionFormBuilder,

    MultifamilyBuildingApplicationForm:
      multifamilyBuildingIterationEngineApplicationFormBuilder,
    MultifamilyRedesignationForm:
      multifamilyIterationEngineRedesignationFormBuilder,
    MultifamilyRedesignationEvaluatorEmailForm:
      multifamilyIterationEngineRedesignationEvaluatorEmailFormBuilder,
  };
