import { NumericField } from "../NumericField/NumericField";
import { NumericTextFieldProps } from "../NumericField/NumericField";

export interface CurrencyFieldProps extends NumericTextFieldProps<number> {
  isRequired?: boolean;
  min?: number;
}

export const CurrencyField: React.FC<CurrencyFieldProps> = ({
  label,
  description,
  value,
  disabled,
  hidden,
  min,
  onChange,
}) => {
  return (
    <NumericField
      type="Currency"
      description={description}
      label={label}
      numericFieldProps={{
        format: "c2",
        placeholder: "$0.00",
        currency: "USD",
        min: min || 0.0,
      }}
      value={value}
      disabled={disabled}
      hidden={hidden}
      onChange={onChange}
    />
  );
};
