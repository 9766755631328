import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";
import "normalize.css/normalize.css";
import "./App.scss";
import variables from "./common/config/variables";
import { registerLicense, validateLicense } from "@syncfusion/ej2-base";
import { configure } from "mobx";
import { productTypeRouteConfig } from "./common/pages/productTypeRouteConfig";

// Register Syncfusion License
registerLicense(variables.syncfusionLicenseKey);
validateLicense();

// Determine the current route's component
const getProductTypePagesByRoutePrefix = () => {
  const currentPath = window.location.pathname;

  const matchingRoute = productTypeRouteConfig.find((route) =>
    currentPath.startsWith(route.pathPrefix)
  );

  return matchingRoute ? matchingRoute.component : <></>;
};

function App() {
  configure({
    disableErrorBoundaries: true,
  });

  const RouteComponent = getProductTypePagesByRoutePrefix();

  return RouteComponent;
}

export default App;
