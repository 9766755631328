import React, { useEffect, useState } from "react";
import { CommercialEvaluationOptions } from "../../../../types/schema";
import { EvaluationChild } from "../../../../types/evaluation/Evaluation";
import { ReadOnlyField } from "@ucl/library";

export interface CommercialEvaluationFieldInfoContainerProps {
  selectedOption: CommercialEvaluationOptions;
  selectedChildOption: EvaluationChild | undefined;
}

const CommercialEvaluationFieldInfoContainer: React.FC<
  CommercialEvaluationFieldInfoContainerProps
> = (props) => {
  const [selectedOption, setSelectedOption] =
    useState<CommercialEvaluationOptions>();

  useEffect(() => {
    setSelectedOption(props.selectedOption);
  }, [props.selectedOption]);

  const [selectedChildOption, setSelectedChildOption] =
    useState<EvaluationChild>();

  useEffect(() => {
    setSelectedChildOption(props.selectedChildOption);
  }, [props.selectedChildOption]);

  return (
    <>
      {!!selectedOption && (
        <div className="redesignation-dialog-evaluation-values">
          <div>
            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.productType_AsString}
              label={"Program"}
            />
            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.designationLevel_AsString}
              label={"Designation Level"}
            />
            <ReadOnlyField
              type="ReadOnly"
              value={
                selectedOption.approvedAt_AsDateOnly
                  ? selectedOption.approvedAt_AsDateOnly.toDateString()
                  : ""
              }
              label={"Approved At"}
            />
            <ReadOnlyField
              type="ReadOnly"
              value={
                selectedOption.expiresAt_AsDateOnly
                  ? selectedOption.expiresAt_AsDateOnly.toDateString()
                  : ""
              }
              label={"Expiration Date"}
            />
            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.isNewConstruction ? "Yes" : "No"}
              label={"Is New Construction"}
            />
            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.buildingAddress}
              label={"Building Address"}
            />
            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.buildingAddress2 || ""}
              label={"Building Address 2"}
            />

            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.buildingCity}
              label={"Building City"}
            />
            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.buildingCounty || ""}
              label={"Building County"}
            />
            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.buildingState_AsString}
              label={"Building State"}
            />
            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.buildingZip}
              label={"Building Zip"}
            />
            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.hazard_AsString}
              label={"Hazard Type"}
            />
            {!!selectedChildOption && (
              <ReadOnlyField
                type="ReadOnly"
                value={
                  selectedChildOption.evaluationExpirationDateType_AsString
                }
                label={"Expiration Status"}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CommercialEvaluationFieldInfoContainer;
