import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC, useContext } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { CreateWildfireApplicationCard } from "../CreateApplicationCard/CreateWildfireApplicationCard";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { WildfireApplicationOverviewCard } from "../ApplicationOverviewCards/WildfireApplicationOverviewCard";
import { CreateWildfireApplicationButton } from "../../Button/CreateApplicationButton/CreateWildfireApplicationButton";
import { useWildfireApplications } from "../../../customHooks/useWildfireApplications";
import { PermissionsContext, PermissionsContextProps } from "@ucl/library";
import "./styles.scss";

export interface WildfireApplicantBoardComponentProps {}

const WildfireApplicantBoardComponent: FC<
  WildfireApplicantBoardComponentProps
> = () => {
  const { wildfireApplications, isLoading, fetchWildfireApplications } =
    useWildfireApplications();

  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  return (
    <div className="wildfire-page board-page">
      {isLoading || !wildfireApplications ? (
        <Loading />
      ) : wildfireApplications.length == 0 ? (
        <>
          {hasPermission("CanViewCreateEvaluationApplicationButton") && (
            <>
              <CreateWildfireApplicationCard />
            </>
          )}
        </>
      ) : (
        <>
          {hasPermission("CanViewCreateEvaluationApplicationButton") && (
            <>
              <CreateWildfireApplicationButton />
            </>
          )}

          <div className="wildfire-application-overview-cards-container">
            {wildfireApplications.map((application, index) => {
              return (
                <WildfireApplicationOverviewCard
                  key={application.id}
                  wildfireEvaluation={application}
                  fetchWildfireApplications={fetchWildfireApplications}
                  index={index}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export const WildfireApplicantBoard = withAITracking(
  reactPlugin,
  WildfireApplicantBoardComponent,
  "WildfireBoard",
  "tracking-container"
);
