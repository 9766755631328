import { BoxRequest } from "./types";

class BoxApiClient {
  getImage = async (data: BoxRequest) => {
    return fetch(`https://api.box.com/2.0/files/${data.fileId}/content`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });
  };

  deleteImage = async (data: BoxRequest) => {
    return fetch(`https://api.box.com/2.0/files/${data.fileId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });
  };
}

export const boxApiClient = new BoxApiClient();
