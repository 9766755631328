const getEnv = (name: string, isRequired?: boolean) => {
  const envVar = process.env[name];
  if (!!isRequired && !envVar) {
    throw new Error(`Missing Env Var ${name}`);
  }

  return envVar as string;
};

export default {
  /** Common B2C */
  domain: getEnv("REACT_APP_DOMAIN", true),
  clientId: getEnv("REACT_APP_CLIENT_ID", true),
  tenantId: getEnv("REACT_APP_TENANT_ID", true),
  scope: getEnv("REACT_APP_SCOPE", true),

  /** Fortified FC FMF B2C */
  fortified_Commercial_Multifamily_RedirectUri: getEnv(
    "REACT_APP_FORTIFIED_COMMERCIAL_MULTIFAMILY_REDIRECT_URI",
    true
  ),
  fortified_Commercial_Multifamily_SignUpSignInUserFlow: getEnv(
    "REACT_APP_FORTIFIED_COMMERCIAL_MULTIFAMILY_SIGN_UP_SIGN_IN_USER_FLOW",
    true
  ),
  fortified_Commercial_Multifamily_PostLogoutRedirectUri: getEnv(
    "REACT_APP_FORTIFIED_COMMERCIAL_MULTIFAMILY_POST_LOGOUT_REDIRECT_URI",
    true
  ),

  /** Fortified Home B2C */
  fortified_Home_RedirectUri: getEnv(
    "REACT_APP_FORTIFIED_HOME_REDIRECT_URI",
    true
  ),
  fortified_Home_SignUpSignInUserFlow: getEnv(
    "REACT_APP_FORTIFIED_HOME_SIGN_UP_SIGN_IN_USER_FLOW",
    true
  ),
  fortified_Home_PostLogoutRedirectUri: getEnv(
    "REACT_APP_FORTIFIED_HOME_POST_LOGOUT_REDIRECT_URI",
    true
  ),

  /** Wildfire B2C */
  wildfire_RedirectUri: getEnv("REACT_APP_WILDFIRE_REDIRECT_URI", true),
  wildfire_SignUpSignInUserFlow: getEnv(
    "REACT_APP_WILDFIRE_SIGN_UP_SIGN_IN_USER_FLOW",
    true
  ),
  wildfire_PostLogoutRedirectUri: getEnv(
    "REACT_APP_WILDFIRE_POST_LOGOUT_REDIRECT_URI",
    true
  ),

  applicationInsightInstrumentationKey: getEnv(
    "REACT_APP_APPLICATION_INSIGHT_INSTRUMENTATION_KEY",
    true
  ),

  /** In order to have access token being returned, one must expose and API and assign API permission to the B2C App */

  newCommercialEvaluationLink: getEnv(
    "REACT_APP_NEW_COMMERCIAL_EVALUATION_LINK",
    true
  ),
  newHomeEvaluationLink: getEnv("REACT_APP_NEW_HOME_EVALUATION_LINK", true),
  newRedesignationEvaluationLink: getEnv(
    "REACT_APP_NEW_REDESIGNATION_EVALUATION_LINK",
    true
  ),
  newCommercialRedesignationEvaluationLink: getEnv(
    "REACT_APP_NEW_COMMERCIAL_REDESIGNATION_EVALUATION_LINK",
    true
  ),

  formstackEnvironment: getEnv("REACT_APP_FORMSTACK_ENVIRONMENT", true),
  syncfusionLicenseKey: getEnv("REACT_APP_SYNCFUSION_LICENSE", true),
  apiBaseUrl: getEnv("REACT_APP_API_BASE_URL", true),

  // Fortified report links
  fortifiedEvaluationVolumeReportLink: getEnv(
    "REACT_APP_FORTIFIED_EVALUATION_VOLUME_REPORT_LINK",
    true
  ),
  fortifiedIbhsBacklogReportLink: getEnv(
    "REACT_APP_FORTIFIED_IBHS_BACKLOG_REPORT_LINK",
    true
  ),
  ibhsAnnualDesignationsReportLink: getEnv(
    "REACT_APP_ANNUAL_DESIGNATIONS_REPORT_LINK",
    true
  ),
  nciuaReportLink: getEnv("REACT_APP_NCIUA_REPORT_LINK", true),

  dailyQueueReportLink: getEnv("REACT_APP_DAILY_QUEUE_REPORT_LINK", true),

  efficiencyReportLink: getEnv("REACT_APP_EFFICIENCY_REPORT_LINK", true),

  // Wildfire report links
  wildfireEvaluationVolumeReportLink: getEnv(
    "REACT_APP_WILDFIRE_EVALUATION_VOLUME_REPORT_LINK",
    true
  ),
  wildfireIbhsBacklogReportLink: getEnv(
    "REACT_APP_WILDFIRE_IBHS_BACKLOG_REPORT_LINK",
    true
  ),

  wildfireIbhsRejectionReasonReport: getEnv(
    "REACT_APP_WILDFIRE_IBHS_Rejection_REPORT_LINK",
    true
  ),

  theme: getEnv("REACT_APP_THEME", false),

  boxFileUploaderRefreshInterval: getEnv(
    "REACT_APP_BOX_FILE_UPLOADER_REFRESH_INTERVAL",
    true
  ),
};
