import { useEffect, useRef, useCallback, MutableRefObject } from "react";

const useTimeout = (callback: Function, delay: number) => {
  const callbackRef: MutableRefObject<Function> = useRef(callback);
  const timeoutRef: MutableRefObject<number | undefined> = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const start = useCallback(() => {
    timeoutRef.current = window.setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const cancel = useCallback(() => {
    !!timeoutRef.current && window.clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    start();
    return cancel;
  }, [delay, start, cancel]);

  const restart = useCallback(() => {
    cancel();
    start();
  }, [cancel, start]);

  return { restart, cancel };
};

export default useTimeout;
