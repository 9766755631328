import axios, { AxiosInstance } from "axios";
import variables from "../../../common/config/variables";

class ExternalApiClient {
  private axiosInstance: AxiosInstance;

  constructor(baseApiUrl: string) {
    this.axiosInstance = axios.create({ baseURL: baseApiUrl });
  }

  async get<T>(url: string) {
    return await this.axiosInstance.get<T>(url).then((res) => res.data);
  }
}

export default new ExternalApiClient(`${variables.apiBaseUrl as string}api`);
