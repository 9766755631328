import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { FCFMFComplianceLetterParts } from "../../../common/types/Evaluation/BaseFCFMFComplianceLetter";
import { MultifamilyComplianceLetterFormModel } from "../../types/MultifamilyComplianceLetterModel";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../types/MultifamilyEvaluationFormModel";

class MultifamilyEvaluationComplianceLetterAPIClient {
  updateMultifamilyComplianceLetter = async (
    evaluationId: string,
    multifamilyComplianceLetterForm: MultifamilyComplianceLetterFormModel,
    fieldKey?: string
  ): Promise<Evaluation_MultifamilyEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_MultifamilyEvaluationFormModel>(
      `/multifamily/compliance-letter/${evaluationId}`,
      {
        multifamilyComplianceLetterForm,
        fieldKey,
      }
    );
  };

  submitMultifamilyComplianceLetter = async (
    wildfireApplicationId: string,
    complianceLetter: MultifamilyComplianceLetterFormModel,
    complianceLetterPart?: FCFMFComplianceLetterParts
  ): Promise<Evaluation_MultifamilyEvaluationFormModel> => {
    const complianceLetterPartValue = (
      complianceLetterPart ??
      (Object.values(
        FCFMFComplianceLetterParts
      ).pop() as FCFMFComplianceLetterParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_MultifamilyEvaluationFormModel>(
      `/multifamily/compliance-letter/${wildfireApplicationId}/${complianceLetterPartValue}`,
      complianceLetter
    );
  };
}

export const multifamilyEvaluationComplianceLetterAPIClient =
  new MultifamilyEvaluationComplianceLetterAPIClient();
