import { Icon, IconProps, Tab, Tabs, Tag } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { WildfireEvaluationSteps } from "../../../../foritfied/types/evaluation/Evaluation";
import { EvaluationStatus } from "../../../types/WildfireApplication/WildfireApplication";
import "./styles.scss";
import { WildfireFieldEvaluationCompliance } from "../../../types/WildfireFieldComplianceDTO";
import { PlusTabId } from "../useWildfireIterationEngine";

interface IterationEngineNavigatorProps {
  evaluationStep: WildfireEvaluationSteps;
  evaluationStatus: EvaluationStatus;
  selectedTabId: string;
  setSelectedTabId: (tabId: string) => void;
  formTabs: { id: string; label: string }[];
  formFieldEvaluationDecisioning: WildfireFieldEvaluationCompliance | undefined;
  getCountOfNonCompliantFields: (isPlus?: boolean) => number | undefined;
}

const orderedSteps = [
  WildfireEvaluationSteps.WildfireApplication,
  WildfireEvaluationSteps.WildfireApplicationPayment,
  WildfireEvaluationSteps.WildfireApplicationIBHSEligibilityReview,
  WildfireEvaluationSteps.WildfireApplicationEvaluatorAssignment,
  WildfireEvaluationSteps.WildfireApplicationFieldEvaluations,
  WildfireEvaluationSteps.WildfireApplicationIBHSReview,
];

const evaluationStepDetails = [
  {
    id: WildfireEvaluationSteps.WildfireApplication,
    label: "Application",
  },
  {
    id: WildfireEvaluationSteps.WildfireApplicationPayment,
    label: "Application Payment",
  },
  {
    id: WildfireEvaluationSteps.WildfireApplicationIBHSEligibilityReview,
    label: "IBHS Eligibility Review",
  },
  {
    id: WildfireEvaluationSteps.WildfireApplicationEvaluatorAssignment,
    label: "Evaluator Assignment",
  },
  {
    id: WildfireEvaluationSteps.WildfireApplicationFieldEvaluations,
    label: "Field Evaluations",
  },
  {
    id: WildfireEvaluationSteps.WildfireApplicationIBHSReview,
    label: "IBHS Review",
  },
];

const getIcon = (
  step: WildfireEvaluationSteps,
  evaluationStep: WildfireEvaluationSteps,
  evaluationStatus: EvaluationStatus
): IconProps => {
  const isEligibilityReview =
    step === WildfireEvaluationSteps.WildfireApplicationIBHSEligibilityReview;
  const isFinalReview =
    step === WildfireEvaluationSteps.WildfireApplicationIBHSReview;

  const shouldDisplayTickCircle =
    orderedSteps.indexOf(evaluationStep) > orderedSteps.indexOf(step) ||
    (isFinalReview && evaluationStatus === "Designated");

  const shouldDisplayCross =
    (isEligibilityReview || isFinalReview) &&
    step === evaluationStep &&
    evaluationStatus === "Not Designated";

  const shouldDisplayCircle = evaluationStep === step;

  if (shouldDisplayTickCircle) {
    return { icon: IconNames.TICK_CIRCLE };
  }

  if (shouldDisplayCross) {
    return { icon: IconNames.CROSS_CIRCLE };
  }

  if (shouldDisplayCircle) {
    return { icon: IconNames.CIRCLE };
  }

  return { icon: IconNames.FULL_CIRCLE };
};

const IterationEngineNavigator: React.FC<IterationEngineNavigatorProps> = (
  props
) => {
  return (
    <div>
      <div className="evaluation-steps-container">
        <div className="evaluation-steps-container__header">Status</div>

        <div className="evaluation-steps-container__steps">
          {evaluationStepDetails.map((step) => {
            return (
              <div className="evaluation-steps-container-step" key={step.id}>
                <Icon
                  size={20}
                  {...getIcon(
                    step.id,
                    props.evaluationStep,
                    props.evaluationStatus
                  )}
                />
                <div className="evaluation-steps-container-step__label">
                  {step.label}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="evaluation-form-navigation-container">
        <div className="evaluation-form-navigation-container__header">
          Forms
        </div>
        <Tabs
          className="evaluation-form-navigation__tabs"
          vertical
          selectedTabId={props.selectedTabId}
          onChange={(newTabId: string) => {
            props.setSelectedTabId(newTabId);
          }}
        >
          {props.formTabs.map((tab) => {
            const nonCompliantFieldsCount = props.getCountOfNonCompliantFields(
              tab.id == PlusTabId
            );

            let tabLabel = tab.label;

            if (
              tabLabel === "Base" &&
              props.evaluationStep ===
                WildfireEvaluationSteps.WildfireApplicationIBHSEligibilityReview
            ) {
              tabLabel = "Eligibility";
            }

            return (
              <Tab
                className="evaluation-form-navigation-tab"
                key={tab.id}
                id={tab.id}
                title={tabLabel}
              >
                {props.formFieldEvaluationDecisioning && (
                  <div>
                    {/* Only display the Tag if there are non-compliant fields */}
                    {nonCompliantFieldsCount && (
                      <div className="non-compliant-count">
                        <Tag large={true} round={true}>
                          {nonCompliantFieldsCount}
                        </Tag>
                      </div>
                    )}
                  </div>
                )}
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default IterationEngineNavigator;
