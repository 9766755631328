import { useEffect, useState } from "react";
import { HomeEvaluationOptions } from "../../../../types/schema";
import {
  EvaluationCreationOptions,
  EvaluationTypes,
} from "../../../../types/evaluation/EvaluationTypes";
import { EvaluationChild } from "../../../../types/evaluation/Evaluation";
import { evaluationApiClient } from "../../../../lib/apiClients/evaluation/evaluationApiClient";
import { CreateHomeEvaluationDialogProps } from "./CreateHomeEvaluationDialog";
import {
  navigateToEvaluationForm,
  openRedesignationFullyExpiredConfirmation,
  openRedesignationIsEarlyConfirmation,
  openRedesignationNotYetApprovedConfirmation,
  submitNewEvaluation,
} from "../CreateEvaluationHelpers";

export const useCreateHomeEvaluationDialog = (
  props: CreateHomeEvaluationDialogProps
) => {
  const [isSaving, setIsSaving] = useState(false);
  const [selectedRedesignation, setSelectedRedesignation] =
    useState<EvaluationChild>();
  const [creationType, setCreationType] = useState<EvaluationCreationOptions>(
    EvaluationCreationOptions.New
  );
  const [isfetching, setIsfetching] = useState(false);
  const [selectedParentEvaluationOption, setParentEvaluationOption] =
    useState<HomeEvaluationOptions>();
  const [
    isNewRedesignationButtonDisabled,
    setIsNewRedesignationButtonDisabled,
  ] = useState<boolean>(true);

  const closeDialog = () => {
    setCreationType(EvaluationCreationOptions.New);
    setParentEvaluationOption(undefined);
    setSelectedRedesignation(undefined);
    setIsNewRedesignationButtonDisabled(true);
    props.closeDialog();
  };

  useEffect(() => {
    setParentEvaluationOption(undefined);
    setSelectedRedesignation(undefined);
    setIsNewRedesignationButtonDisabled(true);
  }, [creationType]);

  useEffect(() => {
    const fetchMostRecentEvaluationChild = async () => {
      if (!!selectedParentEvaluationOption) {
        setIsNewRedesignationButtonDisabled(true);
        setIsfetching(true);
        const mostCurrentRedeg =
          await evaluationApiClient.getMostRecentEvaluationChild(
            selectedParentEvaluationOption?.id
          );
        setSelectedRedesignation(mostCurrentRedeg);
        setIsNewRedesignationButtonDisabled(false);
        setIsfetching(false);
      } else {
        setIsNewRedesignationButtonDisabled(true);
      }
    };

    fetchMostRecentEvaluationChild();
  }, [selectedParentEvaluationOption]);

  const selectableOptions = [
    { label: "New Evaluation", value: EvaluationCreationOptions.New },
    {
      label: "Redesignation",
      value: EvaluationCreationOptions.Child,
    },
  ];

  const submitNewHomeEvaluation = async () => {
    setIsSaving(true);

    await navigateToEvaluationForm(EvaluationTypes.Home)
      .then(() => {
        props.submitDialog();
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const submitNewHomeRedesignation = async () => {
    if (
      selectedRedesignation &&
      selectedParentEvaluationOption &&
      !selectedRedesignation.isApproved
    ) {
      openRedesignationNotYetApprovedConfirmation(
        () =>
          submitNewEvaluation(
            EvaluationTypes.HomeReDesignation,
            selectedParentEvaluationOption.value,
            props.submitDialog,
            setIsSaving,
            closeDialog,
            selectedRedesignation?.evaluationExpirationDateType
          ),
        setIsSaving
      );
    } else if (
      selectedRedesignation &&
      selectedParentEvaluationOption &&
      selectedRedesignation.isEvaluationFullyExpired
    ) {
      openRedesignationFullyExpiredConfirmation(setIsSaving);
    } else if (
      selectedRedesignation &&
      selectedParentEvaluationOption &&
      selectedRedesignation.isEvaluationEarly
    ) {
      openRedesignationIsEarlyConfirmation(
        () =>
          submitNewEvaluation(
            EvaluationTypes.HomeReDesignation,
            selectedParentEvaluationOption.value,
            props.submitDialog,
            setIsSaving,
            closeDialog,
            selectedRedesignation?.evaluationExpirationDateType
          ),
        setIsSaving
      );
    } else {
      return submitNewEvaluation(
        EvaluationTypes.HomeReDesignation,
        selectedParentEvaluationOption?.value || "",
        props.submitDialog,
        setIsSaving,
        closeDialog,
        selectedRedesignation?.evaluationExpirationDateType
      );
    }
  };

  return {
    selectedRedesignation,
    setSelectedRedesignation,
    isSaving,
    selectedParentEvaluationOption,
    setParentEvaluationOption,
    isfetching,
    isNewRedesignationButtonDisabled,
    closeDialog,
    setCreationType,
    creationType,
    selectableOptions,
    submitNewHomeEvaluation,
    submitNewHomeRedesignation,
  };
};
