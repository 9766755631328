import { dialogStore } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import { UnsavedChangesDialog } from "./UnsavedChangesDialog";
import "./styles.scss";

const dialogIdBase = "wildfire-unsaved-changes-blocker-dialog";

const buildWildfireUnsavedChangesDialogProps = (
  location: string
): DialogProps => {
  return {
    id: dialogIdBase,
    className: dialogIdBase,
    header: "Unsaved Changes",
    allowDragging: false,
    content: () => <UnsavedChangesDialog location={location} />,
  };
};

export const openWildfireUnsavedChangesDialog = (location: string) => {
  dialogStore.openDialog(buildWildfireUnsavedChangesDialogProps(location));
};

export const closeWildfireUnsavedChangesDialog = () =>
  dialogStore.closeDialog(dialogIdBase);
