import {
  EnumCheckboxFieldValue,
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
  NumericFieldValue,
  TextAreaFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { FieldSchemaModel } from "../fieldSchemaConfig";

export enum RoofFieldEvaluationFormParts {
  CoverMaterial = "cover-material",
  OtherRoofCoverConditions = "other-roof-cover-conditions",
  GuttersAndDownspouts = "gutters-and-downspouts",
  EavesAndSoffits = "eaves-and-soffits",
  Vents = "vents",
}

export interface RoofFieldEvaluationFormModel extends FieldSchemaModel {
  // Relationships
  wildfireApplicationEvaluationId: string;

  // Metadata
  coverMaterialRequiredFieldCount: number;
  otherRoofCoverConditionsRequiredFieldCount: number;
  guttersAndDownspoutsRequiredFieldCount: number;
  eavesAndSoffitsRequiredFieldCount: number;
  ventsRequiredFieldCount: number;

  isCoverMaterialComplete: boolean;
  isOtherRoofCoverConditionsComplete: boolean;
  isGuttersAndDownspoutsComplete: boolean;
  isEavesAndSoffitsComplete: boolean;
  isVentsComplete: boolean;

  coverMaterialSubmitted: boolean;
  otherRoofCoverConditionsSubmitted: boolean;
  guttersAndDownspoutsSubmitted: boolean;
  eavesAndSoffitsSubmitted: boolean;
  ventsSubmitted: boolean;
  submitted: boolean;

  // Part 1: Cover Material
  roofCoverMaterialPhotoFolder: WildfireBoxContentExplorerValue;
  roofCoverMaterial: EnumSingleSelectFieldValue;
  dominantRoofCoverPercentage: NumericFieldValue;
  secondaryRoofCoverPercentage: NumericFieldValue;
  secondaryRoofCoverMaterial: EnumSingleSelectFieldValue;
  isSecondaryRoofCoverFireResistant: EnumRadioFieldValue;

  // Part 2: Other Roof Cover Conditions
  isRoofClearOfDebris: EnumRadioFieldValue;
  areSkylightsFlatGlass: EnumRadioFieldValue;

  // Part 3: Gutters and Downspouts
  guttersAndDownspoutsPhotoFolder: WildfireBoxContentExplorerValue;
  areGuttersAndDownspoutsMetal: EnumRadioFieldValue;
  areGuttersAndDownspoutsClearOfDebris: EnumRadioFieldValue;
  AreGuttersCoveredWithMetalGuard: EnumRadioFieldValue;

  // Part 4: Eaves and Soffits
  eavesAndSoffitsPhotoFolder: WildfireBoxContentExplorerValue;
  areEavesEnclosedOnUnderside: EnumRadioFieldValue;
  areEavesEnclosedWithNoncombustibleMaterial: EnumRadioFieldValue;

  // Part 5: Vents
  ventsPhotoFolder: WildfireBoxContentExplorerValue;
  roofVentTypes: EnumCheckboxFieldValue;
  areRoofVentsFireResistant: EnumRadioFieldValue;
  otherNotes: TextAreaFieldValue;
}
