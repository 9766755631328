import { WildfireBoxContentExplorerValue } from "../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
export enum FCFMFEvaluationFieldInspectionFormParts {
  EvaluationFieldInspection = "evaluation-field-inspection",
}

export interface BaseFCFMFEvaluationFieldInspectionFormModel
  extends BaseFCFMFEvaluationFieldInspectionFormFields {}

interface BaseFCFMFEvaluationFieldInspectionFormFields {
  fieldInspectionPhotos: WildfireBoxContentExplorerValue;
}
