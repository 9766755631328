import { FieldEvaluationFormPageProps } from "./FieldEvaluationFormPage";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../common/types/Forms/FormChecklist";
import { EvaluationFormModel } from "../../forms/EvaluationFormModel";
import { HomeExteriorFieldEvaluationFormParts } from "../../forms/HomeExteriorFieldEvaluationForm/HomeExteriorFieldEvaluationFormModel";

export interface HomeExteriorFieldEvaluationFormPartsProps {
  fieldEvalautionFormProps: FieldEvaluationFormPageProps;
  fieldEvaluationFormModel: EvaluationFormModel | undefined;
}

const useHomeExteriorFieldEvaluationFormParts = (
  props: HomeExteriorFieldEvaluationFormPartsProps
) => {
  const homeExteriorFormModel =
    props.fieldEvaluationFormModel?.wildfireApplication
      .homeExteriorFieldEvaluationForm;

  const getNextRoofUnsubmittedFormPart = () => {
    if (!homeExteriorFormModel) {
      return undefined;
    }

    if (!homeExteriorFormModel.isVentsComplete) {
      return HomeExteriorFieldEvaluationFormParts.Vents;
    } else if (!homeExteriorFormModel.isSixInchVerticalComplete) {
      return HomeExteriorFieldEvaluationFormParts.SixInchVertical;
    } else if (!homeExteriorFormModel.isSidingMaterialComplete) {
      return HomeExteriorFieldEvaluationFormParts.SidingMaterial;
    } else if (!homeExteriorFormModel.isWindowsComplete) {
      return HomeExteriorFieldEvaluationFormParts.Windows;
    } else if (!homeExteriorFormModel.isDoorsComplete) {
      return HomeExteriorFieldEvaluationFormParts.Doors;
    } else if (!homeExteriorFormModel.isDecksComplete) {
      return HomeExteriorFieldEvaluationFormParts.Decks;
    }
    // Form is complete
    return undefined;
  };

  const getHomeExteriorFieldEvaluationPartChecklistStatus = (
    formPart: HomeExteriorFieldEvaluationFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextRoofUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case HomeExteriorFieldEvaluationFormParts.Vents:
        return homeExteriorFormModel?.isVentsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case HomeExteriorFieldEvaluationFormParts.SixInchVertical:
        return homeExteriorFormModel?.isSixInchVerticalComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case HomeExteriorFieldEvaluationFormParts.SidingMaterial:
        return homeExteriorFormModel?.isSidingMaterialComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case HomeExteriorFieldEvaluationFormParts.Windows:
        return homeExteriorFormModel?.isWindowsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case HomeExteriorFieldEvaluationFormParts.Doors:
        return homeExteriorFormModel?.isDoorsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case HomeExteriorFieldEvaluationFormParts.Decks:
        return homeExteriorFormModel?.isDecksComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const homeExteriorFieldEvaluationChecklistParts: FormChecklistPart<HomeExteriorFieldEvaluationFormParts>[] =
    [
      {
        formPart: HomeExteriorFieldEvaluationFormParts.Vents,
        displayName: "Vents",
        requiredFieldCount: homeExteriorFormModel?.ventsRequiredFieldCount,
      },
      {
        formPart: HomeExteriorFieldEvaluationFormParts.SixInchVertical,
        displayName: '6" Vertical',
        requiredFieldCount:
          homeExteriorFormModel?.sixInchVerticalRequiredFieldCount,
      },
      {
        formPart: HomeExteriorFieldEvaluationFormParts.SidingMaterial,
        displayName: "Siding Material",
        requiredFieldCount:
          homeExteriorFormModel?.sidingMaterialRequiredFieldCount,
      },
      {
        formPart: HomeExteriorFieldEvaluationFormParts.Windows,
        displayName: "Windows",
        requiredFieldCount: homeExteriorFormModel?.windowsRequiredFieldCount,
      },
      {
        formPart: HomeExteriorFieldEvaluationFormParts.Doors,
        displayName: "Doors",
        requiredFieldCount: homeExteriorFormModel?.doorsRequiredFieldCount,
      },
      {
        formPart: HomeExteriorFieldEvaluationFormParts.Decks,
        displayName: "Decks",
        requiredFieldCount: homeExteriorFormModel?.decksRequiredFieldCount,
      },
    ];

  return {
    homeExteriorFieldEvaluationChecklistParts,
    getHomeExteriorFieldEvaluationPartChecklistStatus,
    homeExteriorFormModel,
  };
};

export default useHomeExteriorFieldEvaluationFormParts;
