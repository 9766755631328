import { dialogStore } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import { AdminUpdateForm } from "./AdminUpdateForm";
import "../styles.scss";
import { WildfireEvaluationSteps } from "../../../../foritfied/types/evaluation/Evaluation";

const dialogIdBase = "wildfire-admin-update-dialog";

const getDialogId = (wildfireEvaluationId: string): string =>
  `${dialogIdBase}_${wildfireEvaluationId}`;

const buildAdminUpdateDialogProps = (
  wildfireEvaluationId: string,
  homeOwnerId: string,
  auditorId: string,
  currentStatus: string,
  moveableStepOptionsByAdmin: {
    label: string;
    value: WildfireEvaluationSteps;
  }[],
  getWildfireApplication: () => Promise<void>
): DialogProps => {
  return {
    id: getDialogId(wildfireEvaluationId),
    className: dialogIdBase,
    header: "Admin Update",
    allowDragging: false,
    content: () => (
      <div>
        <AdminUpdateForm
          wildfireEvaluationId={wildfireEvaluationId}
          homeOwnerId={homeOwnerId}
          auditorId={auditorId}
          currentStatus={currentStatus}
          moveableStepOptionsByAdmin={moveableStepOptionsByAdmin}
          refreshWildfireApplication={getWildfireApplication}
        />
      </div>
    ),
  };
};

export const openAdminUpdateDialog = (
  wildfireEvaluationId: string,
  homeOwnerId: string,
  auditorId: string,
  currentStatus: string,
  moveableStepOptionsByAdmin: {
    label: string;
    value: WildfireEvaluationSteps;
  }[],
  getWildfireApplication: () => Promise<void>
) =>
  dialogStore.openDialog(
    buildAdminUpdateDialogProps(
      wildfireEvaluationId,
      homeOwnerId,
      auditorId,
      currentStatus,
      moveableStepOptionsByAdmin,
      getWildfireApplication
    )
  );

export const closeAdminUpdateDialog = (wildfireEvaluationId: string) =>
  dialogStore.closeDialog(getDialogId(wildfireEvaluationId));
