import React, { useEffect } from "react";
import { Navigate, useOutlet } from "react-router";
import { autorun } from "mobx";
import { Dialogs } from "../../components/Dialogs";
import { errorStore } from "../../../common/Components/Error/ErrorStore";
import ErrorWrapper from "../../../common/Components/Error/ErrorWrapper";
import { ErrorBoundary } from "react-error-boundary";
import { fortifiedUserStore } from "../../stores/FortifiedUserStore";
import WelcomeApplicantComponent from "../../components/User/WelcomeApplicantComponent";
import { useProductTypeHandler } from "../../providers/ProductTypeHandlerProvider";
export interface PagesLayoutComponentProps {
  redirectPath: string;
  errorWrapper: JSX.Element;
}

const PagesLayoutComponent: React.FC<PagesLayoutComponentProps> = (props) => {
  const outlet = useOutlet();
  const [hasError, setHasError] = React.useState<boolean>(false);

  const { logoutUser } = useProductTypeHandler();

  useEffect(() => {
    autorun(() => {
      setHasError(!!errorStore.errorMessage);
    });
  }, []);

  const hasWelcomePage = fortifiedUserStore.isFHApplicant;
  const shouldRenderRoutes =
    fortifiedUserStore.isFortifiedUser && !hasWelcomePage;

  return (
    <>
      {hasWelcomePage && (
        <WelcomeApplicantComponent
          productType={"Fortified Home"}
          logOutUser={logoutUser}
        />
      )}

      {shouldRenderRoutes && (
        <div className="fortified-page">
          {hasError && props.errorWrapper}
          {!hasError && (
            <ErrorBoundary FallbackComponent={() => <ErrorWrapper />}>
              <>{outlet || <Navigate to={props.redirectPath} replace />}</>
            </ErrorBoundary>
          )}
        </div>
      )}
      <Dialogs />
    </>
  );
};

export const PagesLayout = PagesLayoutComponent;
