import { EmailFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";

export enum FCFMFRedesignationEvaluatorEmailFormParts {
  EvaluatorEmail = "evaluator-email",
}

export interface BaseFCFMFRedesignationEvaluatorEmailFormModel
  extends EvaluatorEmail {}

interface EvaluatorEmail {
  evaluatorEmail: EmailFieldValue;
}
