import { Button, Card, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Observer } from "mobx-react";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { evaluationStore } from "../../stores/EvaluationStore";
import { permissionStore } from "../../stores/PermissionStore";
import { Evaluation } from "../../types/evaluation/Evaluation";
import {
  EvaluationSchema,
  EvaluationSchemaEnum,
} from "../../types/evaluation/EvaluationSchema";
import { ChecklistItemStatus } from "./ChecklistItemStatus/ChecklistItemStatus";
import { NotifySubmitterLink } from "./NotifySubmitter/NotifySubmitterLink";
import ProceedToIBHSReviewActionButton from "./ProceedToIBHSReviewActionButton";
import ProceedToPaymentActionButton from "./ProceedToPaymentActionButton";
import LegacyEvaluationActionLink from "../Evaluation/EvaluationActions/LegacyEvaluationActionLink";
import LegacyEvaluationDataActionLink from "../Evaluation/EvaluationActions/LegacyEvaluationDataActionLink";
import WithdrawEvaluationActionButton from "../Evaluation/EvaluationActions/WithdrawEvaluationActionButton";
import IterationEngineActionButton from "../Evaluation/EvaluationActions/IterationEngineActionButton";
import DownloadRooferConfirmationFormActionButton from "../Evaluation/EvaluationActions/DownloadRooferConfirmationFormActionButton";

export interface ChecklistTabPanelProps {
  selectedUserRole?: EvaluationSchemaEnum;
  evaluation: Evaluation;
  schema: EvaluationSchema;
}

export const ChecklistTabPanel: React.FC<ChecklistTabPanelProps> = ({
  selectedUserRole,
  evaluation,
  schema,
}) => {
  const isAllSelected = !selectedUserRole || selectedUserRole.id === 0;

  const filteredForms = (evaluation.evaluationForms || [])
    .filter(
      (form) =>
        isAllSelected ||
        form.evaluationItemCompleterRoles.includes(selectedUserRole.id)
    )
    .sort((a, b) => a.sortOrder - b.sortOrder);

  const fillableForms = filteredForms.filter(
    (item) => !item.isCompleted && !item.isDisabled
  );

  return (
    <Observer>
      {() => (
        <div className="form-tab">
          <div className="form-tab__notification-container">
            <div className="form-tab__notification-container__left-pannel">
              <IterationEngineActionButton evaluationId={evaluation.id} />
            </div>
            <div className="form-tab__notification-container__right-pannel">
              {evaluation && !isAllSelected && selectedUserRole.extData && (
                <NotifySubmitterLink
                  mailTo={selectedUserRole.extData}
                  address={evaluationStore.evaluationAddress}
                  fortifiedId={evaluation.fortifiedId}
                  formLinks={fillableForms.map((incompletedItem) => ({
                    name: incompletedItem.evaluationItemTypes_AsString,
                    link: evaluationStore.getEvaluationFormUrl(
                      evaluation,
                      incompletedItem
                    ),
                  }))}
                />
              )}
              {evaluationStore.isLegacyEvaluation &&
                permissionStore.canViewLegacyEvaluationFiles && (
                  <LegacyEvaluationActionLink />
                )}
              {evaluationStore.isLegacyEvaluation &&
                permissionStore.canViewLegacyEvaluationFiles && (
                  <LegacyEvaluationDataActionLink />
                )}
              {filteredForms.some(
                (form) =>
                  form.evaluationItemTypes_AsString === "Roofer Confirmation"
              ) && <DownloadRooferConfirmationFormActionButton />}
              {permissionStore.canWithdraw(evaluationStore.evaluation) && (
                <WithdrawEvaluationActionButton />
              )}
              {evaluationStore.isFHPaymentStep &&
                (evaluationStore.isEvaluationNonProfit ? (
                  <ProceedToIBHSReviewActionButton />
                ) : (
                  <ProceedToPaymentActionButton />
                ))}
              {permissionStore.canViewAdminButton && (
                <Button
                  minimal
                  large
                  onClick={() => {
                    dialogsViewerStore.setIsAdminDialogOpen(true, {
                      evaluationId: evaluation.id,
                    });
                  }}
                >
                  <Icon icon={IconNames.COG} size={16} />
                </Button>
              )}
            </div>
          </div>
          <Card>
            <table>
              <thead>
                <tr className="form-tab__header">
                  <td>Form Name</td>
                  <td>Submitter</td>
                  <td className="form-tab__status">Status</td>
                </tr>
              </thead>
              <tbody>
                {evaluation &&
                  filteredForms.map((item, idx) => (
                    <tr key={idx}>
                      <td>
                        {item.isCompleted ||
                        item.isDisabled ||
                        item.isSyncing ? (
                          <span>{item.evaluationItemTypes_AsString}</span>
                        ) : (
                          <a
                            href={evaluationStore.getEvaluationFormUrl(
                              evaluation,
                              item
                            )}
                          >
                            {item.evaluationItemTypes_AsString}
                          </a>
                        )}
                      </td>
                      <td className="submitter">
                        {item.evaluationItemCompleterRoles
                          .map(
                            (completerRole) =>
                              schema.userRoles.find(
                                (userRole) => userRole.id === completerRole
                              )?.label
                          )
                          .join(", ")}
                      </td>
                      <td className="form-tab__status">
                        <ChecklistItemStatus
                          checklistItem={item}
                          evaluationSchema={schema}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Card>
        </div>
      )}
    </Observer>
  );
};
