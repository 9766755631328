import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useNavigate } from "react-router";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";

export interface BackToLandingPageButtonProps {}
export const BackToLandingPageButton: React.FC<
  BackToLandingPageButtonProps
> = () => {
  const navigate = useNavigate();

  return (
    <Button
      text="Back To Board"
      intent={Intent.PRIMARY}
      minimal
      icon={IconNames.ArrowLeft}
      onClick={async () => navigate(`${wildfireRoutePrefix}/board`)}
    />
  );
};
