import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import { observer } from "mobx-react";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { iterationEngineCommentViewerStore } from "../../stores/IterationEngineCommentViewerStore";
import { fortifiedUserStore } from "../../stores/FortifiedUserStore";

export const IterationEngineActions = observer(() => {
  return (
    <>
      {fortifiedUserStore.isAuditor && (
        <>
          <span>
            {iterationEngineCommentViewerStore.resolvedChangeRequestsCount}/
            {iterationEngineCommentViewerStore.changeRequestsCount} Change
            Requests Resolved
          </span>
          <Button
            text="Approve"
            minimal={true}
            intent={Intent.SUCCESS}
            icon={IconNames.TICK_CIRCLE}
            onClick={() => {
              dialogsViewerStore.setIsAuditorApprovalDialogOpen(true);
            }}
            disabled={
              !iterationEngineCommentViewerStore.canApproveChangeRequest
            }
          />
          {!iterationEngineCommentViewerStore.canRequestChange ? (
            <Popover2
              interactionKind="hover"
              placement="bottom-end"
              portalClassName="tooltip-popover"
              popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
              content={
                <span>
                  There must be at least one unresolved change request in order
                  to Request Changes. To record a new change request, click on
                  any field label and submit a comment that states precisely
                  what needs to be changed.
                </span>
              }
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              renderTarget={({ isOpen, ...targetProps }) => (
                <div {...targetProps}>
                  <Button
                    text="Request Changes"
                    minimal={true}
                    intent={Intent.WARNING}
                    icon={IconNames.REFRESH}
                    onClick={() => {
                      dialogsViewerStore.setIsAuditorRequestChangeDialogOpen(
                        true
                      );
                    }}
                    disabled={
                      !iterationEngineCommentViewerStore.canRequestChange
                    }
                  />
                </div>
              )}
            />
          ) : (
            <Button
              text="Request Changes"
              minimal={true}
              intent={Intent.WARNING}
              icon={IconNames.REFRESH}
              onClick={() => {
                dialogsViewerStore.setIsAuditorRequestChangeDialogOpen(true);
              }}
            />
          )}

          <Button
            text="Reject"
            intent={Intent.DANGER}
            icon={IconNames.DELETE}
            minimal={true}
            onClick={() => {
              dialogsViewerStore.setIsAuditorRejectionDialogOpen(true);
            }}
            disabled={!iterationEngineCommentViewerStore.canRejectChangeRequest}
          />
        </>
      )}
      {fortifiedUserStore.isEvaluator && (
        <>
          {iterationEngineCommentViewerStore.needsAddressedChangeRequestsCount >
            0 && (
            <span>
              {iterationEngineCommentViewerStore.addressedChangeRequestsCount}/
              {
                iterationEngineCommentViewerStore.needsAddressedChangeRequestsCount
              }{" "}
              Change Request Addressed
            </span>
          )}
          {!iterationEngineCommentViewerStore.canSubmitForReview ? (
            <Popover2
              interactionKind="hover"
              placement="bottom-end"
              portalClassName="tooltip-popover"
              popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
              content={
                <span>
                  All change requests must be addressed before sending this for
                  review. If you have addressed all change requests and sent
                  this for review, then no further action is required at this
                  time.
                </span>
              }
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              renderTarget={({ isOpen, ...targetProps }) => (
                <div {...targetProps}>
                  <Button
                    icon={IconNames.KEY_ENTER}
                    text="Send for Review"
                    minimal={true}
                    intent={Intent.PRIMARY}
                    onClick={async () => {
                      dialogsViewerStore.setIsEvaluatorReviewSubmissionDialogOpen(
                        true
                      );
                    }}
                    disabled={
                      !iterationEngineCommentViewerStore.canSubmitForReview
                    }
                  />
                </div>
              )}
            />
          ) : (
            <Button
              icon={IconNames.KEY_ENTER}
              text="Send for Review"
              minimal={true}
              intent={Intent.PRIMARY}
              onClick={async () => {
                dialogsViewerStore.setIsEvaluatorReviewSubmissionDialogOpen(
                  true
                );
              }}
            />
          )}
        </>
      )}
    </>
  );
});
