import { FormGroup, InputGroup } from "@blueprintjs/core";
import React, { useState } from "react";
import { TextField as InterationEngineTextField } from "../../../types/viewModels/IterationEngineViewModel";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { BaseFieldProps } from "../types";

export interface TextFieldProps
  extends BaseFieldProps<string>,
    Omit<InterationEngineTextField, "id" | "sortOrder" | "isHidden"> {
  inputMode?:
    | "search"
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal";
}

export const TextField: React.FC<TextFieldProps> = ({
  fieldId,
  label,
  value,
  inputMode,
  disabled,
  isEmpty,
  onSubmit,
  isSelectedField,
  hasActiveChangeRequest,
  isSelectedChangeRequest,
  isResovledChangeRequest,
  hasActiveInternalComment,
  isExternalView,
}) => {
  if (isEmpty(value)) {
    return null;
  }
  const [localValue, setLocalValue] = useState<string>(value);

  return (
    <FormGroup className="base-field">
      <SelectableLabel
        name={label}
        fieldId={fieldId}
        value={localValue}
        isSelectedField={isSelectedField}
        hasActiveChangeRequest={hasActiveChangeRequest}
        isSelectedChangeRequest={isSelectedChangeRequest}
        isResovledChangeRequest={isResovledChangeRequest}
        hasActiveInternalComment={hasActiveInternalComment}
        shouldDisableFieldLabelClick={isExternalView}
      />
      <InputGroup
        defaultValue={localValue}
        inputMode={inputMode}
        disabled={disabled}
        onChange={(e) => setLocalValue(e.target.value)}
        onBlur={() => onSubmit(localValue)}
      />
    </FormGroup>
  );
};
