import {
  EvaluationBid,
  NewEvaluationBid,
} from "../../../types/evaluation/EvaluationBid";
import { ExternalEvaluationBidSummary } from "../../../types/evaluation/Evaluation";
import baseExternalApiClient from "../baseExternalApiClient";
import baseApiClient from "../baseApiClient";

class EvaluationBidApiClient {
  getExternalCommercialEvaluationSummary = async (id: string | undefined) => {
    return await baseExternalApiClient.get<ExternalEvaluationBidSummary>(
      `evaluation/bid/${id}/summary`
    );
  };

  getEvaluationBids = async (evaluationId: string) => {
    return baseApiClient.get<EvaluationBid[]>(`evaluation/bid/${evaluationId}`);
  };

  createEvaluationBid = async (
    evaluationId: string,
    newEvaluationBid: NewEvaluationBid
  ) => {
    return baseApiClient.post<EvaluationBid>(
      `evaluation/bid/${evaluationId}/create`,
      newEvaluationBid
    );
  };
  resendEvaluationBid = async (
    evaluationId: string,
    bidId: string,
    updatedEvaluationBid: EvaluationBid
  ) => {
    return baseApiClient.put<EvaluationBid>(
      `evaluation/bid/${evaluationId}/${bidId}/resend`,
      updatedEvaluationBid
    );
  };

  acceptEvaluationBid = async (evaluationId: string, bidId: string) => {
    return baseApiClient.put<EvaluationBid>(
      `evaluation/bid/${evaluationId}/${bidId}/accept`
    );
  };
}

export const evaluationBidApiClient = new EvaluationBidApiClient();
