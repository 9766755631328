import { Popover2 } from "@blueprintjs/popover2";
import { Menu, MenuItem, Icon } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { IconNames } from "@blueprintjs/icons";
import "./styles.scss";

export interface UserAvatarProps {
  logout: () => Promise<void>;
  userFullName: string;
  userDisplayRole?: string;
}
export const UserAvatar: React.FC<UserAvatarProps> = observer((props) => {
  return (
    <div>
      <Popover2
        position="bottom"
        placement="bottom-end"
        content={
          <Menu>
            <MenuItem
              icon={IconNames.LOG_OUT}
              text="Sign Out"
              onClick={async () => {
                await props.logout();
              }}
            />
          </Menu>
        }
      >
        {props.userFullName && props.userDisplayRole && (
          <div className="user-profile-tab">
            <div className="user-profile-tab__user-name">
              {props.userFullName}
            </div>
            <div className="user-profile-tab__user-role">
              ({props.userDisplayRole})
            </div>
            <div>
              <Icon iconSize={15} icon={IconNames.CHEVRON_DOWN} />
            </div>
          </div>
        )}
      </Popover2>
    </div>
  );
});
