import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { FCFMFComplianceFormParts } from "../../../common/types/Evaluation/BaseFCFMFComplianceForm";
import { CommercialComplianceFormModel } from "../../types/CommercialComplianceFormModel";
import { Evaluation_CommercialEvaluationFormModel } from "../../types/CommercialEvaluationFormModel";

class CommercialEvaluationComplianceFormAPIClient {
  updateCommercialComplianceForm = async (
    evaluationId: string,
    commercialComplianceForm: CommercialComplianceFormModel,
    fieldKey?: string
  ): Promise<Evaluation_CommercialEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_CommercialEvaluationFormModel>(
      `/commercial/compliance-form/${evaluationId}`,
      {
        commercialComplianceForm,
        fieldKey,
      }
    );
  };

  submitCommercialComplianceForm = async (
    wildfireApplicationId: string,
    complianceForm: CommercialComplianceFormModel,
    complianceFormPart?: FCFMFComplianceFormParts
  ): Promise<Evaluation_CommercialEvaluationFormModel> => {
    // If complianceFormPart is not provided, default to the last part of the form
    const complianceFormPartValue = (
      complianceFormPart ??
      (Object.values(
        FCFMFComplianceFormParts
      ).pop() as FCFMFComplianceFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_CommercialEvaluationFormModel>(
      `/commercial/compliance-form/${wildfireApplicationId}/${complianceFormPartValue}`,
      complianceForm
    );
  };
}

export const commercialEvaluationComplianceFormAPIClient =
  new CommercialEvaluationComplianceFormAPIClient();
