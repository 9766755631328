import { RouterProvider } from "react-router-dom";

import { wildfireBrowserRouter } from "./wildfireRouteConfig";
import { PermissionsContext, PermissionsContextProps } from "@ucl/library";
import { useContext } from "react";
import useUserGuidingWithIdentification from "../../../common/customHooks/userGuiding/useUserGuidingWithIdentification";
import { wildfireUserStore } from "../../stores/WildfireUserStore";
import { useTitle } from "../../customHooks/useTitle";
import { useFavicon } from "../../customHooks/useFavicon";

//Dev Note:
//The Router Provider is implemented separately to enable the initialization
//of PermissionsProvider, and FeatureToggleProvider after authentication,
//ensuring permissions and Feature Toggles are initialized before rendering the router.
const WildfireRouterProvider: React.FC = () => {
  const { hasPermission, permissions } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  useUserGuidingWithIdentification({ user: wildfireUserStore.user });

  useFavicon(`${process.env.PUBLIC_URL}/wfp_favicon.svg`);
  useTitle("Wildfire Prepared");

  return (
    <>
      {permissions && (
        <RouterProvider router={wildfireBrowserRouter(hasPermission)} />
      )}
    </>
  );
};

export default WildfireRouterProvider;
