import { AppSchema } from "../../../types/schema";
import baseApiClient from "../baseApiClient";

class AppSchemaApiClient {
  getAppSchema = async () => {
    return baseApiClient.get<AppSchema>("schema");
  };
}

export const appSchemaApiClient = new AppSchemaApiClient();
