import { dialogStore } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import { AppToaster } from "../../../../foritfied/components/Toast/Toast";
import { evaluationApiClient } from "../../../../foritfied/lib/apiClients/evaluation/evaluationApiClient";
import "../styles.scss";
import { AuditorNotesUpdateForm } from "./AuditorNotesUpdateForm";

export const getAuditorNotesUpdateDialogProps = (
  dialogId: string,
  evaluationId: string,
  auditorNotes: string,
  refresh: () => void
): DialogProps => {
  const onCancelDialog = () => {
    dialogStore.closeDialog(dialogId);
  };

  const onSubmitDialog = async (auditorNotes: string) => {
    await evaluationApiClient.updateAuditorNotes(evaluationId, {
      auditorNotes,
    });

    AppToaster.show({
      message: (
        <div>
          <h3>Success</h3>Action Confirmed.
        </div>
      ),
      intent: "success",
    });

    await dialogStore.closeDialog(dialogId);
    refresh();
  };

  return {
    id: dialogId,
    header: "Update Auditor Notes",
    allowDragging: false,
    content: () => (
      <AuditorNotesUpdateForm
        auditorNotes={auditorNotes}
        onCancel={onCancelDialog}
        onSubmit={onSubmitDialog}
        canViewAuditorNotes={true}
      />
    ),
  };
};
