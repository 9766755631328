import { dialogStore } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import { CreateFCFMFEvaluationForm } from "./CreateFCFMFEvaluationForm";

const dialogIdBase = "fc-fmf-create-evaluation-dialog";

const getDialogId = (): string => `${dialogIdBase}`;

const buildCreateFCFMFEvaluationDialog = (): DialogProps => {
  return {
    id: getDialogId(),
    className: dialogIdBase,
    header: "Create FC FMF Evaluation",
    allowDragging: false,
    content: () => (
      <div>
        <CreateFCFMFEvaluationForm />
      </div>
    ),
  };
};

export const openCreateFCFMFEvaluationDialog = () =>
  dialogStore.openDialog(buildCreateFCFMFEvaluationDialog());

export const closeCreateFCFMFEvaluationDialog = () =>
  dialogStore.closeDialog(getDialogId());
