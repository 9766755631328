import React from "react";
import { dialogsViewerStore } from "../../../stores/DialogsViewerStore";
import { Button } from "@blueprintjs/core";

interface LegacyEvaluationActionLinkProps {}

const LegacyEvaluationActionLink: React.FC<
  LegacyEvaluationActionLinkProps
> = ({}) => {
  return (
    <Button
      text="FOCUS 1.0 Evaluation Files"
      minimal
      onClick={() =>
        dialogsViewerStore.setIsLegacyEvaluationFilesDialogOpen(true)
      }
    />
  );
};

export default LegacyEvaluationActionLink;
