import { ApiClient } from "@ucl/library";
import variables from "../../common/config/variables";
import { getWildfireAccessToken } from "../../common/lib/msal/wildfireMsal";

export const getAuthRequestHeader = async () => {
  const accessToken = await getWildfireAccessToken();

  const record: Record<string, string> = {
    Authorization: `Bearer ${accessToken}`,
  };

  return record;
};

const wildfireBaseAPIClient = new ApiClient(
  `${variables.apiBaseUrl as string}api`,
  async (config) => {
    const accessToken = await getWildfireAccessToken();
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  }
);
export default wildfireBaseAPIClient;
