import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import HomeSCSHEvaluationFormContainer from "../components/Forms/SCSHEvaluationForm/Container/HomeSCSHEvaluationFormContainer";
import { SCSHEvaluationForm_FormParts } from "../types/HomeSCSHEvaluation_EvaluationFormModel";

export interface HomeSCSHEvaluationFormPageProps {
  evaluationId?: string;
  homeSCSHEvaluationFormPart?: SCSHEvaluationForm_FormParts;
}

const HomeSCSHEvaluationFormPageComponent: FC<
  HomeSCSHEvaluationFormPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeSCSHEvaluationFormContainer
            evaluationId={props.evaluationId}
            formPart={props.homeSCSHEvaluationFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeSCSHEvaluationFormPage = withAITracking(
  reactPlugin,
  HomeSCSHEvaluationFormPageComponent,
  "HomeSCSHEvaluationFormPage",
  "tracking-container"
);
