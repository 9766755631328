export enum SCSHEvaluationForm_FormParts {
  Information = "information",
}

export interface HomeSCSHEvaluation_EvaluationFormModel {
  id: string;
  homeSCSHEvaluationId: string;
  evaluationId: string;

  informationRequiredFieldCount: number;
  informationLastSubmittedAt: string;
  informationHasBeenSubmitted: boolean;
  isInformationComplete: boolean;
  areAllRequiredFieldsComplete: boolean;
}
