import baseApiClient from "../../../../foritfied/lib/apiClients/baseApiClient";
import { ChangeRequestV2 } from "../../../../wildfire/components/IterationEngine/types/ChangeRequestV2";

class FortifiedChangeRequestApiClient {
  getChangeRequest = async (evaluationId: string, changeRequestId: string) => {
    return await baseApiClient.get<ChangeRequestV2>(
      `evaluation/${evaluationId}/change-request/${changeRequestId}`
    );
  };

  getChangeRequestsByEvaluation = async (evaluationId: string) => {
    return await baseApiClient.get<ChangeRequestV2[]>(
      `evaluation/${evaluationId}/change-request/v2`
    );
  };

  resolveChangeRequest = async (
    evaluationId: string,
    changeRequestId: string
  ) => {
    return await baseApiClient.put<void>(
      `evaluation/${evaluationId}/change-request/${changeRequestId}/status/resolution`
    );
  };

  unresolveChangeRequest = async (
    evaluationId: string,
    changeRequestId: string
  ) => {
    return await baseApiClient.put<void>(
      `evaluation/${evaluationId}/change-request/${changeRequestId}/status/unresolution`
    );
  };
}

export const fortifiedChangeRequestApiClient =
  new FortifiedChangeRequestApiClient();
