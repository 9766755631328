import { Observer } from "mobx-react";
import React from "react";
import { evaluationChangeRequestStore } from "../../../../stores/EvaluationChangeRequestStore";
import { evaluationStore } from "../../../../stores/EvaluationStore";
import { EvaluationForm as EvaluationFormType } from "../../../../types/evaluation/Evaluation";
import { fieldValidators } from "../../../../utils/fieldValidators";
import { buildField } from "../../../Fields";
import { AllFields } from "../../../Fields/types";
import { insertIf } from "../../../../../common/utils/array";

export interface ComplexCommercialEvaluationBuildingFormProps {
  evaluationForm: EvaluationFormType;
  updateEvaluationItemData: (
    complexCommercialEvaluationBuildingId: string,
    evaluationItemId: string,
    evaluationItemDataId: string,
    value: string
  ) => Promise<void>;
  complexCommercialEvaluationBuildingId: string;
  isSaving: boolean;
  isDeleting: boolean;
}

export const ComplexCommercialEvaluationBuildingForm: React.FC<
  ComplexCommercialEvaluationBuildingFormProps
> = (props) => {
  const buildingFieldNames = [
    "Commercial_Multifamily_Primary_Use",
    ...insertIf(
      evaluationStore.isCommercialEvaluation,
      "Commercial_OccupancyType"
    ),
    ...insertIf(
      evaluationStore.isMultiFamilyEvaluation,
      "Commercial_NumberOfUnits"
    ),
    "Commercial_BuildingName",
    "Commercial_HeightFts",
    "Commercial_BuildingAddress",
    "Commercial_DesignationLevel",
    "Commercial_GrossRoofAreaSqft",
    "Commercial_TotalFloorAreaSqft",
  ];

  return (
    <Observer>
      {() => (
        <div className={`evaluation-container__content__evaluation-form`}>
          <div>
            {props.evaluationForm.evaluationFields
              .filter((field) => field.isActive)
              .filter((field) => field.fieldType === "Embed" || !field.isHidden)
              .filter((field) =>
                buildingFieldNames.includes(field.evaluationFieldName)
              )
              .sort((prev, next) => prev.sortOrder - (next.sortOrder ?? 1000))
              .map((field, idx) => {
                const fieldChangeRequestState =
                  evaluationChangeRequestStore.getFieldChangeRequestState(
                    field.id
                  );
                return (
                  <div key={field.id} id={field.id}>
                    {buildField(
                      {
                        fieldId: field.id,
                        label: field.label,
                        isHidden: field.isHidden,
                        value: field.value,
                        folderId: field.extData,
                        sortOrder: field.sortOrder,
                        fieldType: field.fieldType,
                        optionValues: field.optionValues,
                        isSelectedField:
                          evaluationStore.selectedField?.id === field.id,
                        hasActiveChangeRequest:
                          fieldChangeRequestState.isActive,
                        isSelectedChangeRequest:
                          fieldChangeRequestState.isSelected,
                        isResovledChangeRequest:
                          fieldChangeRequestState.isActiveAndResolved,
                        hasActiveInternalComment:
                          evaluationStore.hasActiveInternalComment(field.id),
                        isEmpty: fieldValidators[field.fieldType],
                        disabled: !field.canBeUpdated,
                        onSubmit: async (value: unknown) => {
                          await props.updateEvaluationItemData(
                            props.complexCommercialEvaluationBuildingId,
                            props.evaluationForm.id,
                            field.id,
                            value as string
                          );
                        },
                      } as AllFields,
                      idx
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </Observer>
  );
};
