import { action, makeObservable, observable } from "mobx";
import { evaluationStore } from "./EvaluationStore";

class IterationEngineViewerStore {
  public selectedTabId = 0;

  constructor() {
    makeObservable(this, {
      selectedTabId: observable,
      setSelectedTabId: action,
    });
  }

  setSelectedTabId = (tabId: number) => {
    this.selectedTabId = tabId;
  };

  scrollToRef() {
    if (!evaluationStore.selectedField?.id) {
      return;
    }

    const element = document.getElementById(evaluationStore.selectedField?.id);
    if (!element) {
      return;
    }

    const form = document.getElementById("evaluation-form");
    if (!form) {
      return;
    }

    element.scrollIntoView({
      behavior: "auto",
      block: "start",
      inline: "nearest",
    });
  }
}

export const iterationEngineViewerStore = new IterationEngineViewerStore();
