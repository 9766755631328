import {
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
  NumericFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { FieldSchemaModel } from "../fieldSchemaConfig";

export enum HomeExteriorFieldEvaluationFormParts {
  Vents = "vents",
  SixInchVertical = "six-inch-vertical",
  SidingMaterial = "siding-material",
  Windows = "windows",
  Doors = "doors",
  Decks = "decks",
}

export interface HomeExteriorFieldEvaluationFormModel extends FieldSchemaModel {
  // Relationships
  id: string;
  wildfireApplicationId: string;
  wildfireApplicationEvaluationId: string;

  // Metadata
  ventsRequiredFieldCount: number;
  sixInchVerticalRequiredFieldCount: number;
  sidingMaterialRequiredFieldCount: number;
  windowsRequiredFieldCount: number;
  doorsRequiredFieldCount: number;
  decksRequiredFieldCount: number;

  isVentsComplete: boolean;
  isSixInchVerticalComplete: boolean;
  isSidingMaterialComplete: boolean;
  isWindowsComplete: boolean;
  isDoorsComplete: boolean;
  isDecksComplete: boolean;

  ventsSubmitted: boolean;
  sixInchVerticalSubmitted: boolean;
  sidingMaterialSubmitted: boolean;
  windowsSubmitted: boolean;
  doorsSubmitted: boolean;
  decksSubmitted: boolean;
  submitted: boolean;

  // Part 1: Vents
  ventsPhotoFolder: WildfireBoxContentExplorerValue;
  areGableEndVentsFireResistant: EnumRadioFieldValue;
  areVentsUnderOpenFramedEaveCoveredWithMetalMesh: EnumRadioFieldValue;
  areCrawlSpaceVentsCoveredWithMetalMesh: EnumRadioFieldValue;
  doesDryerVentHaveLouverOrFlap: EnumRadioFieldValue;
  isDryerVentMetalWithLouverOrFlap: EnumRadioFieldValue;

  // Part 2: Six Inch Vertical
  sixInchVerticalPhotoFolder: WildfireBoxContentExplorerValue;
  doAllExteriorWallsHaveSixInchNoncombustibleVertical: EnumRadioFieldValue;

  // Part 3: Siding Material
  sidingMaterialPhotoFolder: WildfireBoxContentExplorerValue;
  dominantWallCoverPercentage: NumericFieldValue;
  dominantWallCoverMaterial: EnumSingleSelectFieldValue;
  isDominantWallCoverNoncombustible: EnumRadioFieldValue;
  secondaryWallCoverPercentage: NumericFieldValue;
  secondaryWallCoverMaterial: EnumSingleSelectFieldValue;
  areDecorativeShuttersNoncombustible: EnumRadioFieldValue;
  areFunctionalShuttersPresentOnAllWindows: EnumRadioFieldValue;

  // Part 4: Windows
  windowsPhotoFolder: WildfireBoxContentExplorerValue;
  areAllWindowsCompliant: EnumRadioFieldValue;
  isSpaceUnderBayWindowsEnclosed: EnumRadioFieldValue;

  // Part 5: Doors
  doorsPhotoFolder: WildfireBoxContentExplorerValue;
  areAllExteriorDoorsCompliant: EnumRadioFieldValue;

  // Part 6: Decks
  decksPhotoFolder: WildfireBoxContentExplorerValue;
  doesAreaAroundDecksHaveNoncombustibleSpace: EnumRadioFieldValue;
  isTopSurfaceOfDecksClearOfYardDebris: EnumRadioFieldValue;
  isTopSurfaceOfDecksFreeFromTreesAndShrubs: EnumRadioFieldValue;
  doesTopSurfaceOfDecksHaveNoncombustibleItems: EnumRadioFieldValue;
  isUnderneathDecksFreeFromVegetation: EnumRadioFieldValue;
  areDecksClearOfStoredItems: EnumRadioFieldValue;
  areDecksFullyEnclosedUnderneath: EnumRadioFieldValue;
  isAttachedDeckStructureMetal: EnumRadioFieldValue;
  doesDetachedDeckMeetAttachedDeckRequirements: EnumRadioFieldValue;
  areDecksConstructedWithNoncombustibleMaterials: EnumRadioFieldValue;
}
