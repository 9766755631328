import { dialogStore } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import "../styles.scss";
import { EvaluatorAssignmentForm } from "./EvaluatorAssignmentForm";
import { Option } from "@ucl/library/lib/components/Fields/types/fieldTypes";

const dialogIdBase = "wildfire-evaluator-assignment-dialog";

const getDialogId = (wildfireApplicationId: string): string =>
  `${dialogIdBase}_${wildfireApplicationId}`;

const buildEvaluatorAssignmentDialogProps = (
  evaluationId: string,
  evaluationCompanies: Option<number>[],
  onSubmit?: () => void
): DialogProps => {
  return {
    id: getDialogId(evaluationId),
    className: dialogIdBase,
    header: "Evaluator Assignment",
    allowDragging: false,
    content: () => (
      <EvaluatorAssignmentForm
        evaluationId={evaluationId}
        onSubmit={onSubmit}
        evaluationCompanies={evaluationCompanies}
      />
    ),
  };
};

export const openEvaluatorAssignmentDialog = (
  evaluationId: string,
  evaluationCompanies: Option<number>[],
  onSubmit?: () => void
) =>
  dialogStore.openDialog(
    buildEvaluatorAssignmentDialogProps(
      evaluationId,
      evaluationCompanies,
      onSubmit
    )
  );

export const closeEvaluatorAssignmentDialog = (wildfireApplicationId: string) =>
  dialogStore.closeDialog(getDialogId(wildfireApplicationId));
