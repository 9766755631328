import { Button, Icon } from "@blueprintjs/core";
import { WildfireEvaluationSteps } from "../../../../foritfied/types/evaluation/Evaluation";
import { IconNames } from "@blueprintjs/icons";
import { openAdminUpdateDialog } from "../../Dialogs/AdminUpdate/AdminUpdateDialog";

export interface AdminPanelButtonProps {
  evaluationId: string;
  homeOwnerId: string;
  auditorId: string;
  status: string;
  moveableStepOptionsByAdmin: {
    label: string;
    value: WildfireEvaluationSteps;
  }[];
  refreshWildfireApplication: () => Promise<void>;
}
const AdminPanelButton: React.FC<AdminPanelButtonProps> = (props) => {
  return (
    <div className="admin-panel-button">
      <Button
        minimal
        large
        onClick={() =>
          openAdminUpdateDialog(
            props.evaluationId,
            props.homeOwnerId,
            props.auditorId,
            props.status,
            props.moveableStepOptionsByAdmin,
            props.refreshWildfireApplication
          )
        }
      >
        <Icon icon={IconNames.COG} size={16} />
      </Button>
    </div>
  );
};

export default AdminPanelButton;
