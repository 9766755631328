import FortifiedGridColumn from "../../../types/fortifiedGrid/FortifiedGridColumn";

const gridColumnsConfiguration: FortifiedGridColumn[] = [
  new FortifiedGridColumn({
    field: "id",
    headerText: "GUID",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "creditType",
    headerText: "Credit Type",
    type: "number",
  }),
  new FortifiedGridColumn({
    field: "company",
    headerText: "Company",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "creditRemaining",
    headerText: "Credit Remaining",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "notes",
    headerText: "Notes",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "updatedAt_AsDateOnly",
    headerText: "Updated At",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
  }),
  new FortifiedGridColumn({
    field: "updatedBy_AsName",
    headerText: "Updated By",
    type: "string",
  }),
  new FortifiedGridColumn({
    field: "createdAt_AsDateOnly",
    headerText: "Created At",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
  }),
  new FortifiedGridColumn({
    field: "createdBy_AsName",
    headerText: "Created By",
    type: "string",
  }),
];

const gridRoleColumnsConfigurationDefaultFields = [
  "id",
  "creditType",
  "company",
  "creditRemaining",
  "notes",
  "updatedAt_AsDateOnly",
  "updatedBy_AsName",
  "createdAt_AsDateOnly",
  "createdBy_AsName",
];

export const gridRoleColumnsConfiguration: Record<
  "Default",
  FortifiedGridColumn[] | undefined
> = {
  Default: [
    ...gridColumnsConfiguration
      .filter((x) =>
        gridRoleColumnsConfigurationDefaultFields.includes(x.field)
      )
      .sort(function (a, b) {
        return (
          gridRoleColumnsConfigurationDefaultFields.indexOf(a.field) -
          gridRoleColumnsConfigurationDefaultFields.indexOf(b.field)
        );
      }),
  ],
};

export const getGridColumnsConfigurationByRole = () => {
  // const gridColumnsConfigurationForRole = gridRoleColumnsConfiguration[role];

  // return gridColumnsConfigurationForRole
  //   ? gridColumnsConfigurationForRole
  //   : (gridRoleColumnsConfiguration.Default as FortifiedGridColumn[]);

  return gridRoleColumnsConfiguration.Default as FortifiedGridColumn[];
};
