import { Button, Icon, Tag } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import { Observer } from "mobx-react";
import { fortifiedGridFiltersModuleStore } from "../../stores/FortifiedGridFiltersModuleStore";
import { fortifiedGridStore } from "../../stores/FortifiedGridStore";
import "./styles.scss";

export interface FortifiedGridFiltersModuleProps {}
export const FortifiedGridFiltersModule: React.FC<
  FortifiedGridFiltersModuleProps
> = () => {
  return (
    <Observer>
      {() => (
        <div className="fortified-grid-filters-module">
          {fortifiedGridStore.filtersCount > 0 &&
            !!fortifiedGridStore.filterSettings && (
              <div className="fortified-grid-filters">
                <Popover2
                  interactionKind="hover"
                  placement="left"
                  minimal={true}
                  portalClassName="fortified-grid-filters-module"
                  popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
                  content={
                    <div className="fortified-grid-filters-details">
                      <ul className="fortified-grid-filters-details-list">
                        {fortifiedGridFiltersModuleStore
                          .getSortDetails(
                            fortifiedGridStore.filterSettings.rawColumnSettings,
                            fortifiedGridStore.filterSettings.rawSortSettings
                          )
                          ?.map((sort) => (
                            <li key={`sort-${sort.field}`}>
                              <Tag
                                fill={false}
                                onRemove={() =>
                                  fortifiedGridStore.removeSort(sort.field)
                                }
                              >
                                {sort.details}
                                <Icon
                                  iconSize={10}
                                  icon={
                                    sort.direction === "Ascending"
                                      ? IconNames.ARROW_UP
                                      : IconNames.ARROW_DOWN
                                  }
                                />
                              </Tag>
                            </li>
                          ))}
                        {fortifiedGridFiltersModuleStore
                          .getFilterDetails(
                            fortifiedGridStore.filterSettings?.rawFilterSettings
                          )
                          ?.map((filter) => (
                            <li key={`filter-${filter.field}`}>
                              <Tag
                                fill={false}
                                onRemove={() =>
                                  fortifiedGridStore.removeFilter(filter.field)
                                }
                              >
                                {filter.details}
                              </Tag>
                            </li>
                          ))}
                      </ul>
                      <Button
                        className="fortified-grid-filters-details-clear"
                        minimal={true}
                        text="Clear All"
                        onClick={() => fortifiedGridStore.clearFilters()}
                      />
                    </div>
                  }
                >
                  <div className="fortified-grid-filters-count">
                    <Button
                      className="fortified-grid-filters-count-target"
                      title="Clear All"
                    >
                      <Icon
                        className="fortified-grid-filters-count-icon"
                        icon={IconNames.FILTER_LIST}
                      />
                      <span className="fortified-grid-filters-count-text">
                        {`${fortifiedGridStore.filtersCount} Filter(s)`}
                      </span>
                      <Icon
                        className="fortified-grid-filters-count-clear"
                        icon={IconNames.CROSS}
                        onClick={() => fortifiedGridStore.clearFilters()}
                      />
                    </Button>
                  </div>
                </Popover2>
              </div>
            )}
        </div>
      )}
    </Observer>
  );
};
