import {
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
  SmartAddressFieldProps,
} from "@ucl/library";
import { addressApiClient } from "../../../../../../foritfied/lib/apiClients/address/addressApiClient";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "BasicInformationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

//Part 1: Homeowner Information
const homeownerInformationFields = () => [
  buildFieldKey("HomeownerFirstName"),
  buildFieldKey("HomeownerLastName"),
  buildFieldKey("HomeownerEmail"),
];

const homeownerInformationRequiredFields = [
  buildFieldKey("HomeownerFirstName"),
  buildFieldKey("HomeownerLastName"),
  buildFieldKey("HomeownerEmail"),
];

export const homeStandardHomeownerInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        homeownerInformationFields(),
        onValueChange,
        form,
        errors,
        homeownerInformationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

//Part 2: Property Details

const propertyDetailsFields = (
  _form: Evaluation_HomeStandardEvaluationFormModel
) => [
  {
    fieldKey: buildFieldKey("Address"),
    getAddressesByQuery: addressApiClient.getAddressesByQuery,
    getAddressByQuery: addressApiClient.getAddressByQuery,
    isSmartOnly: false,
  } as SmartAddressFieldProps,
];

const propertyDetailsRequiredFields = [buildFieldKey("Address")];

export const homeStandardPropertyDetailsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        propertyDetailsFields(form),
        onValueChange,
        form,
        errors,
        propertyDetailsRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

//Part 3 General Project Details
const confirmationAffirmationsFields = (
  _form: Evaluation_HomeStandardEvaluationFormModel
) => [buildFieldKey("DuplicateAddressConfirmation")];

const confirmationAffirmationsRequiredFields = [
  buildFieldKey("DuplicateAddressConfirmation"),
];

export const homeStandardConfirmationAffirmationsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        confirmationAffirmationsFields(form),
        onValueChange,
        form,
        errors,
        confirmationAffirmationsRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included
export const homeStandardBasicInformationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...homeownerInformationFields(),
          ...propertyDetailsFields(form),
          ...confirmationAffirmationsFields(form),
        ],
        onValueChange,
        form,
        errors,
        [
          ...homeownerInformationRequiredFields,
          ...propertyDetailsRequiredFields,
          ...confirmationAffirmationsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeStandardIterationEngineBasicInformationFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...homeownerInformationFields(),
          ...propertyDetailsFields(form),
          ...confirmationAffirmationsFields(form),
        ],
        onValueChange,
        form,
        errors,
        [
          ...homeownerInformationRequiredFields,
          ...propertyDetailsRequiredFields,
          ...confirmationAffirmationsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};
