import { Classes, Button, Intent } from "@blueprintjs/core";
import { useState } from "react";

export interface DialogFooterProps {
  closeDialog: () => void;
  submitDialog: () => Promise<void>;
  isConfirmDisabled?: boolean;
  closeButtonText?: string;
  submitButtonText?: string;
  isSubmitButtonHidden?: boolean;
  children?: React.ReactNode;
}

export const DialogFooter: React.FC<DialogFooterProps> = ({
  closeDialog,
  submitDialog,
  isConfirmDisabled = false,
  closeButtonText = "No",
  submitButtonText = "Yes",
  isSubmitButtonHidden = false,
  children,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <div className={Classes.DIALOG_FOOTER}>
      <div className="dialog-footer-children">{children}</div>

      <Button
        minimal
        intent={Intent.DANGER}
        text={closeButtonText}
        onClick={closeDialog}
      />
      {!isSubmitButtonHidden && (
        <Button
          intent={Intent.SUCCESS}
          text={submitButtonText}
          hidden={isSubmitButtonHidden}
          disabled={isConfirmDisabled || isSubmitting}
          onClick={async () => {
            setIsSubmitting(true);
            await submitDialog().finally(() => {
              setIsSubmitting(false);
            });
          }}
          loading={isSubmitting}
        />
      )}
    </div>
  );
};
