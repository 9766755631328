import { Button, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import "./styles.scss";
import { evaluationStore } from "../../../stores/EvaluationStore";
import { useState } from "react";
import { browserDownloadFileResponse } from "../../../../common/utils/download";

interface DownloadRooferConfirmationFormActionButtonProps {}

const DownloadRooferConfirmationFormActionButton: React.FC<
  DownloadRooferConfirmationFormActionButtonProps
> = ({}) => {
  const [isLoading, setIsLoading] = useState(false);

  const fileName = `RooferConfirmationForm-${evaluationStore.evaluation?.fortifiedId}.pdf`;

  return (
    <Button
      minimal
      className="download-rcf-button"
      intent={Intent.NONE}
      rightIcon={<Icon icon={IconNames.IMPORT} color={"#4d85eb"} />}
      loading={isLoading}
      onClick={async () => {
        setIsLoading(true);
        await evaluationStore
          .getRooferConfirmationForm()
          .then((response) => {
            browserDownloadFileResponse(response.data, fileName);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }}
    >
      Download RCF
    </Button>
  );
};

export default DownloadRooferConfirmationFormActionButton;
