import { makeObservable, observable } from "mobx";
import { paymentCreditApiClient } from "../lib/apiClients/paymentCredit/paymentCreditApiClient";
import {
  Option,
  PaymentCreditCompanyReadItemDTO,
} from "../lib/apiClients/paymentCredit/types";

class PaymentCreditStore {
  public paymentCreditCompanies?: PaymentCreditCompanyReadItemDTO[];

  constructor() {
    makeObservable(this, {
      paymentCreditCompanies: observable,
    });
  }

  public async setPaymentCreditCompanies() {
    this.paymentCreditCompanies =
      (await paymentCreditApiClient.getPaymentCreditCompanies()) as any;
  }
  get paymentCreditCompanyOptions() {
    if (!this.paymentCreditCompanies) {
      return [];
    }

    return this.paymentCreditCompanies.map<Option>((company) => ({
      label: company.companyName,
      value: company.companyName,
    }));
  }
}

export const paymentCreditStore = new PaymentCreditStore();
