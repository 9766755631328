import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useContext } from "react";
import "../styles.scss";
import { reactPlugin } from "../../../AppInsights";
import {
  errorStore,
  genericErrorMessage,
} from "../../../common/Components/Error/ErrorStore";
import { WildfireReportTypes, reports } from "./reports";
import "./styles.scss";
import { BackToReportsPageButton } from "../../components/Button/BackToReportsPageButton/BackToReportsPageButton";
import { PermissionsContext, PermissionsContextProps } from "@ucl/library";

export interface WildfireReportViewerPageProps {
  reportType?: WildfireReportTypes;
}

export const WildfireReportViewerPageComponent: React.FC<
  WildfireReportViewerPageProps
> = ({ reportType }: WildfireReportViewerPageProps) => {
  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  if (!reportType) {
    errorStore.setErrorMessage(genericErrorMessage);
    return null;
  }

  const report = reports(hasPermission)[reportType];

  return (
    <>
      <div className="wildfire-report-header">
        <BackToReportsPageButton />
        <h1>{report.title}</h1>
      </div>
      {report.hasViewPermission && (
        <div className="wildfire-report-viewer">
          <iframe
            title={report.title}
            width="100%"
            height="100%"
            src={report.reportLink}
            frameBorder="0"
          />
        </div>
      )}
    </>
  );
};

export const WildfireReportViewerPage = withAITracking(
  reactPlugin,
  WildfireReportViewerPageComponent,
  "WildfireReportViewerPage",
  "tracking-container"
);
