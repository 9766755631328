import { dialogStore } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import {
  WildfireRejectionForm,
  WildfireRejectionFormModel,
} from "./WildfireRejectionForm";
import "../styles.scss";

const dialogIdBase = "wildfire-rejection-dialog";

const getDialogId = (wildfireApplicationId: string): string =>
  `${dialogIdBase}_${wildfireApplicationId}`;

const buildWildfireRejectionDialogProps = (
  evaluationId: string,
  wildfireApplicationId: string,
  reject: (form: WildfireRejectionFormModel) => Promise<void>
): DialogProps => {
  return {
    id: getDialogId(wildfireApplicationId),
    className: dialogIdBase,
    header: "Rejection Reasons",
    allowDragging: false,
    content: () => (
      <WildfireRejectionForm
        evaluationId={evaluationId}
        wildfireApplicationId={wildfireApplicationId}
        reject={reject}
      />
    ),
  };
};

export const openWildfireRejectionDialog = (
  evaluationId: string,
  wildfireApplicationId: string,
  reject: (form: WildfireRejectionFormModel) => Promise<void>
) =>
  dialogStore.openDialog(
    buildWildfireRejectionDialogProps(
      evaluationId,
      wildfireApplicationId,
      reject
    )
  );

export const closeWildfireRejectionDialog = (wildfireApplicationId: string) =>
  dialogStore.closeDialog(getDialogId(wildfireApplicationId));
