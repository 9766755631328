export const defaultFields = [
  "externalUnreadCommentCount",
  "lastUnreadCommentDate",
  "auditorNotes",
  "fortifiedId",
  "status_AsString",
  "previousStatus_AsString",
  "auditor_AsFullName",
  "newChangeRequestCount",
  "lastChangeRequestAddressedAtDate",
  "buildingAddress",
  "buildingAddress2",
  "buildingCity",
  "buildingState_AsString",
  "buildingZip",
  "buildingCounty",
  "homeOwnerFirstName",
  "homeOwnerLastName",
  "homeOwnerEmail",
  "homeOwnerPhone",
  "evaluationCompany",
  "wildfire_DesignationLevel",
  "downloadCertificate",
  "modifiedAt",
  "modifiedBy_AsName",
];

export const wildfireAdmin_DefaultFields = [
  "externalUnreadCommentCount",
  "lastUnreadCommentDate",
  "auditorNotes",
  "fortifiedId",
  "status_AsString",
  "auditor_AsFullName",
  "addressedChangeRequestCount",
  "lastChangeRequestAddressedAtDate",
  "buildingAddress",
  "buildingAddress2",
  "buildingCity",
  "buildingState_AsString",
  "buildingZip",
  "buildingCounty",
  "homeOwnerFirstName",
  "homeOwnerLastName",
  "homeOwnerEmail",
  "homeOwnerPhone",
  "evaluationCompany",
  "wildfire_DesignationLevel",
  "downloadCertificate",
  "modifiedAt",
  "modifiedBy_AsName",
];

const wildfireAdmin_OptionalFields = [
  "previousStatus_AsString",
  "productType_AsString",
  "statusUpdatedAt",
  "daysSinceStatusUpdated",
  "totalAmountPaid",
  "wildfire_HasGate",
  "wildfire_NeedsAppointment",
  "wildfire_HomeServiceOption",
  "wildfire_CoOwnerFirstName",
  "wildfire_CoOwnerLastName",
  "wildfire_CoOwnerEmail",
  "wildfire_CoOwnerPhone",
  "wildfire_HomeownerPhone",
  "wildfire_PaymentDate",
  "wildfire_EvaluationPartnerSentDate",
  "wildfire_EvaluationPartnerInspectionDate",
  "wildfire_EvaluationPartnerReceivedDate",
  "submittedDate",
  "approvedAt",
  "expiresAt",
  "rejectionReasons_AsCSV",
  "wildfire_EvaluationPhase_AsString",
  "modifiedAt_AsDateOnly",
  "createdAt_AsDateOnly",
  "createdAt",
  "createdBy_AsName",
  "auditStartDate",
  "auditEndDate",
  "isWithdrawn",
  "withdrawnDate",
  "withdrawnBy",
  "withdrawnBy_AsName",
  "withdrawnReason",
  "reactivateDate",
  "wildfire_HasHotTub",
  "wildfire_HasStationaryPropaneTank",
  "wildfire_HasSecondaryContact",
  "wildfire_SecondaryContactFirstName",
  "wildfire_SecondaryContactLastName",
  "wildfire_SecondaryContactEmail",
  "wildfire_SecondaryContactPhoneNumber",
  "daysInChangesRequired",
  "newChangeRequestCount",
  "lastChangeRequestAddressedAtDate_AsDateOnly",
  "isInIBHSReview",
  "isInIBHSEligibilityReview",
];

export const wildfireAdmin_AllFields = [
  ...wildfireAdmin_DefaultFields,
  ...wildfireAdmin_OptionalFields,
];

export const wildfireEvaluatorFields = [
  "externalUnreadCommentCount",
  "lastUnreadCommentDate",
  "fortifiedId",
  "productType_AsString",
  "status_AsString",
  "statusUpdatedAt",
  "buildingAddress",
  "buildingAddress2",
  "buildingCity",
  "buildingState_AsString",
  "buildingZip",
  "buildingCounty",
  "wildfire_HasGate",
  "wildfire_NeedsAppointment",
  "wildfire_HomeServiceOption",
  "homeOwnerFirstName",
  "homeOwnerLastName",
  "homeOwnerEmail",
  "homeOwnerPhone",
  "wildfire_CoOwnerFirstName",
  "wildfire_CoOwnerLastName",
  "wildfire_CoOwnerEmail",
  "wildfire_CoOwnerPhone",
  "wildfire_HomeownerPhone",
  "wildfire_PaymentDate",
  "evaluationCompany",
  "wildfire_EvaluationPartnerSentDate",
  "wildfire_EvaluationPartnerInspectionDate",
  "wildfire_EvaluationPartnerReceivedDate",
  "wildfire_DesignationLevel",
  "submittedDate",
  "approvedAt",
  "downloadCertificate",
  "expiresAt",
  "rejectionReasons_AsCSV",
  "wildfire_EvaluationPhase_AsString",
  "modifiedAt_AsDateOnly",
  "modifiedAt",
  "modifiedBy_AsName",
  "createdAt_AsDateOnly",
  "createdAt",
  "createdBy_AsName",
  "auditor_AsFullName",
  "auditStartDate",
  "auditEndDate",
  "isWithdrawn",
  "withdrawnDate",
  "withdrawnBy_AsName",
  "withdrawnReason",
  "reactivateDate",
  "wildfire_HasHotTub",
  "wildfire_HasStationaryPropaneTank",
  "wildfire_HasSecondaryContact",
  "wildfire_SecondaryContactFirstName",
  "wildfire_SecondaryContactLastName",
  "wildfire_SecondaryContactEmail",
  "wildfire_SecondaryContactPhoneNumber",
  "daysInChangesRequired",
  "previousStatus_AsString",
];

export const wildfireApplicantFields = [
  "unreadCommentsCount",
  "fortifiedId",
  "auditorNotes",
  "productType_AsString",
  "status_AsString",
  "buildingAddress",
  "buildingAddress2",
  "buildingCity",
  "buildingState_AsString",
  "buildingZip",
  "buildingCounty",
  "homeOwnerFirstName",
  "homeOwnerLastName",
  "homeOwnerEmail",
  "wildfire_CoOwnerFirstName",
  "wildfire_CoOwnerLastName",
  "wildfire_CoOwnerEmail",
  "wildfire_CoOwnerPhone",
  "wildfire_DesignationLevel",
  "approvedAt",
  "downloadCertificate",
  "modifiedAt_AsDateOnly",
  "modifiedAt",
  "modifiedBy_AsName",
  "createdAt_AsDateOnly",
  "createdAt",
  "createdBy_AsName",
  "isWithdrawn",
  "withdrawnDate",
  "withdrawnBy_AsName",
  "withdrawnReason",
  "reactivateDate",
  "wildfire_HasHotTub",
  "wildfire_HasStationaryPropaneTank",
  "wildfire_HasSecondaryContact",
  "wildfire_SecondaryContactFirstName",
  "wildfire_SecondaryContactLastName",
  "wildfire_SecondaryContactEmail",
  "wildfire_SecondaryContactPhoneNumber",
  "daysInChangesRequired",
  "previousStatus_AsString",
];
