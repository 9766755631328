import {
  AddressFieldValue,
  EmailFieldValue,
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
  NumericFieldValue,
  PhoneNumberFieldValue,
  SingleCheckboxFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum FCFMFApplicationFormParts {
  ApplicationInformation = "application-information",
  EmailNotifications = "email-notifications",
  GeneralProjectDetails = "general-project-details",
  NewConstructionsAndNewAdditions = "new-constructions-and-new-additions",
  ExistingConstructionReroofing = "existing-construction-reroofing",
  ProjectConstructionDetails = "project-construction-details",
  ExistingRoofCoverSystem = "existing-roof-cover-system",
  FileUploaders = "file-uploaders",
  ConfirmationFields = "confirmation-fields",
}

export interface BaseFCFMFApplicationFormModel
  extends BaseFCFMFApplicationFormAplicationInformation,
    BaseFCFMFApplicationFormEmailNotifications,
    BaseFCFMFGeneralProjectDetails,
    BaseFCFMFNewConstructionsAndNewAdditions,
    BaseFCFMFExistingConstructionReroofing,
    BaseFCFMFProjectConstructionDetails,
    BaseFCFMFRoofCoverSystemDetails,
    BaseFCFMFApplicationFormFileUploaders,
    BaseFCFMFApplicationFormConfirmationFields {}

interface BaseFCFMFApplicationFormAplicationInformation {
  // Part 1: Application Information
  designationLevel: EnumRadioFieldValue;
  applicantFirstName: TextFieldValue;
  applicantLastName: TextFieldValue;
  businessName: TextFieldValue;
  businessAddress: AddressFieldValue;
  businessPhoneNumber: PhoneNumberFieldValue;
}

interface BaseFCFMFApplicationFormEmailNotifications {
  // Part 2: Email Notifications
  areYouTheBuildingOwner: EnumRadioFieldValue;
  businessEmail: EmailFieldValue;
  title: TextFieldValue;
  companyName: TextFieldValue;
  phoneNumber: PhoneNumberFieldValue;
}

interface BaseFCFMFGeneralProjectDetails {
  // Part 3: General Project Details
  isThisANewConstruction: EnumRadioFieldValue;
  propertyHasMoreThanOneBuilding: EnumRadioFieldValue;
}

interface BaseFCFMFNewConstructionsAndNewAdditions {
  // Part 4: New Constructions & New Additions
  projectPhaseType: EnumRadioFieldValue;
  approxSizeSqFt: NumericFieldValue;
  approxHeightFt: NumericFieldValue;
}

interface BaseFCFMFExistingConstructionReroofing {
  // Part 5: Existing Construction - Reroofing
  yearCompleted: NumericFieldValue;
  yearsOccupied: NumericFieldValue;
  sizeSqfts: NumericFieldValue;
  heightFts: NumericFieldValue;
}

interface BaseFCFMFProjectConstructionDetails {
  // Part 6: Project Construction Details
  projectOrBuildingName: TextFieldValue;
  address: AddressFieldValue;
  hazardType: EnumRadioFieldValue;
  hasHailSupplement: EnumRadioFieldValue;
  ibcVersionType: EnumSingleSelectFieldValue;
  asceCodeType: EnumSingleSelectFieldValue;
  importanceFactorType: EnumRadioFieldValue;
  riskCategoryType: EnumRadioFieldValue;
  exposureCategoryType: EnumRadioFieldValue;
  numberOfRoofCoverSystems: NumericFieldValue;
  grossRoofAreaSqft: NumericFieldValue;
  totalFloorAreaSqft: NumericFieldValue;
}

interface BaseFCFMFRoofCoverSystemDetails {
  // Part 7: Roof Cover System Details
  //1.
  isRoofCoverSystem1GoingToBeReplaced: EnumRadioFieldValue;
  roofCoverSystem1ExistingRoofAgeYears: NumericFieldValue;
  roofCoverSystem1ExistingRoofSlope: EnumSingleSelectFieldValue;
  isRoofCoverSystem1ContinuousParapet: EnumRadioFieldValue;
  roofCoverSystem1ParapetHeightFt: NumericFieldValue;

  //2.
  isRoofCoverSystem2GoingToBeReplaced: EnumRadioFieldValue;
  roofCoverSystem2ExistingRoofAgeYears: NumericFieldValue;
  roofCoverSystem2ExistingRoofSlope: EnumSingleSelectFieldValue;
  isRoofCoverSystem2ContinuousParapet: EnumRadioFieldValue;
  roofCoverSystem2ParapetHeightFt: NumericFieldValue;

  //3.
  isRoofCoverSystem3GoingToBeReplaced: EnumRadioFieldValue;
  roofCoverSystem3ExistingRoofAgeYears: NumericFieldValue;
  roofCoverSystem3ExistingRoofSlope: EnumSingleSelectFieldValue;
  isRoofCoverSystem3ContinuousParapet: EnumRadioFieldValue;
  roofCoverSystem3ParapetHeightFt: NumericFieldValue;

  //4.
  isRoofCoverSystem4GoingToBeReplaced: EnumRadioFieldValue;
  roofCoverSystem4ExistingRoofAgeYears: NumericFieldValue;
  roofCoverSystem4ExistingRoofSlope: EnumSingleSelectFieldValue;
  isRoofCoverSystem4ContinuousParapet: EnumRadioFieldValue;
  roofCoverSystem4ParapetHeightFt: NumericFieldValue;

  //5.
  isRoofCoverSystem5GoingToBeReplaced: EnumRadioFieldValue;
  roofCoverSystem5ExistingRoofAgeYears: NumericFieldValue;
  roofCoverSystem5ExistingRoofSlope: EnumSingleSelectFieldValue;
  isRoofCoverSystem5ContinuousParapet: EnumRadioFieldValue;
  roofCoverSystem5ParapetHeightFt: NumericFieldValue;

  //6.
  isRoofCoverSystem6GoingToBeReplaced: EnumRadioFieldValue;
  roofCoverSystem6ExistingRoofAgeYears: NumericFieldValue;
  roofCoverSystem6ExistingRoofSlope: EnumSingleSelectFieldValue;
  isRoofCoverSystem6ContinuousParapet: EnumRadioFieldValue;
}

interface BaseFCFMFApplicationFormFileUploaders {
  architecturalDrawings: WildfireBoxContentExplorerValue;
  architecturalStructuralDrawings: WildfireBoxContentExplorerValue;
}

interface BaseFCFMFApplicationFormConfirmationFields {
  paymentVerificationAndAcknowledgement: SingleCheckboxFieldValue;
}
