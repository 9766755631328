import { Button, Intent } from "@blueprintjs/core";
import { useState } from "react";
import { evaluationStore } from "../../../stores/EvaluationStore";
import { dialogsViewerStore } from "../../../stores/DialogsViewerStore";
import { genericErrorMessage } from "../../../../common/Components/Error/ErrorStore";
import { AppToaster, showSuccessAppToaster } from "../../Toast/Toast";

interface ClaimEvaluationActionButtonProps {
  afterClaimed?: () => void;
  isEvaluationClaimedByCurrentUser: boolean;
}

const ClaimEvaluationActionButton: React.FC<
  ClaimEvaluationActionButtonProps
> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const openExisitingAddressConfirmation = () => {
    dialogsViewerStore.confirm({
      confirmButtonText: "Continue",
      cancelButtonText: "Cancel",
      confirmHeaderText: "Warning - Duplicate Address",
      content: (
        <>
          An Evaluation already exists for this address. Please review existing
          Evaluations to confirm if this duplicate address is a legitimate
          submission.
          <ul>
            <li>If yes, please click Continue to start the audit.</li>
            <li>If no, please click Cancel and contact the Evaluator.</li>
          </ul>
        </>
      ),
      onClose: () => {
        setIsLoading(false);
      },
      onConfirm: async () => {
        return submitEvaluationClaim();
      },
    });
  };

  const submitEvaluationClaim = async () => {
    const result = { isSuccess: true, error: undefined };

    await evaluationStore
      .claimEvaluation()
      .then(() => {
        showSuccessAppToaster("Evaluation Claimed.");
        props.afterClaimed && props.afterClaimed();
      })
      .catch((error) => {
        result.isSuccess = false;
        result.error = error;

        AppToaster.show({
          message: <div>{genericErrorMessage}</div>,
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    return result;
  };

  return props.isEvaluationClaimedByCurrentUser ? (
    <Button minimal intent={Intent.PRIMARY} disabled>
      Claimed Evaluation
    </Button>
  ) : (
    <Button
      minimal
      intent={Intent.PRIMARY}
      loading={isLoading}
      onClick={async () => {
        setIsLoading(true);
        if (evaluationStore.evaluation?.otherEvaluationsHaveSameAddress) {
          openExisitingAddressConfirmation();
        } else {
          submitEvaluationClaim();
        }
      }}
    >
      Claim Evaluation
    </Button>
  );
};

export default ClaimEvaluationActionButton;
