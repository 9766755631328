import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { FCFMFRedesignationFormParts } from "../../../common/types/Redesignation/BaseFCFMFRedesignationFormModel";
import { MultifamilyRedesignationForm_FormModel } from "../../types/MultifamilyRedesignationForm_FormModel";
import { Evaluation_MultifamilyRedesignationFormModel } from "../../types/MultifamilyRedesignationFormModel";

class MultifamilyRedesignationFormAPIClient {
  updateMultifamilyRedesignationForm = async (
    evaluationId: string,
    redesignationForm: MultifamilyRedesignationForm_FormModel,
    fieldKey?: string
  ): Promise<Evaluation_MultifamilyRedesignationFormModel> => {
    return baseApiClient.put<Evaluation_MultifamilyRedesignationFormModel>(
      `/multifamily/redesignation-form/${evaluationId}`,
      {
        redesignationForm,
        fieldKey,
      }
    );
  };

  submitMultifamilyRedesignationForm = async (
    wildfireApplicationId: string,
    redesignationForm: MultifamilyRedesignationForm_FormModel,
    formPart?: FCFMFRedesignationFormParts
  ): Promise<Evaluation_MultifamilyRedesignationFormModel> => {
    const applicationFormPartValue = (
      formPart ??
      (Object.values(
        FCFMFRedesignationFormParts
      ).pop() as FCFMFRedesignationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_MultifamilyRedesignationFormModel>(
      `/multifamily/redesignation-form/${wildfireApplicationId}/${applicationFormPartValue}`,
      redesignationForm
    );
  };
}

export const multifamilyRedesignationFormAPIClient =
  new MultifamilyRedesignationFormAPIClient();
