import { useState } from "react";
import { WildfireEvaluationSteps } from "../../../../foritfied/types/evaluation/Evaluation";
import EvaluationForm from "../../Forms/EvaluationForms/EvaluationForm";
import { ChangeRequestV2 } from "../types/ChangeRequestV2";
import { PlusTabId } from "../useWildfireIterationEngine";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";

interface IterationEngineFormsProps {
  selectedTabId: string;
  changeRequests: ChangeRequestV2[];
  evaluationId: string;
  evaluationStep: WildfireEvaluationSteps;
  isPlus?: boolean;
  getChangeRequests?: () => Promise<void>;
  fetchFormFieldEvaluationDecisioning: (evaluationId: string) => Promise<void>;
  onEvaluationFormInitialized?: () => void;
  fieldsContainerClassName: string;
}

const WildfireIterationEngineForms: React.FC<IterationEngineFormsProps> = (
  props
) => {
  const onFormSave = async (fieldKey: string) => {
    const fieldKeyHasChangeRequest = props.changeRequests.some(
      (cr) => cr.evaluationFieldKey === fieldKey
    );
    if (fieldKeyHasChangeRequest) {
      props.getChangeRequests && (await props.getChangeRequests());
    }

    props.fetchFormFieldEvaluationDecisioning(props.evaluationId);
  };

  const [ready, setReady] = useState(false);

  const onAllFormsInitialized = () => {
    props.onEvaluationFormInitialized?.();
    setTimeout(() => {
      setReady(true);
    }, 800);
  };

  return (
    <>
      {!ready && <Loading />}

      <div style={{ visibility: ready ? "visible" : "hidden" }}>
        {props.selectedTabId !== PlusTabId && (
          <EvaluationForm
            fieldsContainerClassName={props.fieldsContainerClassName}
            evaluationId={props.evaluationId}
            evaluationStep={props.evaluationStep}
            isPlus={props.isPlus || false}
            onAllFormsInitialized={onAllFormsInitialized}
            onFormSave={async (fieldKey) => {
              onFormSave(fieldKey);
            }}
          />
        )}
        {props.selectedTabId === PlusTabId && (
          <EvaluationForm
            fieldsContainerClassName={props.fieldsContainerClassName}
            evaluationId={props.evaluationId}
            evaluationStep={props.evaluationStep}
            isPlus={props.isPlus || false}
            onAllFormsInitialized={onAllFormsInitialized}
            onFormSave={async (fieldKey) => {
              onFormSave(fieldKey);
            }}
          />
        )}
      </div>
    </>
  );
};

export default WildfireIterationEngineForms;
