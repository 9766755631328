import { useEffect, useState } from "react";
import { User } from "../../Components/User/userTypes";

interface UserGuidingUserAttributes {
  email?: string;
  name?: string;
  created_at?: number;
  role?: string;
  company?: {
    id: string;
    name: string;
  };
}
interface UseUserGuidingProps {
  user?: User;
}

const useUserGuidingWithIdentification = (props: UseUserGuidingProps) => {
  const [isUserGuidingReady, setUserGuidingReady] = useState<boolean>(false);

  const querySelector = "script[src^='https://static.userguiding.com']";
  const scriptSource =
    "https://static.userguiding.com/media/user-guiding-TBU7911905AID-embedded.js";

  const userGuidingMethods = [
    "previewGuide",
    "finishPreview",
    "track",
    "identify",
    "hideChecklist",
    "launchChecklist",
  ];

  useEffect(() => {
    appendUserGuidingScript();
  }, []);

  useEffect(() => {
    // Once UserGuiding is ready, call the identify method
    setUserAttributes();
  }, [isUserGuidingReady, props.user]);

  const appendUserGuidingScript = () => {
    if (!window.userGuiding && !document.querySelector(querySelector)) {
      const script = document.createElement("script");
      script.async = true;
      script.onload = () => setUserGuidingReady(true); // Set ready state once script is loaded
      script.src = scriptSource;
      document.head.appendChild(script);

      // Initialize the UserGuiding object
      window.userGuiding = {
        q: [],
        c:
          (n: string) =>
          (...args: any[]) =>
            window.userGuiding?.q.push([n, args]),
      };

      // Define methods to be included in the UserGuiding object
      userGuidingMethods.forEach((method) => {
        window.userGuiding![method] = window.userGuiding!.c(method);
      });
    }
  };

  const setUserAttributes = () => {
    if (props.user && isUserGuidingReady && window.userGuiding?.identify) {
      const userAttributes: UserGuidingUserAttributes = {
        email: props.user.email,
        name: `${props.user.firstName} ${props.user.lastName}`,
        role: props.user.role,
      };

      window.userGuiding.identify(props.user.id, userAttributes);
    }
  };
};

export default useUserGuidingWithIdentification;
