import { InformationalBanner } from "../InformationalBanner";

export interface PhotoRequirementsBannerProps {}

export const PhotoRequirementsBanner: React.FC<
  PhotoRequirementsBannerProps
> = ({}: PhotoRequirementsBannerProps) => {
  return (
    <InformationalBanner
      title="Section Help"
      content={
        <>
          <p>
            Submit photos demonstrating that you&apos;ve completed the required
            D-I-Y prep work to your home/property outlined in the How-To Prepare
            My Home Checklist. Photos of all four sides showing the 5-foot
            noncombustible buffer surrounding the home will be reviewed for
            eligibility. Once photos of eligibility are confirmed, a 3rd party
            evaluator will visit the home to document areas where the work is
            complete. Note: Not all homes will be eligible for an in-person
            evaluation.
          </p>
          <p>
            <span style={{ textDecoration: "underline" }}>
              Photography Tips:
            </span>
            <p>
              CAPTURING: Capture a wide-angle photo of every side of your home.
              If you can&apos;t see a whole side clearly without vegetation,
              stand at each corner and take pictures along the length to include
              the 5-foot noncombustible zone. Aim for 4-8 photos in total.
            </p>
            <ul>
              <li>Front of home</li>
              <li>
                Right side of home (when looking from the front of the home)
              </li>
              <li>Back/rear of home</li>
              <li>
                Left side of home (when looking from the front of the home)
              </li>
            </ul>
          </p>
          <p>
            FILE FORMATS: We accept the following types: jpg, pdf, jpeg, gif,
            png, svg, webp, jfif, heic, heif
          </p>
        </>
      }
    />
  );
};
