import {
  Icon,
  Intent,
  Spinner,
  SpinnerSize,
  Tag,
  TagProps,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import { EvaluationForm } from "../../../types/evaluation/Evaluation";
import { EvaluationSchema } from "../../../types/evaluation/EvaluationSchema";
import { CompletionStatus } from "../../../types/viewModels/Checklist";
import "./styles.scss";

const ChecklistItemStatusConfig: { [key: string]: TagProps } = {
  complete: {
    minimal: true,
    title: "Complete",
    intent: Intent.SUCCESS,
  },
  incomplete: {
    minimal: true,
    title: "Incomplete",
    intent: Intent.DANGER,
  },
  disabled: {},
};

const buildDisabledMessage = (
  dependencyTypes: number[],
  evaluationSchema?: EvaluationSchema
) => {
  if (!evaluationSchema) {
    return (
      <div>
        <span>
          This form cannot be accessed until all of its form dependencies have
          been completed.
        </span>
      </div>
    );
  }
  return (
    <div>
      <span>
        This form cannot be accessed until the following forms are completed:
        <ul>
          {dependencyTypes.map((dependencyType, idx) => {
            const dependency = evaluationSchema.evaluationItemTypes.find(
              (itemType) => itemType.id === dependencyType
            );

            return (
              dependency && (
                <li key={idx} className="status-disabled_item">
                  <span className="status-disabled_item_name">
                    {dependency.label}
                  </span>
                </li>
              )
            );
          })}
        </ul>
      </span>
    </div>
  );
};

export interface ChecklistItemStatusProps {
  checklistItem: EvaluationForm;
  evaluationSchema?: EvaluationSchema;
}

export const ChecklistItemStatus: React.FC<ChecklistItemStatusProps> = ({
  checklistItem,
  evaluationSchema,
}) => {
  const { isCompleted, isDisabled, isSyncing, evaluationItemDependencies } =
    checklistItem;

  const checklistItemStatus: CompletionStatus = isCompleted
    ? "complete"
    : isDisabled
    ? "disabled"
    : isSyncing
    ? "syncing"
    : "incomplete";

  switch (checklistItemStatus) {
    case "complete":
    case "incomplete":
      const { title, ...checklistItemStatusConfig } =
        ChecklistItemStatusConfig[checklistItemStatus];
      return (
        <Tag round={true} {...checklistItemStatusConfig}>
          {title}
        </Tag>
      );
    case "disabled":
      return (
        <div className="checklist-item__status-disabled">
          <Popover2
            interactionKind="hover"
            placement="bottom-end"
            portalClassName="tooltip-popover checklist-item-tooltip-popover__status-disabled"
            popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
            content={buildDisabledMessage(
              evaluationItemDependencies,
              evaluationSchema
            )}
            renderTarget={({ isOpen: _, ...targetProps }) => (
              <div {...targetProps}>
                <Icon icon={IconNames.LOCK} size={14} />
                Disabled
              </div>
            )}
          />
        </div>
      );
    case "syncing":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner size={SpinnerSize.SMALL} />
          <span
            style={{
              marginLeft: "8px",
            }}
          >
            Syncing...
          </span>
        </div>
      );
    default:
      return null;
  }
};
