import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useNavigate } from "react-router";
import "./styles.scss";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";

export interface DashboardNavigationButtonProps {}
export const DashboardNavigationButton: React.FC<
  DashboardNavigationButtonProps
> = () => {
  const navigate = useNavigate();

  return (
    <div className="wildfire-dashboard-navigation-button-container">
      <Button
        className="wildfire-dashboard-navigation-button"
        minimal
        text="Back to Dashboard"
        intent={Intent.PRIMARY}
        icon={IconNames.ARROW_LEFT}
        onClick={() => navigate(`${wildfireRoutePrefix}/board`)}
      />
    </div>
  );
};
