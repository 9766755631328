import React from "react";
import { Button, Intent } from "@blueprintjs/core";
import { useNavigate } from "react-router";
import { useProductTypeHandler } from "../../../providers/ProductTypeHandlerProvider";

interface IterationEngineActionButtonProps {
  evaluationId: string;
}

const IterationEngineActionButton: React.FC<
  IterationEngineActionButtonProps
> = (props) => {
  const navigate = useNavigate();
  const { routePrefix } = useProductTypeHandler();
  return (
    <Button
      intent={Intent.PRIMARY}
      text="View Iteration Engine"
      small
      onClick={() => {
        navigate(`${routePrefix}/evaluation/${props.evaluationId}`);
      }}
    />
  );
};

export default IterationEngineActionButton;
