import { useState } from "react";
import {
  errorStore,
  genericErrorMessage,
} from "../../common/Components/Error/ErrorStore";
import { WildfireRejectionFormModel } from "../components/Dialogs/Rejection/WildfireRejectionForm";
import { wildfireEvaluationApiClient } from "../lib/apiClients/application/wildfireApplicationApiClient";
import { WildfireEvaluation } from "../types/WildfireApplication/WildfireApplication";

export const useWildfireEvaluation = () => {
  const [isApplicationLoading, setIsApplicationLoading] = useState(false);
  const [areFieldKeysLoading, setAreFieldKeysLoading] = useState(false);
  const [isSendForwardLoading, setIsSendForwardLoading] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [isRequestChangesLoading, setIsRequestChangesLoading] = useState(false);

  const [wildfireEvaluation, setWildfireEvaluation] =
    useState<WildfireEvaluation | null>();

  const [evaluationFieldkeys, setEvaluationFieldkeys] = useState<string[]>();

  const setWildfireEvaluationById = async (id?: string) => {
    setIsApplicationLoading(true);

    if (id) {
      await wildfireEvaluationApiClient
        .getWildfireEvaluationById(id)
        .then((response) => {
          setWildfireEvaluation(response);
        })
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        })
        .finally(() => {
          setIsApplicationLoading(false);
        });
    } else {
      setWildfireEvaluation(null);
    }
  };

  const refreshWildfireEvaluation = async () => {
    setIsApplicationLoading(true);
    if (wildfireEvaluation?.id) {
      await wildfireEvaluationApiClient
        .getWildfireEvaluationById(wildfireEvaluation?.id)
        .then((response) => {
          setWildfireEvaluation(response);
          //setWildfireEvaluationHeader(response);
        })
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        })
        .finally(() => {
          setIsApplicationLoading(false);
        });
    }
  };

  const getEvaluationFieldkeys = async (id?: string) => {
    setAreFieldKeysLoading(true);
    if (id) {
      await wildfireEvaluationApiClient
        .getWildfireEvaluationCommentAndChangeRequestFieldKeys(id)
        .then((response) => {
          setEvaluationFieldkeys(response);
        })
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        })
        .finally(() => {
          setAreFieldKeysLoading(false);
        });
    }
  };

  const downloadCertificate = async () => {
    if (!wildfireEvaluation) {
      return;
    }

    return wildfireEvaluationApiClient.downloadCertificate(
      wildfireEvaluation.id
    );
  };

  const regenerateCertificate = async () => {
    if (!wildfireEvaluation) {
      return;
    }

    return wildfireEvaluationApiClient.regenerateCertificate(
      wildfireEvaluation.id
    );
  };

  const sendForward = async () => {
    if (!wildfireEvaluation) {
      return;
    }
    setIsSendForwardLoading(true);

    await wildfireEvaluationApiClient
      .sendForward(wildfireEvaluation.id)
      .finally(() => {
        setIsSendForwardLoading(false);
      });

    await setWildfireEvaluationById(wildfireEvaluation.id);
  };

  const approve = async () => {
    if (!wildfireEvaluation) {
      return;
    }
    setIsApproveLoading(true);

    await wildfireEvaluationApiClient
      .approve(wildfireEvaluation.id)
      .then((response) => {
        setWildfireEvaluation(response);
      })
      .finally(() => {
        setIsApproveLoading(false);
      });
  };

  const reject = async (form: WildfireRejectionFormModel) => {
    if (!wildfireEvaluation) {
      return;
    }
    setIsRejectLoading(true);

    await wildfireEvaluationApiClient
      .reject(wildfireEvaluation.id, form)
      .then((response) => {
        setWildfireEvaluation(response);
      })
      .finally(() => {
        setIsRejectLoading(false);
      });
  };

  const requestChanges = async () => {
    if (!wildfireEvaluation) {
      return;
    }
    setIsRequestChangesLoading(true);

    await wildfireEvaluationApiClient
      .requestChanges(wildfireEvaluation.id)
      .finally(() => {
        setIsRequestChangesLoading(false);
      });
  };

  return {
    wildfireEvaluation,
    evaluationFieldkeys,
    setWildfireEvaluationById,
    refreshWildfireEvaluation,
    isApplicationLoading,
    areFieldKeysLoading,
    sendForward,
    isSendForwardLoading,
    approve,
    isApproveLoading,
    reject,
    isRejectLoading,
    requestChanges,
    isRequestChangesLoading,
    downloadCertificate,
    regenerateCertificate,
    getEvaluationFieldkeys,
  };
};
