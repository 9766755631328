import {
  EnumRadioFieldProps,
  FieldFormV2,
  FieldFormV2Module,
  FieldSchemaFormBuilderV2,
  Footer,
} from "@ucl/library";
import { FC, useRef, useState } from "react";
import { EnumRadioFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { useNavigate } from "react-router";
import "./styles.scss";
import WildfireSecondaryButton from "../../../../../../../../wildfire/components/Button/DialogFooterButtons/SecondaryButton/SecondaryButton";
import WildfireSubmitButton from "../../../../../../../../wildfire/components/Button/DialogFooterButtons/SubmitButton/SubmitButton";
import { closeCreateHomeEvaluationDialog } from "./CreateHomeEvaluationDialog";
import { homeStandardEvaluationAPIClient } from "../../../../../../standard/lib/apiClients/homeStandardEvaluationAPIClient";
import { homeSCSHEvaluationAPIClient } from "../../../../../../scsh/lib/apiClients/homeSCSHEvaluationAPIClient";

export interface CreateHomeEvaluationFormModel {
  selectedEvaluationType: EnumRadioFieldValue;
}

const createFcFMFFormBuilder: FieldSchemaFormBuilderV2<
  CreateHomeEvaluationFormModel
> = (onValueChange, form, _) => {
  return [
    {
      type: "EnumRadio",
      fieldName: "updateHomeowner",
      fieldKey: "updateHomeowner",
      label: "Choose an Evaluation Type",
      value: form.selectedEvaluationType,
      optionValues: [
        {
          label: "New Evaluation",
          value: 1,
        },
        {
          label: "Redesignation",
          value: 2,
        },
        {
          label: "SCSH",
          value: 3,
        },
      ],
      onSubmit: function (value) {
        onValueChange(
          { ...form, selectedEvaluationType: value as number },
          value,
          this
        );
      },
    } as EnumRadioFieldProps,
  ];
};

export const CreateHomeEvaluationForm: FC = () => {
  const createFCFMFEvaluationFormRef =
    useRef<FieldFormV2Module<CreateHomeEvaluationFormModel>>(null);

  const [fcfmfvaluationFormModel, setFCFMFEvaluationFormModel] =
    useState<CreateHomeEvaluationFormModel>();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const createFcFMFEvaluation = async (form: CreateHomeEvaluationFormModel) => {
    switch (form.selectedEvaluationType) {
      case 1:
        setIsLoading(true);
        await homeStandardEvaluationAPIClient
          .createHomeStandardEvaluation()
          .then((homeStandardEvaluation) => {
            closeCreateHomeEvaluationDialog();
            navigate(
              `/fh/standard/${homeStandardEvaluation.id}/basic-information/homeowner-information`
            );
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });

        break;
      case 3:
        setIsLoading(true);
        await homeSCSHEvaluationAPIClient
          .createHomeSCSHEvaluation()
          .then((homeSCSHEvaluation) => {
            closeCreateHomeEvaluationDialog();
            navigate(
              `/fh/scsh/${homeSCSHEvaluation.id}/basic-information/homeowner-information`
            );
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });

        break;
      default:
        break;
    }
  };

  return (
    <div className="create-home-evalaution-dialog">
      <FieldFormV2<CreateHomeEvaluationFormModel>
        ref={createFCFMFEvaluationFormRef}
        value={fcfmfvaluationFormModel}
        fieldSchemaFormBuilder={createFcFMFFormBuilder}
        onFormSubmit={async (form) => {
          await createFcFMFEvaluation(form);
        }}
        onFieldChanged={(form) => {
          setFCFMFEvaluationFormModel(form);
        }}
      />
      <div>You will be starting a new FORTIFIED Application, continue?</div>
      <br />
      <Footer>
        <WildfireSecondaryButton
          //className="wildfire-admin-update-dialog-cancel-button"
          buttonText="Cancel"
          onClick={() => closeCreateHomeEvaluationDialog()}
          disabled={isLoading}
        />
        <WildfireSubmitButton
          //className="wildfire-admin-update-dialog-submit-button"
          buttonText="Submit"
          onClick={() => createFCFMFEvaluationFormRef.current?.submit()}
          isLoading={isLoading}
        />
      </Footer>
    </div>
  );
};
