import { Button, Classes, Dialog, Intent, RadioGroup } from "@blueprintjs/core";
import React from "react";
import { HomeEvaluationOptions } from "../../../../types/schema";
import { BaseDialogProps } from "../../types";
import { EvaluationCreationOptions } from "../../../../types/evaluation/EvaluationTypes";
import { evaluationApiClient } from "../../../../lib/apiClients/evaluation/evaluationApiClient";
import { useCreateHomeEvaluationDialog } from "./useCreateHomeEvaluationDialog";
import HomeEvaluationFieldInfoContainer from "./HomeEvaluationFieldInfoContainer";
import { AsyncAutoCompleteField } from "../../../../../ucl/components/Fields/Dropdowns/AsyncAutoComplete/AsyncAutoCompleteField";
import variables from "../../../../../common/config/variables";
import { redesignationFooterHelperText } from "../CreateEvaluationHelpers";
import EvaluationHistory from "../../../Evaluation/History/EvaluationHistory";
import { MinimalSelectableLabel } from "../../../Fields/SelectableLabel/MinimalSelectableLabel";
import { useProductTypeHandler } from "../../../../providers/ProductTypeHandlerProvider";

export interface CreateHomeEvaluationDialogProps extends BaseDialogProps {}

const CreateHomeEvaluationDialog: React.FC<CreateHomeEvaluationDialogProps> = (
  props
) => {
  const { getAccessToken } = useProductTypeHandler();

  const {
    selectedRedesignation,
    setSelectedRedesignation,
    isSaving,
    selectedParentEvaluationOption,
    setParentEvaluationOption,
    isfetching,
    isNewRedesignationButtonDisabled,
    closeDialog,
    setCreationType,
    creationType,
    selectableOptions,
    submitNewHomeEvaluation,
    submitNewHomeRedesignation,
  } = useCreateHomeEvaluationDialog(props);

  const newEvalautionHelperText = (
    <div className={"section-text"}>
      You will be starting a new FORTIFIED Home Evaluation, continue?
    </div>
  );

  return (
    <Dialog
      className="create-evaluation-dialog"
      isOpen={props.isOpen}
      isCloseButtonShown={true}
      onClose={closeDialog}
      hasBackdrop={false}
      title={<div>Create FH Evaluation</div>}
    >
      <div className={Classes.DIALOG_BODY}>
        <div>
          <MinimalSelectableLabel label={"Please Select an Option"} />
          <RadioGroup
            inline={true}
            onChange={(e) => {
              const value = e.currentTarget.value as EvaluationCreationOptions;
              setCreationType(value);
            }}
            selectedValue={creationType}
            options={selectableOptions.map((option, idx) => ({
              ...option,
              key: idx,
            }))}
          />
          <div className={"new-evaluation-section"}>
            {creationType === EvaluationCreationOptions.New &&
              newEvalautionHelperText}
            {creationType === EvaluationCreationOptions.Child && (
              <>
                <div>
                  <div className="create-evaluation-search-help-text">
                    Search for Evaluation by FID or Address
                  </div>

                  <AsyncAutoCompleteField
                    label="Search by FID"
                    type="AsyncAutocomplete"
                    apiBaseUrl={variables.apiBaseUrl}
                    odataUrl={`${variables.apiBaseUrl}odata/evaluationodata/options`}
                    getAuthRequestHeader={getAccessToken}
                    value={selectedParentEvaluationOption?.value}
                    autoCompleteSettings={{
                      minLength: 10,
                      suggestionCount: 1,
                    }}
                    disabled={isfetching || isSaving}
                    onSubmit={async (e) => {
                      const selectedOption = e as HomeEvaluationOptions;
                      if (selectedOption) {
                        setParentEvaluationOption(selectedOption);
                        const mostCurrentRedesignation =
                          await evaluationApiClient.getMostRecentEvaluationChild(
                            selectedOption.id
                          );
                        setSelectedRedesignation(mostCurrentRedesignation);
                      } else {
                        setParentEvaluationOption(undefined);
                      }
                    }}
                  />
                  <AsyncAutoCompleteField
                    label="Search by Address"
                    type="AsyncAutocomplete"
                    apiBaseUrl={variables.apiBaseUrl}
                    odataUrl={`${variables.apiBaseUrl}odata/homeevaluationaddressodata/options`}
                    getAuthRequestHeader={getAccessToken}
                    value={selectedParentEvaluationOption?.value}
                    autoCompleteSettings={{
                      minLength: 8,
                    }}
                    disabled={isfetching || isSaving}
                    onSubmit={async (e) => {
                      const selectedOption = e as HomeEvaluationOptions;

                      if (selectedOption) {
                        setParentEvaluationOption(selectedOption);
                        const mostCurrentRedesignation =
                          await evaluationApiClient.getMostRecentEvaluationChild(
                            selectedOption.id
                          );
                        setSelectedRedesignation(mostCurrentRedesignation);
                      } else {
                        setParentEvaluationOption(undefined);
                      }
                    }}
                  />
                </div>
                {selectedParentEvaluationOption && (
                  <>
                    <HomeEvaluationFieldInfoContainer
                      selectedOption={selectedParentEvaluationOption}
                      selectedChildOption={selectedRedesignation}
                    />
                    <EvaluationHistory
                      evaluationId={selectedParentEvaluationOption.id}
                      readonly={true}
                    />
                  </>
                )}
                {redesignationFooterHelperText}
              </>
            )}
          </div>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text={"Cancel"}
          onClick={() => {
            closeDialog();
          }}
        />
        {creationType === EvaluationCreationOptions.New && (
          <Button
            intent={Intent.SUCCESS}
            text={"New Evaluation"}
            type="submit"
            onClick={async () => {
              await submitNewHomeEvaluation();
            }}
            loading={isSaving}
          />
        )}
        {creationType === EvaluationCreationOptions.Child && (
          <Button
            intent={Intent.SUCCESS}
            minimal={isNewRedesignationButtonDisabled}
            disabled={isNewRedesignationButtonDisabled}
            loading={isSaving || isfetching}
            text={"New Redesignation"}
            type="submit"
            onClick={async () => {
              await submitNewHomeRedesignation();
            }}
          />
        )}
      </div>
    </Dialog>
  );
};

export default CreateHomeEvaluationDialog;
