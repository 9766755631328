import { Classes, Dialog, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useEffect, useState } from "react";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { DialogFooter } from "./DialogFooter";
import { BaseDialogProps, PostDesignationFilesDialogOptions } from "./types";
import { boxTokenStore } from "../../stores/BoxTokenStore";
import { IntlProvider } from "react-intl";
import { BoxContentExplorer } from "../../../common/Components/Fields/BoxContentExplorer";
import { permissionStore } from "../../stores/PermissionStore";

export interface PostDesignationFilesDialogProps extends BaseDialogProps {}

export const PostDesignationFilesDialog: React.FC<
  PostDesignationFilesDialogProps
> = ({ isOpen, closeDialog, submitDialog }) => {
  const [ready, setReady] = useState<boolean>(false);
  const [dialogOptions, setDialogOptions] =
    useState<PostDesignationFilesDialogOptions>();

  useEffect(() => {
    setDialogOptions(dialogsViewerStore.postDesignationFilesDialogOptions);

    setReady(true);
  }, [dialogsViewerStore.isPostDesignationFilesDialogOpen]);

  return (
    <>
      {ready && (
        <Dialog
          className="post-redesignation-file-uploader-dialog"
          isOpen={isOpen}
        >
          <div className={Classes.DIALOG_HEADER}>
            <Icon
              icon={IconNames.Document}
              intent={Intent.DANGER}
              title={"Post Designation Files"}
            />
            Post Designation Files
          </div>
          <div className={`${Classes.DIALOG_BODY}`}>
            <IntlProvider locale="en">
              {boxTokenStore.isTokenValid &&
                !!dialogOptions?.postDesignationBoxFolderId && (
                  <BoxContentExplorer
                    token={boxTokenStore.getBoxAccessToken as any}
                    rootFolderId={dialogOptions?.rootBoxFolderId}
                    language="en-US"
                    canCreateNewFolder={false}
                    canShare={false}
                    canDelete={permissionStore.canDeletePostDesignationFiles}
                    canUpload={true}
                    canRename={false}
                    currentFolderId={dialogOptions?.postDesignationBoxFolderId}
                  />
                )}
            </IntlProvider>
          </div>
          <DialogFooter
            closeButtonText="Close"
            isSubmitButtonHidden={true}
            closeDialog={closeDialog}
            submitDialog={submitDialog}
          />
        </Dialog>
      )}
    </>
  );
};
