import { Icon, IconName } from "@blueprintjs/core";
import classnames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

export interface PageSidebarConfigItem {
  route: string;
  icon: IconName;
  label: string;
}
interface PageSidebarItemProps {
  item: PageSidebarConfigItem;
  isSelected: boolean;
}
export const PageSidebarItem: React.FC<PageSidebarItemProps> = ({
  item,
  isSelected,
}) => {
  return (
    <Link to={item.route}>
      <div
        className={classnames(
          {
            "page-sidebar-item__selected": isSelected,
          },
          "page-sidebar-item"
        )}
      >
        <Icon icon={item.icon} iconSize={20} />
        <div className="page-sidebar-item-label">{item.label}</div>
      </div>
    </Link>
  );
};
