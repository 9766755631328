import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC, useEffect, useState } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import wildfireBaseApiClient from "../../../../wildfire/lib/wildfireBaseApiClient";
import { FCFMFSchemaFactoryV2 } from "../../common/customHooks/useFCFMFFieldSchemaFactory";
import { Schema } from "@ucl/library";
import CommercialRedesignationIterationEngine from "../components/IterationEngine/CommercialRedesignationIterationEngine";

export interface CommercialRedesignationIterationEnginePageProps {
  evaluationId?: string;
}

const CommercialRedesignationIterationEnginePageComponent: FC<
  CommercialRedesignationIterationEnginePageProps
> = (props) => {
  const [commercialSchemaFactory, setCommercialSchemaFactory] =
    useState<FCFMFSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireBaseApiClient.get<Schema>(`/wildfire/schema`);
    const newSchemaFactory = new FCFMFSchemaFactoryV2(schema);
    setCommercialSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!commercialSchemaFactory) {
      getSchema();
    }
  }, []);

  return (
    <>
      {props.evaluationId && commercialSchemaFactory && (
        <UnSavedChangesBlockerProvider>
          <CommercialRedesignationIterationEngine
            evaluationId={props.evaluationId}
            schemaFactory={commercialSchemaFactory}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const CommercialRedesignationIterationEnginePage = withAITracking(
  reactPlugin,
  CommercialRedesignationIterationEnginePageComponent,
  "CommercialRedesignationIterationEnginePage",
  "tracking-container"
);
