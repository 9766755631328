import {
  EnumRadioFieldProps,
  FieldFormV2,
  FieldFormV2Module,
  FieldSchemaFormBuilderV2,
  Footer,
  ReadOnlyFieldProps,
} from "@ucl/library";
import { FC, useRef, useState } from "react";
import "./styles.scss";
import { PaymentCreditResponseReadDTO } from "../../types/PaymentCredit/PaymentCreditResponseReadDTO";
import variables from "../../../../common/config/variables";
import WildfireSecondaryButton from "../../../../wildfire/components/Button/DialogFooterButtons/SecondaryButton/SecondaryButton";
import WildfireSubmitButton from "../../../../wildfire/components/Button/DialogFooterButtons/SubmitButton/SubmitButton";
import { closePaymentCreditDialogV2 } from "./PaymentCreditDialogV2";
import {
  EvaluationStep,
  ProductTypes,
} from "../../../../foritfied/types/evaluation/Evaluation";
import { commercialEvaluationAPIClient } from "../../../FCFMF/commercial/lib/apiClients/commercialEvaluationAPIClient";
import { multifamilyEvaluationAPIClient } from "../../../FCFMF/multifamily/lib/apiClients/multifamilyEvaluationAPIClient";
import { useNavigate } from "react-router";

interface PaymentCreditFormModel {
  creditType: number;
}

export interface PaymentCreditFormProps {
  paymentCredit: PaymentCreditResponseReadDTO;
  fortifiedId: string;
  productType: ProductTypes;
  step: EvaluationStep;
  setEvaluation: (evaluation: any) => void;
  evaluationId: string;
}

const optionValues = [
  {
    label: "IBHS Credit",
    value: 1,
  },
  {
    label: "Credit or Debit Card",
    value: 2,
  },
];

const paymentCreditFormBuilder: FieldSchemaFormBuilderV2<
  PaymentCreditFormModel
> = (onValueChange, form, _) => {
  return [
    {
      type: "EnumRadio",
      fieldName: "paymentCreditType",
      fieldKey: "paymentCreditType",
      label: "How would you like to pay?",
      value: form.creditType,
      optionValues: optionValues,
      onSubmit: function (value) {
        onValueChange({ ...form, creditType: value as number }, value, this);
      },
    } as EnumRadioFieldProps,
    {
      type: "ReadOnly",
      fieldName: "paymentCreditTypeNote",
      fieldKey: "paymentCreditTypeNote",
      value:
        "* After payment is submitted, this Evaluation will be automatically sent to IBHS for review.",
    } as ReadOnlyFieldProps,
  ];
};

export const PaymentCreditForm: FC<PaymentCreditFormProps> = (props) => {
  const paymentCreditFormRef =
    useRef<FieldFormV2Module<PaymentCreditFormModel>>(null);

  const [paymentCreditFormModel, setPaymentCreditFormModel] =
    useState<PaymentCreditFormModel>();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const submitFHPaymentCredit = async () => {
    setIsLoading(true);
    await commercialEvaluationAPIClient
      .applyApplicationFeePaymentCredit(props.evaluationId)
      .then((evalaution) => {
        props.setEvaluation(evalaution);
        closePaymentCreditDialogV2();
        navigate(`/fc-fmf`);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const submitFCPaymentCredit = async () => {
    switch (props.step) {
      case EvaluationStep.ApplicationFee:
        setIsLoading(true);
        await commercialEvaluationAPIClient
          .applyApplicationFeePaymentCredit(props.evaluationId)
          .then((evalaution) => {
            props.setEvaluation(evalaution);
            closePaymentCreditDialogV2();
            navigate(`/fc-fmf`);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case EvaluationStep.CertificateFee:
        break;
    }
  };

  const submitFMFPaymentCredit = async () => {
    switch (props.step) {
      case EvaluationStep.ApplicationFee:
        setIsLoading(true);
        await multifamilyEvaluationAPIClient
          .applyApplicationFeePaymentCredit(props.evaluationId)
          .then((evalaution) => {
            props.setEvaluation(evalaution);
            closePaymentCreditDialogV2();
            navigate(`/fc-fmf`);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case EvaluationStep.CertificateFee:
        break;
    }
  };

  const submitPaymentCreditSelection = async (form: PaymentCreditFormModel) => {
    if (form.creditType === 1) {
      switch (props.productType) {
        case ProductTypes.FortifiedCommercial:
          await submitFCPaymentCredit();
          break;
        case ProductTypes.FortifiedMultifamily:
          await submitFMFPaymentCredit();
          break;
        case ProductTypes.FortifiedHome:
          await submitFHPaymentCredit();
          break;
      }
    } else {
      setIsLoading(true);
      window.location.href =
        props.paymentCredit.paymentLink +
        `?${new URLSearchParams({
          fortified_id: props.fortifiedId,
          ibhs_formstack_env: variables.formstackEnvironment,
        })}`;
    }
  };

  return (
    <div className="apply-payment-credit-dialog-v2">
      <FieldFormV2<PaymentCreditFormModel>
        ref={paymentCreditFormRef}
        value={paymentCreditFormModel}
        fieldSchemaFormBuilder={paymentCreditFormBuilder}
        onFormSubmit={async (form) => {
          await submitPaymentCreditSelection(form);
        }}
        onFieldChanged={(form) => {
          setPaymentCreditFormModel(form);
        }}
      />
      <Footer>
        <WildfireSecondaryButton
          buttonText="Cancel"
          onClick={() => closePaymentCreditDialogV2()}
          disabled={isLoading}
        />
        <WildfireSubmitButton
          buttonText="Submit"
          onClick={() => paymentCreditFormRef.current?.submit()}
          isLoading={isLoading}
          disabled={paymentCreditFormModel?.creditType === undefined}
        />
      </Footer>
    </div>
  );
};
