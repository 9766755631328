import { FC, useContext, useState } from "react";
import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ExistingComment } from "../../../../common/apiClients/comments/types";
import { parseLongDate } from "../../../../common/utils/date";
import { wildfireUserStore } from "../../../stores/WildfireUserStore";
import {
  PermissionsContext,
  PermissionsContextProps,
  TextAreaField,
} from "@ucl/library";
import "./styles.scss";

export interface ExistingGridCommentFormProps {
  comment: ExistingComment;
  markCommentAsRead: (commentId: string) => Promise<void>;
  markCommentAsUnread: (commentId: string) => Promise<void>;
  updateComment: (
    commentId: string,
    comment: ExistingComment,
    updatedMessage: string
  ) => Promise<void>;
  deleteComment: (commentId: string) => Promise<void>;
}

export const ExistingGridCommentForm: FC<ExistingGridCommentFormProps> = ({
  comment,
  markCommentAsRead,
  markCommentAsUnread,
  updateComment,
  deleteComment,
}: ExistingGridCommentFormProps) => {
  const [localComment, setLocalComment] = useState<string>(
    comment.message || ""
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  const canDeleteComments = hasPermission("CanDeleteAnyComments");

  return (
    <>
      <div className="comment-card-header">
        <div>
          <div>
            <div>
              {`${comment.createdBy_AsName} (${comment.creatorRole_AsString})`}
            </div>
          </div>
          <div className="comment-card-date">
            {parseLongDate(comment.createdAt)}
          </div>
        </div>
        <div className="comment-card-actions">
          {!comment.canModify && !comment.isRead && (
            <Button
              className="comment-card-view-button"
              minimal={true}
              icon={IconNames.EYE_ON}
              onClick={async () => await markCommentAsRead(comment.id)}
            >
              Mark As Read
            </Button>
          )}

          {comment.isRead &&
            comment.viewedBy !== wildfireUserStore.user?.id && (
              <div className="comment-card-viewer-name">
                {`Viewed By: ${comment.viewedBy_AsName}`}
              </div>
            )}
          {!!comment.isRead && comment.viewedBy === wildfireUserStore.user?.id && (
            <div className="comment-card-viewer-name">
              <Button
                className="comment-card-view-button"
                minimal={true}
                icon={IconNames.EYE_OFF}
                onClick={async () => await markCommentAsUnread(comment.id)}
              >
                Mark As Unread
              </Button>
            </div>
          )}

          {comment.canModify && (
            <>
              {isEditing && canDeleteComments && (
                <Button
                  icon={IconNames.FLOPPY_DISK}
                  minimal={true}
                  disabled={!canDeleteComments}
                  onClick={async () => {
                    await updateComment(comment.id, comment, localComment);
                    setIsEditing(false);
                  }}
                />
              )}
              {!isEditing && canDeleteComments && (
                <Button
                  icon={IconNames.EDIT}
                  minimal={true}
                  disabled={!canDeleteComments}
                  onClick={() => {
                    setIsEditing(true);
                    // setLocalComment(comment.message || "");
                  }}
                />
              )}
              {canDeleteComments && (
                <Button
                  icon={IconNames.TRASH}
                  minimal={true}
                  disabled={!canDeleteComments}
                  onClick={async () => await deleteComment(comment.id)}
                ></Button>
              )}
            </>
          )}
        </div>
      </div>
      <TextAreaField
        disabled={!isEditing}
        value={comment.message}
        onSubmit={(updatedComment) => setLocalComment(updatedComment || "")}
      />
    </>
  );
};
