import { Button, Icon, Tag } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { uniqBy } from "lodash";
import moment from "moment";
import { evaluationCommentStore } from "../../stores/EvaluationCommentStore";
import { UserRole, fortifiedUserStore } from "../../stores/FortifiedUserStore";
import { ChangeRequest } from "../../types/evaluation/ChangeRequest";
import { EvaluationField } from "../../types/evaluation/Evaluation";
import { EvaluationComment } from "../../types/evaluation/EvaluationComment";
import {
  changeRequestStatusMapToIcon,
  changeRequestStatusMapToIntent,
} from "../../types/viewModels/IterationEngineChangeRequest";
import { changeRequestHelpers } from "../../../common/utils/changeRequest";
import { parseDateTime } from "../../../common/utils/date";
import { ChangeRequestNavigator } from "./ChangeRequestNavigator";
import { permissionStore } from "../../stores/PermissionStore";

export interface CommentItemView {
  id: string;
  createdBy: string;
  createdBy_AsName: string;
  createdAt: string;
  deletedAt?: string | null;
  deletedBy?: string | null;
  comment: React.ReactNode;
  role?: UserRole;
  role_AsString?: string;
  isPublic: boolean;
}

export interface CommentListViewProps {
  comments: EvaluationComment[];
  field: EvaluationField;
  currentPosition: number;
  changeRequestCount: number;
  navigateToChangeRequest: (idx: number) => void;
  changeRequest?: ChangeRequest;
  isAuditor?: boolean;
}

const changeRequestCommentId = "CHANGE_REQUEST_COMMENT_ID";

export const CommentListView: React.FC<CommentListViewProps> = ({
  comments,
  changeRequest,
  field,
  changeRequestCount,
  navigateToChangeRequest,
  currentPosition,
  isAuditor,
}) => {
  const changeRequestStatusLabel =
    isAuditor && changeRequest?.status_AsString === "Addressed"
      ? "Unresolved"
      : changeRequest?.status_AsString;

  const helper = changeRequestHelpers[field.fieldType.toLowerCase()];
  let uiComments: CommentItemView[] = comments;
  if (!!changeRequest && !!changeRequest.to) {
    uiComments = uiComments.filter((comment) => comment.id !== "fake");
    uiComments.push({
      id: `fake`,
      createdBy: changeRequest.valueChangedBy || changeRequest.updatedBy,
      createdBy_AsName:
        changeRequest.valueChangedBy_AsName || changeRequest.updatedBy_AsName,
      createdAt: changeRequest.valueChangedAt || changeRequest.updatedAt,
      comment: (
        <span className="change-request-comment">
          Value changed from{" "}
          <span className="change-request-comment_old">
            {helper?.getValue
              ? helper.getValue(changeRequest.from, field)
              : changeRequest.from}
          </span>{" "}
          to{" "}
          <span className="change-request-comment_new">
            {helper?.getValue && changeRequest.to
              ? helper.getValue(changeRequest.to, field)
              : changeRequest.to}
          </span>
        </span>
      ),
      isPublic: true,
    });
  }

  return (
    <div className="comment-list-view">
      <ChangeRequestNavigator
        changeRequestCount={changeRequestCount}
        currentPosition={currentPosition}
        navigateToChangeRequest={navigateToChangeRequest}
      />
      <div className="comment-list-view_header">
        <div className="comment-list-view_header_name">{field.label}</div>
        <div className="comment-list-view_header_indicator">
          {changeRequest?.status && changeRequestStatusLabel && (
            <Tag
              round={true}
              intent={changeRequestStatusMapToIntent[changeRequestStatusLabel]}
            >
              <Icon
                icon={changeRequestStatusMapToIcon[changeRequestStatusLabel]}
              />{" "}
              {changeRequestStatusLabel}
            </Tag>
          )}
        </div>
      </div>
      <div className="comment-list-view_item">
        {uniqBy(uiComments, (comment) => comment.id)
          .sort(
            (prev, next) =>
              moment(prev.createdAt).unix() - moment(next.createdAt).unix()
          )
          .map((uiComment) => {
            const canDeleteComment =
              uiComment.id !== changeRequestCommentId &&
              uiComment.createdBy === fortifiedUserStore.user?.id;

            return (
              <div key={`${uiComment.id}`}>
                <div className="comment-list-view_item_line-1">
                  <div>
                    <div className="comment-list-view_item_line-1_name">
                      {fortifiedUserStore.isFHInternalUser && (
                        <Icon
                          className="comment-list-view_item_line-1_name_icon"
                          icon={
                            uiComment.isPublic
                              ? IconNames.GLOBE
                              : IconNames.LOCK
                          }
                          size={12}
                        />
                      )}
                      {!fortifiedUserStore.isAnAuditor(uiComment.role) ||
                      permissionStore.canViewAuditorName
                        ? uiComment.createdBy_AsName
                        : ""}
                      {` (${uiComment.role_AsString})`}
                    </div>
                    <div className="comment-list-view_item_line-1_time">
                      {parseDateTime(uiComment.createdAt)}
                    </div>
                  </div>

                  <div className="comment-list-view_item_line-1_delete">
                    {canDeleteComment && uiComment.id !== "fake" && (
                      <Button
                        minimal={true}
                        icon={IconNames.TRASH}
                        onClick={() => {
                          evaluationCommentStore.deleteComment(uiComment.id);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="comment-list-view_item_line-2">
                  {uiComment.comment}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
