/* eslint-disable react/display-name */

import {
  FieldFormV2,
  FieldFormV2Module,
  FieldSchemaFormBuilderV2,
} from "@ucl/library";
import { NonIdealState } from "@blueprintjs/core";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { FormChecklistPart } from "../../../../../../common/types/Forms/FormChecklist";
import { FCFMFApplicationFormParts } from "../../../../common/types/Evaluation/BaseFCFMFApplicationForm";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../../types/MultifamilyEvaluationFormModel";
import useMultifamilyBuildingApplicationForm from "./useMultifamilyBuildingApplicationForm";
import { forwardRef } from "react";

export interface MultifamilyBuildingApplicationFormProps {
  evaluationId: string;
  isIterationEnginePage?: boolean;
  formPart?: FCFMFApplicationFormParts;
  onFormSave?: (fieldKey: string, value: any) => Promise<void>;
  setFormModel?: (formModel: Evaluation_MultifamilyEvaluationFormModel) => void;
  disableForm?: boolean;
  onInitialized?: () => void;
  setHasUnsavedChanges: (value: boolean) => void;
  hideFormControls?: boolean;
  formChecklistParts?: FormChecklistPart<FCFMFApplicationFormParts>[];
  updateChildFormError: (formId: string, hasError: boolean) => void;
  updateChildRequiredFieldCount?: (childId: string, count: number) => void;
}

export const MultifamilyBuildingApplicationForm = forwardRef<
  FieldFormV2Module<Evaluation_MultifamilyEvaluationFormModel>,
  MultifamilyBuildingApplicationFormProps
>((props, ref) => {
  const {
    formRef,
    isLoading,
    isSubmitting,
    applicationFormModel,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
  } = useMultifamilyBuildingApplicationForm(props);

  return (
    <div ref={containerRef} className="wildfire-form-container">
      {noBuilders || isLoading ? (
        <Loading />
      ) : !applicationFormModel ? (
        <NonIdealState />
      ) : (
        <>
          <FieldFormV2<Evaluation_MultifamilyEvaluationFormModel>
            isDisabled={isSubmitting || props.disableForm}
            value={applicationFormModel}
            fieldSchemaFormBuilder={
              getApplicationFormPartBuilder() as FieldSchemaFormBuilderV2<Evaluation_MultifamilyEvaluationFormModel>
            }
            onFormSubmit={async (form) => {
              await handleFormSubmit(form);
            }}
            ref={ref ? ref : formRef}
            onFieldChanged={async (form, value, fieldProps) => {
              await onFormFieldChange(form, value, fieldProps);
            }}
          />
        </>
      )}
    </div>
  );
});

export default MultifamilyBuildingApplicationForm;

(
  MultifamilyBuildingApplicationForm as React.ForwardRefExoticComponent<MultifamilyBuildingApplicationFormProps>
).displayName = "MultifamilyBuildingApplicationForm";
