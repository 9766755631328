import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  Intent,
  Radio,
  RadioGroup,
  TextArea,
} from "@blueprintjs/core";
import React from "react";
import { paymentCreditApiClient } from "../../lib/apiClients/paymentCredit/paymentCreditApiClient";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { fortifiedGridStore } from "../../stores/FortifiedGridStore";
import { paymentCreditStore } from "../../stores/PaymentCreditStore";
import { BaseDialogProps } from "./types";
import { Option } from "../../lib/apiClients/paymentCredit/types";
import { MinimalSelectableLabel } from "../Fields/SelectableLabel/MinimalSelectableLabel";
import { CurrencyField } from "../Fields/CurrencyField/CurrencyField";
import { permissionStore } from "../../stores/PermissionStore";
import { useAccount } from "@azure/msal-react";
import { SingleSelectField } from "../../../ucl/components/Fields/Dropdowns/SingleSelectField";

const defaultFormValue: PaymentCreditDialogFormValue = {
  creditType: 0,
  company: "",
  creditRemaining: 0,
  notes: "",
};

interface PaymentCreditDialogFormValue {
  creditType: number;
  company: string;
  creditRemaining: number;
  notes: string;
}

export interface PaymentCreditDialogProps extends BaseDialogProps {}

export const PaymentCreditDialog: React.FC<PaymentCreditDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [formValue, setFormValue] =
    React.useState<PaymentCreditDialogFormValue>(defaultFormValue);

  const account = useAccount();

  React.useEffect(() => {
    if (!!dialogsViewerStore.paymentCreditDialogOpenOptions?.isCreate)
      setFormValue(defaultFormValue);
  }, [closeDialog]);

  React.useEffect(() => {
    if (!!account) {
      paymentCreditStore.setPaymentCreditCompanies();
    }
  }, []);

  React.useEffect(() => {
    if (!!dialogsViewerStore.paymentCreditDialogOpenOptions?.paymentCreditId) {
      const paymentCreditId =
        dialogsViewerStore.paymentCreditDialogOpenOptions?.paymentCreditId;

      paymentCreditApiClient
        .getPaymentCreditById(paymentCreditId)
        .then((paymentCredit) => {
          if (!!paymentCredit) {
            const { creditType, company, creditRemaining, notes } =
              paymentCredit;

            setFormValue({
              creditType,
              company,
              creditRemaining,
              notes,
            });
          }
        });
    }
  }, [dialogsViewerStore.paymentCreditDialogOpenOptions?.paymentCreditId]);

  return (
    <Dialog isOpen={isOpen} hasBackdrop={false}>
      <div className={Classes.DIALOG_HEADER}>Payment Credit</div>
      <div className={Classes.DIALOG_BODY}>
        <form
          id="payment-credit-form"
          onSubmit={async (e) => {
            e.preventDefault();

            setIsSaving(true);

            if (!dialogsViewerStore.paymentCreditDialogOpenOptions?.isCreate) {
              if (
                !!dialogsViewerStore.paymentCreditDialogOpenOptions
                  ?.paymentCreditId
              ) {
                await paymentCreditApiClient
                  .updatePaymentCreditById(
                    dialogsViewerStore.paymentCreditDialogOpenOptions
                      .paymentCreditId,
                    formValue
                  )
                  .then(() => {
                    fortifiedGridStore.refresh();
                    submitDialog();
                  })
                  .finally(() => {
                    setIsSaving(false);
                  });
              }
            } else {
              await paymentCreditApiClient
                .createPaymentCredit(formValue)
                .then(() => {
                  fortifiedGridStore.refresh();
                  submitDialog();
                })
                .finally(() => {
                  setIsSaving(false);
                });
            }
          }}
        >
          {permissionStore.canSelectPaymentCreditType && (
            <FormGroup className="base-field">
              <MinimalSelectableLabel label={"Credit Type"} />
              <RadioGroup
                onChange={(item) => {
                  setFormValue((prev) => ({
                    ...prev,
                    creditType: +item.currentTarget.value,
                  }));
                }}
                selectedValue={formValue.creditType}
              >
                <Radio label="FORTIFIED Home" value={1} />
                <Radio label="FORTIFIED Commercial and Multifamily" value={2} />
              </RadioGroup>
            </FormGroup>
          )}
          <FormGroup className="base-field">
            {dialogsViewerStore.paymentCreditDialogOpenOptions?.isCreate ? (
              <SingleSelectField
                type={"SingleSelect"}
                label="Company"
                value={formValue.company}
                optionValues={paymentCreditStore.paymentCreditCompanyOptions}
                onSubmit={(value) => {
                  const option = value as Option;

                  setFormValue((prev) => ({
                    ...prev,
                    company: option.value,
                  }));
                }}
                disabled={
                  !dialogsViewerStore.paymentCreditDialogOpenOptions?.isCreate
                }
              />
            ) : (
              <>
                <MinimalSelectableLabel label={"Company"} />
                <MinimalSelectableLabel label={formValue.company} />
              </>
            )}
          </FormGroup>
          <FormGroup className="base-field">
            <MinimalSelectableLabel label={"Available Credit"} />
            <div className="available-credit-field">
              <div>
                <CurrencyField
                  value={formValue.creditRemaining}
                  type="Currency"
                  onChange={(newValue) => {
                    setFormValue((prev) => ({
                      ...prev,
                      creditRemaining: newValue,
                    }));
                  }}
                />
              </div>
            </div>
          </FormGroup>
          <FormGroup className="base-field">
            <MinimalSelectableLabel label={"Notes"} />
            <div className="notes-field">
              <div>
                <TextArea
                  className="comment-textarea"
                  value={formValue.notes}
                  onChange={(e) =>
                    setFormValue((prev) => ({
                      ...prev,
                      notes: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </FormGroup>
        </form>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text={"Cancel"}
          onClick={closeDialog}
        />
        <Button
          intent={Intent.SUCCESS}
          text={"Save"}
          type="submit"
          form="payment-credit-form"
          loading={isSaving}
        />
      </div>
    </Dialog>
  );
};
