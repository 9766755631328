import { Icon, Tag } from "@blueprintjs/core";
import { EvaluationCommentV2 } from "../../../../wildfire/components/IterationEngine/types/EvaluationCommentV2";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { ChangeRequestV2 } from "../../../../wildfire/components/IterationEngine/types/ChangeRequestV2";
import useFortifiedChangeRequestNavigator from "../../customHooks/useFortifiedChangeRequestNavigator";
import ChangeRequestNavigator from "../../../../wildfire/components/IterationEngine/Comment/ChangeRequestNavigator/ChangeRequestNavigator";
import {
  changeRequestStatusMapToIcon,
  changeRequestStatusMapToIntent,
} from "../../../../foritfied/types/viewModels/IterationEngineChangeRequest";
import IterationEngineCommentInput from "../../../../wildfire/components/IterationEngine/Comment/IterationEngineCommentInput";
import IterationEngineCommentList from "../../../../wildfire/components/IterationEngine/Comment/IterationEngineCommentList";
import { fortifiedUserStore } from "../../../../foritfied/stores/FortifiedUserStore";

export interface FotifiedCommentContainerProps {
  comments: EvaluationCommentV2[];
  deleteComment?: (
    commentId: string,
    evaluationFieldKey: string
  ) => Promise<void>;
  createComment?: (
    comment: string,
    evaluationFieldKey: string,
    evaluationFieldLabel: string,
    isPublic: boolean
  ) => Promise<void>;
  selectedField?: BaseFieldProps<any>;
  changeRequests: ChangeRequestV2[];
  setSelectedKeyAndGetComments:
    | ((fieldKey?: string) => Promise<void>)
    | undefined;
  selectedKey: string | undefined;
  setSelectedIterationEngineFormTabId: (tabId: string) => void;
  selectedIterationEngineFormTabId: string;
  unresolvedChangeRequests: ChangeRequestV2[];
  isOpen: boolean;
  fieldsContainerClassName: string;
  allChangeRequests: ChangeRequestV2[];
  changeRequestCount: number;
  resolvedChangeRequestCount: number;
  resolvedChangeRequestsPercentage: () => number;
  unresolveChangeRequest: (changeRequestId: string) => Promise<void>;
  resolveChangeRequest: (changeRequestId: string) => Promise<void>;
  getChangeRequests: () => Promise<void>;
}

const FortifiedIterationEngineCommentPanel: React.FC<
  FotifiedCommentContainerProps
> = (props) => {
  const {
    moveToNextChangeRequest,
    hasAssociatedChangeRequests,
    hasNoNewChangeRequests,
    navigateToPreviousChangeRequest,
    currentChangeRequestIndex,
    navigateToNextChangeRequest,
    applicantChangeRequestStatusLabel,
    currentChangeRequest,
    fieldsOnPageCount,
    isCurrentKeyOnPage,
  } = useFortifiedChangeRequestNavigator(
    props,
    props.allChangeRequests,
    "evaluation-forms-container"
  );

  return (
    <>
      <div className="comment-pannel-v2">
        <div className="comment-container-v2">
          {props.selectedField && fieldsOnPageCount <= 0 && (
            <div className="comment-list-view_header_name">
              {isCurrentKeyOnPage ? props.selectedField?.label : ""}
            </div>
          )}
          {(props.selectedField || fieldsOnPageCount > 0) && (
            <>
              {fieldsOnPageCount > 0 && (
                <>
                  <ChangeRequestNavigator
                    selectedField={props.selectedField}
                    hasAssociatedChangeRequests={hasAssociatedChangeRequests}
                    hasNoNewChangeRequests={hasNoNewChangeRequests}
                    changeRequestCount={props.changeRequestCount}
                    resolvedChangeRequestCount={
                      props.resolvedChangeRequestCount
                    }
                    resolvedChangeRequestsPercentage={
                      props.resolvedChangeRequestsPercentage
                    }
                    currentChangeRequestIndex={currentChangeRequestIndex}
                    navigateToPreviousChangeRequest={
                      navigateToPreviousChangeRequest
                    }
                    navigateToNextChangeRequest={navigateToNextChangeRequest}
                    applicantChangeRequestStatusLabel={
                      applicantChangeRequestStatusLabel
                    }
                  />
                </>
              )}
              {(props.selectedField || currentChangeRequest) && (
                <div className="comment-list-view_header">
                  <div className="comment-list-view_header_name">
                    {props.selectedField?.label ||
                      currentChangeRequest?.evaluationFieldLabel}
                  </div>

                  <div className="comment-list-view_header_indicator">
                    {hasAssociatedChangeRequests &&
                      currentChangeRequest?.status &&
                      applicantChangeRequestStatusLabel && (
                        <Tag
                          round={true}
                          intent={
                            changeRequestStatusMapToIntent[
                              applicantChangeRequestStatusLabel
                            ]
                          }
                        >
                          <Icon
                            icon={
                              changeRequestStatusMapToIcon[
                                applicantChangeRequestStatusLabel
                              ]
                            }
                          />
                          {applicantChangeRequestStatusLabel}
                        </Tag>
                      )}
                  </div>
                </div>
              )}

              <IterationEngineCommentList
                currentKey={currentChangeRequest?.evaluationFieldKey}
                comments={props.comments}
                changeRequest={currentChangeRequest}
                deleteComment={props.deleteComment}
                hasAssociatedChangeRequests={hasAssociatedChangeRequests}
                canViewSystemGeneratedComments={fortifiedUserStore.IsFCFMFAdmin}
              />

              {(props.selectedField || currentChangeRequest) && (
                <IterationEngineCommentInput
                  currentKey={
                    props.selectedField?.fieldKey ||
                    currentChangeRequest?.evaluationFieldKey
                  }
                  changeRequest={currentChangeRequest}
                  hasAssociatedChangeRequests={hasAssociatedChangeRequests}
                  canResolveChangeRequests={fortifiedUserStore.IsFCFMFAdmin}
                  onSubmitComment={async (
                    comment,
                    evaluationFieldKey,
                    evaluationFieldLabel,
                    isPublic
                  ) => {
                    await props.createComment?.(
                      comment,
                      evaluationFieldKey,
                      evaluationFieldLabel,
                      isPublic
                    );

                    if (!currentChangeRequest) {
                      await props.getChangeRequests();
                    }
                  }}
                  resolveChangeRequest={props.resolveChangeRequest}
                  onUnresolveChangeRequest={props.unresolveChangeRequest}
                  selectedField={props.selectedField}
                  moveToNextChangeRequest={moveToNextChangeRequest}
                  getChangeRequests={props.getChangeRequests}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default FortifiedIterationEngineCommentPanel;
