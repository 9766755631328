import { FieldFormV2, FieldSchemaFormBuilderV2 } from "@ucl/library";
import { NonIdealState } from "@blueprintjs/core";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { Evaluation_CommercialEvaluationFormModel } from "../../../../../types/CommercialEvaluationFormModel";
import useSimpleCommercialApplicationForm from "./useSimpleCommercialApplicationForm";

export interface SimpleCommercialApplicationFormProps {
  form: Evaluation_CommercialEvaluationFormModel;
}

export const SimpleCommercialApplicationForm = (
  props: SimpleCommercialApplicationFormProps
) => {
  const {
    formRef,
    applicationFormModel,
    getApplicationFormPartBuilder,
    noBuilders,
    containerRef,
  } = useSimpleCommercialApplicationForm(props);

  return (
    <div ref={containerRef} className="wildfire-form-container">
      {noBuilders ? (
        <Loading />
      ) : !applicationFormModel ? (
        <NonIdealState />
      ) : (
        <>
          <FieldFormV2<Evaluation_CommercialEvaluationFormModel>
            isDisabled={true}
            value={applicationFormModel}
            fieldSchemaFormBuilder={
              getApplicationFormPartBuilder() as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>
            }
            ref={formRef}
          />
        </>
      )}
    </div>
  );
};

export default SimpleCommercialApplicationForm;
