import {
  errorStore,
  genericErrorMessage,
} from "../../common/Components/Error/ErrorStore";
import { WildfireEvaluationSteps } from "../../foritfied/types/evaluation/Evaluation";
import { wildfireEvaluationApiClient } from "../lib/apiClients/application/wildfireApplicationApiClient";
import { wildfireRoutePrefix } from "../pages/index/wildfireRouteConfig";
import { WildfireEvaluation } from "../types/WildfireApplication/WildfireApplication";

const getWildfireEvaluation = async (
  evaluationId: string
): Promise<WildfireEvaluation | undefined> => {
  try {
    const wildfireEvaluation =
      await wildfireEvaluationApiClient.getWildfireEvaluationById(evaluationId);
    return wildfireEvaluation;
  } catch (error) {
    errorStore.setErrorMessage(genericErrorMessage);
    return undefined;
  }
};

export const getWildfireEvaluationURL = async (
  evaluationId: string,
  providedWildfireEvaluation?: WildfireEvaluation
): Promise<string> => {
  const boardRoute = `${wildfireRoutePrefix}/board`;

  let wildfireEvaluation = providedWildfireEvaluation;

  if (!wildfireEvaluation) {
    wildfireEvaluation = await getWildfireEvaluation(evaluationId);
    if (!wildfireEvaluation) {
      return boardRoute;
    }
  }

  const { step } = wildfireEvaluation;

  switch (step) {
    case WildfireEvaluationSteps.WildfireApplication:
    case WildfireEvaluationSteps.WildfireApplicationPayment:
    case WildfireEvaluationSteps.WildfireApplicationEligibilityPhotos:
      return `${wildfireRoutePrefix}/${wildfireEvaluation.id}/application-form/${wildfireEvaluation.wildfireApplication.applicationForm.currentApplicationFormPart}`;

    case WildfireEvaluationSteps.WildfireApplicationEvaluatorAssignment:
    case WildfireEvaluationSteps.WildfireApplicationIBHSEligibilityReview:
    case WildfireEvaluationSteps.WildfireApplicationIBHSReview:
      return `${wildfireRoutePrefix}/iteration-engine/${wildfireEvaluation.id}`;

    case WildfireEvaluationSteps.WildfireApplicationFieldEvaluations:
      return `${wildfireRoutePrefix}/${wildfireEvaluation.id}/${wildfireEvaluation.wildfireApplication.currentFieldEvaluationFormPartURL}`;

    default:
      return boardRoute;
  }
};
