import {
  FieldSchemaFormBuilderV2,
  NumericFieldProps,
  SchemaFactoryV2,
  SmartAddressFieldProps,
} from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { YesOrNoSelection } from "../../../../../../wildfire/types/FormFields";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { addressApiClient } from "../../../../../../foritfied/lib/apiClients/address/addressApiClient";
import { Evaluation_CommercialEvaluationFormModel } from "../../../../commercial/types/CommercialEvaluationFormModel";

const modelName = "Evaluation";
const subModelName = "CommercialEvaluation";
const formName = "CommercialEvaluationApplicationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

//Part 5 Existing Construction - Reroofing
const existingConstructionReroofingFields = (
  form: Evaluation_CommercialEvaluationFormModel
) => [
  {
    fieldKey: buildFieldKey("HeightFts"),
    hidden:
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .isThisANewConstruction === YesOrNoSelection.Yes &&
      form.commercialEvaluation.commercialEvaluationApplicationForm
        .propertyHasMoreThanOneBuilding === YesOrNoSelection.Yes,
    format: "0",
  } as NumericFieldProps,
];

const existingConstructionReroofingRequiredFields = [
  buildFieldKey("HeightFts"),
];

export const commercialBuildingExistingConstructionReroofingBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        existingConstructionReroofingFields(form),
        onValueChange,
        form,
        errors,
        existingConstructionReroofingRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};

//Part 6 Project Construction Details
const projectConstructionDetailsFields = (
  _form: Evaluation_CommercialEvaluationFormModel
) => [
  buildFieldKey("ProjectOrBuildingName"),
  {
    fieldKey: buildFieldKey("Address"),
    getAddressesByQuery: addressApiClient.getAddressesByQuery,
    getAddressByQuery: addressApiClient.getAddressByQuery,
    isSmartOnly: false,
  } as SmartAddressFieldProps,
  //Commercial Specific
  buildFieldKey("OccupancyType"),
  {
    fieldKey: buildFieldKey("GrossRoofAreaSqft"),
    format: "0",
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("TotalFloorAreaSqft"),
    format: "0",
  } as NumericFieldProps,
];

const projectConstructionDetailsRequiredFields = [
  buildFieldKey("ProjectOrBuildingName"),
  buildFieldKey("Address"),
  buildFieldKey("OccupancyType"),
  buildFieldKey("GrossRoofAreaSqft"),
  buildFieldKey("TotalFloorAreaSqft"),
];

export const commercialBuildingProjectConstructionDetailsFieldsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        projectConstructionDetailsFields(form),
        onValueChange,
        form,
        errors,
        projectConstructionDetailsRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};

// Full form builder with all parts included
export const commercialBuildingApplicationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        [
          ...existingConstructionReroofingFields(form),
          ...projectConstructionDetailsFields(form),
        ],
        onValueChange,
        form,
        errors,
        [
          ...existingConstructionReroofingRequiredFields,
          ...projectConstructionDetailsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const commercialBuildingIterationEngineApplicationFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (
    fields: BaseFieldProps<any>[],
    shouldEnableForm?: boolean
  ) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        [
          ...existingConstructionReroofingFields(form),
          ...projectConstructionDetailsFields(form),
        ],
        onValueChange,
        form,
        errors,
        [
          ...existingConstructionReroofingRequiredFields,
          ...projectConstructionDetailsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      const shouldEnableForm =
        !form.commercialEvaluation
          ?.isComplexCommercialEvaluationBuildingFormAbleToBeModified;

      fields = beforeBuild(fields, shouldEnableForm);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};
