import { FormGroup, InputGroup, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import React from "react";
import { FormstackAddress } from "../../../types/formstack/fieldValueTypes/FormstackAddress";
import { FormstackCheckbox } from "../../../types/formstack/fieldValueTypes/FormstackCheckbox";
import { FormstackName } from "../../../types/formstack/fieldValueTypes/FormstackName";
import { FormstackRadio } from "../../../types/formstack/fieldValueTypes/FormstackRadio";
import { FormstackSelect } from "../../../types/formstack/fieldValueTypes/FormstackSelect";
import { FormstackSmartListOption } from "../../../types/formstack/fieldValueTypes/FormstackSmartListOption";
import { SelectField as InterationEngineStackField } from "../../../types/viewModels/IterationEngineViewModel";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { BaseFieldProps } from "../types";
import "./styles.scss";

export interface SelectFieldProps
  extends BaseFieldProps<FormstackSelect>,
    Omit<InterationEngineStackField, "id" | "sortOrder" | "isHidden"> {}

const getSelectValue = (
  values: FormstackSelect,
  options: FormstackSmartListOption[],
  isEmpty: (
    val:
      | FormstackAddress
      | FormstackCheckbox
      | FormstackSelect
      | FormstackRadio
      | FormstackName
      | number
  ) => boolean
) => {
  if (isEmpty(values)) {
    return [];
  }

  return (values as []).map((value) =>
    options.find((option) => option.value === value)
  );
};

export const SelectField: React.FC<SelectFieldProps> = ({
  fieldId,
  label,
  optionValues,
  value,
  disabled,
  onSubmit,
  isEmpty,
  isSelectedField,
  hasActiveChangeRequest,
  isSelectedChangeRequest,
  isResovledChangeRequest,
  hasActiveInternalComment,
  isExternalView,
}) => {
  const [localValue, setLocalValue] = React.useState<FormstackSelect>(
    value || []
  );
  const selectedOption = getSelectValue(localValue, optionValues, isEmpty);

  if (isEmpty(localValue)) {
    return null;
  }

  return (
    <FormGroup className="select-field">
      <SelectableLabel
        name={label}
        fieldId={fieldId}
        value={localValue}
        isSelectedField={isSelectedField}
        hasActiveChangeRequest={hasActiveChangeRequest}
        isSelectedChangeRequest={isSelectedChangeRequest}
        isResovledChangeRequest={isResovledChangeRequest}
        hasActiveInternalComment={hasActiveInternalComment}
        shouldDisableFieldLabelClick={isExternalView}
      />
      <Select<FormstackSmartListOption>
        items={optionValues}
        itemRenderer={(item, itemProps) => {
          return (
            <MenuItem
              className="select__menu-item"
              key={itemProps.index}
              text={item.label}
              active={localValue[0] === item.value}
              onClick={itemProps.handleClick}
            />
          );
        }}
        onItemSelect={(item) => {
          setLocalValue([item.value]);
          onSubmit(JSON.stringify([item.value]));
        }}
        disabled={disabled}
      >
        <InputGroup
          disabled={disabled}
          value={selectedOption ? selectedOption[0]?.label : undefined}
        />
      </Select>
    </FormGroup>
  );
};
