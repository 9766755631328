import { AddressField } from "./AddressField/AddressField";
import { CheckboxField } from "./CheckboxField/CheckboxField";
import { DatetimeField } from "./DatetimeField/DatetimeField";
import { EmailField } from "./EmailField/EmailField";
import { FileField } from "./FileField/FileField";
import { NameField } from "./NameField/NameField";
import { NumberField } from "./NumberField/NumberField";
import { PhoneField } from "./PhoneField/PhoneField";
import { RadioField } from "./RadioField/RadioField";
import { SelectField } from "./SelectField/SelectField";
import { TextAreaField } from "./TextAreaField/TextAreaField";
import { TextField } from "./TextField/TextField";
import { AllFields } from "./types";

export const buildField = (fieldProps: AllFields, idx: number) => {
  switch (fieldProps.fieldType) {
    case "Text":
      return <TextField key={idx} {...fieldProps} />;
    case "Textarea":
      return <TextAreaField key={idx} {...fieldProps} />;
    case "Email":
      return <EmailField key={idx} {...fieldProps} />;
    case "Number":
      return <NumberField key={idx} {...fieldProps} />;
    case "Name":
      return <NameField key={idx} {...fieldProps} />;
    case "Radio":
      return <RadioField key={idx} {...fieldProps} />;
    case "Checkbox":
      return <CheckboxField key={idx} {...fieldProps} />;
    case "Select":
      return <SelectField key={idx} {...fieldProps} />;
    case "Address":
      return <AddressField key={idx} {...fieldProps} />;
    case "Embed":
      return <FileField key={idx} {...fieldProps} />;
    case "Phone":
      return <PhoneField key={idx} {...fieldProps} />;
    case "Datetime":
      return <DatetimeField key={idx} {...fieldProps} />;
    default:
      console.warn("Something went wrong");
      return null;
  }
};
