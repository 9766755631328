import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../../../AppInsights";
import {
  PermissionsContext,
  PermissionsContextProps,
  PrimaryGrid,
} from "@ucl/library";
import variables from "../../../../common/config/variables";
import { wildfireUserStore } from "../../../stores/WildfireUserStore";
import wildfireBaseAPIClient, {
  getAuthRequestHeader,
} from "../../../lib/wildfireBaseApiClient";
import { WildfireEvaluationMetricReadDTO } from "./dtos/WildfireEvaluationMetricReadDTO";
import "../styles.scss";
import { WildfireEvaluationGridReadDTO } from "./dtos/WildfireEvaluationGridReadDTO";
import { useNavigate } from "react-router";
import { MenuEventArgs } from "@syncfusion/ej2-react-navigations";
import { Intent } from "@blueprintjs/core";
import { useContext } from "react";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { getWildfireEvaluationURL } from "../../../utils/getWildfireEvaluationURL";
import {
  getDefaultGridColumnsConfigurationByRole,
  getAllGridColumnsConfigurationByRole,
} from "./configurations/wildfireEvaluationRoleColumnsConfiguration";
import { useWildfireFeatureToggle } from "../../../customHooks/useWildfireFeatureToggle";

export interface WildfireEvaluationGridProps {}

const WildfireEvaluationGridComponent: React.FC<
  WildfireEvaluationGridProps
> = () => {
  const navigate = useNavigate();

  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  const { areNewGridMetricsEnabled } = useWildfireFeatureToggle();

  const openLink = (url: string, newTab?: boolean) =>
    newTab ? window.open(url, "_blank") : navigate(url);

  const openEvaluation = async (
    wildfireEvaluationId: string,
    newTab?: boolean
  ) => {
    const evaluationURL = await getWildfireEvaluationURL(wildfireEvaluationId);

    openLink(evaluationURL, newTab);
  };

  const onRecordClick = async ({
    rowData,
  }: {
    rowData: WildfireEvaluationGridReadDTO;
  }) => {
    await openEvaluation(rowData.id);
  };

  type ContextMenuClickArgs = MenuEventArgs & {
    rowInfo: {
      rowData: WildfireEvaluationGridReadDTO;
      cell: { innerText: string };
    };
  };

  const contextMenuItems = [
    {
      text: "Copy",
      target: ".e-rowcell",
      id: "copy-cell",
    },
    {
      text: "Open in new tab",
      target: ".e-row",
      id: "open-in-new-tab",
    },
  ];

  const contextMenuClick = async (args: ContextMenuClickArgs) => {
    switch (args.item.id) {
      case "copy-cell":
        handleCopyCell(args);
        break;
      case "open-in-new-tab":
        await handleOpenInNewTab(args);
        break;
    }
  };

  const handleCopyCell = (args: ContextMenuClickArgs) => {
    const cellText = args.rowInfo.cell.innerText;
    navigator.clipboard.writeText(cellText);
    AppToaster.show({
      message: `Copied "${cellText}" to clipboard`,
      intent: Intent.SUCCESS,
      timeout: 2000,
    });
  };

  const handleOpenInNewTab = async (args: ContextMenuClickArgs) => {
    const rowData = args.rowInfo.rowData;
    await openEvaluation(rowData.id, true);
  };

  return (
    <div className="wildfire-board">
      <PrimaryGrid<
        WildfireEvaluationGridReadDTO,
        WildfireEvaluationMetricReadDTO
      >
        gridKey={"wildfire-evaluation"}
        key={"evaluation-grid"}
        apiClient={wildfireBaseAPIClient}
        allowSearch={true}
        getAuthRequestHeader={getAuthRequestHeader}
        odataUrl={`${variables.apiBaseUrl}odata/evaluationodatagrid`}
        columnsConfiguration={getAllGridColumnsConfigurationByRole(
          wildfireUserStore.user?.role
        )}
        getColumnsConfigurationForUser={() =>
          getDefaultGridColumnsConfigurationByRole(wildfireUserStore.user?.role)
        }
        viewsSettings={{
          gridTitle: "Applications Dashboard",
          odataUrl: `${variables.apiBaseUrl}odata/evaluationodatagrid`,
          showGridViewPicker: hasPermission(
            "CanViewConfigureEvaluationsGridColumnsButton"
          ),
          shouldCreateAutoView: true,
          gridAutoName: "All",
          showUserDefaultView: true,
        }}
        metricsSettings={{
          url: `${variables.apiBaseUrl}api/wildfire-evaluation`,
          columns: 4,
          languageMapping: areNewGridMetricsEnabled
            ? {
                applicationCount: "Application Count",
                paidApplicationsThisWeek: "Paid Applications This Week",
                completedApplicationsThisWeek:
                  "Completed Applications This Week",

                auditQueueCount: "Audit Queue Count",
                auditQueueAgedUpToThreeDays: "Audit Queue Aged < 3 Days",
                auditQueueAgedFourToSevenDays: "Audit Queue Aged 4-7 Days",
                auditQueueAgedEightToFourteenDays: "Audit Queue Aged 8-14 Days",
                auditQueueAgedOverFourteenDays: "Audit Queue Aged > 14 Days",
                auditQueueOldestAge: "Audit Queue Oldest Submission",

                statusApplicationCount: "Application Status Count",
                statusApplicationPaymentCount:
                  "Application Payment Status Count",
                statusIBHSEligibilityReviewCount:
                  "Eligibility Review Status Count",
                statusChangesRequiredCount: "Changes Required Status Count",
                statusReadyForReReviewCount: "Ready for Re-Review Status Count",
                statusEvaluatorAssignmentCount:
                  "Evaluator Assignment Status Count",
                statusFieldEvaluationsCount: "Field Evaluations Status Count",
                statusIBHSReviewCount: "IBHS Review Status Count",
                statusEligibilityPhotosCount: "Eligibility Photos Status Count",
                statusDesignatedCount: "Designated Status Count",
                statusNotDesignatedCount: "Not Designated Status Count",
                statusClosedCount: "Closed Status Count",
              }
            : ({
                applicationCount: "Count of Applications",
                paidApplicationsThisWeek: "Paid Applications Last Week",
              } as WildfireEvaluationMetricReadDTO),
        }}
        gridConfiguration={{
          allowSelection: false,
          defaultSort: {
            field: "modifiedAt",
            direction: "Descending",
          },
          allowExcelExport: hasPermission(
            "CanViewExportToExcelEvaluationsGridButton"
          ),
          onRecordClick,
          contextMenuItems,
          contextMenuClick,
        }}
      />
    </div>
  );
};

export const WildfireEvaluationGrid = withAITracking(
  reactPlugin,
  WildfireEvaluationGridComponent,
  "WildfireEvaluationGrid",
  "tracking-container"
);
