import { Classes, Dialog } from "@blueprintjs/core";
import { DialogFooter } from "./DialogFooter";
import "./styles.scss";
import { BaseDialogProps } from "./types";
import { evaluationStore } from "../../stores/EvaluationStore";
import { useNavigate } from "react-router";
import { useProductTypeHandler } from "../../providers/ProductTypeHandlerProvider";

export interface ProceedToIBHSReviewDialogProps extends BaseDialogProps {}

export const ProceedToIBHSReviewDialog: React.FC<
  ProceedToIBHSReviewDialogProps
> = ({ isOpen, closeDialog }) => {
  const navigate = useNavigate();
  const { routePrefix } = useProductTypeHandler();
  return (
    <Dialog isOpen={isOpen} hasBackdrop={false}>
      <div className={Classes.DIALOG_HEADER}>Proceed To IBHS Review</div>
      <div className={Classes.DIALOG_BODY}>
        By submitting, you will sending this evaluation to IBHS for review. Are
        you sure you want to proceed?
      </div>
      <DialogFooter
        closeDialog={closeDialog}
        submitDialog={async () => {
          await evaluationStore.sendForward();
          closeDialog();
          navigate(`${routePrefix}`);
        }}
        closeButtonText="Cancel"
        submitButtonText="Submit"
      />
    </Dialog>
  );
};
