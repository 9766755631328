export enum EvaluationTypes {
  Home = "Home",
  Commercial = "Commercial",
  Multifamily = "Multifamily",
  HomeReDesignation = "HomeRe-Designation",
  CommercialReDesignation = "CommercialRe-Designation",
}

export enum EvaluationCreationOptions {
  New = "new",
  Child = "child",
}
