import variables from "../../../common/config/variables";
import { UserRoleType } from "../../../common/Components/User/userTypes";

export type FortifiedReportTypes =
  | "ibhs-evaluation-volume-report"
  | "ibhs-backlog-report"
  | "ibhs-annual-designations-report"
  | "nciua-ncjua-report"
  | "daily-queue-report"
  | "efficiency-report";

export interface ReportViewDetails {
  title: string;
  slug: FortifiedReportTypes;
  reportLink: string;
  viewerRoles: UserRoleType[];
}

const reports: Record<FortifiedReportTypes, ReportViewDetails> = {
  "ibhs-evaluation-volume-report": {
    title: "IBHS Evaluation Volume Report",
    slug: "ibhs-evaluation-volume-report",
    reportLink: variables.fortifiedEvaluationVolumeReportLink,
    viewerRoles: [
      "Relativity Admin",
      "IBHS Admin",
      "FH Auditor",
      "FC/FMF Auditor",
      "FC/FMF Admin",
    ],
  },
  "ibhs-backlog-report": {
    title: "IBHS Backlog Report",
    slug: "ibhs-backlog-report",
    reportLink: variables.fortifiedIbhsBacklogReportLink,
    viewerRoles: [
      "Relativity Admin",
      "IBHS Admin",
      "FH Auditor",
      "FC/FMF Auditor",
      "FC/FMF Admin",
    ],
  },
  "ibhs-annual-designations-report": {
    title: "IBHS Annual Designations Report",
    slug: "ibhs-annual-designations-report",
    reportLink: variables.ibhsAnnualDesignationsReportLink,
    viewerRoles: [
      "Relativity Admin",
      "IBHS Admin",
      "FH Auditor",
      "FC/FMF Auditor",
      "FC/FMF Admin",
    ],
  },
  "nciua-ncjua-report": {
    title: "NCIUA/NCJUA Report",
    slug: "nciua-ncjua-report",
    reportLink: variables.nciuaReportLink,
    viewerRoles: [
      "Relativity Admin",
      "IBHS Admin",
      "FH Auditor",
      "NCIUA Partner",
    ],
  },
  "daily-queue-report": {
    title: "Daily Queue Report",
    slug: "daily-queue-report",
    reportLink: variables.dailyQueueReportLink,
    viewerRoles: ["Relativity Admin", "IBHS Admin", "FH Auditor"],
  },
  "efficiency-report": {
    title: "Efficiency Report",
    slug: "efficiency-report",
    reportLink: variables.efficiencyReportLink,
    viewerRoles: ["Relativity Admin", "IBHS Admin"],
  },
};

export default reports;
