import { FormstackAddress } from "../types/formstack/fieldValueTypes/FormstackAddress";
import { FormstackCheckbox } from "../types/formstack/fieldValueTypes/FormstackCheckbox";
import { FormstackName } from "../types/formstack/fieldValueTypes/FormstackName";
import { FormstackRadio } from "../types/formstack/fieldValueTypes/FormstackRadio";
import { FormstackSelect } from "../types/formstack/fieldValueTypes/FormstackSelect";

export const fieldValidators: {
  [key: string]: (
    value:
      | FormstackAddress
      | FormstackCheckbox
      | FormstackSelect
      | FormstackRadio
      | FormstackName
      | number
  ) => boolean;
} = {
  Text: (value) => value === "ibhs-null-value",
  Textarea: (value) => value === "ibhs-null-value",
  Email: (value) => value === "ibhs-null-value",
  Phone: (value) => value === "ibhs-null-value",
  Number: (value) => value === "ibhs-null-value",
  Datetime: (value) => value === "ibhs-null-value",
  Name: (value) =>
    !(value as FormstackName).first && !(value as FormstackName).last,
  Radio: (value) => value === "ibhs-null-value",
  Checkbox: (value) => value === "ibhs-null-value",
  Select: (value) => value === "ibhs-null-value",
  Address: (value) =>
    !(value as FormstackAddress).address &&
    !(value as FormstackAddress).city &&
    !(value as FormstackAddress).state &&
    !(value as FormstackAddress).zip,
};
