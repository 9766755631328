import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { FCFMFApplicationFormParts } from "../../../common/types/Evaluation/BaseFCFMFApplicationForm";
import { CommercialApplicationFormModel } from "../../types/CommercialApplicationFormModel";
import { Evaluation_CommercialEvaluationFormModel } from "../../types/CommercialEvaluationFormModel";

class CommercialEvaluationApplicationFormAPIClient {
  updateCommercialApplicationForm = async (
    evaluationId: string,
    commercialApplicationForm: CommercialApplicationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_CommercialEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_CommercialEvaluationFormModel>(
      `/commercial/application-form/${evaluationId}`,
      {
        commercialApplicationForm,
        fieldKey,
      }
    );
  };

  submitCommercialApplicationForm = async (
    wildfireApplicationId: string,
    applicationForm: CommercialApplicationFormModel,
    applicationFormPart?: FCFMFApplicationFormParts
  ): Promise<Evaluation_CommercialEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const applicationFormPartValue = (
      applicationFormPart ??
      (Object.values(
        FCFMFApplicationFormParts
      ).pop() as FCFMFApplicationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_CommercialEvaluationFormModel>(
      `/commercial/application-form/${wildfireApplicationId}/${applicationFormPartValue}`,
      applicationForm
    );
  };
}

export const commercialEvaluationApplicationFormAPIClient =
  new CommercialEvaluationApplicationFormAPIClient();
