import { makeObservable, observable } from "mobx";
import { evaluationCommentApiClient } from "../lib/apiClients/evaluation/evaluationCommentApiClient";
import { EvaluationComment } from "../types/evaluation/EvaluationComment";
import { evaluationChangeRequestStore } from "./EvaluationChangeRequestStore";
import { evaluationStore } from "./EvaluationStore";

class EvaluationCommentStore {
  public comments: EvaluationComment[] = [];

  constructor() {
    makeObservable(this, {
      comments: observable,
    });
  }

  setComments = (comments: EvaluationComment[]) => {
    this.comments = comments;
  };

  createComment = async (comment: string, isPublic: boolean) => {
    if (!evaluationStore.evaluation || !evaluationStore.selectedField) {
      throw new Error("Missing Evaluation Field");
    }

    const createdCommentRes =
      await evaluationCommentApiClient.createEvaluationComment(
        evaluationStore.selectedEvaluationId as string,
        evaluationStore.selectedField.id,
        comment,
        isPublic
      );

    this.comments = [createdCommentRes.evaluationComment].concat(this.comments);

    evaluationChangeRequestStore.updateChangeRequest(
      createdCommentRes.changeRequest
    );
  };

  deleteComment = async (commentId: string) => {
    if (!evaluationStore.evaluation || !evaluationStore.selectedField) {
      throw new Error("Missing Evaluation Field");
    }

    await evaluationCommentApiClient.deleteEvaluationComment(
      evaluationStore.evaluation.id,
      commentId
    );

    this.comments = this.comments.filter((c) => c.id !== commentId);
  };
}

export const evaluationCommentStore = new EvaluationCommentStore();
