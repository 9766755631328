import { Ajax } from "@syncfusion/ej2-base";
import {
  Predicate,
  CrudOptions,
  DataOptions,
  DataResult,
  ODataV4Adaptor,
  Query,
  DataManager,
} from "@syncfusion/ej2-data";
import { FortifiedODataBeforeSendArgs } from "../../types/fortifiedGrid/FortifiedODataBeforeSendArgs";
import _ from "lodash";
import { GridColumn } from "@syncfusion/ej2-react-grids";
import moment from "moment";

export default class FortifiedODataV4Adaptor extends ODataV4Adaptor {
  private onBeforeSend?: (
    beforeSendArgs: FortifiedODataBeforeSendArgs
  ) => string;

  constructor(
    private columnsConfiguration?: GridColumn[],
    beforeSendFn?: (beforeSendArgs: FortifiedODataBeforeSendArgs) => string
  ) {
    super();

    this.onBeforeSend = beforeSendFn;
  }

  beforeSend = async (
    dataManager: DataManager,
    request: XMLHttpRequest,
    ajax: Ajax
  ) => {
    // Fetch Query
    const url = new URL(ajax.url);
    let queryString = url.search;

    // Cleanups
    //--Replaces any undefined keyword for null to supprto api types
    queryString = queryString.replace(/undefined/g, "null");

    // Send onBeforeSend event
    if (!!this.onBeforeSend) {
      queryString = this.onBeforeSend({
        dataManager,
        request,
        ajax,
        queryString,
      } as FortifiedODataBeforeSendArgs);
    }

    // Send request on Body
    // Note that is important that the body still sends the ? on the beginning for full support of queriers, including top.
    //queryString = queryString + "&$orderby=createdAt%20desc";
    ajax.data = queryString;
    request.open("POST", String(`${url.origin}${url.pathname}$query`));
  };

  onPredicate(
    predicate: Predicate,
    query: Query | boolean,
    requiresCast?: boolean
  ): string {
    // When Searching / filtering dates need to be converted back to thier native format of UTC.
    // "date" types will display as is without time conversion
    // Type on Column Configuration should be date, ideally with format: { format: "MM/dd/yyyy", type: "date" }
    const dateColumnConfigs = this.columnsConfiguration?.filter(
      (x) => x.type === "date"
    );

    if (dateColumnConfigs?.map((x) => x.field).includes(predicate.field)) {
      // Exclude search text from being parsed as a date
      if (predicate.value && typeof predicate.value === "object") {
        const momentDate = moment(predicate.value as Date);
        if (momentDate.isValid()) {
          const userTimezoneOffset =
            momentDate.toDate().getTimezoneOffset() * 60000;
          const newDateNoTz = new Date(
            momentDate.toDate().getTime() - userTimezoneOffset
          );
          const rawDateWithoutZoneAsDate = new Date(newDateNoTz);
          predicate.value = rawDateWithoutZoneAsDate;
        }
      }
    }

    const result = super.onPredicate(predicate, query, requiresCast);
    return result;
  }

  processResponse(
    data: DataResult,
    ds?: DataOptions,
    query?: Query,
    xhr?: XMLHttpRequest,
    request?: Ajax,
    changes?: CrudOptions
  ): Object {
    const dateColumnReults = this.processDateColumns(data);

    if (!!dateColumnReults) {
      data = dateColumnReults;
    }
    const processResult = super.processResponse.apply(this, [
      data,
      ds,
      query,
      xhr,
      request,
      changes,
    ]);
    return processResult;
  }
  processDateColumns(data: DataResult): DataResult | undefined {
    // "date" types will display as is without time conversion
    // Type on Column Configuration should be date, ideally with format: { format: "MM/dd/yyyy", type: "date" }
    const dateColumnConfigs = this.columnsConfiguration?.filter(
      (x) => x.type === "date"
    );
    if (dateColumnConfigs) {
      const cleaned = (_.get(data, "value") as any as Object[])?.map(
        (resultData) => {
          dateColumnConfigs.forEach((columnConfig) => {
            const rawDate = _.get(resultData, columnConfig.field) as string;
            if (!!rawDate) {
              // since we now officially set the odata timestamp to UTC, we can ensure that Z will always be returned
              const rawDateWithoutZone = rawDate?.replace("Z", "");
              if (rawDateWithoutZone) {
                const rawDateWithoutZoneAsDate = new Date(rawDateWithoutZone);
                _.set(resultData, columnConfig.field, rawDateWithoutZoneAsDate);
              }
            }
          });

          return resultData;
        }
      );

      return { ...data, ...{ value: cleaned } };
    }
  }
}
