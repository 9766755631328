import {
  FieldSchemaFormBuilderConfigV2,
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
} from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  homeStandardBasicInformationFormBuilder,
  homeStandardConfirmationAffirmationsBuilder,
  homeStandardHomeownerInformationBuilder,
  homeStandardPropertyDetailsBuilder,
} from "../../../standard/components/Forms/BasicInformationForm/HomeStandardBasicInformationFormBuilder";
import {
  homeSCSHBasicInformationFormBuilder,
  homeSCSHConfirmationAffirmationsBuilder,
  homeSCSHHomeownerInformationBuilder,
  homeSCSHPropertyDetailsBuilder,
} from "../../../scsh/components/Forms/BasicInformationForm/HomeSCSHBasicInformationFormBuilder";
import {
  homeSCSHEvaluationFormInformationBuilder,
  homeSCSHEvaluationInformationBuilder,
} from "../../../scsh/components/Forms/SCSHEvaluationForm/HomeSCSHEvaluationFormBuilder";

export interface HomeFieldSchemaModel {}
export type HomeFieldSchemaFormType =
  typeof HomeFieldSchemaFormTypeList[number];
export type HomeFieldSchemaConfiguration = Record<
  HomeFieldSchemaFormType,
  FieldSchemaFormBuilderConfigV2 | undefined
>;

export const HomeFieldSchemaFormTypeList = [
  "HomeStandardBasicInformationForm",
  "HomeStandardHomeownerInformation",
  "HomeStandardPropertyDetails",
  "HomeStandardConfirmationAffirmations",

  "HomeSCSHBasicInformationForm",
  "HomeSCSHHomeownerInformation",
  "HomeSCSHPropertyDetails",
  "HomeSCSHConfirmationAffirmations",
  "HomeSCSHEvaluationInformation",
  "HomeSCSHEvaluationForm",
] as const;
export const homeFieldSchemaConfig: HomeFieldSchemaConfiguration = {
  HomeStandardBasicInformationForm: undefined,
  HomeStandardHomeownerInformation: undefined,
  HomeStandardPropertyDetails: undefined,
  HomeStandardConfirmationAffirmations: undefined,

  HomeSCSHBasicInformationForm: undefined,
  HomeSCSHHomeownerInformation: undefined,
  HomeSCSHPropertyDetails: undefined,
  HomeSCSHConfirmationAffirmations: undefined,
  HomeSCSHEvaluationInformation: undefined,
  HomeSCSHEvaluationForm: undefined,
};

export type FCFMFCustomFieldSchemaFormBuildersConfig = Record<
  HomeFieldSchemaFormType,
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ) => Partial<FieldSchemaFormBuilderV2<HomeFieldSchemaModel>>
>;
export const homeCustomFieldSchemaFormBuilders: FCFMFCustomFieldSchemaFormBuildersConfig =
  {
    HomeStandardBasicInformationForm: homeStandardBasicInformationFormBuilder,
    HomeStandardHomeownerInformation: homeStandardHomeownerInformationBuilder,
    HomeStandardPropertyDetails: homeStandardPropertyDetailsBuilder,
    HomeStandardConfirmationAffirmations:
      homeStandardConfirmationAffirmationsBuilder,

    HomeSCSHBasicInformationForm: homeSCSHBasicInformationFormBuilder,
    HomeSCSHHomeownerInformation: homeSCSHHomeownerInformationBuilder,
    HomeSCSHPropertyDetails: homeSCSHPropertyDetailsBuilder,
    HomeSCSHConfirmationAffirmations: homeSCSHConfirmationAffirmationsBuilder,
    HomeSCSHEvaluationInformation: homeSCSHEvaluationInformationBuilder,
    HomeSCSHEvaluationForm: homeSCSHEvaluationFormInformationBuilder,
  };
