import React from "react";
import CreateHomeEvaluationDialog from "./Home/CreateHomeEvaluationDialog";
import CreateCommercialEvaluationDialog from "./Commercial/CreateCommercialEvaluationDialog";
import { EvaluationTypes } from "../../../types/evaluation/EvaluationTypes";
import { BaseDialogProps } from "../types";

export interface EvaluationChoiceDialogDialogProps extends BaseDialogProps {
  productType: EvaluationTypes;
}

const CreateEvaluationDialog: React.FC<EvaluationChoiceDialogDialogProps> = (
  props
) => {
  return (
    <>
      {props.productType === EvaluationTypes.Home && (
        <CreateHomeEvaluationDialog
          isOpen={props.isOpen}
          closeDialog={props.closeDialog}
          submitDialog={props.submitDialog}
        />
      )}
      {props.productType == EvaluationTypes.Commercial && (
        <CreateCommercialEvaluationDialog
          isOpen={props.isOpen}
          closeDialog={props.closeDialog}
          submitDialog={props.submitDialog}
        />
      )}
    </>
  );
};

export default CreateEvaluationDialog;
