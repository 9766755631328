import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import CommercialApplicationFormContainer from "../components/forms/ApplicationForm/Container/CommercialApplicationFormContainer";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { FCFMFApplicationFormParts } from "../../common/types/Evaluation/BaseFCFMFApplicationForm";

export interface CommercialApplicationFormPageProps {
  evaluationId?: string;
  commercialApplicationFormPart?: FCFMFApplicationFormParts;
}

const CommercialApplicationFormPageComponent: FC<
  CommercialApplicationFormPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <CommercialApplicationFormContainer
            evaluationId={props.evaluationId}
            applicationFormPart={props.commercialApplicationFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const CommercialApplicationFormPage = withAITracking(
  reactPlugin,
  CommercialApplicationFormPageComponent,
  "CommercialApplicationForm",
  "tracking-container"
);
