import { AddressFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { wildfireRoutePrefix } from "../../pages/index/wildfireRouteConfig";
import { Address } from "../../types/WildfireApplication/WildfireApplication";
import { wildfireEvaluationApiClient } from "../apiClients/application/wildfireApplicationApiClient";

export const createNewWildfireApplication = async (): Promise<string> => {
  const evaluation =
    await wildfireEvaluationApiClient.createWildfireEvaluation();
  return `${wildfireRoutePrefix}/${evaluation.id}/application-form`;
};

export const formatSecondaryAddressInfo = (
  address: Address | AddressFieldValue
): string => {
  const longState = address.state_AsString; // e.g. FL - Florida
  const shortState = longState
    ? longState.substring(0, longState.indexOf(" "))
    : ""; // e.g. FL

  return ` ${address.street2 ? `${address.street2}, ` : ""}${
    address.city
  }, ${shortState} ${address.zip}`;
};
