import baseApiClient from "../../../foritfied/lib/apiClients/baseApiClient";
import { ExistingComment, CommentReadDTO, Comment } from "./types";

class CommentApiClient {
  createComment = async (data: Comment) => {
    return baseApiClient.post<CommentReadDTO>(`comment`, data);
  };

  updateComment = async (id: string, data: ExistingComment) => {
    return baseApiClient.put<CommentReadDTO>(`comment/${id}`, data);
  };

  markCommentAsRead = async (id: string) => {
    return baseApiClient.put<CommentReadDTO>(`comment/${id}/mark-as-read`);
  };

  markCommentAsUnread = async (id: string) => {
    return baseApiClient.put<CommentReadDTO>(`comment/${id}/mark-as-unread`);
  };

  deleteComment = async (id: string) => {
    return baseApiClient.delete(`comment/${id}`);
  };
}

export const commentApiClient = new CommentApiClient();
