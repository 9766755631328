import { FormstackEmail } from "../../../types/formstack/fieldValueTypes/FormstackEmail";
import { EmailField as InterationEngineEmailField } from "../../../types/viewModels/IterationEngineViewModel";
import { TextField } from "../TextField/TextField";
import { BaseFieldProps } from "../types";

export interface EmailFieldProps
  extends BaseFieldProps<FormstackEmail>,
    Omit<InterationEngineEmailField, "id" | "sortOrder" | "isHidden"> {}

export const EmailField: React.FC<EmailFieldProps> = ({
  label,
  fieldId,
  value,
  disabled,
  onChange,
  onSubmit,
  isEmpty,
  isSelectedField,
  hasActiveChangeRequest,
  isSelectedChangeRequest,
  isResovledChangeRequest,
  hasActiveInternalComment,
  isExternalView,
}) => {
  return (
    <TextField
      isSelectedField={isSelectedField}
      hasActiveChangeRequest={hasActiveChangeRequest}
      isSelectedChangeRequest={isSelectedChangeRequest}
      isResovledChangeRequest={isResovledChangeRequest}
      hasActiveInternalComment={hasActiveInternalComment}
      fieldId={fieldId}
      fieldType="Text"
      label={label}
      value={value}
      isEmpty={isEmpty}
      inputMode="email"
      disabled={disabled}
      onChange={onChange}
      onSubmit={onSubmit}
      isExternalView={isExternalView}
    />
  );
};
