import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { FieldSchemaModel } from "./fieldSchemaConfig";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { wieApplicationFormBuilder } from "./ApplicationForm/ApplicationFormBuilders";
import { wieRoofFieldEvaluationFormBuilder } from "./RoofFieldEvaluationForm/RoofFieldEvaluationFormBuilders";
import { wieHomeExteriorFieldEvaluationFormBuilder } from "./HomeExteriorFieldEvaluationForm/HomeExteriorFieldEvaluationFormBuilders";
import { wieLandscapeFieldEvaluationFormBuilder } from "./LandscapeFieldEvaluationForm/LandscapeFieldEvaluationFormBuilders";
import { wieOtherFieldEvaluationFormBuilder } from "./OtherFieldEvaluationForm/OtherFieldEvaluationFormBuilders";

export type WieFieldSchemaFormType = typeof WieFieldSchemaFormTypeList[number];

export const WieFieldSchemaFormTypeList = [
  "ApplicationForm",
  "RoofFieldEvaluationForm",
  "HomeExteriorFieldEvaluationForm",
  "LandscapeFieldEvaluationForm",
  "OtherFieldEvaluationForm",
] as const;

export type WieFieldSchemaFormBuildersConfig = Record<
  WieFieldSchemaFormType,
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ) => Partial<FieldSchemaFormBuilderV2<FieldSchemaModel>>
>;
export const wieFieldSchemaFormBuilders: WieFieldSchemaFormBuildersConfig = {
  ApplicationForm: wieApplicationFormBuilder,
  RoofFieldEvaluationForm: wieRoofFieldEvaluationFormBuilder,
  HomeExteriorFieldEvaluationForm: wieHomeExteriorFieldEvaluationFormBuilder,
  LandscapeFieldEvaluationForm: wieLandscapeFieldEvaluationFormBuilder,
  OtherFieldEvaluationForm: wieOtherFieldEvaluationFormBuilder,
};
