import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { RelativityTooltip } from "@ucl/library";

interface WildfireSendForwardActionButtonProps {
  sendForward: () => Promise<void>;
  isSendForwardLoading: boolean;
  errors?: string[];
  resolvedAllChangeRequests: boolean;
  isEvaluationClaimedByCurrentUser: boolean;
  isWithdrawn: boolean;
}

const WildfireSendForwardActionButton: React.FC<
  WildfireSendForwardActionButtonProps
> = (props) => {
  const toolTipText = !props.resolvedAllChangeRequests
    ? `All Change Requests must be resolved to Send Forward`
    : !props.isEvaluationClaimedByCurrentUser
    ? "Claim Evaluation to Send Forward"
    : props.isWithdrawn
    ? "Evaluation is canceled"
    : "Send Forward";

  const buttonIsDisabled =
    !props.resolvedAllChangeRequests ||
    !props.isEvaluationClaimedByCurrentUser ||
    props.isWithdrawn;

  return (
    <RelativityTooltip shouldShowTooltip={true} content={toolTipText}>
      <Button
        text="Send Forward"
        disabled={buttonIsDisabled}
        minimal
        icon={IconNames.Tick}
        intent={Intent.SUCCESS}
        loading={props.isSendForwardLoading}
        onClick={async () => {
          await props.sendForward().then(() => {
            AppToaster.show({
              message: (
                <div>
                  <h3>Step Complete</h3>A status update email has been sent.
                </div>
              ),
              intent: Intent.SUCCESS,
            });
          });
        }}
      />
    </RelativityTooltip>
  );
};

export default WildfireSendForwardActionButton;
