import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { BaseDialogProps } from "./types";
import { boxTokenStore } from "../../stores/BoxTokenStore";
import { IntlProvider } from "react-intl";
import { useEffect } from "react";
import { BoxContentExplorer } from "../../../common/Components/Fields/BoxContentExplorer";
import { evaluationStore } from "../../stores/EvaluationStore";

export interface LegacyEvaluationFilesDialogProps extends BaseDialogProps {}

export const LegacyEvaluationFilesDialog: React.FC<
  LegacyEvaluationFilesDialogProps
> = ({ isOpen, closeDialog }) => {
  useEffect(() => {
    boxTokenStore.init();
  }, []);

  return (
    <Dialog
      className="legacy-evaluation-files-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
    >
      <div className={Classes.DIALOG_HEADER}>FOCUS 1.0 Files</div>
      <div className={Classes.DIALOG_BODY}>
        <IntlProvider locale="en">
          {boxTokenStore.isTokenValid && (
            <BoxContentExplorer
              token={boxTokenStore.getBoxAccessToken as any}
              rootFolderId={evaluationStore.evaluation?.externalFolderId}
              language="en-US"
              canCreateNewFolder={false}
              canShare={false}
              canDelete={false}
              canUpload={false}
              canRename={false}
            />
          )}
        </IntlProvider>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text={"Cancel"}
          onClick={closeDialog}
        />
      </div>
    </Dialog>
  );
};
