import { PrimaryCard } from "@ucl/library";
import RoofFieldEvaluationForm from "../RoofFieldEvaluationForm/RoofFieldEvaluationForm";
import HomeExteriorFieldEvaluationForm from "../HomeExteriorFieldEvaluationForm/HomeExteriorFieldEvaluationForm";
import LandscapeFieldEvaluationForm from "../LandscapeFieldEvaluationForm/LandscapeFieldEvaluationForm";
import OtherFieldEvaluationForm from "../OtherFieldEvaluationForm/OtherFieldEvaluationForm";
import { useContext, useEffect, useState } from "react";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import classNames from "classnames";
import ApplicationForm from "../ApplicationForm/ApplicationForm";
import { WildfireEvaluationSteps } from "../../../../foritfied/types/evaluation/Evaluation";
import { UnsavedChangesBlockerContext } from "../../Provider/UnsavedChangesBlockerContext";

interface IterationEngineFormsProps {
  evaluationId: string;
  evaluationStep: WildfireEvaluationSteps;
  isPlus: boolean;
  onFormSave: (fieldKey: string) => Promise<void>;
  onAllFormsInitialized: () => void;
  fieldsContainerClassName: string;
}

const WildfireIterationEngineForms: React.FC<IterationEngineFormsProps> = (
  props
) => {
  const loadEvaluationForms =
    props.evaluationStep ===
    WildfireEvaluationSteps.WildfireApplicationIBHSReview;
  const [applicationFormInitiaized, setApplicationFormInitiaized] = useState(
    props.isPlus
  );
  const [
    roofFieldEvaluationFormInitiaized,
    setRoofFieldEvaluationFormInitiaized,
  ] = useState(!loadEvaluationForms);
  const [
    homeExteriorFieldEvaluationFormInitiaized,
    setHomeExteriorFieldEvaluationFormInitiaized,
  ] = useState(!loadEvaluationForms);
  const [
    landscapeFieldEvaluationFormInitiaized,
    setLandscapeFieldEvaluationFormInitiaized,
  ] = useState(!loadEvaluationForms);
  const [
    otherFieldEvaluationFormInitiaized,
    setOtherFieldEvaluationFormInitiaized,
  ] = useState(!loadEvaluationForms);
  const [allFormsInitialized, setAllFormsInitialized] = useState(false);

  useEffect(() => {
    const formsInitialized =
      applicationFormInitiaized &&
      roofFieldEvaluationFormInitiaized &&
      homeExteriorFieldEvaluationFormInitiaized &&
      landscapeFieldEvaluationFormInitiaized &&
      otherFieldEvaluationFormInitiaized;

    if (formsInitialized) {
      setTimeout(() => {
        setAllFormsInitialized(true);
        props.onAllFormsInitialized();
      }, 600);
    } else {
      setAllFormsInitialized(false);
    }
  }, [
    applicationFormInitiaized,
    roofFieldEvaluationFormInitiaized,
    homeExteriorFieldEvaluationFormInitiaized,
    landscapeFieldEvaluationFormInitiaized,
    otherFieldEvaluationFormInitiaized,
  ]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  return (
    <>
      {!allFormsInitialized && <Loading />}
      <div
        className={classNames(props.fieldsContainerClassName, {
          "evaluation-forms-container-initialized": allFormsInitialized,
        })}
      >
        {!props.isPlus && (
          <PrimaryCard headerContent="Application Form">
            <ApplicationForm
              wildfireEvaluationId={props.evaluationId}
              isWiePage={true}
              showFormControls={false}
              onInitialized={() => {
                setApplicationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          </PrimaryCard>
        )}

        {loadEvaluationForms && (
          <>
            <PrimaryCard headerContent="Roof">
              <RoofFieldEvaluationForm
                wildfireEvaluationId={props.evaluationId}
                isWiePage={true}
                showFormControls={false}
                onInitialized={() => {
                  setRoofFieldEvaluationFormInitiaized(true);
                }}
                onFormSave={async (fieldKey) => {
                  props.onFormSave(fieldKey);
                }}
                setHasUnsavedChanges={setHasUnsavedChanges}
              />
            </PrimaryCard>

            <PrimaryCard headerContent="Home Exterior">
              <HomeExteriorFieldEvaluationForm
                wildfireEvaluationId={props.evaluationId}
                isWiePage={true}
                showFormControls={false}
                onInitialized={() => {
                  setHomeExteriorFieldEvaluationFormInitiaized(true);
                }}
                onFormSave={async (fieldKey) => {
                  props.onFormSave(fieldKey);
                }}
                setHasUnsavedChanges={setHasUnsavedChanges}
              />
            </PrimaryCard>

            <PrimaryCard headerContent="Landscape">
              <LandscapeFieldEvaluationForm
                wildfireEvaluationId={props.evaluationId}
                isWiePage={true}
                showFormControls={false}
                onInitialized={() => {
                  setLandscapeFieldEvaluationFormInitiaized(true);
                }}
                onFormSave={async (fieldKey) => {
                  props.onFormSave(fieldKey);
                }}
                setHasUnsavedChanges={setHasUnsavedChanges}
              />
            </PrimaryCard>

            <PrimaryCard headerContent="Other">
              <OtherFieldEvaluationForm
                wildfireEvaluationId={props.evaluationId}
                isWiePage={true}
                showFormControls={false}
                onInitialized={() => {
                  setOtherFieldEvaluationFormInitiaized(true);
                }}
                onFormSave={async (fieldKey) => {
                  props.onFormSave(fieldKey);
                }}
                setHasUnsavedChanges={setHasUnsavedChanges}
              />
            </PrimaryCard>
          </>
        )}
      </div>
    </>
  );
};

export default WildfireIterationEngineForms;
