import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import {
  default as config,
  default as variables,
} from "../../common/config/variables";
import { evaluationApiClient } from "../lib/apiClients/evaluation/evaluationApiClient";
import { evaluationCommentApiClient } from "../lib/apiClients/evaluation/evaluationCommentApiClient";
import {
  ComplexCommercialEvaluationBuilding,
  Evaluation,
  EvaluationField,
  EvaluationForm,
  EvaluationStep,
  ProductTypes,
  TraineeAuditStatuses,
} from "../types/evaluation/Evaluation";
import { EvaluationSchema } from "../types/evaluation/EvaluationSchema";
import { EvaluationTypes } from "../types/evaluation/EvaluationTypes";
import { Option } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { evaluationChangeRequestStore } from "./EvaluationChangeRequestStore";
import { evaluationCommentStore } from "./EvaluationCommentStore";
import { iterationEngineViewerStore } from "./IterationEngineViewerStore";
import { fortifiedUserStore } from "./FortifiedUserStore";
import { DesignationLevel } from "../types/evaluation/DesignationLevel";

export class EvaluationStore {
  public evaluation?: Evaluation;
  public complexCommercialEvaluationBuildings?: ComplexCommercialEvaluationBuilding[];
  public schema?: EvaluationSchema;
  public error?: string[];
  public selectedField?: Pick<EvaluationField, "id" | "label" | "value"> & {
    tabId?: number;
  };
  public selectedEvaluationId?: string;

  constructor() {
    makeObservable(this, {
      evaluation: observable,
      complexCommercialEvaluationBuildings: observable,
      error: observable,
      schema: observable,
      selectedField: observable,
      init: action,
      reset: action,
      setEvaluation: action,
      rejectEvaluation: action,
      approveEvaluation: action,
      sendForward: action,
      claimEvaluation: action,
      evaluationAddress: computed,
      isEvaluationClaimedByCurrentUser: computed,
    });
  }

  getEvaluationFieldById = (evaluationItemDataId: string) => {
    const evaluationForms = this.evaluation?.evaluationForms || [];
    const complexCommercialForms =
      this.complexCommercialEvaluationBuildings?.flatMap(
        (building) => building.commercialApplicationForm || []
      ) || [];

    const combinedForms = this.isComplexCommercialEvaluation
      ? [...evaluationForms, ...complexCommercialForms]
      : evaluationForms;

    const foundField = combinedForms
      ?.map((form) =>
        form.evaluationFields.map((field) => ({
          ...field,
          tabId: form.evaluationItemType,
          sortOrder: form.sortOrder * 1000 + field.sortOrder,
        }))
      )
      .flat()
      .find((field) => field.id === evaluationItemDataId);

    return foundField;
  };

  setSelectedField = async (selectedFieldId: string) => {
    const selectedField = this.getEvaluationFieldById(selectedFieldId);

    this.selectedField = selectedField;
    this.selectedEvaluationId = this.findEvaluationIdFieldById(selectedFieldId);

    if (this.selectedEvaluationId === undefined) {
      this.selectedEvaluationId =
        this.findComplexCommercialEvaluationIdFieldById(selectedFieldId);
    }

    if (!evaluationStore.evaluation) {
      throw new Error("Missing Evaluation");
    }

    if (!!this.selectedField?.tabId) {
      iterationEngineViewerStore.setSelectedTabId(this.selectedField.tabId);
    }

    evaluationCommentStore.setComments(
      await evaluationCommentApiClient.getEvaluationItemDataComments(
        evaluationStore.evaluation?.id,
        selectedFieldId
      )
    );
  };

  resetCommentContainer = () => {
    this.selectedField = undefined;
  };

  init = async (id: string, changeRequestId?: string) => {
    const evaluationDetail = await evaluationApiClient.getEvaluationDetail(id);
    this.evaluation = evaluationDetail.evaluation;
    this.schema = evaluationDetail.schema;
    this.error = evaluationDetail.error.errors;

    if (this.isComplexCommercialEvaluation) {
      await this.getComplexCommercialEvaluationBuildings(id);
    }

    await this.init_change_requests(id, changeRequestId);
  };

  init_change_requests = async (id: string, changeRequestId?: string) => {
    if (
      this.complexCommercialEvaluationBuildings &&
      this.complexCommercialEvaluationBuildings.length > 0
    ) {
      const complexCommercialBuildingIds =
        this.complexCommercialEvaluationBuildings.map(
          (building) => building.id
        );

      await evaluationChangeRequestStore.setChangeRequests(
        id,
        changeRequestId,
        complexCommercialBuildingIds
      );
    } else {
      await evaluationChangeRequestStore.setChangeRequests(id, changeRequestId);
    }
  };

  closeFilePreviews = () => {
    document
      .querySelectorAll<HTMLButtonElement>(
        "button.bcpr-PreviewHeader-button-close"
      )
      .forEach((closeButton) => closeButton.click());
  };

  getEvaluationByFortifiedId = async (fortifiedId: string) => {
    return evaluationApiClient.getEvaluationDetailByFortifiedId(fortifiedId);
  };

  setEvaluation = ({
    evaluation,
    schema,
    error,
  }: {
    evaluation: Evaluation;
    schema: EvaluationSchema;
    error: { errors: string[] };
  }) => {
    this.evaluation = evaluation;
    this.schema = schema;
    this.error = error.errors;
  };

  reset = () => {
    this.evaluation = undefined;
    this.schema = undefined;
    this.error = undefined;
    this.complexCommercialEvaluationBuildings = undefined;
  };

  refresh = async () => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }

    await this.init(this.evaluation.id);
  };

  createEvaluation = async () => {
    const commercialEvaluation = (await evaluationApiClient.createEvaluation())
      .evaluation;
    return commercialEvaluation;
  };

  createHomeEvaluation = async () => {
    const homeEvaluation = (await evaluationApiClient.createHomeEvaluation())
      .evaluation;
    return homeEvaluation;
  };

  createHomeRedesignationEvaluation = async (
    parentEvaluationId: string,
    evaluationExpirationDateType: number
  ) => {
    const redesignationEvaluation = (
      await evaluationApiClient.createHomeRedesignationEvaluation(
        parentEvaluationId,
        evaluationExpirationDateType
      )
    ).evaluation;
    return redesignationEvaluation;
  };

  createCommercialRedesignationEvaluation = async (
    parentEvaluationId: string
  ) => {
    const redesignationEvaluation = (
      await evaluationApiClient.createCommercialRedesignationEvaluation(
        parentEvaluationId
      )
    ).evaluation;
    return redesignationEvaluation;
  };

  sendForward = async () => {
    if (!this.evaluation) {
      return;
    }

    await evaluationApiClient.moveWorkflowToNextStep(this.evaluation.id);

    await this.init(this.evaluation.id);
  };

  isSelectedTabABuildingEvaluationForm = () => {
    const isSelectedFieldABuildingEvaluation =
      this.selectedEvaluationId != this.evaluation?.id;

    return isSelectedFieldABuildingEvaluation;
  };

  findComplexCommercialEvaluationIdFieldById = (
    targetFieldId: string
  ): string | undefined => {
    for (const evaluation of this.complexCommercialEvaluationBuildings || []) {
      const form = evaluation.commercialApplicationForm;

      for (const evaluationField of form.evaluationFields) {
        if (evaluationField.id === targetFieldId) {
          return evaluation.id;
        }
      }
    }
    return undefined; // Return undefined if the field with the specified ID is not found
  };

  findEvaluationIdFieldById = (targetFieldId: string): string | undefined => {
    if (this.evaluation) {
      for (const evaluationForm of this.evaluation.evaluationForms) {
        for (const evaluationField of evaluationForm.evaluationFields) {
          if (evaluationField.id === targetFieldId) {
            return this.evaluation.id;
          }
        }
      }
    }

    return undefined; // Return undefined if the field with the specified ID is not found
  };

  createComplexCommercialEvaluationBuilding = async (
    complexCommercialEvaluationId: string
  ) => {
    const buildings =
      await evaluationApiClient.createComplexCommercialEvaluationBuilding(
        complexCommercialEvaluationId
      );

    runInAction(() => (this.complexCommercialEvaluationBuildings = buildings));
  };

  getComplexCommercialEvaluationBuildings = async (
    complexCommercialEvaluationId: string
  ) => {
    const buildings =
      await evaluationApiClient.getComplexCommercialEvaluationBuildings(
        complexCommercialEvaluationId
      );

    runInAction(() => (this.complexCommercialEvaluationBuildings = buildings));
  };

  updateBuildingEvaluationItemData = async (
    complexCommercialEvaluationBuildingId: string,
    evaluationItemId: string,
    evaluationItemDataId: string,
    value: unknown
  ) => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }

    const { changeRequest, error } =
      await evaluationApiClient.updateEvaluationItemData(
        complexCommercialEvaluationBuildingId,
        evaluationItemId,
        evaluationItemDataId,
        value as string
      );

    evaluationChangeRequestStore.updateChangeRequest(changeRequest);

    runInAction(() => {
      this.error = error.errors;
    });
  };

  deleteComplexCommercialEvaluationBuilding = async (
    evaluationBulidingId: string
  ) => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }

    // Delete the evaluation
    await evaluationApiClient.deleteEvaluation(evaluationBulidingId);

    // Remove the deleted item from the list
    runInAction(() => {
      this.complexCommercialEvaluationBuildings =
        this.complexCommercialEvaluationBuildings?.filter(
          (building) => building.id !== evaluationBulidingId
        );
    });
  };

  getAreBuildingsFinishedBeingProcessed = async () => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }

    this.evaluation.areBuildingsFinishedBeingProcessed =
      await evaluationApiClient.getAreBuildingsFinishedBeingProcessed(
        this.evaluation.id
      );
  };

  updateEvaluationItemData = async (
    evaluationItemId: string,
    evaluationItemDataId: string,
    value: unknown
  ) => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }

    const { evaluation, changeRequest, error } =
      await evaluationApiClient.updateEvaluationItemData(
        this.evaluation.id,
        evaluationItemId,
        evaluationItemDataId,
        value as string
      );

    evaluationChangeRequestStore.updateChangeRequest(changeRequest);
    runInAction(() => {
      this.evaluation = evaluation;
      this.error = error.errors;
    });
  };

  requestChanges = async () => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }
    await evaluationApiClient.sendRequestedChanges(this.evaluation.id);
  };

  sendForReview = async () => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }
    await evaluationApiClient.sendChangesForReview(this.evaluation.id);
  };

  approveEvaluation = async () => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }

    if (this.evaluation.step === 7) {
      await evaluationApiClient.approveEvaluation(this.evaluation.id);
      this.evaluation.isApproved = true;
    } else {
      await this.sendForward();
    }

    this.closeFilePreviews();

    await this.init(this.evaluation.id);
  };

  rejectEvaluation = async (
    reasons: Option<number>[],
    additionalNotes: string
  ) => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }

    const evaluation = await evaluationApiClient.rejectEvaluation(
      this.evaluation.id,
      reasons,
      additionalNotes
    );

    this.evaluation = evaluation;
  };

  get evaluationDesignationLevelString() {
    return this.evaluation?.designationLevel_AsString;
  }

  get evaluationHazardTypeString() {
    return this.evaluation?.hazard_AsString;
  }

  get evaluationHasHailSupplementString() {
    return this.evaluation?.hasHailSupplement === true ? "Yes" : "No";
  }

  get evaluationIsNewConstruction() {
    return this.evaluation?.isNewConstruction === true ? "Yes" : "No";
  }

  get evaluationAddress() {
    if (!this.evaluation) return "";
    if (!this.evaluation.buildingAddress) return "";

    return `${this.evaluation.buildingAddress},${
      this.evaluation.buildingAddress2
        ? ` ${this.evaluation.buildingAddress2},`
        : ""
    } ${this.evaluation.buildingCity}, ${
      this.evaluation.buildingState_AsString
    } ${this.evaluation.buildingZip}`;
  }

  get evaluationGrossRoofAreaString() {
    return this.evaluation?.grossRoofAreaSqft.toLocaleString();
  }

  get evaluationTotalFloorAreaString() {
    return this.evaluation?.totalFloorAreaSqft.toLocaleString();
  }

  get evaluationUsesRoofAreaForFeeCalculations() {
    return this.evaluationDesignationLevelString === DesignationLevel.Roof;
  }

  get evaluationUsesFloorAreaForFeeCalculations() {
    const designation = this.evaluationDesignationLevelString;
    return (
      designation === DesignationLevel.Silver ||
      designation === DesignationLevel.Gold
    );
  }

  get evaluationFortifiedId() {
    return this.evaluation ? `(${this.evaluation.fortifiedId})` : "";
  }

  get evaluationBreadcrumbText() {
    return (
      evaluationStore.evaluationAddress +
      " " +
      evaluationStore.evaluationFortifiedId
    );
  }

  get getProductTypeByUserRole() {
    switch (fortifiedUserStore.user?.role) {
      case "FC/FMF Applicant":
      case "FC/FMF Admin":
        return "commercial";
      case "FH Evaluator":
      case "FH Direct Submit Roofer":
        return "home";
      default:
        return "home";
    }
  }

  get hasEvaluationRedesignations() {
    return this.evaluation && !!this.evaluation.parentEvaluationId;
  }

  get isApproved() {
    return this.evaluation && !!this.evaluation.approvedAt;
  }

  get isFortifiedHomeEvaluation() {
    return this.evaluation && this.evaluation.productType === 3;
  }

  get doesEvaluationHaveFinalDetermination() {
    return (
      this.evaluation?.isApproved === true ||
      this.evaluation?.isApproved === false
    );
  }

  get isFHPaymentStep() {
    return this.evaluation?.step === EvaluationStep.Payment;
  }

  get hasFinalCertificateFeePaymentBeenProcessed() {
    return this.evaluation?.hasFinalCertificateFeePaymentBeenProcessed === true;
  }

  get isEvaluationCreatorExemptFromPayment() {
    return this.evaluation?.isEvaluationCreatorExemptFromPayment === true;
  }

  get isFCFMFPaymentStep() {
    return (
      this.evaluation?.step === EvaluationStep.ApplicationFee ||
      (this.evaluation?.step === EvaluationStep.CertificateFee &&
        this.evaluation.hasFinalCertificateFeePaymentBeenProcessed === false &&
        this.evaluation.hasFCFMFPaymentWorkflow)
    );
  }

  get paymentLink() {
    if (!this.evaluation?.paymentLink) {
      return;
    }

    return (
      this.evaluation.paymentLink +
      `?${new URLSearchParams({
        fortified_id: this.evaluation.fortifiedId,
        ibhs_formstack_env: config.formstackEnvironment,
      })}`
    );
  }

  getNewEvaluationUrl = (
    productType: EvaluationTypes,
    evaluation: Evaluation
  ) => {
    switch (productType) {
      case EvaluationTypes.Commercial:
        const documentFields = evaluation.evaluationForms
          .find(
            (form) =>
              form?.evaluationItemTypes_AsString ===
              "Commercial Application Form"
          )
          ?.evaluationFields?.filter((field) => field.fieldType === "Embed");

        if (!documentFields || documentFields.length === 0) {
          return;
        }

        const documentFieldFolderMapping = documentFields.reduce(
          (a, c) => ({ ...a, [`fieldFolderId_${c.externalId}`]: c.extData }),
          {}
        );

        return `${config.newCommercialEvaluationLink}?${new URLSearchParams({
          evaluationId: evaluation.id,
          evaluation_id: evaluation.id,
          ...documentFieldFolderMapping,
        })}`;
      case EvaluationTypes.Home:
        const submitterEmail = fortifiedUserStore.user?.email;

        if (!submitterEmail) {
          return;
        }

        return `${config.newHomeEvaluationLink}?${new URLSearchParams({
          fortified_id: evaluation.fortifiedId,
          ibhs_formstack_env: config.formstackEnvironment,
          evaluator_id: submitterEmail,
        })}`;
      case EvaluationTypes.HomeReDesignation:
        return `/fh/checklist?fortifiedId=${evaluation.fortifiedId}`;
      case EvaluationTypes.CommercialReDesignation:
        return `${
          config.newCommercialRedesignationEvaluationLink
        }?${new URLSearchParams({
          evaluationId: evaluation.id,
          fortified_id: evaluation.fortifiedId,
          root_box_folder_id: evaluation.externalFolderId,
          ibhs_formstack_env: config.formstackEnvironment,
        })}`;
    }
  };

  getEvaluationFormUrl = (evaluation: Evaluation, form: EvaluationForm) => {
    const params: [string, string][] = [];

    for (const key of Object.keys(form.evaluationItemUrlParameters)) {
      const values = form.evaluationItemUrlParameters[key];

      for (const value of values) {
        params.push([key, value]);
      }
    }

    return `${form.externalUrl}?${decodeURI(
      new URLSearchParams([
        ...params,
        ["evaluation_id", evaluation.id],
        ["fortified_id", evaluation.fortifiedId],
        ["ibhs_formstack_env", variables.formstackEnvironment],
        ["root_box_folder_id", evaluation.externalFolderId],
      ]).toString()
    )}`;
  };

  withdrawApplication = async () => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }

    await evaluationApiClient.withdrawEvaluation(this.evaluation.id);

    await this.refresh();
  };

  reactivateEvaluation = async () => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }

    await evaluationApiClient.reactivateEvaluation(this.evaluation.id);

    await this.refresh();
  };

  claimEvaluation = async () => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }

    const evaluation = await evaluationApiClient.claimEvaluation(
      this.evaluation.id
    );

    this.evaluation = evaluation;
  };

  applyPaymentCredit = async () => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }

    const evaluation = await evaluationApiClient.applyPaymentCredit(
      this.evaluation.id
    );

    this.evaluation = evaluation;
  };

  getEvaluationCertificate = async () => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }
    return evaluationApiClient.getEvaluationCertificate(this.evaluation.id);
  };

  regenerateEvaluationCertificate = async () => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }
    return evaluationApiClient.regenerateEvaluationCertificate(
      this.evaluation.id
    );
  };

  getRooferConfirmationForm = async () => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }
    return evaluationApiClient.getRooferConfirmationForm(
      this.evaluation.fortifiedId
    );
  };

  createPostDesignationBoxFolder = async () => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }
    const evaluation = await evaluationApiClient.createPostDesignationBoxFolder(
      this.evaluation.id
    );
    this.evaluation = evaluation;
  };

  resubmitEvaluation = async () => {
    if (!this.evaluation) {
      throw new Error("Missing Evaluation");
    }
    const evaluation = await evaluationApiClient.resubmitEvaluation(
      this.evaluation.id
    );
    this.evaluation = evaluation;
  };

  get isEvaluationClaimedByCurrentUser() {
    if (!!this.evaluation?.auditorId && !!fortifiedUserStore.user?.id) {
      return this.evaluation.auditorId === fortifiedUserStore.user.id;
    }

    return false;
  }

  get isEvaluationClaimed() {
    if (!!this.evaluation?.isEvaluationClaimed) {
      return this.evaluation.isEvaluationClaimed;
    }

    return false;
  }

  get isClaimedForTraineeAudit() {
    return !!this.evaluation?.auditorTraineeId;
  }

  get isClaimedForTraineeAuditByCurrentUser() {
    return (
      !!this.evaluation?.auditorTraineeId &&
      !!fortifiedUserStore.user?.id &&
      this.evaluation.auditorTraineeId === fortifiedUserStore.user.id
    );
  }

  get isTraineeAuditActionByTraineeRequired() {
    const status = this.evaluation?.traineeAuditStatus;
    return (
      status === TraineeAuditStatuses.IBHSReview ||
      status === TraineeAuditStatuses.ChangesRequired
    );
  }

  get isTraineeAuditActionByAdminRequired() {
    const status = this.evaluation?.traineeAuditStatus;
    return (
      status === TraineeAuditStatuses.ReviewComplete ||
      status === TraineeAuditStatuses.ReadyForReview
    );
  }

  get shouldShowTraineeAuditActionButtons() {
    if (!this.evaluation) {
      return false;
    }

    if (this.doesEvaluationHaveFinalDetermination) {
      return false;
    }

    const step = this.evaluation.step;
    return (
      step === EvaluationStep.EligibilityReview ||
      step === EvaluationStep.FinalReview
    );
  }

  get evaluationProductType() {
    return this.evaluation?.productType;
  }

  get isRedesignationEvaluation() {
    return this.evaluation?.isRedesignation;
  }

  get isMultiFamilyEvaluation() {
    return this.evaluationProductType === ProductTypes.FortifiedMultifamily;
  }
  get isCommercialEvaluation() {
    return this.evaluationProductType === ProductTypes.FortifiedCommercial;
  }

  get isCommercialOrMultifamilyEvaluation() {
    return (
      this.evaluationProductType === ProductTypes.FortifiedCommercial ||
      this.evaluationProductType === ProductTypes.FortifiedMultifamily
    );
  }

  get isLegacyEvaluation() {
    if (!!this.evaluation?.isLegacyEvaluation) {
      return this.evaluation.isLegacyEvaluation;
    }
    return false;
  }

  get getLegacyEvaluationType() {
    if (!!this.evaluation?.legacyEvaluationSource) {
      return this.evaluation.legacyEvaluationSource;
    }
  }

  get isEvaluationNonProfit() {
    if (!!this.evaluation?.isNonProfitProject) {
      return this.evaluation.isNonProfitProject;
    }
  }

  get isEvaluationNonProfitOnPaymentStep() {
    return (
      this.evaluation?.step === EvaluationStep.Payment &&
      this.isEvaluationNonProfit
    );
  }

  get isEvaluationOnRequiredPaymentStep() {
    return (
      this.evaluation?.step === EvaluationStep.Payment &&
      this.doesEvaluationHaveRequiredPayment
    );
  }
  get doesEvaluationHaveRequiredPayment() {
    return (
      this.evaluation?.isPaymentApplicable &&
      !this.evaluation?.hasPaymentBeenSubmitted
    );
  }
  get isEvaluationAutoRejected() {
    return this.evaluation?.isAutoRejected === true;
  }

  get isEvaluationOnFinalReviewStep() {
    return this.evaluation?.step === EvaluationStep.FinalReview;
  }

  get isEvaluationOnComplianceLetterStep() {
    return (
      this.evaluation?.step ===
      EvaluationStep.EvaluatorComplianceLetterSubmitted
    );
  }

  get canResubmit() {
    return (
      this.evaluation?.isApproved === false && this.isEvaluationAutoRejected
    );
  }

  get shouldShowAutoRejectBanner() {
    return this.evaluation?.shouldShowAutoRejectBanner;
  }

  get isComplexCommercialEvaluation() {
    return this.evaluation?.isComplexCommercialEvaluation;
  }

  get isComplexCommercialEvaluationBuilding() {
    return this.evaluation?.isComplexCommercialEvaluationBuilding;
  }

  get isComplexCommercialEvaluationAbleToApproveAllBuildings() {
    if (
      !this.isComplexCommercialEvaluation ||
      !this.isEvaluationOnFinalReviewStep ||
      !this.complexCommercialEvaluationBuildings
    ) {
      return false;
    }

    for (const building of this.complexCommercialEvaluationBuildings) {
      if (building.isBlockingParentFromApproval === true) {
        return false;
      }
    }
    return true;
  }

  get isComplexCommercialEvaluationBlockedAtFinalApprovalByBuilding() {
    return (
      evaluationStore.isComplexCommercialEvaluation &&
      evaluationStore.isEvaluationOnFinalReviewStep &&
      !evaluationStore.isComplexCommercialEvaluationAbleToApproveAllBuildings
    );
  }

  get isComplexCommercialEvaluationBuildingFormAbleToBeModified() {
    return (
      (this.evaluation?.step === EvaluationStep.Application ||
        this.evaluation?.step === EvaluationStep.EligibilityReview ||
        this.evaluation?.step === EvaluationStep.ApplicationFee ||
        this.evaluation?.step === EvaluationStep.ComplianceFormSubmitted) &&
      this.isComplexCommercialEvaluation
    );
  }

  get isComplexCommercialEvaluationAndNeedToFillOutBuildings() {
    return (
      this.evaluation?.step === EvaluationStep.ApplicationFee &&
      evaluationStore.isComplexCommercialEvaluation
    );
  }

  hasActiveInternalComment = (evaluationItemDataId: string): boolean => {
    return (
      !!this.evaluation &&
      !!this.evaluation.evaluationItemDataIdsWithInternalComments &&
      this.evaluation.evaluationItemDataIdsWithInternalComments.includes(
        evaluationItemDataId
      )
    );
  };
}

export const evaluationStore = new EvaluationStore();
