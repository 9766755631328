import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { CommercialEvaluationCompanyAssignmentFormModel } from "../../types/CommercialCompanyAssignmentFormModel";
import { Evaluation_CommercialEvaluationFormModel } from "../../types/CommercialEvaluationFormModel";

class CommercialEvaluationCompanyAssignmentFormAPIClient {
  updateCommercialEvaluationCompanyAssignmentForm = async (
    evaluationId: string,
    evaluationCompanyAssignmentForm: CommercialEvaluationCompanyAssignmentFormModel,
    fieldKey?: string
  ): Promise<Evaluation_CommercialEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_CommercialEvaluationFormModel>(
      `/commercial/evaluation-company-assignment-form/${evaluationId}`,
      {
        evaluationCompanyAssignmentForm,
        fieldKey,
      }
    );
  };

  submitCommercialEvaluationCompanyAssignmentForm = async (
    evaluationId: string,
    evaluationCompanyAssignmentForm: CommercialEvaluationCompanyAssignmentFormModel
  ): Promise<Evaluation_CommercialEvaluationFormModel> => {
    return baseApiClient.post<Evaluation_CommercialEvaluationFormModel>(
      `/commercial/evaluation-company-assignment-form/${evaluationId}`,
      evaluationCompanyAssignmentForm
    );
  };
}

export const commercialEvaluationCompanyAssignmentFormAPIClient =
  new CommercialEvaluationCompanyAssignmentFormAPIClient();
