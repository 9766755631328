import "./styles.scss";
import { MenuItem, SidebarMenu } from "../components/SidebarMenu/SidebarMenu";
import { useEffect, useState } from "react";
import { IconNames } from "@blueprintjs/icons";

export enum HelpPageMenuItems {
  ReleaseNotes = "release-notes",
  SupportRequest = "support-request",
}

export const HelpCenterComponent: React.FC = () => {
  const [activeMenuItem, setActiveMenuItem] = useState<HelpPageMenuItems>(
    HelpPageMenuItems.ReleaseNotes
  );

  const menuItems: MenuItem[] = [
    {
      id: HelpPageMenuItems.ReleaseNotes,
      label: "Release Notes",
      iconName: IconNames.Updated,
      onClick: () => {
        setActiveMenuItem(HelpPageMenuItems.ReleaseNotes);
      },
    },
    {
      id: HelpPageMenuItems.SupportRequest,
      label: "Support Request",
      iconName: IconNames.Help,
      onClick: () => {
        setActiveMenuItem(HelpPageMenuItems.SupportRequest);
      },
    },
  ];

  // Function to add the external script to the document
  const addScript = (src: string) => {
    const script = document.createElement("script");
    script.src = src;
    script.type = "text/javascript";
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (activeMenuItem === HelpPageMenuItems.SupportRequest) {
      // Add the script when the active menu item is SupportRequest
      addScript(
        "https://fortifiedhome.org/wp-content/plugins/gravity-forms-iframe-develop/assets/scripts/gfembed.min.js"
      );
    }
  }, [activeMenuItem]);

  return (
    <div className="help-page">
      <div className="help-page__sidebar">
        <SidebarMenu menuItems={menuItems} activeMenuItem={activeMenuItem} />
      </div>
      <div className="help-page__content">
        {activeMenuItem === HelpPageMenuItems.ReleaseNotes && (
          <iframe
            className="help-page__content__iframe"
            src="https://doc.clickup.com/14269711/d/h/dkf8f-24011/6ae2c28240a63d8"
          />
        )}
        {activeMenuItem === HelpPageMenuItems.SupportRequest && (
          <iframe
            className="help-page__content__iframe"
            src="https://fortifiedhome.org/gfembed/?f=19"
            width="100%"
            height="100%"
          />
        )}
      </div>
    </div>
  );
};
