import {
  FieldSchemaFormBuilderV2,
  Schema,
  SchemaFactoryV2,
} from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { ChangeRequestV2 } from "../components/IterationEngine/types/ChangeRequestV2";
import { transformFieldsForIterationEngineBeforeBuild } from "../forms/Utils/transformFieldBeforeBuild";
import {
  FieldSchemaFormType,
  FieldSchemaFormTypeList,
  FieldSchemaModel,
  customFieldSchemaFormBuilders,
  fieldSchemaConfig,
} from "../forms/fieldSchemaConfig";
import {
  WieFieldSchemaFormType,
  WieFieldSchemaFormTypeList,
  wieFieldSchemaFormBuilders,
} from "../forms/wieFieldSchemaConfig";
import { WildfireFieldEvaluationCompliance } from "../types/WildfireFieldComplianceDTO";
import { FieldSchemas } from "@ucl/library/lib/components/FieldSchema/types";

export type WildfireFieldSchemaType = {
  [key: string]: {
    designationLevel?: WildfireDesignationLevel;
    associatedFieldKey?: string;
    fieldKey?: string;
  };
};

export enum WildfireDesignationLevel {
  Base = 1,
  Plus = 2,
}

interface FieldSchemaFactoryContextProps {
  schemaFactory?: WildfireSchemaFactoryV2;
  builders?: FieldSchemaBuilderConfig;
  wieBuilders?: WieFieldSchemaBuilderConfig;
}

interface FieldSchemaFactoryProviderProps {
  schemaFactory: WildfireSchemaFactoryV2;
  children: ReactNode;
  formOverrides?: FormOverrides;
}

export interface FormOverrides {
  onFieldChange?: (field: FieldSchemaModel) => void;
  onLabelClick?: (fieldProps: BaseFieldProps<any>) => void;
  iterationEngineProps?: IterationEngineProps;
  fieldDecisions?: WildfireFieldEvaluationCompliance;
  isPlus?: boolean;
}

export interface WildfireSchema extends Schema {
  schema: {
    Evaluation: FieldSchemas;
    [key: string]: FieldSchemas;
  };
  wildfireFieldSchema: WildfireFieldSchemaType;
}

export class WildfireSchemaFactoryV2 extends SchemaFactoryV2 {
  schema: WildfireSchema;

  constructor(loadedSchema: Schema | null) {
    super(loadedSchema);
    this.schema = loadedSchema as WildfireSchema;
  }
}

interface IterationEngineProps {
  fieldKeysWithChangeRequests?: string[];
  shouldEnableForm: boolean;
  changeRequests?: ChangeRequestV2[];
}

type FieldSchemaBuilderConfig = Record<
  FieldSchemaFormType,
  Partial<FieldSchemaFormBuilderV2<FieldSchemaModel>>
>;

type WieFieldSchemaBuilderConfig = Record<
  WieFieldSchemaFormType,
  Partial<FieldSchemaFormBuilderV2<FieldSchemaModel>>
>;

const FieldSchemaFactoryContext = createContext<FieldSchemaFactoryContextProps>(
  {
    schemaFactory: undefined,
    builders: undefined,
  }
);

export const FieldSchemaFactoryProvider: React.FC<
  FieldSchemaFactoryProviderProps
> = (props) => {
  const [builders, setBuilders] = useState<FieldSchemaBuilderConfig>();
  const [wieBuilders, setWieBuilders] = useState<WieFieldSchemaBuilderConfig>();

  const getBuilders = (factory: WildfireSchemaFactoryV2) => {
    let builderList = {};
    for (const formIdx in FieldSchemaFormTypeList) {
      if (
        customFieldSchemaFormBuilders.hasOwnProperty(
          FieldSchemaFormTypeList[formIdx] as FieldSchemaFormType
        )
      ) {
        const customFieldSchemaBuilderConfig = customFieldSchemaFormBuilders[
          FieldSchemaFormTypeList[formIdx]
        ](factory, (fields: BaseFieldProps<any>[]) => {
          return fields.map((field) => {
            return field;
          });
        });
        builderList = {
          ...builderList,
          [FieldSchemaFormTypeList[formIdx]]: customFieldSchemaBuilderConfig,
        };
      } else {
        const config =
          fieldSchemaConfig[
            FieldSchemaFormTypeList[formIdx] as FieldSchemaFormType
          ];
        if (config) {
          const fieldSchemaBuilderConfig =
            factory.buildFieldSchemaFormBuilder<FieldSchemaModel>(config);

          builderList = {
            ...builderList,
            [FieldSchemaFormTypeList[formIdx]]: fieldSchemaBuilderConfig,
          };
        }
      }
    }
    setBuilders(builderList as FieldSchemaBuilderConfig);
  };

  const getWieBuilders = (factory: WildfireSchemaFactoryV2) => {
    let builderList = {};
    for (const formIdx in WieFieldSchemaFormTypeList) {
      if (
        wieFieldSchemaFormBuilders.hasOwnProperty(
          WieFieldSchemaFormTypeList[formIdx] as WieFieldSchemaFormType
        )
      ) {
        const wieFieldSchemaBuilderConfig = wieFieldSchemaFormBuilders[
          WieFieldSchemaFormTypeList[formIdx]
        ](factory, (fields: BaseFieldProps<any>[]) => {
          // Transform fields for iteration engine
          return fields.map((field) => {
            const transformedField =
              transformFieldsForIterationEngineBeforeBuild(
                fields,
                field,
                props.formOverrides,
                factory.schema?.wildfireFieldSchema
              );

            return transformedField;
          });
        });

        builderList = {
          ...builderList,
          [WieFieldSchemaFormTypeList[formIdx]]: wieFieldSchemaBuilderConfig,
        };
      }
    }
    setWieBuilders(builderList as WieFieldSchemaBuilderConfig);
  };

  useEffect(() => {
    if (props.schemaFactory) {
      getBuilders(props.schemaFactory);
      getWieBuilders(props.schemaFactory);
    }
  }, [props.schemaFactory]);

  useEffect(() => {
    if (props.schemaFactory) {
      getWieBuilders(props.schemaFactory);
    }
  }, [
    props.formOverrides?.iterationEngineProps?.changeRequests,
    props.formOverrides?.fieldDecisions,
    props.formOverrides?.isPlus,
  ]);

  return (
    <FieldSchemaFactoryContext.Provider
      value={{
        schemaFactory: props.schemaFactory,
        builders,
        wieBuilders,
      }}
    >
      {props.children}
    </FieldSchemaFactoryContext.Provider>
  );
};

export const useFieldSchemaFactory = () =>
  useContext(FieldSchemaFactoryContext);
