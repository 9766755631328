import { Divider, Icon, IconProps, Intent, Tab, Tabs } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { useState } from "react";
import { useEffect } from "react";
import { evaluationStore } from "../../stores/EvaluationStore";
import { EvaluationStep } from "../../types/evaluation/Evaluation";
import { EvaluationSchema } from "../../types/evaluation/EvaluationSchema";
import EvaluationHistory from "./History/EvaluationHistory";
import { parseDate } from "../../../common/utils/date";
import { iterationEngineViewerStore } from "../../stores/IterationEngineViewerStore";
import ComplexCommercialEvaluationRelationshipView from "./ComplexCommercialEvaluationBuilding/Buttons/ComplexCommercialEvaluationRelationshipView";
import AddBuildingsButton from "./ComplexCommercialEvaluationBuilding/Buttons/AddBuildingsButton";
import useTimeout from "../../../common/customHooks/useTimeout";
import { AppToaster } from "../Toast/Toast";

interface EvaluationStatusesProps {
  evaluationStatus: number;
  evaluationSchema: EvaluationSchema;
  isApproved?: boolean;
  formTabs: { id: number; label: string }[];
  selectedTabId: number;
  setSelectedTabId: (tablId: number) => void;
  evaluationId: string;
  expiresAt?: Date;
  isRedesignation: boolean;
  evaluationExpirationDateType_AsString?: string;
}

const getIcon = (
  evaluationStatus: number,
  status: number,
  isApproved?: boolean
): IconProps => {
  const orderedSteps = [
    EvaluationStep.Application,
    EvaluationStep.ApplicationFee,
    EvaluationStep.EligibilityReview,
    EvaluationStep.ApplicationForms,
    EvaluationStep.EvaluatorAssignment,
    EvaluationStep.ComplianceFormSubmitted,
    EvaluationStep.FieldInspectionsSubmitted,
    EvaluationStep.EvaluatorComplianceLetterSubmitted,
    EvaluationStep.Payment,
    EvaluationStep.FinalReview,
    EvaluationStep.CertificateFee,
  ];

  const areAllStepsCompleted =
    (evaluationStatus === EvaluationStep.FinalReview ||
      evaluationStore.evaluation?.hasFinalCertificateFeePaymentBeenProcessed) &&
    isApproved === true;

  const shouldDisplayRejectIcon =
    isApproved === false && evaluationStatus === status;

  const shouldDisplayTickCircle =
    orderedSteps.indexOf(evaluationStatus) > orderedSteps.indexOf(status) ||
    areAllStepsCompleted;

  const shouldDisplayCircle = evaluationStatus === status;

  if (shouldDisplayRejectIcon) {
    return { intent: Intent.DANGER, icon: IconNames.DELETE };
  }

  if (shouldDisplayTickCircle) {
    return { icon: IconNames.TICK_CIRCLE };
  }

  if (shouldDisplayCircle) {
    return { icon: IconNames.CIRCLE };
  }

  return { icon: IconNames.FULL_CIRCLE };
};
export const EvaluationStatuses: React.FC<EvaluationStatusesProps> = ({
  evaluationStatus,
  isApproved,
  evaluationSchema,
  formTabs,
  selectedTabId,
  setSelectedTabId,
  evaluationId,
  expiresAt,
  isRedesignation,
  evaluationExpirationDateType_AsString,
}) => {
  const shouldShowTabs = evaluationStore.isFortifiedHomeEvaluation;

  const [inactivityToastKey, setInactivityToastKey] = useState<string>();

  const { restart: restartInactivityTimeout } = useTimeout(() => {
    setInactivityToastKey(
      AppToaster.show({
        message: (
          <div>
            Your session has expired. Please refresh your page to continue.
          </div>
        ),
        intent: Intent.DANGER,
        timeout: 0, // Don't automatically disappear, must be dismissed by user
      })
    );
  }, 45 * 60_000); // 45 minutes

  useEffect(() => {
    if (formTabs.length > 0 && !selectedTabId) {
      setSelectedTabId(formTabs[0].id);
    }
    return () => {
      iterationEngineViewerStore.setSelectedTabId(0);
    };
  }, []);

  return (
    <div className="evaluation-container__status">
      <Tabs
        vertical={true}
        className="evaluation-container__status__disabled-tabs"
      >
        {evaluationSchema.evaluationStatuses.map((status) => {
          return (
            <Tab
              disabled={true}
              key={status.id}
              id={status.id}
              title={
                <>
                  {
                    <Icon
                      {...getIcon(evaluationStatus, status.id, isApproved)}
                    />
                  }
                  {status.label}
                </>
              }
            />
          );
        })}
      </Tabs>
      {shouldShowTabs && (
        <>
          <Divider className="evaluation-container__status__divider" />
          <Tabs
            vertical
            selectedTabId={selectedTabId}
            onChange={(newTabId: number) => {
              setSelectedTabId(newTabId);
              restartInactivityTimeout();
              !!inactivityToastKey && AppToaster.dismiss(inactivityToastKey);
            }}
          >
            {formTabs.map((tab) => (
              <Tab key={tab.id} id={tab.id} title={tab.label} />
            ))}
          </Tabs>
        </>
      )}
      <EvaluationHistory evaluationId={evaluationId} />
      {(evaluationStore.isComplexCommercialEvaluation ||
        evaluationStore.isComplexCommercialEvaluationBuilding) && (
        <ComplexCommercialEvaluationRelationshipView
          evaluationId={evaluationId}
        />
      )}

      {isRedesignation && evaluationExpirationDateType_AsString && expiresAt && (
        <div className="evaluation-expiration-container">
          <div className="evaluation-expiration-container__field">
            <div className="evaluation-expiration-container__label">
              Expiration Status:
            </div>
            <div>{evaluationExpirationDateType_AsString}</div>
          </div>
          <div className="evaluation-expiration-container__field">
            <div className="evaluation-expiration-container__label">
              Expiration Date:
            </div>
            <div>{parseDate(expiresAt)}</div>
          </div>
        </div>
      )}
      {evaluationStore.evaluation?.isComplexCommercialEvaluation && (
        <div className="evaluation-add-building-button">
          <AddBuildingsButton
            id="evaluation-add-building-button"
            isLoading={false}
            buttonText="Go To Buildings"
            onClick={() => {
              const form = document.getElementById(
                "complex-evaluation-buildings-card"
              );
              if (!form) {
                return;
              }
              form.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start",
              });
            }}
          />
        </div>
      )}
    </div>
  );
};
