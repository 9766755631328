import { Classes, Dialog, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DialogFooter } from "./DialogFooter";
import { BaseDialogProps } from "./types";

export interface AuditorRequestChangeDialogProps extends BaseDialogProps {}

export const AuditorRequestChangeDialog: React.FC<
  AuditorRequestChangeDialogProps
> = ({ isOpen, closeDialog, submitDialog }) => {
  return (
    <Dialog isOpen={isOpen} hasBackdrop={false}>
      <div className={Classes.DIALOG_HEADER}>
        <Icon icon={IconNames.REFRESH} /> Request Changes Confirmation
      </div>
      <div className={Classes.DIALOG_BODY}>
        Are you sure you want to request changes?
      </div>
      <DialogFooter closeDialog={closeDialog} submitDialog={submitDialog} />
    </Dialog>
  );
};
