import { Card } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import React from "react";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { evaluationStore } from "../../stores/EvaluationStore";
import { permissionStore } from "../../stores/PermissionStore";
import { fortifiedUserStore } from "../../stores/FortifiedUserStore";
import {
  Evaluation,
  EvaluationForm as EvaluationFormType,
} from "../../types/evaluation/Evaluation";
import { EvaluationActions } from "./EvaluationActions";
import { EvaluationForm } from "./EvaluationForm";
import ComplexCommercialEvaluationBuildingsCard from "./ComplexCommercialEvaluationBuilding/Buttons/ComplexCommercialEvaluationBuildingsCard";

export interface EvaluationFormWrapperProps {
  evaluation: Evaluation;
  formFilter: (form: EvaluationFormType) => boolean;
}

export const EvaluationFormWrapper: React.FC<EvaluationFormWrapperProps> = ({
  evaluation,
  formFilter,
}) => {
  return (
    <Observer>
      {() => {
        return (
          <div className={`evaluation-container__content`}>
            <EvaluationActions
              shouldDisplayLabel={
                !evaluationStore.doesEvaluationHaveFinalDetermination
              }
              step={evaluationStore.evaluation?.step}
              role={fortifiedUserStore.user?.role}
              // Approval params
              isApproveDisabled={!permissionStore.canApprove}
              approveDisabledTooltip={
                evaluationStore.isComplexCommercialEvaluationBlockedAtFinalApprovalByBuilding
                  ? "All of this evaluation's buildings must be eligible for approval before it can be approved"
                  : undefined
              }
              isRequestChangesDisabled={!permissionStore.canRequestChanges}
              requestChanges={() =>
                dialogsViewerStore.setIsAuditorRequestChangeDialogOpen(true)
              }
              isRejectDisabled={!permissionStore.canReject}
              reject={() =>
                dialogsViewerStore.setIsAuditorRejectionDialogOpen(true)
              }
              // Send For Review params
              shouldDisplaySendForReview={permissionStore.canViewSendForReview}
              isSendForReviewDisabled={!permissionStore.canSendForReview}
              sendForReview={async () =>
                dialogsViewerStore.setIsEvaluatorReviewSubmissionDialogOpen(
                  true
                )
              }
              // Send Forward params
              errors={evaluationStore.error}
              shouldDisplaySendForward={permissionStore.canViewSendForward}
              isSendForwardDisabled={!permissionStore.canSendForward}
              // Trainee Claim Evaluation params
              shouldDisplayTraineeClaimEvaluation={
                fortifiedUserStore.isAuditorTrainee &&
                evaluationStore.shouldShowTraineeAuditActionButtons &&
                !evaluationStore.isClaimedForTraineeAuditByCurrentUser
              }
              isTraineeClaimEvaluationDisabled={
                evaluationStore.isClaimedForTraineeAudit
              }
              // Trainee Send For Review params
              shouldDisplayTraineeSendForReview={
                fortifiedUserStore.isAuditorTrainee &&
                evaluationStore.shouldShowTraineeAuditActionButtons &&
                evaluationStore.isClaimedForTraineeAuditByCurrentUser
              }
              isTraineeSendForReviewDisabled={
                evaluationStore.isTraineeAuditActionByAdminRequired
              }
              // Trainee Return Changes Required params
              shouldDisplayTraineeReturnChangesRequired={
                fortifiedUserStore.isTraineeAuditAdmin &&
                evaluationStore.shouldShowTraineeAuditActionButtons &&
                evaluationStore.isClaimedForTraineeAudit
              }
              isTraineeReturnChangesRequiredDisabled={
                evaluationStore.isTraineeAuditActionByTraineeRequired
              }
            />
            <Card id="evaluation-form">
              <div>
                {evaluation.evaluationForms.filter(formFilter).map((form) => {
                  return (
                    <div key={form.id} id={form.id}>
                      {evaluationStore.isFortifiedHomeEvaluation && (
                        <h2 className="bp4-heading">
                          {form.evaluationItemTypes_AsString}
                        </h2>
                      )}
                      {
                        <EvaluationForm
                          evaluationForm={form}
                          updateEvaluationItemData={
                            evaluationStore.updateEvaluationItemData
                          }
                        />
                      }
                    </div>
                  );
                })}
                {evaluationStore.evaluation?.isComplexCommercialEvaluation && (
                  <ComplexCommercialEvaluationBuildingsCard />
                )}
              </div>
            </Card>
          </div>
        );
      }}
    </Observer>
  );
};
