import { IconNames } from "@blueprintjs/icons";
import { Button, NonIdealState } from "@blueprintjs/core";
import { errorStore, genericErrorMessage } from "./ErrorStore";

interface ErrorWrapperProps {
  logOutUser?: () => Promise<void>;
}

const ErrorWrapper: React.FC<ErrorWrapperProps> = (props) => {
  return (
    <>
      <NonIdealState
        icon={IconNames.ERROR}
        description={<>{errorStore.errorMessage || genericErrorMessage}</>}
      >
        <div className="error-page-signout">
          {props.logOutUser && (
            <Button
              className="signout-button"
              text="Sign Out"
              intent="primary"
              onClick={async () => {
                props.logOutUser && (await props.logOutUser());
              }}
            />
          )}
        </div>
      </NonIdealState>
    </>
  );
};

export default ErrorWrapper;
