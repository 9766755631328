import baseApiClient from "../baseApiClient";
import {
  PaymentCreditCompaniesReadDTO,
  PaymentCreditCreateDTO,
  PaymentCreditReadDTO,
  PaymentCreditUpdateDTO,
} from "./types";

class PaymentCreditApiClient {
  getPaymentCreditCompanies = async () => {
    return baseApiClient.get<PaymentCreditCompaniesReadDTO>(
      `payment-credits/available-companies`
    );
  };
  getPaymentCreditById = async (id: string) => {
    return baseApiClient.get<PaymentCreditReadDTO | undefined>(
      `payment-credits/${id}`
    );
  };

  updatePaymentCreditById = async (
    id: string,
    data: PaymentCreditUpdateDTO
  ) => {
    return baseApiClient.put<PaymentCreditReadDTO | undefined>(
      `payment-credits/${id}`,
      data
    );
  };

  createPaymentCredit = async (data: PaymentCreditCreateDTO) => {
    return await baseApiClient.post<{ paymentCredit: PaymentCreditCreateDTO }>(
      `payment-credits`,
      data
    );
  };
}

export const paymentCreditApiClient = new PaymentCreditApiClient();
