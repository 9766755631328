import { Intent } from "@blueprintjs/core";
import { dialogsViewerStore } from "../../../stores/DialogsViewerStore";
import { genericErrorMessage } from "../../../../common/Components/Error/ErrorStore";
import { evaluationStore } from "../../../stores/EvaluationStore";
import { EvaluationTypes } from "../../../types/evaluation/EvaluationTypes";
import { AppToaster } from "../../Toast/Toast";

export const redesignationFooterHelperText = (
  <>
    <br />
    <div>
      Please ensure the information matches the property you are looking to
      redesignate. If you are having trouble, please reach out to{" "}
      <a href="mailto:FORTIFIEDTechSupport@ibhs.org">
        FORTIFIEDTechSupport@ibhs.org
      </a>
    </div>
  </>
);

export const navigateToEvaluationForm = async (
  evaluationType: EvaluationTypes,
  parentEvaluationId?: string,
  evaluationExpirationDateType?: number
) => {
  // We must open the new tab synchronously so that the
  // browser doesn't flag it as a suspicious popup
  // source: https://www.yaplex.com/blog/avoid-browser-pop-up-blockers/
  const appWindow = window.open("", "_blank") as Window;

  const evaluation = await createEvaluation(
    evaluationType,
    parentEvaluationId,
    evaluationExpirationDateType
  );

  const newEvaluationUrl = evaluationStore.getNewEvaluationUrl(
    evaluationType,
    evaluation
  );

  if (newEvaluationUrl) {
    appWindow.location = newEvaluationUrl;
  }
};

export const createEvaluation = (
  evaluationType: EvaluationTypes,
  parentEvaluationId?: string,
  evaluationExpirationDateType?: number
) => {
  switch (evaluationType) {
    case EvaluationTypes.Home:
      return evaluationStore.createHomeEvaluation();
    case EvaluationTypes.Commercial:
      return evaluationStore.createEvaluation();
    case EvaluationTypes.HomeReDesignation:
      return evaluationStore.createHomeRedesignationEvaluation(
        parentEvaluationId as string,
        evaluationExpirationDateType as number
      );
    case EvaluationTypes.CommercialReDesignation:
      return evaluationStore.createCommercialRedesignationEvaluation(
        parentEvaluationId as string
      );
    default:
      throw new Error(
        `Cannot create evaluation with unexpected evaluationType: ${evaluationType}`
      );
  }
};

export const openRedesignationFullyExpiredConfirmation = (
  setIsSaving: (isSaving: boolean) => void
) => {
  setIsSaving(true);
  dialogsViewerStore.confirm({
    shouldHideCancelButton: true,
    confirmButtonText: "Close",
    confirmButtonIntent: "danger",
    confirmHeaderText: "Warning - Redesignation Expired",
    content: (
      <>
        This Redesignation is Fully Expired and cannot be submitted. Please
        create a new Evaluation.
      </>
    ),
    onClose: () => {
      setIsSaving(false);
    },
    onConfirm: async () => {
      const result = {
        isSuccess: true,
        error: undefined,
      };
      setIsSaving(false);
      return result;
    },
  });
};

export const openRedesignationNotYetApprovedConfirmation = (
  submitRedesignation: () => Promise<{ isSuccess: boolean; error: any }>,
  setIsSaving: (isSaving: boolean) => void
) => {
  setIsSaving(true);
  dialogsViewerStore.confirm({
    confirmButtonText: "Submit",
    cancelButtonText: "Cancel",
    confirmHeaderText: "Warning",
    content: (
      <>
        This address already has a re-designation in progress for the current
        renewal period (within 5 years from its expiration date). Are you sure
        you want to proceed?
      </>
    ),
    onClose: () => {
      setIsSaving(false);
    },
    onConfirm: async () => {
      return submitRedesignation();
    },
  });
};

export const openRedesignationIsEarlyConfirmation = (
  submitRedesignation: () => Promise<{ isSuccess: boolean; error: any }>,
  setIsSaving: (isSaving: boolean) => void
) => {
  setIsSaving(true);
  dialogsViewerStore.confirm({
    confirmButtonText: "Submit",
    cancelButtonText: "Cancel",
    confirmHeaderText: "Warning",
    content: (
      <>
        There is already an active (approved and not expired) re-designation
        existing for this address. Are you sure you want to proceed?
      </>
    ),
    onClose: () => {
      setIsSaving(false);
    },
    onConfirm: async () => {
      return submitRedesignation();
    },
  });
};

export const submitNewEvaluation = async (
  evaluationType: EvaluationTypes,
  parentEvaluationId: string,
  submitDialog: () => Promise<void>,
  setIsSaving: (isSaving: boolean) => void,
  closeDialog: () => void,
  evaluationExpirationDateType?: number
) => {
  const result = { isSuccess: true, error: undefined };

  await navigateToEvaluationForm(
    evaluationType,
    parentEvaluationId,
    evaluationExpirationDateType
  )
    .then(() => {
      submitDialog();
    })
    .catch((error) => {
      result.isSuccess = false;
      result.error = error;

      AppToaster.show({
        message: <div>{genericErrorMessage}</div>,
        intent: Intent.DANGER,
      });
    })
    .finally(() => {
      setIsSaving(false);
      closeDialog();
    });
  return result;
};
