import {
  SmartAddressFieldProps,
  FieldSchemaFormBuilderV2,
  NumericFieldProps,
  PhoneNumberFieldProps,
  SchemaFactoryV2,
  SingleCheckboxFieldProps,
  TextAreaFieldProps,
  TextFieldProps,
  EmailFieldProps,
  EnumSingleSelectFieldProps,
} from "@ucl/library";
import {
  BaseFieldProps,
  PhoneNumberProps,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { addressApiClient } from "../../../foritfied/lib/apiClients/address/addressApiClient";
import backOfHomeExample1 from "../../assets/ExamplePhotos/BackOfHomeExample1.png";
import backOfHomeExample2 from "../../assets/ExamplePhotos/BackOfHomeExample2.png";
import frontOfHomeExample1 from "../../assets/ExamplePhotos/FrontOfHomeExample1.png";
import frontOfHomeExample2 from "../../assets/ExamplePhotos/FrontOfHomeExample2.png";
import leftSideOfHomeExample1 from "../../assets/ExamplePhotos/LeftSideOfHomeExample1.png";
import leftSideOfHomeExample2 from "../../assets/ExamplePhotos/LeftSideOfHomeExample2.png";
import rightSideOfHomeExample1 from "../../assets/ExamplePhotos/RightSideOfHomeExample1.png";
import rightSideOfHomeExample2 from "../../assets/ExamplePhotos/RightSideOfHomeExample2.png";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { HowDidYouHearAboutUs, YesOrNoSelection } from "../../types/FormFields";
import { EvaluationFormModel } from "../EvaluationFormModel";
import { get } from "lodash";
import { WildfireEvaluationSteps } from "../../../foritfied/types/evaluation/Evaluation";

const modelName = "Evaluation";
const subModelName = "WildfireApplication";
const formName = "ApplicationForm";
const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Property Information
const propertyInformationFields = (form: EvaluationFormModel) => [
  buildFieldKey("HowDidYouHearAboutUs"),
  {
    fieldKey: buildFieldKey("HowDidYouHearAboutUsOther"),
    hidden:
      form.wildfireApplication.applicationForm.howDidYouHearAboutUs !=
      HowDidYouHearAboutUs.Other,
  } as TextFieldProps,

  buildFieldKey("OwnershipAttestation"),

  buildFieldKey("HasCoOwner"),
  {
    fieldKey: buildFieldKey("CoOwnerFirstName"),
    hidden:
      form.wildfireApplication.applicationForm.hasCoOwner !=
      YesOrNoSelection.Yes,
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("CoOwnerLastName"),
    hidden:
      form.wildfireApplication.applicationForm.hasCoOwner !=
      YesOrNoSelection.Yes,
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("CoOwnerEmail"),
    hidden:
      form.wildfireApplication.applicationForm.hasCoOwner !=
      YesOrNoSelection.Yes,
  } as EmailFieldProps,
  {
    fieldKey: buildFieldKey("CoOwnerPhoneNumber"),
    hidden:
      form.wildfireApplication.applicationForm.hasCoOwner !=
      YesOrNoSelection.Yes,
  } as PhoneNumberProps,

  buildFieldKey("HasSecondaryContact"),
  {
    fieldKey: buildFieldKey("SecondaryContactFirstName"),
    hidden:
      form.wildfireApplication.applicationForm.hasSecondaryContact !=
      YesOrNoSelection.Yes,
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("SecondaryContactLastName"),
    hidden:
      form.wildfireApplication.applicationForm.hasSecondaryContact !=
      YesOrNoSelection.Yes,
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("SecondaryContactEmail"),
    hidden:
      form.wildfireApplication.applicationForm.hasSecondaryContact !=
      YesOrNoSelection.Yes,
  } as EmailFieldProps,
  {
    fieldKey: buildFieldKey("SecondaryContactPhoneNumber"),
    hidden:
      form.wildfireApplication.applicationForm.hasSecondaryContact !=
      YesOrNoSelection.Yes,
  } as PhoneNumberProps,

  buildFieldKey("RoofInstallationYearKnown"),
  {
    fieldKey: buildFieldKey("RoofInstallationYear"),
    min: 1900,
    max: new Date().getFullYear(),
    format: "0000.",
    validateDecimalOnType: true,
    decimals: 0,
    hidden:
      form.wildfireApplication.applicationForm.roofInstallationYearKnown !=
      YesOrNoSelection.Yes,
  } as NumericFieldProps,

  buildFieldKey("HomeConstructionYearKnown"),
  {
    fieldKey: buildFieldKey("HomeConstructionYear"),
    min: 1900,
    max: new Date().getFullYear(),
    format: "0000.",
    validateDecimalOnType: true,
    decimals: 0,
    hidden:
      form.wildfireApplication.applicationForm.homeConstructionYearKnown !=
      YesOrNoSelection.Yes,
  } as NumericFieldProps,

  {
    fieldKey: buildFieldKey("StreetAddress"),
    getAddressesByQuery: addressApiClient.getFilteredAddressesByQuery,
    getAddressByQuery: addressApiClient.getAddressByQuery,
    isSmartOnly: true,
  } as SmartAddressFieldProps,

  buildFieldKey("HasHotTub"),
  buildFieldKey("HasStationaryPropaneTank"),

  buildFieldKey("HasGate"),
  {
    fieldKey: buildFieldKey("GateCode"),
    hidden:
      form.wildfireApplication.applicationForm.hasGate != YesOrNoSelection.Yes,
  } as TextAreaFieldProps,

  buildFieldKey("HasPets"),
  {
    fieldKey: buildFieldKey("PetRestraintAttestation"),
    hidden:
      form.wildfireApplication.applicationForm.hasPets != YesOrNoSelection.Yes,
  } as SingleCheckboxFieldProps,

  {
    fieldKey: buildFieldKey("NeedsAppointment"),
    disabled:
      form.wildfireApplication.applicationForm.hasPets === YesOrNoSelection.Yes,
    value:
      form.wildfireApplication.applicationForm.hasPets === YesOrNoSelection.Yes
        ? YesOrNoSelection.Yes
        : form.wildfireApplication.applicationForm.needsAppointment,
  } as SingleCheckboxFieldProps,
  {
    fieldKey: buildFieldKey("AppointmentPhoneNumber"),
    hidden:
      form.wildfireApplication.applicationForm.needsAppointment !=
        YesOrNoSelection.Yes &&
      form.wildfireApplication.applicationForm.hasPets != YesOrNoSelection.Yes,
  } as PhoneNumberFieldProps,

  buildFieldKey("OtherPropertyNotes"),
];
const propertyInformationRequiredFields = [
  buildFieldKey("HowDidYouHearAboutUs"),
  buildFieldKey("HowDidYouHearAboutUsOther"),
  buildFieldKey("OwnershipAttestation"),
  buildFieldKey("HasCoOwner"),
  buildFieldKey("CoOwnerFirstName"),
  buildFieldKey("CoOwnerLastName"),
  buildFieldKey("CoOwnerEmail"),
  buildFieldKey("CoOwnerPhoneNumber"),
  buildFieldKey("HasSecondaryContact"),
  buildFieldKey("SecondaryContactFirstName"),
  buildFieldKey("SecondaryContactLastName"),
  buildFieldKey("SecondaryContactEmail"),
  buildFieldKey("SecondaryContactPhoneNumber"),
  buildFieldKey("RoofInstallationYearKnown"),
  buildFieldKey("RoofInstallationYear"),
  buildFieldKey("HomeConstructionYearKnown"),
  buildFieldKey("HomeConstructionYear"),
  buildFieldKey("StreetAddress"),
  buildFieldKey("HasHotTub"),
  buildFieldKey("HasStationaryPropaneTank"),
  buildFieldKey("HasGate"),
  buildFieldKey("GateCode"),
  buildFieldKey("HasPets"),
  buildFieldKey("PetRestraintAttestation"),
  buildFieldKey("NeedsAppointment"),
  buildFieldKey("AppointmentPhoneNumber"),
];

export const applicationPropertyInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        propertyInformationFields(form),
        onValueChange,
        form,
        errors,
        propertyInformationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Part 2: Service Options
const serviceOptionsFields = (form: EvaluationFormModel) => [
  {
    fieldKey: buildFieldKey("ServiceType"),
    disabled:
      form.wildfireApplication.applicationForm.canOnlyHaveSelfService === true,
  } as EnumSingleSelectFieldProps,
];

const serviceOptionsRequiredFields = [buildFieldKey("ServiceType")];

export const applicationServiceOptionsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        serviceOptionsFields(form),
        onValueChange,
        form,
        errors,
        serviceOptionsRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Part 3: Eligibility
const eligibilityFields = [
  buildFieldKey("SelfServiceWorkAttestation"),
  buildFieldKey("SelfServicePhotoAttestation"),
  buildFieldKey("SelfServiceNonRefundabilityAttestation"),
];
const eligibilityRequiredFields = [
  buildFieldKey("SelfServiceWorkAttestation"),
  buildFieldKey("SelfServicePhotoAttestation"),
  buildFieldKey("SelfServiceNonRefundabilityAttestation"),
];

export const applicationEligibilityBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        eligibilityFields,
        onValueChange,
        form,
        errors,
        eligibilityRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Part 4: Photo Requirements
const photoRequirementsFields = (
  form: EvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("FrontPhotoFolder"),
    examplePhoto: frontOfHomeExample1,
    examplePhoto2: frontOfHomeExample2,
    defaultToUpload: true,
    hasLimitedFileTypes: true,
    errorMessages: get(errors, "frontPhotoFolder.FileCount"),
    // If the evaluation was migrated, all file uploaders in the application form should be optional since all
    // migrated applications will have the photos already uploaded to the legacy folder.
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire",
    canDelete:
      form.wildfireApplication.step ===
        WildfireEvaluationSteps.WildfireApplication ||
      form.wildfireApplication.step ===
        WildfireEvaluationSteps.WildfireApplicationPayment,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("RightSidePhotoFolder"),
    examplePhoto: rightSideOfHomeExample1,
    examplePhoto2: rightSideOfHomeExample2,
    defaultToUpload: true,
    hasLimitedFileTypes: true,
    errorMessages: get(errors, "rightSidePhotoFolder.FileCount"),
    // If the evaluation was migrated, all file uploaders in the application form should be optional since all
    // migrated applications will have the photos already uploaded to the legacy folder.
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire",
    canDelete:
      form.wildfireApplication.step ===
        WildfireEvaluationSteps.WildfireApplication ||
      form.wildfireApplication.step ===
        WildfireEvaluationSteps.WildfireApplicationPayment,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("BackPhotoFolder"),
    examplePhoto: backOfHomeExample1,
    examplePhoto2: backOfHomeExample2,
    defaultToUpload: true,
    hasLimitedFileTypes: true,
    errorMessages: get(errors, "backPhotoFolder.FileCount"),
    // If the evaluation was migrated, all file uploaders in the application form should be optional since all
    // migrated applications will have the photos already uploaded to the legacy folder.
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire",
    canDelete:
      form.wildfireApplication.step ===
        WildfireEvaluationSteps.WildfireApplication ||
      form.wildfireApplication.step ===
        WildfireEvaluationSteps.WildfireApplicationPayment,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("LeftSidePhotoFolder"),
    examplePhoto: leftSideOfHomeExample1,
    examplePhoto2: leftSideOfHomeExample2,
    defaultToUpload: true,
    hasLimitedFileTypes: true,
    errorMessages: get(errors, "leftSidePhotoFolder.FileCount"),
    // If the evaluation was migrated, all file uploaders in the application form should be optional since all
    // migrated applications will have the photos already uploaded to the legacy folder.
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire",
    canDelete:
      form.wildfireApplication.step ===
        WildfireEvaluationSteps.WildfireApplication ||
      form.wildfireApplication.step ===
        WildfireEvaluationSteps.WildfireApplicationPayment,
  } as WildfireBoxContentExplorerProps,
];
const photoRequirementsRequiredFields: string[] = [
  buildFieldKey("FrontPhotoFolder"),
  buildFieldKey("RightSidePhotoFolder"),
  buildFieldKey("BackPhotoFolder"),
  buildFieldKey("LeftSidePhotoFolder"),
];

export const applicationPhotoRequirementsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        photoRequirementsFields(form, errors),
        onValueChange,
        form,
        errors,
        photoRequirementsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Full form builder with all parts included
export const applicationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        [
          ...propertyInformationFields(form),
          ...serviceOptionsFields(form),
          ...eligibilityFields,
          ...photoRequirementsFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        [
          ...propertyInformationRequiredFields,
          ...serviceOptionsRequiredFields,
          ...eligibilityRequiredFields,
          ...photoRequirementsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const wieApplicationFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        [
          ...propertyInformationFields(form),
          ...serviceOptionsFields(form),
          ...eligibilityFields,
          // Remove example photos and default to upload from Photo Requirements fields
          ...photoRequirementsFields(form, errors).map((field) => {
            const { examplePhoto, defaultToUpload, ...rest } = field;
            return rest;
          }),
        ],
        onValueChange,
        form,
        errors,
        [
          ...propertyInformationRequiredFields,
          ...serviceOptionsRequiredFields,
          ...eligibilityRequiredFields,
          ...photoRequirementsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};
