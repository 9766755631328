import { createRef, useEffect, useState } from "react";
import { multifamilyEvaluationAPIClient } from "../../lib/apiClients/multifamilyEvaluationAPIClient";
import { ComplexMultifamilyEvaluationBuildingsCardV2Props } from "./ComplexMultifamilyEvaluationBuildingsCardV2";

export const useComplexMultifamilyEvaluationBuildingsCardV2 = (
  props: ComplexMultifamilyEvaluationBuildingsCardV2Props
) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [buildingIds, setBuildingIds] = useState<string[]>([]);

  useEffect(() => {
    getBuildingIds();
  }, []);

  const getBuildingIds = async () => {
    setIsFetching(true);
    const buildingIds =
      await multifamilyEvaluationAPIClient.getComplexCommercialEvaluationBuildingIds(
        props.evaluationId
      );

    buildingIds?.forEach((id) => {
      if (!props.buildingFormRefs.current.has(id)) {
        props.buildingFormRefs.current.set(id, createRef());
      }
    });

    setBuildingIds(buildingIds);
    setIsFetching(false);
  };

  const createBuilding = async (complexCommercialEvaluationId: string) => {
    setIsSaving(true);
    await multifamilyEvaluationAPIClient
      .createComplexCommercialEvaluationBuilding(complexCommercialEvaluationId)
      .then(async () => {
        await getBuildingIds();
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return {
    createBuilding,
    isSaving,
    isFetching,
    buildingIds,
    getBuildingIds,
  };
};
