import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import "./styles.scss";
import { evaluationStore } from "../../../../stores/EvaluationStore";
import { RelativityTooltip } from "@ucl/library";

export interface ComplexCommercialEvaluationBuildingFormProps {
  isLoading: boolean;
  onClick?: () => void;
  buttonText?: string;
  id?: string;
}

const AddBuildingsButton: React.FC<
  ComplexCommercialEvaluationBuildingFormProps
> = (props) => {
  const buttonText = props.buttonText || "Add Building";

  return (
    <RelativityTooltip
      placement="auto"
      compact={true}
      shouldShowTooltip={true}
      interactionKind={"hover"}
      content={
        "Note: Users can add Buildings up until the “Field Inspections” Step."
      }
    >
      <Button
        id={props.id}
        text={buttonText}
        large
        disabled={
          !evaluationStore.isComplexCommercialEvaluationBuildingFormAbleToBeModified
        }
        loading={props.isLoading}
        intent={Intent.PRIMARY}
        icon={IconNames.ADD}
        onClick={props.onClick}
      />
    </RelativityTooltip>
  );
};

export default AddBuildingsButton;
