import { PrimaryCard } from "@ucl/library";
import React from "react";
import fortified_logo_home from "../../assets/fortified_logo_home.png";
import { Button } from "@blueprintjs/core";
import "./styles.scss";

interface ApplicantLandingPageProps {
  productType: string;
  logOutUser: () => Promise<void>;
}

const WelcomeApplicantComponent: React.FC<ApplicantLandingPageProps> = (
  props
) => {
  return (
    <div className="welcome-applicant-container">
      <PrimaryCard title={props.productType}>
        <div className="welcome-applicant-container__body">
          <div>
            <img src={fortified_logo_home} alt="Fortified Home" />
          </div>
          <div className="welcome-applicant-container-help">
            <h3>
              Thank you for creating your FOCUS credentials. Please submit a
              support request below to gain access.
            </h3>
            <a
              href="https://fortifiedhome.org/focus-support-request/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Submit Fortified Home Support Request
            </a>
            <div>
              {props.logOutUser && (
                <Button
                  className="signout-button"
                  text="Sign Out"
                  intent="primary"
                  onClick={async () => {
                    props.logOutUser && (await props.logOutUser());
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </PrimaryCard>
    </div>
  );
};

export default WelcomeApplicantComponent;
