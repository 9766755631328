import { Checkbox, FormGroup } from "@blueprintjs/core";
import { isArray } from "lodash";
import React from "react";
import { FormstackCheckbox } from "../../../types/formstack/fieldValueTypes/FormstackCheckbox";
import { CheckboxField as IterationEngineCheckboxField } from "../../../types/viewModels/IterationEngineViewModel";
import { BaseFieldProps } from "../types";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";

export interface CheckboxFieldProps
  extends BaseFieldProps<FormstackCheckbox>,
    Omit<IterationEngineCheckboxField, "id" | "sortOrder"> {}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  fieldId,
  label,
  value,
  optionValues,
  disabled,
  onSubmit,
  isEmpty,
  isSelectedField,
  hasActiveChangeRequest,
  isSelectedChangeRequest,
  isResovledChangeRequest,
  hasActiveInternalComment,
  isExternalView,
}) => {
  const [localValues, setLocalValues] =
    React.useState<FormstackCheckbox>(value);

  if (isEmpty(localValues)) {
    return null;
  }
  return (
    <FormGroup className="checkbox-field">
      <SelectableLabel
        name={label}
        fieldId={fieldId}
        value={localValues}
        isSelectedField={isSelectedField}
        hasActiveChangeRequest={hasActiveChangeRequest}
        isResovledChangeRequest={isResovledChangeRequest}
        isSelectedChangeRequest={isSelectedChangeRequest}
        hasActiveInternalComment={hasActiveInternalComment}
        shouldDisableFieldLabelClick={isExternalView}
      />
      {(isArray(localValues) || typeof localValues === "string") &&
        optionValues
          .filter((option) => !!option.value)
          .map((option, idx) => {
            const checked =
              localValues !== "None" &&
              localValues.some((arg) => arg === option.value);
            return (
              <Checkbox
                key={idx}
                name={option.label}
                label={option.label}
                disabled={disabled}
                checked={checked}
                onChange={() => {
                  let newLocalValues: FormstackCheckbox;
                  if (checked) {
                    const filteredValues = localValues.filter(
                      (localValue) => localValue != option.value
                    );
                    newLocalValues =
                      filteredValues.length > 0 ? filteredValues : [];
                  } else {
                    newLocalValues =
                      localValues !== "None"
                        ? [...localValues, option.value]
                        : [option.value];
                  }
                  setLocalValues(newLocalValues);
                  onSubmit(JSON.stringify(newLocalValues));
                }}
              />
            );
          })}
    </FormGroup>
  );
};
