import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { HomeBasicInformationFormParts } from "../../../common/types/BaseHomeEvaluationBasicInformationForm";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationBasicInformationFormModel } from "../../types/HomeStandardEvaluationBasicInformationFormModel";

class HomeStandardEvaluationBasicInformationAPIClient {
  updateHomeStandardBasicInformationForm = async (
    evaluationId: string,
    basicInformationForm: HomeStandardEvaluationBasicInformationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    console.log("basicInformationForm", basicInformationForm);
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/basic-information-form/${evaluationId}`,
      {
        basicInformationForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardBasicInformationForm = async (
    evaluationId: string,
    basicInformationForm: HomeStandardEvaluationBasicInformationFormModel,
    basicInformationFormPart?: HomeBasicInformationFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const basicInformationFormPartValue = (
      basicInformationFormPart ??
      (Object.values(
        HomeBasicInformationFormParts
      ).pop() as HomeBasicInformationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/basic-information-form/${evaluationId}/${basicInformationFormPartValue}`,
      basicInformationForm
    );
  };
}

export const homeStandardEvaluationBasicInformationAPIClient =
  new HomeStandardEvaluationBasicInformationAPIClient();
