import { ChangeRequest } from "../../../types/evaluation/ChangeRequest";
import baseApiClient from "../baseApiClient";

class ChangeRequestApiClient {
  getChangeRequestsByEvaluation = async (evaluationId: string) => {
    return await baseApiClient.get<ChangeRequest[]>(
      `evaluation/${evaluationId}/change-request`
    );
  };

  resolveChangeRequest = async (
    evaluationId: string,
    changeRequestId: string
  ) => {
    return await baseApiClient.put<void>(
      `evaluation/${evaluationId}/change-request/${changeRequestId}/status/resolution`
    );
  };

  unresolveChangeRequest = async (
    evaluationId: string,
    changeRequestId: string
  ) => {
    return await baseApiClient.put<void>(
      `evaluation/${evaluationId}/change-request/${changeRequestId}/status/unresolution`
    );
  };
}

export const changeRequestApiClient = new ChangeRequestApiClient();
