import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { dialogsViewerStore } from "../../../stores/DialogsViewerStore";
import { evaluationStore } from "../../../stores/EvaluationStore";
import { useState } from "react";

interface PostDesignationFilesActionButtonProps {
  evaluationId: string | undefined;
  rootBoxFolderId: string | undefined;
  postDesignationBoxFolderId: string | undefined;
}

const createPostDesignationBoxFolder = async () => {
  if (
    evaluationStore.evaluation &&
    !evaluationStore.evaluation.postDesignationBoxFolderId
  ) {
    // Make the API call to create the folder here
    await evaluationStore.createPostDesignationBoxFolder();
  }
};

const PostDesignationFilesActionButton: React.FC<
  PostDesignationFilesActionButtonProps
> = (props) => {
  const [isLoading, setIsloading] = useState(false);

  return (
    <Button
      text="Post Designation Files"
      intent={Intent.PRIMARY}
      icon={IconNames.Document}
      disabled={false}
      loading={isLoading}
      minimal
      onClick={async () => {
        if (!props.postDesignationBoxFolderId) {
          setIsloading(true);
          await createPostDesignationBoxFolder();
          setIsloading(false);
        }

        dialogsViewerStore.setIsPostDesignationFilesDialogOpen(true, {
          evaluationId: props.evaluationId,
          rootBoxFolderId: props.rootBoxFolderId,
          postDesignationBoxFolderId: !!props.postDesignationBoxFolderId
            ? props.postDesignationBoxFolderId
            : evaluationStore.evaluation?.postDesignationBoxFolderId,
        });
      }}
    />
  );
};

export default PostDesignationFilesActionButton;
