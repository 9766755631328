import { Observer } from "mobx-react";
import { Button, Divider } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  EvaluationBid,
  NewEvaluationBid,
} from "../../../types/evaluation/EvaluationBid";
import { useState } from "react";
import { evaluationBidApiClient } from "../../../lib/apiClients/evaluation/evaluationBidApiClient";
import { showSuccessAppToasterWithTitle } from "../../Toast/Toast";
import { ReadOnlyField } from "@ucl/library";
interface EvaluationBidCompanySelectContainerProps {
  evaluationBid?: NewEvaluationBid | EvaluationBid;
  evaluationId: string;
  refreshBids: () => void;
}

export const EvaluationBidCompanySelectContainer: React.FC<
  EvaluationBidCompanySelectContainerProps
> = (props) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  return (
    <Observer>
      {() => {
        return (
          <>
            {props.evaluationBid && (
              <>
                <div className="bid-container">
                  <ReadOnlyField
                    label={"Evaluation Company"}
                    value={props.evaluationBid.evaluationCompany_AsString}
                    type={"ReadOnly"}
                  />
                  {!props.evaluationBid.id ? (
                    <div className="send-bid-request-button-container">
                      <Button
                        text={"Send Request"}
                        intent={"primary"}
                        loading={isSaving}
                        minimal
                        icon={IconNames.DrawerLeft}
                        onClick={async () => {
                          if (props.evaluationBid) {
                            setIsSaving(true);
                            await evaluationBidApiClient
                              .createEvaluationBid(
                                props.evaluationId,
                                props.evaluationBid
                              )
                              .then(() => {
                                showSuccessAppToasterWithTitle(
                                  "Your bid has been sent to the assigned Evaluator - they will reach out to you shortly.",
                                  "Request Sent"
                                );
                              })
                              .finally(() => {
                                setIsSaving(false);
                                props.refreshBids();
                              });
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <div className="send-bid-request-button-container">
                      <Button
                        text={"Resend Request"}
                        intent={"primary"}
                        minimal
                        loading={isSaving}
                        icon={IconNames.Repeat}
                        onClick={async () => {
                          if (props.evaluationBid?.id) {
                            setIsSaving(true);
                            await evaluationBidApiClient
                              .resendEvaluationBid(
                                props.evaluationId,
                                props.evaluationBid.id,
                                props.evaluationBid as EvaluationBid
                              )
                              .then(() => {
                                showSuccessAppToasterWithTitle(
                                  "Your bid has been re-sent to the assigned Evaluator - they will reach out to you shortly.",
                                  "Request Re-Sent"
                                );
                              })
                              .finally(() => {
                                setIsSaving(false);
                                props.refreshBids();
                              });
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
                <Divider />
              </>
            )}
          </>
        );
      }}
    </Observer>
  );
};
