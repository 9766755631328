import { FieldFormV2, FieldSchemaFormBuilderV2 } from "@ucl/library";
import { Button, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useNavigate } from "react-router";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import "../styles.scss";
import { EvaluationFormModel } from "../../../forms/EvaluationFormModel";
import { LandscapeFieldEvaluationFormParts } from "../../../forms/LandscapeFieldEvaluationForm/LandscapeFieldEvaluationFormModel";
import useLandscapeFieldEvaluationForm from "./useLandscapeFieldEvaluationForm";
import classnames from "classnames";
import { HomeExteriorFieldEvaluationFormParts } from "../../../forms/HomeExteriorFieldEvaluationForm/HomeExteriorFieldEvaluationFormModel";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";

export interface LandscapeFieldEvaluationFormProps {
  wildfireEvaluationId: string;
  isWiePage?: boolean;
  formPart?: LandscapeFieldEvaluationFormParts;
  formModel?: EvaluationFormModel;
  setFormModel?: (model: EvaluationFormModel) => void;
  showFormControls?: boolean;
  onFormSave?: (fieldKey: string, value: any) => Promise<void>;
  disableForm?: boolean;
  onInitialized?: () => void;
  setHasUnsavedChanges: (value: boolean) => void;
}

export const LandscapeFieldEvaluationForm = (
  props: LandscapeFieldEvaluationFormProps
) => {
  const navigate = useNavigate();

  const {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    landscapeFieldEvaluationFormModel,
    setShouldSubmit,
    isFirstPartOfForm,
    isLastPartOfForm,
    getLandscapeFieldEvaluationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
  } = useLandscapeFieldEvaluationForm(props);

  const noBuilders = !builders && !wieBuilders;

  return (
    <div className="wildfire-form-container">
      {noBuilders || isLoading ? (
        <Loading />
      ) : !landscapeFieldEvaluationFormModel ? (
        <NonIdealState />
      ) : (
        <>
          <FieldFormV2<EvaluationFormModel>
            isDisabled={isSubmitting || props.disableForm}
            value={landscapeFieldEvaluationFormModel}
            fieldSchemaFormBuilder={
              getLandscapeFieldEvaluationFormPartBuilder() as FieldSchemaFormBuilderV2<EvaluationFormModel>
            }
            onFormSubmit={async (form) => handleFormSubmit(form)}
            ref={formRef}
            onFieldChanged={async (form, value, fieldProps) => {
              await onFormFieldChange(form, value, fieldProps);
            }}
          />

          {(props.showFormControls ?? true) && (
            <div className="wildfire-form-footer">
              <Button
                className={
                  "wildfire-form-back-button " +
                  "wildfire-form-navigation-button"
                }
                text="Back"
                icon={IconNames.ARROW_LEFT}
                onClick={() => {
                  if (isFirstPartOfForm) {
                    // Navigate to the last part of the previous form
                    navigate(
                      `${wildfireRoutePrefix}/${
                        props.wildfireEvaluationId
                      }/home-exterior-field-evaluation-form/${Object.values(
                        HomeExteriorFieldEvaluationFormParts
                      )
                        ?.pop()
                        ?.valueOf()}`
                    );
                  } else {
                    // Navigate to the previous part of the form
                    navigate(
                      `${wildfireRoutePrefix}/${
                        props.wildfireEvaluationId
                      }/landscape-field-evaluation-form/${Object.values(
                        LandscapeFieldEvaluationFormParts
                      )[
                        Object.values(
                          LandscapeFieldEvaluationFormParts
                        ).indexOf(props.formPart!) - 1
                      ].valueOf()}`
                    );
                  }
                }}
              />
              <Button
                className={classnames({
                  "wildfire-form-submit-button": isLastPartOfForm,
                  "wildfire-form-continue-button": !isLastPartOfForm,
                  "wildfire-form-navigation-button": !isLastPartOfForm,
                })}
                text={isLastPartOfForm ? "Submit" : "Continue"}
                rightIcon={
                  isLastPartOfForm ? IconNames.TICK : IconNames.ARROW_RIGHT
                }
                disabled={isSubmitting || props.disableForm}
                loading={isSubmitting}
                onClick={() => {
                  if (formRef.current) {
                    props.setHasUnsavedChanges(false);
                    setShouldSubmit(true);
                    setIsSubmitting(true);
                    formRef.current.submit();
                  }
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LandscapeFieldEvaluationForm;
