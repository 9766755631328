import { debounce } from "lodash";
import { action, makeObservable, observable } from "mobx";
import { wildfireUserApiClient } from "../lib/user/wildfireUserApiClient";

class BoxTokenStore {
  public boxToken?: string;
  public lastUpdatedAt?: number;
  public refreshingToken = false;
  private tokenRefreshPromise?: Promise<void>;

  constructor() {
    makeObservable(this, {
      boxToken: observable,
      init: action,
      refreshingToken: observable,
    });
  }

  getBoxAccessToken = debounce(async () => {
    // If the token is not valid or a refresh is in progress, start a new refresh
    if (!this.isTokenValid || this.tokenRefreshPromise) {
      this.tokenRefreshPromise = this.refreshBoxToken();
      await this.tokenRefreshPromise;
    }
    return this.boxToken;
  }, 200).bind(this);

  public async init() {
    if (!this.isTokenValid) {
      await this.getBoxAccessToken();
    }
  }

  public async refreshBoxToken() {
    if (this.refreshingToken) {
      return this.tokenRefreshPromise;
    }

    this.refreshingToken = true;

    this.tokenRefreshPromise = new Promise(async (resolve, reject) => {
      try {
        this.boxToken = await wildfireUserApiClient.getBoxToken();
        this.lastUpdatedAt = new Date().getTime();
        resolve();
      } catch (error) {
        console.error("BOX token refresh error:", error);
        reject(error);
      } finally {
        this.refreshingToken = false;
      }
    });

    return this.tokenRefreshPromise;
  }

  get isTokenValid() {
    return (
      !!this.boxToken &&
      !!this.lastUpdatedAt &&
      this.lastUpdatedAt >= new Date().getTime() - 1800000
    );
  }
}

export const boxTokenStore = new BoxTokenStore();
