import { Intent, Position, Toaster } from "@blueprintjs/core";

/** Singleton toaster instance. Create separate instances for different options. */
export const AppToaster = Toaster.create({
  position: Position.TOP,
});
/** Create a Success AppToaster Notification */
export const showSuccessAppToaster = (successActionName: string) => {
  return AppToaster.show({
    message: (
      <div>
        <h3>Success!</h3>
        {successActionName}
      </div>
    ),
    intent: Intent.SUCCESS,
  });
};

export const showSuccessAppToasterWithTitle = (
  successActionName: string,
  title: string
) => {
  return AppToaster.show({
    message: (
      <div>
        <h3>{title}</h3>
        {successActionName}
      </div>
    ),
    intent: Intent.SUCCESS,
  });
};

export const showErrorAppToaster = (errorActionName: string) => {
  return AppToaster.show({
    message: (
      <div>
        <h3>An Error has occured</h3>
        {errorActionName}
      </div>
    ),
    intent: Intent.DANGER,
  });
};
