import { Icon } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";
import {
  Column,
  ColumnModel,
  GridComponent,
} from "@syncfusion/ej2-react-grids";
import { dialogStore } from "@ucl/library";
import { get } from "lodash";
import { getAuditorNotesUpdateDialogProps } from "../../../Dialogs/AuditorNotesUpdate/AuditorNotesUpdateDialog";

interface WildfireAuditorGridClickableIconColumnOptions extends ColumnModel {
  columnName: string;
  icon: IconName;
}

export default class WildfireAuditorGridClickableIconColumn extends Column {
  constructor(opts: WildfireAuditorGridClickableIconColumnOptions) {
    const { columnName, icon, ...superOpts } = opts;
    super({
      ...superOpts,
      template: ((model: Record<string, any>) => {
        const auditorNotes = model[columnName];
        const dialogId = `${model["fortifiedId"]}_auditor_notes_dialog`;
        const evaluationId = model["id"];
        const grid = get(model, "column.parent") as GridComponent;

        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              dialogStore.openDialog(
                getAuditorNotesUpdateDialogProps(
                  dialogId,
                  evaluationId,
                  auditorNotes,
                  () => {
                    grid.refresh();
                  }
                )
              );
            }}
          >
            <Icon className="base-grid-icon" icon={icon}></Icon>
            {auditorNotes}
          </div>
        );
      }) as any,
    });
  }
}
