import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { FormOverrides } from "../customHooks/useFCFMFFieldSchemaFactory";
import { ChangeRequestStatus } from "../../../../foritfied/types/evaluation/ChangeRequest";

export const transformFieldsForIterationEngineBeforeBuild = (
  field: BaseFieldProps<unknown>,
  formOverrides?: FormOverrides,
  shouldEnableForm?: boolean
) => {
  // Early return if no fieldKey is present
  if (!field.fieldKey) {
    return field;
  }

  const fieldToOverride =
    !formOverrides?.iterationEngineProps?.fieldsToEnable?.includes(
      field.fieldKey as string
    );

  const changeRequestOverride =
    !formOverrides?.iterationEngineProps?.fieldKeysWithChangeRequests?.includes(
      field.fieldKey as string
    );

  // Change Request Handling
  const shouldBeDisabled =
    formOverrides?.iterationEngineProps?.shouldEnableForm === false &&
    !shouldEnableForm &&
    changeRequestOverride &&
    fieldToOverride;

  const fieldChangeRequest =
    formOverrides?.iterationEngineProps?.changeRequests?.find(
      (cr) => cr.evaluationFieldKey === field.fieldKey
    );
  let changeRequestClassName = "";
  if (fieldChangeRequest) {
    switch (fieldChangeRequest.status) {
      case ChangeRequestStatus.New:
        changeRequestClassName = "change-request-new";
        break;
      case ChangeRequestStatus.Addressed:
        changeRequestClassName = "change-request-addressed";
        break;
      case ChangeRequestStatus.Resolved:
        changeRequestClassName = "change-request-resolved";
        break;
      default:
        changeRequestClassName = "";
    }
  }

  // Combine classNames from both compliance and change request logic
  const classNames = [field.fieldKey, changeRequestClassName].filter(Boolean);

  return {
    ...field,
    labelProps: {
      classNames,
      disabled: false,
      onClick: () =>
        formOverrides?.onLabelClick && formOverrides.onLabelClick(field),
    },
    disabled: shouldBeDisabled,
    hidden: field.hidden,
  };
};
