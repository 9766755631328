import { Schema } from "@ucl/library";
import { useContext, useEffect, useRef, useState } from "react";
import { FCFMFSchemaFactoryV2 } from "../../../../../common/customHooks/useFCFMFFieldSchemaFactory";
import wildfireBaseApiClient from "../../../../../../../wildfire/lib/wildfireBaseApiClient";
import { Evaluation_CommercialEvaluationFormModel } from "../../../../types/CommercialEvaluationFormModel";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { YesOrNoSelection } from "../../../../../../../wildfire/types/FormFields";
import { FCFMFApplicationFormParts } from "../../../../../common/types/Evaluation/BaseFCFMFApplicationForm";
import { CommercialApplicationFormContainerProps } from "./CommercialApplicationFormContainer";

function useCommercialApplicationFormContainer(
  props: CommercialApplicationFormContainerProps
) {
  const [commercialSchemaFactory, setCommercialSchemaFactory] =
    useState<FCFMFSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireBaseApiClient.get<Schema>(`/wildfire/schema`);
    const newSchemaFactory = new FCFMFSchemaFactoryV2(schema);
    setCommercialSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!commercialSchemaFactory) {
      getSchema();
    }
    //return () => {
    //     setDefaultHeader();
    //   };
  }, []);

  useEffect(() => {
    setApplicationFormPart(getBestFormPart());
  }, [props.applicationFormPart]);

  // const { setWildfireEvaluationHeader, setDefaultHeader } =
  //   useContext(AppHeaderContext);
  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [applicationFormPart, setApplicationFormPart] = useState<
    FCFMFApplicationFormParts | undefined
  >(props.applicationFormPart);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_CommercialEvaluationFormModel>();

  const childRequiredFieldCounts = useRef<Map<string, number>>(new Map()); // Store counts by child ID
  const [
    generalProjectDetailsRequiredFieldCount,
    setGeneralProjectDetailsRequiredFieldCount,
  ] = useState<number>(0);

  const updateChildRequiredFieldCount = (childId: string, count: number) => {
    childRequiredFieldCounts.current.set(childId, count);

    // Calculate the total of all children’s counts
    const childTotal = Array.from(
      childRequiredFieldCounts.current.values()
    ).reduce((acc, val) => acc + val, 0);

    // Combine initial parent count with child counts
    setGeneralProjectDetailsRequiredFieldCount(
      (applicationForm?.generalProjectDetailsRequiredFieldCount || 0) +
        childTotal
    );
  };

  const applicationForm =
    evaluationFormModel?.commercialEvaluation
      .commercialEvaluationApplicationForm;

  useEffect(() => {
    if (applicationForm) {
      setGeneralProjectDetailsRequiredFieldCount(
        applicationForm.generalProjectDetailsRequiredFieldCount || 0
      );
    }
  }, [applicationForm]);

  const getNextUnsubmittedFormPart = () => {
    if (!applicationForm) {
      return null;
    }

    if (!applicationForm.isApplicationInformationComplete) {
      return FCFMFApplicationFormParts.ApplicationInformation;
    } else if (!applicationForm.isEmailNotificationsComplete) {
      return FCFMFApplicationFormParts.EmailNotifications;
    } else if (
      !applicationForm.isGeneralProjectDetailsComplete ||
      generalProjectDetailsRequiredFieldCount !== 0
    ) {
      return FCFMFApplicationFormParts.GeneralProjectDetails;
    } else if (!applicationForm.isNewConstructionsAndNewAdditionsComplete) {
      return FCFMFApplicationFormParts.NewConstructionsAndNewAdditions;
    } else if (!applicationForm.isExistingConstructionReroofingComplete) {
      return FCFMFApplicationFormParts.ExistingConstructionReroofing;
    } else if (!applicationForm.isProjectConstructionDetailsComplete) {
      return FCFMFApplicationFormParts.ProjectConstructionDetails;
    } else if (!applicationForm.isExistingRoofCoverSystemComplete) {
      return FCFMFApplicationFormParts.ExistingRoofCoverSystem;
    } else if (!applicationForm.isFileUploadersComplete) {
      return FCFMFApplicationFormParts.FileUploaders;
    } else if (!applicationForm.isConfirmationFieldsComplete) {
      return FCFMFApplicationFormParts.ConfirmationFields;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart = (): FCFMFApplicationFormParts => {
    // Default to the first part of the form if we can't find a better option
    const defaultFormPart = Object.values(FCFMFApplicationFormParts)[0];

    // If the application form part is provided (by URL), use it
    if (!!props.applicationFormPart) {
      return props.applicationFormPart;
    }

    // Otherwise, try to find the first unsubmitted part of the form
    // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
    return getNextUnsubmittedFormPart() ?? defaultFormPart;
  };

  const getApplicationPartChecklistStatus = (
    formPart: FCFMFApplicationFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case FCFMFApplicationFormParts.ApplicationInformation:
        return applicationForm?.isApplicationInformationComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case FCFMFApplicationFormParts.EmailNotifications:
        return applicationForm?.isEmailNotificationsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case FCFMFApplicationFormParts.GeneralProjectDetails:
        return applicationForm?.isGeneralProjectDetailsComplete &&
          generalProjectDetailsRequiredFieldCount === 0
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case FCFMFApplicationFormParts.NewConstructionsAndNewAdditions:
        return applicationForm?.isNewConstructionsAndNewAdditionsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case FCFMFApplicationFormParts.ExistingConstructionReroofing:
        return applicationForm?.isExistingConstructionReroofingComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case FCFMFApplicationFormParts.ProjectConstructionDetails:
        return applicationForm?.isProjectConstructionDetailsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case FCFMFApplicationFormParts.ExistingRoofCoverSystem:
        return applicationForm?.isExistingRoofCoverSystemComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case FCFMFApplicationFormParts.FileUploaders:
        return applicationForm?.isFileUploadersComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case FCFMFApplicationFormParts.ConfirmationFields:
        return applicationForm?.isConfirmationFieldsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const applicationChecklistParts: FormChecklistPart<FCFMFApplicationFormParts>[] =
    [
      {
        formPart: FCFMFApplicationFormParts.ApplicationInformation,
        displayName: "Application Information",
        requiredFieldCount:
          applicationForm?.applicationInformationRequiredFieldCount,
      },
      {
        formPart: FCFMFApplicationFormParts.EmailNotifications,
        displayName: "Email Notifications",
        requiredFieldCount:
          applicationForm?.emailNotificationsRequiredFieldCount,
        disabled: applicationForm?.isApplicationInformationComplete !== true,
      },
      {
        formPart: FCFMFApplicationFormParts.GeneralProjectDetails,
        displayName: "General Project Details",
        requiredFieldCount: generalProjectDetailsRequiredFieldCount,
        disabled: applicationForm?.isEmailNotificationsComplete !== true,
      },
      {
        formPart: FCFMFApplicationFormParts.NewConstructionsAndNewAdditions,
        displayName: "New Constructions and New Additions",
        requiredFieldCount:
          applicationForm?.newConstructionsAndNewAdditionsRequiredFieldCount,
        disabled: applicationForm?.isGeneralProjectDetailsComplete !== true,
        hidden:
          applicationForm?.isThisANewConstruction !== YesOrNoSelection.Yes,
      },
      {
        formPart: FCFMFApplicationFormParts.ExistingConstructionReroofing,
        displayName: "Existing Construction Reroofing",
        requiredFieldCount:
          applicationForm?.existingConstructionReroofingRequiredFieldCount,
        disabled:
          applicationForm?.isNewConstructionsAndNewAdditionsComplete !== true,
        hidden: applicationForm?.isThisANewConstruction !== YesOrNoSelection.No,
      },
      {
        formPart: FCFMFApplicationFormParts.ProjectConstructionDetails,
        displayName: "Project Construction Details",
        requiredFieldCount:
          applicationForm?.projectConstructionDetailsRequiredFieldCount,
        disabled:
          applicationForm?.isExistingConstructionReroofingComplete !== true,
      },
      {
        formPart: FCFMFApplicationFormParts.ExistingRoofCoverSystem,
        displayName: "Existing Roof Cover System",
        requiredFieldCount:
          applicationForm?.existingRoofCoverSystemRequiredFieldCount,
        disabled:
          applicationForm?.isProjectConstructionDetailsComplete !== true,
        hidden:
          applicationForm?.numberOfRoofCoverSystems === null ||
          applicationForm?.numberOfRoofCoverSystems === 0 ||
          applicationForm?.isThisANewConstruction !== YesOrNoSelection.No,
      },
      {
        formPart: FCFMFApplicationFormParts.FileUploaders,
        displayName: "File Uploaders",
        requiredFieldCount: applicationForm?.fileUploadersRequiredFieldCount,
        disabled: applicationForm?.isExistingRoofCoverSystemComplete !== true,
      },
      {
        formPart: FCFMFApplicationFormParts.ConfirmationFields,
        displayName: "Confirmation Fields",
        requiredFieldCount:
          applicationForm?.confirmationFieldsRequiredFieldCount,
        disabled: applicationForm?.isFileUploadersComplete !== true,
      },
    ];

  return {
    applicationChecklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    applicationFormPart,
    getBestFormPart,
    commercialSchemaFactory,
    updateChildRequiredFieldCount,
  };
}

export default useCommercialApplicationFormContainer;
