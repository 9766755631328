import { Intent } from "@blueprintjs/core";
import { FieldFormV2Module } from "@ucl/library";
import { useFieldSchemaFactory } from "../../../customHooks/useFieldSchemaFactory";
import { HomeExteriorFieldEvaluationFormParts } from "../../../forms/HomeExteriorFieldEvaluationForm/HomeExteriorFieldEvaluationFormModel";
import { HomeExteriorFieldEvaluationFormProps } from "./HomeExteriorFieldEvaluationForm";
import { useCallback, useEffect, useRef, useState } from "react";
import { wildfireEvaluationApiClient } from "../../../lib/apiClients/application/wildfireApplicationApiClient";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../common/Components/Error/ErrorStore";
import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { useNavigate } from "react-router";
import { EvaluationFormModel } from "../../../forms/EvaluationFormModel";
import { LandscapeFieldEvaluationFormParts } from "../../../forms/LandscapeFieldEvaluationForm/LandscapeFieldEvaluationFormModel";
import { wildfireRoutePrefix } from "../../../pages/index/wildfireRouteConfig";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { getScrollContainer } from "../../../utils/getScrollContainer";

export function useHomeExteriorFieldEvaluationForm(
  props: HomeExteriorFieldEvaluationFormProps
) {
  const navigate = useNavigate();

  const { builders, wieBuilders } = useFieldSchemaFactory();
  const formRef = useRef<FieldFormV2Module<EvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!props.isWiePage && !!containerRef.current) {
      const scrollableParent = getScrollContainer(containerRef.current);

      scrollableParent?.scrollTo(0, 0);
    }
  }, [props.formPart]);

  const [
    homeExteriorFieldEvaluationFormModel,
    setHomeExteriorFieldEvaluationFormModel,
  ] = useState<EvaluationFormModel>();

  // Differentiates between save and submit
  let shouldSubmit = false;
  const setShouldSubmit = (value: boolean) => {
    shouldSubmit = value;
  };

  useEffect(() => {
    if (homeExteriorFieldEvaluationFormModel) {
      setHomeExteriorFieldEvaluationFormModel({
        ...homeExteriorFieldEvaluationFormModel,
      });
    }
  }, [wieBuilders]);

  const isFirstPartOfForm =
    props.formPart === Object.values(HomeExteriorFieldEvaluationFormParts)[0];

  const isLastPartOfForm =
    props.formPart ===
    Object.values(HomeExteriorFieldEvaluationFormParts).pop();

  const fetchHomeExteriorFieldEvaluationForm = async () => {
    setIsLoading(true);
    await wildfireEvaluationApiClient
      .getHomeExteriorFieldEvaluationForm(props.wildfireEvaluationId)
      .then((response) => {
        setHomeExteriorFieldEvaluationFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!!props.formModel) {
      setHomeExteriorFieldEvaluationFormModel(props.formModel);
      setIsLoading(false);
    } else {
      fetchHomeExteriorFieldEvaluationForm();
    }
  }, [props.formModel]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (!!homeExteriorFieldEvaluationFormModel) {
      setHomeExteriorFieldEvaluationFormModel(
        homeExteriorFieldEvaluationFormModel
      );

      if (props.setFormModel) {
        props.setFormModel(homeExteriorFieldEvaluationFormModel);
      }
    }
  }, [homeExteriorFieldEvaluationFormModel]);

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: EvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormSubmit(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: EvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getHomeExteriorFieldEvaluationFormPartBuilder = () => {
    if (props.isWiePage) {
      return wieBuilders?.HomeExteriorFieldEvaluationForm;
    }
    switch (props.formPart) {
      case HomeExteriorFieldEvaluationFormParts.Vents:
        return builders?.HomeExteriorFieldEvaluationFormVentsPart;
      case HomeExteriorFieldEvaluationFormParts.SixInchVertical:
        return builders?.HomeExteriorFieldEvaluationFormSixInchVerticalPart;
      case HomeExteriorFieldEvaluationFormParts.SidingMaterial:
        return builders?.HomeExteriorFieldEvaluationFormSidingMaterialPart;
      case HomeExteriorFieldEvaluationFormParts.Windows:
        return builders?.HomeExteriorFieldEvaluationFormWindowsPart;
      case HomeExteriorFieldEvaluationFormParts.Doors:
        return builders?.HomeExteriorFieldEvaluationFormDoorsPart;
      case HomeExteriorFieldEvaluationFormParts.Decks:
        return builders?.HomeExteriorFieldEvaluationFormDecksPart;
      default:
        return builders?.HomeExteriorFieldEvaluationForm;
    }
  };

  const handleFormSubmit = async (
    form: EvaluationFormModel,
    fieldKey?: string
  ) => {
    setHomeExteriorFieldEvaluationFormModel(form);

    await (shouldSubmit
      ? wildfireEvaluationApiClient.submitHomeExteriorFieldEvaluationForm(
          form.wildfireApplication.id,
          form.wildfireApplication.homeExteriorFieldEvaluationForm,
          props.formPart
        )
      : wildfireEvaluationApiClient.updateHomeExteriorFieldEvaluationForm(
          form.wildfireApplication.id,
          form.wildfireApplication.homeExteriorFieldEvaluationForm,
          fieldKey
        )
    )
      .then(async (response) => {
        handleFormResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = (response: EvaluationFormModel) => {
    props.setHasUnsavedChanges(false);
    if (shouldSubmit) {
      setHomeExteriorFieldEvaluationFormModel(response);
      if (isLastPartOfForm || !props.formPart) {
        // Navigate to first part of next form
        navigate(
          `${wildfireRoutePrefix}/${
            props.wildfireEvaluationId
          }/landscape-field-evaluation-form/${Object.values(
            LandscapeFieldEvaluationFormParts
          )[0].valueOf()}`
        );
      } else {
        // Navigate to next part of form
        navigate(
          `${wildfireRoutePrefix}/${
            props.wildfireEvaluationId
          }/home-exterior-field-evaluation-form/${Object.values(
            HomeExteriorFieldEvaluationFormParts
          )[
            Object.values(HomeExteriorFieldEvaluationFormParts).indexOf(
              props.formPart
            ) + 1
          ].valueOf()}`
        );
      }
    } else {
      handlePartialResponseFormUpdate(response);
    }
  };

  const handlePartialResponseFormUpdate = (response: EvaluationFormModel) => {
    //This is used to combat save on blur issues. This only updates fields that are essetnial to the form.
    //These are set in the API response. Field Counts and sub section completion status
    setHomeExteriorFieldEvaluationFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        decksRequiredFieldCount,
        doorsRequiredFieldCount,
        sidingMaterialRequiredFieldCount,
        sixInchVerticalRequiredFieldCount,
        ventsRequiredFieldCount,
        windowsRequiredFieldCount,
        submitted,
        decksSubmitted,
        doorsSubmitted,
        sidingMaterialSubmitted,
        sixInchVerticalSubmitted,
        ventsSubmitted,
        windowsSubmitted,
        decksPhotoFolder,
        doorsPhotoFolder,
        sidingMaterialPhotoFolder,
        sixInchVerticalPhotoFolder,
        ventsPhotoFolder,
        windowsPhotoFolder,
        isDecksComplete,
        isDoorsComplete,
        isSidingMaterialComplete,
        isSixInchVerticalComplete,
        isVentsComplete,
        isWindowsComplete,
      } = response.wildfireApplication.homeExteriorFieldEvaluationForm;

      return {
        ...prevState,
        wildfireApplication: {
          ...prevState.wildfireApplication,
          homeExteriorFieldEvaluationForm: {
            ...prevState.wildfireApplication.homeExteriorFieldEvaluationForm,
            decksRequiredFieldCount,
            doorsRequiredFieldCount,
            sidingMaterialRequiredFieldCount,
            sixInchVerticalRequiredFieldCount,
            ventsRequiredFieldCount,
            windowsRequiredFieldCount,
            submitted,
            decksSubmitted,
            doorsSubmitted,
            sidingMaterialSubmitted,
            sixInchVerticalSubmitted,
            ventsSubmitted,
            windowsSubmitted,
            decksPhotoFolder,
            doorsPhotoFolder,
            sidingMaterialPhotoFolder,
            sixInchVerticalPhotoFolder,
            ventsPhotoFolder,
            windowsPhotoFolder,
            isDecksComplete,
            isDoorsComplete,
            isSidingMaterialComplete,
            isSixInchVerticalComplete,
            isVentsComplete,
            isWindowsComplete,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    homeExteriorFieldEvaluationFormModel,
    setShouldSubmit,
    isFirstPartOfForm,
    isLastPartOfForm,
    getHomeExteriorFieldEvaluationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    containerRef,
  };
}

export default useHomeExteriorFieldEvaluationForm;
