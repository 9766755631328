import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { openWildfireApprovalDialog } from "../../Dialogs/Approval/WildfireApprovalDialog";
import { EvaluationStatus } from "../../../types/WildfireApplication/WildfireApplication";
import { RelativityTooltip } from "@ucl/library";

interface WildfireApproveActionButtonProps {
  wildfireApplicationId: string;
  hasFinalDetermination: boolean;
  status: EvaluationStatus;
  approve: () => Promise<void>;
  isApproveLoading: boolean;
  isEvaluationClaimedByCurrentUser: boolean;
  isWithdrawn: boolean;
}

export const WildfireApproveActionButton: React.FC<
  WildfireApproveActionButtonProps
> = (props: WildfireApproveActionButtonProps) => {
  const shouldDisable =
    props.hasFinalDetermination ||
    !props.isEvaluationClaimedByCurrentUser ||
    props.isWithdrawn;

  const toolTipText = props.hasFinalDetermination
    ? `Evaluation is already ${props.status}`
    : !props.isEvaluationClaimedByCurrentUser
    ? "Claim Evaluation to Approve"
    : props.isWithdrawn
    ? "Evaluation is closed"
    : "Approve";

  return (
    <RelativityTooltip shouldShowTooltip={shouldDisable} content={toolTipText}>
      <Button
        minimal
        className="wildfire-approve-evaluation-button"
        text="Approve"
        disabled={shouldDisable}
        loading={props.isApproveLoading}
        intent={Intent.SUCCESS}
        rightIcon={IconNames.ENDORSED}
        onClick={async () => {
          openWildfireApprovalDialog(
            props.wildfireApplicationId,
            props.approve
          );
        }}
      />
    </RelativityTooltip>
  );
};
