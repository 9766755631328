import React, { useEffect, useState } from "react";
import { HomeEvaluationOptions } from "../../../../types/schema";
import { EvaluationChild } from "../../../../types/evaluation/Evaluation";
import { parseDate } from "../../../../../common/utils/date";
import { ReadOnlyField } from "@ucl/library";

export interface HomeEvaluationFieldInfoContainerProps {
  selectedOption: HomeEvaluationOptions;
  selectedChildOption: EvaluationChild | undefined;
}

const HomeEvaluationFieldInfoContainer: React.FC<
  HomeEvaluationFieldInfoContainerProps
> = (props) => {
  const [selectedOption, setSelectedOption] = useState<HomeEvaluationOptions>();

  useEffect(() => {
    setSelectedOption(props.selectedOption);
  }, [props.selectedOption]);

  const [selectedChildOption, setSelectedChildOption] =
    useState<EvaluationChild>();

  useEffect(() => {
    setSelectedChildOption(props.selectedChildOption);
  }, [props.selectedChildOption]);
  return (
    <>
      {!!selectedOption && (
        <div className="redesignation-dialog-evaluation-values">
          <div>
            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.productType_AsString}
              label={"Program"}
            />
            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.designationLevel_AsString}
              label={"Designation Level"}
            />
            {!!selectedChildOption && (
              <ReadOnlyField
                type="ReadOnly"
                value={
                  selectedChildOption.approvedAt_AsDateOnly
                    ? parseDate(selectedChildOption.approvedAt_AsDateOnly)
                    : ""
                }
                label={"Approved At"}
              />
            )}
            {!!selectedChildOption && (
              <ReadOnlyField
                type="ReadOnly"
                value={
                  selectedChildOption.expiresAt_AsDateOnly
                    ? parseDate(selectedChildOption.expiresAt_AsDateOnly)
                    : ""
                }
                label={"Expiration Date"}
              />
            )}
            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.buildingAddress}
              label={"Building Address"}
            />
            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.buildingAddress2 || ""}
              label={"Building Address 2"}
            />

            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.buildingCity}
              label={"Building City"}
            />
            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.buildingCounty || ""}
              label={"Building County"}
            />
            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.buildingState_AsString}
              label={"Building State"}
            />
            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.buildingZip}
              label={"Building Zip"}
            />
            <ReadOnlyField
              type="ReadOnly"
              value={selectedOption.hazard_AsString}
              label={"Hazard Type"}
            />
            {!!selectedChildOption && (
              <ReadOnlyField
                type="ReadOnly"
                value={
                  selectedChildOption.evaluationExpirationDateType_AsString
                }
                label={"Expiration Status"}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default HomeEvaluationFieldInfoContainer;
