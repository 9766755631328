import { Intent, Label } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { Icon } from "@blueprintjs/core";
import "./styles.scss";
import { BlueprintIcons_16Id } from "@blueprintjs/icons/lib/esm/generated-icons/16px/blueprint-icons-16";
import { RelativityTooltip } from "../../Tooltip/RelativityTooltip";

export interface FieldLabelProps {
  labelName: string | React.ReactNode;
  tooltipDescription?: string;
  intent?: Intent;
  icon?: BlueprintIcons_16Id;
  disabled?: boolean;
}

export const FieldLabel: React.FC<FieldLabelProps> = observer(
  ({ labelName, tooltipDescription, icon, disabled, intent }) => {
    return (
      <Label className="field-label" disabled={disabled}>
        <span className="label-title">{labelName}</span>
        <RelativityTooltip
          placement="auto"
          compact={true}
          content={
            <div className="label-tooltip-content">
              {tooltipDescription || ""}
            </div>
          }
          interactionKind={"hover"}
          shouldShowTooltip={true}
          disabled={disabled}
          intent={!!intent ? intent : Intent.NONE}
        >
          {!!tooltipDescription && (
            <Icon
              iconSize={15}
              icon={!!icon ? icon : "info-sign"}
              intent={!!intent ? intent : Intent.NONE}
            />
          )}
        </RelativityTooltip>
      </Label>
    );
  }
);
