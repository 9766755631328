import { PrimaryCard, PrimaryCardHeader } from "@ucl/library";
import classNames from "classnames";
import { ReactNode } from "react";

export interface ServiceOptionsInfoCardProps {
  title: string;
  details: (string | ReactNode)[];
  price: ReactNode;
  bestFor: string;
  canOnlyHaveSelfService?: boolean;
}

export const ServiceOptionsInfoCard: React.FC<ServiceOptionsInfoCardProps> = ({
  title,
  details,
  price,
  bestFor,
  canOnlyHaveSelfService,
}: ServiceOptionsInfoCardProps) => {
  return (
    <PrimaryCard
      className={classNames(
        canOnlyHaveSelfService ? "self-service-only" : "all-services"
      )}
    >
      <PrimaryCardHeader label="">
        <div>
          <span className="service-options-info-card-header">{title}</span>
          {canOnlyHaveSelfService && (
            <span className="service-options-info-card-header-self-service-only">
              {"(Coming Soon)"}
            </span>
          )}
        </div>
      </PrimaryCardHeader>
      <div className="service-options-info-card-body">
        <ul>
          <li>
            <strong>Details: </strong>
          </li>
          <ul>
            {details.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
          <li>
            <strong>Price: </strong>
            {price}
          </li>
          <li>
            <strong>Best For: </strong>
            {bestFor}
          </li>
        </ul>
      </div>
    </PrimaryCard>
  );
};
