import { ExistingResource } from "./ExistingResource";

export interface NewEvaluationBid {
  id?: string;
  evaluationCompany: EvaluationCompanyTypes;
  evaluationCompany_AsString: string;
  hasBidBeenResent?: boolean;
}
export type EvaluationBid = ExistingResource<NewEvaluationBid>;

export enum EvaluationCompanyTypes {
  // Paragon = 1,
  GRC = 2,
  AEI = 4,
}

export const evaluationCompanyDefaults: Record<
  EvaluationCompanyTypes,
  NewEvaluationBid
> = {
  // [EvaluationCompanyTypes.Paragon]: {
  //   evaluationCompany: EvaluationCompanyTypes.Paragon,
  //   evaluationCompany_AsString: "Paragon",
  // },
  [EvaluationCompanyTypes.GRC]: {
    evaluationCompany: EvaluationCompanyTypes.GRC,
    evaluationCompany_AsString: "GRC",
  },
  [EvaluationCompanyTypes.AEI]: {
    evaluationCompany: EvaluationCompanyTypes.AEI,
    evaluationCompany_AsString: "AEI",
  },
};
