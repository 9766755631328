import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { RelativityTooltip } from "@ucl/library";
import { useState } from "react";

export interface SendToEvaluatorAssignmentButtonProps {
  isDisabled: boolean;
  sendToEvaluatorAssignment: (evaluationId: string) => Promise<void>;
  evaluationId: string;
}
export const SendToEvaluatorAssignmentButton: React.FC<
  SendToEvaluatorAssignmentButtonProps
> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const shouldSHowTooltip = props.isDisabled;

  return (
    <RelativityTooltip
      shouldShowTooltip={shouldSHowTooltip}
      content={
        "All Change Requests need to be resolved before moving to Evaluator Assignment"
      }
    >
      <Button
        text="Send Forward"
        intent={Intent.PRIMARY}
        minimal
        disabled={props.isDisabled || isLoading}
        loading={isLoading}
        icon={IconNames.ArrowRight}
        onClick={async () => {
          setIsLoading(true);
          await props
            .sendToEvaluatorAssignment(props.evaluationId)
            .finally(() => {
              setIsLoading(false);
            });
        }}
      />
    </RelativityTooltip>
  );
};
