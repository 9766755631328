import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import { showSuccessAppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { useState } from "react";

interface RequestChangesButtonProps {
  isRequestChangesDisabled: boolean;
  requestChanges: () => Promise<void>;
}

const RequestChangesButton: React.FC<RequestChangesButtonProps> = ({
  isRequestChangesDisabled,
  requestChanges,
}) => {
  const [isloading, setIsLoading] = useState(false);

  return isRequestChangesDisabled ? (
    <Popover2
      interactionKind="hover"
      placement="bottom-end"
      portalClassName="tooltip-popover"
      popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
      content={
        <span>
          There must be at least one unresolved change request in order to
          Request Changes. To record a new change request, click on any field
          label and submit a comment that states precisely what needs to be
          changed.
        </span>
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      renderTarget={({ isOpen, ...targetProps }) => (
        <div {...targetProps}>
          <Button
            text="Request Changes"
            minimal
            intent={Intent.WARNING}
            icon={IconNames.REFRESH}
            disabled
          />
        </div>
      )}
    />
  ) : (
    <Button
      text="Request Changes"
      minimal
      intent={Intent.WARNING}
      loading={isloading}
      icon={IconNames.REFRESH}
      onClick={async () => {
        setIsLoading(true);
        await requestChanges()
          .then(() => {
            showSuccessAppToaster("Changes requested successfully");
          })
          .finally(() => setIsLoading(false));
      }}
    />
  );
};

export default RequestChangesButton;
